import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { DetailsService } from '../../_services/index';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '../../_services';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { ErrorService } from 'src/app/_services/error.service';
import { PermissionService } from 'src/app/_services/permission.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DragulaService } from 'ng2-dragula';
import { EmailValidator } from '@angular/forms';
import { trigger, state, animate, transition, style } from '@angular/animations';
import { Subscription } from 'rxjs';
import { shouldCallLifecycleInitHook } from '@angular/core/src/view';

@Component({
  selector: 'app-customization',
  templateUrl: './customization.component.html',
  styleUrls: ['./customization.component.css'],
  viewProviders: [DragulaService],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class CustomizationComponent implements OnInit {
  maxChars = 255;
  dealsArr = [];
  dealsFinalArr = [];
  ocdstatusArr =[];
  ProjectUserdetailas=[];
  result: any;
  syncArr = [];
  approvalArr = [];
  projectArr = [];
  dealsUpdateArr = [];
  approvalUpdateArr = [];
  projectUpdateArr = [];
  PreferenceArr = [];
  fromEmailIdArr = [];
  certifierArr = [];
  projectUserArr = [];
  projectUserArrselect:any;
  body: {};
  _body: any;
  dependedStateSettings: any;
  predecessorStateSettings: any;
  id: any;
  nameErr: any;
  descErr: any;
  daysErr: any;
  stateErr: any;
  emailErr: any;
  userid: any;
  admin: any;
  genderErr: any;
  fnameErr: any;
  lnameErr: any;
  certifiemailErr: any;
  phoneErr: any;
  settingssingle: any;
  certifierID: any;
  subId:any;
  subs = new Subscription();
  isCreate: boolean = true;
  isdismail: boolean = false;
  addAccountDis: boolean = false;
  dealfromDis: boolean = false;
  isAdminDis: boolean = false;
  isDeal: boolean = false;
  isApproval: boolean = false;
  isProject: boolean = false;
  isadd: boolean = false;
  isapp: boolean = false;
  isproj: boolean = false;
  syncheck: boolean = false;
  menuStateEmail: string = 'out';
  menuStateCertifier: string = 'out';
  menuStateDeal: string = 'out';
  menuStateApproval: string = 'out';
  menuStateProject: string = 'out';
  menuOCDState: string = 'out';
  menuState: string = 'out'
  closeResult: string;
  urlResult: string;
  subJob_Show_Hide: boolean = false;
  isSubJobCreate: boolean = true;
  subjobtable : boolean = false;
  approvalform: FormGroup;
  dealForm: FormGroup;
  projectform: FormGroup;
  certifierForm: FormGroup;
  subJobform: FormGroup;
  emailForm: FormGroup;
  OCDStateform: FormGroup;
  result1 :any;
  result2:any;
  result3 :any;
  result4 :any;
  order :any;
  res1 :any;
  buidingArr=[];
  buidingArrselect = [];
  selectedItems = [];
  dropdownList = [];
  buildErr:any;
  statusErr:any;
  settings:any;
  msettings:any;
  projectDepended: any;
  projectPredecessor: any;
  statussettings:any;
  projectStatus = [];
  projectStatusDep = [];
  nameSubJobErr :any;
  descSubJobErr :any;
  daysSubJobErr :any;
  statusSubJobErr :any;
  resultOrder :any;
  parentId : any;
  editSh: boolean = false;
  projectUserArrselectSubJob=[];
  projectDependedSubJob =[];
  projectPredecessorSubJob =[];
  subStatesArr =[];
  subStatesDetailsArr =[];
  popoverform: boolean = false;
  subStates:any;
  notifiSettings: any;
  noftificationArr = [];
  noftificationArrSelect = [];
  noftificationSubJobArrSelect =[];
  isSiteAdmin: boolean = false;
  passreset: boolean = false;
  passwordForm: FormGroup;
  passwordErr: any;
  resetpasswordErr: any;
  confirmpasswordErr: any;
  supervisorsettings :any;
  projectAllUsersArr =[];
  projectUsersArr =[];
  buidingArrselectSubJob=[];
  projectAllUsersArrSelect =[];
  projectSupervisorArrSelect =[];
  projectSupervisorArrSelectSubJob =[];
  supervisorShow: boolean = false;
  supervisorShowSubJob: boolean = false;
  supervisorErr :any;
  externalinternalResult :any
  predecessorStateSettings1 :any;
  loggedInUserDomain: any;

  projectStagesArr = [];
  projectStageDefaultArr = [];
  stageUpdateArr = [];
  stageForm: any;
  menuProjectStage: string = 'out';
  isstageadd: boolean = false;

  projectTypeArr = [];
  projectTypeSelectedArr = [];
  ocdUpdateArr = [];
  isocdorderupdated: boolean = false;


  constructor(private DetailsService: DetailsService,private http: HttpClient, private router: Router, private modalService: NgbModal,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private errorService: ErrorService,
    private confirmService: ConfirmService,
    private dragulaService: DragulaService,
    private permissionService: PermissionService,
    private spinner: NgxSpinnerService,
  ) {

    this.createForm();
    this.projectUserArrselect=[];
    this.subs.add(this.dragulaService.drop()
      .subscribe(({ name, el, target, source, sibling }) => {
        if (name == "deal") {
          this.dealsUpdateArr = [];
          for (let i = 0; i < source.childElementCount; i++) {
            var j = source.children[i].id;
            this.dealsUpdateArr.push(parseInt(j));
          }
          var dealId = el.attributes["id"].nodeValue;
          if (dealId == -1) { return false; }
          this.isadd = true;
        } else if (name == "approval") {
          this.approvalUpdateArr = [];
          for (let i = 0; i < source.childElementCount; i++) {
            var j = source.children[i].id;
            this.approvalUpdateArr.push(parseInt(j));
          }
          var approvalId = el.attributes["id"].nodeValue;
          if (approvalId == -1) { return false; }
          this.isapp = true;
        } else if (name == "project") {
          this.projectUpdateArr = [];
          for (let i = 0; i < source.childElementCount; i++) {
            var j = source.children[i].id;
            this.projectUpdateArr.push(parseInt(j));
          }
          var projectId = el.attributes["id"].nodeValue;
          if (projectId == -1) { return false; }
          this.isproj = true;
        } else if (name == "stage") {
          this.stageUpdateArr = [];
          for (let i = 0; i < source.childElementCount; i++) {
            var j = source.children[i].id;
            this.stageUpdateArr.push(parseInt(j));
          }
          var stageId = el.attributes["id"].nodeValue;
          if (stageId == -1) { return false; }
          this.isstageadd = true;
        } else if (name == "ocdstatus") {
          this.ocdUpdateArr = [];
          for (let i = 0; i < source.childElementCount; i++) {
            var j = source.children[i].id;
            this.ocdUpdateArr.push(parseInt(j));
          }
          var ocdId = el.attributes["id"].nodeValue;
          if (ocdId == -1) { return false; }
          this.isocdorderupdated = true;
        }

      })
    );

  }

  ngOnInit() {
    this.userid = localStorage.getItem("loggedInUserID");
    this.admin = localStorage.getItem("loggedInUserAdmin");
    this.loggedInUserDomain = localStorage.getItem('loggedInDomainID');
    if(this.admin == 'Y'){
      this.isSiteAdmin = true;
    }else{
      this.isSiteAdmin = false;
    }
    this.getDeals();
    this.getApproval();
    this.getProject();
    // this.getFromEmail();
    // this.getPreference();
    // this.getCertifier();
    this.getBuildingtype();
    this.getPermissionDetails();
    this.getallUserAndExternalUser();
    this.dropdownList = [
      { "id": 1, "itemName": "Male" },
      { "id": 2, "itemName": "Female" },
      { "id": 3, "itemName": "Others" }
    ];

    this.projectTypeArr = [
      { "id": "DA", "itemName": "DA" },
      { "id": "CC", "itemName": "CC" },
      { "id": "CDC", "itemName": "CDC"},
      { "id": "SubDivision", "itemName": "SubDivision" }
    ];
    this.projectTypeSelectedArr = [
      { "id": "DA", "itemName": "DA" }
    ];
        this.settings = {
      text: "Select Building",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: false,
      enableCheckAll: true
    };
     this.msettings = {
      text: "Select User",
      classes: "myclass custom-class",
      singleSelection: true,
      enableCheckAll: false,
      groupBy: "type"
    };
    this.settingssingle = {
      text: "Select",
      classes: "myclass custom-class",
      singleSelection: true,
      enableCheckAll: false
    };
    this.statussettings={
    text: "Select projectState",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: true,
      enableCheckAll: false
    }
    this.supervisorsettings = {
      text: "Select Supervisor",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: true,
      enableCheckAll: false
    }
    this.noftificationArr = [
    { "id": 1, "itemName": "3 Days before" },
    { "id": 2, "itemName": "7 Days before" },
    { "id": 3, "itemName": "15 Days before" },
    { "id": 4, "itemName": "30 Days before" }
  ];

  this.dependedStateSettings = {
    text: "Select ",
    enableSearchFilter: true,
    classes: "myclass custom-class",
    disabled: false,
    singleSelection: false,
    enableCheckAll: false
  }
  this.predecessorStateSettings1 = {
    text: "Select ",
    enableSearchFilter: true,
    classes: "myclass custom-class",
    disabled: false,
    singleSelection: true,
    enableCheckAll: false
  }
  }
  getPermissionDetails() {
    this.http.get('/api/user/' + this.userid).subscribe((res: Response) => {
     this.result = res;
      var permision = this.result.role.permision;
      if (permision.length > 0) {
        var dealPermission = this.permissionService.getDealPermission(permision);
        var approvalPermission = this.permissionService.getApprovalPermission(permision);
        var projectPermission = this.permissionService.getProjectPermission(permision);
        if (dealPermission == this.permissionService.DEAL_ADMIN || this.admin == "Y") {
          this.isAdminDis = true;
          this.isDeal = true;
        } else if (dealPermission == this.permissionService.DEAL_OBSERVER) {
          this.isDeal = true;
        }
        if (approvalPermission == this.permissionService.APPROVAL_ADMIN || this.admin == "Y") {
          // this.isAdminDis = true;
          this.isApproval = true;
        } else if (approvalPermission == this.permissionService.APPROVAL_OBSERVER) {
          this.isApproval = true;
        }
        if (projectPermission == this.permissionService.PROJECT_ADMIN || this.admin == "Y") {
          // this.isAdminDis = true;
          this.isProject = true;
        } else if (projectPermission == this.permissionService.PROJECT_OBSERVER) {
          this.isProject = true;
        }
      }

    });
  }
  getFromEmail() {
    this.http.get('/api/mail/' + this.userid).subscribe((res: Response) => {
      this.result = res;
      if (this.result.length != 0) {
        this.fromEmailIdArr.push({
          "fromId": this.result[0].email,
        })
      }
    })

  }
  getPreference() {
    this.http.get('/api/userPreferences/' + this.userid).subscribe((res: Response) => {
      this.result = res;
      if (this.result.length != 0) {
        if (this.result[0].isAccountAdded == "Y") {
          this.syncheck = true;
          this.isdismail = true;
        } else {
          this.isdismail = false;
          this.syncheck = false;
        }
        this.PreferenceArr.push({
          "id": this.result[0].id, "isAccountAdded": this.result[0].isAccountAdded, "isCalenderSync": this.result[0].isCalenderSync, "isContactsSync": this.result[0].isContactsSync, "isMailSync": this.result[0].isMailSync
        })
      }
    })

  }
  check(number) {
    this.http.get('/api/userPreferences/' + this.userid).subscribe((res: Response) => {
      var data1 = res;
      var data = data1[0];
      if (number == 1) {
        if (data.isAccountAdded == "Y") {
          data.isAccountAdded = "N";
          data.isMailSync = "N";
          data.isCalenderSync = "N";
          data.isContactsSync = "N";
        }
        this.http.put('/api/userPreferences/', data).subscribe((response) => {

          this.toastr.clear();
          this.result = response;
          if (this.result.success) {
            this.resetForm();
            this.toastr.success(this.result.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.getPreference();
            this.PreferenceArr = []
          } else {

            this.toastr.error(this.result.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
          }
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })
      } else if (number == 2) {
        if (data.isMailSync == "Y") {
          data.isMailSync = "N";
        } else {
          data.isMailSync = "Y";
        }
        this.http.put('/api/userPreferences/', data).subscribe((response) => {

          this.toastr.clear();
          this.result = response;
          if (this.result.success) {
            this.resetForm();
            this.toastr.success(this.result.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });

          } else {

            this.toastr.error(this.result.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
          }
        }
          , error => {
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
            this.errorService.handleError(error);
          })
      } else if (number == 3) {
        if (data.isCalenderSync == "Y") {
          data.isCalenderSync = "N";
        } else {
          data.isCalenderSync = "Y";
        }
        this.http.put('/api/userPreferences/', data).subscribe((response) => {

          this.toastr.clear();
          this.result = response;
          if (this.result.success) {
            this.resetForm();
            this.toastr.success(this.result.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });

          } else {

            this.toastr.error(this.result.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
          }
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })
      } else if (number == 4) {
        if (data.isContactsSync == "Y") {
          data.isContactsSync = "N";
        } else {
          data.isContactsSync = "Y";
        }
        this.http.put('/api/userPreferences/', data).subscribe((response) => {
          this.toastr.clear();
          this.result = response;
          if (this.result.success) {
            this.resetForm();
            this.toastr.success(this.result.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });

          } else {

            this.toastr.error(this.result.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
          }
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })
      } else {
      }
    })
  }
  getDeals() {
    this.dealsArr = [];
    this.dealsFinalArr = [];
    this.spinner.show();
    this.http.get('/api/projectStates/deals').subscribe((res: Response) => {
      this.result = res;
      this.spinner.hide();
      for (let i = 0; i < this.result.length; i++) {
        if (this.result[i].orders == 1) {
          this.dealsFinalArr.push({
            "id": this.result[i].id, "name": this.result[i].name, "shortdesc": this.result[i].shortdesc

          })

        } else if (this.result[i].isFinal == "Y") {
          this.dealsFinalArr.push({
            "id": this.result[i].id, "name": this.result[i].name, "shortdesc": this.result[i].shortdesc
          })
        } else {
          this.dealsArr.push({
            "id": this.result[i].id, "name": this.result[i].name, "shortdesc": this.result[i].shortdesc

          })
        }

      }
    });
  }
  getApproval() {
    this.approvalArr = [];
    this.spinner.show();
    this.http.get('/api/projectStates/approvals').subscribe((res: Response) => {
      this.result = res;
      this.spinner.hide();
      for (let i = 0; i < this.result.length; i++) {
        this.approvalArr.push({
          "id": this.result[i].id, "name": this.result[i].name, "shortdesc": this.result[i].shortdesc

        })
      }
    });
  }
  getCC() {
    this.approvalArr = [];
    this.spinner.show();
    this.http.get('/api/projectStates/cc').subscribe((res: Response) => {
      this.result = res;
      this.spinner.hide();
      for (let i = 0; i < this.result.length; i++) {
        this.approvalArr.push({
          "id": this.result[i].id, "name": this.result[i].name, "shortdesc": this.result[i].shortdesc

        })
      }
    });
  }
  getSubDivision() {
    this.approvalArr = [];
    this.spinner.show();
    this.http.get('/api/projectStates/subdivision').subscribe((res: Response) => {
      this.result = res;
      this.spinner.hide();
      for (let i = 0; i < this.result.length; i++) {
        this.approvalArr.push({
          "id": this.result[i].id, "name": this.result[i].name, "shortdesc": this.result[i].shortdesc

        })
      }
    });
  }
  getProject() {
    this.projectArr = [];
    this.spinner.show();
    this.http.get('/api/projectStates/projects').subscribe((res: Response) => {
      this.result = res;
      this.spinner.hide();
      for (let i = 0; i <this.result .length; i++) {
        this.projectArr.push({
          "id":   this.result [i].state.id, "name":   this.result [i].state.name, "shortdesc":   this.result [i].state.shortdesc, "daysDuration":   this.result [i].state.daysDuration,
          // "id":id[i].types.id
          "type":   this.result [i].types, "subStatesCount" : this.result[i].substates.length


        })
      }


    });
  }
  getProjectStages() {
    this.projectStagesArr = [];
    this.projectStageDefaultArr = [];
    this.spinner.show();
    this.http.get('/api/projectStage/domain/' + this.loggedInUserDomain ).subscribe((res: Response) => {
      this.result = res;
      this.spinner.hide();
      for (let i = 0; i <this.result.length; i++) {
        if (this.result[i].isDefault == "Y") {
          this.projectStageDefaultArr.push(this.result[i]);
        } else {
          this.projectStagesArr.push(this.result[i]);
        }
      }
    });
  }
  onchangtab(value) {
    var id = value.nextId;
    if (id == "Deal") {
      this.menuStateDeal = 'out';
    } else if (id == "Approval") {
      this.menuStateApproval = 'out';
      this.getApproval();
    } else if (id == "CC") {
        this.menuStateApproval = 'out';
        this.getCC();
    } else if (id == "Subdivision") {
      this.menuStateApproval = 'out';
      this.getSubDivision();
    } else if (id == "Project") {
      this.menuStateProject = 'out';
    }
    else if (id == "Certifier") {
      this.menuStateCertifier = 'out';
    }else if(id == "OcdState"){
      this.menuOCDState = 'out';
      this.getOCDStatus();
    }else if(id == "Stage") {
      this.menuProjectStage = 'out';
      this.getProjectStages();
    }
  }
  toggleDealMenu() {
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.nameErr = null;
    this.descErr = null;
    this.daysErr = null;
    this.isCreate = true;
    this.id = undefined;
    this.resetForm();
    this.menuStateDeal = this.menuStateDeal === 'out' ? 'in' : 'out';
  }
  toggleApprovalMenu() {
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.nameErr = null;
    this.descErr = null;
    this.daysErr = null;
    this.isCreate = true;
    this.id = undefined;
    this.resetForm();
    this.menuStateApproval = this.menuStateApproval === 'out' ? 'in' : 'out';
  }
  toggleProjectMenu() {
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.supervisorShow = false;
    this.subjobtable = false;
    this.subJob_Show_Hide = false;
    this.buidingArrselect=[];
     this.projectDepended = [];
    this.projectPredecessor = [];
    this.projectStatus=[];
    this.projectStatusDep=[];
    this.projectUserArrselectSubJob = [];
    this.projectSupervisorArrSelect=[];
    this.nameErr = null;
    this.descErr = null;
    this.daysErr = null;
    this.supervisorErr =null;
     this.buildErr=null;
     this.statusErr=null;
    this.isCreate = true;
    this.id = undefined;
    this.resetForm();
    this.subJobResetForm();
    this.menuStateProject = this.menuStateProject === 'out' ? 'in' : 'out';
  }
  toggleCertifierMenu() {
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.fnameErr = null;
    this.lnameErr = null;
    this.certifiemailErr = null;
    this.phoneErr = null;
    this.isCreate = true;
    this.id = undefined;
    this.selectedItems=[];
    this.genderErr = null;
    this.resetForm();
    this.menuStateCertifier = this.menuStateCertifier === 'out' ? 'in' : 'out';
  }
  toggleOCDState() {
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.nameErr = null;
    this.descErr = null;
    this.daysErr = null;
    this.isCreate = true;
    this.id = undefined;
    this.resetForm();
    this.menuOCDState = this.menuOCDState === 'out' ? 'in' : 'out';
  }
  toggleProjectStageMenu() {
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.nameErr = null;
    this.descErr = null;
    this.isCreate = true;
    this.id = undefined;
    this.resetForm();
    this.menuProjectStage = this.menuProjectStage === 'out' ? 'in' : 'out';
  }


  createForm() {
    this.approvalform = this.formBuilder.group({
      domainId :'',
      id: '',
      name: ['', Validators.required],
      shortdesc: ['', Validators.required],
      projectStateTypeId: ['', Validators.required],
      orders: '',
      createdDate: '',
      createdBy: '',
      updatedDate: '',
      updatedBy: '',
      isActive: '',
      isFinal: ''
    });
    this.dealForm = this.formBuilder.group({
      domainId :'',
      id: '',
      name: ['', Validators.required],
      shortdesc: ['', Validators.required],
      projectStateTypeId: ['', Validators.required],
      orders: '',
      createdDate: '',
      createdBy: '',
      updatedDate: '',
      updatedBy: '',
      isActive: '',
      isFinal: ''
    });
    this.stageForm = this.formBuilder.group({
      domainId :'',
      id: '',
      stageTypeID: 5,
      name: ['', Validators.required],
      description: '',
      order: '',
      createdDate: '',
      createdBy: '',
      updatedDate: '',
      updatedBy: '',
      isActive: '',
      isDefault: ''
    });
    this.projectform = this.formBuilder.group({
      id: '',
      name: ['', Validators.required],
      shortdesc: ['', Validators.required],
      projectStateTypeId: ['', Validators.required],
      orders: '',
      createdDate: '',
      createdBy: '',
      updatedDate: '',
      updatedBy: '',
      isActive: '',
      daysDuration: null,
      isFinal: '',
      projectTypeId:'',
      defaultAssignee: '',
      domainId:'',
      dependedJobId:'',
      predecessorJobId:'',
      notifybefore: [],
      notifyMsg :'',
      defaultSupervisor:[]
    });
    this.OCDStateform = this.formBuilder.group({
      id: '',
      name: ['', Validators.required],
      shortdesc: ['', Validators.required],
      projectStateTypeId: ['', Validators.required],
      orders: '',
      createdDate: '',
      createdBy: '',
      updatedDate: '',
      updatedBy: '',
      isActive: '',
      isFinal: ''
    });
    this.certifierForm = this.formBuilder.group({
      domainId :'',
      id: null,
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: '',
      gender: '',
      isActive: '',
      createdBy: '',
      createdDate: '',
      updatedBy: ''
    });
    this.passwordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]]
    })
    this.subJobform = this.formBuilder.group({
      id: '',
      name: '',
      shortdesc: '',
      parentJobId: '',
      orders: '',
      createdDate: '',
      createdBy: '',
      updatedDate: '',
      updatedBy: '',
      isFinal: '',
      isActive: '',
      daysDuration:null,
      projectTypeId:'',
      defaultAssignee:'',
      dependedJobId:'',
      predecessorJobId:'',
      notifybefore: [],
      notifyMsg :'',
      defaultSupervisor: []
    });
    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }
  public resetForm() {
    this.approvalform.reset({
      name: '',
      shortdesc: '',
      projectStateTypeId: '',
      orders: '',
      createdDate: '',
      createdBy: '',
      updatedDate: '',
      updatedBy: '',
      isActive: '',
      isFinal: ''
    })
    this.dealForm.reset({
      name: '',
      shortdesc: '',
      projectStateTypeId: '',
      orders: '',
      createdDate: '',
      createdBy: '',
      updatedDate: '',
      updatedBy: '',
      isActive: '',
      isFinal: ''
    })
    this.stageForm = this.formBuilder.group({
      domainId :'',
      id: '',
      stageTypeID: 5,
      name: '',
      description: '',
      order: '',
      createdDate: '',
      createdBy: '',
      updatedDate: '',
      updatedBy: '',
      isActive: '',
      isDefault: ''
    });
    this.emailForm.reset({
      email: ''
    })
    this.projectform = this.formBuilder.group({

      id: '',
      name: ['', Validators.required],
      shortdesc: ['', Validators.required],
      projectStateTypeId: ['', Validators.required],
      orders: '',
      createdDate: '',
      createdBy: '',
      updatedDate: '',
      updatedBy: '',
      isActive: '',
      daysDuration:null,
      isFinal: '',
      projectTypeId:'',
      defaultAssignee: '',
      domainId:'',
      dependedJobId:'',
      predecessorJobId:'',
      notifybefore: [],
      notifyMsg :'',
      defaultSupervisor:[]
    });
    this.OCDStateform.reset({
      name: '',
      shortdesc: '',
      projectStateTypeId: '',
      orders: '',
      createdDate: '',
      createdBy: '',
      updatedDate: '',
      updatedBy: '',
      isActive: '',
      isFinal: ''
    })
    this.projectUserArrselect=[];
    this.certifierForm = this.formBuilder.group({
      domainId :'',
      id: null,
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: '',
      isActive: '',
      createdBy: '',
      gender: [],
      createdDate: '',
      updatedBy: '',
    });
    this.buidingArrselect = [];
    this.projectDepended = [];
    this.projectPredecessor = [];
  }
  public passwordresetForm() {
    this.resetpasswordErr = null;
    this.confirmpasswordErr = null;
    this.passwordForm.reset({
       password: '',
       confirmPassword: '',
     })
   }
  subJobResetForm(){
    this.subId = null;
    this.subJobform.reset({
      id: '',
      name: '',
      shortdesc: '',
      parentJobId: '',
      orders: '',
      createdDate: '',
      createdBy: '',
      updatedDate: '',
      updatedBy: '',
      isActive: '',
      isFinal: '',
      daysDuration:null,
      projectTypeId:'',
      dependedJobId:'',
      predecessorJobId:'',
      notifybefore: [],
      notifyMsg :'',
      defaultSupervisor:[]
    });
  }
  certifierValidate() {
    this.fnameErr = null;
    this.lnameErr = null;
    this.genderErr = null;
    this.certifiemailErr = null;
    this.phoneErr = null;
    var data = this.certifierForm.value;
    const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const validNumber = /^\d+$/;
    const strongRegex = /(?=.{6,})/;
    var valid = true;
    if (data.firstName == undefined || data.firstName == '') {
      this.fnameErr = "First Name is required.";
      valid = false;
    }
    else if (data.lastName == undefined || data.lastName == '') {
      this.lnameErr = "Last Name is required.";
      valid = false;
    }
    else if (data.email == EmailValidator || data.email == '') {
      this.certifiemailErr = "Email is required.";
      valid = false;
    } else if (!validEmailRegEx.test(data.email)) {
      this.certifiemailErr = "Invalid Email";
      valid = false;
    }else if (data.gender.length == 0) {
      this.genderErr = "Gender is Required.";
      valid = false;
    }else if(data.phone != '' && !validNumber.test(data.phone)) {
      this.phoneErr = "Invalid Phone Number";
      valid = false;
    }
    return valid;
  }
  approvalValidate() {
    this.nameErr = null;
    this.descErr = null;
    var data = this.approvalform.value;
    var valid = true;
    if (data.name == undefined || data.name == '') {
      this.nameErr = "Approval Name is required.";
      valid = false;
    }
    else if (data.shortdesc == undefined || data.shortdesc == '') {
      this.descErr = "Shortdesc Name is required.";
      valid = false;
    }
    return valid;
  }

  Dealvalidate() {
    this.nameErr = null;
    this.descErr = null;
    var data = this.dealForm.value;
    var valid = true;
    if (data.name == undefined || data.name == '') {
      this.nameErr = "Deal Name is required.";
      valid = false;
    }
    else if (data.shortdesc == undefined || data.shortdesc == '') {
      this.descErr = "Shortdesc Name is required.";
      valid = false;
    }
    return valid;
  }

  stageValidate() {
    this.nameErr = null;
    this.descErr = null;
    var data = this.stageForm.value;
    var valid = true;
    if (data.name == undefined || data.name == '') {
      this.nameErr = "Name is required.";
      valid = false;
    }
    return valid;
  }
  projectvalidate() {
    this.nameErr = null;
    this.descErr = null;
    this.daysErr = null;
     this.buildErr=null;
     this.statusErr=null;
     this.supervisorErr =null;
    var data = this.projectform.value;
    const validNumber = /^\d+$/;
    const strongRegex = /^[\d]{1,2}$/;
    var valid = true;
    if (data.name == undefined || data.name == '') {
      this.nameErr = "Project State is required.";
      valid = false;
    }
    else if (data.shortdesc == undefined || data.shortdesc == '') {
      this.descErr = "Description  is required.";
      valid = false;
    }
    else if (data.daysDuration == undefined || data.daysDuration == '') {
      this.daysErr = "Days is required.";
      valid = false;
    }
    else if (!validNumber.test(data.daysDuration)) {
      this.daysErr = "Invalid Days.";
      valid = false;
    }
       else if (data.projectTypeId.length == 0) {
      this.buildErr = "Building Type is required.";
      valid = false;
    }
    //   else if (data.dependedJobId) {
    //   if(data.predecessorJobId){
    //     if(data.dependedJobId.length != 0 &&  data.predecessorJobId.length != 0){
    //     if(data.dependedJobId[0].id == data.predecessorJobId[0].id){
    //       this.statusErr = "Depended and Predecessor Should be different.";
    //             valid = false;
    //     }
    //   }
    //   }
    // }
    if (data.dependedJobId) {
      for (let i = 0; i < data.dependedJobId.length; i++) {
        if(data.predecessorJobId != null){
          if (data.predecessorJobId.length > 0) {
            if (data.dependedJobId[i].id == data.predecessorJobId[0].id) {
              this.statusErr = "Dependent and Predecessor Should be different.";
              valid = false;
            }
          }
        }

      }
    }
    if (data.predecessorJobId) {
      for (let i = 0; i < data.dependedJobId.length; i++) {
        if (data.dependedJobId) {
          if (data.predecessorJobId.length > 0) {
          if (data.dependedJobId[i].id == data.predecessorJobId[0].id) {
            this.statusErr = "Dependent and Predecessor Should be different.";
            valid = false;
          }
        }
      }
      }
    }
    // else if (data.predecessorJobId) {
    //   if (data.dependedJobId) {
    //     if(data.dependedJobId.length != 0 &&  data.predecessorJobId.length != 0){
    //       if(data.dependedJobId[0].id == data.predecessorJobId[0].id){
    //         this.statusErr = "Depended and Predecessor Should be different.";
    //               valid = false;
    //       }
    //     }

    //   }
    // }
    if(this.supervisorShow ==  true){
      if (data.defaultSupervisor.length == 0) {
     this.supervisorErr = "Supervisor is required";
     valid = false;
   }
  }
    return valid;
  }
  updateDeals(number, data1) {
    if (number == 0) {
      var data = this.dealForm.value;
      data.updatedBy = this.userid;
      if (this.Dealvalidate()) {
        this.spinner.show();
        this.http.put('/api/projectState/', data).subscribe((response) => {

          this.toastr.clear();
          this.result = response;
          if (this.result.success) {
            this.resetForm();
            this.toastr.success(this.result.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.toggleDealMenu();
            this.getDeals();
            this.getApproval();
            this.getProject();
          } else {

            this.toastr.error(this.result.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
          }
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })

      }
    } else if (number == 1) {
      data1.orders = data1.orders + 1;
      data1.updatedBy = this.userid;
      this.http.put('/api/projectState/', data1).subscribe((response) => {
        this.toastr.clear();
        this.result = response;
        if (this.result.success) {
          this.resetForm();
          this.toastr.success(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.getDeals();
          //this.getApproval();
          this.getProject();
        } else {

          this.toastr.error(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
        this.errorService.handleError(error);
      })
    }
  }

  submitStage() {
    this.nameErr = null;
    this.descErr = null;
    var data = this.stageForm.value;
    data.id = null;
    data.domainId = this.loggedInUserDomain;
    data.isActive = "Y";
    data.createdBy = this.userid;
    if (this.stageValidate()) {
      this.spinner.show();
      this.http.get('/api/projectStage/order').subscribe((res: Response) => {
        this.order = res;
        if (this.order) {
          data.order = this.order.maxOrder+1;
          data.isDefault = "N";
          this.http.post('/api/projectStage', data).subscribe((response) => {
            this.res1 = response;
            if (this.res1.success == true) {
              this.resetForm();
              this.toastr.clear();
              this.toastr.success(this.res1.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });

              this.toggleProjectStageMenu();
              this.getProjectStages();
            } else {
              this.spinner.hide();
              this.toastr.clear();
              this.toastr.error(this.res1.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
            }
          }, error => {
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
            this.errorService.handleError(error);
          })
        }
      });

    }
  }

  updateStage() {
      var data = this.stageForm.value;
      data.updatedBy = this.userid;
      if (this.stageValidate()) {
        this.spinner.show();
        this.http.put('/api/projectStage', data).subscribe((response) => {

          this.toastr.clear();
          this.result = response;
          if (this.result.success) {
            this.resetForm();
            this.toastr.success(this.result.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.toggleProjectStageMenu();
            this.getProjectStages();
          } else {

            this.toastr.error(this.result.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
          }
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })

      }
  }

  deleteStage(id) {
    this.confirmService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) => {
        if (confirmed) {
          window.scrollTo(0, 0);
          this.spinner.show();
          this.http.delete('/api/projectStage/' + id).subscribe((response) => {
            this.toastr.clear();
            this.result = response;
            if (this.result.success) {
              this.resetForm();
              this.toastr.success(this.result.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.toggleProjectStageMenu();
              this.getProjectStages();
              this.popoverform=false;

            } else {
              this.toastr.error(this.result.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
            }

          }, error => {
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
            this.errorService.handleError(error);
          })
        } else {
        }
        this.dealsArr = [];
        this.dealsFinalArr = [];
      })
      .catch(() => {
        return false;
      });
  }

  submitDeals() {
    this.nameErr = null;
    this.descErr = null;
    var data = this.dealForm.value;
    data.createdBy = this.userid;
    if (this.Dealvalidate()) {
      this.spinner.show();
      this.http.get('/api/projectStates/deals/order').subscribe((res: Response) => {
        this.order = res;
        if (this.order) {
          data.orders = this.order.maxOrder;
          data.isFinal = "N";
          this.http.post('/api/projectStates/deals/', data).subscribe((response) => {
            this.res1 = response;
            if (this.res1.success == true) {
              this.resetForm();
              this.toastr.clear();
              this.toastr.success(this.res1.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.http.get('/api/projectState/' + this.order.id).subscribe((res: Response) => {
                this.result1 = res;
                this.updateDeals(1, this.result1);
              })
              this.toggleDealMenu();
              this.getDeals();
              this.dealsArr = [];
              this.dealsFinalArr = [];
            } else {
              this.spinner.hide();
              this.toastr.clear();
              this.toastr.error(this.res1.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
            }
          }, error => {
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
            this.errorService.handleError(error);
          })
        }
      });

    }
  }


  submitApproval(type: string) {
    this.nameErr = null;
    this.descErr = null;
    var data = this.approvalform.value;
    data.createdBy = this.userid;
    if (this.approvalValidate()) {
      this.spinner.show();
      this.http.get('/api/projectStates/' + type + '/order').subscribe((res: Response) => {
        this.order = res;
        if (this.order) {
          data.orders = (this.order.maxOrder) + 1;
          this.http.post('/api/projectStates/' + type, data).subscribe((response) => {
            this.res1= response;
            if (this.res1.success == true) {
              this.resetForm();
              this.toastr.clear();
              this.toastr.success(this.res1.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.toggleApprovalMenu();
              this.approvalArr = [];
              if (type == 'approvals') {
                this.getApproval();
              } else if (type == 'cc') {
                this.getCC();
              } else if (type == 'subdivision') {
                this.getSubDivision();
              }

            } else {
              this.spinner.hide();
              this.toastr.clear();
              this.toastr.error(this.res1.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
            }
          }, error => {
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
            this.errorService.handleError(error);
          })

        }
      });
    }
  }
   getprojectStatus(id) {
    this.projectStatus=[];
        this.projectStatusDep=[];
      this.http.get('/api/projectStateDropDown/'+id).subscribe((res: Response) => {
          this.result = res;
           for (let i = 0; i < this.result.predecessorStateArray.length; i++) {
            if(id != this.result.predecessorStateArray[i].id){
                   this.projectStatus.push({  "id":  this.result.predecessorStateArray[i].id, "itemName":  this.result.predecessorStateArray[i].name,})
            }
          }
          for (let j = 0; j < this.result.dependedStateArray.length;j++) {
            if(id != this.result.dependedStateArray[j].id){
                   this.projectStatusDep.push({  "id":  this.result.dependedStateArray[j].id, "itemName":  this.result.dependedStateArray[j].name,})
            }
          }
      });
   }


  submitProject() {
    this.nameErr = null;
    this.descErr = null;
    var data = this.projectform.value;
    var notifiyArr = [];
    data.createdBy = parseInt(this.userid);
    if (this.projectvalidate()) {
      this.spinner.show();
       if(data.notifybefore != null){
        if(data.notifybefore.length > 0){
          for (let i = 0; i < data.notifybefore.length; i++) {
            notifiyArr.push(data.notifybefore[i].id);
          }
          data.notifybefore = notifiyArr;
        }else{
          data.notifybefore = [1];
        }
      }else{
        data.notifybefore = [1];
      }
      this.http.get('/api/projectStates/projects/order').subscribe((res: Response) => {
        this.order = res;
        if (this.order) {
          data.orders = (this.order.maxOrder) + 1;
             var cusr = [];
          if(data.projectTypeId.length > 0){
            for(let i = 0; i < data.projectTypeId.length; i++){
              cusr.push(data.projectTypeId[i].id);
            }
             data.projectTypeId = cusr;
          }
            if(data.defaultAssignee.length > 0){
                data.defaultAssignee = data.defaultAssignee[0].id;
           }else{
            data.defaultAssignee = null;
           }
             if(data.dependedJobId != null){
              if(data.dependedJobId.length > 0){
              data.dependedJobId = data.dependedJobId[0].id;
            }else{
              data.dependedJobId = null;
            }
          }
            if(data.predecessorJobId != null){
           if(data.predecessorJobId.length > 0){
             data.predecessorJobId = data.predecessorJobId[0].id;
          }else{
              data.predecessorJobId = null;

           }
            }
            if (this.supervisorShow == true) {
              if (data.defaultSupervisor.length != 0) {
                data.defaultSupervisor = data.defaultSupervisor[0].id;
              }
            }else{
              data.defaultSupervisor = null;
            }
           data.daysDuration = parseInt(data.daysDuration);
          this.http.post('/api/projectStates/projects/', data).subscribe((response) => {
            this.res1 = response;
            if (this.res1.success == true) {
              this.resetForm();
              this.toastr.clear();
              this.toastr.success(this.res1.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.toggleProjectMenu();
              this.getProject();
              this.projectArr = [];
            } else {
              this.spinner.hide();
              this.toastr.clear();
              this.toastr.error(this.res1.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
            }
          }, error => {
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
            this.errorService.handleError(error);
          })

        }
      });
    }
  }
  toggleProjectEditMenu(id) {
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.supervisorShow = false;
    this.subjobtable =true;
    this.nameErr = null;
    this.descErr = null;
    this.stateErr = null;
    this.projectStatus=[];
    this.projectStatusDep=[];
    this.getprojectStatus(id);
    this.buidingArrselect=[];
    this.projectUserArrselect=[];
    this.projectDepended = [];
    this.projectPredecessor = [];
    this.projectSupervisorArrSelect = [];
    this.subStatesArr = [];
    this.buildErr=null;
    this.statusErr=null;
    this.isCreate = false;
    this.id = id;
    this.parentId=id;
     this.http.get('/api/projectState/project/'+ this.id).subscribe((res: Response) => {
      this.result1 = res;
      if (this.result1) {
        var result =this.result1.state;
        var typeval =this.result1.types;
         var subStates= this.result1.substates;
         if(subStates.length > 0){
          for (let i = 0; i < subStates.length; i++) {
              this.subStatesArr.push({
                "id": subStates[i].id,
                "name": subStates[i].name,
                "daysDuration": subStates[i].daysDuration,
                "shortdesc": subStates[i].shortdesc
              })
            }
        }
           if (result.notifybefore.length > 0) {
          this.noftificationArrSelect = [];
        for (let i = 0; i < result.notifybefore.length; i++) {
            if (result.notifybefore[i] == "1") {
              this.noftificationArrSelect.push({ "id": 1, "itemName": "3 Days before" })
            } else if (result.notifybefore[i]  == "2") {
              this.noftificationArrSelect.push({ "id": 2, "itemName": "7 Days Before" })
            } else if (result.notifybefore[i]  == "3") {
              this.noftificationArrSelect.push({ "id": 3, "itemName": "15 Days Before" })
            } else if (result.notifybefore[i]  == "4") {
              this.noftificationArrSelect.push({ "id": 4, "itemName": "30 Days Before" })
            }
           }
             } else {
          this.noftificationArrSelect = [];
        }
        if(typeval.length > 0){
          for (let i = 0; i < typeval.length; i++) {
            for (let j = 0; j < this.buidingArr.length; j++) {
              if(typeval[i] == this.buidingArr[j].id){
              this.buidingArrselect.push({
                "id": this.buidingArr[j].id, "itemName": this.buidingArr[j].itemName,
              })
              }
            }
            }
        }
        if(result.defaultAssignee != null){
          this.projectAllUsersArr.forEach(r => {
            if(r.id == result.defaultAssignee)
            this.projectUserArrselect.push({
              "id": r.id, "itemName": r.itemName
            })
          });
        }else{
          this.projectform.patchValue({ 'defaultAssignee': result.defaultAssignee });
        }
        if(result.defaultSupervisor != null){
          this.supervisorShow = true;
          this.projectAllUsersArr.forEach(r => {
            if(r.id == result.defaultSupervisor)
            this.projectSupervisorArrSelect.push({
              "id": r.id, "itemName": r.itemName
            })
          });
        }else{
          this.projectSupervisorArrSelect=[];
          this.supervisorShow = false;
          this.projectform.patchValue({ 'defaultSupervisor': result.defaultSupervisor });
        }
          //   if(result.dependedJobId != null){
          //       this.projectArr.forEach(r => {
          //         if(r.id == result.dependedJobId){
          //           this.projectDepended.push({
          //             "id": r.id, "itemName": r.name
          //           })
          //         }
          //   });
          // }else{
          //    this.projectform.patchValue({ 'dependedJobId': result.dependedJobId });
          // }
          if (result.dependedJobId != null) {
            this.projectArr.forEach(r => {
              for (let j = 0; j <result.dependedJobId.length; j++) {
                if (r.id == result.dependedJobId[j]) {
                  this.projectDepended.push({
                    "id": r.id, "itemName": r.name
                  })
                }
              }
            });
          } else {
            this.projectform.patchValue({ 'dependedJobId': this.result.dependedJobId });
          }
          if(result.predecessorJobId != null){
                this.projectArr.forEach(r => {
                  if(r.id == result.predecessorJobId){
                    this.projectPredecessor.push({
                      "id": r.id, "itemName": r.name
                     })
                  }
            });
          }else{
             this.projectform.patchValue({ 'predecessorJobId': result.predecessorJobId });
          }

      //  this.buidingArrselect.push({ "id": result.id, "itemName": result.type })
        this.projectform.patchValue({ 'id': result.id });
        this.projectform.patchValue({ 'name': result.name });
        this.projectform.patchValue({ 'shortdesc': result.shortdesc });
        this.projectform.patchValue({ 'projectStateTypeId': result.projectStateTypeId });
        this.projectform.patchValue({ 'orders': result.orders });
        this.projectform.patchValue({ 'createdDate': result.createdDate });
        this.projectform.patchValue({ 'createdBy': result.createdBy });
        this.projectform.patchValue({ 'updatedDate': result.updatedDate });
        this.projectform.patchValue({ 'updatedBy': result.updatedBy });
        this.projectform.patchValue({ 'isActive': result.isActive });
        this.projectform.patchValue({ 'isFinal': result.isFinal });
        this.projectform.patchValue({ 'daysDuration': result.daysDuration });
        this.projectform.patchValue({ 'domainId': result.domainId });
        this.projectform.patchValue({ 'notifyMsg': result.notifyMsg });
      }
    });
    this.menuStateProject = this.menuStateProject === 'out' ? 'in' : 'out';
  }
  closeProject() {
    this.menuStateProject = this.menuStateProject === 'out' ? 'in' : 'out';

  }
  updateProject() {
    var data = this.projectform.value;
    data.updatedBy = parseInt(this.userid);
    if (this.projectvalidate()) {
      window.scrollTo(0, 0);
      this.spinner.show();
          var cusr = [];
      if(data.projectTypeId.length > 0){
        for(let i = 0; i < data.projectTypeId.length; i++){
          cusr.push(data.projectTypeId[i].id);
        }
         data.projectTypeId = cusr;
      }
         if(data.defaultAssignee != null){
              if(data.defaultAssignee.length > 0){
                data.defaultAssignee = data.defaultAssignee[0].id;
              }
                else{
                data.defaultAssignee = null;
              }
           }
            // if(data.dependedJobId != null){
            //   if(data.dependedJobId.length > 0){
            //         data.dependedJobId = data.dependedJobId[0].id;
            //   }else{
            //     data.dependedJobId = null;
            //   }
            //  }
            var dependedJobArr = [];
            if (data.dependedJobId != null) {
              if (data.dependedJobId.length > 0) {
                for (let i = 0; i < data.dependedJobId.length; i++) {
                  dependedJobArr.push(data.dependedJobId[i].id);
                }
                data.dependedJobId = dependedJobArr;
              } else {
                data.dependedJobId = [];
              }
            }
         if(data.predecessorJobId != null){
           if(data.predecessorJobId.length > 0){
             data.predecessorJobId = data.predecessorJobId[0].id;
          }else{
            data.predecessorJobId = null;

          }
         }
              var notifiyArr =[];
         if(data.notifybefore != null){
          if(data.notifybefore.length > 0){
            for (let i = 0; i < data.notifybefore.length; i++) {
              notifiyArr.push(data.notifybefore[i].id);
            }
            data.notifybefore = notifiyArr;
          }else{
            data.notifybefore = [1];
          }
        }else{
          data.notifybefore = [1];
        }
        if (this.supervisorShow == true) {
          if (data.defaultSupervisor.length != 0) {
            data.defaultSupervisor = data.defaultSupervisor[0].id;
          }
        }else{
          data.defaultSupervisor = null;
        }
      data.daysDuration = parseInt(data.daysDuration);
      this.http.put('/api/projectStates/projects', data).subscribe((response) => {
        this.toastr.clear();
        this.result = response
        if (this.result.success) {
          this.resetForm();
          this.toastr.success(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.closeProject();
          this.getDeals();
          this.getApproval();
          this.getProject();
          this.popoverform=false;
        } else {
          this.toastr.error(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
        this.errorService.handleError(error);
      })

    }
  }
  async getBuildingtype() {
    this.projectUserArr =[];
     this.result1 = await this.DetailsService.getUsers();
   this.result1.forEach(r => {
          this.projectUserArr.push({
              "id": r.details.id, "itemName": r.details.firstName + " " + r.details.lastName + " - " + r.role.name, "roleId": r.role.id, "roleName": r.role.name
            })

      });
    this.buidingArr = [];
    this.http.get('/api/projectTypes').subscribe((res: Response) =>
    {
      this.result = res;
      for (let i = 0; i <      this.result .length; i++) {

        this.buidingArr.push({
          "id":this.result [i].id, "itemName":      this.result [i].type,
        })
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
   }
    dealStateDelete(id){
    this.http.get('/api/projectStates/dealsCount/' + id).subscribe((response) => {
     this.result1 = response;
      if( this.result1.count == 0){
        this.confirmService.confirm('Please confirm..', 'Do you really want to delete ?')
        .then((confirmed) => {
          if (confirmed) {
            window.scrollTo(0, 0);
            this.spinner.show();
            this.http.delete('/api/projectState/' + id).subscribe((response) => {
              this.toastr.clear();
              this.result = response;
              if (this.result.success) {
                this.resetForm();
                this.toastr.success(this.result.message, 'Report Status', {
                  timeOut: 6000,
                  closeButton: true
                });
                this.getDeals();
              } else {
                this.toastr.error(this.result.message, 'Report Status', {
                  timeOut: 6000,
                  closeButton: true
                });
              }
              this.spinner.hide();
            }, error => {
              this.toastr.error(error, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
              this.errorService.handleError(error);
            })
          }
          this.dealsArr = [];
          this.dealsFinalArr = [];
        })
        .catch(() => {
          return false;
        });
      }else{
         this.confirmService.confirm('Do not Delete State..! There are '+ this.result1.count+' Projects',  this.result1.projects)
        .then((confirmed) => {
          if (confirmed) {
            return false;
          }

        });
      }
    })
   }
  delete(id) {
    this.confirmService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) => {
        if (confirmed) {
          window.scrollTo(0, 0);
          this.spinner.show();
          this.http.delete('/api/projectState/' + id).subscribe((response) => {
            this.toastr.clear();
            this.result = response;
            if (this.result.success) {
              this.resetForm();
              this.toastr.success(this.result.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.getDeals();
              this.getApproval();
              this.toggleDealMenu();
              this.toggleApprovalMenu();
              this.toggleProjectMenu();
              this.popoverform=false;

              this.getProject();
            } else {
              this.toastr.error(this.result.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
            }

          }, error => {
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
            this.errorService.handleError(error);
          })
        } else {
        }
        this.dealsArr = [];
        this.dealsFinalArr = [];
      })
      .catch(() => {
        return false;
      });
  }
  deleteApproval(id, type) {
    this.confirmService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) => {
        if (confirmed) {
          window.scrollTo(0, 0);
          this.spinner.show();
          this.http.delete('/api/projectState/' + id).subscribe((response) => {
            this.toastr.clear();
            this.result = response;
            if (this.result.success) {
              this.resetForm();
              this.toastr.success(this.result.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              //this.getDeals();
              this.approvalArr = [];
              if (type == 'approvals') {
                this.getApproval();
              } else if (type == 'cc') {
                this.getCC();
              } else if (type == 'subdivision') {
                this.getSubDivision();
              }
              this.toggleDealMenu();
              this.toggleApprovalMenu();
              this.toggleProjectMenu();
              this.popoverform=false;

              //this.getProject();
            } else {
              this.toastr.error(this.result.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
            }

          }, error => {
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
            this.errorService.handleError(error);
          })
        } else {
        }
        this.dealsArr = [];
        this.dealsFinalArr = [];
      })
      .catch(() => {
        return false;
      });
  }

  approvalEditMenu(id) {
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.nameErr = null;
    this.descErr = null;
    this.stateErr = null;
    this.isCreate = false;
    this.id = id;
    this.http.get('/api/projectState/' + this.id).subscribe((res: Response) => {
      this.result = res;
      if (this.result) {
        this.approvalform.patchValue({ 'id': this.result.id });
        this.approvalform.patchValue({ 'name': this.result.name });
        this.approvalform.patchValue({ 'shortdesc': this.result.shortdesc });
        this.approvalform.patchValue({ 'projectStateTypeId': this.result.projectStateTypeId });
        this.approvalform.patchValue({ 'orders': this.result.orders });
        this.approvalform.patchValue({ 'createdDate': this.result.createdDate });
        this.approvalform.patchValue({ 'createdBy': this.result.createdBy });
        this.approvalform.patchValue({ 'updatedDate': this.result.updatedDate });
        this.approvalform.patchValue({ 'updatedBy': this.result.updatedBy });
        this.approvalform.patchValue({ 'isActive': this.result.isActive });
        this.approvalform.patchValue({ 'isFinal': this.result.isFinal });
        this.approvalform.patchValue({ 'domainId': this.result.domainId });
      }
    });
    this.menuStateApproval = this.menuStateApproval === 'out' ? 'in' : 'out';
  }
  updateApproval(type: string) {
    var data = this.approvalform.value;
    data.updatedBy = this.userid;
    if (this.approvalValidate()) {
      window.scrollTo(0, 0);
      this.spinner.show();
      this.http.put('/api/projectState/', data).subscribe((response) => {
        this.toastr.clear();
        this.result = response;
        if (this.result.success) {
          this.resetForm();
          this.toastr.success(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.toggleApprovalMenu();
          //this.getDeals();
          this.approvalArr = [];
          if (type == 'approvals') {
            this.getApproval();
          } else if (type == 'cc') {
            this.getCC();
          } else if (type == 'subdivision') {
            this.getSubDivision();
          }
          //this.getProject();
        } else {

          this.toastr.error(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
        this.errorService.handleError(error);
      })

    }
  }

  dealEditMenu(id) {
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.nameErr = null;
    this.descErr = null;
    this.stateErr = null;
    this.isCreate = false;
    this.id = id;
    this.http.get('/api/projectState/' + this.id).subscribe((res: Response) => {
      this.result = res;
      if (this.result) {
        this.dealForm.patchValue({ 'id': this.result.id });
        this.dealForm.patchValue({ 'name': this.result.name });
        this.dealForm.patchValue({ 'shortdesc': this.result.shortdesc });
        this.dealForm.patchValue({ 'projectStateTypeId': this.result.projectStateTypeId });
        this.dealForm.patchValue({ 'orders': this.result.orders });
        this.dealForm.patchValue({ 'createdDate': this.result.createdDate });
        this.dealForm.patchValue({ 'createdBy': this.result.createdBy });
        this.dealForm.patchValue({ 'updatedDate': this.result.updatedDate });
        this.dealForm.patchValue({ 'updatedBy': this.result.updatedBy });
        this.dealForm.patchValue({ 'isActive': this.result.isActive });
        this.dealForm.patchValue({ 'isFinal': this.result.isFinal });
        this.dealForm.patchValue({ 'domainId': this.result.domainId });
        if(this.result.isFinal == 'Y' || this.result.orders == 1){
          this.dealfromDis =true;
        }else{
          this.dealfromDis =false;
        }
      }
    });

    this.menuStateDeal = this.menuStateDeal === 'out' ? 'in' : 'out';
  }

  projectStageEditMenu(id) {
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.nameErr = null;
    this.descErr = null;
    this.isCreate = false;
    this.id = id;
    this.http.get('/api/projectStage/' + this.id).subscribe((res: Response) => {
      this.result = res;
      if (this.result) {
        this.stageForm.patchValue({ 'id': this.result.id });
        this.stageForm.patchValue({ 'name': this.result.name });
        this.stageForm.patchValue({ 'description': this.result.description });
        this.stageForm.patchValue({ 'projectStateTypeId': this.result.projectStateTypeId });
        this.stageForm.patchValue({ 'order': this.result.order });
        this.stageForm.patchValue({ 'createdDate': this.result.createdDate });
        this.stageForm.patchValue({ 'createdBy': this.result.createdBy });
        this.stageForm.patchValue({ 'updatedDate': this.result.updatedDate });
        this.stageForm.patchValue({ 'updatedBy': this.result.updatedBy });
        this.stageForm.patchValue({ 'isActive': this.result.isActive });
        this.stageForm.patchValue({ 'isDefault': this.result.isDefault });
        this.stageForm.patchValue({ 'domainId': this.result.domainId });
        if(this.result.isDefault == 'Y' || this.result.order == 0){
          this.dealfromDis =true;
        }else{
          this.dealfromDis =false;
        }
      }
    });

    this.menuProjectStage = this.menuProjectStage === 'out' ? 'in' : 'out';
  }

  EditDealsOrder() {
    window.scrollTo(0, 0);
    this.spinner.show();
    for (let i = 0; i < this.dealsUpdateArr.length; i++) {
      var ord = 0;
      this.http.get('/api/projectState/' + this.dealsUpdateArr[i]).subscribe((res: Response) => {
        this.result = res;
        if (this.result) {
          this.dealForm.patchValue({ 'id': this.result.id });
          this.dealForm.patchValue({ 'name': this.result.name });
          this.dealForm.patchValue({ 'shortdesc': this.result.shortdesc });
          this.dealForm.patchValue({ 'projectStateTypeId': this.result.projectStateTypeId });
          this.dealForm.patchValue({ 'createdDate': this.result.createdDate });
          this.dealForm.patchValue({ 'createdBy': this.result.createdBy });
          this.dealForm.patchValue({ 'updatedDate': this.result.updatedDate });
          this.dealForm.patchValue({ 'updatedBy': this.result.updatedBy });
          this.dealForm.patchValue({ 'isActive': this.result.isActive });
        }
        var data = this.dealForm.value;
        data.orders = i + 2;
        data.isFinal = "N";
        this.http.put('/api/projectState/', data).subscribe((response) => {

          this.toastr.clear();
          this.result = response;
          if (this.result.success) {
            if (i + 1 == this.dealsUpdateArr.length) {
              this.resetForm();
              this.toastr.success(this.result.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
            }
            this.isadd = false;
          } else {

            this.toastr.error(this.result.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });

          }
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })
      });

    }
  }
  canclDealOrders() {
    this.dealsArr = [];
    this.dealsFinalArr = [];
    this.getDeals();
    this.isadd = false;

  }

  editStageOrder() {
    window.scrollTo(0, 0);
    this.spinner.show();
    for (let i = 0; i < this.stageUpdateArr.length; i++) {
      var ord = 0;
      this.http.get('/api/projectStage/' + this.stageUpdateArr[i]).subscribe((res: Response) => {
        this.result = res;
        if (this.result) {
          this.result["order"] = i + 1;
          this.http.put('/api/projectStage/', this.result).subscribe((response) => {
            if (i + 1 == this.stageUpdateArr.length) {
              this.toastr.success("Reorder Successfull", 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
            }
            this.isstageadd = false;
          }, error => {
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
            this.errorService.handleError(error);
          });
        }
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        });
      }
      this.toastr.clear();
  }
  cancleStageOrder() {
    this.projectStagesArr = [];
    this.projectStageDefaultArr = [];
    this.getProjectStages();
    this.isstageadd = false;

  }

  async editOcdOrder() {
    this.spinner.show();
    for (let i = 0; i < this.ocdUpdateArr.length; i++) {
      var ord = 0;
       var res = await this.getstatusdetails(this.ocdUpdateArr[i]);
            // this.http.get('/api/projectState/' + this.approvalUpdateArr[i]).subscribe((res: Response) => {
        this.result = res;
        if (this.result) {
          this.OCDStateform.patchValue({ 'id': this.result.id });
          this.OCDStateform.patchValue({ 'name': this.result.name });
          this.OCDStateform.patchValue({ 'shortdesc': this.result.shortdesc });
          this.OCDStateform.patchValue({ 'projectStateTypeId': this.result.projectStateTypeId });
          this.OCDStateform.patchValue({ 'createdDate': this.result.createdDate });
          this.OCDStateform.patchValue({ 'createdBy': this.result.createdBy });
          this.OCDStateform.patchValue({ 'updatedDate': this.result.updatedDate });
          this.OCDStateform.patchValue({ 'updatedBy': this.result.updatedBy });
          this.OCDStateform.patchValue({ 'isActive': this.result.isActive });
        }
        var data = this.OCDStateform.value;
        data.orders = i + 1;
        data.isFinal = "N";
        data.projectTypeName = this.projectTypeSelectedArr[0].id;
        this.http.put('/api/projectState/', data).subscribe((response) => {

          this.toastr.clear();
          this.result = response;
          if (this.result.success) {
            if (i + 1 == this.ocdstatusArr.length) {
              this.resetForm();
              this.toastr.success(this.result.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
            }
            this.isadd = false;
          } else {

            this.toastr.error(this.result.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });

          }
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })
    }
    this.isocdorderupdated = false;
  }
  cancleOcdOrder() {
    this.ocdstatusArr = [];
    this.getOCDStatus();
    this.isocdorderupdated = false;
  }
  getstatusdetails(id){
    return this.http.get('/api/projectState/'+id).toPromise();
  }
   getProjectstatusdetails(id){
    return this.http.get('/api/projectState/project/'+id).toPromise();
  }
  async EditApprovalsOrder() {
    this.spinner.show();
    for (let i = 0; i < this.approvalUpdateArr.length; i++) {
      var ord = 0;
       var res = await this.getstatusdetails(this.approvalUpdateArr[i]);
            // this.http.get('/api/projectState/' + this.approvalUpdateArr[i]).subscribe((res: Response) => {
        this.result = res;
        if (this.result) {
          this.approvalform.patchValue({ 'id': this.result.id });
          this.approvalform.patchValue({ 'name': this.result.name });
          this.approvalform.patchValue({ 'shortdesc': this.result.shortdesc });
          this.approvalform.patchValue({ 'projectStateTypeId': this.result.projectStateTypeId });
          this.approvalform.patchValue({ 'createdDate': this.result.createdDate });
          this.approvalform.patchValue({ 'createdBy': this.result.createdBy });
          this.approvalform.patchValue({ 'updatedDate': this.result.updatedDate });
          this.approvalform.patchValue({ 'updatedBy': this.result.updatedBy });
          this.approvalform.patchValue({ 'isActive': this.result.isActive });
        }
        var data = this.approvalform.value;
        data.orders = i + 1;
        data.isFinal = "N";
        this.http.put('/api/projectState/', data).subscribe((response) => {

          this.toastr.clear();
          this.result = response;
          if (this.result.success) {
            if (i + 1 == this.approvalUpdateArr.length) {
              this.resetForm();
              this.toastr.success(this.result.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
            }
            this.isadd = false;
          } else {

            this.toastr.error(this.result.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });

          }
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })
    }
  }
  canclapprovalOrders(type: string){
    this.approvalArr = [];
    if (type == 'approvals') {
      this.getApproval();
    } else if (type == 'cc') {
      this.getCC();
    } else if (type == 'subdivision') {
      this.getSubDivision();
    }
    this.isapp = false;
  }
   async EditprojectOrder() {
    this.spinner.show();
    for (let i = 0; i < this.projectUpdateArr.length; i++) {
      this.projectUserArrselect=[];
       this.projectDepended = [];
    this.projectPredecessor = [];
      var res = await this.getProjectstatusdetails(this.projectUpdateArr[i]);
      this.result2 = res;
        if (this.result2) {
          this.result =this.result2.state;
        var typeval =this.result2.types;
          this.projectform.patchValue({ 'id': this.result.id });
          this.projectform.patchValue({ 'name': this.result.name });
          this.projectform.patchValue({ 'shortdesc': this.result.shortdesc });
          this.projectform.patchValue({ 'projectStateTypeId': this.result.projectStateTypeId });
          this.projectform.patchValue({ 'orders': this.result.orders });
          this.projectform.patchValue({ 'createdDate': this.result.createdDate });
          this.projectform.patchValue({ 'createdBy': this.result.createdBy });
          this.projectform.patchValue({ 'updatedDate': this.result.updatedDate });
          this.projectform.patchValue({ 'updatedBy': this.result.updatedBy });
          this.projectform.patchValue({ 'isActive': this.result.isActive });
          this.projectform.patchValue({ 'isFinal': this.result.isFinal });
          this.projectform.patchValue({ 'daysDuration': this.result.daysDuration });

        if(typeval.length > 0){
          for (let i = 0; i < typeval.length; i++) {
            for (let j = 0; j < this.buidingArr.length; j++) {
              if(typeval[i] == this.buidingArr[j].id){
              this.buidingArrselect.push({
                "id": this.buidingArr[j].id, "itemName": this.buidingArr[j].itemName,
              })
              }
            }
            }
        }
          if(this.result.defaultAssignee != null){
                this.projectUserArr.forEach(r => {
                  if(r.id == this.result.defaultAssignee){
                      this.projectUserArrselect.push({
                    "id": r.id, "itemName": r.itemName
                  })
                }
            });
          }else{
             this.projectform.patchValue({ 'defaultAssignee':this.result.defaultAssignee });
          }
                // if(this.result.dependedJobId != null){
                //           this.projectArr.forEach(r => {
                //             if(r.id == this.result.dependedJobId)
                //             this.projectDepended.push({
                //               "id": r.id, "itemName": r.name
                //             })
                //       });
                //     }else{
                //       this.projectform.patchValue({ 'dependedJobId': this.result.dependedJobId });
                //     }
                if (this.result.dependedJobId != null) {
                  this.projectArr.forEach(r => {
                    for (let j = 0; j < this.result.dependedJobId.length; j++) {
                      if (r.id == this.result.dependedJobId[j]) {
                        this.projectDepended.push({
                          "id": r.id, "itemName": r.name
                        })
                      }
                    }
                  });
                } else {
                  this.projectform.patchValue({ 'dependedJobId': this.result.dependedJobId });
                }
                    if(this.result.predecessorJobId != null){
                          this.projectArr.forEach(r => {
                            if(r.id == this.result.predecessorJobId)
                            this.projectPredecessor.push({
                            "id": r.id, "itemName": r.name
                            })
                      });
                    }else{
                      this.projectform.patchValue({ 'predecessorJobId': this.result.predecessorJobId });
                    }
        }
        var data = this.projectform.value;
           var cusr = [];
          if(data.projectTypeId.length > 0){
            for(let i = 0; i < data.projectTypeId.length; i++){
              cusr.push(data.projectTypeId[i].id);
            }
             data.projectTypeId = cusr;
          }
        if(data.defaultAssignee != null){
              if(data.defaultAssignee.length != 0){
                data.defaultAssignee = data.defaultAssignee[0].id;
              }
           }
           if(data.dependedJobId != null){
              if(data.dependedJobId.length != 0){
                    data.dependedJobId = data.dependedJobId[0].id;
              }
             }
         if(data.predecessorJobId != null){
           if(data.predecessorJobId.length != 0){
             data.predecessorJobId = data.predecessorJobId[0].id;
          }
         }
        data.orders = i + 1;
        data.isFinal = "N";
        this.http.put('/api/projectState/', data).subscribe((response) => {
          this.toastr.clear();
          this.result = response;
          if (this.result.success) {
            if (i + 1 == this.projectUpdateArr.length) {
              this.resetForm();
              this.toastr.success(this.result.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
            this.isproj = false;
            this.projectArr = [];
            this.getProject();
            }
          } else {
            this.toastr.error(this.result.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
          }
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })
    }
  }
  canclprojectOrders(){
    this.projectArr = [];
    this.getProject();
    this.isproj = false;
  }

  toggleEmail() {
    this.emailErr = null;
    this.isCreate = true;
    this.resetForm();
    this.menuStateEmail = this.menuStateEmail === 'out' ? 'in' : 'out';
  }

  submitEmail() {
    if (this.emailValidate()) {
      let data = this.emailForm.value;
      this.http.post('api/email/', this.userid, data).subscribe((response) => {
        this.res1 = response;
        if (this.res1.success == true) {
          this.resetForm();
          this.toastr.clear();
          this.toastr.success(this.res1.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.getDeals();
          this.getApproval();
          this.getProject();
        } else {
          this.spinner.hide();
          this.toastr.clear();
          this.toastr.error(this.res1.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
        this.errorService.handleError(error);
      })
      this.dealsArr = [];
      this.dealsFinalArr = [];

    }
  }
  emailValidate() {
    this.emailErr = null;
    var valid = true;
    var data = this.emailForm.value;
    const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (data.email == EmailValidator || data.email == '') {
      this.emailErr = "Email is required.";
      valid = false;
    } else if (!validEmailRegEx.test(data.email)) {
      this.emailErr = "Invalid Email";
      valid = false;
    }
    return valid;
  }


  switchuser() {
    this.confirmService.confirm(' Remove Account ..', 'Do you want remove account ?')
      .then((confirmed) => {
        if (confirmed) {
          this.spinner.show();
          this.check(1);
          this.syncheck = false;
        }
        else {
        }
        this.spinner.hide();
      })
      .catch(() => {
        return false;
      });
  }

  getEmailSync() {
    this.confirmService.confirm(' Add Google Account..', 'Do you want Add your Account?')
      .then((confirmed) => {
        if (confirmed) {
          this.http.get('/api/mail/gsync/' + this.userid).subscribe((res: Response) => {
            this.result = res;
            if (this.result) {
              window.open(this.result.token, "_self");
            }
          })
        }

      })
      .catch(() => {
        return false;
      });

  }

  getCertifier() {
    this.spinner.show();
    this.certifierArr = [];
    this.http.get('/api/certifiers/').subscribe((res: Response) => {
      this.result = res;
      this.spinner.hide();
      for (let i = 0; i < this.result.length; i++) {
        this.certifierArr.push({
          "id": this.result[i].id, "firstName": this.result[i].firstName, "lastName": this.result[i].lastName, "email": this.result[i].email, "phone": this.result[i].phone
        })
      }
    });
  }
  submitCertifier() {
    var data = this.certifierForm.value;
    data.createdBy = this.userid;
    if (this.certifierValidate()) {
      this.spinner.show();
      data.gender = data.gender[0].itemName;
      this.http.post('/api/certifier/', data).subscribe((response) => {
        this.toastr.clear();
        this.result = response;
        if (this.result.success) {
          this.resetForm();
          this.toastr.success(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.toggleCertifierMenu();
          this.getCertifier();
        } else {
          this.toastr.error(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }

      }, error => {
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
        this.errorService.handleError(error);
      })
    }

  }
  certifierEditMenu(id) {
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.nameErr = null;
    this.lnameErr = null;
    this.emailErr = null;
    this.isCreate = false;
    this.certifierID = id;
    this.http.get('/api/certifier/' + this.certifierID).subscribe((res: Response) => {
      this.result = res;
      if (this.result) {
        this.certifierForm.patchValue({ 'id': this.result.id });
        this.certifierForm.patchValue({ 'firstName': this.result.firstName });
        this.certifierForm.patchValue({ 'lastName': this.result.lastName });
        this.certifierForm.patchValue({ 'email': this.result.email });
        this.certifierForm.patchValue({ 'phone': this.result.phone });
        this.certifierForm.patchValue({ 'createdBy': this.result.createdBy });
        this.certifierForm.patchValue({ 'isActive': this.result.isActive });
        this.certifierForm.patchValue({ 'createdDate': this.result.createdDate });
        this.certifierForm.patchValue({ 'domainId': this.result.domainId });
        if (this.result.gender == "Male") {
          this.selectedItems = [];
          this.selectedItems.push({ "id": 1, "itemName": "Male" })
        } else if (this.result.gender == "Female") {
          this.selectedItems = [];
          this.selectedItems.push({ "id": 2, "itemName": "Female" })
        }
        else if (this.result.details.gender == "Others") {
          this.selectedItems = [];
          this.selectedItems.push({ "id": 3, "itemName": "Others" })
        }
      }
    });
    this.menuStateCertifier = this.menuStateCertifier === 'out' ? 'in' : 'out';
  }
  updateCertifier() {
    var data = this.certifierForm.value;
    data.updatedBy = this.userid;
    if (this.certifierValidate()) {
      this.spinner.show();
      data.gender = data.gender[0].itemName;
      this.http.put('/api/certifier/', data).subscribe((response) => {
        this.toastr.clear();
        this.result = response;
        if (this.result.success) {
          this.resetForm();
          this.toastr.success(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.toggleCertifierMenu();
          this.getCertifier();
          this.popoverform=false;
        } else {

          this.toastr.error(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }

      }, error => {
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
        this.errorService.handleError(error);
      })
    }
  }
  certifierDelete(id) {
    this.confirmService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) => {
        if (confirmed) {
          this.spinner.show();
          this.http.delete('/api/certifier/' + id).subscribe((response) => {
            this.toastr.clear();
            this.result = response;
            if (this.result.success) {
              this.resetForm();
              this.toastr.success(this.result.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.getCertifier();
              this.toggleCertifierMenu();
              this.popoverform=false;
            } else {
              this.toastr.error(this.result.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
            }
          }, error => {
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
            this.errorService.handleError(error);
          })
        } else {
        }
      })
      .catch(() => {
        return false;
      });
  }
    showSubJob(parentId){
    this.subJobResetForm();
    this.projectUserArrselectSubJob=[];
    this.isSubJobCreate = false;
    this.supervisorShowSubJob = false;
    this.http.get('/api/projectStates/projects/suborder/' + parentId).subscribe((response) => {
    this.resultOrder = response;
    })
     if (this.subJob_Show_Hide == true) {
      this.subJob_Show_Hide = false;
    } else {
      this.subJob_Show_Hide = true;
    }
   }
   closeSubJob(){
    this.subJob_Show_Hide = false;
   }

   sunmitSubJob(parentId){
    var data = this.subJobform.value;
    if(this.subJobValidate()){
      this.spinner.show();
      data.predecessorJobId = null;
      data.dependedJobId=null;
      data.projectTypeId=null;
      data.createdBy = parseInt(this.userid);
      data.parentJobId = parseInt(parentId);
      data.daysDuration =parseInt(data.daysDuration);
      if(data.defaultAssignee != null){
        if(data.defaultAssignee.length > 0){
          data.defaultAssignee = data.defaultAssignee[0].id;
        }else{
          data.defaultAssignee =null;
         }
        }else{
          data.defaultAssignee =null;
         }
        var notifiyArr =[];
         if(data.notifybefore != null){
          if(data.notifybefore.length > 0){
            for (let i = 0; i < data.notifybefore.length; i++) {
              notifiyArr.push(data.notifybefore[i].id);
            }
            data.notifybefore = notifiyArr;
          }else{
            data.notifybefore = [1];
          }
        }else{
          data.notifybefore = [1];
        }
        if (this.supervisorShowSubJob == true) {
          if (data.defaultSupervisor.length != 0) {
            data.defaultSupervisor = data.defaultSupervisor[0].id;
          }
        }else{
          data.defaultSupervisor = null;
        }
      data.orders = (this.resultOrder.maxOrder) + 1;
      this.http.post('/api/projectStates/projects/', data).subscribe((response) => {
       this.res1 = response;
        if (this.res1.success == true) {
          this.subJob_Show_Hide = false;
          this.SubJobDetailGet(parentId);
          this.subJobResetForm();
          this.getProject();
          setTimeout(() => {
            this.reloadSliderDaysCount(this.parentId);
            },1000);
          this.toastr.clear();
          this.toastr.success(this.res1.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
        } else {
          this.spinner.hide();
          this.toastr.clear();
          this.toastr.error(this.res1.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
        }

      }, error => {
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
        this.errorService.handleError(error);
      })

    }

   }
   subJobEdit(id){
     this.subId = id;
     this.subJob_Show_Hide = true;
     this.isSubJobCreate = true;
     this.supervisorShowSubJob = false;
     this.projectUserArrselectSubJob =[];
     this.projectSupervisorArrSelectSubJob=[];
     this.http.get('/api/projectState/project/'+ id).subscribe((res: Response) => {
      this.result1 = res;
      if (this.result1) {
        this.result =this.result1.state;
        if(this.result.defaultAssignee != null){
          this.projectAllUsersArr.forEach(r => {
            if(r.id == this.result.defaultAssignee)
            this.projectUserArrselectSubJob.push({
              "id": r.id, "itemName": r.itemName
            })
          });
          }else{
             this.subJobform.patchValue({ 'defaultAssignee': this.result.defaultAssignee });
          }
          if(this.result.defaultSupervisor != null){
            this.supervisorShowSubJob = true;
            this.projectAllUsersArr.forEach(r => {
              if(r.id == this.result.defaultSupervisor)
              this.projectSupervisorArrSelectSubJob.push({
                "id": r.id, "itemName": r.itemName
              })
            });
          }else{
            this.projectSupervisorArrSelectSubJob=[];
            this.supervisorShowSubJob = false;
            this.subJobform.patchValue({ 'defaultSupervisor': this.result.defaultSupervisor });
          }
            if (this.result.notifybefore.length > 0) {
          this.noftificationSubJobArrSelect = [];
            for (let i = 0; i < this.result.notifybefore.length; i++) {
            if (this.result.notifybefore[i] == "1") {
              this.noftificationSubJobArrSelect.push({ "id": 1, "itemName": "3 Days before" })
            } else if (this.result.notifybefore[i]  == "2") {
              this.noftificationSubJobArrSelect.push({ "id": 2, "itemName": "7 Days Before" })
            } else if (this.result.notifybefore[i]  == "3") {
              this.noftificationSubJobArrSelect.push({ "id": 3, "itemName": "15 Days Before" })
            } else if (this.result.notifybefore[i]  == "4") {
              this.noftificationSubJobArrSelect.push({ "id": 4, "itemName": "30 Days Before" })
            }
           }
             } else {
          this.noftificationSubJobArrSelect = [];
        }
          this.subJobform.patchValue({ 'parentJobId': this.parentId });
          this.subJobform.patchValue({ 'dependedJobId': this.result.dependedJobId });
          this.subJobform.patchValue({ 'predecessorJobId': this.result.predecessorJobId });
          this.subJobform.patchValue({ 'id': this.result.id });
          this.subJobform.patchValue({ 'name': this.result.name });
          this.subJobform.patchValue({ 'shortdesc': this.result.shortdesc });
          this.subJobform.patchValue({ 'projectStateTypeId': this.result.projectStateTypeId });
          this.subJobform.patchValue({ 'orders': this.result.orders });
          this.subJobform.patchValue({ 'createdDate': this.result.createdDate });
          this.subJobform.patchValue({ 'createdBy': this.result.createdBy });
          this.subJobform.patchValue({ 'updatedDate': this.result.updatedDate });
          this.subJobform.patchValue({ 'updatedBy': this.result.updatedBy });
          this.subJobform.patchValue({ 'isActive': this.result.isActive });
          this.subJobform.patchValue({ 'isFinal': this.result.isFinal });
          this.subJobform.patchValue({ 'daysDuration': this.result.daysDuration });
          this.subJobform.patchValue({ 'notifyMsg': this.result.notifyMsg });
        }
    });
   }
   SubJobDetailGet(id) {
    this.subStatesArr = [];
    this.subStates=[];
    this.projectUserArrselectSubJob =[];
    this.http.get('/api/projectState/project/'+id).subscribe((res: Response) => {
      this.result1 = res;
      if (this.result1) {
        this.subStates= this.result1.substates;
        if(this.subStates.length > 0){
          for (let i = 0; i < this.subStates.length; i++) {
              this.subStatesArr.push({
                "id": this.subStates[i].id,
                "name": this.subStates[i].name,
                "daysDuration": this.subStates[i].daysDuration,
                "shortdesc": this.subStates[i].shortdesc
              })
            }

          }
          }
        })
      }


      updateSub(){
        var data = this.subJobform.value;
        if (this.subJobValidate()) {
          this.spinner.show();
          var cusr = [];
          data.updatedBy = parseInt(this.userid);
          data.projectTypeId =null;
          data.daysDuration =parseInt(data.daysDuration);
          if(data.defaultAssignee != null){
                  if(data.defaultAssignee.length > 0){
                    data.defaultAssignee = data.defaultAssignee[0].id;
                  }
              }
                      var notifiyArr =[];
              if(data.notifybefore != null){
               if(data.notifybefore.length > 0){
                 for (let i = 0; i < data.notifybefore.length; i++) {
                   notifiyArr.push(data.notifybefore[i].id);
                 }
                 data.notifybefore = notifiyArr;
               }else{
                 data.notifybefore = [1];
               }
             }else{
               data.notifybefore = [1];
             }
             if (this.supervisorShowSubJob == true) {
              if (data.defaultSupervisor.length != 0) {
                data.defaultSupervisor = data.defaultSupervisor[0].id;
              }
            }else{
              data.defaultSupervisor = null;
            }
          this.http.put('/api/projectStates/projects/', data).subscribe((response) => {
            this.toastr.clear();
            this.result = response;
            if (this.result.success) {
              this.toastr.success(this.result.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.subJobResetForm();
              this.subJob_Show_Hide = false;
              this.SubJobDetailGet(this.parentId);
              this.getProject();
              setTimeout(() => {
                this.reloadSliderDaysCount(this.parentId);
                },1000);
            } else {
              this.toastr.error(this.result.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
            }

          }, error => {
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
            this.errorService.handleError(error);
          })

        }
      }

      subJobDelete(id){
        this.menuStateProject = this.menuStateProject === 'out' ? 'in' : 'out';
        this.confirmService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) => {
        if (confirmed) {
          window.scrollTo(0, 0);
          this.spinner.show();
          this.http.delete('/api/projectState/' + id).subscribe((response) => {
            this.toastr.clear();
            this.result = response;
            if (this.result.success) {
              this.subJob_Show_Hide = false;
              this.menuStateProject = this.menuStateProject === 'out' ? 'in' : 'out';
              this.projectArr=[];
              this.getProject();
              this.subJobResetForm();
              this.toastr.success(this.result.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
             this.SubJobDetailGet(this.parentId);
            } else {

              this.toastr.error(this.result.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
            }
            this.spinner.hide();

          }, error => {
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
            this.errorService.handleError(error);
          })
        } else {
        }
      })
      .catch(() => {
        this.menuStateProject = this.menuStateProject === 'out' ? 'in' : 'out';
        return false;
      });
      }

      reloadSliderDaysCount(id){
        this.http.get('/api/projectState/project/'+ id).subscribe((res: Response) => {
          this.result1 = res;
          if (this.result1) {
            this.result =this.result1.state;
              this.projectform.patchValue({ 'daysDuration': this.result.daysDuration });
            }
        });
      }

      subJobDetails(id){
        this.editSh =true;
        this.subStatesDetailsArr=[];
        this.http.get('/api/projectState/project/'+ id).subscribe((res: Response) => {
         this.result = res;
          for (let i = 0; i < this.result.substates.length; i++) {
            this.subStatesDetailsArr.push({
              "id": this.result.substates[i].id,
              "name": this.result.substates[i].name,
              "daysDuration": this.result.substates[i].daysDuration,
              "shortdesc": this.result.substates[i].shortdesc
            })
          }


        });
      }
      closePop(){
        this.editSh =false;
      }
   subJobValidate() {
    this.supervisorErr =null;
    this.nameSubJobErr = null;
    this.descSubJobErr = null;
    this.daysSubJobErr = null;
    this.statusSubJobErr=null;
    var data = this.subJobform.value;
    const validNumber = /^\d+$/;
    const strongRegex = /^[\d]{1,2}$/;
    var valid = true;
    if (data.name == undefined || data.name == '') {
      this.nameSubJobErr = "Sub-Job Name is required.";
      valid = false;
    }
    else if (data.shortdesc == undefined || data.shortdesc == '') {
      this.descSubJobErr = "Description  is required.";
      valid = false;
    }
    else if (data.daysDuration == undefined || data.daysDuration == '') {
      this.daysSubJobErr = "Days is required.";
      valid = false;
    }else if (data.daysDuration == "0") {
      this.daysSubJobErr = "Invalid Days.";
      valid = false;
    }
    else if (!validNumber.test(data.daysDuration)) {
      this.daysSubJobErr = "Invalid Days.";
      valid = false;
    }  if(this.supervisorShowSubJob ==  true){
      if (data.defaultSupervisor.length == 0) {
     this.supervisorErr = "Supervisor is required";
     valid = false;
   }
  }
    return valid;
  }

  restpasslist: boolean = false;
  restpass(){
    if (this.passreset == true) {
      this.passreset = false;
    } else {
      this.passreset = true;
    }
  }
  passwordPost(certifierID) {
    if (this.passwordValidate()) {
      this.spinner.show();
      this.http.get('/api/certifier/' +certifierID).subscribe((res: Response) => {
        this.result = res;
        var data = this.result;
        var data1 = this.passwordForm.value;
        data.password = data1.confirmPassword;
        data.updatedBy = parseInt(this.userid);
        this.http.put('/api/certifier/' , data).subscribe((response) => {
          this.toastr.clear();
          this.result1 = response;
          if (this.result1.success) {
            this.certifierID =this.result1.response.id
            this.passwordresetForm();
            this.toastr.success(this.result1.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.restpass();
            this.spinner.hide();
          } else {
            this.toastr.error(this.result1.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
          }
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })
      })
    }
  }

  passwordValidate() {
    this.resetpasswordErr = null;
    this.confirmpasswordErr = null;
    var data = this.passwordForm.value;
    const strongRegex = /(?=.{6,})/;
    var valid = true;
    if (data.password == Validators || data.password == '') {
      this.resetpasswordErr = "Password is required.";
      valid = false;
    } else if (!strongRegex.test(data.password)) {
      this.resetpasswordErr = "Password is Must be 6 Charcters.";
      valid = false;
    } else if (data.password != data.confirmPassword) {
      this.confirmpasswordErr = "Password doesn't Match.";
      valid = false;
    }
    return valid;
  }
  getallUserAndExternalUser() {
    this.projectAllUsersArr =[];
    this.projectUsersArr = [];
    this.http.get('/api/projectState/stateAssignedToExt/').subscribe((res: Response) => {
      this.externalinternalResult = res;
      var result = this.externalinternalResult;
      for (let i = 0; i < result.internaluser.length; i++) {
        this.projectUsersArr.push({
          "id": result.internaluser[i].id, "itemName": result.internaluser[i].name
        })
        this.projectAllUsersArr.push({
          "id": result.internaluser[i].id, "itemName": result.internaluser[i].name, "type": "User"
        })
      }
      for (let i = 0; i < result.externalUser.length; i++) {
        this.projectAllUsersArr.push({
          "id": result.externalUser[i].id, "itemName": result.externalUser[i].name, "type": "External User"
        })
      }
    });
  }
  jobUserExternalSelect(item,num){
    if(num ==0){
      if(item.type == "External User"){
        this.supervisorShow = true;
      }else{
        this.supervisorShow = false;
      }
    }else{
      this.supervisorShow = false;
      this.supervisorErr=null;
    }
  }
  jobUserExternalSelectSubJob(item,num){
    if(num ==0){
      if(item.type == "External User"){
        this.supervisorShowSubJob = true;
      }else{
        this.supervisorShowSubJob = false;
      }
    }else{
      this.supervisorShowSubJob = false;
      this.supervisorErr=null;
    }
  }
  getOCDStatus() {
    this.ocdstatusArr = [];
    var projectTypeName = this.projectTypeSelectedArr[0].id;
    this.spinner.show();
    this.http.get('api/projectStates/projectOCDs/' + projectTypeName).subscribe((res: Response) => {
      this.result = res;
      this.spinner.hide();
      for (let i = 0; i <  this.result.length; i++) {
        this.ocdstatusArr.push({
          "id":  this.result[i].id, "name":  this.result[i].name, "shortdesc":  this.result[i].shortdesc

        })
      }
    });
  }
  OCDStatevalidate() {
    this.nameErr = null;
    this.descErr = null;
    var data = this.OCDStateform.value;
    var valid = true;
    if (data.name == undefined || data.name == '') {
      this.nameErr = "OCD State is required..";
      valid = false;
    }
    else if (data.shortdesc == undefined || data.shortdesc == '') {
      this.descErr = "Description is required.";
      valid = false;
    }
    return valid;
  }
  submitOCDState() {
    this.nameErr = null;
    this.descErr = null;
    var projectTypeName = this.projectTypeSelectedArr[0].id;
    var data = this.OCDStateform.value;
    data.createdBy = this.userid;
    if (this.OCDStatevalidate()) {
      this.spinner.show();
      this.http.get('api/projectStates/projectOCDs/'+ projectTypeName +'/order').subscribe((res: Response) => {
        this.order = res;
        if (this.order) {
          data.orders = (this.order.maxOrder) + 1;
          data["projectTypeName"] = projectTypeName;
          this.http.post('api/projectStates/projectOCDs', data).subscribe((response) => {
            this.res1= response;
            var res1 =  this.res1;
            if (res1.success == true) {
              this.resetForm();
              this.toastr.clear();
              this.toastr.success(res1.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.toggleClose(4);
              this.getOCDStatus();
              this.ocdstatusArr = [];
            } else {
              this.spinner.hide();
              this.toastr.clear();
              this.toastr.error(res1.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
            }
            this.spinner.hide();

          }, error => {
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
            this.errorService.handleError(error);
          })

        }
      });
    }
  }
  toggleClose(number){
    if(number == 0){
      if (this.menuStateDeal == 'in') {
        this.menuStateDeal = 'out';
      }
    }else if(number == 1){
      if (this.menuStateApproval == 'in') {
        this.menuStateApproval = 'out';
      }
    }else if(number == 2){
      if (this.menuStateProject == 'in') {
        this.menuStateProject = 'out';
      }
    }else if(number == 3){
      if (this.menuStateCertifier == 'in') {
        this.menuStateCertifier = 'out';
      }
    }else if(number == 4){
      this.popoverform = false;
      if (this.menuOCDState == 'in') {
        this.menuOCDState = 'out';
      }
    }
  }
  toggleOCDStateEditMenu(id) {
    this.nameErr = null;
    this.descErr = null;
    this.stateErr = null;
    this.isCreate = false;
    this.popoverform = true;
    this.id = id;
    this.http.get('/api/projectState/' + this.id).subscribe((res: Response) => {
      this.result = res;
      var result = this.result;
      if (result) {
        this.OCDStateform.patchValue({ 'id': result.id });
        this.OCDStateform.patchValue({ 'name': result.name });
        this.OCDStateform.patchValue({ 'shortdesc': result.shortdesc });
        this.OCDStateform.patchValue({ 'projectStateTypeId': result.projectStateTypeId });
        this.OCDStateform.patchValue({ 'orders': result.orders });
        this.OCDStateform.patchValue({ 'createdDate': result.createdDate });
        this.OCDStateform.patchValue({ 'createdBy': result.createdBy });
        this.OCDStateform.patchValue({ 'updatedDate': result.updatedDate });
        this.OCDStateform.patchValue({ 'updatedBy': result.updatedBy });
        this.OCDStateform.patchValue({ 'isActive': result.isActive });
        this.OCDStateform.patchValue({ 'isFinal': result.isFinal });
      }
    });
    this.menuOCDState = this.menuOCDState === 'out' ? 'in' : 'out';
  }
  updateOCDState() {
    var data = this.OCDStateform.value;
    var projectTypeName = this.projectTypeSelectedArr[0].id;
    data.updatedBy = this.userid;
    data["projectTypeName"] = projectTypeName;
    if (this.OCDStatevalidate()) {
      window.scrollTo(0, 0);
      this.spinner.show();
      this.http.put('/api/projectState/', data).subscribe((response) => {
        this.toastr.clear();
        this.result = response;
        if (this.result.success) {
          this.resetForm();
          this.toastr.success(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.toggleClose(4);
           this.getOCDStatus();
        } else {

          this.toastr.error(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
        }
        this.spinner.hide();

      }, error => {
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
        this.errorService.handleError(error);
      })

    }
  }
  deleteOcd(id){
    this.confirmService.confirm('Please confirm..', 'Do you really want to delete ?')
    .then((confirmed) => {
      if (confirmed) {
        window.scrollTo(0, 0);
        this.spinner.show();
        this.http.delete('/api/projectState/' + id).subscribe((response) => {
          this.toastr.clear();
          this.result = response;
          if (this.result.success) {
            this.resetForm();
            this.toastr.success(this.result.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.getOCDStatus();
            this.toggleClose(4);
          } else {
            this.toastr.error(this.result.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
          }

        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })
      } else {
      }
      this.dealsArr = [];
      this.dealsFinalArr = [];
    })
    .catch(() => {
      return false;
    });
  }
}

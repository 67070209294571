import { Component, ElementRef, ViewChild, Renderer2, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import * as moment from 'moment/moment.js';
import { NgForm } from '@angular/forms';
import { DragulaService } from 'ng2-dragula';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '../_services';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { ErrorService } from 'src/app/_services/error.service';
import { PermissionService } from 'src/app/_services/permission.service';
import { ToastrService } from 'ngx-toastr';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { trigger, state, animate, transition, style } from '@angular/animations';
import { Subscription } from 'rxjs';
import { DetailsService } from '../_services/index';
@Component({

  selector: 'app-deals',
  templateUrl: './deals.component.html',
  styleUrls: ['./deals.component.css'],
  viewProviders: [DragulaService],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})


export class DealsComponent implements OnInit {
  @ViewChild('isIncludeTag') isIncludeTag: ElementRef;
  @Input('dealSaveChild') dealSaveChild;
  @Output() ChangeEvent = new EventEmitter();
  ddresult: any;
  result: any;
  selDate: any;
  body: {};
  dealArr = [];
  custArr = [];
  projectStateId: any;
  isvaluecheck: boolean = false;
  customerSlider: boolean = false;
  certifierSliderCheck: boolean = false;
  userSliderCheck: boolean = false;
  editable: boolean = false;
  dealstateArr: any;
  customersArr = [];
  customersArrselect = [];
  dealstateArr1select = [];
  usersArr = [];
  usersCustArr = [];
  usersArrselect = [];
  currency: any;
  ddArr = [];
  dsid: number;
  isCreate: boolean = true;
  closeResult: string;
  menuState: string = 'out';
  dealEdit: string = 'out';
  valueErr: any;
  currencyErr: any;
  shortdescErr: any;
  customerErr: any;
  projectStateIdErr: any;
  settings: any;
  customerIdErr: any;
  titleErr: any;
  myDatePickerOptions: any;
  multiSettings: any;
  expCloseDate: any;
  form: FormGroup;
  dealstateArr1 = [];
  dealstateMap = [];
  subs = new Subscription();
  username: any;
  userid: any;
  did: any;
  Finalorder: any;
  admin: any;
  isAdminDis: boolean = false;
  isAdminDeal: boolean = false;
  movetocust: boolean = false;
  isSuperAdmin: boolean = false;
  isDeal: boolean = false;
  movetouser: boolean = false;
  today: any;
  settings1: any;
  dateErr: any;
  result1: any;
  result2: any;
  result3: any;
  result4: any;
  result5: any;

  res2: any
  res: any;
  dealstateArrId: any;
  certifierArr = [];
  certifierArrselect = [];
  editSh: boolean = false;
  projectId: any;
  settingsManger: any;
  settingsCertifier: any;
  managerErr: any;
  centrifiedErr: any;
  dealName: any;
  currencyArr: any;
  currencyArrSelect: any;
  currencysettings: any;
  projectForm: FormGroup;
  popoverform: boolean = false;
  popoverform1: boolean = false;
  movetocerti: boolean = false;
  dealHidePage: boolean = false;
  loalDealValueSave = [];
  term:any;
  dropdownList1 = [];
  selectedItems = [];
  typeErr: any;

  stateArr = [];
  stateArrselect = [];
  StateErr: any;

  buidingArr = [];
  buidingArrselect = [];
  buildErr: any;
  loggedInUserDomain: any;

  councilArr = [];
  councilArrSelect = [];

  orgMap = {};
  buildingTypeMap = {};

  constructor(private http: HttpClient, private router: Router,
    private dragulaService: DragulaService,
    private modalService: NgbModal, private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private errorService: ErrorService,
    private permissionService: PermissionService,
    private confirmService: ConfirmService, private datePipe: DatePipe,
    private formBuilder: FormBuilder, private DetailsService: DetailsService) {
    this.createForm();
    this.currencyArr = [];
    this.currencyArrSelect = [];

    this.dragulaService.createGroup('V', {
      moves: function (el: any, container: any, handle: any): any {
        if (el.classList.contains('nomove')) {
            return false;
        }
        return true;
    }
    });

    this.subs.add(this.dragulaService.drop()
      .subscribe(({ name, el, target, source, sibling }) => {
        var i = target.attributes["id"].nodeValue;
        var dealId = el.attributes["id"].nodeValue;
        if (dealId == -1) { return false; }
        var order = this.dealstateArr[i].id;
        this.dealstateArrId = this.dealstateArr[i].id;

        if (this.Finalorder == order) {
          this.confirmService.confirm('Please confirm..', 'Do you want to convert the Deal to Project? Once converted cannot be recovered.')
            .then((confirmed) => {
              if (confirmed) {
                this.usersArrselect = [];
                this.certifierArrselect = [];
                this.councilArrSelect = [];
                this.editSh = true;
                this.projectId = dealId;
                this.http.get('/api/project/' + dealId).subscribe((res: Response) => {
                  this.result = res;
                  this.dealName = this.result.title;
                });
              } else {
                this.dealArr = [];
                this.getDeal();
              }
            })
            .catch(() => {
              this.dealArr = [];
              this.getDeal();
            });
        } else {
          this.setDealProjectState(dealId, this.dealstateArr[i].id);
        }
      })
    );
  }

  postCertifier(dealId) {
    this.http.get('/api/project/' + dealId).subscribe((res: Response) => {
      this.result = res;
      var data = this.projectForm.value;
      this.result.updatedBy = parseInt(this.userid);
      if (this.validateCertifier()) {
        this.spinner.show();
        if (data.managerId.length > 0) {
          this.result.managerId = data.managerId[0].id;
        }
        if (data.certifierId.length > 0) {
          this.result.certifierId = data.certifierId[0].id;
        }
        this.http.put('/api/project/', this.result).subscribe((res1: Response) => {
          this.res2 = res1;
          if (this.res2.success == true) {
            this.editSh = false;
            this.setDealProjectStateFinal(this.projectId, this.dealstateArrId);
          }
        });
      }
    });
  }

  setDealProjectStateFinal(dealId, dealStateId) {
    this.http.get('/api/project/' + dealId).subscribe((res: Response) => {
      this.result = res;
      if (this.result != null) {
        this.result.projectStateId = dealStateId;
        this.result.dealCompleted = "T";
        this.result.updatedBy = parseInt(this.userid);
        this.http.put('/api/project/', this.result).subscribe((res1: Response) => {
          this.res2 = res1;
          if (this.res2.success == true) {
            this.toastr.clear();
            this.toastr.success(this.res2.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.dealArr = [];
            this.getDeal();
          }
        }, error => {
          this.toastr.clear();
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.errorService.handleError(error);
        })
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    })
  }

  setDealProjectState(dealId, dealStateId) {
    this.spinner.show();
    this.http.get('/api/project/' + dealId).subscribe((res: Response) => {
      this.result = res;
      if (this.result != null) {
        this.result.projectStateId = dealStateId;
        this.result.updatedBy = parseInt(this.userid);
        this.http.put('/api/project/', this.result).subscribe((res1: Response) => {
          this.res2 = res1;
          this.toastr.clear();
          this.toastr.success('Deal Moved successfully', 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();

        }, error => {
          this.toastr.clear();
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.errorService.handleError(error);
        });
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }

  showSelectedDate() {
    let expCloseDate: any = this.form.controls['expCloseDate'].value;
  }

  onDateChanged(event) {
    this.dateErr = null;
  }


  ngOnInit() {
    this.getDealStatusOreder();
    this.getCertifier();
    this.getCouncil();
    this.getOrgs();
    // this.getUserCust();
    this.getcurr();
    this.username = localStorage.getItem("loggedInUser");
    this.userid = localStorage.getItem("loggedInUserID");
    this.admin = localStorage.getItem("loggedInUserAdmin");
    this.loggedInUserDomain = localStorage.getItem('loggedInDomainID');
    this.today = new Date();
    this.myDatePickerOptions = {
      dateFormat: 'dd-mm-yyyy',
      disableUntil: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() - 1 }

    }
    this.settings = {
      text: "Select User",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: true,
      enableCheckAll: false
    };

    this.currencysettings = {
      text: "Select Currency",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: true,
      enableCheckAll: false
    };
    this.multiSettings = {
      text: "Select Customer",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: false,
      enableCheckAll: false
    };
    this.settings1 = {
      text: "Select State",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: true,
      enableCheckAll: false
    };
    this.settingsManger = {
      text: "Select Manager",
      enableSearchFilter: true,
      classes: "fullwidth",
      disabled: false,
      singleSelection: true,
      enableCheckAll: false
    };
    this.settingsCertifier = {
      text: "Select Certifier",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: true,
      enableCheckAll: false
    };
    if (this.admin == "Y") {
      this.isSuperAdmin = true;
    }
    this.dropdownList1 = [
      { "id": 1, "itemName": "DA" },
      { "id": 2, "itemName": "CC" },
      { "id": 4, "itemName": "CDC"},
      { "id": 3, "itemName": "SubDivision" }

    ];
    this.getUser();
    this.getCountryStates();
    this.getBuildingtype();
    //this.getCertifier();
    this.getDeal();
  }

  ngOnChanges() {
    this.dealHidePage = true;
    this.popoverform = false;
    this.certifierSliderCheck = false;
    this.toggleCreateMenu();
    setTimeout(() => {
      this.spinner.hide();
      this.currencyArrSelect = [];

      var stateId = localStorage.getItem("projectStateId");
      var stateName = localStorage.getItem("projectStateName");

      var date = localStorage.getItem("expCloseDate");
      if (date != "null") {
        var cdate = date;
        if (cdate) {
          var ydate = moment(cdate).format("YYYY")
          var mdate = moment(cdate).format("M")
          var ddate = moment(cdate).format("D")
          this.form.patchValue({
            'expCloseDate': {
              date: {
                year: ydate,
                month: mdate,
                day: ddate
              }
            }
          });
        } else {
          this.form.patchValue({ expCloseDate: null });
        }
      } else {
        this.form.patchValue({ expCloseDate: null });

      }
      var value = localStorage.getItem("value");
      var desc = localStorage.getItem("shortdesc");
      var title = localStorage.getItem("title");
      var currency = localStorage.getItem("currency");
      this.form.patchValue({ 'title': title });
      this.form.patchValue({ 'value': value });
      this.form.patchValue({ 'shortdesc': desc });
      if(stateId != null){
        this.dealstateArr1select =[];
        this.dealstateArr1select.push({
          "id": parseInt(stateId), "itemName": stateName
        })
      }


      this.currencyArr.forEach(r => {
        if (currency == r.itemName)
          this.currencyArrSelect.push({
            "id": r.itemName, "itemName": r.itemName
          })
      });
     // this.localClear();
      this.spinner.hide();
    }, 2000);

  }

  localClear(){
    localStorage.removeItem('projectStateId');
    localStorage.removeItem('projectStateName');
    localStorage.setItem("expCloseDate", null);
    localStorage.removeItem('shortdesc');
    localStorage.removeItem('value');
    localStorage.removeItem('currency');
    localStorage.removeItem('title');
  }

  getCountryStates() {
    this.http.get('/api/australianStates').subscribe((res: Response) => {
      this.result= res;
      for (let i = 0; i < this.result.length; i++) {
        this.stateArr.push({
          "id": this.result[i].id, "itemName": this.result[i].name,
        })
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }

  getBuildingtype() {
      this.http.get('/api/projectTypes').subscribe((res: Response) =>
      {
        this.result = res;
        for (let i = 0; i < this.result.length; i++) {
          this.buidingArr.push({
            "id": this.result[i].id, "itemName": this.result[i].type,
          })
          this.buildingTypeMap[this.result[i].id] = this.result[i].type;
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
     }

 getOrgs() {
     this.http.get('/api/orgs').subscribe((res: Response) =>
     {
       this.result = res;
       for (let i = 0; i < this.result.length; i++) {
         this.orgMap[this.result[i].id] = this.result[i].orgName;
       }
     }, error => {
       this.toastr.clear();
       this.toastr.error(error, 'Report Status', {
         timeOut: 6000,
         closeButton: true
       });
       this.errorService.handleError(error);
     });
    }
  getDealStatusOreder() {
    this.http.get('/api/projectStates/deals').subscribe((res: Response) => {
      this.result = res;
      for (let i = 0; i < this.result.length; i++) {
        if (this.result[i].isFinal == "Y") {
          this.Finalorder = this.result[i].id;
        }
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });

  }
  async getcurr() {
    this.currencyArr = [];
    var result = await this.DetailsService.getCurrency();
    this.result1 = result;
    for (let i = 0; i < this.result1.length; i++) {
      this.currencyArr.push({
        "id": this.result1[i], "itemName": this.result1[i]
      })
    }
  }
  async getUser() {
    var result = await this.DetailsService.getUsers();
    this.result1 = result;
    this.result1.forEach(r => {
      this.usersArr.push({
        "id": r.details.id, "itemName": r.details.firstName + " " + r.details.lastName
      });
      this.usersCustArr.push({
        "id": r.details.id, "itemName": r.details.firstName + " " + r.details.lastName
      })
    });
  }

  async getUserCust() {
    var result = await this.DetailsService.getUsers();
    this.result1 = result;
    if (this.result1.length != 0) {
      this.movetouser = false;
      this.result1.forEach(r => {
        this.usersCustArr.push({
          "id": r.details.id, "itemName": r.details.firstName + " " + r.details.lastName
        })
      })
    }
    else {
      this.movetouser = true;
    }
  }


  getdealbyuser(value, num) {
    console.log(JSON.stringify(value));
    this.spinner.show();
    this.dealstateArr = [];
    this.customersArr = [];
    this.dealArr=[];
    this.http.get('/api/projectStates/deals').subscribe((res: Response) => {
      this.result = res;
      for (let i = 0; i < this.result.length; i++) {
        if (this.result[i].isFinal != "Y") {
          this.dealstateArr1.push({
            "id": this.result[i].id, "itemName": this.result[i].name
          })
        }
        this.dealstateMap.push(this.result[i].name);
      }
      for (let i = 0; i < this.result.length; i++) {
        if (this.result[i].name.length > 12) {
          var name = this.result[i].name.slice(0, 13).concat("...");
        } else {
          var name = this.result[i].name;
        }
        this.dealstateArr.push({
          "id": this.result[i].id, "itemName": name, "titleName": this.result[i].name
        });
        this.dealstateMap.push(this.result[i].name);
      }

      this.http.get('/api/customer').subscribe((res1: Response) => {
        this.result1 = res1;
        if (this.result1.length != 0) {
          this.movetocust = false;
          for (let i = 0; i < this.result1.length; i++) {
            this.customersArr.push({
              "id": this.result1[i].details.id, "itemName": this.result1[i].details.firstName + " " + this.result1[i].details.lastName, "orgId": this.result1[i].details.orgId
            })
          }
        }
        else {
          this.movetocust = true;
        }

        this.http.get('/api/user/' + value.id).subscribe((res: Response) => {
          this.result2 = res;
          var permision = this.result2.role.permision;
          if (permision.length > 0) {

              this.isDeal = false;
              var dealurl = '/api/projects/deals/' + value.id;
              this.http.get(dealurl).subscribe((res: Response) => {
                this.result3 = res;
                if (this.result3.length > 0) {
                  for (let i = 0; i < this.result3.length; i++) {
                    for (let j = 0; j < this.dealstateArr.length; j++) {
                      if (this.dealArr[j] == null) {
                        this.dealArr[j] = [{ id: -1, name: "Drop Here", title: "test" }];
                      }
                      if (this.dealstateArr[j].id == this.result3[i].deal.projectStateId) {
                        this.http.get("/api/projectLogs/dealDuration/" + this.result3[i].deal.id).subscribe((res: Response) => {
                          this.result4 = res;
                          if (this.result4.TotalDealDurationDays != 0 && this.result4.TotalDealDurationMonths == 0 && this.result4.TotalDealDurationYears == 0) {
                            var dayvalue = this.result4.TotalDealDurationDays + " days";
                          } else if (this.result4.TotalDealDurationDays != 0 && this.result4.TotalDealDurationMonths != 0 && this.result4.TotalDealDurationYears == 0) {
                            var dayvalue = this.result4.TotalDealDurationMonths + " mnt " + this.result4.TotalDealDurationDays + " days";
                          } else if (this.result4.TotalDealDurationYears != 0) {
                            var dayvalue = this.result4.TotalDealDurationYears + " Yr " + this.result4.TotalDealDurationMonths + " mnt " + this.result4.TotalDealDurationDays + " days";
                          } else {
                            var dayvalue = this.result4.TotalDealDurationDays + " days";
                          }

                          if (value.id == this.result3[i].deal.managerId) {
                            this.editable = true;
                          }else{
                            this.editable = false;
                          }

                          this.dealArr[j].push({ "id": this.result3[i].deal.id,
                          "title": this.result3[i].deal.title,
                          "shortdesc": this.result3[i].deal.shortdesc,
                          "days": dayvalue, "value": this.result3[i].deal.value,
                          "Customer": this.result3[i].customers[0].name,
                          "org" : this.orgMap[this.result3[i].customers[0].org],
                          "stateType" : this.result3[i].info.projectStateType,
                          "manager" : this.result3[i].info.managerName,
                          "editable" : this.editable
                         })
                        }, error => {
                          this.toastr.clear();
                          this.toastr.error(error, 'Report Status', {
                            timeOut: 6000,
                            closeButton: true
                          });
                          this.errorService.handleError(error);
                        })
                      }
                    }
                  }
                  console.log(JSON.stringify(this.dealArr));
                } else {
                  this.spinner.hide();
                }
                this.spinner.hide();
              }, error => {
                this.toastr.clear();
                this.toastr.error(error, 'Report Status', {
                  timeOut: 6000,
                  closeButton: true
                });
                this.errorService.handleError(error);
              });


          }
        }, error => {
          this.toastr.clear();
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.errorService.handleError(error);
        });

      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });

    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }

  getDeal() {
    this.spinner.show();
    this.dealstateArr = [];
    this.customersArr = [];
    this.dealArr=[];
    
    this.http.get('/api/projectStates/deals').subscribe((res: Response) => {
      this.result = res;
      for (let i = 0; i < this.result.length; i++) {
        if (this.result[i].isFinal != "Y") {
          this.dealstateArr1.push({
            "id": this.result[i].id, "itemName": this.result[i].name
          })
        }
        this.dealstateMap.push(this.result[i].name);
      }
      for (let i = 0; i < this.result.length; i++) {
        if (this.result[i].name.length > 12) {
          var name = this.result[i].name.slice(0, 13).concat("...");
        } else {
          var name = this.result[i].name;
        }
        this.dealstateArr.push({
          "id": this.result[i].id, "itemName": name, "titleName": this.result[i].name
        });
        this.dealstateMap.push(this.result[i].name);
      }

      this.http.get('/api/customer').subscribe((res1: Response) => {
        this.result1 = res1;
        if (this.result1.length != 0) {
          this.movetocust = false;
          for (let i = 0; i < this.result1.length; i++) {
            this.customersArr.push({
              "id": this.result1[i].details.id, "itemName": this.result1[i].details.firstName + " " + this.result1[i].details.lastName, "orgId": this.result1[i].details.orgId
            })
          }
        }
        else {
          this.movetocust = true;
        }

        this.http.get('/api/user/' + this.userid).subscribe((res: Response) => {
          this.result2 = res;
          var permision = this.result2.role.permision;
          if (permision.length > 0) {
            var dealPermission = this.permissionService.getDealPermission(permision);
            if (dealPermission == this.permissionService.DEAL_ADMIN || this.admin == "Y") {
              this.isAdminDeal = true;
            } else if (dealPermission == this.permissionService.DEAL_OBSERVER) {
              this.isAdminDeal = false;
            }
            if (this.admin == "Y") {
              var dealurl = '/api/projects/deals/';
              this.isAdminDis = true;
              this.isDeal = true;
              this.editable = true;
              this.http.get(dealurl).subscribe((res: Response) => {
                this.result3 = res;
                if (this.result3.length > 0) {
                  for (let i = 0; i < this.result3.length; i++) {
                    for (let j = 0; j < this.dealstateArr.length; j++) {
                      if (this.dealArr[j] == null) {
                        this.dealArr[j] = [{ id: -1, name: "Drop Here", title: "test" }];
                      }
                      if (this.dealstateArr[j].id == this.result3[i].deal.projectStateId) {
                        this.http.get("/api/projectLogs/dealDuration/" + this.result3[i].deal.id).subscribe((res: Response) => {
                          this.result4 = res;
                          if (this.result4.TotalDealDurationDays != 0 && this.result4.TotalDealDurationMonths == 0 && this.result4.TotalDealDurationYears == 0) {
                            var dayvalue = this.result4.TotalDealDurationDays + " days";
                          } else if (this.result4.TotalDealDurationDays != 0 && this.result4.TotalDealDurationMonths != 0 && this.result4.TotalDealDurationYears == 0) {
                            var dayvalue = this.result4.TotalDealDurationMonths + " mnt " + this.result4.TotalDealDurationDays + " days";
                          } else if (this.result4.TotalDealDurationYears != 0) {
                            var dayvalue = this.result4.TotalDealDurationYears + " Yr " + this.result4.TotalDealDurationMonths + " mnt " + this.result4.TotalDealDurationDays + " days";
                          } else {
                            var dayvalue = this.result4.TotalDealDurationDays + " days";
                          }
                          this.dealArr[j].push({ "id": this.result3[i].deal.id,
                          "title": this.result3[i].deal.title,
                          "shortdesc": this.result3[i].deal.shortdesc,
                          "days": dayvalue, "value": this.result3[i].deal.value,
                          "Customer": this.result3[i].customers[0].name,
                          "org" : this.orgMap[this.result3[i].customers[0].org],
                          "stateType" : this.result3[i].info.projectStateType,
                          "manager" : this.result3[i].info.managerName,
                          "editable" : this.editable
                         })
                        }, error => {
                          this.toastr.clear();
                          this.toastr.error(error, 'Report Status', {
                            timeOut: 6000,
                            closeButton: true
                          });
                          this.errorService.handleError(error);
                        })

                      }
                    }
                  }
                } else {
                  this.spinner.hide();
                }
                this.spinner.hide();
              });
            }else{
              this.isDeal = false;
              var dealurl = '/api/projects/deals/' + this.userid;
              this.http.get(dealurl).subscribe((res: Response) => {
                this.result3 = res;
                if (this.result3.length > 0) {
                  for (let i = 0; i < this.result3.length; i++) {
                    for (let j = 0; j < this.dealstateArr.length; j++) {
                      if (this.dealArr[j] == null) {
                        this.dealArr[j] = [{ id: -1, name: "Drop Here", title: "test" }];
                      }
                      if (this.dealstateArr[j].id == this.result3[i].deal.projectStateId) {
                        this.http.get("/api/projectLogs/dealDuration/" + this.result3[i].deal.id).subscribe((res: Response) => {
                          this.result4 = res;
                          if (this.result4.TotalDealDurationDays != 0 && this.result4.TotalDealDurationMonths == 0 && this.result4.TotalDealDurationYears == 0) {
                            var dayvalue = this.result4.TotalDealDurationDays + " days";
                          } else if (this.result4.TotalDealDurationDays != 0 && this.result4.TotalDealDurationMonths != 0 && this.result4.TotalDealDurationYears == 0) {
                            var dayvalue = this.result4.TotalDealDurationMonths + " mnt " + this.result4.TotalDealDurationDays + " days";
                          } else if (this.result4.TotalDealDurationYears != 0) {
                            var dayvalue = this.result4.TotalDealDurationYears + " Yr " + this.result4.TotalDealDurationMonths + " mnt " + this.result4.TotalDealDurationDays + " days";
                          } else {
                            var dayvalue = this.result4.TotalDealDurationDays + " days";
                          }

                          if (this.userid == this.result3[i].deal.managerId) {
                            this.editable = true;
                          }else{
                            this.editable = false;
                          }
                          this.dealArr[j].push({ "id": this.result3[i].deal.id,
                          "title": this.result3[i].deal.title,
                          "shortdesc": this.result3[i].deal.shortdesc,
                          "days": dayvalue, "value": this.result3[i].deal.value,
                          "Customer": this.result3[i].customers[0].name,
                          "org" : this.orgMap[this.result3[i].customers[0].org],
                          "stateType" : this.result3[i].info.projectStateType,
                          "manager" : this.result3[i].info.managerName,
                          "editable" : this.editable
                         })
                        }, error => {
                          this.toastr.clear();
                          this.toastr.error(error, 'Report Status', {
                            timeOut: 6000,
                            closeButton: true
                          });
                          this.errorService.handleError(error);
                        })
                      }
                    }
                  }
                  console.log(JSON.stringify(this.dealArr));
                } else {
                  this.spinner.hide();
                }
                this.spinner.hide();
              }, error => {
                this.toastr.clear();
                this.toastr.error(error, 'Report Status', {
                  timeOut: 6000,
                  closeButton: true
                });
                this.errorService.handleError(error);
              });
            }

          }
        }, error => {
          this.toastr.clear();
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.errorService.handleError(error);
        });

      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });

    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });

  }


  submitDeal() {
    var data = this.form.value;
    if (this.validate()) {
      this.spinner.show();
      var customerId = [];
      var orgId = [];
      var currency = [];
      if (data.projectStateId.length > 0) {
        data.projectStateId = data.projectStateId[0].id;
      }
      if (data.customerId.length > 0) {
        for (let i = 0; i < data.customerId.length; i++) {
          if (data.customerId[i].id) {
            customerId.push(data.customerId[i].id);
            orgId.push(data.customerId[i].orgId)
          } else {
            var orgId = [];
            customerId.push(data.customerId[i]);
            for (let i = 0; i < data.orgId.length; i++) {
              orgId.push(data.orgId[i])
            }

          }
        }
        data["customerId"] = customerId;
        data["orgId"] = orgId;
      }
      if (data.currency.length > 0) {
        if (data.currency[0].itemName) {
          data["currency"] = data.currency[0].itemName;
        } else {
          data["currency"] = data.currency
        }
      } else {
        data["currency"] = null;
      }

      if (data.expCloseDate != null) {
        var dateval = data.expCloseDate.jsdate;
        data.expCloseDate = moment(dateval).format("YYYY-MM-DD")
      }
      data.createdBy = this.userid;
      data.updatedBy = this.userid;
      data.managerId = data.managerId[0].id;
      if (data.certifierId != null && data.certifierId.length > 0 ) {
        data.certifierId = data.certifierId[0].id;
      } else {
        data.certifierId = null;
      }
      if (data.councilId != null && data.councilId.length > 0 ) {
        data.councilId = data.councilId[0].id;
      } else {
        data.councilId = null;
      }
      this.http.post('/api/project/', data).subscribe((response) => {
        this.res = response;
        if (this.res.success == true) {
          this.body = {};
          this.http.get('/api/projectInfo/project/' + this.res.response.id).subscribe((res2: Response) => {
            this.result = res2;
              this.body["projectId"] = this.res.response.id;
              this.body["id"] = this.result.ProjectInfoId;
              this.body["title"] = data.title;
              this.body["shortdesc"] = data.shortdesc;
              this.body["projectStateType"] = data.projectStateType[0].itemName;
              this.body["projectTypeId"] = data.projectTypeId[0].id;
              this.body["ausState"] = data.ausState[0].id.toString();
              this.body["managerId"] = data.managerId;
              this.body["code"] = data.code;
              if (data.certifierId ) {
                this.body["certifierId"] = data.certifierId;
              }
              if (data.councilId ) {
                this.body["councilId"] = data.councilId;
              }
              this.body["createdDate"] = data.createdDate;
              this.body["createdBy"] = parseInt(this.userid);
              this.body["updatedBy"] = parseInt(this.userid);
              this.body["domainId"] = parseInt(this.loggedInUserDomain);
              this.body["unit"] = data.unit;
              this.body["address1"] = data.address1;
              this.body["address2"] = data.address2;
              this.body["city"] = data.city;
              this.body["state"] = data.state;
              this.body["country"] = data.country;
              this.body["postalCode"] = data.postalCode;
              this.body["isActive"] = "Y";
              this.body["isHardDeleted"] = "N";
              this.http.put('/api/projectInfo', this.body).subscribe((response) => {

                this.res = response;
              });
            });

          localStorage.removeItem('projectStateId');
          localStorage.removeItem('projectStateName');
          localStorage.removeItem('expCloseDate');
          localStorage.removeItem('shortdesc');
          localStorage.removeItem('value');
          localStorage.removeItem('currency');
          localStorage.removeItem('title');
          this.resetForm();
          this.toastr.clear();
          this.toastr.success(this.res.message, 'Success', {
            timeOut: 6000,
            closeButton: true
          });
          this.toggleCreateMenu();
          this.dealArr = [];
          if (this.dealHidePage == true) {
            location.reload();
          } else {
            this.dealArr = [];
            this.dealstateArr1 = [];
            this.getDeal();
          }

        } else {
          this.spinner.hide();
          this.toastr.clear();
          this.toastr.error(this.res.message, 'Error', {
            timeOut: 6000,
            closeButton: true
          });
        }

      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      })

    }
  }

  async toggleCreateMenu() {
    // this.customersArr =[];
    // var result = await this.DetailsService.getCustomers();
    // this.result1 = result;
    // for (let i = 0; i < this.result1.length; i++) {
    //   this.customersArr.push({
    //     "id": this.result1[i].details.id, "itemName": this.result1[i].details.firstName + " " + this.result1[i].details.lastName,"orgId":this.result1[i].details.orgId
    //   })
    // }

    this.settings = {
      text: "Select ..",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: true,
      enableCheckAll: false
    };
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.titleErr = null;
    this.shortdescErr = null;
    this.projectStateIdErr = null;
    this.valueErr = null;
    this.customerErr = null;
    this.dateErr = null;
    this.isCreate = true;
    this.dsid = undefined;
    this.resetForm();
    this.currencyArrSelect = [];
    this.currencyArrSelect.push({
      "id": "AUD", "itemName": "AUD"
    })
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
  }

  public resetForm() {
    this.form.reset({
      projectStateId: [],
      customerId: [],
      expCloseDate: '',
      title: '',
      value: '',
      date: '',
      shortdesc: '',
      currency: [],
      createdDate: '',
      createdBy: '',
      orgId: '',
      unit: '',
      certifierId: null,
      councilId: null,
      managerId: [],
      ausState: [],
      address1: '',
      address2: '',
      city: 'Sydney',
      state: 'New South Wales',
      country: 'Australia',
      postalCode: '',
      projectStateType: [],
      projectTypeId:[],
    })
    this.form.patchValue({ expCloseDate: null });

  }


  createForm() {
    this.form = this.formBuilder.group({
      projectStateId: '',
      date: '',
      customerId: '',
      title: ['', Validators.required],
      value: '',
      shortdesc: ['', Validators.required],
      expCloseDate: '',
      currency: [],
      createdDate: '',
      createdBy: '',
      orgId: '',
      certifierId: null,
      councilId: null,
      managerId: [],
      note: '',
      code: '',
      approvedBy: '',
      approvalSign: '',
      projectCompleted: '',
      approvalCompleted: '',
      isHardDeleted: '',
      dealCompleted: '',
      unit: '',
      ausState: [],
      address1: '',
      address2: '',
      city: 'Sydney',
      state: 'New South Wales',
      country: 'Australia',
      postalCode: '',
      projectStateType: [],
      projectTypeId:[],
    })
    this.projectForm = this.formBuilder.group({
      certifierId: '',
      managerId: ''

    })
  }

  validate() {
    this.titleErr = null;
    this.shortdescErr = null;
    this.projectStateIdErr = null;
    this.valueErr = null;
    this.customerErr = null;
    this.dateErr = null;
    this.currencyErr = null;
    this.typeErr = null;
    this.StateErr = null;
    this.managerErr = null;
    this.buildErr=null;
    this.centrifiedErr = null;

    var data = this.form.value;
    var valid = true;
    const strongRegex = /^[:;,\-@0-9a-zA-Zגיט'.\s]{1,10000}$/;
    const validNumber = /^\d+$/;
    if (data.customerId.length == 0) {
      this.customerErr = "Customer is required.";
      valid = false;
    }
    else if (data.projectStateId.length == 0) {
      this.projectStateIdErr = "Deal state is required.";
      valid = false;
    }
    else if (data.title == undefined || data.title == '') {
      this.titleErr = "Deal name is required.";
      valid = false;
    }
    else if (data.shortdesc == undefined || data.shortdesc == '') {
      this.shortdescErr = "Description is required ";
      valid = false;
    }
    // else if (!strongRegex.test(data.shortdesc)) {
    //   this.shortdescErr = "Max Length Of 10000 Characters.";
    //   valid = false;
    // }
    else if (data.shortdesc.length > 10000) {
      this.shortdescErr = "Max Length Of 10000 Characters.";
      valid = false;
    }
    else if (!data.value) {
      this.valueErr = "Deal Value is required.";
      valid = false;
    } else if (!validNumber.test(data.value)) {
      this.valueErr = "Invalid Value";
      valid = false;
    }
    else if (data.currency.length == 0) {
      this.currencyErr = "Currency is required";
      valid = false;
    }
    else if (!data.expCloseDate) {
      this.dateErr = "Closing date is required";
      valid = false;
    } else if (data.projectStateType.length == 0) {
      this.typeErr = "Project Type is required";
      valid = false;
    }
    else if (data.ausState.length == 0) {
      this.StateErr = "State is required.";
      valid = false;
    }
    else if (data.projectTypeId == null || data.projectTypeId.length == 0) {
    this.buildErr = "building Type is required.";
    valid = false;
  }
    else if (data.managerId == null || data.managerId.length == 0) {
      this.managerErr = "Manager Name is required.";
      valid = false;
    }

    return valid;
  }

  validateCertifier() {
    this.managerErr = null;
    this.centrifiedErr = null;
    var data = this.projectForm.value;
    var valid = true;
    if (data.managerId.length == 0) {
      this.managerErr = "Manager is required.";
      valid = false;
    }
    // else if (data.certifierId.length == 0) {
    //   this.centrifiedErr = "Certifier is required.";
    //   valid = false;
    // }
    return valid;
  }


  toggleEditMenu(id) {
    this.settings = {
      text: "Select User",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: true,
      enableCheckAll: false
    };
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.customersArrselect = [];
    this.dealstateArr1select = [];
    this.titleErr = null;
    this.dateErr = null;
    this.shortdescErr = null;
    this.projectStateIdErr = null;
    this.valueErr = null;
    this.customerErr = null;
    this.isCreate = false;
    this.did = id;
    this.http.get('/api/project/' + this.did).subscribe((res: Response) => {
      this.result = res;
      if (this.result) {
        this.form.patchValue({ 'id': this.result.id });
        this.form.patchValue({ 'title': this.result.title });
        this.form.patchValue({ 'value': this.result.value });
        this.form.patchValue({ 'longdesc': this.result.longdesc });
        this.form.patchValue({ 'shortdesc': this.result.shortdesc });
        this.form.patchValue({ 'createdDate': this.result.createdDate });
        this.form.patchValue({ 'createdBy': this.result.createdBy });
        this.form.patchValue({ 'updatedDate': this.result.updatedDate });
        this.form.patchValue({ 'updatedBy': this.result.updatedBy });
        this.form.patchValue({ 'note': this.result.note });
        this.form.patchValue({ 'code': this.result.code });
        this.form.patchValue({ 'approvedBy': this.result.approvedBy });
        this.form.patchValue({ 'approvalSign': this.result.approvalSign });
        this.form.patchValue({ 'projectCompleted': this.result.projectCompleted });
        this.form.patchValue({ 'approvalCompleted': this.result.approvalCompleted });
        this.form.patchValue({ 'isHardDeleted': this.result.isHardDeleted });
        this.form.patchValue({ 'projectCompleted': this.result.projectCompleted });
        this.form.patchValue({ 'dealCompleted': this.result.dealCompleted });
        // this.form.patchValue({ 'orgId': this.result.orgId });
        var cdate = this.result.expCloseDate;
        if (cdate) {
          var ydate = moment(cdate).format("YYYY")
          var mdate = moment(cdate).format("M")
          var ddate = moment(cdate).format("D")
          this.form.patchValue({
            'expCloseDate': {
              date: {
                year: ydate,
                month: mdate,
                day: ddate
              }
            }
          });
        } else {
          this.form.patchValue({ expCloseDate: null });
        }
        this.currencyArrSelect = [];
        if (this.result.currency) {
          this.currencyArr.forEach(r => {
            if (this.result.currency == r.itemName)
              this.currencyArrSelect.push({
                "id": r.itemName, "itemName": r.itemName
              })
          })
        }

        this.http.get('/api/projectCustomer/project/' + this.result.id).subscribe((res1: Response) => {
          this.result = res1;
          for (let i = 0; i < this.result.length; i++) {
            this.customersArrselect.push({
              "id": this.result[i].cust.id, "itemName": this.result[i].cust.firstName + " " + this.result[i].cust.lastName, "orgId": this.result[i].cust.orgId
            })
          }
        }, error => {
          this.toastr.clear();
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.errorService.handleError(error);
        });
        this.http.get('/api/projectState/' + this.result.projectStateId).subscribe((res1: Response) => {
          this.result = res1;
          this.dealstateArr1select.push({
            "id": this.result.id, "itemName": this.result.name
          })
        }, error => {
          this.toastr.clear();
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.errorService.handleError(error);
        });

        // getProject info details
        this.http.get('/api/projectInfo/project/' + this.did).subscribe((res2: Response) => {
          this.result = res2;
          if (this.result.ProjectInfoId != null) {
            this.http.get('/api/projectInfo/' + this.result.ProjectInfoId).subscribe((res: Response) => {
              this.result = res;
              this.result1 = this.result.projectInfo;
              this.result2 = this.result.projectAddress;
              this.result3 = this.result.project;
              if (this.result) {
//                this.pinfoid = this.result1.id;
//                this.projectName= this.result3.title;
//                this.projform.patchValue({ 'id': this.result1.projectId });
//                this.projform.patchValue({ 'input1': this.result3.title });
//                this.projectform.patchValue({ 'Code': this.result1.code });
//                this.projectform.patchValue({ 'id': this.result1.id });
//                this.projectform.patchValue({ 'createdDate': this.result1.createdDate });
//                this.projectform.patchValue({ 'createdBy': this.result1.createdBy });
                if (this.result1.projectStateType != null) {
                  if (this.result1.projectStateType == "DA") {
                    this.selectedItems = [];
                    this.selectedItems.push({ "id": 1, "itemName": this.result1.projectStateType })
                  } else if (this.result1.projectStateType == "CC") {
                    this.selectedItems = [];
                    this.selectedItems.push({ "id": 2, "itemName": this.result1.projectStateType })
                  } else if (this.result1.projectStateType == "SubDivision") {
                    this.selectedItems = [];
                    this.selectedItems.push({ "id": 3, "itemName": this.result1.projectStateType })
                  } else if (this.result1.projectStateType == "CDC") {
                    this.selectedItems = [];
                    this.selectedItems.push({ "id": 4, "itemName": this.result1.projectStateType })
                  }
                }
                if (this.result1.ausState != null) {
                  this.http.get('/api/australianStates/' + this.result1.ausState).subscribe((res: Response) => {
                    this.result5 = res;
                    this.stateArrselect.push({
                      "id": this.result5.id, "itemName": this.result5.name,
                    })
                  }, error => {
                    this.toastr.clear();
                    this.toastr.error(error, 'Report Status', {
                      timeOut: 6000,
                      closeButton: true
                    });
                    this.errorService.handleError(error);
                  });
                }
                      if (this.result1.projectTypeId != null) {
                  this.http.get('/api/projectType/' + this.result1.projectTypeId).subscribe((res: Response) => {
                    this.result = res;
                    this.buidingArrselect.push({
                      "id": this.result .id, "itemName": this.result .type,
                    })
                  }, error => {
                    this.toastr.clear();
                    this.toastr.error(error, 'Report Status', {
                      timeOut: 6000,
                      closeButton: true
                    });
                    this.errorService.handleError(error);
                  });
                }
                this.usersArrselect = [];
                if (this.result.persons.manager != "") {
                  this.usersArrselect.push({
                    "id": this.result1.managerId, "itemName": this.result.persons.manager,
                  })
                }
                this.certifierArrselect = [];
                if (this.result.persons.certifier != "") {
                  this.certifierArrselect.push({
                    "id": this.result1.certifierId, "itemName": this.result.persons.certifier,
                  })
                }
                this.councilArrSelect = [];
                if (this.result.persons.council != "") {
                  this.councilArrSelect.push({
                    "id": this.result1.councilId, "itemName": this.result.persons.council,
                  })
                }
                if (this.result2) {
                  this.form.patchValue({ 'unit': this.result2.unit });
                  this.form.patchValue({ 'address1': this.result2.address1 });
                  this.form.patchValue({ 'address2': this.result2.address2 });
                  this.form.patchValue({ 'city': this.result2.city });
                  this.form.patchValue({ 'state': this.result2.state });
                  this.form.patchValue({ 'country': this.result2.country });
                  this.form.patchValue({ 'postalCode': this.result2.postalCode });
                //   this.projectform.patchValue({ 'createdDate': this.result2.createdDate });
                //   this.projectform.patchValue({ 'createdBy': this.result2.createdBy });
                }
              }
            }, error => {
              this.toastr.clear();
              this.toastr.error(error, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.errorService.handleError(error);
            });
           }
          }, error => {
            this.toastr.clear();
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.errorService.handleError(error);
          });


      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
    this.menuState = this.menuState === 'out' ? 'in' : 'out';

  }


  updateDeal() {
    if (this.validate()) {
      this.body = {};
      this.body["id"] = this.did;
      var data = this.form.value;
      var customerId = [];
      var orgId = [];
      if (data.customerId.length > 0) {
        for (let i = 0; i < data.customerId.length; i++) {
          if (data.customerId[i].id) {
            customerId.push(data.customerId[i].id);
            orgId.push(data.customerId[i].orgId)
          } else {
            var orgId = [];
            customerId.push(data.customerId[i]);
            for (let i = 0; i < data.orgId.length; i++) {
              orgId.push(data.orgId[i])
            }

          }
          this.body["customerId"] = customerId;
          this.body["orgId"] = orgId;
        }
        if (data.currency.length > 0) {
          if (data.currency[0].itemName) {
            this.body["currency"] = data.currency[0].itemName;
          } else {
            this.body["currency"] = data.currency
          }
        } else {
          this.body["currency"] = null;
        }
        this.body["code"] = this.form.value["code"];
        this.body["approvedBy"] = this.form.value["approvedBy"];
        this.body["approvalSign"] = this.form.value["approvalSign"];
        this.body["projectStateId"] = this.form.value["projectStateId"][0].id;
        this.body["title"] = this.form.value["title"];
        this.body["value"] = this.form.value["value"];
        this.body["createdDate"] = this.form.value["createdDate"];
        this.body["createdBy"] = this.form.value["createdBy"];
        this.body["note"] = this.form.value["note"];
        this.body["updatedDate"] = new Date();
        this.body["updatedBy"] = parseInt(this.userid);
        this.body["longdesc"] = null;
        this.body["shortdesc"] = this.form.value["shortdesc"];
        if (this.form.value["expCloseDate"]) {
          var expCloseDate = moment(this.form.value["expCloseDate"].jsdate).format("YYYY-MM-DD")
          this.body["expCloseDate"] = expCloseDate;
        } else {
          this.body["expCloseDate"] = this.form.value["expCloseDate"];
        }
        this.body["isActive"] = "Y";
        this.body["isHardDeleted"] = "N";
        this.body["dealCompleted"] = "F";
        this.body["approvalCompleted"] = "F";
        this.body["projectCompleted"] = "F";
        data.managerId = data.managerId[0].id;
        if (data.certifierId != null && data.certifierId.length > 0 ) {
          data.certifierId = data.certifierId[0].id;
        } else {
          data.certifierId = null;
        }
        if (data.councilId != null && data.councilId.length > 0 ) {
          data.councilId = data.councilId[0].id;
        } else {
          data.councilId = null;
        }
        if (data.managerId != null && data.managerId.length > 0 ) {
          data.managerId = data.managerId[0].id;
        }

        this.spinner.show();
        this.http.put('/api/project/', this.body).subscribe((response) => {
          this.res = response;
          if (this.res.success == true) {
            this.body = {};
            this.http.get('/api/projectInfo/project/' + this.res.response.id).subscribe((res2: Response) => {
              this.result = res2;
                this.body["projectId"] = this.res.response.id;
                this.body["id"] = this.result.ProjectInfoId;
                this.body["title"] = data.title;
                this.body["shortdesc"] = data.shortdesc;
                this.body["projectStateType"] = data.projectStateType[0].itemName;
                this.body["projectTypeId"] = data.projectTypeId[0].id;
                this.body["ausState"] = data.ausState[0].id.toString();
                this.body["managerId"] = data.managerId;
                this.body["code"] = data.code;
                if (data.certifierId ) {
                  this.body["certifierId"] = data.certifierId;
                }
                if (data.councilId ) {
                  this.body["councilId"] = data.councilId;
                }
                this.body["createdDate"] = data.createdDate;
                this.body["createdBy"] = parseInt(this.userid);
                this.body["updatedBy"] = parseInt(this.userid);
                this.body["domainId"] = parseInt(this.loggedInUserDomain);
                this.body["unit"] = data.unit;
                this.body["address1"] = data.address1;
                this.body["address2"] = data.address2;
                this.body["city"] = data.city;
                this.body["state"] = data.state;
                this.body["country"] = data.country;
                this.body["postalCode"] = data.postalCode;
                this.body["isActive"] = "Y";
                this.body["isHardDeleted"] = "N";
                this.http.put('/api/projectInfo', this.body).subscribe((response) => {

                  this.res = response;
                });
              });
            this.resetForm();
            this.toastr.clear();
            this.toastr.success(this.res.message, 'Success', {
              timeOut: 6000,
              closeButton: true
            });
            this.toggleCreateMenu();
            this.dealArr = [];
            this.dealstateArr1 = [];
            this.getDeal();
          } else {
            this.spinner.hide();
            this.toastr.clear();
            this.toastr.error(this.res.message, 'Error', {
              timeOut: 6000,
              closeButton: true
            });
          }
        }, error => {
          this.toastr.clear();
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.errorService.handleError(error);
        })
      }

    }
  }
  deleteDeal() {
    this.toggleCreateMenu();
    this.confirmService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) => {
        if (confirmed) {
          this.spinner.show();
          this.http.delete('/api/project/' + this.did).subscribe((response) => {
            this.res = response;
            if (this.res.success == true) {
              this.resetForm();
              this.toastr.clear();
              this.toastr.success(this.res.message, 'Success', {
                timeOut: 6000,
                closeButton: true
              });
              this.dealArr = [];
              this.dealstateArr1 = [];
              this.getDeal();
            } else {
              this.spinner.hide();
              this.toastr.clear();
              this.toastr.error(this.res.message, 'Error', {
                timeOut: 6000,
                closeButton: true
              });
            }
          }, error => {
            this.toastr.clear();
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.errorService.handleError(error);
          })
        }
      })
      .catch(() => {
        return false;
      });
  }

  archieveDeal() {
    this.toggleCreateMenu();
    this.confirmService.confirm('Please confirm..', 'Do you really want to archive ?')
      .then((confirmed) => {
        if (confirmed) {
          this.spinner.show();
          this.http.delete('/api/project/archive/' + this.did + '/1').subscribe((response) => {
            this.res = response;
            if (this.res.success == true) {
              this.resetForm();
              this.toastr.clear();
              this.toastr.success(this.res.message, 'Success', {
                timeOut: 6000,
                closeButton: true
              });
              this.dealArr = [];
              this.dealstateArr1 = [];
              this.getDeal();
            } else {
              this.spinner.hide();
              this.toastr.clear();
              this.toastr.error(this.res.message, 'Error', {
                timeOut: 6000,
                closeButton: true
              });
            }
          }, error => {
            this.toastr.clear();
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.errorService.handleError(error);
          })
        }
      })
      .catch(() => {
        return false;
      });
  }

  getCertifier() {
    this.certifierArr = [];
    this.http.get('/api/certifiers/').subscribe((res: Response) => {
      this.result = res;
      if (this.result.length != 0) {
        this.movetocerti = false;
        for (let i = 0; i < this.result.length; i++) {
          this.certifierArr.push({
            "id": this.result[i].id, "itemName": this.result[i].firstName + " " + this.result[i].lastName,
          })
        }
      }
      else {
        this.movetocerti = true;

      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }

  getCouncil() {
    this.councilArr = [];
    this.http.get('/api/council/').subscribe((res: Response) => {
      this.result = res;
      if (this.result.length != 0) {
        this.movetocerti = false;
        for (let i = 0; i < this.result.length; i++) {
          this.councilArr.push({
            "id": this.result[i].id, "itemName": this.result[i].firstName + " " + this.result[i].lastName,
          })
        }
      }
      else {
        this.movetocerti = true;

      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }

  noteEdit() {
    this.editSh = false;
    this.dealArr = [];
    this.getDeal();
  }
  ClosetoggleCreateMenu() {
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.titleErr = null;
    this.shortdescErr = null;
    this.projectStateIdErr = null;
    this.valueErr = null;
    this.customerErr = null;
    this.dateErr = null;
    this.isCreate = true;
    this.dsid = undefined;
    this.resetForm();
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
  }
  OpenSliderCustomer() {
    var data = this.form.value;
    if (data.projectStateId.length > 0) {
      localStorage.setItem("projectStateId", data.projectStateId[0].id);
      localStorage.setItem("projectStateName", data.projectStateId[0].itemName);
    }
    if (data.expCloseDate != null) {
      var dateval = data.expCloseDate.jsdate;
      data.expCloseDate = moment(dateval).format("DD-MM-YYYY");
      localStorage.setItem("expCloseDate", dateval);
    } else {
      localStorage.setItem("expCloseDate", null);

    }

    localStorage.setItem("shortdesc", data.shortdesc);
    localStorage.setItem("value", data.value);
    localStorage.setItem("title", data.title);
    if (data.currency.length > 0) {
      localStorage.setItem("currency", data.currency[0].itemName);
    }
    this.spinner.show();
    this.ClosetoggleCreateMenu();
    this.customerSlider = false;
    setTimeout(() => {
      this.customerSlider = !this.customerSlider;
      this.spinner.hide();
    }, 1000);
  }
  certifierSlierOpen() {
    this.editSh = false;
    this.certifierSliderCheck = true;
  }
  userSlierOpen() {
    this.editSh = false;
    this.userSliderCheck = true;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http,Response} from "@angular/http";

import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable()

export class DetailsService {

  private dealsUrl = '/api/deals';  // URL to web api Blog List
  private projectUrl = '/api/project';  // URL to web api Blog View
  private customerUrl = '/api/customer';  // URL to web api Blog View
  private projectsroleUrl = '/api/projectUserRoleByProjectId';
  private projectlogUrl = 'api/projects/activities/projectLogs';
  private projecttaskUrl = '/api/tasks';
  private allActivitiyUrl = '/api/projects/activities';
  private allActivitiyForProjectUrl = '/api/projects/Projectactivities/';
  private taskLogyUrl = '/api/projects/activities/tasks';
  private notesLogyUrl = '/api/projects/activities/notes';
  private dateCountUrl = '/api/projectLogs/duration';
  private approvaldateCountUrl = '/api/projectLogs/approvalDuration';
  private projectdateCountUrl = '/api/projectLogs/projectDuration';
  private mailContentUrl = '/api/mail/contents';
  private rolesPermissionUrl = '/api/user/RoleDivision/';
  private uploadFileUrl = '/api/files/deal';
  private uploadFileProjectUrl = '/api/files/project';
  private orgUrl = '/api/org';
  private taskUrl = '/api/tasks';
  private usersUrl = 'api/user';
  private userslistUrl ='api/userList/';
  private roleUrl = '/api/role';
  private notesUrl = '/api/notes/internal';
  private notesUrlexternal = '/api/notes/external';
  private userscustUrl = 'api/user/all'
  private basicHomeData = '/api/basicPackages';
  private basicHomeFilterData='/api/basicPackage/filter';
  private estimationCheckData='/api/estimation/project';
  private estimationCreateData='/api/estimation/newVersion';
  private designGroupData='/api/designGroups/estimationPreview';
  private estimationProbCheckData='/api/estimationProperty';
  private updateEstPropData='/api/estimationProperty';
  private setSelHomeData='api/estimation';
  private totalEstimationCheckData='/api/estimationProperty/totalvalue';
  private finalEstimationCheckData='/api/estimationProperty/preview';
  private generateEstimationDocData='/api/generatePDF/estimation';
  private estimationVersionUrl ='/api/estimationVersion/project';
  private estimationVersionDetails ='/api/estimationVersion';
  private setSelHomeDataVersion='api/estimationVersion/BasicPackageChange';

  constructor(private http: HttpClient) { }

  /** GET heroes from the server */
  getDetail(): Observable<any> {
    return this.http.get(this.dealsUrl)
  }

  getDetailsID(id: number) {
    const url = `${this.projectUrl}/${id}`;
    return this.getHttpResponse(url);
  }
  getCustomerID(id: number) {
    const url = `${this.customerUrl}/${id}`;
    return this.getHttpResponse(url);
  }
  getProjectsUser(id: number) {
    const url = this.projectsroleUrl + "/" + id;
    return this.getHttpResponse(url);
  }

  getRoleID(id: number) {
    const url = `${this.roleUrl}/${id}`;
    return this.getHttpResponse(url);
  }

  getProjectLog(id: number) {
    const url = `${this.projectlogUrl}/${id}`;
    return this.getHttpResponse(url);
  }
  
  getProjectTaskId(id: number) {
    const url = `${this.projecttaskUrl}/${id}`;
    return this.getHttpResponse(url);
  }
  getTaskId(id: number) {
    const url = `${this.taskUrl}/${id}`;
    return this.getHttpResponse(url);
  }
  getOrgId(id: number) {
    const url = `${this.orgUrl}/${id}`;
    return this.getHttpResponse(url);
  }
 allActivitiy(id: number) {
    const url = `${this.allActivitiyUrl}/${id}`;
    return this.getHttpResponse(url);
  }
   allActivitiyForProject(id: number) {
    const url = `${this.allActivitiyForProjectUrl}/${id}`;
    return this.getHttpResponse(url);
  }
  taskLogId(id: number) {
    const url = `${this.taskLogyUrl}/${id}`;
    return this.getHttpResponse(url);
  }
  noteLogId(id: number) {
    const url = `${this.notesLogyUrl}/${id}`;
    return this.getHttpResponse(url);
  }
  getNotesDetailsID(id: number,uid:number) {
    const url = `${this.notesUrl}/${id}/${uid}/${"deal"}`;
    return this.getHttpResponse(url);
  }
  getNotesDetailsIDexternsl(id: number,uid:number) {
    const url = `${this.notesUrlexternal}/${id}/${uid}/${"deal"}`;
    return this.getHttpResponse(url);
  }
  getProjectNotesDetailsID(id: number,uid:number) {
    const url = `${this.notesUrl}/${id}/${uid}/${"project"}`;
    return this.getHttpResponse(url);
  }
  getProjectNotesDetailsIDexternal(id: number,uid:number) {
    const url = `${this.notesUrlexternal}/${id}/${uid}/${"project"}`;
    return this.getHttpResponse(url);
  } 
  getApprovalNotesDetailsID(id: number,uid:number) {
    const url = `${this.notesUrl}/${id}/${uid}/${"approvals"}`;
    return this.getHttpResponse(url);
  }
  getApprovalNotesDetailsIDexternal(id: number,uid:number) {
    const url = `${this.notesUrlexternal}/${id}/${uid}/${"approvals"}`;
    return this.getHttpResponse(url);
  }

  dateCount(id : number){
    const url = `${this.dateCountUrl}/${id}`;
    return this.getHttpResponse(url);
  }
  approvaldateCount(id : number){
    const url = `${this.approvaldateCountUrl}/${id}`;
    return this.getHttpResponse(url);
  }
  projectdateCount(id : number){
    const url = `${this.projectdateCountUrl}/${id}`;
    return this.getHttpResponse(url);
  }
  uploadFile(id : number){
    const url = `${this.uploadFileUrl}/${id}`;
    return this.getHttpResponse(url);
  }
  uploadProjectFile(id : number){
    const url = `${this.uploadFileProjectUrl}/${id}`;
    return this.getHttpResponse(url);
  }
  emailContents(id : number){
    const url = `${this.mailContentUrl}/${id}`;
    return this.getHttpResponse(url);
  }
  rolesPermission(type){
    const url = `${this.rolesPermissionUrl}/${type}`;
    return this.getHttpResponse(url);
  }
  getUsers() {
    return this.getHttpResponse(this.usersUrl);
  }
  getCustomers() {
    return this.getHttpResponse(this.customerUrl);
  }
  getCustUsers() {
    return this.getHttpResponse(this.userscustUrl);
  }

  getRoles() {
    return this.getHttpResponse("/api/roles");
  }

  getOrgs() {
    return this.getHttpResponse("/api/orgs");
  }
  
  getTasks() {
    return this.getHttpResponse("/api/tasks");
  }

  getDealStates() {
    return this.getHttpResponse("/api/projectStates/deals");
  }

  getHttpResponse(url) {
    return this.http.get(url).toPromise();
  }
  getUserslist(type) {
    const url = `${this.userslistUrl}/${type}`;
    return this.getHttpResponse(url);
  }  
  getCurrency() {
    return this.getHttpResponse("/api/project/currency");
  }
  getALLDesignGroups() {
    return this.getHttpResponse("/api/designGroups");
  }
  getpropertyType() {
    return this.getHttpResponse("/api/propertyTypes");
  }
  // getHttpResponse(url) {
  //   return this.http.get(url).toPromise();
  // }
  getBasicHomePack(){
    const url = `${this.basicHomeData}`;
    return this.getHttpResponse(url);
  }
  // getFilterHomePack(filterValues){
  //   const url = `${this.basicHomeFilterData}`;
  //   return this.getHttpResponse(url);
  // }
  getFilterHomePack(filterValues){
    const url = `${this.basicHomeFilterData}`;
    return this.http.post(url, filterValues).toPromise()
              .then((response: Response) => response)
  }
  getEstimationCheck(id){
    const url = `${this.estimationCheckData}/${id}`;
    return this.getHttpResponse(url);
    // return this.http.get(url).toPromise()
    //           .then((response: Response) => response.json())
  }
  createEstimationId(data){
    const url = `${this.estimationCreateData}`;
    return this.http.post(url,data).toPromise()
              .then((response: Response) => response)
  }
  getDesignGroup(estimationId){
    const url = `${this.designGroupData}/${estimationId}`;
    return this.getHttpResponse(url);
  }
  getEstimationPropCheck(designId,estimationId){
    const url = `${this.estimationProbCheckData}/estimationVersion/${estimationId}/group/${designId}`;
    return this.getHttpResponse(url);
  }
  updateEstimationProp(data){
    const url = `${this.updateEstPropData}`;
    return this.http.post(url, data).toPromise()
              .then((response: Response) => response)
  }
  setSelectedHomePack(data){
    const url = `${this.setSelHomeData}`;
    return this.http.put(url, data).toPromise()
              .then((response: Response) => response)
  }
  setSelectedHomePackVersion(data){
    const url = `${this.setSelHomeDataVersion}`;
    return this.http.put(url, data).toPromise()
              .then((response: Response) => response)
  }
  getTotalEstimationAmount(estimationId){
    const url = `${this.totalEstimationCheckData}/${estimationId}`;
    return this.getHttpResponse(url);
  }
  getEstimationFinalCheck(estimationId){
    const url = `${this.finalEstimationCheckData}/${estimationId}`;
    return this.getHttpResponse(url);
  }
  generateEstimationDoc(estimationId, userId){
    const data ={};
    const url = `${this.generateEstimationDocData}/${estimationId}/user/${userId}`;
    return this.http.post(url, data).toPromise()
              .then((response: Response) => response)
  }
  getEstimationVersion(id: number) {
    const url = `${this.estimationVersionUrl}/${id}`;
    return this.getHttpResponse(url);
  }
  getDetailsestimationVersion(id: number) {
    const url = `${this.estimationVersionDetails}/${id}`;
    return this.getHttpResponse(url);
  }
  getaustralianStates() {
    return this.getHttpResponse("/api/australianStates");
  }
  getprojectTypes() {
    return this.getHttpResponse("/api/projectTypes");
  }
}

import { Injectable } from '@angular/core';
import * as moment from 'moment/moment.js';


@Injectable()
export class AuthService {

  constructor() {}

  // ...
  public isAuthenticated(): boolean {

    var todaydate =new Date();
    var tdate = moment(todaydate).format("YYYY-MM-DD")
    const token = localStorage.getItem('loggedIn');
    const date = localStorage.getItem('loggedIndate');
    // Check whether the token is expired and return
    // true or false
    if(tdate == date){
      return token == 'true' ? true: false;
    }else{
      return false;
    }
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders, HttpResponse  } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Http, Headers, RequestOptions} from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
 
@Injectable()
export class ErrorService {

	constructor(private httpClient: HttpClient,private toastr: ToastrService, private spinner: NgxSpinnerService) { }
	handleError(error){
		if(error){
			this.spinner.hide();
			this.toastr.clear();
			this.toastr.error('Operation was not successful. Please contact Admin', 'Server error', {
				timeOut: 6000,
				closeButton:true
			});
		}
	}
}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '../../_services';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { ErrorService } from 'src/app/_services/error.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.css']
})
export class ArchiveComponent implements OnInit {
  ArchiveArr = [];
  DealarchiveArr=[];
  ProjarchArr=[];
  result: any;
  id:any;
  dealtab:boolean=true;
  approavltab:boolean=false;
  projecttab:boolean=false;
  tab1 : any = 'tab1';
  tab2 : any;
  tab3 : any;
  step:any;
  key: any;
  sort: any;
  filter: any;
  reverse: any;
  p1: any;
  p2: any;
  p3: any;
  term: any;

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private errorService: ErrorService,
    private confirmService: ConfirmService,
    private spinner: NgxSpinnerService,
    private http: HttpClient, 
  ) { }

  ngOnInit() {
    this.getApprovalArchive();
    this.getDealArchive();
    this.getProjectArchive();
    this.step="step1";
  }
  tabs1(){
    this.term = null;
    this.dealtab=true;
    this.approavltab=false;
    this.projecttab=false
  }
  tabs2(){
    this.term = null;
    this.dealtab=false;
    this.approavltab=true;
    this.projecttab=false
  }
  tabs3(){
    this.term = null;
    this.dealtab=false;
    this.approavltab=false;
    this.projecttab=true
  }
  
  
  getApprovalArchive() {
    this.spinner.show();
    this.http.get('/api/projects/approvals/archived').subscribe((res: Response) => {
      this.result = res;
      this.spinner.hide();
      for (let i = 0; i <  this.result.length; i++) {
        this.ArchiveArr.push({
          "id":this.result[i].id, "title": this.result[i].title

        })
      }
    });
  }
  getDealArchive() {
    this.spinner.show();
    this.http.get('/api/projects/deals/archived').subscribe((res: Response) => {
      this.result =res;
      this.spinner.hide();
      for (let i = 0; i <     this.result.length; i++) {
        this.DealarchiveArr.push({
          "id":     this.result[i].id, "title":     this.result[i].title

        })
      }
    });
  }
  getProjectArchive() {
    this.spinner.show();
    this.http.get('/api/projectInfo/archived').subscribe((res: Response) => {
      this.result = res;
      this.spinner.hide();
      for (let i = 0; i <  this.result.length; i++) {
        this.ProjarchArr.push({
          "projectInfoId":  this.result[i].projectInfoId,
          "projectTitle":  this.result[i].projectTitle,
          "projectId": this.result[i].projectId

        })
      }
    });
  }
  approvalunarchieve(id){
  
      this.spinner.show();
      this.http.delete('/api/project/archive/'+id+'/0').subscribe((res: Response) => {
        this.toastr.clear();
        this.result = res;
        if (this.result.success) {
          this.toastr.success(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
            
          });
     
          this.getApprovalArchive();
         this.ArchiveArr =[];
        }
         else {

          this.toastr.error(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
        
          this.spinner.hide();
        }
      }, error => {
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
        this.getApprovalArchive();
        this.errorService.handleError(error);
      }
      )
 
  }
  dealunarchieve(id){
  
        this.spinner.show();
      this.http.delete('/api/project/archive/'+id+'/0').subscribe((res: Response) => {
        this.toastr.clear();
        this.result = res;
        if (this.result.success) {
          this.toastr.success(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
            
          });
     
          this.getDealArchive();
         this.DealarchiveArr =[];
        }
         else {

          this.toastr.error(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
        
          this.spinner.hide();
        }
      }, error => {
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
        this.getDealArchive();
        this.errorService.handleError(error);
      }
      )
 
  }
  projectunarchieve(id){
   
        this.spinner.show();
      this.http.delete('/api/projectInfo/archive/'+ id +'/0').subscribe((res: Response) => {
        this.toastr.clear();
        this.result = res;
        if (this.result.success) {
          this.toastr.success(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
            
          });
                   
          this.getProjectArchive();
         this.ProjarchArr =[];
        }
         else {

          this.toastr.error(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
        
          this.spinner.hide();
        }
      }, error => {
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
        this.getProjectArchive();
        this.errorService.handleError(error);
      }
      )
 
  }
  onSwitch(check){

    switch (check) {
     case 1:
       return 'tab1';
     case 2:
       return 'tab2';
     case 3:
       return 'tab3';
    
   }
  }
  onSelectdeals(Dealarch) {
    this.router.navigate(['/details', Dealarch]);
  }
  onSelectapproval(Archive) {
    
    this.router.navigate(['/approvals-details', Archive]);
  }
  onSelectProject(Projarch) {
    this.router.navigate(['/project-details', Projarch]);
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Http,Response, Headers, RequestOptions} from "@angular/http";
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { ErrorService } from 'src/app/_services/error.service';
@Component({
  selector: 'app-print-invoice',
  templateUrl: './print-invoice.component.html',
  styleUrls: ['./print-invoice.component.css']
})
export class PrintInvoiceComponent implements OnInit {

  amountword:any;
  invoiceresult:any;
  result:any;
  domainresult:any;
  atemp:any;
  number1:any;
  n_length:any;
  words_string:any;
  n_array:any;
  received_n_array:any;
  words:any;
  invperiod:any;
  planAmt:any;
  values:any;
  invplanAmt:any;
  description:any;
  Qyt:any;
  invgross:any;
  invplan:any;
  tax:any;
  invtax:any;
  subamt:any;
  constructor( private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router, private errorService: ErrorService,) { 
    this.invoiceresult=[];
    this.domainresult=[];
  }

  ngOnInit() {
     var invoiveId = this.route.snapshot.paramMap.get('invoiveId');
  if (invoiveId)
    this.invoice(invoiveId);
  }
   convertNumberToWords(amount) {
     this.words = new Array();
    this.words[0] = '';
    this.words[1] = 'One';
    this.words[2] = 'Two';
    this.words[3] = 'Three';
    this.words[4] = 'Four';
    this.words[5] = 'Five';
    this.words[6] = 'Six';
    this.words[7] = 'Seven';
    this.words[8] = 'Eight';
    this.words[9] = 'Nine';
    this.words[10] = 'Ten';
    this.words[11] = 'Eleven';
    this.words[12] = 'Twelve';
    this.words[13] = 'Thirteen';
    this.words[14] = 'Fourteen';
    this.words[15] = 'Fifteen';
    this.words[16] = 'Sixteen';
    this.words[17] = 'Seventeen';
    this.words[18] = 'Eighteen';
    this.words[19] = 'Nineteen';
    this.words[20] = 'Twenty';
    this.words[30] = 'Thirty';
    this.words[40] = 'Forty';
    this.words[50] = 'Fifty';
    this.words[60] = 'Sixty';
    this.words[70] = 'Seventy';
    this.words[80] = 'Eighty';
    this.words[90] = 'Ninety';
    amount = amount.toString();
     this.atemp = amount.split(".");
     this.number1 = this.atemp[0].split(",").join("");
     this.n_length = this.number1.length;
     this.words_string = "";
    if (this.n_length <= 9) {
         this.n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
         this.received_n_array = new Array();
        for (var i = 0; i < this.n_length; i++) {
            this.received_n_array[i] = this.number1.substr(i, 1);
        }
        for (var i = 9 - this.n_length, j = 0; i < 9; i++, j++) {
            this.n_array[i] = this.received_n_array[j];
        }
        for (var i = 0, j = 1; i < 9; i++, j++) {
            if (i == 0 || i == 2 || i == 4 || i == 7) {
                if (this.n_array[i] == 1) {
                    this.n_array[j] = 10 + parseInt(this.n_array[j]);
                    this.n_array[i] = 0;
                }
            }
        }
       this.values = "";
        for (var i = 0; i < 9; i++) {
            if (i == 0 || i == 2 || i == 4 || i == 7) {
               this.values = this.n_array[i] * 10;
            } else {
                this.values = this.n_array[i];
            }
            if (this.values != 0) {
                this.words_string += this.words[this.values] + " ";
            }
            if ((i == 1 && this.values != 0) || (i == 0 && this.values != 0 && this.n_array[i + 1] == 0)) {
                this.words_string += "Crores ";
            }
            if ((i == 3 && this.values != 0) || (i == 2 && this.values != 0 && this.n_array[i + 1] == 0)) {
                this.words_string += "Lakhs ";
            }
            if ((i == 5 && this.values != 0) || (i == 4 && this.values != 0 && this.n_array[i + 1] == 0)) {
                this.words_string += "Thousand ";
            }
            if (i == 6 && this.values != 0 && (this.n_array[i + 1] != 0 && this.n_array[i + 2] != 0)) {
                this.words_string += "Hundred and ";
            } else if (i == 6 && this.values != 0) {
                this.words_string += "Hundred ";
            }
        }
        this.words_string = this.words_string.split("  ").join(" ");
    }
    return this.words_string;
}
  invoice(id){
         this.http.get('/api/rp/payment/'+id).subscribe((res: Response) => {
          this.result = res;
          this.invoiceresult =this.result.receipt;
          this.domainresult =this.result.domain;
          var notes =this.invoiceresult.notes;
         this.amountword=this.convertNumberToWords(this.invoiceresult.amount)+" Only.";
         if(notes){
           var data = notes.split(",");

       for (let i = 0; i < data.length; i++) {
         var data2 = data[i].split(":");
         if (data2[0].replace(/[\s]/g, '') == '"plan"' || data2[0].replace(/[\s]/g, '') == '{"plan"') {
           if (data2[1].replace(/[\s]/g, '') != '""') {
            if (data2[1].replace(/[\s]/g, '') == '"1"') {
                   this.invplan=1;
            }else if (data2[1].replace(/[\s]/g, '') == '"2"') {
               this.invplan=2;
            }
           } 
         }
         if (data2[0].replace(/[\s]/g, '') == '"user"' || data2[0].replace(/[\s]/g, '') == '{"user"') {
          if (data2[1].replace(/[\s]/g, '') != '""') {
            var x = data2[1].replace(/[\s]/g, '');
            var y = x.split('"');
            this.Qyt=y[1];
          } 
        }
         if (data2[0].replace(/[\s]/g, '') == '"module"' || data2[0].replace(/[\s]/g, '') == '{"module"') {
          if (data2[1].replace(/[\s]/g, '') != '""') {
            var x = data2[1].replace(/[\s]/g, '');
            var y = x.split('"');
            this.description= y[1];
          } 
        }
         if (data2[0].replace(/[\s]/g, '') == '"planAmt"' || data2[0].replace(/[\s]/g, '') == '{"planAmt"') {
          if (data2[1].replace(/[\s]/g, '') != '""') {
            var x = data2[1].replace(/[\s]/g, '');
            var y = x.split('"');
            this.invplanAmt= y[1];
          } 
        }
          if (data2[0].replace(/[\s]/g, '') == '"tax"' || data2[0].replace(/[\s]/g, '') == '{"tax"') {
          if (data2[1].replace(/[\s]/g, '') != '""') {
             var x = data2[1].replace(/[\s]/g, '');
            var y = x.split('"');
            this.invtax = y[1];
          } 
        }
        if (data2[0].replace(/[\s]/g, '') == '"amount"' || data2[0].replace(/[\s]/g, '') == '{"amount"') {
          if (data2[1].replace(/[\s]/g, '') != '""') {
             var x = data2[1].replace(/[\s]/g, '');
            var y = x.split('"');
            this.subamt = y[1];
          } 
        }
        if (data2[0].replace(/[\s]/g, '') == '"periodtype"' || data2[0].replace(/[\s]/g, '') == '{"periodtype"') {
          if (data2[1].replace(/[\s]/g, '') != '""') {
              if (data2[1].replace(/[\s]/g, '') == '"Month"') {
                  this.invperiod = 1;
            }else if (data2[1].replace(/[\s]/g, '') == '"Quarter"') {
                  this.invperiod = 3
            }else if (data2[1].replace(/[\s]/g, '') == '"Half"') {
                  this.invperiod = 6
            }else if (data2[1].replace(/[\s]/g, '') == '"Year"') {
                  this.invperiod = 12
            }
            
          } 
        }
        }
      }    
        }, error => {
          this.errorService.handleError(error);
        });
        }

        print() {
   
    window.print();
      }

    captureScreen() {
    var data = document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      var imgWidth = 208;
      var pageHeight = 265;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, pageHeight)
      //pdf.save('MYPdf.pdf'); // Generated PDF
      pdf.output('save',  '.pdf');
      
    });
  }
  onselectback(){
    this.router.navigate(['/billing/'])
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment/moment.js';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { EventSesrvice } from 'src/app/_services/event.service';
import { PermissionService } from 'src/app/_services/permission.service';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { DetailsService } from '../_services/index';
import { ErrorService } from 'src/app/_services/error.service';
import { ToastrService } from 'ngx-toastr';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { TreeNode, TreeModel, TREE_ACTIONS, KEYS, IActionMapping,TreeComponent } from 'angular-tree-component';
import { ITreeOptions } from 'angular-tree-component';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';

@Component({
  selector: 'app-customer-dashboard',
  templateUrl: './customer-dashboard.component.html',
  styleUrls: ['./customer-dashboard.component.css']
})
export class CustomerDashboardComponent implements OnInit {
  @ViewChild('myContextMenu') public myContextMenu:ContextMenuComponent;
  @ViewChild('myFileContextMenu') public myFileContextMenu:ContextMenuComponent;
  @ViewChild('tree') treeComponent: TreeComponent;

  admin: any;
  nid: any;
  body: {};
  email: any;
  userid: any;
  username: any;
  customer: any;
  result: any;
  entId: any;
  isCreate: boolean = true;
  customerAdd: any;
  noteHeadingErr: any;
  noteDescErr: any;
  custdealArr = [];
  custapprovalArr = [];
  projectArr = [];
  Photos: any;
  custprojectArr = [];
  uploadPhotoArr = [];
  projectInfoArr = [];
  subJobDetailsArr = [];
  mainJobDetailsArr = [];
  noteArr = [];
  customerdeal: any;
  notesUserForm: FormGroup;
  customerapproval: any;
  customerproject: any;
  projectId: any;
  projectName: any;
  approvalName: any;
  projectInfoID: any;
  subModule: any;
  p: any;
  profileDetails: any;
  profileDetailsArr1: any;
  profileDetailsArr: any;
  profile_pc_view: boolean = false;
  editSh: boolean = false;
  selectedItem: any;
  selectedItem1: any;
  selectedItem2: any;
  allApproavlShow: boolean = false;
  allProjectShow: boolean = false;
  allDealShow: boolean = false;
  projectComplete: any;
  approvalStateArr = [];
  projectId2: any;
  detailsArr: any;
  projectUserArr: any;
  approvalproArrStatus = [];
  users: {};
  custArr = [];
  documentsLength: any;
  search_show: boolean = false;
  search_show1: boolean = false;
  search_show2: boolean = false;
  search_show3: boolean = false;
  iscertifer: boolean = true;
  isexternal: boolean = false;
  myFilter1: any;
  myFilter: any;
  myFilter2: any;
  approavlCompeleteStatus: any;
  projectshow: boolean = false;
  dealshow: boolean = false;
  approvalshow: boolean = false;
  domainName: any;
  noteTypeList: any;
  eventListSelected = [];
  nameErr: any;
  settingssingle: any;
  settingssingleDisable :any;
  navView: boolean = false;
  dealAllDetails: any;
  customerNotesArr: any;
  result1: any;
  result2: any;
  result3: any;
  result4: any;
  result5: any;
  result6: any;
  result7: any;
  result8: any;
  result9: any;
  result10: any;
  result11: any;
  result12: any;
  result13: any;
  result14: any;
  result15: any;
  result16: any;
  result17: any;
  result18: any;
  result19: any;
  result20: any;
  result21: any;
  result22: any;
  result23: any;
  proPicId: any;
  projectIdJob: any;
  profilePicView: any;
  externalUserprojectJobs: any;
  is_delete: boolean = false;
  externalUserResultProject: any;
  allOCDShow: boolean = false;
  ocdListArr = [];
  ocdProjectID: any;
  allocdShow: boolean = false;
  allocdShowpop: boolean = false;
  ocdCopyprojectId: any;
  ocdName: any;
  ocdDocumentArr = [];
  selectedItem3: any;
  totalStatesCheckedCount: any;
  totalStatesCount: any;
  isSubmit: boolean = false;
  isCuset: boolean = false;
  myFilter4: any;

  treeNodesOpen : boolean = false;
  folderval: boolean = true;
  nodes: any;

  constructor(private http: HttpClient, private router: Router, protected eventService: EventSesrvice, private DetailsService: DetailsService,
    private toastr: ToastrService, private formBuilder: FormBuilder, private confirmService: ConfirmService, private errorService: ErrorService,
    private spinner: NgxSpinnerService, private permissionService: PermissionService,
    private contextMenuService: ContextMenuService) {
  }

  ngOnInit() {
    this.spinner.show();
    this.username = localStorage.getItem("loggedInUser");
    this.userid = localStorage.getItem("loggedInUserID");
    this.admin = localStorage.getItem("loggedInUserAdmin");
    this.email = localStorage.getItem("loggedInUserEmail");
    this.proPicId = localStorage.getItem("loggedInUserproPicId");
    this.entId = localStorage.getItem("loggedInUserType");
    this.getDomainDetailsViewImage();
    setTimeout(() => {
      this.spinner.hide();
    }, 3000);
    if (this.entId == 3 || this.entId == 6) {
      this.iscertifer = false;
      this.isexternal = false;
      // this.getCustomerProject();
      this.getCustomerDetials();
      this.getCertiferProject();
      this.getAttachFiless();
      this.createForms();
      this.getuserAll();
    } else if (this.entId == 4 || this.entId == 5) {
      this.iscertifer = true;
      this.isexternal = true;
      this.getextProject();
      this.getCustomerDetials();
      this.createForms();
      this.getuserAll();
    } else {
      this.iscertifer = false;
      this.isCuset = true;
      this.getCustomerProject();
      this.getCustomerDetials();
      this.getAttachFiless();
      this.createForms();
      this.getuserAll();
    }
    this.noteTypeList = [];
    this.noteTypeList = [
      { "id": 1, "itemName": "Personal" },
    ];
    this.settingssingle = {
      text: "Select",
      enableSearchFilter: false,
      classes: "myclass custom-class",
      singleSelection: true,
      enableCheckAll: false
    };
    this.settingssingleDisable = {
      text: "Select",
      enableSearchFilter: false,
      classes: "myclass custom-class",
      singleSelection: true,
      enableCheckAll: false,
      disabled: true,
    };
    if (this.proPicId == "null") {
      this.profile_pc_view = false;
    } else {
      this.profile_pc_view = true;
      this.profilePicView = '/unsecure/gallery/' + this.proPicId;
    }
  }
  createForms() {
    this.notesUserForm = this.formBuilder.group({
      viewType: [],
      description: ['', Validators.required],
      heading: '',
      userId: '',
      projectId: '',
      createdAt: '',
      updatedAt: '',
      createdBy: '',
      updatedBy: ''

    })
  }
  resetForms() {
    this.eventListSelected = [];
    this.isCreate = true;
    this.notesUserForm.reset({
      description: '',
      heading: '',
      viewType: []
    })
  }
  getCustomerDetials() {
    var url = '/api/user/' + this.userid;
    this.http.get(url).subscribe((res: Response) => {
      this.result1 = res;
      this.customer = this.result1.details;
      this.customerAdd = this.result1.location;

    });
  }
  projectInfoCode() {
    this.http.get('/api/projectInfo/project/' + this.projectId).subscribe((res: Response) => {
      this.result2 = res;
      this.projectInfoID = this.result2.ProjectInfoId;
      if (this.result2.ProjectInfoId) {
        this.getprojectinfo(this.projectInfoID);
      }
      this.spinner.hide();
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  getprojectinfo(id) {
    this.http.get('/api/projectInfo/' + id).subscribe((res: Response) => {
      this.result3 = res;
      console.log(JSON.stringify(this.result3));
      if (this.result3) {
        this.projectName = this.result3.project.title;
        this.projectIdJob = this.result3.project.id;
        if (this.entId == 4 || this.entId == 5) {
          this.getJobDetails();

        } else {
          if (this.result3.projectInfo.projectTypeId) {
            this.ocdDocumentArr.forEach( ocd => {
              console.log("project id : " + this.projectId);
              console.log("ocd project : " + ocd.projectId);
              if (ocd.projectId == this.projectId) {
                //this.ocdlistClick(ocd);
                this.allOCDShow = true;
                this.getOCDDetials(ocd);
              }
            });
            this.getCustomerNotes();
            this.getphoto();
            this.getDays();
          }
          if (this.result3.projectInfo.projectTypeId == null) {
            this.Photos = "";
          }
        }
        if(this.result3.projectInfo.projectEnd != null){
          var duration = moment(this.result3.projectInfo.projectStart).format("DD-MM-YYYY") + " to " + moment(this.result3.projectInfo.projectEnd).format("DD-MM-YYYY")
        }else{
          var duration = moment(this.result3.projectInfo.projectStart).format("DD-MM-YYYY") + " to " + " Not Specified"
        }

        this.projectInfoArr.push({
          "managerName": this.result3.persons.manager,
          "certifierName": this.result3.persons.certifier,
          "councilName": this.result3.persons.council,
          "duration": duration,
          "buildingType": this.result3.projectBuildingType,
          "projectType": this.result3.projectInfo.projectStateType
        })

         if(this.entId == 2 || this.entId == 3 || this.entId == 4){
          this.getdocumentsFileTree(this.projectId,this.entId);
        }
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  getDays() {
    this.http.get('/api/projectJob/happening/' + this.projectId).subscribe((res: Response) => {
      this.result4 = res;
      for (let i = 0; i < this.result4.length; i++) {
        this.projectArr.push({
          "jobid": this.result4[i].job.id,
           "statename": this.result4[i].state.name,
            "startDate": this.result4[i].job.startDate,
             "endDate": this.result4[i].job.endDate,
              "isCompleted": this.result4[i].job.isCompleted,
              "statusId": this.result4[i].job.statusId
        })
      }

    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  getCustomerProject() {
    var url = '/api/customer/projects/' + this.userid;
    this.http.get(url).subscribe((res: Response) => {
      this.result5 = res;
      if (this.result5) {
        this.custdealArr = this.result5.deal;
        this.custapprovalArr = this.result5.approval;
        this.custprojectArr = this.result5.project;
        this.ocdDocumentArr = this.result5.ocd;
        if (this.result5.project.length > 0) {
          this.listClick(this.result5.project, 0);
          this.projectId = this.result5.project[0].id;
          this.getCustomerNotes();
          this.getCustomerProjectDetials(this.projectId);
        }
      }
    });
  }
  getCertiferProject() {
    var url = '/api/certifier/projects/' + this.userid;
    if (this.entId == 6) {
      url = '/api/council/projects/' + this.userid;
    }
    this.http.get(url).subscribe((res: Response) => {
      this.result = res;
      if (this.result) {
        this.custapprovalArr = this.result.approval;
        this.custprojectArr = this.result.project;
        this.ocdDocumentArr = this.result.ocd;
        if (this.result.project.length > 0) {
          this.listClick(this.result.project, 0);
          this.projectId = this.result.project[0].id;
          this.getCustomerNotes();
          this.getCustomerProjectDetials(this.projectId);
        }
      }
    });
  }
  getCustomerProjectDetials(id) {
    this.uploadPhotoArr = [];
    this.projectArr = [];
    this.projectInfoArr = [];
    this.noteArr = [];
    this.projectId = id;
    this.projectInfoCode();
  }
  getphoto() {
    this.Photos = '';
    this.http.get('/api/files/customer/photo/' + this.projectId).subscribe((res1: Response) => {
      this.result6 = res1;
      this.uploadPhotoArr = this.result6;
      if (this.uploadPhotoArr.length > 0) {
        this.switchPhoto(this.uploadPhotoArr[0].src);
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  switchPhoto(photo) {
    if (photo) {
      this.Photos = photo;
    }
  }

  submitNotesUser() {
    if (this.noteValidate()) {
      this.spinner.show();
      let data = this.notesUserForm.value;
      data.viewType = parseInt(data.viewType[0].id);
      data.userId = parseInt(this.userid);
      data.createdBy = parseInt(this.userid);
      data.projectId = this.projectId;
      this.http.post('api/notes/project', data).subscribe((response) => {
        this.result7 = response;
        if (this.result7.success == true) {
          this.toastr.clear();
          this.toastr.success(this.result7.message, 'Success', {
            timeOut: 6000,
            closeButton: true
          });
          this.resetForms();
          this.getCustomerNotes();
          this.editSh = false;
          this.spinner.hide();
        } else {
          this.toastr.clear();
          this.toastr.error(this.result7.message, 'Error', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });

      this.noteArr = [];
    }
  }
  noteEditMenu(id) {
    if (this.editSh == true) {
      this.editSh = false;
    } else {
      this.editSh = true;
    }
    this.noteHeadingErr = null;
    this.nameErr = null;
    this.noteDescErr = null;
    this.isCreate = false;
    this.nid = id;
    this.http.get('/api/note/' + this.nid).subscribe((res: Response) => {
      this.result8 = res;
      if (this.result8) {
        this.notesUserForm.patchValue({ 'id': this.result8.id });
        this.notesUserForm.patchValue({ 'heading': this.result8.heading });
        this.notesUserForm.patchValue({ 'description': this.result8.description });
        this.notesUserForm.patchValue({ 'isActive': this.result8.isActive });
        this.notesUserForm.patchValue({ 'userId': this.result8.userId });
        this.notesUserForm.patchValue({ 'projectId': this.result8.projectId });
        this.notesUserForm.patchValue({ 'createdAt': this.result8.createdAt });
        this.notesUserForm.patchValue({ 'updatedAt': this.result8.updatedAt });
        this.notesUserForm.patchValue({ 'createdBy': this.result8.createdBy });
        this.notesUserForm.patchValue({ 'updatedBy': this.result8.updatedBy });
        if (this.result8.viewType == "1") {
          this.eventListSelected = [];
          this.eventListSelected.push({ "id": 1, "itemName": "Personal" })
        } else if (this.result8.viewType == "3") {
          this.eventListSelected = [];
          this.eventListSelected.push({ "id": 3, "itemName": "External" })
        }
        if (this.result8.createdBy == this.userid || this.admin == 'Y') {
          this.is_delete = false;
        } else {
          this.is_delete = true;
          this.notesUserForm.controls['heading'].disable();
          this.notesUserForm.controls['description'].disable();
        }

      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  updateNote() {
    if (this.noteValidate()) {
      this.spinner.show();
      this.body = {};
      this.body["id"] = this.nid;
      this.body["heading"] = this.notesUserForm.value["heading"];
      this.body["description"] = this.notesUserForm.value["description"];
      this.body["userId"] = this.notesUserForm.value["userId"];
      this.body["projectId"] = this.notesUserForm.value["projectId"];
      this.body["createdAt"] = this.notesUserForm.value["createdAt"];
      this.body["updatedAt"] = this.notesUserForm.value["updatedAt"];
      this.body["createdBy"] = this.notesUserForm.value["createdBy"];
      this.body["updatedBy"] = this.userid;
      this.body["isActive"] = "Y";
      this.body["viewType"] = this.notesUserForm.value["viewType"][0].id;
      this.http.put('/api/notes/project', this.body).subscribe((response) => {
        this.resetForms();
        this.result9 = response;
        if (this.result9.success == true) {
          this.toastr.clear();
          this.toastr.success(this.result9.message, 'Success', {
            timeOut: 6000,
            closeButton: true
          });
          this.editSh = false;
          this.spinner.hide();
          this.getCustomerNotes();
        } else {
          this.toastr.clear();
          this.toastr.error(this.result9.message, 'Error', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
      this.noteArr = [];
    }

  }
  noteValidate() {
    this.noteHeadingErr = null;
    this.noteDescErr = null;
    this.nameErr = null;
    const strongRegex = /^[:;,\-@0-9a-zA-Zגיט'.\s]{1,9999}$/;
    var valid = true;
    var data = this.notesUserForm.value;
    var desc = data.description;
    var edit = $(desc).text();
    if (!data.heading) {
      this.noteHeadingErr = "Heading is required";
      valid = false;
    } else if (data.viewType.length == 0) {
      this.nameErr = "NoteType  is required.";
      valid = false;
    } else if (edit == undefined || edit == '') {
      this.noteDescErr = "Description is required ";
      valid = false;
    } 
    else if (edit.length > 10000) {
      this.noteDescErr = "Too Long. Please restrict to 10000 characters";
      valid = false;
    }
    return valid;
  }

  getAttachFiless() {
    this.http.get('/api/files/profile/' + this.userid).subscribe((res: Response) => {
      this.result10 = res;
      this.profileDetails = this.result10;
      if (this.profileDetails.length > 0) {
        this.profileDetailsArr = this.profileDetails[0].src;
        this.profile_pc_view = true;
      } else {
        this.profile_pc_view = false;
      }
    });
  }

  noteEdit() {
    this.resetForms();
    this.notesUserForm.controls['heading'].enable();
    this.notesUserForm.controls['description'].enable();
    this.is_delete = false;
    this.noteHeadingErr = null;
    this.noteDescErr = null;
    this.nameErr = null;
    if (this.editSh == true) {
      this.editSh = false;
    } else {
      this.editSh = true;
    }
  }

  listClick(newValue, num) {
    if (num == 0) {
      if (newValue.length > 0) {
        this.selectedItem = newValue[0];
      } else {
        this.selectedItem = newValue;
      }
    } else {
      this.spinner.show();
      this.projectshow = false;
      this.listClickHide(null, 1);
      this.listClickHide(null, 2);
      this.listClickHide(null, 3);
      this.projectInfoArr = [];
      this.allDealShow = false;
      this.allProjectShow = false;
      this.allApproavlShow = false;
      this.allOCDShow = true;
      this.selectedItem = newValue;
      this.getCustomerNotesAll(newValue.id);
      if(this.entId == 2 || this.entId == 3 || this.entId == 4){
          this.getdocumentsFileTree(this.projectId,this.entId);
      }
      this.spinner.hide();

    }
  }
  listClickHide(newValue, num) {
    switch (num) {
      case 0:
        this.selectedItem = newValue;
        break;
      case 1:
        this.selectedItem1 = newValue;
        break;
      case 2:
        this.selectedItem2 = newValue;
        break;
      case 3:
        this.selectedItem3 = newValue;
        break;
    }
  }

  approvalListClick(newValue) {
    this.spinner.show();
    this.listClickHide(null, 0);
    this.listClickHide(null, 2);
    this.listClickHide(null, 3);
    this.approvalshow = false;
    this.allDealShow = false;
    this.allProjectShow = true;
    this.allApproavlShow = true;
    this.allOCDShow = false;
    this.treeNodesOpen = false;
    this.approvalproArrStatus = [];
    this.custArr = [];
    this.approvalStateArr = [];
    this.selectedItem1 = newValue;
  }
  dealListClick(newValue) {
    this.spinner.show();
    this.dealshow = false;
    this.listClickHide(null, 0);
    this.listClickHide(null, 1);
    this.listClickHide(null, 3);
    this.allDealShow = true;
    this.allProjectShow = true;
    this.allApproavlShow = false;
    this.treeNodesOpen = false;
    this.allOCDShow = false;
    this.selectedItem2 = newValue;
    this.dealAllDetails = newValue;
    if(this.entId == 2 || this.entId == 3 || this.entId == 4){
      this.getdocumentsFileTree(newValue.id,this.entId);
  }
    this.spinner.hide();
  }
  ocdlistClick(newValue) {
    this.allocdShowpop = false;
    this.ocdCopyprojectId = newValue.projectId;
    this.allOCDShow = true;
    this.dealshow = false;
    this.listClickHide(null, 0);
    this.listClickHide(null, 1);
    this.listClickHide(null, 2);
    this.allDealShow = false;
    this.allProjectShow = false;
    this.allApproavlShow = false;
    this.selectedItem3 = newValue;
    this.dealAllDetails = newValue;
  }
  async getuserAll() {
    var result = await this.DetailsService.getCustUsers();
    this.result11 = result;
    this.detailsArr = [];
    this.projectUserArr = this.result11;
    if (result) {
      this.users = {};
      this.result11.forEach(r => {
        this.users[r.details.id] = r.details;
        this.detailsArr.push(r.details.id);
      });
    } else {
    }
  }
  async onSelectapproval(data) {
    this.allDealShow = false;
    this.allProjectShow = true;
    this.allApproavlShow = true;
    this.projectComplete = [];
    this.projectId2 = data.id;
    this.approvalName = data.name;
    this.approavlCompeleteStatus = data.isCompleted;
    var value = await this.getcompleted();
    this.projectComplete = value;
    var completestatus;
    if (data.isCompleted == "T") {
      var url = 'api/projectStates/approvals/' + this.projectId2 + '/completed'
      completestatus = data.stateCount;
    } else {
      var url = 'api/projectStates/approvals';
      completestatus = data.stateChecked;
    }
    this.approvalproArrStatus.push({
      "approvalproId": data.id, "approvalproName": data.name, "approvalstatus": data.stateCount, "approvalcomplete": completestatus
    })
    this.http.get(url).subscribe((res: Response) => {
      this.result12 = res;
      if (this.result12) {
        this.result12.forEach(val => {
          var item = this.projectComplete.filter(a => a.projectStateId === val.id);
          if (item && item.length > 0 && item[0].isCompleted === "Y") {
            item = item[0];
            val.status = "Completed";
            val.isCompleted = val.status === "Completed";
            val.downloadId = item.id;

          }
          else {
            val.status = "Pending";
          }
          this.approvalStateArr.push({
            "approvalStatesName": val.name,
            "approvalStatesId": val.downloadId,
            "approvalstatus": val.status,
            "approvalNotes": item.note

          })
          return val;
        });
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
    this.spinner.hide();
  }

  getcompleted() {
    return this.http.get('api/approvallist/' + this.projectId2).toPromise();
  }
  zipFile() {
    const url = 'unsecure/approval/downloadZip/' + this.projectId2;
    window.open(url, "_self");
  }
  // getApproval() {
  //         var approvalurl = '/api/projects/approvals/';
  //         this.http.get('api/projectStates/approvals/').subscribe((res: Response) => {
  //           this.result14 = res;
  //           this.http.get(approvalurl).subscribe((res: Response) => {
  //            this.result15 = res;
  //             if (this.result15.length > 0) {
  //               for (let i = 0; i < this.result15.length; i++) {
  //                 this.http.get('api/approvallist/' + this.result15[i].id).subscribe((res: Response) => {
  //                   this.result16 = res;
  //                   var completecount = 0;
  //                   for (let j = 0; j < this.result16.length; j++) {
  //                     if (this.result16[j].isCompleted == "Y") {
  //                       completecount++;
  //                     }
  //                   }
  //                   if(this.result15[i].id == this.projectId2){
  //                   this.approvalproArrStatus.push({
  //                     "approvalproId": this.result15[i].id, "approvalproName": this.result15[i].title, "approvalstatus": this.result14.length, "approvalcomplete": completecount
  //                   })
  //                 }
  //                   this.spinner.hide();
  //                 })
  //               }
  //             } else {
  //               // this.spinner.hide();
  //             }
  //           })
  //         })

  // }
  downloadFileApproval(approvalStateID) {
    this.http.get('/api/approvallist/' + this.projectId2 + '/' + approvalStateID).subscribe((res: Response) => {
      this.result17 = res;
      if (this.result17.data != null) {
        this.getdocuments(this.result17.data.id);
      } else {
        this.toastr.clear();
        this.toastr.error("Empty Documents", 'Report Status', {
          timeOut: 2000,
          closeButton: true
        });
      }
    });
  }
  getdocuments(Checkid) {
    this.http.get('api/approvallist/documents/' + Checkid).subscribe((res: Response) => {
      this.result18 = res;
      this.documentsLength = this.result18;
      if (this.result18.length > 0) {
        for (let i = 0; i < this.result18.length; i++) {
          this.downloadFile(this.result18[i].id);
        }
      } else {
        this.toastr.clear();
        this.toastr.error("Empty Documents", 'Report Status', {
          timeOut: 2000,
          closeButton: true
        });
      }
    });
  }
  downloadFile(fid) {
    const url = '/unsecure/download/' + fid;
    window.open(url);
  }
  searchShow(num) {
    switch (num) {
      case 0:
        this.myFilter1 = null;
        this.search_show = !this.search_show;
        break;
      case 1:
        this.myFilter = null;
        this.search_show1 = !this.search_show1;
        break;
      case 2:
        this.myFilter2 = null;
        this.search_show2 = !this.search_show2;
        break;
      case 3:
        this.myFilter4 = null;
        this.search_show3 = !this.search_show3;
        break;
    }
  }
  projectAllShow(num) {
    switch (num) {
      case 0:
        if (this.projectshow == true) {
          this.projectshow = false;
        } else {
          this.projectshow = true;
        }
        break;
      case 1:
        if (this.dealshow == true) {
          this.dealshow = false;
        } else {
          this.dealshow = true;
        }
        break;
      case 2:
        if (this.approvalshow == true) {
          this.approvalshow = false;
        } else {
          this.approvalshow = true;
        }
        break;
      case 3:
        if (this.allocdShowpop == true) {
          this.allocdShowpop = false;
        } else {
          this.allocdShowpop = true;
        }
        break;
    }

  }

  fbshare(fbSharePhoto) {
    var url = this.domainName + fbSharePhoto;
    window.open('https://www.facebook.com/sharer/sharer.php?u=' + url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');


  }
  getDomainDetailsViewImage() {
    this.http.get('api/domainName/').subscribe((res: Response) => {
      this.result19 = res;
      this.domainName = this.result19.domainName;
    });
  }
  nav() {
    this.navView = !this.navView;

  }
  getCustomerNotes() {
    this.noteArr = [];
    this.http.get('api/notes/customer/' + this.projectId + '/' + this.userid + '/' + "project").subscribe((res: Response) => {
      this.result20 = res;
      if (this.result20) {
        this.result20.forEach(r => {
          var desc = r.description;
          var edit = $(desc).text();
          this.noteArr.push({
            "id": r.id, "descName": r.description, "heading": r.heading, "viewType": r.viewType, "createdAt": r.createdAt, "createdBy": r.createdBy
          })

        })
      }
    })
  }
  getCustomerNotesAll(id) {
    this.noteArr = [];
    this.http.get('api/notes/customer/' + id + '/' + this.userid + '/' + "project").subscribe((res: Response) => {
      this.result21 = res;
      if (this.result21) {
        this.result21.forEach(r => {
          var desc = r.description;
          var edit = $(desc).text();
          this.noteArr.push({
            "id": r.id, "descName": r.description, "viewType": r.viewType, "heading": r.heading, "createdAt": r.createdAt, "createdBy": r.createdBy
          })
        })
      }
    })
  }
  delateNote() {
    this.confirmService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) => {
        if (confirmed) {
          this.spinner.show();
          this.http.delete('/api/notes/' + this.nid).subscribe((response) => {
            this.resetForms();
            this.isCreate = true;
            this.result23 = response;
            if (this.result23.success == true) {
              this.toastr.clear();
              this.toastr.success(this.result23.message, 'Success', {
                timeOut: 2000,
                closeButton: true
              });
              this.spinner.hide();
              this.noteEdit();
              this.noteArr = [];
              this.getCustomerNotes();

            } else {
              this.toastr.clear();
              this.toastr.error(this.result23.message, 'Error', {
                timeOut: 6000,
                closeButton: true
              });
            }
          }, error => {
            this.toastr.clear();
            this.toastr.error(error, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });
            this.spinner.hide();
            this.errorService.handleError(error);
          })
        }
      })
      .catch(() => {
        return false;
      });
  }
  getextProject() {
    var url = '/api/externalUser/projects/' + this.userid;
    this.http.get(url).subscribe((res: Response) => {
      this.externalUserResultProject = res;
      var result = this.externalUserResultProject;
      if (result) {
        this.custprojectArr = result.project;
        if (result.project.length > 0) {
          this.listClick(result.project, 0);
          this.projectId = result.project[0].id;
          this.getCustomerNotesAll(result.project[0].id);
          this.getCustomerProjectDetials(this.projectId);
        }
      }
    });
  }
  getJobDetails() {
    this.http.get('/api/externalUser/projectJobs/' + this.projectId + "/" + this.userid).subscribe((res1: Response) => {
      this.externalUserprojectJobs = res1;
      var result = this.externalUserprojectJobs;
      this.mainJobDetailsArr = [];
      this.subJobDetailsArr = [];
      for (let i = 0; i < result.length; i++) {
        this.mainJobDetailsArr.push({
          "jobid": result[i].job.id,
          "jobName": result[i].job.name,
          "statename": result[i].state.name,
          "startDate": result[i].job.startDate,
          "endDate": result[i].job.endDate,
          "isCompleted": result[i].job.isCompleted,
          "assigned": result[i].assigned.name,
          "createdDate": result[i].job.createdDate,
          "statusId": result[i].job.statusId
        })
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  jobHandlingPage(projectId) {
    this.router.navigate(["/job", projectId]);
  }
  getOCDDetials(data) {
    this.ocdName = data.name;
    this.ocdProjectID = data.projectId;
    this.totalStatesCheckedCount = data.totalStatesCheckedCount;
    this.totalStatesCount = data.totalStatesCount;
    if (this.totalStatesCount == this.totalStatesCheckedCount) {
      this.isSubmit = true;
    } else {
      this.isSubmit = false;
    }
    this.http.get('/api/projectOCD/' + data.projectId).subscribe((res: Response) => {
      this.result = res;
      var ocd = this.result.OCDs;
      var states = this.result.states;
      var OCStatus = false;
      var completecount = 0;
      this.ocdListArr = [];
      var val = false;
      for (let i = 0; i < states.length; i++) {
        val = false;
        var checklistnote = "";
        for (let j = 0; j < ocd.length; j++) {
          if (states[i].id == ocd[j].projectStateId) {
            var ocdID = ocd[j].id;
            var data = ocd[j];
            checklistnote = ocd[j].note;
            if (ocd[j].isCompleted == "Y") {
              completecount++;
              val = true;
            }
            break;
          }
        }
        this.ocdListArr.push({
          "StateName": states[i].name,
          "StateId": states[i].id,
          "status": val,
          "Id": ocdID,
          "checklistnote": checklistnote,
          "data": states[i],
        })
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  getdocumentsFile(Checkid) {
    this.http.get('api/projectOCDs/documents/' + Checkid).subscribe((res: Response) => {
      this.result = res;
      if (this.result.length > 0) {
        for (let i = 0; i < this.result.length; i++) {
          this.downloadFileOcDocument(this.result[i].id);
        }
      } else {
        this.toastr.clear();
        this.toastr.error("Empty Documents", 'Report Status', {
          timeOut: 2000,
          closeButton: true
        });
      }

    });
  }
  downloadFileOcDocument(fid) {
    const url = '/unsecure/download/' + fid;
    window.open(url);
  }
  zipOcdFile(id) {
    const url = 'unsecure/ocd/downloadZip/' + id;
    window.open(url, "_self");
  }

  getdocumentsFileTree(id,eId){
    this.http.get('/api/fileMap/project/' + id + "/" + eId).subscribe((res1: Response) => {
      this.result1 = res1;
      this.nodes = this.result1;
        if(this.nodes.length == 0){
          this.treeNodesOpen = false;
        }else{
          this.treeNodesOpen = true;
        }

    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }


  downloadNodes(treeModel: any) {
    if (treeModel.activeNodes.length > 0) {
      let last: any = treeModel.activeNodes[treeModel.activeNodes.length - 1];
      let type = last.data.type;
      let fid = last.data.id;
      if (type == "file") {
        this.downloadFile(fid)
      }
    }
  }
  foldercheck(treeModel: any) {
    if (treeModel.activeNodes.length > 0) {
      let last: any = treeModel.activeNodes[treeModel.activeNodes.length - 1];
      let type = last.data.type;
      if (type == "folder") {
        this.folderval = true;
      } else {
        this.folderval = false;
      }
    }
  }

  foldervall() {
    this.folderval = false
  }

  myContextClick($event,data,id){

    this.contextMenuService.show.next({
      contextMenu:this.myContextMenu,
      event:<any>$event,
      item:data,
    });
    const treeModel: TreeModel = this.treeComponent.treeModel;
    treeModel.getNodeById(id).setIsActive(true);
    $event.preventDefault();
    $event.stopPropagation();
  }
  myFileContextMenuClick($event,data,id){

    this.contextMenuService.show.next({
      contextMenu:this.myFileContextMenu,
      event:<any>$event,
      item:data,
    });
    const treeModel: TreeModel = this.treeComponent.treeModel;
    treeModel.getNodeById(id).setIsActive(true);
    $event.preventDefault();
    $event.stopPropagation();
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './login/logout.component';
import { HomeComponent } from './home/home.component';
import { routing } from './app.routing';
import { HttpModule, RequestOptions } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DealsComponent } from './deals/deals.component';
import { ContactsComponent } from './contacts/contacts.component';

import { ModalComponent } from './_directives';
import { ModalService } from './_services';
import { HeaderComponent } from './MainFrame/header/header.component';
import { NavComponent } from './MainFrame/nav/nav.component';
import { IndexComponent } from './MainFrame/index/index.component';
import { OrganizationComponent } from './contacts/organization/organization.component';
import { CustomerComponent } from './contacts/customer/customer.component';
import { DragulaModule } from 'ng2-dragula';
import {  ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { ErrorService } from 'src/app/_services/error.service';
import { PermissionService } from 'src/app/_services/permission.service';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { EventEmitterService } from 'src/app/_services/event-emitter.service';
import {
  AuthGuardService as AuthGuard
} from './_services/auth-guard.service';
import { AuthService } from './_services/auth.service';
import { WindowRef } from './_services/windowRef.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectComponent } from './project/project.component';
import { AdminComponent } from './admin/admin.component';
import { UserComponent } from './admin/user/user.component';
import { CustomizationComponent } from './admin/customization/customization.component';



import { Ng2OrderModule } from 'ng2-order-pipe'; //importing the module
import {NgxPaginationModule} from 'ngx-pagination'; // <-- import the module
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ApprovalComponent } from './approval/approval.component';
import { RoleComponent } from './admin/role/role.component';
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
import { DatePipe } from '@angular/common';//importing the module
import { MyDatePickerModule } from 'mydatepicker';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { DetailsComponent } from './details/details.component';
import { DetailsService } from './_services/index';
import { TooltipModule } from 'ng2-tooltip-directive';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ApprovalsDetailsComponent } from './approvals-details/approvals-details.component';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { FullCalendarModule } from 'ng-fullcalendar';
import { EventSesrvice } from './_services/event.service';
import { SchdeuleComponent } from './schdeule/schdeule.component';
import { ResourceManagementComponent } from './resource-management/resource-management.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { TreeModule } from 'angular-tree-component';

import {MatProgressBarModule} from '@angular/material/progress-bar';
import { ChartsModule } from 'ng2-charts';
import { DomainComponent } from './domain/domain.component';
import { IssuesComponent } from './issues/issues.component';
import {HTTP_INTERCEPTORS } from '@angular/common/http';
import { LightboxModule } from 'ngx-lightbox';
import {  TruncatePipesModule }   from 'angular-truncate-pipes';
import { ChartModule } from 'angular-highcharts';
import { TokenInterceptor } from 'src/app/_services/token.interceptor';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { CreatepasswordComponent } from './createpassword/createpassword.component';
import { UserLogsComponent } from './admin/user-logs/user-logs.component';
import { UsercreatepasswordComponent } from './usercreatepassword/usercreatepassword.component';
import { DashboardNewComponent } from './dashboard-new/dashboard-new.component';
import { TasksComponent } from './tasks/tasks.component';
import { ProfileComponent } from './profile/profile.component';
import { BillingComponent } from './billing/billing.component';
import { PrintInvoiceComponent } from './print-invoice/print-invoice.component';
import { CustomerDashboardComponent } from './customer-dashboard/customer-dashboard.component';
import { ArchiveComponent } from './admin/archive/archive.component';
import { ContextMenuModule } from 'ngx-contextmenu';
import { JobHandlingComponent } from './job-handling/job-handling.component';
import { NgGanttEditorModule } from 'ng-gantt';
import { CertifierComponent } from './admin/certifier/certifier.component';
import { ExternalUserComponent } from './admin/external-user/external-user.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { EstimationAdminComponent } from './admin/estimation-admin/estimation-admin.component';
import { PropsaveComponent } from './details/propsave/propsave.component';
import{DealDetailsModel} from './model/deal-details-model';
import { DomainRegistryComponent } from './domain-registry/domain-registry.component'


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LogoutComponent,
    HomeComponent,
    DealsComponent,
    ContactsComponent,
    ModalComponent,
    HeaderComponent,
    NavComponent,
    IndexComponent,
    OrganizationComponent,
    CustomerComponent,
    ConfirmationDialogComponent,
    ProjectComponent,
    AdminComponent,
    UserComponent,
    CustomizationComponent,
    ApprovalComponent,
    RoleComponent,
    DetailsComponent,
    SignaturePad,
    ApprovalsDetailsComponent,
    DashboardComponent,
    PrivacyPolicyComponent,
    SchdeuleComponent,
    ResourceManagementComponent,
    ProjectDetailsComponent,
    DomainComponent,
    IssuesComponent,
    ResetpasswordComponent,
    CreatepasswordComponent,
    UserLogsComponent,
    UsercreatepasswordComponent,
    DashboardNewComponent,
    TasksComponent,
    ProfileComponent,
    BillingComponent,
    PrintInvoiceComponent,
    CustomerDashboardComponent,
    ArchiveComponent,
    JobHandlingComponent,
    CertifierComponent,
    ExternalUserComponent,
    EstimationAdminComponent,
    PropsaveComponent,
    // models
    DealDetailsModel,
    DomainRegistryComponent



  ],
  imports: [
    LightboxModule,
    BrowserModule,
    ChartsModule,
    BrowserModule,
    TreeModule.forRoot(),
    routing,
    EditorModule,
    NgbModule,
    HttpModule,
    HttpClientModule,
    FullCalendarModule,
    MatProgressBarModule,
    TruncatePipesModule,
    ChartModule,
    TooltipModule ,
    FormsModule,
    AngularMultiSelectModule,
    InfiniteScrollModule,
    NgxSpinnerModule,
    Ng2SearchPipeModule, //including into imports
    Ng2OrderModule, // importing the sorting package here
    NgxPaginationModule,
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    DragulaModule.forRoot(),
    AngularDateTimePickerModule,
    MyDatePickerModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    NgGanttEditorModule,
    AngularEditorModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger' // set defaults here
    }),
    NgbModule.forRoot(),
    GoogleChartsModule,
    ContextMenuModule.forRoot({
      useBootstrap4: true,
    })
    ],
  providers: [
    ModalService,
    ErrorService,
    ConfirmService,
    NgbActiveModal,
    DetailsService,
    EventSesrvice,
    DatePipe,
    AuthGuard,
    AuthService,
    PermissionService,
    WindowRef,
    EventEmitterService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [ConfirmationDialogComponent]
})
export class AppModule { }

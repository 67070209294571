import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { ErrorService } from 'src/app/_services/error.service';
import { PermissionService } from 'src/app/_services/permission.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '../_services';
import { trigger, state, animate, transition, style } from '@angular/animations';
import { DetailsService } from '../_services/index';

@Component({
  selector: 'app-approval',
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class ApprovalComponent implements OnInit {
  result: any;
  body: {};
  approvalproArr = [];
  approvalComArr = [];
  id: any;
  isCreate : boolean = true;
  closeResult: string;
  menuState:string = 'out';
  form: FormGroup;
  usersArr=[];
  usersArrselect=[];
  settings: any;
  admin :any;
  userid :any;
  ccc: any;
  colorAppro1: any;
  colorM: any;
  isAdminDis: boolean = false;
  result0 :any;
  result1 :any;
  result3:any;
  result2:any;
  result4:any;
  result5:any;
  result6 :any;
  pagedItemspointer: any;
  inpagedItemspointer: any;
  currentPage = 1;
  maxSize = 5;
  inmaxSize = 5;
  pagelimit = 10;
  reat: any;
  InComplreat:any;
  isfitler:boolean =false;
  chguser:any;
  showMe:any;
  incurrentPage = 1;
  resultComp:any;
  resultIncomp:any;
  cusresultComp:any;
  cusresultIncomp:any;
  totalElementsComplete:any;
  totalElementsInComplete:any;
  approvalType: string = "approvals";

 constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute,
     private modalService: NgbModal,
     private toastr: ToastrService,private spinner: NgxSpinnerService,
     private errorService: ErrorService,
     private confirmService: ConfirmService,
     private permissionService: PermissionService,
     private formBuilder: FormBuilder,private DetailsService: DetailsService) {
        }

     ngOnInit() {
       this.approvalType = this.route.snapshot.params.type;
       console.log("approval type : " + this.approvalType);

      this.userid = localStorage.getItem("loggedInUserID");
      this.admin = localStorage.getItem("loggedInUserAdmin");
      this.settings = {
        text: "Select User",
        enableSearchFilter: true,
        classes: "myclass custom-class",
        disabled: false,
        singleSelection: true,
        enableCheckAll: false
      };
      // if(this.admin == "Y"){
      // this.getUser();
      // }
      // this.result2=[];
      this.result3=[];
      this.result=[];
      this.getApproval(0);
      this.getInComplApproval(0);
      this.currentPage=1;
       this.incurrentPage=1;
    }

    getApproval(num){
      this.spinner.show();
      this.isfitler =false;
        this.http.get('/api/user/'+this.userid).subscribe((res: Response) => {
          this.result= res;
        var permision=this.result.role.permision;
        if(permision.length > 0){
          var approvalPermission = this.permissionService.getApprovalPermission(permision);
          // if(approvalPermission == this.permissionService.APPROVAL_ADMIN || this.admin == "Y")  {
           if(this.admin == "Y")  {
                     //this.getUser();
                     this.isAdminDis=true;
                        var approvalurl='/api/projects/approvals/' + this.approvalType + '/completed/' + this.currentPage + '/' + this.pagelimit
                              //this.http.get('api/projectStates/approvals').subscribe((res: Response) => {
                              this.http.get('api/projectStates/'+ this.approvalType).subscribe((res: Response) => {
                                this.result1 = res;
                                // this.approvalproArr=[];
                                this.http.get(approvalurl).subscribe((res: Response) => {
                                  this.cusresultComp=[];
                                  this.result2 = res;
                                  this.cusresultComp = this.result2.approvals;
                                  var datacount =this.result2.totalElements;
                                  this.totalElementsComplete = datacount;
                                if (num == 0) {
                                  this.setPagination(datacount);
                                }
                                if(this.cusresultComp.length > 0){
                                  this.approvalComArr=[];
                                for (let i = 0; i < this.cusresultComp.length; i++) {
                                  this.http.get('api/approvallist/'+this.cusresultComp[i].approval.id).subscribe((res: Response) => {
                                    this.result4 = res;
                                    var completecount=0;
                                    for (let j = 0; j <  this.result4.length; j++) {
                                    if( this.result4[j].isCompleted=="Y"){
                                      completecount++;
                                    }
                                  }
                                  if(this.cusresultComp[i].approval.approvalCompleted == "T"){
                                    var approvalcount  = completecount;
                                   }else{
                                     var leg =this.result1.length
                                    var approvalcount  = parseInt(leg);
                                   }
                                   this.colorAppro1 = completecount  / approvalcount * 100;
                                   if( this.colorAppro1 <= 50 ){
                                     this.colorM = "brown";
                                   }
                                   else if( this.colorAppro1 <= 80 ){
                                     this.colorM = "green";
                                   }
                                   else {
                                      this.colorM = "#00b3ca";
                                   }
                                   console.log(this.cusresultComp[i].duration);
                                   if (this.cusresultComp[i].duration.TotalApprovalDurationDays != 0 && this.cusresultComp[i].duration.TotalApprovalDurationMonths == 0 && this.cusresultComp[i].duration.TotalApprovalDurationYears == 0) {
                                     var dayvalue = this.cusresultComp[i].duration.TotalApprovalDurationDays + " days";
                                   } else if (this.cusresultComp[i].duration.TotalApprovalDurationDays != 0 && this.cusresultComp[i].duration.TotalApprovalDurationMonths != 0 && this.cusresultComp[i].duration.TotalApprovalDurationYears == 0) {
                                     var dayvalue = this.cusresultComp[i].duration.TotalApprovalDurationMonths + " mnt " + this.cusresultComp[i].duration.TotalApprovalDurationDays + " days";
                                   } else if (this.cusresultComp[i].duration.TotalApprovalDurationYears != 0) {
                                     var dayvalue = this.cusresultComp[i].duration.TotalApprovalDurationYears + " Yr " + this.cusresultComp[i].duration.TotalApprovalDurationMonths + " mnt " + this.cusresultComp[i].duration.TotalApprovalDurationDays + " days";
                                   } else {
                                     var dayvalue = this.cusresultComp[i].duration.TotalApprovalDurationDays + " days";
                                   }
                                  this.approvalComArr.push({
                                    "approvalproId": this.cusresultComp[i].approval.id,"days": dayvalue, "approvalproName": this.cusresultComp[i].approval.title,"approvalstatus":approvalcount,"approvalcomplete":completecount,"colorAppro":this.colorM,"approvalcertName": this.cusresultComp[i].certName, "approvalmanagerName": this.cusresultComp[i].managerName
                                  })

                                  this.spinner.hide();
                                })
                                }
                            }else{
                              this.spinner.hide();
                            }
                          })
                          })
            // }else  if(approvalPermission == this.permissionService.APPROVAL_OBSERVER)  {
            }else  {
                           var approvalurl='/api/projects/approvals/' + this.approvalType + '/completed/' + this.userid + '/' + this.currentPage + '/' + this.pagelimit
                            //this.http.get('api/projectStates/approvals').subscribe((res: Response) => {
                            this.http.get('api/projectStates/' + this.approvalType).subscribe((res: Response) => {
                             this.result1 = res;
                            //  this.approvalproArr=[];
                              this.http.get(approvalurl).subscribe((res: Response) => {
                                this.cusresultComp=[];
                                this.result2 = res;
                                this.cusresultComp = this.result2.approvals;
                                var datacount =this.result2.totalElements
                                this.totalElementsComplete = datacount;
                              if (num == 0) {
                                this.setPagination(datacount);
                              }
                              if(this.cusresultComp.length > 0){
                                this.approvalComArr=[];
                              for (let i = 0; i < this.cusresultComp.length; i++) {
                                this.http.get('api/approvallist/'+this.cusresultComp[i].Project.id).subscribe((res: Response) => {
                                  this.result5 = res;
                                  var completecount=0;
                                  for (let j = 0; j < this.result5.length; j++) {
                                  if(this.result5[j].isCompleted=="Y"){
                                    completecount++;
                                  }
                                }
                                if(this.cusresultComp[i].Project.approvalCompleted == "T"){
                                  var approvalcount  = completecount;
                                 }else{
                                   var leg =this.result1.length
                                  var approvalcount  = parseInt(leg);
                                 }
                                this.colorAppro1 = completecount  / approvalcount * 100;
                                   if( this.colorAppro1 <= 50 ){
                                     this.colorM = "brown";
                                   }
                                   else if( this.colorAppro1 <= 80 ){
                                     this.colorM = "green";
                                   }
                                   else {
                                      this.colorM = "#00b3ca";
                                   }

                                   console.log(JSON.stringify(this.cusresultComp[i]));
                                   console.log(this.cusresultComp[i].duration);
                                   if (this.cusresultComp[i].duration.TotalApprovalDurationDays != 0 && this.cusresultComp[i].duration.TotalApprovalDurationMonths == 0 && this.cusresultComp[i].duration.TotalApprovalDurationYears == 0) {
                                     var dayvalue = this.cusresultComp[i].duration.TotalApprovalDurationDays + " days";
                                   } else if (this.cusresultComp[i].duration.TotalApprovalDurationDays != 0 && this.cusresultComp[i].duration.TotalApprovalDurationMonths != 0 && this.cusresultComp[i].duration.TotalApprovalDurationYears == 0) {
                                     var dayvalue = this.cusresultComp[i].duration.TotalApprovalDurationMonths + " mnt " + this.cusresultComp[i].duration.TotalApprovalDurationDays + " days";
                                   } else if (this.cusresultComp[i].duration.TotalApprovalDurationYears != 0) {
                                     var dayvalue = this.cusresultComp[i].duration.TotalApprovalDurationYears + " Yr " + this.cusresultComp[i].duration.TotalApprovalDurationMonths + " mnt " + this.cusresultComp[i].duration.TotalApprovalDurationDays + " days";
                                   } else {
                                     var dayvalue = this.cusresultComp[i].duration.TotalApprovalDurationDays + " days";
                                   }
                                this.approvalComArr.push({
                                  "approvalproId": this.cusresultComp[i].Project.id, "approvalproName": this.cusresultComp[i].Project.title,"approvalstatus":approvalcount,"approvalcomplete":completecount,"colorAppro":this.colorM,
                                  "approvalcertName": this.cusresultComp[i].certName,"days": dayvalue,
                                  "approvalcouncilName": this.cusresultComp[i].councilName,
                                  "approvalmanagerName": this.cusresultComp[i].managerName
                                })

                                this.spinner.hide();
                              })
                              }
                          }else{
                            this.spinner.hide();
                          }
                        })
                   })
            }
        }

        this.spinner.hide();
      });
 }

 getInComplApproval(num){
  this.spinner.show();
  this.isfitler =false;
    this.http.get('/api/user/'+this.userid).subscribe((res: Response) => {
      this.result= res;
    var permision=this.result.role.permision;
    if(permision.length > 0){
      var approvalPermission = this.permissionService.getApprovalPermission(permision);
      //  if(approvalPermission == this.permissionService.APPROVAL_ADMIN || this.admin == "Y")  {
               if( this.admin == "Y")  {
                 this.getUser();
                 this.isAdminDis=true;
                    var approvalurl='/api/projects/approvals/' + this.approvalType + '/incompleted/' + this.incurrentPage + '/' + this.pagelimit
                          //this.http.get('api/projectStates/approvals').subscribe((res: Response) => {
                          this.http.get('api/projectStates/' + this.approvalType).subscribe((res: Response) => {
                            this.result1 = res;
                            this.http.get(approvalurl).subscribe((res: Response) => {
                              this.cusresultIncomp=[];
                              this.result2 = res;
                              this.cusresultIncomp = this.result2.approvals;
                              var datacount =this.result2.totalElements;
                              this.totalElementsInComplete = datacount;
                            if (num == 0) {
                              this.InComplsetPagination(datacount);
                            }
                            if(this.cusresultIncomp.length > 0){
                              this.approvalproArr=[];
                            for (let i = 0; i < this.cusresultIncomp.length; i++) {
                              this.http.get('api/approvallist/'+this.cusresultIncomp[i].approval.id).subscribe((res: Response) => {
                                this.result4 = res;
                                var completecount=0;
                                for (let j = 0; j <  this.result4.length; j++) {
                                if( this.result4[j].isCompleted=="Y"){
                                  completecount++;
                                }
                              }
                              if(this.cusresultIncomp[i].approval.approvalCompleted == "T"){
                                var approvalcount  = completecount;
                               }else{
                                 var leg =this.result1.length
                                var approvalcount  = parseInt(leg);
                               }
                               this.colorAppro1 = completecount  / approvalcount * 100;
                               if( this.colorAppro1 <= 50 ){
                                 this.colorM = "brown";
                               }
                               else if( this.colorAppro1 <= 80 ){
                                 this.colorM = "green";
                               }
                               else {
                                  this.colorM = "#00b3ca";
                               }
                               console.log(this.cusresultIncomp[i].duration);
                                 if (this.cusresultIncomp[i].duration.TotalApprovalDurationDays != 0 && this.cusresultIncomp[i].duration.TotalApprovalDurationMonths == 0 && this.cusresultIncomp[i].duration.TotalApprovalDurationYears == 0) {
                                   var dayvalue = this.cusresultIncomp[i].duration.TotalApprovalDurationDays + " days";
                                 } else if (this.cusresultIncomp[i].duration.TotalApprovalDurationDays != 0 && this.cusresultIncomp[i].duration.TotalApprovalDurationMonths != 0 && this.cusresultIncomp[i].duration.TotalApprovalDurationYears == 0) {
                                   var dayvalue = this.cusresultIncomp[i].duration.TotalApprovalDurationMonths + " mnt " + this.cusresultIncomp[i].duration.TotalApprovalDurationDays + " days";
                                 } else if (this.cusresultIncomp[i].duration.TotalApprovalDurationYears != 0) {
                                   var dayvalue = this.cusresultIncomp[i].duration.TotalApprovalDurationYears + " Yr " + this.cusresultIncomp[i].duration.TotalApprovalDurationMonths + " mnt " + this.cusresultIncomp[i].duration.TotalApprovalDurationDays + " days";
                                 } else {
                                   var dayvalue = this.cusresultIncomp[i].duration.TotalApprovalDurationDays + " days";
                                 }
                              this.approvalproArr.push({
                                "approvalproId": this.cusresultIncomp[i].approval.id,
                                "approvalproName": this.cusresultIncomp[i].approval.title,
                                "approvalstatus":approvalcount,
                                "approvalcomplete":completecount,
                                "colorAppro":this.colorM,
                                "days": dayvalue,
                                "approvalcertName": this.cusresultIncomp[i].certName,
                                "approvalcouncilName": this.cusresultIncomp[i].councilName,
                                "approvalmanagerName": this.cusresultIncomp[i].managerName
                              })

                              this.spinner.hide();
                            })
                            }
                        }else{
                          this.spinner.hide();
                        }
                      })

                      })
        // }else  if(approvalPermission == this.permissionService.APPROVAL_OBSERVER)  {
                    }else{
                      //this.result3=[];
                       var approvalurl='/api/projects/approvals/' + this.approvalType + '/incompleted/' + this.userid + '/' + this.incurrentPage + '/' + this.pagelimit
                        //this.http.get('api/projectStates/approvals').subscribe((res: Response) => {
                        this.http.get('api/projectStates/' + this.approvalType).subscribe((res: Response) => {
                         this.result1 = res;
                          this.http.get(approvalurl).subscribe((res: Response) => {
                            this.cusresultIncomp=[];
                            this.result2 = res;
                            this.cusresultIncomp = this.result2.approvals;
                            var datacount =this.result2.totalElements;
                            this.totalElementsInComplete = datacount;
                          if (num == 0) {
                            this.InComplsetPagination(datacount);
                          }
                          if(this.cusresultIncomp.length > 0){
                            this.approvalproArr=[];
                          for (let i = 0; i < this.cusresultIncomp.length; i++) {
                            this.http.get('api/approvallist/'+this.cusresultIncomp[i].Project.id).subscribe((res: Response) => {
                              this.result5 = res;
                              var completecount=0;
                              for (let j = 0; j < this.result5.length; j++) {
                              if(this.result5[j].isCompleted=="Y"){
                                completecount++;
                              }
                            }
                            if(this.cusresultIncomp[i].Project.approvalCompleted == "T"){
                              var approvalcount  = completecount;
                             }else{
                               var leg =this.result1.length
                              var approvalcount  = parseInt(leg);
                             }
                            this.colorAppro1 = completecount  / approvalcount * 100;
                               if( this.colorAppro1 <= 50 ){
                                 this.colorM = "brown";
                               }
                               else if( this.colorAppro1 <= 80 ){
                                 this.colorM = "green";
                               }
                               else {
                                  this.colorM = "#00b3ca";
                               }
                               console.log(this.cusresultIncomp[i].duration);
                               if (this.cusresultIncomp[i].duration.TotalApprovalDurationDays != 0 && this.cusresultIncomp[i].duration.TotalApprovalDurationMonths == 0 && this.cusresultIncomp[i].duration.TotalApprovalDurationYears == 0) {
                                 var dayvalue = this.cusresultIncomp[i].duration.TotalApprovalDurationDays + " days";
                               } else if (this.cusresultIncomp[i].duration.TotalApprovalDurationDays != 0 && this.cusresultIncomp[i].duration.TotalApprovalDurationMonths != 0 && this.cusresultIncomp[i].duration.TotalApprovalDurationYears == 0) {
                                 var dayvalue = this.cusresultIncomp[i].duration.TotalApprovalDurationMonths + " mnt " + this.cusresultIncomp[i].duration.TotalApprovalDurationDays + " days";
                               } else if (this.cusresultIncomp[i].duration.TotalApprovalDurationYears != 0) {
                                 var dayvalue = this.cusresultIncomp[i].duration.TotalApprovalDurationYears + " Yr " + this.cusresultIncomp[i].duration.TotalApprovalDurationMonths + " mnt " + this.cusresultIncomp[i].duration.TotalApprovalDurationDays + " days";
                               } else {
                                 var dayvalue = this.cusresultIncomp[i].duration.TotalApprovalDurationDays + " days";
                               }

                            this.approvalproArr.push({
                              "approvalproId": this.cusresultIncomp[i].Project.id, "approvalproName": this.cusresultIncomp[i].Project.title,"approvalstatus":approvalcount,"approvalcomplete":completecount,"colorAppro":this.colorM,
                              "approvalcertName": this.cusresultIncomp[i].certName,
                              "days": dayvalue,
                              "approvalcouncilName": this.cusresultIncomp[i].councilName,
                              "approvalmanagerName": this.cusresultIncomp[i].managerName
                            })

                            this.spinner.hide();
                          })
                          }
                      }else{
                        this.spinner.hide();
                      }
                    })
               })
        }
    }

    this.spinner.hide();
  });
}


   async getUser(){
    this.usersArr=[];
      var result = await this.DetailsService.getUsers();
      this.result0 = result;
      this.result0.forEach(r=> {
        this.usersArr.push({
          "id": r.details.id, "itemName": r.details.firstName + " " + r.details.lastName
        })
      });
    }

     getInAndComplAppl(value,num){
       this.currentPage=1;
       this.incurrentPage=1;
      this.getApprovalByUser(value,num);
      this.getInComplApprovalByUser(value,num);

    }
      getApprovalByUser(value,num){
        this.spinner.show();
        this.isfitler =true;
        if(num == 2){
          this.chguser=value;
        }else{
          this.chguser=value.id;
        }
        //this.result3=[];
        var url ='/api/projects/approvals/' + this.approvalType + '/completed/'+ this.chguser + '/' + this.currentPage + '/' + this.pagelimit
        if(num==0 || num ==2){
          //this.http.get('api/projectStates/approvals').subscribe((res: Response) => {
          this.http.get('api/projectStates/' + this.approvalType).subscribe((res: Response) => {
            this.result6= res;
            this.http.get(url).subscribe((res: Response) => {
              this.resultComp=[];
              this.result2 = res;
              this.resultComp = this.result2.approvals;
              var datacount =this.result2.totalElements
              if (num == 0) {
                this.setPagination(datacount);
              }
              this.approvalComArr =[];
          if(this.resultComp.length > 0){

            for (let i = 0; i < this.resultComp.length; i++) {
              this.http.get('api/approvallist/'+this.resultComp[i].Project.id).subscribe((res: Response) => {
                this.result2 = res;
                var completecount=0;
                for (let j = 0; j < this.result2.length; j++) {
                if(this.result2[j].isCompleted=="Y"){
                  completecount++;
                }
              }
              if(this.resultComp[i].Project.approvalCompleted == "T"){
                var approvalcount  = completecount;
               }else{
                 var leg =this.result6.length
                var approvalcount  = parseInt(leg);
               }
              this.colorAppro1 = completecount  / approvalcount * 100;
                 if( this.colorAppro1 <= 50 ){
                   this.colorM = "brown";
                 }
                 else if( this.colorAppro1 <= 80 ){
                   this.colorM = "green";
                 }
                 else {
                    this.colorM = "#00b3ca";
                 }

              this.approvalComArr.push({
                "approvalproId": this.resultComp[i].Project.id, "approvalproName": this.resultComp[i].Project.title,"approvalstatus":approvalcount,"approvalcomplete":completecount,"colorAppro":this.colorM,
                "approvalcertName": this.resultComp[i].certName,
                "approvalcouncilName": this.resultComp[i].councilName,
                "approvalmanagerName": this.resultComp[i].managerName
              })

              this.spinner.hide();
            })
            }
          }else{
            this.spinner.hide();
          }
          })
         })
        }else{
          this.getApproval(0);
        }
        }


        getInComplApprovalByUser(value,num){
          this.spinner.show();
          this.isfitler =true;
          if(num == 2){
            this.chguser=value;
          }else{
            this.chguser=value.id;
          }
         // this.result3=[];
          var url ='/api/projects/approvals/' + this.approvalType + '/incompleted/'+ this.chguser + '/' + this.incurrentPage + '/' + this.pagelimit
          if(num==0 || num ==2){
            this.http.get('api/projectStates/' + this.approvalType ).subscribe((res: Response) => {
              this.result6= res;
              this.http.get(url).subscribe((res: Response) => {
                this.resultIncomp=[];
                this.result2 = res;
                this.resultIncomp = this.result2.approvals;
                var datacount =this.result2.totalElements
                if (num == 0) {
                  this.InComplsetPagination(datacount);
                }

                this.approvalproArr =[];
            if(this.resultIncomp.length > 0){

              for (let i = 0; i < this.resultIncomp.length; i++) {

                this.http.get('api/approvallist/'+this.resultIncomp[i].Project.id).subscribe((res: Response) => {
                  this.result2 = res;
                  var completecount=0;
                  for (let j = 0; j < this.result2.length; j++) {
                  if(this.result2[j].isCompleted=="Y"){
                    completecount++;
                  }
                }
                if(this.resultIncomp[i].Project.approvalCompleted == "T"){
                  var approvalcount  = completecount;
                 }else{
                   var leg =this.result6.length
                  var approvalcount  = parseInt(leg);
                 }
                this.colorAppro1 = completecount  / approvalcount * 100;
                   if( this.colorAppro1 <= 50 ){
                     this.colorM = "brown";
                   }
                   else if( this.colorAppro1 <= 80 ){
                     this.colorM = "green";
                   }
                   else {
                      this.colorM = "#00b3ca";
                   }


                this.approvalproArr.push({
                  "approvalproId": this.resultIncomp[i].Project.id, "approvalproName": this.resultIncomp[i].Project.title,"approvalstatus":approvalcount,"approvalcomplete":completecount,"colorAppro":this.colorM,
                  "approvalcertName": this.resultIncomp[i].certName,
                  "approvalcouncilName": this.resultIncomp[i].councilName,
                  "approvalmanagerName": this.resultIncomp[i].managerName
                })

                this.spinner.hide();
              })
              }
            }else{
              this.spinner.hide();
            }
            })
           })
          }else{
              this.getInComplApproval(0);
          }
          }

    onSelect(approvalpro){
       this.router.navigate(['/approvals-details',approvalpro]);
    }

    mode = 'determinate';
    value = 50;
    bufferValue = 75;

    setPagination(pagedItems) {
      var val = pagedItems / this.pagelimit;
      var rem = pagedItems % this.pagelimit;
      if (rem != 0) {
        if (val < 1) {
          this.pagedItemspointer = 1;
        } else {
          this.pagedItemspointer = Math.floor(val) + 1;
        }
      } else {
        this.pagedItemspointer = val;
      }
      if (this.pagedItemspointer < this.maxSize) {
        this.range(this.currentPage, this.pagedItemspointer, this.pagedItemspointer);
      } else {
        this.range(this.currentPage, this.maxSize, this.pagedItemspointer);
      }
    }

    InComplsetPagination(pagedItems) {
      var val = pagedItems / this.pagelimit;
      var rem = pagedItems % this.pagelimit;
      if (rem != 0) {
        if (val < 1) {
          this.inpagedItemspointer = 1;
        } else {
          this.inpagedItemspointer = Math.floor(val) + 1;
        }
      } else {
        this.inpagedItemspointer = val;
      }
      if (this.inpagedItemspointer < this.inmaxSize) {
        this.InComplrange(this.incurrentPage, this.inpagedItemspointer, this.inpagedItemspointer);
      } else {
        this.InComplrange(this.incurrentPage, this.inmaxSize, this.inpagedItemspointer);
      }
    }
    prevPage() {
      var perPage = this.currentPage - 1;
      if (this.maxSize >= perPage) {
        var end = this.maxSize;
        var start = 1;
      } else {
        var end = perPage;
        var start = (end + 1) - this.maxSize;
      }
      if (this.reat.includes(perPage)) {
        this.currentPage = perPage;
      } else {
        this.currentPage = perPage;
        this.range(start, end, this.pagedItemspointer);
      }
      if (this.isfitler != true) {
        this.getApproval(1);
      } else {
        this.getApprovalByUser(this.chguser, 2);
      }
    }

    InComplprevPage() {
      var perPage = this.incurrentPage - 1;
      if (this.inmaxSize >= perPage) {
        var end = this.inmaxSize;
        var start = 1;
      } else {
        var end = perPage;
        var start = (end + 1) - this.inmaxSize;
      }
      if (this.InComplreat.includes(perPage)) {
        this.incurrentPage = perPage;
      } else {
        this.incurrentPage = perPage;
        this.InComplrange(start, end, this.inpagedItemspointer);
      }
      if (this.isfitler != true) {
        this.getInComplApproval(0);
      } else {
        this.getInComplApprovalByUser(this.chguser,2)
      }
    }

    setPage(n) {
      this.currentPage = n;
      if (this.isfitler != true) {
        this.getApproval(1);
      } else {
        this.getApprovalByUser(this.chguser, 2);
      }    }

      InComplsetPage(n) {
        this.incurrentPage = n;
        if (this.isfitler != true) {
          this.getInComplApproval(1);
        } else {
          this.getInComplApprovalByUser(this.chguser, 2);
        }
      }
    nextPage() {
      var nextPage = this.currentPage + 1;
      if (this.maxSize < nextPage) {
        var text = this.maxSize + nextPage - 1;
        if (text < this.pagedItemspointer) {
          var end = text;
          var start = nextPage;
        } else {
          end = this.pagedItemspointer;
          var start = nextPage;
        }
      } else {
        var end = this.maxSize;
        var start = 1;
      }
      if (this.reat.includes(nextPage)) {
        this.currentPage = nextPage;
      } else {
        this.currentPage = nextPage;
        this.range(start, end, this.pagedItemspointer)
      }
      if (this.isfitler != true) {
        this.getApproval(1);
      } else {
        this.getApprovalByUser(this.chguser, 2);
      }    }

      InComplnextPage() {
        var nextPage = this.incurrentPage + 1;
        if (this.inmaxSize < nextPage) {
          var text = this.inmaxSize + nextPage - 1;
          if (text < this.inpagedItemspointer) {
            var end = text;
            var start = nextPage;
          } else {
            end = this.inpagedItemspointer;
            var start = nextPage;
          }
        } else {
          var end = this.inmaxSize;
          var start = 1;
        }
        if (this.InComplreat.includes(nextPage)) {
          this.incurrentPage = nextPage;
        } else {
          this.incurrentPage = nextPage;
          this.InComplrange(start, end, this.inpagedItemspointer)
        }
        if (this.isfitler != true) {
          this.getInComplApproval(1);
        } else {
          this.getInComplApprovalByUser(this.chguser, 2);
        }    }

    range(start, end, pages) {
      this.reat = [];
      if (pages == 1) {
        this.reat.push(1);
      } else {
        for (var i = start; i <= end; i++) {
          this.reat.push(i);
        }
      }
    };

    InComplrange(start, end, pages) {
      this.InComplreat = [];
      if (pages == 1) {
        this.InComplreat.push(1);
      } else {
        for (var i = start; i <= end; i++) {
          this.InComplreat.push(i);
        }
      }
    };

    getHeaderLabel() {
      if (this.approvalType == 'approvals') {
        return "Approvals";
      } else if (this.approvalType == 'cc') {
        return "CC";
      } else if (this.approvalType == 'subdivision') {
        return "Subdivision";
      } else {
        return "Approvals";
      }
    }
}

import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorService } from 'src/app/_services/error.service';
import * as moment from 'moment/moment.js';
import { Title, Meta } from '@angular/platform-browser';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';




@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  username = "";
  userid = "";
  regierValidationErr :any;
  entityTypeId:any;
  ProjectName:string;
  result: any;
  password: any[];
  email: any[];
  body: {};
  status = "";
  message = "";
  loginErr = false;
  recovrErr = false;
  forgotPass: boolean = true;
  forgotPass2: boolean = false;
  createRegister: boolean = false;
  regitserPageVerified: boolean = false;
  editSh: boolean = false;
  domainListArr : any;
  loginEmail :any;
  domainNameErr :any;
  emailErr :any;
  domainSiteAdmin :any
  domainRegisterform: FormGroup;

  butDis: boolean = false;
  constructor(private http: HttpClient, private spinner: NgxSpinnerService,
    private errorService: ErrorService, private router: Router, private toastr: ToastrService,
    private titleService: Title, private metaService: Meta,private formBuilder: FormBuilder,) { }

  ngOnInit() {
    // localStorage.clear();
    var loggedIn = localStorage.getItem("loggedIn");
    var ProjectName =localStorage.getItem("ProjectName");
    this.getProjectName();
    this.createForm();

    // if(!ProjectName){
    // }else{
    //   this.ProjectName = ProjectName;
    // }
    if (loggedIn == "true") {
      this.entityTypeId = localStorage.getItem("loggedInUserType");
      if( this.entityTypeId == 1){
        // this.router.navigate(["/profile"]);
        this.router.navigate(["/dashboardnew"]);
      }else if(this.entityTypeId == 2){
        // this.router.navigate(["/profile"]);
          this.router.navigate(["/customer-dashboard"]);
      }else if(this.entityTypeId == 3){
        // this.router.navigate(["/profile"]);
          this.router.navigate(["/certifier-dashboard"]);
      }else if(this.entityTypeId == 4 || this.entityTypeId == 5){
        // this.router.navigate(["/profile"]);
          this.router.navigate(["/externaluser-dashboard"]);
      } else if(this.entityTypeId == 6){
        // this.router.navigate(["/profile"]);
          this.router.navigate(["/council-dashboard"]);
      }
    }
   // this.getProjectName();

  }
  getProjectName(){
    this.http.get('/unsecure/app/name').subscribe((res: Response) => {
      this.result = res;
      this.ProjectName = this.result.aname;
      this.titleService.setTitle(this.result.aname);
      localStorage.setItem("ProjectName", this.result.aname);
    });
  }
  onSubmit(form: NgForm) {
    var data = form.value;
    if (!data.email) {
      this.loginErr = true;
    }else{
    this.loginErr = false;
    this.recovrErr = null;
    this.http.post('/unsecure/login/', data).subscribe((response) => {
      this.result = response;
      var status = this.result.status;
      var message = this.result.message;
      var data = this.result.data.userCommon;
      if (data) {
        // var date =new Date();
        // var tdate = moment(date).format("YYYY-MM-DD")
        // if( data.entityTypeId == 1){
        //   this.router.navigate(["/profile"]);
        //     // this.router.navigate(["/dashboardnew"]);
        // }else if(data.entityTypeId == 2 || data.entityTypeId == 3){
        //   this.router.navigate(["/profile"]);
        //     // this.router.navigate(["/customer-dashboard"]);
        // }
        // localStorage.setItem("loggedIn", "true");
        localStorage.setItem("jwt",this.result.jwt);
        // // localStorage.setItem("domainData", this.result.data.domains);
        localStorage.setItem("loggedInUserID", data.id);
        localStorage.setItem("loggedInUser", data.firstName + " " + data.lastName);
        // // localStorage.setItem("loggedInDomainID", data.domainId);
        // localStorage.setItem("loggedInUser", data.firstName + " " + data.lastName);
        // localStorage.setItem("loggedInUserAdmin", data.isAdmin);
        // localStorage.setItem("loggedIndate", tdate);
        // localStorage.setItem("loggedInUserType", data.entityTypeId);
        localStorage.setItem("loggedInUserproPicId", data.proPicId);
        // this.router.navigate(["/profile"]);
        localStorage.setItem("loggedEmail", data.email);
        if(this.result.data.domains.length ==  1){
           this.domainSelect(this.result.data.domains[0]);
           localStorage.setItem("domainLength", "true");
        }else{
        localStorage.setItem("domainLength", "false");
        // localStorage.setItem("domainLength", "false");
        this.forgotPass = false;
        this.editSh = true;
        this.domainListArr = this.result.data.domains;
        this.domainSiteAdmin = this.result.data.siteAdmin;
        }


      }
      else {
        this.loginErr = true;
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  }

  forgotPasswordPage() {
    this.forgotPass=false;
    this.forgotPass2=true;
    this.butDis = false;
    this.loginErr = null;
    this.recovrErr = null;

  }
  loginPage() {
    this.forgotPass=true;
    this.forgotPass2=false;
    this.recovrErr = null;
  }
  recoverPassword(form: NgForm) {
    var data = form.value;
    if (!data.email) {
      this.recovrErr = true;
    } else {
      this.butDis = true;
      this.http.post('/unsecure/forgotPassword/',data).subscribe((response) => {
        this.result = response;
        var status = this.result.success;
        var message = this.result.message;
        if (status == true) {
          this.toastr.success(message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.forgotPass = true;
          this.forgotPass2=false;
          this.recovrErr = null;
        } else {
          this.toastr.error(message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.butDis = false;
          this.recovrErr = null;
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
        this.butDis = false;
      });
    }
  }
  domainSave(form: NgForm){
    var data = this.domainRegisterform.value;
    if(this.domainRegisterValidate()){
      this.butDis = true;
      this.http.post('/unsecure/domainRegistry',data).subscribe((response) => {
        this.result = response;
        var status = this.result.success;
        var message = this.result.message;
        if (status == true) {
          this.toastr.success(message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.butDis = false;
          this.createRegister =false;
          this.forgotPass = true;
          this.forgotPass2=false;
          this.recovrErr = null;
        } else {
          this.toastr.error(message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.butDis = false;
          this.recovrErr = null;
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
        this.butDis = false;
      });
    }
    }

  domainPage(){
    this.emailErr = null
    this.domainNameErr = false
    this.regierValidationErr = null;
    this.createForm();
    this.loginErr = null;
    this.createRegister =true;
    this.forgotPass2 = false;
    this.forgotPass = false;

  }
  domainPage2(){
    this.createRegister =false;
    this.forgotPass2 = false;
    this.forgotPass = true;
    this.butDis=false;
  }
  domainClose(){
    this.forgotPass = true;
    this.editSh = false;
}
domainSelect(data){
  this.spinner.show();
   this.loginEmail = localStorage.getItem("loggedEmail");
   localStorage.setItem("domainName", data.domainName);
   this.http.post('/unsecure/login/domain'+ '/' + this.loginEmail + '/' + data.domainId,data).subscribe((response) => {
    this.result = response;
    var status = this.result.status;
    var message = this.result.message;
    var data = this.result.data;
    if (data) {
      var date =new Date();
      var tdate = moment(date).format("YYYY-MM-DD")
      localStorage.setItem("loggedIn", "true");
      localStorage.setItem("jwt",this.result.jwt);
      localStorage.setItem("loggedInUserID", data.id);
      localStorage.setItem("loggedInDomainID", data.domainId);
      localStorage.setItem("loggedInUser", data.firstName + " " + data.lastName);
      localStorage.setItem("loggedInUserAdmin", data.isAdmin);
      localStorage.setItem("loggedIndate", tdate);
      localStorage.setItem("loggedInUserType", data.entityTypeId);
      localStorage.setItem("loggedInUserproPicId", data.proPicId);
      localStorage.setItem("domainAdmin", this.result.domainAdmin);
      if( data.entityTypeId == 1){
        this.router.navigate(["/dashboardnew"]);
    }else if(data.entityTypeId == 2){
        this.router.navigate(["/customer-dashboard"]);
    }else if(data.entityTypeId == 3){
        this.router.navigate(["/certifier-dashboard"]);
    }else if(data.entityTypeId == 4 || data.entityTypeId == 5){
        this.router.navigate(["/externaluser-dashboard"]);
    }
    }
    else {
      this.loginErr = true;
    }
  }, error => {
    this.toastr.clear();
    this.toastr.error(error, 'Report Status', {
      timeOut: 6000,
      closeButton: true
    });
    this.errorService.handleError(error);
  });
}
createForm() {
  this.domainRegisterform = this.formBuilder.group({
    customerName: '',
    companyName :'',
    registeredEmailId: '',
    registeredPhone: '',
    shortDescription :''
  })

}
domainRegisterValidate(){
  var data = this.domainRegisterform.value;
  this.regierValidationErr = null;
  const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const validNumber = /^\d+$/;
  const strongRegex = /(?=.{6,})/;
  var valid = true;
  if (!data.customerName) {
    this.regierValidationErr = "Customer Name is required.";
    valid = false;
  } else if (!data.companyName) {
    this.regierValidationErr = "Company Name is required.";
    valid = false;
  }
  else if (!data.registeredEmailId) {
    this.regierValidationErr = "Email is required.";
    valid = false;
  } else if (!validEmailRegEx.test(data.registeredEmailId)) {
    this.regierValidationErr = "Invalid Email.";
    valid = false;
  }else if (!data.registeredPhone) {
    this.regierValidationErr = "Phone Number  is required..";
    valid = false;
  }else if (!validNumber.test(data.registeredPhone)) {
    this.regierValidationErr = "Invalid Phone Number.";
    valid = false;
  }
  return valid;
}
domainRegisteryPage(){
  this.editSh = false;
  this.router.navigate(["/domain-registry"]);
}


}

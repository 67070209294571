import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';

@Component({
    selector: 'app-deal-details-model',
    template: '<p></p>'
})
export class DealDetailsModel {
    form: FormGroup;
    projectUserForm: FormGroup;
    notesUserForm: FormGroup;
    orgForm: FormGroup;
    emailForm: FormGroup;
    emailFormAttach: FormGroup;
    uploadFileForm: FormGroup;
    TaskForm: FormGroup;
    taskComment: FormGroup;
    custForm: FormGroup;
    estimationNameForm: FormGroup;
    menuStateUploadFile: string = 'out';
    menuStateEmail: string = 'out';
    menuStateDealEdit: string = 'out';
    menuStateProjectUserEdit: string = 'out';
    menuStateCustomerEdit: string = 'out';
    menuStateNotesUserEdit: string = 'out';
    menuStateOrgEdit: string = 'out';
    menuStateTaskEdit: string = 'out';
    menuStateEmailEdit: string = 'out';
    nodes: any;
    folderErr = null;
    folderval: boolean = true;
    documentErr = null;
    filemailErr: any;
    options: any;
    fol: boolean = false;
    doc: boolean = false;
    folderform: FormGroup;
    documentform: FormGroup;
    emptyarr = {};
    documentPermissionForm: FormGroup;
    treeEditPermissionTrue : boolean = false;
    filesName :any;
    treeMapId :any;
    nameErr = null;
    custErr = null;
    fileTaskErr = null;
    detailsErr = null;
    taskLogArr = [];
    usersArr = [];
    rolesArr = [];
    taskAttArr = [];
    orgsArr = [];
    taskArr = [];
    noteLogArr = [];
    custArr = [];
    projectUsersArr = [];
    taskUserArr = [];
    containers = [];
    custArrselect = [];
    noteArr = [];
    allActivityArr = [];
    dateCountArr = [];
    projectlogArr = [];
    uploadFileArr = [];
    uploadFilesArr = [];
    fromEmailIdArr = [];
    mailContentsArr = [];
    mailSendArr = [];
    noteTypeList = [];
    filesatt = [];
    taskUser = [];
    userArr = [];
    detailsArr = [];
    projectUserArr = [];
    mailDetailsArr = [];
    mailArr = [];
    taskCommentArr = [];
    taskCommentesAr = [];
    dealstateArr = [];
    notesDesArr = [];
    mailAttachmentArr = [];
    customerAllDetailsArr = [];
    custArrDe = [];
    filesUploadArr = [];
    myFilesTaskArr = [];
    customersArr = [];
    desginGrData = [];
    estimationProperty = [];
    propSaveChild = [];
    dealUploadArr = [];
    estimationUploadArr = [];
    versionNameArr = [];
    versionNameArrSelect = [];
    tapNameSetArr = [];
    myFiles: string[] = [];
    myFilesEmail: string[] = [];
    myFilesTask: string[] = [];
    basicHomeArr = [];

    isCreate: boolean = true;
    isCreate1: boolean = false;
    isdis: boolean = false;
    resetTask: boolean = false;
    gmailsyn: boolean = false;
    isAdminDis: boolean = false;
    iscurrdis: boolean = false;
    iscompleteDis: boolean = false;
    istakenHide: boolean = false;
    is_delete: boolean = false;
    noteCreatedAnddeleteUSer: boolean = false;
    tab01: boolean = false;
    tab02: boolean = false;
    tab03: boolean = false;
    tab04: boolean = false;
    editSh1: boolean = false;
    editSh: boolean = false;
    isDeal: boolean = true;
    internaluser: boolean = false;
    projectUserRoleErr: boolean = false;
    orgExists = false;
    syncheck = false;
    estimationSelectActive: boolean = true;
    tab05: boolean = false;
    estimationTabSelect: boolean = false;
    noDataEstimation: boolean = false;
    editVersioncheck: boolean = false;
    public isCollapsed = false;
    popoverform: boolean = false;
    popoverform2: boolean = false;
    popoverformmail: boolean = false;
    popoverformtask: boolean = false;
    multimail: boolean = false;
    opened = false;
    cc = false;
    bcc = false;

    dealStates = {};
    body: {};
    users = {};
    roles = {};
    orgs = {};
    settings = {};
    settings1 = {};
    selectedFile: File

    projectUserErr = null;
    orgErr = null;
    noteHeadingErr = null;
    noteDescErr = null;

    result: any;
    details: any;
    customer: any;
    customermail: any;
    notes: any;
    allActivity: any;
    orgAllDetails: any;
    taskLog: any;
    noteLog: any;
    projectFromState: any;
    projectToState: any;
    task: any;
    customerAllArrselect: any;
    myDatePickerOptions: any;
    myDatePickerOptions2: any;
    completeBy: any;
    selDate: any;
    dateErr: any;
    StartdateErr: any;
    fd: any;
    custLength: any;
    emailErr: any;
    emailccErr: any;
    emailbccErr: any;
    fileErr: any;
    customerErr: any;
    projectUsers: any;
    tid: any;
    projectId: any;
    userid: any;
    tab1: any = 'tab1';
    tab2: any;
    tab3: any;
    tab4: any;
    step: any;
    myFilesDocument: string[] = [];
    myFilesPhoto: string[] = [];
    myFilesDocumentArr = [];
    fileTodocument: any;
    settingssingle: any;
    eventListSelected: any;
    fileToUpload: any;
    fileToUploadcomt: any;
    fileToEmail: any;
    role: any;
    mail: any;
    allCustomer: any;
    taskcommentId: any;
    i: any;
    nid: any;
    syncmail: string;
    admin: any;
    today: any;
    result1: any;
    result2: any;
    result3: any;
    result4: any;
    res2: any
    res: any;
    res1: any;
    result5: any;
    loggedInUserDomain: any;
    customerAllDetails: any;
    rolesPermissionArr: any;
    mailSignnature: any;
    tab: any = 'singleStorey';
    singleStorey: any;
    doubleStorey: any;
    splitLevel: any;
    duplex: any;
    defaultHomeId: any;
    estimationPropTitle: any;
    copyProjectId: any;
    finalEstHomePreArr = [];
    finalEstGroupPreArr: any;
    generatefileName: any;
    dataSave: any;
    dataSavePreview: any;
    showMe: any;
    estimationFileDetails: any;
    versionSettings: any;
    createVersionId: any;
    createBasicPackageId: any;
    btnName: any;
    currentbaseVersionName: any;
    isLockedCheck: any;
    versionDataDetails: any;
    estimatenameErr: any;
    basicHomeSaveArr: any;
    estimationOptDisplay: any;
    selectedItem: any;
    estimationID: any;

    designVersionId: number;
    bed_value: number;
    bath_value: number;
    garage_value: number;
    widthRange: number;
    storeyId: number;
    basicHomeTotal: number;
    designActive: number;
    partTwoActive: number;
    currentVersionId: number;
    estimationNext: boolean;
    currentbasePackageId: number;
    defaultEstimationPropId: number;
    projectStateIdOrder = -1;
    defaultHomeURL: '';
    defaultHomeName: '';
    defaultHomePrice: '';
    defaultHomeImg: '';

    certMang: any;

    keypeopleForm: any;
    allCouncil: any = [];
    keypeopleAllArrselect:any = [];
    councilErr = '';
    popoverforkeypeople: any;
    menuStateKeypeopleEdit1: string = 'out';


    public createProjectUserForm(FormBuilder) {
        return FormBuilder.group({
            userId: '',
            roleId: ''
        });
      }
      public createProjectorgForm(FormBuilder) {
        return FormBuilder.group({
            orgId: ''
        });
      }
      public createCustForm(FormBuilder) {
        return FormBuilder.group({
            customerId: ''
        });
      }
      public createEmailForm(FormBuilder) {
        return FormBuilder.group({
            to: '',
            subject: '',
            bodyText: '',
            cc: '',
            bcc: '',
            file: '',
            attach: ''
        });
      }
      public createEmailFormAttach(FormBuilder) {
        return FormBuilder.group({
            to: '',
            subject: '',
            bodyText: '',
            cc: '',
            bcc: '',
            file: '',
            attach: ''
        });
      }
      public createUploadFileForm(FormBuilder) {
        return FormBuilder.group({
            file: '',
            attach: ''
        });
      }
      public createnNotesUserForm(FormBuilder) {
        return FormBuilder.group({
            domainId: '',
            viewType: [],
            description: ['', Validators.required],
            heading: '',
            userId: '',
            projectId: '',
            createdAt: '',
            updatedAt: '',
            createdBy: '',
            updatedBy: ''
        });
      }
      public createTaskForm(FormBuilder) {
        return FormBuilder.group({
            domainId: '',
            name: '',
            details: '',
            userId: '',
            projectId: '',
            createdAt: '',
            updatedAt: '',
            startBy: '',
            createdBy: '',
            updatedBy: '',
            taskId: '',
            completeBy: null,
            comment: '',
            commentType: '',
            isCompleted: '',
            isTaken: '',
            type: 'task'
        });
      }
      public createTaskComment(FormBuilder) {
        return FormBuilder.group({
            domainId: '',
            taskId: '',
            comment: '',
            commentType: '',
            commentBy: '',
            attachmentFile: ''
        });
      }
      public createEstimationNameForm(FormBuilder) {
        return FormBuilder.group({
            id: '',
            versionId: [],
            versionName: ''
        });
      }

      public resetprojectUserForm(FormBuilder) {
        return FormBuilder.reset({
            id: '',
            versionId: [],
            versionName: ''
        });
    }

      resetForms() {
        this.emailErr = null;
        this.emailccErr = null;
        this.emailbccErr = null;
        this.projectUserForm.reset({
          userId: [],
          roleId: null
        })
        this.orgForm.reset({
          orgId: []
        })
        this.custForm.reset({
          customerId: []
        })
        this.emailForm.reset({
          to: '',
          subject: '',
          bodyText: '',
          cc: '',
          bcc: '',
          file: '',
          attach: ''


        })
        this.uploadFileForm.reset({
          file: '',
          attach: ''

        });
        this.notesUserForm.reset({
          description: '',
          heading: ''
        })
        this.TaskForm.reset({
          domainId: '',
          name: null,
          details: null,
          userId: [],
          isCompleted: null,
          isTaken: null,
        })
        this.keypeopleForm.reset({
          councilId: []
        });
        this.TaskForm.patchValue({ startBy: null });
        this.TaskForm.patchValue({ completeBy: null });
        this.documentPermissionForm.reset({
          mapId:'',
          isCertifierShared: 'N',
          isCustomerShared: 'N',
          isExternalUserShared: 'N',
        });
        this.folderform.reset({
          document: '',
          // projectId:'',
          // createdDate :'',
          // updatedDate :'',
          // createdBy :'',
          // updatedBy:'',
          // rootId:'',
          // id:'',
          // isActive:''
        });
        this.documentform.reset({
          folder: '',
          projectId: '',
          createdDate: '',
          updatedDate: '',
          createdBy: '',
          updatedBy: '',
          rootId: '',
          id: '',
          isActive: ''
        });
    
      }
      emailAttachReset() {
        this.emailFormAttach.reset({
          file: ''
        })
      }
      esitimationResetForm() {
        this.estimationNameForm.reset({
          id: '',
          versionName: '',
        })
      }

      taskreset() {
        this.taskComment.reset({
          comment: '',
          attachmentFile: ''
        })
      }

// Validation Start

      clearFile(num) {
        if (num == 0) {
          this.fileErr = null;
          var valid = true;
          let data = this.uploadFileForm.value;
          if (!data.file) {
            this.fileErr = "Upload File is Required";
            valid = false;
          }
          return valid;
        } else {
          this.filemailErr = null;
          var valid = true;
          let data = this.emailFormAttach.value;
          if (!data.file) {
            this.filemailErr = "Upload File is Required";
            valid = false;
          } return valid;
        }
      }
      clearTaskFile() {
        this.fileTaskErr = null;
        var valid = true;
        let data = this.taskComment.value;
        if (!data.attachmentFile) {
          this.fileTaskErr = "Upload File is Required";
          valid = false;
        }
        return valid;
      }
      noteValidate() {
        this.noteHeadingErr = null;
        this.noteDescErr = null;
        this.nameErr = null;
        const strongRegex = /^[:;,\-@0-9a-zA-Zגיט'.\s]{1,9999}$/;
        var valid = true;
        var data = this.notesUserForm.value;
        var desc = data.description;
        var edit = $(desc).text();
        if (!data.heading) {
          this.noteHeadingErr = "Heading is required";
          valid = false;
        } else if (data.viewType.length == 0) {
          this.nameErr = "NoteType  is required.";
          valid = false;
        }
        if (edit == undefined || edit == '') {
          this.noteDescErr = "Notes is Required";
          valid = false;
        } 
        // else if (!strongRegex.test(edit)) {
        //   this.noteDescErr = "Too long. Please restrict to 10000 characters";
        //   valid = false;
        // }
        else if (edit.length > 10000) {
          this.noteDescErr = "Too long. Please restrict to 10000 characters";
          valid = false;
        }
        return valid;
      }
      orgValidate() {
        this.orgErr = null;
        var valid = true;
        var data = this.orgForm.value;
        if (data.orgId.length == 0) {
          this.orgErr = "Organization is required";
          valid = false;
        }
        return valid;
      }
      emailValidate(num) {
        this.emailErr = null;
        this.emailccErr = null;
        this.emailbccErr = null;
        var valid = true;
        var data = this.emailForm.value;
        const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


        if (num == 0) {
          if (!data.to) {
            this.emailErr = "Email is required.";
            valid = false;
          } else {
            var valueemail = data.to.split(",");
            for (let i = 0; i < valueemail.length; i++) {
              if (!validEmailRegEx.test(valueemail[i])) {
                this.emailErr = "Invalid Email";
                this.multimail = true;

                valid = false;
              }
            }

          }
        } else if (num == 1) {
          if (data.cc != "") {
            var valueemail = data.cc.split(",");
            for (let i = 0; i < valueemail.length; i++) {
              if (!validEmailRegEx.test(valueemail[i])) {
                this.emailccErr = "Invalid Email";
                this.multimail = true;
                valid = false;
              }
            }
          }
        } else if (num == 2) {
          if (data.bcc != "") {
            var valueemail = data.bcc.split(",");
            for (let i = 0; i < valueemail.length; i++) {
              if (!validEmailRegEx.test(valueemail[i])) {
                this.emailbccErr = "Invalid Email";
                this.multimail = true;
                valid = false;
              }
            }
          }
        }
        return valid;
      }
      taskValidate(num) {
        if (num == 0) {
          this.nameErr = null;
          this.custErr = null;
          this.StartdateErr = null;
          this.dateErr = null;
          this.detailsErr = null;

          var valid = true;
          var data = this.TaskForm.value;
          if (!data.name) {
            this.nameErr = "Name is required";
            valid = false;
          }
          else if (data.userId.length == 0) {
            this.custErr = "User is required";
            valid = false;
          }
          else if (!data.startBy) {
            this.StartdateErr = "Start Date is required";
            valid = false;
          }
          else if (!data.completeBy) {
            this.dateErr = " End Date is required";
            valid = false;
          }
          else if (data.startBy && data.completeBy) {
            var date1 = ("0" + (data.startBy.date.day)).slice(-2);
            var month1 = ("0" + (data.startBy.date.month)).slice(-2);
            var date2 = ("0" + (data.completeBy.date.day)).slice(-2);
            var month2 = ("0" + (data.completeBy.date.month)).slice(-2);
            var startBy = data.startBy.date.year + "-" + month1 + "-" + date1
            var completeBy = data.completeBy.date.year + "-" + month2 + "-" + date2
            if (startBy > completeBy) {
              if (startBy == completeBy) {
                this.dateErr = null;
              } else {
                this.dateErr = " End Date is must greater than Start Date";
                valid = false;
              }
            }
            else if (!data.details) {
              this.detailsErr = "Details is required";
              valid = false;
            }
          }

          return valid;
        } else if (num == 2) {
          this.nameErr = null;
          this.custErr = null;
          this.StartdateErr = null;
          this.dateErr = null;
          this.detailsErr = null;

          var valid = true;
          var data = this.TaskForm.value;
          if (!data.name) {
            this.nameErr = "Name is required";
            valid = false;
          }
          if (data.userId) {
            if (data.userId.length == 0) {
              this.custErr = "User is required";
              valid = false;
            }
          }
          if (!data.startBy) {
            this.StartdateErr = "Start Date is required";
            valid = false;
          }
          if (!data.completeBy) {
            this.dateErr = "End Date is required";
            valid = false;
          }
          if (data.startBy && data.completeBy) {
            var date1 = ("0" + (data.startBy.date.day)).slice(-2);
            var month1 = ("0" + (data.startBy.date.month)).slice(-2);
            var date2 = ("0" + (data.completeBy.date.day)).slice(-2);
            var month2 = ("0" + (data.completeBy.date.month)).slice(-2);
            var startBy = data.startBy.date.year + "-" + month1 + "-" + date1
            var completeBy = data.completeBy.date.year + "-" + month2 + "-" + date2
            if (startBy > completeBy) {
              if (startBy == completeBy) {
                this.dateErr = null;
              } else {
                this.dateErr = " End Date is must greater than Start Date";
                valid = false;
              }
            }
          }
          if (!data.details) {
            this.detailsErr = "Details is required";
            valid = false;
          }
          return valid;
        } else {
          this.isCreate1 = true;
          this.custErr = null;
          this.StartdateErr = null;
          this.dateErr = null;
          this.detailsErr = null;
          var valid = true;
          var data = this.TaskForm.value;
          if (data.userId) {
            if (data.userId.length == 0) {
              this.custErr = "User is required";
              valid = false;
            }
          }
          if (!data.startBy) {
            this.StartdateErr = "Start Date is required";
            valid = false;
          }
          if (!data.completeBy) {
            this.dateErr = " End Date is required";
            valid = false;
          }
          if (data.startBy && data.completeBy) {
            var date1 = ("0" + (data.startBy.date.day)).slice(-2);
            var month1 = ("0" + (data.startBy.date.month)).slice(-2);
            var date2 = ("0" + (data.completeBy.date.day)).slice(-2);
            var month2 = ("0" + (data.completeBy.date.month)).slice(-2);
            var startBy = data.startBy.date.year + "-" + month1 + "-" + date1
            var completeBy = data.completeBy.date.year + "-" + month2 + "-" + date2
            if (startBy > completeBy) {
              if (startBy == completeBy) {
                this.dateErr = null;
              } else {
                this.dateErr = " End Date is must greater than Start Date";
                valid = false;
              }
            }
          }
          return valid;
        }
      }
      custValidate() {
        this.customerErr = null;
        var valid = true;
        var data = this.custForm.value;
        if (data.customerId.length == 0) {
          this.customerErr = "Customer is required";
          valid = false;
        }
        return valid;
      }

      estimatevalidate() {
        this.estimatenameErr = null;
        var data = this.estimationNameForm.value;
        var valid = true;
        if (data.versionName == undefined || data.versionName == '') {
          this.estimatenameErr = "Name is required";
          valid = false;
        }
        return valid;
      }

      // Validation End
}

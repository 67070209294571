import { Component, OnInit, ViewChild } from '@angular/core';
import { Options } from 'fullcalendar';
import { EventSesrvice } from 'src/app/_services/event.service';
import { CalendarComponent } from 'ng-fullcalendar';
import { Router } from '@angular/router';
import * as moment from 'moment/moment.js';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { ErrorService } from 'src/app/_services/error.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, animate, transition, style } from '@angular/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { ConstantPool } from '@angular/compiler';



@Component({
  selector: 'app-schdeule',
  templateUrl: './schdeule.component.html',
  styleUrls: ['./schdeule.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class SchdeuleComponent implements OnInit {
  calendarOptions: Options;
  EventForm: FormGroup;
  displayEvent: any;
  result: any;
  mytime: Date = new Date();
  isCreate: boolean = true;
  body: {};
  settings: any;
  settingssingle: any;
  settingssingleproj: any;
  eid: any;
  userid: any;
  Delasdis: boolean = false;
  Projectdis: boolean = false;
  Custdis: boolean = false;
  Orgdis: boolean = false;
  calnderDis: boolean = false;
  updateDis: boolean = false;
  isHiddenBtnDis: boolean = true;
  fromEmailIdArr = [];
  closeResult: string;
  myDatePickerOptions: any;
  nameErr: any;
  desErr: any;
  dateErr: any;
  timeErr: any;
  durationErr: any;
  dealErr: any;
  orgErr: any;
  selDate: any;
  personErr: any;
  projErr: any;
  eventAssignTypeErr: any;
  menuStateeventEdit: string = 'out';
  customersArr = [];
  customersArrselect = [];
  eventListSelected = [];
  dealArr = [];
  durArr = [];
  timeArr = [];
  orgArr = [];
  orgArrselect = [];
  projectArr = [];
  projectArrselect = [];
  dealArrselect = [];
  linkedArr = [];
  selectedItems = [];
  dropdownList = [];
  eventList = [];
  dropdownSettings = {};
  calendarTitleJql: string;
  timeArrSelected: string;
  durArrSelected: string;
  start: any;
  today: any;
  settings1: any;
  settings2: any;
  result1: any;
  result3: any;
  result2: any;
  res: any;
  data: any;
  popoverform: boolean = false;
  loggedInUserDomain: any;
  eventAttendessArr :any;
  eventResult :any;

  @ViewChild(CalendarComponent) ucCalendar: CalendarComponent;

  constructor(private http: HttpClient, private router: Router,
    private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private errorService: ErrorService,
    private confirmService: ConfirmService,
    private modalService: NgbModal, private activeModal: NgbActiveModal, protected eventService: EventSesrvice, private datePipe: DatePipe, ) {
    this.createForm();
  }


  public resetForm() {
    this.EventForm.reset({
      customerId: [],
      orgId: [],
      dealId: [],
      projId: [],
      eventAssignType: [],
      description: '',
      usr_time: '',
      persons: '',
      todate: '',
      days: '',
      assigendto: '',
      heading: [],
      createdBy: '',
      updatedBy: '',
      eventTypeId: '',
      isHidden: ''
    })
    this.EventForm.patchValue({ startDateTime: null });
  }

  createForm() {
    this.EventForm = this.formBuilder.group({
      heading: '',
      description: '',
      customerId: '',
      orgId: '',
      dealId: '',
      projId: '',
      eventAssignType: '',
      duration: '',
      time: '',
      persons: '',
      usr_time: '',
      startDateTime: '',
      todate: '',
      assigendto: '',
      createdDate: '',
      updatedDate: '',
      createdBy: '',
      updatedBy: '',
      eventTypeId: '',
      isHidden: ''
    })
  }

  eventvalidate() {
    this.nameErr = null;
    this.desErr = null;
    this.dateErr = null;
    this.timeErr = null;
    this.durationErr = null;
    var data = this.EventForm.value;
    if (data.heading == undefined || data.heading == '') {
      this.nameErr = "Event heading is required.";
      var valid = false;
    } else if (data.description == undefined || data.description == '') {
      this.desErr = "Description is required.";
      var valid = false;
    } else if (data.startDateTime == undefined || data.startDateTime == '') {
      this.dateErr = "Date is required.";
      var valid = false;
    } else if (data.time == undefined || data.time == '') {
      this.timeErr = "Time is required.";
      var valid = false;
    } else if (data.duration == undefined || data.duration == '') {
      this.durationErr = "Duration is required.";
      var valid = false;
    } else {
      var valid = true;
      return valid;
    }
  }
  eventAssignType() {
    this.eventAssignTypeErr = null;
    var data = this.EventForm.value;
    var valid = true;
    if (data.eventAssignType.length == 0) {
      this.eventAssignTypeErr = "Assigned To is required.";
      var valid = false;
    }
    return valid;
  }

  evnentAssignValidate(number) {
    this.dealErr = null;
    this.orgErr = null;
    this.personErr = null;
    this.projErr = null;
    var data = this.EventForm.value;
    var valid = true;
    if (number == 0) {
      if (data.dealId.length == 0) {
        this.dealErr = "Deal is required.";
        valid = false;
      }
    }
    if (number == 1) {
      if (data.orgId.length == 0) {
        this.orgErr = "Organization is required.";
        valid = false;
      }
    }
    if (number == 2) {
      if (data.customerId.length == 0) {
        this.personErr = "Person is required.";
        valid = false;
      }
    }
    if (number == 3) {
      if (data.projId.length == 0) {
        this.projErr = "Project is required.";
        valid = false;
      }
    }
    return valid;
  }


  toggleCreateevent(num, date) {
    this.updateDis = false;
    this.EventForm.enable();
    this.eventAssignTypeErr = null;
    this.dealErr = null;
    this.orgErr = null;
    this.personErr = null;
    this.projErr = null;
    this.nameErr = null;
    this.desErr = null;
    this.dateErr = null;
    this.timeErr = null;
    this.durationErr = null;
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    var datetime = moment(date).format("YYYY-MM-DD");
    if (num == 1) {
      this.Custdis = null;
      this.Orgdis = null;
      this.Delasdis = null;
      this.Projectdis = null;
      this.isCreate = true;
      this.nameErr = null;
      this.desErr = null;
      this.dealErr = null;
      this.orgErr = null;
      this.dateErr = null;
      this.timeErr = null;
      this.durationErr = null;
      this.eventAssignTypeErr = null;
      this.resetForm();
      this.menuStateeventEdit = this.menuStateeventEdit === 'out' ? 'in' : 'out';
    }
    if (num == 0) {
      this.resetForm();
      var ydate = moment(datetime).format("YYYY")
      var mdate = moment(datetime).format("M")
      var ddate = moment(datetime).format("D")
      this.EventForm.patchValue({
        'startDateTime': {
          date: {
            year: ydate,
            month: mdate,
            day: ddate
          }, jsdate: date
        }
      });
      this.Custdis = null;
      this.Orgdis = null;
      this.Delasdis = null;
      this.Projectdis = null;
      this.isCreate = true;
      this.nameErr = null;
      this.desErr = null;
      this.dealErr = null;
      this.orgErr = null;
      this.dateErr = null;
      this.timeErr = null;
      this.durationErr = null;
      this.eventAssignTypeErr = null;
      this.menuStateeventEdit = this.menuStateeventEdit === 'out' ? 'in' : 'out';
    }

  }
  showSelectedDate() {
    let expCloseDate: any = this.EventForm.controls['expCloseDate'].value;


  }

  onDateChanged(event) {

  }
  ngOnInit() {
    this.userid = localStorage.getItem("loggedInUserID");
    this.loggedInUserDomain = localStorage.getItem('loggedInDomainID');
    this.calenderSync();
    this.dropdownList = [
      { "id": 1, "itemName": "Deal" },
      { "id": 2, "itemName": "People" },
      { "id": 3, "itemName": "Organization" },
      { "id": 4, "itemName": "Project" },

    ];
    this.eventList = [
      { "id": 1, "itemName": "Call" },
      { "id": 2, "itemName": "Meeting" },
      { "id": 3, "itemName": "Task" },
      { "id": 4, "itemName": "Deadline" },
      { "id": 5, "itemName": "Email" },
      { "id": 6, "itemName": "Lunch" },
    ];

    this.gettime("start");
    this.getduration("start");
    this.settings = {
      text: "Select",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      singleSelection: false,
      enableCheckAll: false
    };
    this.settingssingle = {
      text: "Select",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      singleSelection: true,
      enableCheckAll: false
    };
    this.settingssingleproj = {
      text: "Select",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      singleSelection: true,
      enableCheckAll: false
    };
    this.settings1 = {
      singleSelection: true,
      disabled: true
    }
    this.settings2 = {
      singleSelection: true,
      disabled: true
    }


    this.today = new Date();

    this.myDatePickerOptions = {
      dateFormat: 'dd-mm-yyyy',
      disableUntil: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() - 1 }

    }

    this.calendarFunc();

  }


  calendarFunc() {
    this.spinner.show();
    this.eventService.getEvents().subscribe(data => {
      data = data;
      this.calendarOptions = {
        editable: true,
        eventLimit: false,
        header: {
          left: 'prev,next today',
          center: 'title',
          right: 'month,agendaWeek,agendaDay,listMonth'
        },

        events: data,

      };
      this.spinner.hide();

    });
  }

  calenderSync() {
    this.http.get('/api/userPreferences/' + this.userid).subscribe((res: Response) => {
      this.data = res;
      if (this.data.length > 0) {
        this.fromEmailIdArr.push({
          "fromId": this.data[0].email,
        })
        if (this.data[0].isCalenderSync == "Y") {
          this.calnderDis = true;
        } else {
          this.calnderDis = false;
        }
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);

    });

  }

  btnClick = function () {
    this.router.navigateByUrl('/profile/mailsync');
  };

  gettime(value) {
    this.timeArrSelected = value;
    var x = 15;
    var times = [];
    var tt = 0;
    var ap = [' AM', ' PM'];
    for (var i = 0; tt < 24 * 60; i++) {
      var hh = Math.floor(tt / 60);
      var mm = (tt % 60);
      var a = ("0" + (hh % 12)).slice(-2);
      var b = ("0" + mm).slice(-2) + ap[Math.floor(hh / 12)];
      var vals = a + ":" + b;
      tt = tt + x;
      this.timeArr.push({ "times": vals })
    }

  }
  getduration(value) {
    var x = 15;
    var tt = 0;
    this.durArrSelected = value;
    for (var i = 0; tt < 495; i++) {
      var hh = Math.floor(tt / 60);
      var mm = (tt % 60);
      var a = ("0" + (hh % 12)).slice(-2);
      var b = ("0" + mm).slice(-2);
      var vals = a + ":" + b;
      tt = tt + x;
      this.durArr.push({ "times": vals });
    }


  }

  eventClick(model: any) {
    this.eventAssignTypeErr = null;
    this.dealErr = null;
    this.orgErr = null;
    this.personErr = null;
    this.projErr = null;
    this.nameErr = null;
    this.desErr = null;
    this.dateErr = null;
    this.timeErr = null;
    this.durationErr = null;
    this.updateDis = false;
    this.EventForm.enable();
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    if (model.event.createdBy == this.userid) {
      var datatime = moment(model.event.end._i).format("YYYY-MM-DD");
      var date = new Date();
      var currdate = moment(date).format("YYYY-MM-DD");
      if (datatime < currdate) {
        this.updateDis = true;
        this.EventForm.disable();
      } else {
        this.updateDis = false;
        this.EventForm.enable();
      }
      this.eventEditMenu(model.event.id)
    } else {
      this.updateDis = true;
      this.EventForm.disable();
      this.eventEditMenu(model.event.id)
    }
  }

  updateEvent(model: any) {
    model = {
      event: {
        id: model.event.id,
        start: model.event.start,
        end: model.event.end,
        title: model.event.title
      },
      duration: {
        _data: model.duration._data
      }
    }
    this.displayEvent = model;
  }
  dayClick(event) {
    var datatime = event.date._d;
    var chechdate = moment(event.date._d).format("YYYY-MM-DD");
    var date = new Date();
    var currdate = moment(date).format("YYYY-MM-DD");
    if (chechdate >= currdate) {
      this.toggleCreateevent(0, datatime);
    }
  }


  onItemSelect(item: any) {
    this.dealArr = [];
    this.customersArr = [];
    this.orgArr = [];
    this.orgArrselect = [];
    this.dealArrselect = [];
    this.projectArr = [];
    this.projectArrselect = [];
    this.customersArrselect = [];
    if (item.itemName == "Deal") {
      this.Delasdis = true;
      this.Custdis = false;
      this.Orgdis = false;
      this.Projectdis = false;
      this.http.get('/api/projects/deals/').subscribe((res: Response) => {
        this.result = res;
        for (let i = 0; i < this.result.length; i++) {
          this.dealArr.push({
            "id": this.result[i].deal.id, "itemName": this.result[i].deal.title,
          })
        }
        this.eventAssignType();
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });

    } else if (item.itemName == "People") {
      this.Delasdis = false;
      this.Custdis = true;
      this.Orgdis = false;
      this.Projectdis = false;
      this.http.get('/api/user/').subscribe((res1: Response) => {
        this.result = res1;
        for (let i = 0; i < this.result.length; i++) {
          this.customersArr.push({
            "id": this.result[i].details.id, "itemName": this.result[i].details.firstName + " " + this.result[i].details.lastName,
          })
        }

        this.eventAssignType();
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });


    } else if (item.itemName == "Organization") {
      this.Delasdis = false;
      this.Custdis = false;
      this.Projectdis = false;
      this.Orgdis = true;
      this.http.get('/api/orgs').subscribe((res: Response) => {
        this.result = res;
        for (let i = 0; i < this.result.length; i++) {
          this.orgArr.push({
            "id": this.result[i].id, "itemName": this.result[i].orgName
          })
        }
        this.eventAssignType();
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });

    }
    else if (item.itemName == "Project") {
      this.Delasdis = false;
      this.Custdis = false;
      this.Projectdis = true;
      this.Orgdis = false;
      this.http.get('/api/projectInfo').subscribe((res: Response) => {
        this.result = res;
        for (let i = 0; i < this.result.length; i++) {
          this.projectArr.push({
            "id": this.result[i].projectId, "itemName": this.result[i].projectTitle,
          })
        }
        this.eventAssignType();
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });

    } else {
      this.Delasdis = false;
      this.Custdis = false;
      this.Orgdis = false;
      this.Projectdis = false;
    }
  }
  submitevent() {
    window.scrollTo(0, 0);
    if (this.eventvalidate()) {
      if (this.eventAssignType()) {
        var data1 = {}
        var data = this.EventForm.value;
        var datatime = moment(data.startDateTime.jsdate).format("YYYY-MM-DD");
        var datasec = data.time.split(" ");
        if (datasec[1] == "AM") {
          var startDateTime = datatime + " " + datasec[0] + ":00";
          var dat = data.duration.split(":");
          var dat1 = datasec[0].split(":");
          var hur = (parseInt(dat1[1]) + parseInt(dat[1]))
          if (hur >= 60) {
            var hur1 = (parseInt(dat1[0]) + parseInt(dat[0])) + 1
            var hur2 = hur - 60;
            var vt = hur1 + ":" + hur2;
          } else {
            var hur1 = (parseInt(dat1[0]) + parseInt(dat[0]))
            var vt = hur1 + ":" + hur;
          }
          var duration = datatime + " " + vt + ":00";
        } else {
          var vae = datasec[0];
          var dat = vae.split(":");
          var tm = parseInt(dat[0]) + 12;
          var startDateTime = datatime + " " + tm + ":" + dat[1] + ":00";
          if (tm >= 16) {
            var dat = data.duration.split(":");
            var dat1 = datasec[0].split(":");
            var hur = (parseInt(dat1[1]) + parseInt(dat[1]))
            if (hur >= 60) {
              var hur1 = (tm + parseInt(dat[0])) + 1
              var hur2 = hur - 60;
              if (hur1 >= 24) {
                var date = new Date(datatime);
                date.setDate(date.getDate() + 1);
                var tdate = moment(date).format("YYYY-MM-DD")
                var hur3 = hur1 - 24;
                var vt = hur3 + ":" + hur2;
                var duration = tdate + " " + vt + ":00";
              } else {

                var vt = hur1 + ":" + hur2;
                var duration = datatime + " " + vt + ":00";
              }

            } else {
              var hur1 = (tm + parseInt(dat[0]))
              if (hur1 >= 24) {
                var date = new Date(datatime);
                date.setDate(date.getDate() + 1);
                var tdate = moment(date).format("YYYY-MM-DD")
                var hur3 = hur1 - 24;
                var vt = hur3 + ":" + hur;
                var duration = tdate + " " + vt + ":00";
              } else {

                var vt = hur1 + ":" + hur;
                var duration = datatime + " " + vt + ":00";
              }
            }

          } else {
            var dat = data.duration.split(":");
            var dat1 = datasec[0].split(":");
            var hur = (parseInt(dat1[1]) + parseInt(dat[1]))
            if (hur >= 60) {
              var hur1 = (tm + parseInt(dat[0])) + 1
              var hur2 = hur - 60;
              var vt = hur1 + ":" + hur2;
            } else {
              var hur1 = (tm + parseInt(dat[0]))
              var vt = hur1 + ":" + hur;
            }
            var duration = datatime + " " + vt + ":00";
          }
        }
        data.createdBy = parseInt(this.userid);
        if (data.isHidden == true) {
          data.isHidden = "Y";
        } else {
          data.isHidden = "N";
        }
        if (data.eventAssignType[0].itemName == "Deal") {
          if (this.evnentAssignValidate(0)) {
            this.spinner.show();
            var cusr = [];
            cusr.push(data.dealId[0].id.toString());
            data1 = {
              "createdBy": data.createdBy, "eventAssignType": data.eventAssignType[0].itemName, "heading": data.heading, "eventTypeId": cusr, "startDateTime": startDateTime, "duration": duration, "description": data.description
              , "isHidden": data.isHidden
            };
            this.http.post('/api/calendar', data1).subscribe((response) => {
              this.resetForm();
              this.res = response;
              if (this.res.success == true) {
                this.toastr.clear();
                this.toastr.success(this.res.message, 'Success', {
                  timeOut: 6000,
                  closeButton: true
                });

                this.toggleCreateevent(1, 0);
                window.location.reload();
              } else {
                this.toastr.clear();
                this.toastr.error(this.res.message, 'Error', {
                  timeOut: 6000,
                  closeButton: true
                });
                this.spinner.hide();
              }

            }, error => {
              this.toastr.clear();
              this.toastr.error(error, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.errorService.handleError(error);
            });

          }
        }
        if (data.eventAssignType[0].itemName == "Project") {
          if (this.evnentAssignValidate(3)) {
            this.spinner.show();
            var cusr = [];
            cusr.push(data.projId[0].id.toString());
            data1 = {
              "createdBy": data.createdBy, "eventAssignType": data.eventAssignType[0].itemName, "heading": data.heading, "eventTypeId": cusr, "startDateTime": startDateTime, "duration": duration, "description": data.description
              , "isHidden": data.isHidden
            };
            this.http.post('/api/calendar', data1).subscribe((response) => {
              this.resetForm();
              this.res = response;
              if (this.res.success == true) {
                this.toastr.clear();
                this.toastr.success(this.res.message, 'Success', {
                  timeOut: 6000,
                  closeButton: true
                });

                this.toggleCreateevent(1, 0);
                window.location.reload();
              } else {
                this.toastr.clear();
                this.toastr.error(this.res.message, 'Error', {
                  timeOut: 6000,
                  closeButton: true
                });
                this.spinner.hide();
              }

            }, error => {
              this.toastr.clear();
              this.toastr.error(error, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.errorService.handleError(error);
            });

          }
        }
        if (data.eventAssignType[0].itemName == "Organization") {
          if (this.evnentAssignValidate(1)) {
            this.spinner.show();
            var cusr = [];
            cusr.push(data.orgId[0].id.toString());
            data1 = { "createdBy": data.createdBy, "eventAssignType": data.eventAssignType[0].itemName, "heading": data.heading, "eventTypeId": cusr, "startDateTime": startDateTime, "duration": duration, "description": data.description, "isHidden": data.isHidden };
            this.http.post('/api/calendar', data1).subscribe((response) => {
              this.resetForm();
              this.res = response;
              if (this.res.success == true) {
                this.toastr.clear();
                this.toastr.success(this.res.message, 'Success', {
                  timeOut: 6000,
                  closeButton: true
                });
                this.toggleCreateevent(1, 0);
                window.location.reload();
              } else {
                this.toastr.clear();
                this.toastr.error(this.res.message, 'Error', {
                  timeOut: 6000,
                  closeButton: true
                });
                this.spinner.hide();
              }

            }, error => {
              this.toastr.clear();
              this.toastr.error(error, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.errorService.handleError(error);
            });

          }
        }
        else if (data.eventAssignType[0].itemName == "People") {
          if (this.evnentAssignValidate(2)) {
            this.spinner.show();
            var cusr = [];
            for (let i = 0; i < data.customerId.length; i++) {
              cusr.push(data.customerId[i].id.toString());
            }
            data1 = { "createdBy": data.createdBy, "eventAssignType": data.eventAssignType[0].itemName, "heading": data.heading, "eventTypeId": cusr, "startDateTime": startDateTime, "duration": duration, "description": data.description, "isHidden": data.isHidden };
            this.http.post('/api/calendar', data1).subscribe((response) => {
              this.resetForm();
              this.res = response;
              if (this.res.success == true) {
                this.toastr.clear();
                this.toastr.success(this.res.message, 'Success', {
                  timeOut: 6000,
                  closeButton: true
                });
                this.toggleCreateevent(1, 0);
                window.location.reload();
              } else {
                this.toastr.clear();
                this.toastr.error(this.res.message, 'Error', {
                  timeOut: 6000,
                  closeButton: true
                });
                this.spinner.hide();
              }

            }, error => {
              this.toastr.clear();
              this.toastr.error(error, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.errorService.handleError(error);
            });

          }

        }
      }
    }
  }

  eventEditMenu(id) {
    this.nameErr = null;
    this.desErr = null;
    this.isCreate = false;
    this.timeArr = [];
    this.durArr = [];
    this.eid = id;
    this.http.get('/api/calendar/' + this.eid).subscribe((res: Response) => {
      this.result = res;
      let result1: any = this.result.event;
      let result2: any = this.result.attendees;
      this.eventAttendessArr=this.result.attendees
      if (result1) {
        this.EventForm.patchValue({ 'id': result1.id });     
        this.EventForm.patchValue({ 'heading': result1.heading });
        this.EventForm.patchValue({ 'description': result1.description });
        this.EventForm.patchValue({ 'isActive': result1.isActive });
        this.EventForm.patchValue({ 'createdAt': result1.createdAt });
        this.EventForm.patchValue({ 'updatedAt': result1.updatedAt });
        this.EventForm.patchValue({ 'createdBy': result1.createdBy });
        this.EventForm.patchValue({ 'updatedBy': this.userid });
        if (result1.isHidden == "Y") {
          this.EventForm.patchValue({ 'isHidden': true });
          this.isHiddenBtnDis = true;
          this.updateDis = true;
          this.EventForm.disable();
        } else {
          this.EventForm.patchValue({ 'isHidden': false });
          this.isHiddenBtnDis = false;
        }

        var cdate = result1.startDateTime;
        var dydate = result1.duration;
        var endDate = new Date(dydate);
        var startDate = new Date(cdate);
        var timehr = moment(cdate).format("HH");
        var timemm = moment(cdate).format("mm");
        var noon = parseInt(timehr);
        if (noon < 12) {
          var time = ("0" + timehr).slice(-2) + ":" + ("0" + timemm).slice(-2) + " AM";
        } else {
          noon = noon - 12
          var time = ("0" + noon).slice(-2) + ":" + ("0" + timemm).slice(-2) + " PM";
        }
        this.gettime(time);
        if (result1.eventAssignType == "Deal") {
          this.selectedItems = [];
          this.dealArrselect = [];
          this.selectedItems.push({ "id": 1, "itemName": "Deal" })
          let last: any = this.selectedItems[this.selectedItems.length - 1];
          this.onItemSelect(last);
          for (let i = 0; i < result2.length; i++) {
            this.dealArrselect.push({
              "id": result2[i].id, "itemName": result2[i].name,
            })
          }
        } else if (result1.eventAssignType == "Organization") {
          this.selectedItems = [];
          this.orgArrselect = [];
          this.selectedItems.push({ "id": 3, "itemName": "Organization" })
          let last: any = this.selectedItems[this.selectedItems.length - 1];
          this.onItemSelect(last);
          for (let i = 0; i < result2.length; i++) {
            this.orgArrselect.push({
              "id": result2[i].id, "itemName": result2[i].name,
            })
          }
        } else if (result1.eventAssignType == "People") {
          this.selectedItems = [];
          this.customersArrselect = [];
          this.selectedItems.push({ "id": 2, "itemName": "People" })
          let last: any = this.selectedItems[this.selectedItems.length - 1];
          this.onItemSelect(last);
          for (let i = 0; i < result2.length; i++) {
            this.customersArrselect.push({
              "id": result2[i].id, "itemName": result2[i].name,
            })
          }
        }
        else if (result1.eventAssignType == "Project") {
          this.selectedItems = [];
          this.projectArrselect = [];
          this.selectedItems.push({ "id": 4, "itemName": "Project" })
          let last: any = this.selectedItems[this.selectedItems.length - 1];
          this.onItemSelect(last);
          for (let i = 0; i < result2.length; i++) {
            this.projectArrselect.push({
              "id": result2[i].id, "itemName": result2[i].name,
            })
          }
        }
        var diff = endDate.getTime() - startDate.getTime();
        var days = Math.floor(diff / (60 * 60 * 24 * 1000));
        var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
        var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
        var dur = ("0" + hours).slice(-2) + ":" + ("0" + minutes).slice(-2);
        this.getduration(dur);
        var jsdate = new Date(cdate);
        if (cdate) {
          var ydate = moment(cdate).format("YYYY")
          var mdate = moment(cdate).format("M")
          var ddate = moment(cdate).format("D")
          this.EventForm.patchValue({
            'startDateTime': {
              date: {
                year: ydate,
                month: mdate,
                day: ddate
              }, jsdate: jsdate
            }
          });
        } else {
          this.EventForm.patchValue({ completeBy: null });
        }
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });

    this.menuStateeventEdit = this.menuStateeventEdit === 'out' ? 'in' : 'out';
  }

  EventEnable() {
    this.EventForm.enable();
    this.updateDis = false;
    this.isHiddenBtnDis = false;
  }


  updateevents() {
    window.scrollTo(0, 0);
    if (this.eventvalidate()) {
      if (this.eventAssignType()) {
        var data1 = {}
        var data = this.EventForm.value;
        if (data.isHidden == true) {
          data.isHidden = "Y";
        } else {
          data.isHidden = "N";
        }
        var datatime = moment(data.startDateTime.jsdate).format("YYYY-MM-DD");
        var datasec = data.time.split(" ");
        if (datasec[1] == "AM") {
          var startDateTime = datatime + " " + datasec[0] + ":00";
          var dat = data.duration.split(":");
          var dat1 = datasec[0].split(":");
          var hur = (parseInt(dat1[1]) + parseInt(dat[1]))
          if (hur >= 60) {
            var hur1 = (parseInt(dat1[0]) + parseInt(dat[0])) + 1
            var hur2 = hur - 60;
            var vt = hur1 + ":" + hur2;
          } else {
            var hur1 = (parseInt(dat1[0]) + parseInt(dat[0]))
            var vt = hur1 + ":" + hur;
          }
          var duration = datatime + " " + vt + ":00";
        } else {
          var vae = datasec[0];
          var dat = vae.split(":");
          var tm = parseInt(dat[0]) + 12;
          var startDateTime = datatime + " " + tm + ":" + dat[1] + ":00";
          if (tm >= 16) {
            var dat = data.duration.split(":");
            var dat1 = datasec[0].split(":");
            var hur = (parseInt(dat1[1]) + parseInt(dat[1]))
            if (hur >= 60) {
              var hur1 = (tm + parseInt(dat[0])) + 1
              var hur2 = hur - 60;
              if (hur1 >= 24) {
                var date = new Date(datatime);
                date.setDate(date.getDate() + 1);
                var tdate = moment(date).format("YYYY-MM-DD")
                var hur3 = hur1 - 24;
                var vt = hur3 + ":" + hur2;
                var duration = tdate + " " + vt + ":00";
              } else {

                var vt = hur1 + ":" + hur2;
                var duration = datatime + " " + vt + ":00";
              }

            } else {
              var hur1 = (tm + parseInt(dat[0]))
              if (hur1 >= 24) {
                var date = new Date(datatime);
                date.setDate(date.getDate() + 1);
                var tdate = moment(date).format("YYYY-MM-DD")
                var hur3 = hur1 - 24;
                var vt = hur3 + ":" + hur;
                var duration = tdate + " " + vt + ":00";
              } else {

                var vt = hur1 + ":" + hur;
                var duration = datatime + " " + vt + ":00";
              }
            }

          } else {
            var dat = data.duration.split(":");
            var dat1 = datasec[0].split(":");
            var hur = (parseInt(dat1[1]) + parseInt(dat[1]))
            if (hur >= 60) {
              var hur1 = (tm + parseInt(dat[0])) + 1
              var hur2 = hur - 60;
              var vt = hur1 + ":" + hur2;
            } else {
              var hur1 = (tm + parseInt(dat[0]))
              var vt = hur1 + ":" + hur;
            }
            var duration = datatime + " " + vt + ":00";
          }
        }
        data.createdBy = parseInt(this.userid);
        if (data.eventAssignType[0].itemName == "Deal") {
          if (this.evnentAssignValidate(0)) {
            this.spinner.show();
            var cusr = [];
            cusr.push(data.dealId[0].id.toString());
            data1 = { "id": this.eid, "createdBy": data.createdBy, "eventAssignType": data.eventAssignType[0].itemName, "heading": data.heading, "eventTypeId": cusr, "startDateTime": startDateTime, "duration": duration, "description": data.description, "updatedBy": data.createdBy, "isActive": "Y", "eventCompleted": "N", "isHidden": data.isHidden };
            this.http.put('/api/calendar', data1).subscribe((response) => {
              this.res = response;
              if (this.res.success == true) {
                this.resetForm();
                this.toastr.clear();
                this.toastr.success(this.res.message, 'Success', {
                  timeOut: 6000,
                  closeButton: true
                });
                this.toggleCreateevent(1, 0);
                window.location.reload();
              } else {
                this.toastr.clear();
                this.toastr.error(this.res.message, 'Error', {
                  timeOut: 6000,
                  closeButton: true
                });
                this.spinner.hide();
              }

            }, error => {
              this.toastr.clear();
              this.toastr.error(error, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.errorService.handleError(error);
            });

          }
        }
        else if (data.eventAssignType[0].itemName == "Project") {
          if (this.evnentAssignValidate(3)) {
            this.spinner.show();
            var cusr = [];
            cusr.push(data.projId[0].id.toString());
            data1 = { "id": this.eid, "createdBy": data.createdBy, "eventAssignType": data.eventAssignType[0].itemName, "heading": data.heading, "eventTypeId": cusr, "startDateTime": startDateTime, "duration": duration, "description": data.description, "updatedBy": data.createdBy, "isActive": "Y", "eventCompleted": "N", "isHidden": data.isHidden };
            this.http.put('/api/calendar', data1).subscribe((response) => {
              this.res = response;
              if (this.res.success == true) {
                this.resetForm();
                this.toastr.clear();
                this.toastr.success(this.res.message, 'Success', {
                  timeOut: 6000,
                  closeButton: true
                });
                this.toggleCreateevent(1, 0);
                window.location.reload();
              } else {
                this.toastr.clear();
                this.toastr.error(this.res.message, 'Error', {
                  timeOut: 6000,
                  closeButton: true
                });
                this.spinner.hide();
              }

            }, error => {
              this.toastr.clear();
              this.toastr.error(error, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.errorService.handleError(error);
            });

          }
        } else if (data.eventAssignType[0].itemName == "Organization") {
          if (this.evnentAssignValidate(1)) {
            this.spinner.show();
            var cusr = [];
            cusr.push(data.orgId[0].id.toString());
            data1 = { "id": this.eid, "createdBy": data.createdBy, "eventAssignType": data.eventAssignType[0].itemName, "heading": data.heading, "eventTypeId": cusr, "startDateTime": startDateTime, "duration": duration, "description": data.description, "updatedBy": data.createdBy, "isActive": "Y", "eventCompleted": "N", "isHidden": data.isHidden };
            this.http.put('/api/calendar', data1).subscribe((response) => {
              this.resetForm();
              this.res = response;
              if (this.res.success == true) {
                this.toastr.clear();
                this.toastr.success(this.res.message, 'Success', {
                  timeOut: 6000,
                  closeButton: true
                });
                this.toggleCreateevent(1, 0);
                window.location.reload();
              } else {
                this.toastr.clear();
                this.toastr.error(this.res.message, 'Error', {
                  timeOut: 6000,
                  closeButton: true
                });
                this.spinner.hide();
              }

            }, error => {
              this.toastr.clear();
              this.toastr.error(error, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.errorService.handleError(error);
            });

          }
        }
        else if (data.eventAssignType[0].itemName == "People") {
          if (this.evnentAssignValidate(2)) {
            this.spinner.show();
            var cusr = [];
            for (let i = 0; i < data.customerId.length; i++) {
              if (data.customerId[i].id == 0) {
                var value = data.customerId[i].itemName;
              } else {
                var value = data.customerId[i].id.toString();
              }
              cusr.push(value);
            }
            data1 = { "id": this.eid, "createdBy": data.createdBy, "eventAssignType": data.eventAssignType[0].itemName, "heading": data.heading, "eventTypeId": cusr, "startDateTime": startDateTime, "duration": duration, "description": data.description, "updatedBy": data.createdBy, "isActive": "Y", "eventCompleted": "N", "isHidden": data.isHidden };
            this.http.put('/api/calendar', data1).subscribe((response) => {
              this.resetForm();
              this.res = response;
              if (this.res.success == true) {
                this.toastr.clear();
                this.toastr.success(this.res.message, 'Success', {
                  timeOut: 6000,
                  closeButton: true
                });
                this.toggleCreateevent(1, 0);
                window.location.reload();
              } else {
                this.toastr.clear();
                this.toastr.error(this.res.message, 'Error', {
                  timeOut: 6000,
                  closeButton: true
                });
                this.spinner.hide();
              }
            }, error => {
              this.toastr.clear();
              this.toastr.error(error, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.errorService.handleError(error);
            });

          }

        }
      }
    }
  }
  deleteEvent() {
    this.toggleCreateevent(1, 0);
    this.confirmService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) => {
        if (confirmed) {
          window.scrollTo(0, 0);
          this.spinner.show();
          this.http.delete('/api/calendar/' + this.eid).subscribe((response) => {
            this.res = response;
            if (this.res.success == true) {
              this.toastr.clear();
              this.toastr.success(this.res.message, 'Success', {
                timeOut: 6000,
                closeButton: true
              });
              window.location.reload();
              this.spinner.hide();
            } else {
              this.toastr.clear();
              this.toastr.error(this.res.message, 'Error', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
            }
          }, error => {
            this.toastr.clear();
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.errorService.handleError(error);
          });
        }
      })
      .catch(() => {
        return false;
      });
  }

  eventRefresh() {
    this.spinner.show();
    var domainid = parseInt(this.loggedInUserDomain);
    this.http.get('/api/calendar/read/' + this.userid + '/' + domainid).subscribe((res: Response) => {
      var result = res;
      if (result) {
        window.location.reload();
        this.spinner.hide();
      } else {
        this.spinner.hide();
      }

    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  eventDrop(event) {
    var dropDate = moment(event.event.start._d).format("YYYY-MM-DD");
    var date = new Date();
    var currdate = moment(date).format("YYYY-MM-DD");
    if (dropDate >= currdate) {
      this.spinner.show();
      var dropDate = moment(event.event.start._d).format("YYYY-MM-DD");
      this.http.get('/api/calendar/' + event.event.id).subscribe((res: Response) => {
        this.result = res;
        this.eventResult = this.result.event;
        var attendeesResult = this.result.attendees;
        var cusr = [];
        for (let i = 0; i < attendeesResult.length; i++) {
          if (attendeesResult[i].id == 0) {
            var value = attendeesResult[i].itemName;
          } else {
            var value = attendeesResult[i].id.toString();
          }
          cusr.push(value);
        }
        if (this.eventResult) {
          var startDateHrs = moment(this.eventResult.startDateTime).format("HH:mm:ss");
          var startDateTime = dropDate + " " + startDateHrs;
          var durationHrs = moment(this.eventResult.duration).format("HH:mm:ss");
          var duration = dropDate + " " + durationHrs;
          this.eventResult.startDateTime = startDateTime;
          this.eventResult.duration = duration;
          this.eventResult.updatedBy = parseInt(this.userid);
          this.eventResult.eventTypeId = cusr;
          this.http.put('/api/calendar', this.eventResult).subscribe((response) => {
            this.res = response;
            if (this.res.success == true) {
              this.resetForm();
              this.toastr.clear();
              this.spinner.hide();
              this.toastr.success("Event Moved Successfully", 'Success', {
                timeOut: 6000,
                closeButton: true
              });
            } else {
              this.toastr.clear();
              this.toastr.error(this.res.message, 'Error', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
            }
          }, error => {
            this.toastr.clear();
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.errorService.handleError(error);
          });
        }

      });
    } else {
      this.toastr.error("Not Allowed past Date ", 'Warning', {
        timeOut: 2000,
        closeButton: true
      });
    }

  }
  eventDragStart(event) {
  }
}

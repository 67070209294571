import { Component, OnInit, ɵConsole } from '@angular/core';
import { Options } from 'fullcalendar';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { trigger, state, animate, transition, style } from '@angular/animations';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { Chart } from 'angular-highcharts';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class TasksComponent implements OnInit {
  loggedInUserDomain: any;
  userid: any;
  result: any;
  userDealTasks = [];
  userProjectTasks = [];
  taskLoad: boolean = false;

  constructor(private http: HttpClient,
    private router: Router, private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService) {
    this.userDealTasks = [];
    this.userProjectTasks = [];
  }


  ngOnInit() {
    this.loggedInUserDomain = localStorage.getItem('loggedInDomainID');
    this.userid = localStorage.getItem("loggedInUserID");
    this.getUserTasks();
  }

  getUserTasks() {
    //this.taskLoad = true;
    this.http.get('/api/tasks/listbyuser/' + this.userid).subscribe((res: Response) => {
      //this.taskLoad = false;
      this.result = res;
      console.log(this.result);
      console.log(this.result.tasks);
      this.userDealTasks =  this.result.tasks;
      this.userProjectTasks = this.result.projectTasks;
    });
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { IndexComponent } from '../MainFrame/index/index.component';

import { ModalService } from '../_services';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit {
  result: any;
  body: {};
  orgArr = [];
  custArr = [];
  recordOrg: boolean = false;
  recordCust: boolean = false;
 
  constructor(private http: Http, private router: Router,
    private modalService: ModalService) { }

  ngOnInit() {
    this.getOrg();
    this.getCust();
    this.recordOrg = false;
  }

  getOrg() {
    this.recordCust = true;
    this.http.get('/api/orgs/').subscribe((res: Response) => {
      var result = res.json();
      for (let i = 0; i < result.length; i++) {
        this.orgArr.push({
          "id": result[i].id, "orgName": result[i].orgName, "orgDesc": result[i].orgDesc

        })
      }
    });
  }
  getCust() {
    this.http.get('/api/persons/').subscribe((res: Response) => {
      var result = res.json();
      for (let i = 0; i < result.length; i++) {
        this.custArr.push({
          "id": result[i].id, "orgid": result[i].orgid, "firstName": result[i].firstName, "lastName": result[i].lastName, "phone": result[i].phone, "location": result[i].location
        })
      }
    });
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }
  submitOrg(form: NgForm) {
    var data = form.value;
    this.http.post('/api/org/', data).subscribe((response) => {
      this.result = response;
      this.getOrg();
    })
    this.closeModal('add-org');
    this.orgArr = [];  
  }
  submitCust(form: NgForm) {
    var data = form.value;
    this.http.post('/api/person/', data).subscribe((response) => {
      this.result = response;
      this.getCust();
    })
    this.custArr = [];
  
    this.closeModal('add-cust');
  }

  change1() {
    this.recordOrg = false;
    this.recordCust = true;
  }
  change2() {
    this.recordOrg = true;
    this.recordCust = false;
  }
}

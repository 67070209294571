import { Component, OnInit, ɵConsole } from '@angular/core';
import { Options } from 'fullcalendar';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { EventSesrvice } from 'src/app/_services/event.service';
import { PermissionService } from 'src/app/_services/permission.service';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { trigger, state, animate, transition, style } from '@angular/animations';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { DetailsService } from '../_services/index';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { Chart } from 'angular-highcharts';

@Component({
  selector: 'app-dashboard-new',
  templateUrl: './dashboard-new.component.html',
  styleUrls: ['./dashboard-new.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class DashboardNewComponent implements OnInit {
  calendarOptions: Options;
  eid :any;
  mydeals: any;
  loggedInUserDomain: any;
  dealArr = [];
  projectArr = [];
  taskassignArr = [];
  jobcreatedArr = [];
  myProject: any;
  userid: any;
  result: any;
  result1: any;
  username: any;
  admin: any;
  p: any;
  nid: any;
  res: any;
  pc: any;
  users = {};
  PriorityChart: any;
  low_priority = [];
  medium_priority = [];
  high_priority = [];
  dates_label = [];
  taskArr = [];
  ticketArr = [];
  issuseresul: any;
  ticketcount: any;
  noteTypeList: any;
  settingssingle: any;
  settingsgroup: any;
  notesUserForm: FormGroup;
  isOnly: boolean = false;
  isSuperAdmin: boolean = false;
  isPersonal: boolean = true;
  isCreate: boolean = true;
  addNote: boolean = false;
  is_delete: boolean = false;
  noteCreatedAnddeleteUSer: boolean = false;
  projArrselect: any;
  eventListSelected: any;
  projArr: any;
  notesArr: any;
  noteHeadingErr: any;
  noteDescErr: any;
  nameErr: any;
  projectErr: any;
  menuState: string = 'out';
  pagedItemspointer: any;
  getDealDiagram: any;
  getApprovalDiagram: any;
  approvalproArr: any;
  myapprovals: any;
  taskassigned: any;
  projectJob: any;
  isDeal: boolean = false;
  isApproval: boolean = false;
  popoverformdashboard: boolean = false;
  isProject: boolean = false;
  iscustomer: boolean = true;
  istotal: boolean = false;
  isAdminDis: boolean = false;
  currentPage = 1;
  pagelimit = 25;
  result19: any;
  Filtercheck: boolean = false;
  projArrfilterselect = [];
  maxSize = 5;
  opened = false;
  reat: any;
  item: any;
  num: any;
  chart01: any;
  chart02: any;
  chart03: any;
  chart04: any;
  chart05: any;
  chart06: any;
  itemid: any;
  teckArr = [];
  tickcount: any;
  dealLoad: boolean = false;
  approvalLoad: boolean = false;
  projectLoad: boolean = false;
  taskLoad: boolean = false;
  jobLoad: boolean = false;
  ticketLoad: boolean = false;
  dealChartLoad: boolean = false;
  eventsLoad: boolean = false;
  chartcc: any;
  dealcount: any;
  mydealscount: any;
  myprojectcount: any;
  myapprovalcount: any;
  mydealscountweek: any;
  myprojectcountweek: any;
  myapprovalcountweek: any;
  taskCount: any;
  taskCountweek: any;
  jobcount: any;
  jobcountweek: any;
  ticketcountweek: any;
  constructor(private http: HttpClient, private DetailsService: DetailsService, private router: Router, private formBuilder: FormBuilder, protected eventService: EventSesrvice,
    private toastr: ToastrService, private confirmService: ConfirmService, private spinner: NgxSpinnerService, private permissionService: PermissionService) {
    this.projArrselect = [];
    this.eventListSelected = [];
    this.projArr = [];
    this.notesArr = [];

  }
  toggleAddMenu() {
    if (this.popoverformdashboard == true) {
      this.popoverformdashboard = false;
    } else {
      this.popoverformdashboard = true;
    }
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
    if (this.addNote = true) {
      this.addNote = false
    } else {
      this.addNote = false
    }
  }

  addNotes() {
    this.noteCreatedAnddeleteUSer = false;
    this.is_delete = false;
    var data = this.notesUserForm.value;
    if (!data.viewType || data.viewType.length == 0) {
      this.addNote = !this.addNote;
      this.resetForms();
    } else {
      this.resetForms();
    }
  }
  addNotes1() {
    this.resetForms();
    this.addNote = !this.addNote;
  }

  ngOnInit() {
    this.eventsLoad = true;
    this.createForms()
    this.loggedInUserDomain = localStorage.getItem('loggedInDomainID');
    this.username = localStorage.getItem("loggedInUser");
    this.userid = localStorage.getItem("loggedInUserID");
    this.admin = localStorage.getItem("loggedInUserAdmin");
    this.getPermissionDetails();
    this.getallusercustomeradminProject();
    this.getUser();
    this.noteTypeList = [
      { "id": 1, "itemName": "Personal" },
      { "id": 2, "itemName": "Internal" },
      { "id": 3, "itemName": "External" },
    ];
    this.settingssingle = {
      text: "Select",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      singleSelection: true,
      enableCheckAll: false
    };
    this.settingsgroup = {
      text: "filter Project",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: true,
      enableCheckAll: true,
      groupBy: "type"
    };
  }

  getPermissionDetails() {
    this.http.get('/api/user/' + this.userid).subscribe((res: Response) => {
      this.result = res;
      var permision = this.result.role.permision;
      if (permision.length > 0) {
        var dealPermission = this.permissionService.getDealPermission(permision);
        var approvalPermission = this.permissionService.getApprovalPermission(permision);
        var projectPermission = this.permissionService.getProjectPermission(permision);
        this.getovercalls(0);
        if (dealPermission == this.permissionService.DEAL_ADMIN || dealPermission == this.permissionService.DEAL_OBSERVER) {
          this.isDeal = true;
        }
        if (approvalPermission == this.permissionService.APPROVAL_ADMIN || approvalPermission == this.permissionService.APPROVAL_OBSERVER) {
          this.isApproval = true;
        }
        if (projectPermission == this.permissionService.PROJECT_ADMIN || projectPermission == this.permissionService.PROJECT_OBSERVER) {
          this.isProject = true;
        }
        this.dashbordpermission();
      }
    });
  }
  dashbordpermission() {
    if (this.admin == "Y") {
      this.isSuperAdmin = true;
      this.isAdminDis = false;
    } else if (this.isDeal == true && this.isProject == true && this.isApproval == true) {
      this.isSuperAdmin = true;
      this.isAdminDis = false;
    } else {
      if (this.isDeal == true && this.isApproval == true) {
        this.isSuperAdmin = false;
        this.isAdminDis = true;
      } else if (this.isApproval == true && this.isProject == true) {
        this.isSuperAdmin = false;
        this.isAdminDis = true;
      } else if (this.isDeal == true && this.isProject == true) {
        this.isSuperAdmin = false;
        this.isAdminDis = true;
      } else {
        if (this.isDeal == true || this.isApproval == true || this.isProject == true) {
          this.isSuperAdmin = false;
          this.isAdminDis = false;
          this.isOnly = true;
        }
      }
    }
  }
  typecheck() {
    var data = this.notesUserForm.value;
    if (data.viewType[0].id == 1) {
      this.isPersonal = true;
      this.noteValidate();
    } else if (data.viewType[0].id == 2 || data.viewType[0].id == 3) {
      this.projArrselect = [];
      this.isPersonal = false;
      this.noteValidate();
    }
  }
  getallusercustomeradminProject() {
    this.http.get('/api/issues/projects/' + this.userid).subscribe((res: Response) => {
      this.result = res;
      for (let i = 0; i < this.result.deal.length; i++) {
        this.projArr.push({
          "id": this.result.deal[i].id, "itemName": this.result.deal[i].title, "type": "Deal"
        })
      }
      for (let i = 0; i < this.result.project.length; i++) {
        this.projArr.push({
          "id": this.result.project[i].id, "itemName": this.result.project[i].title, "type": "Project"
        })
      }
    });

  }
  createForms() {
    this.notesUserForm = this.formBuilder.group({
      id: '',
      domainId: '',
      viewType: [],
      description: ['', Validators.required],
      heading: '',
      projectId: '',
      isActive: '',
      createdAt: '',
      updatedAt: '',
      createdBy: '',
      updatedBy: ''

    })
  }
  resetForms() {
    this.isPersonal = true;
    this.projArrselect = [];
    this.eventListSelected = [];
    this.noteTypeList = [];
    this.noteTypeList.push({ "id": 1, "itemName": "Personal" }, { "id": 2, "itemName": "Internal" }, { "id": 3, "itemName": "External" })
    this.notesUserForm.reset({
      description: '',
      heading: ''
    })
  }
  getPersonalNote() {
    this.notesArr = [];
    this.http.get('/api/notes/personal/' + this.userid + "/Personal").subscribe((res: Response) => {
      this.result = res;
      this.result.forEach(r => {
        var desc = r.description;
        var edit = $(desc).text();
        this.notesArr.push({
          "id": r.id, "descName": r.description, "heading": r.heading, "createdAt": r.createdAt, "createdBy": r.createdBy, "viewType": r.viewType,
        })
      })
    });

  }
  noteEditMenu(id) {
    this.noteHeadingErr = null;
    this.noteDescErr = null;
    this.nameErr = null;
    this.projectErr = null;
    this.addNote = true;
    this.nid = id;
    this.http.get('/api/note/' + id).subscribe((res: Response) => {
      this.result = res;
      if (this.result) {
        this.notesUserForm.patchValue({ 'id': this.result.id });
        this.notesUserForm.patchValue({ 'heading': this.result.heading });
        this.notesUserForm.patchValue({ 'description': this.result.description });
        this.notesUserForm.patchValue({ 'isActive': this.result.isActive });
        this.notesUserForm.patchValue({ 'userId': this.result.userId });
        this.notesUserForm.patchValue({ 'domainId': this.result.domainId });
        this.notesUserForm.patchValue({ 'createdAt': this.result.createdAt });
        this.notesUserForm.patchValue({ 'updatedAt': this.result.updatedAt });
        this.notesUserForm.patchValue({ 'createdBy': this.result.createdBy });
        this.notesUserForm.patchValue({ 'updatedBy': this.result.updatedBy });
        if (this.result.createdBy == this.userid || this.admin == "Y") {
          this.noteCreatedAnddeleteUSer = false;
          this.is_delete = true;
        } else {
          this.noteCreatedAnddeleteUSer = true;
          this.is_delete = false;
        }
        if (this.result.viewType == 1) {
          this.isPersonal = true;
          this.notesUserForm.patchValue({ 'projectId': null });
          this.eventListSelected = [];
          this.eventListSelected.push({ "id": 1, "itemName": "Personal" })
        } else if (this.result.viewType == 2) {
          this.noteTypeList = [];
          this.noteTypeList.push({ "id": 2, "itemName": "Internal" }, { "id": 3, "itemName": "External" })
          this.isPersonal = false;
          this.eventListSelected = [];
          this.projArrselect = [];
          this.getProjectForNotes(this.result.projectId);
          this.eventListSelected.push({ "id": 2, "itemName": "Internal" })
        } else if (this.result.viewType == 3) {
          this.noteTypeList = [];
          this.noteTypeList.push({ "id": 2, "itemName": "Internal" }, { "id": 3, "itemName": "External" })
          this.isPersonal = false;
          this.eventListSelected = [];
          this.projArrselect = [];
          this.getProjectForNotes(this.result.projectId);
          this.eventListSelected.push({ "id": 3, "itemName": "External" })
        }
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
    });
  }
  delateNote() {
    this.confirmService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) => {
        if (confirmed) {
          this.spinner.show();
          this.http.delete('/api/notes/' + this.nid).subscribe((response) => {
            this.resetForms();
            this.isCreate = true;
            this.res = response;
            if (this.res.success == true) {
              this.toastr.clear();
              this.toastr.success(this.res.message, 'Success', {
                timeOut: 2000,
                closeButton: true
              });
              this.spinner.hide();
              this.addNotes1();
              this.getPersonalNote();
            } else {

              this.toastr.clear();
              this.toastr.error(this.res.message, 'Error', {
                timeOut: 2000,
                closeButton: true
              });
            }
          }, error => {
            this.toastr.clear();
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
          })
          this.getPersonalNote();
        }
      })
      .catch(() => {
        return false;
      });
  }
  getProjectForNotes(pid) {
    this.http.get('/api/project/' + pid).subscribe((res: Response) => {
      this.result = res;
      if (this.result.approvalCompleted == "F" && this.result.dealCompleted == "F") {
        this.projArrselect.push({ "id": this.result.id, "itemName": this.result.title, "type": "Deal" })
      } else if (this.result.approvalCompleted == "T" && this.result.dealCompleted == "T") {
        this.projArrselect.push({ "id": this.result.id, "itemName": this.result.title, "type": "Project" })
      }
    });
  }
  noteValidate() {
    this.noteHeadingErr = null;
    this.noteDescErr = null;
    this.nameErr = null;
    this.projectErr = null;
    const strongRegex = /^[:;,\-@0-9a-zA-Zגיט'.\s]{1,9999}$/;
    var valid = true;
    var data = this.notesUserForm.value;
    var desc = data.description;
    var edit = $(desc).text();
    if (!data.heading) {
      this.noteHeadingErr = "Heading is required";
      valid = false;
    } else if (!data.viewType || data.viewType.length == 0) {
      this.nameErr = "NoteType  is required.";
      valid = false;
    } else if (data.viewType.length != 0 && data.viewType[0].id != 1) {
      if (!data.projectId || data.projectId.length == 0) {
        this.projectErr = "Project  is required.";
        valid = false;
      }
    }
    if (edit == undefined || edit == '') {
      this.noteDescErr = "Note is required ";
      valid = false;
    }
    else if (edit.length > 10000) {
      this.noteDescErr = "Too long. Please limit to 10000 characters";
      valid = false;
    }
    return valid;
  }


  getovercalls(num) {
    this.getdealbyuser();
    this.getApprovalbyuser();
    this.getProjectUser();
    this.getPersonalNote();
    this.getTaskassigned();
    this.getjobcreated();
    this.getTickets();
    this.dealscount();
    this.calendarFunc();

  }
  async getUser() {
    this.result = await this.DetailsService.getUsers();
    this.result1 = this.result;
    if (this.result) {
      this.users = {};
      this.result1.forEach(r => {
        this.users[r.details.id] = r.details;
      });
    }

  }




  dealscount() {
    this.dealcount = [];
    var url = '/api/projects/cardCount/' + this.userid;
    this.http.get(url).subscribe((res: Response) => {
      this.result = res;
      this.spinner.hide();
      this.mydealscount = this.result.dealCount;
      this.myprojectcount = this.result.projectsCount;
      this.myapprovalcount = this.result.approvalsCount;
      this.taskCount = this.result.taskCount;
      this.jobcount = this.result.jobCount;
      this.tickcount = this.result.ticketsCount;

      this.mydealscountweek = this.result.dealDurationCount;
      this.myprojectcountweek = this.result.projectDurationCount;
      this.myapprovalcountweek = this.result.approvalDurationCount;
      this.taskCountweek = this.result.weekTaskCountAssigned;
      this.jobcountweek = this.result.weekJobCountAssigned;
      this.ticketcountweek = this.result.weekTicketCountAssigned;
      var totalMyDeals = this.result.dealCount - this.result.dealDurationCount
      var totelMyApprovals = this.result.approvalsCount - this.result.approvalDurationCount
      var totalMyProject = this.result.projectsCount - this.result.projectDurationCount
      var totalMyTask = this.result.taskCount - this.result.weekTaskCountAssigned
      var totalMyProjectJob = this.result.jobCount - this.result.weekJobCountAssigned
      var totalMyTicket = this.result.ticketsCount - this.result.weekTicketCountAssigned



      if (this.result.dealCount != this.result.dealDurationCount) {
        if (totalMyDeals == 0 && this.result.dealCount == 0) {
          totalMyDeals = 1;
        }
      } else {
        if (this.result.dealDurationCount == 0 && this.result.dealCount == 0) {
          totalMyDeals = 1;
        }
      }

      if (this.result.approvalsCount != this.result.approvalDurationCount) {
        if (totelMyApprovals == 0 && this.result.approvalsCount == 0) {
          totelMyApprovals = 1;
        }
      } else {
        if (this.result.approvalDurationCount == 0 && this.result.approvalsCount == 0) {
          totelMyApprovals = 1;
        }
      }
      if (this.result.projectsCount != this.result.projectDurationCount) {
        if (totalMyProject == 0 && this.result.projectsCount == 0) {
          totalMyProject = 1;
        }
      } else {
        if (this.result.projectDurationCount == 0 && this.result.projectsCount == 0) {
          totalMyProject = 1;
        }
      }
      if (this.result.taskCount != this.result.weekTaskCountAssigned) {
        if (totalMyTask == 0 && this.result.taskCount == 0) {
          totalMyTask = 1;
        }
      } else {
        if (this.result.weekTaskCountAssigned == 0 && this.result.taskCount == 0) {
          totalMyTask = 1;
        }
      }
      if (this.result.jobCount != this.result.weekJobCountAssigned) {
        if (totalMyProjectJob == 0 && this.result.jobCount == 0) {
          totalMyProjectJob = 1;
        }
      } else {
        if (this.result.weekJobCountAssigned == 0 && this.result.jobCount == 0) {
          totalMyProjectJob = 1;
        }
      }

      if (this.result.ticketsCount != this.result.weekTicketCountAssigned) {
        if (totalMyTicket == 0 && this.result.ticketsCount == 0) {
          totalMyTicket = 1;
        }
      } else {
        if (this.result.weekTicketCountAssigned == 0 && this.result.ticketsCount == 0) {
          totalMyTicket = 1;
        }
      }
      this.dealChartLoad = true;

      this.chart01 = new Chart({
        chart: {
          type: 'pie'
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false
            },
            startAngle: -90,
            endAngle: 90,
            size: 100,
            center: ['50%', '60%']
          }
        },
        series: [{
          type: 'pie',
          innerSize: '40%',
          data: [{
            name: 'Deals',
            color: 'rgba(26, 188, 156,1.0)',
            y: this.result.dealDurationCount
          }, {
            name: 'Deals',
            color: 'rgba(26, 188, 156,0.70)',
            y: totalMyDeals
          }]
        }]
      });

      this.chart02 = new Chart({
        chart: {
          type: 'pie'
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false
            },
            startAngle: -90,
            endAngle: 90,
            size: 100,
            center: ['50%', '60%']
          }
        },
        series: [{
          type: 'pie',
          innerSize: '40%',
          data: [{
            name: 'Vendor A',
            color: 'rgba(0, 179, 202,1.0)',
            y: this.result.approvalDurationCount
          },
          {
            name: 'Vendor A',
            color: 'rgba(55, 211, 231,0.70)',
            y: totelMyApprovals
          }]
        }]
      });

      this.chart03 = new Chart({
        chart: {
          type: 'pie'
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false
            },
            startAngle: -90,
            endAngle: 90,
            size: 100,
            center: ['50%', '60%']
          }
        },
        series: [{
          type: 'pie',
          innerSize: '40%',
          data: [{
            name: 'Vendor A',
            color: 'rgba(108, 92, 231,1.0)',
            y: this.result.projectDurationCount
          },
          {
            name: 'Vendor A',
            color: 'rgba(108, 92, 231,0.70)',
            y: totalMyProject
          }]
        }]
      });

      this.chart04 = new Chart({
        chart: {
          type: 'pie'
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false
            },
            startAngle: -90,
            endAngle: 90,
            size: 100,
            center: ['50%', '60%']
          }
        },
        series: [{
          type: 'pie',
          innerSize: '40%',
          data: [{
            name: 'Vendor A',
            color: 'rgba(248, 85, 90,1.0)',
            y: this.result.weekTaskCountAssigned
          }, {
            name: 'Vendor A',
            color: 'rgba(248, 85, 90,0.70)',
            y: totalMyTask
          }]
        }]
      });

      this.chart05 = new Chart({
        chart: {
          type: 'pie'
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false
            },
            startAngle: -90,
            endAngle: 90,
            size: 100,
            center: ['50%', '60%']
          }
        },
        series: [{
          type: 'pie',
          innerSize: '40%',
          data: [{
            name: '',
            color: 'rgba(253, 121, 168,1.0)',
            y: this.result.weekJobCountAssigned
          }, {
            name: '',
            color: 'rgba(253, 121, 168,0.75)',
            y: totalMyProjectJob
          }]
        }]
      });

      this.chart06 = new Chart({
        chart: {
          type: 'pie'
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false
            },
            startAngle: -90,
            endAngle: 90,
            size: 100,
            center: ['50%', '60%']
          }
        },
        series: [{
          type: 'pie',
          innerSize: '40%',
          data: [{
            name: 'Vendor A',
            color: 'rgba(253, 203, 110,1.0)',
            y: this.result.weekTicketCountAssigned
          },
          {
            name: 'Vendor A',
            color: 'rgba(253, 203, 110,0.70)',
            y: totalMyTicket
          }]
        }]
      });

    });
  }

  getdealbyuser() {
    this.dealArr = [];
    var url = '/api/projects/' + this.userid;
    this.http.get(url).subscribe((res: Response) => {
      this.result = res;
      this.mydeals = this.result.length;

      if (this.result.length < 10) {
        this.mydeals = ("0" + this.result.length).slice(-2);
      } else if (this.result.length == 0) {
        this.mydeals = "00";
      } else {
        this.mydeals = this.result.length;
      }
      this.chartcc = this.mydeals * this.mydeals;
      this.result.forEach(r => {
        if (r.Project.title.length > 50) {
          var name = r.Project.title.slice(0, 52).concat("...");
        } else {
          var name = r.Project.title;
        }
        this.dealArr.push({
          "id": r.Project.id, "itemName": name, "createdDate": r.Project.createdDate, "dealCompleted": r.Project.dealCompleted, "value": r.Project.value, "State": r.state.name
        })
      });
      this.dealLoad = true;
    })
    this.spinner.hide();
  }
  getApprovalbyuser() {
    this.approvalproArr = [];
    var url = '/api/projects/approvals/approvals/' + this.userid;
    this.http.get(url).subscribe((res: Response) => {
      this.result = res;
      this.myapprovals = this.result.length;
      if (this.result.length < 10) {
        this.myapprovals = ("0" + this.result.length).slice(-2);
      } else if (this.result.length == 0) {
        this.myapprovals = "00";
      } else {
        this.myapprovals = this.result.length;
      }
      this.result.forEach(r => {
        if (r.Project.title.length > 50) {
          var name = r.Project.title.slice(0, 52).concat("...");
        } else {
          var name = r.Project.title;
        }
        this.approvalproArr.push({
          "approvalproId": r.Project.id, "approvalproName": name, "createdBy": r.Project.createdBy, "value": r.Project.value, "createdDate": r.Project.createdDate, "approvalCompleted": r.Project.approvalCompleted, "checkedStates": r.checkedStates, "totalStates": r.totalStates

        })
      });
      this.approvalLoad = true;
    })
  }
  getProjectUser() {
    this.projectArr = [];
    var url = '/api/projects/projects/' + this.userid;
    this.http.get(url).subscribe((res: Response) => {
      this.result = res;
      this.myProject = this.result.length;
      if (this.result.length < 10) {
        this.myProject = ("0" + this.result.length).slice(-2);
      } else if (this.result.length == 0) {
        this.myProject = "00";
      } else {
        this.myProject = this.result.length;
      }
      this.result.forEach(r => {
        if (r.Project.title.length > 50) {
          var name = r.Project.title.slice(0, 52).concat("...");
        } else {
          var name = r.Project.title;
        }
        this.projectArr.push({
          "projectID": r.Project.id, "projectName": name, "createdBy": r.Project.createdBy, "value": r.Project.value, "createdDate": r.Project.createdDate, "projectCompleted": r.Project.projectCompleted, "curJob": r.curJob
        })
      });
      this.projectLoad = true;
    })
  }

  getTaskassigned() {
    this.http.get('/api/userTaskAssigned/' + this.userid).subscribe((res: Response) => {
      this.result = res;
      this.taskassigned = this.result.taskCount;
      if (this.result.taskCount < 10) {
        this.taskassigned = ("0" + this.result.taskCount).slice(-2);
      } else if (this.result.taskCount == 0) {
        this.taskassigned = "00";
      } else {
        this.taskassigned = this.result.taskCount;
      }
      var taskDataList = this.result.taskDataList
      for (let i = 0; i < taskDataList.length; i++) {
        if (taskDataList[i].Task.name.length > 50) {
          var name = taskDataList[i].Task.name.slice(0, 52).concat("...");
        } else {
          var name = taskDataList[i].Task.name;
        }
        this.taskassignArr.push({
          "taskid": taskDataList[i].Task.id, "TaskName": name, "completeBy": taskDataList[i].Task.completeBy, "isCompleted": taskDataList[i].Task.isCompleted, "createdDate": taskDataList[i].Task.createdAt, "ProjectId": taskDataList[i].Project.id, "ProjectTitle": taskDataList[i].Project.title, "UserId": taskDataList[i].User.id, "UserName": taskDataList[i].User.firstName + " " + taskDataList[i].User.lastName, "type": taskDataList[i].Task.type
        })
      }
      this.taskLoad = true;
    });
  }

  getjobcreated() {
    this.http.get('/api/projectJob/user/' + this.userid).subscribe((res: Response) => {
      this.result = res;
      this.projectJob = this.result.jobCount;
      if (this.result.jobCount < 10) {
        this.projectJob = ("0" + this.result.jobCount).slice(-2);
      } else if (this.result.jobCount == 0) {
        this.projectJob = "00";
      } else {
        this.projectJob = this.result.jobCount;
      }
      var masterList = this.result.masterList;
      for (let i = 0; i < masterList.length; i++) {
        if (masterList[i].jobName.length > 50) {
          var name = masterList[i].jobName.slice(0, 52).concat("...");
        } else {
          var name = masterList[i].jobName;
        }
        this.jobcreatedArr.push({
          "id": masterList[i].id, "jobName": name, "projectName": masterList[i].projectName, "assigned": masterList[i].assigned,
          "startDate": masterList[i].startDate, "endDate": masterList[i].endDate, "ProjectId": masterList[i].projectId, "edit": "true", "jobtype": masterList[i].type
        })
      }
      this.jobLoad = true;
    });
  }

  getTickets() {
    this.itemid = 10;
    this.http.get('/api/userTicketAssigned/paging/' + this.userid + '/' + 1 + '/' + 5).subscribe((res: Response) => {
      this.result = res;
      for (let i = 0; i < this.result.length; i++) {
        if (this.result[i].ticket.name.length > 50) {
          var name = this.result[i].ticket.name.slice(0, 52).concat("...");
        } else {
          var name = this.result[i].ticket.name;
        }
        this.teckArr.push({
          "id": this.result[i].ticket.id, "name": name,
          "ProjectId": this.result[i].Project.id,
          "project": this.result[i].Project.title, "status": this.result[i].ticket.statusId,
          "priority": this.result[i].ticket.priorityId, "endDate": this.result[i].ticket.completeBy
        })
      }
      this.ticketLoad = true;
    });
  }
  submitNote() {
    var data = this.notesUserForm.value;
    if (this.noteValidate()) {
      this.spinner.show();
      if (data.viewType[0].id == 1) {
        var url = "/api/notes/Personal"
      } else if (data.viewType[0].id == 2 || data.viewType[0].id == 3) {
        if (data.projectId[0].type == "Deal") {
          var url = "/api/notes/Deal"
        } else if (data.projectId[0].type == "Project") {
          var url = "/api/notes/Project"
        }
        if (data.projectId.length > 0) {
          data.projectId = data.projectId[0].id;
        }  else {
          data.projectId = null;
        }
      }
      if (data.viewType.length > 0) {
        data.viewType = data.viewType[0].id;
      }
      data.domainId = parseInt(this.loggedInUserDomain);
      data.createdBy = parseInt(this.userid);
      if (!data.id) {
        this.http.post(url, data).subscribe((response) => {
          this.toastr.clear();
          this.result = response;
          if (this.result.success == true) {
            this.addNotes();
            this.notesArr = [];
            this.getPersonalNote();
            this.toastr.success(this.result.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
          } else {
            this.toastr.error(this.result.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
          }
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        })
      } else {
        data.updatedBy = parseInt(this.userid);
        this.http.put(url, data).subscribe((response) => {
          this.toastr.clear();
          this.result = response;
          if (this.result.success == true) {
            this.addNotes();
            this.notesArr = [];
            this.getPersonalNote();
            this.toastr.success(this.result.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
          } else {
            this.toastr.error(this.result.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
          }
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        })
      }

    }

  }
  onSelectdeals(deals) {
    this.router.navigate(['/details', deals]);
  }
  onSelectapproval(approvalpro) {
    this.router.navigate(['/approvals-details', approvalpro]);
  }
  onSelectProject(projects) {
    this.router.navigate(['/project-details', projects]);
  }
  ontasks(deals, taskid) {
    this.router.navigate(['/details', deals]);
  }
  calendarFunc() {
    this.eventsLoad = false;
    this.eventService.getPeopleListEvents().subscribe(data => {
      data = data;
      this.calendarOptions = {
        defaultView: 'listMonth',
        editable: false,
        eventLimit: false,
        header: {
          left: 'prev,next today',
          center: 'title',
          right: '',

        },
        events: data
      };
      this.eventsLoad = true;
    });
  }
  eventClick(model: any) {
    this.eid = model.event.id
    this.confirmService.confirm('Please confirm..', 'Do you want mark this event as Done?')
      .then((confirmed) => {
        if (confirmed) {
          var data = {}
          this.http.put('/api/calendar/hide/' + this.eid, data).subscribe((response) => {
            this.toastr.clear();
            this.toastr.success('Events Done!', 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.result = response;
            if (this.result) {
              window.location.reload();
            }
          })
        } else {
          return false;
        }


      })
      .catch(() => {
        return false;
      });
  }

}

import { Component, OnInit, ɵConsole, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { DetailsService } from '../_services/index';
import * as moment from 'moment/moment.js';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { ErrorService } from 'src/app/_services/error.service';
import { PermissionService } from 'src/app/_services/permission.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { trigger, state, animate, transition, style } from '@angular/animations';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { HttpHeaders } from '@angular/common/http';
import { NgForm, EmailValidator } from '@angular/forms';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';


@Component({
  selector: 'app-issues',
  templateUrl: './issues.component.html',
  styleUrls: ['./issues.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class IssuesComponent implements OnInit {
  maxChars = 255;
  note : ''
  myFilter: any;
  Filtercheck: boolean = false;
  navspec: boolean = false;
  pd: any;
  key: any;
  sort: any;
  filter: any;
  result: any;
  details: any;
  customer: any;
  customermail: any;
  notes: any;
  allActivity: any;
  taskLog: any;
  noteLog: any;
  projectUsersArr = [];
  projArr = [];
  // projFiterArr = [];
  projArrfilterselect = [];
  reverse: any;
  p: any;
  taskUserArr = [];
  containers = [];
  projectFromState: any;
  projectToState: any;
  task: any;
  noteLogArr = [];
  custArr = [];
  custArrselect = [];
  projArrselect = [];
  projectUsersArrselect = [];
  myDatePickerOptions: any;
  myDatePickerOptions2: any;
  completeBy: any;
  selDate: any;
  dateErr: any;
  projerr: any;
  StartdateErr: any;
  fd: any;
  TaskForm: FormGroup;
  FilterForm: FormGroup;
  taskComment: FormGroup;
  subTicketForm: FormGroup;
  nameErr = null;
  // custErr = null;
  proErr = null;
  fileTaskErr = null;
  detailsErr = null;
  priorityErr = null;
  taskLogArr = [];
  usersArr = [];
  rolesArr = [];
  taskAttArr = [];
  taskArr = [];
  taskTotalElements :any
  allActivityArr = [];
  dateCountArr = [];
  projectlogArr = [];
  uploadFileArr = [];
  uploadFilesArr = [];
  isCustomer: boolean = false;
  isCreate: boolean = true;
  isCreate1: boolean = false;
  isdis: boolean = false;
  resetTask: boolean = false;
  isAdminDis: boolean = false;
  iscurrdis: boolean = false;
  iscompleteDis: boolean = false;
  istakenHide: boolean = false;
  isDeal: boolean = true;
  overlay: boolean = false;
  fileToUpload: any;
  fileToEmail: any;
  role: any;
  users = {};
  projectUsers: any;
  roles = {};
  tid: any;
  form: FormGroup;
  projectId: any;
  userid: any;
  settings: any;
  settingsMuliti: any;
  settings02: any;
  settings3: any;
  settings4: any;
  settings5: any;
  settings7: any;
  settings6: any;
  settings8: any;
  selectedFile: File
  projectUserRoleErr: boolean = false;
  projectUserErr = null;
  projectUserForm: FormGroup;
  uploadFileForm: FormGroup;
  menuStateUploadFile: string = 'out';
  menuStateTaskEdit: string = 'out';
  userType: any;
  dealstateArr = [];
  notesDesArr = [];
  dealStates = {};
  projectStateIdOrder = -1;
  i: any;
  nid: any;
  body: {};
  mail: any;
  taskcommentId: any;
  taskCommentArr = [];
  taskCommentesAr = [];
  opened = false;
  admin: any;
  today: any;
  filesatt = [];
  taskUser = [];
  userArr = [];
  detailsArr = [];
  projectUserArr = [];
  istotal: boolean = false;
  isApproval: boolean = true;
  isProject: boolean = true;
  iscustomer: boolean = true;
  isoverlay: boolean = false;
  cmtshw: boolean = false;
  proArr = [];
  is_edit: boolean = false;
  etrue: boolean = false;
  dropdownList = [];
  dropdownList1 = [];
  statusListSelected = [];
  statusErr: any;
  settingsgroup: any;
  settingsgroup1: any;
  settingsgroup2 :any;
  isvaluechecks: boolean = false;
  priorityListSelected = [];
  pagedItemspointer: any;
  currentPage = 1;
  maxSize = 5;
  pagelimit = 25;
  reat: any;
  issuseresul: any;
  result1: any;
  result2: any;
  result3: any;
  result4: any;
  result5: any;
  result6: any;
  result7: any;
  result8: any;
  result9: any;
  result10: any;
  result11: any;
  result12: any;
  result13: any;
  result14: any;
  result15: any;
  result16: any;
  result17: any;
  result18: any;
  result19: any;
  step: any;
  username: any;
  filesResult: any
  statusDropdown: any;
  filterUserID: any;
  filterPriorityID: any;
  filterStatusID: any;
  filterProjectID: any;
  priorityResult: any;
  statusResult: any;
  projectResult: any;
  userResult: any;
  filtersHideAndShow: boolean = false;
  projectFilterId: any;
  userFilterId: any;
  prorityFilterId: any;
  statusFilterId: any;
  projectUsersArrselect1 = [];
  priorityListSelected1 = [];
  statusListSelected1 = [];
  res: any;
  res1: any;
  filesToUpload: Array<File> = [];
  resultFiles: any;
  popoverform: boolean = false;
  @ViewChild("docname") docname: ElementRef;
  myFilesTicket: string[] = [];
  myFilesTicketArr = [];
  childResultShow: boolean = true;
  childTicketArr = [];
  parentId: any;
  taskNameHead: any;
  parentProjectId: number;
  completTicketResult: any;
  child_show: boolean = false;
  
  tab01: boolean = false;
  tab02: boolean = false;
  tab03: boolean = false;
  tab04: boolean = false;
  tab05: boolean = false;
  editsh: boolean = false;
  taball: boolean = true;
  subticket: boolean = false;
  subticketBtn: boolean = false;
  subTicketSpinner: boolean = false;

  constructor(private DetailsService: DetailsService, private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService, private toastr: ToastrService,
    private errorService: ErrorService,
    private confirmService: ConfirmService,
    private formBuilder: FormBuilder,
    private permissionService: PermissionService,
    private http: HttpClient, ) {
    this.userid = localStorage.getItem("loggedInUserID");
    this.settings = {
      text: "Select User",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: true,
      enableCheckAll: false
    };
    this.settingsMuliti = {
      text: "Select User",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: true,
      enableCheckAll: false
    };
    this.settings5 = {
      text: "Select status",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: false,
      enableCheckAll: false
    };
    this.settings7 = {
      text: "Select status",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: true,
      enableCheckAll: false
    };
    this.settings6 = {
      text: "Select Priority",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: true,
      enableCheckAll: false
    };
    this.statusDropdown = {
      text: "Select Status",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: true,
      enableCheckAll: false
    };
    this.settings8 = {
      text: "Select Project",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: true,
      enableCheckAll: false
    };

    this.settingsgroup = {
      text: "Filter Project",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: true,
      enableCheckAll: true,
      groupBy: "type"
    };
    this.settingsgroup1 = {
      text: "Select Project",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: true,
      // enableCheckAll: true,
      groupBy: "type"
    };
    this.settingsgroup2 = {
      text: "Select Project",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: true,
      singleSelection: true,
      // enableCheckAll: true,
      groupBy: "type"
    };

    this.settings3 = {
      text: "Select User",
      disabled: true,
    };

    this.settings4 = {
      text: "Select Project",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      singleSelection: true,
      enableCheckAll: false
    };
  }
  showSelectedDate() {
    let completeBy: any = this.form.controls['completeBy'].value;
  }
  onDateChanged(event) {

  }
  ngOnInit() {
    this.spinner.show();
    this.username = localStorage.getItem("loggedInUser");
    this.admin = localStorage.getItem("loggedInUserAdmin");
    this.userType = localStorage.getItem("loggedInUserType");
    this.getallusercustomeradminProject();
    if (this.userType == 2) {
      this.isCustomer = true;

    } else {
      this.isCustomer = false;
    }
    this.createForms();
    this.getUser();
    this.today = new Date();
    this.myDatePickerOptions = {
      dateFormat: 'dd-mm-yyyy',
      disableUntil: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() - 1 }

    }
    this.myDatePickerOptions2 = {
      dateFormat: 'dd-mm-yyyy',
      disableUntil: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() - 1 }

    }
    this.getDetails();
    this.dropdownList = [
      { "id": 1, "itemName": "Open" },
      { "id": 2, "itemName": "InProgress" },
      { "id": 3, "itemName": "Completed" },
      { "id": 4, "itemName": "Duplicate" },

    ];

    this.dropdownList1 = [
      { "id": 1, "itemName": "High" },
      { "id": 2, "itemName": "Medium" },
      { "id": 3, "itemName": "Low" },

    ];
    // this.step="step1";
  }
  createForms() {
    this.TaskForm = this.formBuilder.group({
      name: '',
      parentId :'',
      project: '',
      details: '',
      userId: '',
      projectId: '',
      createdAt: '',
      updatedAt: '',
      startBy: '',
      createdBy: '',
      updatedBy: '',
      taskId: '',
      completeBy: null,
      isCompleted: '',
      isTaken: '',
      type: '',
      priorityId: '',
      statusId: []
    })
    this.FilterForm = this.formBuilder.group({
      userIdIn: '',
      userId: '',
      projectIdIn: '',
      priorityId: '',
      statusIdIn: []
    })
    this.taskComment = this.formBuilder.group({
      taskId: '',
      comment: '',
      commentType: '',
      commentBy: '',
      attachmentFile: ''

    })
    this.subTicketForm = this.formBuilder.group({
      name: '',
      parentId :'',
      project: '',
      details: '',
      userId: '',
      projectId: [],
      createdAt: '',
      updatedAt: '',
      startBy: '',
      createdBy: '',
      updatedBy: '',
      taskId: '',
      completeBy: null,
      isCompleted: '',
      isTaken: '',
      type: '',
      priorityId: [],
      statusId: [],
    })
  }
  subTicketResetForms(){
    this.subTicketForm.reset({
      name: '',
      parentId :'',
      project: '',
      details: '',
      userId: '',
      projectId: [],
      createdAt: '',
      updatedAt: '',
      startBy: '',
      createdBy: '',
      updatedBy: '',
      taskId: '',
      completeBy: null,
      isCompleted: '',
      isTaken: '',
      type: '',
      priorityId: [],
      statusId: [],
    })
    this.subTicketForm.patchValue({ startBy: null });
    this.subTicketForm.patchValue({ completeBy: null });
    this.priorityListSelected = [];
    this.statusListSelected =[];  
    this.projectUsersArrselect =[];  
  }
  resetForms() {
    this.TaskForm.reset({
      priorityId: [],
      projectId: [],
      statusId: [],
      name: null,
      project: null,
      details: null,
      userId: [],
      isCompleted: null,
      isTaken: null,
    })
    this.TaskForm.patchValue({ startBy: null });
    this.TaskForm.patchValue({ completeBy: null });

  }
  taskreset() {
    this.taskComment.reset({
      comment: '',
      attachmentFile: ''
    })
  }
  async getalllog() {
    var result = await this.DetailsService.allActivitiy(this.projectId);
    this.result1 = result;
    this.allActivityArr = this.result1;

  }
  async getAllTask(num) {
    this.taskArr = [];
    if (this.Filtercheck == true) {
      this.Filtercheck = false;
      this.currentPage = 1;
    }
    this.spinner.show();
    var url = '/api/issues/' + this.userid + '/' + this.currentPage + '/' + this.pagelimit
    await this.http.get(url).subscribe((res: Response) => {
      this.issuseresul = res;
      if (num == 0) {
        this.setPagination(this.issuseresul.totalCount);
      }

      this.taskArr = this.issuseresul.tickets;
    })
  }
  async roleDetails(id) {
    var result = await this.DetailsService.getProjectsUser(id);
    this.projectUsers = result;
    this.userArr = [];
    for (let i = 0; i < this.projectUsers.length; i++) {
      this.userArr.push(this.projectUsers[i].userId);
    }
    this.projectUsers.forEach(r => {
      this.http.get('/api/user/' + r.userId).subscribe((res: Response) => {
        this.result2 = res;
        this.proArr.push({
          "id": this.result2.details.id, "itemName": this.result2.details.firstName + " " + this.result2.details.lastName
        });
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
    })
  }
  toggleAddProjectTask(tid) {
    this.http.get('/api/task/' + tid).subscribe((res: Response) => {
      this.result = res;
      this.task = {};
      this.task.name = this.result.name;
      this.task.details = this.result.details;
      var sdate = this.result.startBy;
      if (sdate) {
        sdate = new Date(sdate);
        sdate = moment(sdate).format("YYYY-MM-DD")
      } else {
        sdate = '';
      }
      this.task.startBy = sdate;
      var cdate = this.result.completeBy;
      if (cdate) {
        cdate = new Date(cdate);
        cdate = moment(cdate).format("YYYY-MM-DD")
      } else {
        cdate = '';
      }
      this.task.completeBy = cdate;
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
    this.menuStateTaskEdit = this.menuStateTaskEdit === 'out' ? 'in' : 'out';
  }
  arr_diff(a1, a2) {
    var a = [], diff = [];

    for (var i = 0; i < a1.length; i++) {
      a[a1[i]] = true;
    }

    for (var i = 0; i < a2.length; i++) {
      if (a[a2[i]]) {
        delete a[a2[i]];
      } else {
        a[a2[i]] = true;
      }
    }

    for (var k in a) {
      diff.push(k);
    }

    return diff;
  }
  closeFunction() {
    this.menuStateTaskEdit = this.menuStateTaskEdit === 'out' ? 'in' : 'out';
  }
  async getDetails() {
    this.spinner.show();
    const id = +this.route.snapshot.paramMap.get('id');
    this.projectId = id;
    var result = await this.DetailsService.getDealStates();
    this.result3 = result;
    this.result3.forEach(r => {
      if (r.isFinal == "N") {
        this.dealStates[r.id] = r;
        this.dealstateArr.push({
          "id": r.id, "itemName": r.name, "orders": r.orders
        })
      }
    });
    result = await this.DetailsService.getDetailsID(id);
    this.details = result;    
    this.allFilter(0, 1);
    const TaskId = +this.route.snapshot.paramMap.get('iid');
    if (TaskId != 0) {
      this.taskEditMenu(TaskId, 0);

    }
  }
  async getUser() {
    var result = await this.DetailsService.getUsers();
    this.result4 = result;
    this.result4.forEach(r => {
      this.custArr.push({
        "id": r.details.id, "itemName": r.details.firstName + " " + r.details.lastName
      })
    })
  }

  toggleAddTask() {
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.editsh = true;
    this.tab01 = false;
    this.tab02 = false;
    this.tab03 = false;
    this.tab04 = false;
    this.tab05 = false;
    this.taball = false;
    this.cmtshw = false;
    if (this.userType == 1) {
      // alert("WDJJ"+this.userType);
      this.iscustomer = false

    } else {
      this.iscustomer = true
    }
    this.isdis = null;
    // this.custErr = null;
    this.proErr = null;
    this.nameErr = null;
    this.detailsErr = null;
    this.priorityErr = null;
    this.statusErr = null;
    this.fileTaskErr = null;
    this.taskCommentesAr = [];
    this.userArr = [];
    this.taskCommentArr = [];
    this.taskAttArr = [];
    this.dateErr = null;
    this.projerr = null;
    this.StartdateErr = null;
    this.isCreate = true;
    this.projArrselect = [];
    this.projectUsersArrselect = [];
    this.priorityListSelected = [];
    this.statusListSelected = [];

    this.resetForms();
    if (this.userType != 2) {
      this.TaskForm.enable();
    }



    this.menuStateTaskEdit = this.menuStateTaskEdit === 'out' ? 'in' : 'out';
    // this.settings1 = {
    //   disabled: false,
    // };
    this.settingsgroup1 = {
      disabled: false,
      // groupBy: "type",
      singleSelection: true,

    };
    // this.settings4 = {

    //   disabled: false,
    // };
    // this.settings02 = {

    //   disabled: false,
    // };
    this.navspec = false;
  }

  submitTask() {
    if (this.taskValidate(0)) {
      this.spinner.show();
      let data = this.TaskForm.value;
      if (this.userType == 2) {
        data.userId = null;
      } else {
        if (data.userId.length == 0) {
          data.userId = null;
        } else {
          data.userId = data.userId[0].id;
        }
      }
      if (data.startBy != null) {
        var sdateval = data.startBy.jsdate;
        data.startBy = moment(sdateval).format("YYYY-MM-DD")
      }
      if (data.completeBy != null) {
        var dateval = data.completeBy.jsdate;
        data.completeBy = moment(dateval).format("YYYY-MM-DD")
      }
      if (data.projectId.length > 0) {
        data.projectId = data.projectId[0].id;
      }
      if (data.statusId.length > 0) {
        data.statusId = data.statusId[0].id;
      }
      if (data.priorityId.length > 0) {
        data.priorityId = data.priorityId[0].id;
      }
      data.createdBy = parseInt(this.userid);
      data.type = "ISSUE";
      data.parentId = null;
      this.http.post('api/task/', data).subscribe((res: Response) => {
        this.result5 = res;
        if (this.result5.success == true) {
          this.toastr.clear();
          this.toastr.success(this.result5.message, 'Success', {
            timeOut: 6000,
            closeButton: true
          });
          this.toggleAddTask();
          //  this.getAllTask(0);
          this.allFilter(0, 1);
        } else {
          this.toastr.clear();
          this.toastr.error(this.result5.message, 'Error', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
      this.taskArr = [];
      this.taskCommentArr = [];
    }

  }
  async taskMenu(tid) {
    this.proErr = null;
    this.nameErr = null;
    this.detailsErr = null;
    this.priorityErr = null;
    this.statusErr = null;
    this.isCreate1 = null;
    this.iscurrdis = null;
    this.isCreate = false;
    this.tid = tid;
    this.http.get('/api/task/' + tid).subscribe((res: Response) => {
      this.result6 = res;
      this.taskcommentId = this.result6.id;
      this.taskCommentArr.push({
        "taskName": this.result6.name, "taskcommId": this.result6.id
      })
      this.getTaskComments(this.taskcommentId);
      this.getTickkCmtslogatt(this.taskcommentId)
      if (this.result6) {
        this.TaskForm.patchValue({ 'id': this.result6.id });
        this.TaskForm.patchValue({ 'name': this.result6.name });
        this.TaskForm.patchValue({ 'details': this.result6.details });
        this.TaskForm.patchValue({ 'isActive': this.result6.isActive });
        this.TaskForm.patchValue({ 'projectId': this.result6.projectId });
        this.TaskForm.patchValue({ 'createdAt': this.result6.createdAt });
        this.TaskForm.patchValue({ 'updatedAt': this.result6.updatedAt });
        this.TaskForm.patchValue({ 'createdBy': this.result6.createdBy });
        this.TaskForm.patchValue({ 'updatedBy': this.result6.updatedBy });
        this.TaskForm.patchValue({ 'parentId': this.result8.parentId });
        this.TaskForm.patchValue({ 'userId': this.result8.userId });
      }

      if (this.result6.userId == this.userid) {
        this.iscurrdis = true;
        if (this.result6.isTaken == "Y") {
          this.TaskForm.patchValue({ 'isTaken': true });
          this.istakenHide = true;
          this.iscompleteDis = true;
          this.isdis = true;
          if (this.result6.isCompleted == "Y") {
            this.TaskForm.patchValue({ 'isCompleted': true });
            this.resetTask = true;
            this.TaskForm.disable();
            this.taskComment.disable();

          } else {
            this.TaskForm.patchValue({ 'isCompleted': false });
            this.resetTask = false;
            this.TaskForm.enable();
            this.taskComment.enable();

          }
        } else {
          this.TaskForm.patchValue({ 'isTaken': false });
          this.istakenHide = false;
          this.iscompleteDis = false;
          this.isdis = false;
        }

      }
      var scdate = this.result6.startBy;
      if (scdate) {
        var ydate = moment(scdate).format("YYYY")
        var mdate = moment(scdate).format("M")
        var ddate = moment(scdate).format("D")
        this.TaskForm.patchValue({
          'startBy': {
            date: {
              year: ydate,
              month: mdate,
              day: ddate
            }
          }
        });
      } else {
        this.TaskForm.patchValue({ startBy: null });
      }
      this.onDateChanged(this.result6.startBy);

      var cdate = this.result6.completeBy;
      if (cdate) {
        var ydate = moment(cdate).format("YYYY")
        var mdate = moment(cdate).format("M")
        var ddate = moment(cdate).format("D")


        this.TaskForm.patchValue({
          'completeBy': {
            date: {
              year: ydate,
              month: mdate,
              day: ddate
            }
          }
        });
      } else {
        this.TaskForm.patchValue({ completeBy: null });
      }
      this.onDateChanged(this.result6.completeBy);
      if (this.result6.userId) {
        this.http.get('/api/user/' + this.result6.userId).subscribe((res1: Response) => {
          this.result7 = res1;
          this.projectUsersArrselect.push({
            "id": this.result7.details.id, "itemName": this.result7.details.firstName + " " + this.result7.details.lastName,
          })
        });
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });

    this.taskCommentArr = [];
    this.taskCommentesAr = [];
    this.taskAttArr = [];

    this.menuStateTaskEdit = this.menuStateTaskEdit === 'out' ? 'in' : 'out';

  }
  async taskEditMenu(tid, num) {
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.note = '';
    this.navspec = true;
    this.tab01 = false;
    this.tab02 = false;
    this.tab03 = false;
    this.tab04 = false;
    this.tab05 = false;
    this.editsh = false;
    this.taball = true;
    this.cmtshw = true;
    if (this.userType == 2) {
      this.iscustomer = true;
      this.is_edit = true;
      this.settingsgroup1 = {
        text: "Select project",
        disabled: true,
      };
    } else {
      this.iscustomer = false;
      this.is_edit = false;
      this.settings = {
        text: "Select User",
        disabled: false,
        singleSelection: true,
      };
    }
    if (num == 0) {
      this.admin = localStorage.getItem("loggedInUserAdmin");
      if (this.admin == 'Y') {

        this.settingsgroup1 = {
          text: "Select Project",
          disabled: true,
        };
      }
      else {

        this.settings = {
          text: "Select Project",
          disabled: false,
        };
      }
      this.taskreset();
      this.userArr = [];
      this.projectUsersArrselect = [];
      this.projArrselect = [];
      this.proErr = null;
      this.nameErr = null;
      this.detailsErr = null;
      this.priorityErr = null;
      this.statusErr = null;
      this.isCreate1 = null;
      this.iscurrdis = null;
      this.isCreate = false;
      this.tid = tid;
      this.taskCommentArr = [];
      this.http.get('/api/task/' + tid).subscribe((res: Response) => {
        this.result8 = res;
        this.parentProjectId = this.result8.projectId;
        if (this.userType != 2) {
          this.projectUserSelect1(this.result8.projectId)
        }
        if(this.result8.parentId != null){
          this.subticketBtn = false
        }else{
          this.subticketBtn = true
        }
        this.taskcommentId = this.result8.id;
        this.taskCommentArr.push({
          "taskName": this.result8.name, "taskcommId": this.result8.id, "statusId": this.result8.statusId,
          "priorityId": this.result8.priorityId, "startBy": this.result8.startBy, "completeBy": this.result8.completeBy
        })
        this.getTaskComments(this.taskcommentId);
        this.getTickkCmtslogatt(this.taskcommentId)
        if (this.result8) {
          this.TaskForm.patchValue({ 'id': this.result8.id });
          this.TaskForm.patchValue({ 'name': this.result8.name });
          this.TaskForm.patchValue({ 'details': this.result8.details });
          this.TaskForm.patchValue({ 'isActive': this.result8.isActive });
          this.TaskForm.patchValue({ 'createdAt': this.result8.createdAt });
          this.TaskForm.patchValue({ 'updatedAt': this.result8.updatedAt });
          this.TaskForm.patchValue({ 'createdBy': this.result8.createdBy });
          this.TaskForm.patchValue({ 'updatedBy': this.result8.updatedBy });
          this.TaskForm.patchValue({ 'parentId': this.result8.parentId });
          this.TaskForm.patchValue({ 'id': this.result8.id });
          this.TaskForm.patchValue({ 'userId': this.result8.userId });
          if (this.result8.statusId == 1) {
            this.statusListSelected = [];
            this.statusListSelected.push({ "id": 1, "itemName": "Open" })
          } else if (this.result8.statusId == 2) {
            this.statusListSelected = [];
            this.statusListSelected.push({ "id": 2, "itemName": "InProgress" })
          } else if (this.result8.statusId == 3) {
            this.statusListSelected = [];
            this.statusListSelected.push({ "id": 3, "itemName": "Completed" })
          } else if (this.result8.statusId == 4) {
            this.statusListSelected = [];
            this.statusListSelected.push({ "id": 4, "itemName": "Duplicate" })
          }
          if (this.result8.priorityId == 1) {
            this.priorityListSelected = [];
            this.priorityListSelected.push({ "id": 1, "itemName": "High" })
          } else if (this.result8.priorityId == 2) {
            this.priorityListSelected = [];
            this.priorityListSelected.push({ "id": 2, "itemName": "Medium" })
          } else if (this.result8.priorityId == 3) {
            this.priorityListSelected = [];
            this.priorityListSelected.push({ "id": 3, "itemName": "Low" })
          }


        }
        this.http.get('/api/project/' + this.result8.projectId).subscribe((res1: Response) => {
          this.result9 = res1;
          this.projArrselect.push({
            "id": this.result9.id, "itemName": this.result9.title,
            // type: "groupBy",

          })

        })
        if (this.result8.userId == this.userid) {
          this.iscurrdis = true;
          if (this.result8.isTaken == "Y") {
            this.TaskForm.patchValue({ 'isTaken': true });
            this.istakenHide = true;
            this.iscompleteDis = true;
            this.isdis = true;
            if (this.result8.isCompleted == "Y") {
              this.TaskForm.patchValue({ 'isCompleted': true });
              this.resetTask = true;
              this.TaskForm.disable();
              this.taskComment.disable();
            } else {
              this.TaskForm.patchValue({ 'isCompleted': false });
              this.resetTask = false;
              this.TaskForm.enable();
              this.taskComment.enable();
            }
          } else {
            this.TaskForm.patchValue({ 'isTaken': false });
            this.iscompleteDis = false;
            this.istakenHide = false;
            this.isdis = false;

          }
        }
        var scdate = this.result8.startBy;
        if (scdate) {
          var ydate = moment(scdate).format("YYYY")
          var mdate = moment(scdate).format("M")
          var ddate = moment(scdate).format("D")


          this.TaskForm.patchValue({
            'startBy': {
              date: {
                year: ydate,
                month: mdate,
                day: ddate
              }
            }
          });
        } else {
          this.TaskForm.patchValue({ startBy: null });
        }

        var cdate = this.result8.completeBy;
        if (cdate) {
          var ydate = moment(cdate).format("YYYY")
          var mdate = moment(cdate).format("M")
          var ddate = moment(cdate).format("D")


          this.TaskForm.patchValue({
            'completeBy': {
              date: {
                year: ydate,
                month: mdate,
                day: ddate
              }
            }
          });
        } else {
          this.TaskForm.patchValue({ completeBy: null });
        }
        if (this.result8.userId != null) {
          this.http.get('/api/user/' + this.result8.userId).subscribe((res1: Response) => {
            this.result10 = res1;
            this.projectUsersArrselect.push({
              "id": this.result10.details.id, "itemName": this.result10.details.firstName + " " + this.result10.details.lastName,
            })
          });
        }


      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
    }

    this.taskCommentArr = [];
    this.taskCommentesAr = [];
    this.taskAttArr = [];
    this.menuStateTaskEdit = this.menuStateTaskEdit === 'out' ? 'in' : 'out';

  }
  Enable() {
    this.isdis = false;
    this.TaskForm.enable();
    this.taskComment.enable();
  }
  updateTask() {
    if (this.taskValidate(2)) {
      this.spinner.show();
      this.body = {};
      this.body["id"] = this.tid;
      this.body["name"] = this.TaskForm.value["name"];
      this.body["details"] = this.TaskForm.value["details"];
      this.body["statusId"] = this.TaskForm.value["statusId"][0].id;
      this.body["priorityId"] = this.TaskForm.value["priorityId"][0].id;
      this.body["parentId"] = this.TaskForm.value["parentId"];
      if (this.userType == 2) {
        if (this.TaskForm.value.userId != null) {          
          this.body["userId"] = this.TaskForm.value["userId"];
        } else {
          this.body["userId"] = null;
        }
      } else {
        if (this.TaskForm.value.userId.length > 0) {
          this.body["userId"] = this.TaskForm.value["userId"][0].id;
        } else {
          this.body["userId"] = null;
        }
      }
      this.body["projectId"] = this.TaskForm.value["projectId"][0].id;
      this.body["createdAt"] = this.TaskForm.value["createdAt"];
      this.body["updatedAt"] = new Date();
      this.body["updatedBy"] = parseInt(this.userid);
      this.body["createdBy"] = this.TaskForm.value["createdBy"];
      this.body["type"] = "ISSUE";
      var startBy = null;
      var completeBy = null;
      if (!this.TaskForm.value.startBy) {
        this.body["startBy"] = null;
      } else {
        var date1 = ("0" + (this.TaskForm.value["startBy"].date.day)).slice(-2);
        var month1 = ("0" + (this.TaskForm.value["startBy"].date.month)).slice(-2);
        startBy = this.TaskForm.value["startBy"].date.year + "-" + month1 + "-" + date1
        this.body["startBy"] = startBy;
      }
      if (!this.TaskForm.value.completeBy) {
        this.body["completeBy"] = null;
      } else {
        var date2 = ("0" + (this.TaskForm.value["completeBy"].date.day)).slice(-2);
        var month2 = ("0" + (this.TaskForm.value["completeBy"].date.month)).slice(-2);
        completeBy = this.TaskForm.value["completeBy"].date.year + "-" + month2 + "-" + date2
        this.body["completeBy"] = completeBy;
      }
      this.body["isActive"] = "Y";
      if (this.TaskForm.value["isCompleted"] == true) {
        this.body["isCompleted"] = "Y";
      } else {
        this.body["isCompleted"] = "N";
      }
      if (this.TaskForm.value["isTaken"] == true) {
        this.body["isTaken"] = "Y";

      } else {
        this.body["isTaken"] = "N";
      }
      this.http.put('/api/task', this.body).subscribe((res1: Response) => {
        this.spinner.show();
        this.resetForms();
        this.result11 = res1;
        if (this.result11.success == true) {
          this.toastr.clear();
          this.toastr.success(this.result11.message, 'Success', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
          this.child_show = false;
          this.childTicketOpen(this.result11.response.id,1);
          this.toggleAddTask();
          this.allFilter(0, 1);
          // this.getAllTask(0);
        } else {
          this.toastr.clear();
          this.toastr.error(this.result11.message, 'Error', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
      this.taskArr = [];
      this.taskCommentArr = [];
      this.taskCommentesAr = [];
    }

  }
  submitTaskComment(tid) {
    if (this.taskComment.value.comment) {
      let data = this.taskComment.value;
      data.taskId = tid;
      data.commentBy = this.userid;
      data.createdBy = this.userid;
      this.http.post('api/taskLogs/', data).subscribe((res1: Response) => {
        this.result12 = res1;
        if (this.result12.success == true) {
          this.toastr.clear();
          this.taskreset();
          this.getTaskComments(this.taskcommentId);
          this.getTickkCmtslogatt(this.taskcommentId)
        } else {
          this.toastr.clear();
          this.toastr.error(this.result12.message, 'Error', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
      this.taskCommentesAr = [];
      this.taskAttArr = [];
    } else {
      return false;
    }
  }
  getTaskComments(id) {
    var taskCid = id;
    this.http.get('/api/taskComments/' + taskCid).subscribe((res1: Response) => {
      this.result13 = res1;
      this.result13.forEach(r => {
        this.taskCommentesAr.push({
          "id": r.id, "comment": r.comment, "commentBy": r.commentBy, "createdAt": r.createdAt, "commentType": r.commentType,

        })
        if (r.commentType == "file") {
          this.taskAttArr.push({
            "id": r.id, "comment": r.comment, "commentBy": r.commentBy, "createdAt": r.createdAt, "attId": r.attachmentId
          })
        }
      })
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  tickkcmtlogAr = [];
  getTickkCmtslogatt(id) {
    var taskCidc = id;
    this.tickkcmtlogAr = [];
    this.http.get('/api/taskComments/' + taskCidc).subscribe((res1: Response) => {
      this.result13 = res1;
      for (let i = 0; i < this.result13.length; i++) {
        this.tickkcmtlogAr.push({
          "id": this.result13[i].id, "comment": this.result13[i].comment, "commentBy": this.result13[i].commentBy, "createdAt": this.result13[i].createdAt, "commentType": this.result13[i].commentType, "attachmentId": this.result13[i].attachmentId,
          "attachmentType": this.result13[i].attachmentType
        })
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }


  getTaskFile(files: FileList) {
    this.fileToUpload = files.item(0);
    this.uploadFilesArr.push({
      "fileNames": this.fileToUpload.name,
    })
  }
  clearTaskFile() {
    this.fileTaskErr = null;
    var valid = true;
    let data = this.taskComment.value;
    if (!data.attachmentFile) {
      this.fileTaskErr = "Upload File is Required";
      valid = false;
    }
    return valid;
  }
  taskFilePost(tid) {
    if (this.clearTaskFile()) {
      this.spinner.show();
      let data = this.taskComment.value;
      const id = +this.route.snapshot.paramMap.get('id');
      this.projectId = id;
      var formData = new FormData();
      formData.append("file", this.fileToUpload, this.fileToUpload.name);
      this.http.post('/api/upload/approval/' + this.projectId + '/' + this.userid, formData).subscribe((res1: Response) => {
        this.result14 = res1;
        if (this.result14.success == true) {
          data.taskId = tid;
          data.commentBy = this.userid;
          data.createdBy = this.userid;
          data.attachmentId = this.result14.response.id;
          data.comment = this.result14.response.uploadName;
          this.http.post('/api/taskLogs/attachment', data).subscribe((res1: Response) => {
            this.toastr.clear();
            this.result14 = res1;
            if (this.result14.success == true) {
              this.taskreset();
              this.toastr.clear();
              this.toastr.success(this.result14.message, 'Success', {
                timeOut: 6000,
                closeButton: true
              });
              this.getTaskComments(tid);
              this.getTickkCmtslogatt(this.taskcommentId)
            } else {
              this.toastr.clear();
              this.toastr.error(this.result14.message, 'Error', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
            }
            this.spinner.hide();
          }, error => {
            this.toastr.clear();
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.errorService.handleError(error);
          });
          this.taskAttArr = [];
          this.taskCommentesAr = [];
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
      return false;
    }
  }

  downloadTaskFile(fid) {
    const url = '/api/download/' + fid;
    window.open(url);
  }
  taskValidate(num) {
    var valid = true;
    var data = this.TaskForm.value;
    if (num == 0) {
      this.nameErr = null;
      this.proErr = null;
      this.StartdateErr = null;
      this.dateErr = null;
      this.detailsErr = null;
      this.priorityErr = null;
      this.statusErr = null;
      this.isCreate1 = true;
      if (!data.name) {
        this.nameErr = "Name is required";
        valid = false;
      }
      if (data.projectId) {
        if (data.projectId.length == 0) {
          this.proErr = "Project is required";
          valid = false;
        }
      }
      if (data.startBy && data.completeBy) {
        var date1 = ("0" + (data.startBy.date.day)).slice(-2);
        var month1 = ("0" + (data.startBy.date.month)).slice(-2);
        var date2 = ("0" + (data.completeBy.date.day)).slice(-2);
        var month2 = ("0" + (data.completeBy.date.month)).slice(-2);
        var startBy = data.startBy.date.year + "-" + month1 + "-" + date1
        var completeBy = data.completeBy.date.year + "-" + month2 + "-" + date2
        if (startBy > completeBy) {
          if (startBy == completeBy) {
            this.dateErr = null;
          } else {
            this.dateErr = " End Date is must greater than Start Date";
            valid = false;
          }
        }
      }
      if (!data.details) {
        this.detailsErr = "Details is required";
        valid = false;
      }
      if (data.statusId) {
        if (data.statusId.length == 0) {
          this.statusErr = "status is required.";
          valid = false;
        }else if(data.statusId[0].id == 3 && this.isCreate == false){
          this.http.get('/api/issues/canComplete/parent/' + this.tid).subscribe((res: Response) => {
            this.completTicketResult = res;
            if(this.completTicketResult.canComplete == false){               
              this.statusErr = "Close all sub-tickets first!";
              valid = false;
            }else{ 
              this.completTicketResult.canComplete = true;
            }
          });
        }else{
          this.completTicketResult = null;
        }       
      } 
      if (data.priorityId) {
        if (data.priorityId.length == 0) {
          this.priorityErr = "priority is required.";
          valid = false;
        }
      }
      return valid;
    } else if (num == 2) {
      this.nameErr = null;
      this.proErr = null;
      this.StartdateErr = null;
      this.dateErr = null;
      this.detailsErr = null;
      this.priorityErr = null;
      this.statusErr = null;
      this.isCreate1 = true;
      if (!data.name) {
        this.nameErr = "Name is required";
        valid = false;
      }
      if (data.projectId) {
        if (data.projectId.length == 0) {
          this.proErr = "project is required";
          valid = false;
        }
      }
      if (data.statusId) {
        if (data.statusId.length == 0) {
          this.statusErr = "status is required.";
          valid = false;
        }
      }
      if (data.priorityId) {
        if (data.priorityId.length == 0) {
          this.priorityErr = "priority is required.";
          valid = false;
        }
      }
      if (data.startBy && data.completeBy) {
        var date1 = ("0" + (data.startBy.date.day)).slice(-2);
        var month1 = ("0" + (data.startBy.date.month)).slice(-2);
        var date2 = ("0" + (data.completeBy.date.day)).slice(-2);
        var month2 = ("0" + (data.completeBy.date.month)).slice(-2);
        var startBy = data.startBy.date.year + "-" + month1 + "-" + date1
        var completeBy = data.completeBy.date.year + "-" + month2 + "-" + date2
        if (startBy > completeBy) {
          if (startBy == completeBy) {
            this.dateErr = null;
          } else {
            this.dateErr = " End Date is must greater than Start Date";
            valid = false;
          }
        }
      }
      if (!data.details) {
        this.detailsErr = "Details is required";
        valid = false;
      }
      if(this.completTicketResult != null){
        if(this.completTicketResult.canComplete == false){
          this.statusErr = "Close all sub-tickets first!";
          valid = false;
        }
       }

      return valid;
    } else {
      this.isCreate1 = true;
      this.proErr = null;
      this.StartdateErr = null;
      this.dateErr = null;
      this.detailsErr = null;
      this.priorityErr = null;
      this.statusErr = null;
      if (data.projectId) {
        if (data.projectId.length == 0) {
          this.proErr = "project is required";
          valid = false;
        }
      }
      if (data.statusId) {
        if (data.statusId.length == 0) {
          this.statusErr = "status is required.";
          valid = false;
        }
      }
      if (data.priorityId) {
        if (data.priorityId.length == 0) {
          this.priorityErr = "priority is required.";
          valid = false;
        }
      }

      return valid;
    }
  }
  getallusercustomeradminProject() {
    this.projArr = [];
    this.http.get('/api/issues/projects/' + this.userid).subscribe((res: Response) => {
      this.result15 = res;
      for (let i = 0; i < this.result15.deal.length; i++) {
        this.projArr.push({
          "id": this.result15.deal[i].id, "itemName": this.result15.deal[i].title, "type": "Deal"
        })
      }
      for (let i = 0; i < this.result15.project.length; i++) {
        this.projArr.push({
          "id": this.result15.project[i].id, "itemName": this.result15.project[i].title, "type": "project"
        })
      }

    });

  }



  getPermissionDetails() {
    this.http.get('/api/user/' + this.userid).subscribe((res: Response) => {
      this.result16 = res;
      var permision = this.result16.role.permision;
      if (permision.length > 0) {
        var dealPermission = this.permissionService.getDealPermission(permision);
        var approvalPermission = this.permissionService.getApprovalPermission(permision);
        var projectPermission = this.permissionService.getProjectPermission(permision);
        var customerPermission = this.permissionService.getCustomerPermission(permision);

        if (dealPermission == this.permissionService.DEAL_ADMIN || this.admin == "Y") {
          this.istotal = true;
          this.iscustomer = false;
          this.isAdminDis = true;
          this.isDeal = true;
        } else if (dealPermission == this.permissionService.DEAL_OBSERVER) {
          this.istotal = true;
          this.iscustomer = false;
          this.isDeal = true;
          this.isProject = false;
          this.isApproval = false;
        }
        if (approvalPermission == this.permissionService.APPROVAL_ADMIN || this.admin == "Y") {
          this.istotal = true;
          this.iscustomer = false;
          this.isAdminDis = true;
          this.isApproval = true;
        } else if (approvalPermission == this.permissionService.APPROVAL_OBSERVER) {
          this.istotal = true;
          this.iscustomer = false;
          this.isApproval = true;
          this.isDeal = false;
          this.isProject = false;
        }
        if (projectPermission == this.permissionService.PROJECT_ADMIN || this.admin == "Y") {
          this.istotal = true;
          this.iscustomer = false;
          this.isAdminDis = true;
          this.isProject = true;
        } else if (projectPermission == this.permissionService.PROJECT_OBSERVER) {
          this.istotal = true;
          this.iscustomer = false;
          this.isProject = true;
          this.isApproval = false;


          this.isDeal = false;
        }

      }
    });
  }

  projectUserSelect(item: any) {
    var id = item.id;
    this.userArr = [];
    this.http.get('/api/issues/assignedTo/' + this.userid + '/' + id).subscribe((res: Response) => {
      this.result17 = res;
      for (let i = 0; i < this.result17.length; i++) {
        // this.http.get('/api/user/' + result[i]).subscribe((res: Response) => {
        //   var result1 = res.json();
        this.userArr.push({
          "id": this.result17[i].id, "itemName": this.result17[i].name
        })
        // });
      }
    }
    )
  }
  projectUserSelect1(id) {
    this.http.get('/api/issues/assignedTo/' + this.userid + '/' + id).subscribe((res: Response) => {
      this.result18 = res;
      for (let i = 0; i < this.result18.length; i++) {
        // this.http.get('/api/user/' + result[i]).subscribe((res: Response) => {
        //   var result1 = res.json();
        this.userArr.push({
          "id": this.result18[i].id, "itemName": this.result18[i].name
        })
        // });
      }
    }
    )
  }
  getIssueByUser(item: any, num) {
    this.spinner.show();
    this.taskArr = [];
    if (num == 0) {
      this.currentPage = 1;
      this.http.get('api/issues/' + this.userid + '/' + item.id + '/' + this.currentPage + '/' + this.pagelimit).subscribe((res: Response) => {
        this.result19 = res;
        this.Filtercheck = true;
        if (num == 0) {
          this.setPagination(this.result19.totalCount);
        }
        this.taskArr = this.result19.tickets;
      })
    } else if (num == 2) {
      this.http.get('api/issues/' + this.userid + '/' + item[0].id + '/' + this.currentPage + '/' + this.pagelimit).subscribe((res: Response) => {
        this.result19 = res;
        this.Filtercheck = true;
        if (num == 2) {
          this.setPagination(this.result19.totalCount);
        }
        this.taskArr = this.result19.tickets;
      })
    } else if (num == 1) {
      this.projArrfilterselect = [];
      this.opened = !this.opened
      this.getAllTask(0)
    }
    this.spinner.hide();
  }
  setPagination(pagedItems) {
    var val = pagedItems / this.pagelimit;
    var rem = pagedItems % this.pagelimit;
    if (rem != 0) {
      if (val < 1) {
        this.pagedItemspointer = 1;
      } else {
        this.pagedItemspointer = Math.floor(val) + 1;
      }
    } else {
      this.pagedItemspointer = val;
    }
    if (this.pagedItemspointer < this.maxSize) {
      this.range(this.currentPage, this.pagedItemspointer, this.pagedItemspointer);
    } else {
      this.range(this.currentPage, this.maxSize, this.pagedItemspointer);
    }
  }
  prevPage() {
    var perPage = this.currentPage - 1;
    if (this.maxSize >= perPage) {
      var end = this.maxSize;
      var start = 1;
    } else {
      var end = perPage;
      var start = (end + 1) - this.maxSize;
    }
    if (this.reat.includes(perPage)) {
      this.currentPage = perPage;
    } else {
      this.currentPage = perPage;
      this.range(start, end, this.pagedItemspointer);
    }
    if (this.Filtercheck != true) {
      this.allFilter(0, 1);
    } else {
      this.allFilter(1, 0);
    }
  }
  setPage(n) {
    this.currentPage = n;
    if (this.Filtercheck != true) {
      this.allFilter(0, 1);
    } else {
      this.allFilter(1, 0);
    }
  }
  nextPage() {
    var nextPage = this.currentPage + 1;
    if (this.maxSize < nextPage) {
      var text = this.maxSize + nextPage - 1;
      if (text < this.pagedItemspointer) {
        var end = text;
        var start = nextPage;
      } else {
        end = this.pagedItemspointer;
        var start = nextPage;
      }
    } else {
      var end = this.maxSize;
      var start = 1;
    }
    if (this.reat.includes(nextPage)) {
      this.currentPage = nextPage;
    } else {
      this.currentPage = nextPage;
      this.range(start, end, this.pagedItemspointer)
    }
    if (this.Filtercheck != true) {
      this.allFilter(0, 1);
    } else {
      this.allFilter(1, 0);
    }
  }
  range(start, end, pages) {
    this.reat = [];
    if (pages == 1) {
      this.reat.push(1);
    } else {
      for (var i = start; i <= end; i++) {
        this.reat.push(i);
      }
    }
  };
  downloadFile(fid) {
    const url = '/api/download/' + fid;
    window.open(url, "_self");
  }


  // edittab() {
  //   this.editsh = true
  //   this.tab01 = false
  //   this.tab02 = false
  //   this.tab03 = false
  //   this.tab04 = false
  //   this.taball = false
  //   this.cmtshw = false;
  // }
  // tabs1() {
  //   this.tab01 = true
  //   this.tab02 = false
  //   this.tab03 = false
  //   this.tab04 = false
  //   this.editsh = false
  //   this.taball = false
  //   this.tab05 = false
  // }
  // tabs2() {
  //   this.tab02 = true
  //   this.tab01 = false
  //   this.tab03 = false
  //   this.tab04 = false
  //   this.editsh = false
  //   this.taball = false
  //   this.tab05 = false
  // }
  // tabs3() {
  //   this.tab03 = true
  //   this.tab01 = false
  //   this.tab02 = false
  //   this.tab04 = false
  //   this.editsh = false
  //   this.taball = false
  //   this.tab05 = false
  // }

  // subZTicketAdd() {
  //   this.tab03 = false
  //   this.tab01 = false
  //   this.tab02 = false
  //   this.tab04 = false
  //   this.editsh = false
  //   this.taball = false
  //   this.tab05 = true
  // }


  tabs(tabName) {
    this.editsh = false
    this.tab01 = false
    this.tab02 = false
    this.tab03 = false
    this.tab04 = false
    this.tab05 = false
    this.taball = false
    this.cmtshw = false;
    switch (tabName) {
      case 'Edit':
        this.editsh = true
        break;
      case 'addSubTicket':       
        this.subTicketResetForms();
        this.tab05 = true
        break;
      case 'Comment':
        this.tab01 = true
        this.cmtshw = true;
        break;
      case 'Attach':
        this.tab03 = true
        break;
    }
  }

  onSwitch(check) {
    switch (check) {
      case 1:
        return 'tab01';
      case 2:
        return 'tab02';
      case 3:
        return 'tab03';
    }
  }

  keyDownFunction(event, id) {
    if (event.keyCode == 13) {
      this.submitTaskComment(id);
      // alert('you just clicked enter');
    }
  }
  getFilterProject(item, num) {
    if (num == 0) {
      if (item.id != null) {
        this.projectFilterId = [item.id];
      } else {
        this.projectFilterId = [];
      }
    } else {
      this.projectFilterId = [];
    }
    this.allFilter(1, 1);

  }
  getFilterUser(item: any, num) {
    if (num == 0) {
      if (item.id != null) {
        this.userFilterId = [item.id];
      } else {
        this.userFilterId = [];
      }
    } else {
      this.userFilterId = [];
    }
    this.allFilter(1, 1);
  }
  getFilterPriority(item, num) {
    if (num == 0) {
      if (item.id != null) {
        this.prorityFilterId = item.id;
      } else {
        this.prorityFilterId = null;
      }
    } else {
      this.prorityFilterId = null;
    }
    this.allFilter(1, 1);


  }
  getFilterStatus(item) {
    if (item.length > 0) {
      var statusId = [];
      for (let i = 0; i < item.length; i++) {
        statusId.push(item[i].id);
      }
      this.statusFilterId = statusId;
    } else {
      this.statusFilterId = [];
    }
    this.allFilter(1, 1);
  }

  allFilter(num, page) {
    this.spinner.show();
    this.taskArr = [];
    if (num == 1) {
      this.currentPage = 1
    }
    var body = this.FilterForm.value;
    body["pageNo"] = this.currentPage;
    if (this.projectFilterId != null) {
      body["projectIdIn"] = this.projectFilterId;
    } else {
      body["projectIdIn"] = [];
    } if (this.userFilterId != null) {
      body["userIdIn"] = this.userFilterId;
    } else {
      body["userIdIn"] = [];
    } if (this.prorityFilterId != null) {
      body["priorityId"] = this.prorityFilterId;
    } else {
      body["priorityId"] = null;
    }
    if (num == 0) {
      this.statusListSelected1 = [];
      this.statusListSelected1 = [{ "id": 1, "itemName": "Open" },
      { "id": 2, "itemName": "InProgress" }]
      body["statusIdIn"] = [1, 2];
      this.statusFilterId = [1, 2];
    } else if (num == 1) {
      if (this.statusFilterId.length > 0) {
        body["statusIdIn"] = this.statusFilterId;
      } else {
        body["statusIdIn"] = [];
      }
    }
    body["pageLimit"] = this.pagelimit;
    body["userId"] = parseInt(this.userid);
    this.http.post('api/issuess/', body).subscribe((res: Response) => {
      this.projectResult = res;
      this.taskArr = this.projectResult.tickets;
      this.taskTotalElements = this.projectResult.totalCount;
      if (this.projectResult.totalCount > 0) {
        if (page == 1) {
          this.setPagination(this.projectResult.totalCount);
        }
        this.filtersHideAndShow = false;
      } else {
        this.filtersHideAndShow = true;
        this.subTicketSpinner = true
      }
      this.spinner.hide();
    })
  }

  // getAttachFile(files: any) {
  //   this.filesToUpload = <Array<File>>files.target.files;
  //   this.taskFilePosts();
  // }


  // taskFilePosts() {
  //   var files: Array<File> = this.filesToUpload;
  //   for (let i = 0; i < files.length; i++) {
  //     let data = this.taskComment.value;
  //     var formData = new FormData();
  //     formData.append("file", files[i], files[i].name);
  //     this.http.post('/api/upload/approval/' + this.result8.projectId + '/' + this.userid, formData).subscribe((res1: Response) => {
  //       this.result14 = res1;
  //       if (this.result14.success == true) {
  //         data.taskId = this.result8.id;
  //         data.commentBy = this.userid;
  //         data.createdBy = this.userid;
  //         data.attachmentId = this.result14.response.id;
  //         data.comment = this.result14.response.uploadName;
  //         data.attachmentType = this.result14.response.fileType;
  //         this.http.post('/api/taskLogs/attachment', data).subscribe((res1: Response) => {
  //           this.toastr.clear();
  //           this.result14 = res1;
  //           if (this.result14.success == true) {
  //             this.taskreset();
  //             this.toastr.clear();
  //             this.toastr.success(this.result14.message, 'Success', {
  //               timeOut: 6000,
  //               closeButton: true
  //             });
  //             this.getTaskComments(this.result8.id);
  //             this.getTickkCmtslogatt(this.result8.id)
  //             this.docname.nativeElement.classList.remove("valid");
  //             this.docname.nativeElement.value="";
  //           } else {
  //             this.toastr.clear();
  //             this.toastr.error(this.result14.message, 'Error', {
  //               timeOut: 6000,
  //               closeButton: true
  //             });
  //           }
  //         }, error => {
  //           this.toastr.clear();
  //           this.toastr.error(error, 'Report Status', {
  //             timeOut: 6000,
  //             closeButton: true
  //           });
  //           this.errorService.handleError(error);
  //         });
  //         this.taskAttArr = [];
  //         this.taskCommentesAr = [];
  //       }
  //     }, error => {
  //       this.toastr.clear();
  //       this.toastr.error(error, 'Report Status', {
  //         timeOut: 6000,
  //         closeButton: true
  //       });
  //       this.errorService.handleError(error);
  //     });
  //     return false;
  //   }
  // }
  getAttachFile(e) {
    this.myFilesTicket = [];
    this.myFilesTicketArr = [];
    for (var i = 0; i < e.target.files.length; i++) {
      this.myFilesTicket.push(e.target.files[i]);
      this.myFilesTicketArr.push({
        "name": e.target.files[i].name
      })
    }
    this.taskFilePosts();
  }
  taskFilePosts() {
    this.spinner.show();
    let data = this.taskComment.value;
    const frmData = new FormData();
    for (var i = 0; i < this.myFilesTicket.length; i++) {
      frmData.append("file", this.myFilesTicket[i]);
    }
    frmData.append("description", "");
    this.http.post('/api/upload/approval/' + this.result8.projectId + '/' + this.userid, frmData).subscribe((res1: Response) => {
      this.result14 = res1;
      if (this.result14.successArr.length > 0) {
        var lastarr = this.result14.successArr.length - 1;
        for (var i = 0; i < this.result14.successArr.length; i++) {
          data.taskId = this.result8.id;
          data.commentBy = this.userid;
          data.createdBy = this.userid;
          data.attachmentId = this.result14.successArr[i].response.id;
          data.comment = this.result14.successArr[i].response.name;
          data.attachmentType = this.result14.successArr[i].response.fileType;
          this.http.post('/api/taskLogs/attachment', data).subscribe((res1: Response) => {
            this.toastr.clear();
            this.result14 = res1;
            if (this.result14.success == true) {
              this.taskreset();
              this.toastr.clear();
              this.toastr.success(this.result14.message, 'Success', {
                timeOut: 6000,
                closeButton: true
              });
            } else {
              this.toastr.clear();
              this.toastr.error(this.result14.successArr.message, 'Error', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
            }
            this.spinner.hide();
          }, error => {
            this.toastr.clear();
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.errorService.handleError(error);
          });
          this.taskAttArr = [];
          this.taskCommentesAr = [];
          if (lastarr == i) {
            this.myFilesTicketArr = [];
            setTimeout(() => {
              this.getTickkCmtslogatt(this.result8.id)
              this.docname.nativeElement.classList.remove("valid");
              this.docname.nativeElement.value = "";
            }, 2000);
            this.spinner.hide();
          }
        }
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
    return false;

  }


  deleteFile(fid) {
    this.confirmService.confirm('Please confirm..', 'Do you want to delete the File ?')
      .then((confirmed) => {
        if (confirmed) {
          this.http.delete('/api/files/' + fid).subscribe((response) => {
            this.res = response;
            if (this.res.success == true) {
              this.toastr.clear();
              this.toastr.success(this.res.message, 'Success', {
                timeOut: 6000,
                closeButton: true
              });
              this.getTaskComments(this.result8.id);
              this.getTickkCmtslogatt(this.result8.id)
            } else {
              this.toastr.clear();
              this.toastr.error(this.res.message, 'Error', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
            }
          }, error => {
            this.toastr.clear();
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.errorService.handleError(error);
          });
          this.uploadFileArr = [];

        }

      })
      .catch(() => {
        return false;
      });
  }
  downloadFiles(fid) {
    const url = '/unsecure/download/' + fid;
    window.open(url, "_self");
  }
  //   myfunction(id){

  //     const url = '/unsecure/gallery/' + id;
  //     window.open(url);
  // }
  openImage(id) {
    var url = "/unsecure/gallery" + "/" + id
    window.open(url);
  }

  childTicketOpen(id, num) {
    this.parentId = id;
    if (num == 0) {
      if (id != null) {
        if (this.childResultShow == true) {
          this.childResultShow = false;
        } else if (this.childResultShow == false) {
          this.childResultShow == false
        } {
           this.subTicketSpinner = false;
          this.childResultShow = true;
        }
        this.childTicketArr = [];
        this.http.get('/api/issues/childTickets/parent/' + id +'/user' +'/'+ this.userid).subscribe((res: Response) => {
          this.result = res;
          this.childTicketArr = this.result;
          this.child_show = true;
          this.subTicketSpinner = true;
        });
      } else {
        this.child_show = false;
        if (this.childResultShow == true) {
          this.childResultShow = false;
        } else if (this.childResultShow == false) {
          this.childResultShow == false
        } {
          this.childResultShow = false;
        }
        this.subTicketSpinner = false;
      }
    } else {
      this.childTicketArr = [];
      this.http.get('/api/issues/childTickets/parent/' + id  +'/user/' +'/'+  this.userid).subscribe((res: Response) => {
        this.result = res;
        this.childTicketArr = this.result; 
        this.parentId = 0;
        this.childResultShow = false;
        this.subTicketSpinner = true;
      });
    }

  }
  subTicketValidate(num) {
    var valid = true;
    var data = this.subTicketForm.value;
    if (num == 0) {
      this.nameErr = null;
      this.proErr = null;
      this.StartdateErr = null;
      this.dateErr = null;
      this.detailsErr = null;
      this.priorityErr = null;
      this.statusErr = null;
      this.isCreate1 = true;
      if (!data.name) {
        this.nameErr = "Name is required";
        valid = false;
      }
      if (data.projectId) {
        if (data.projectId.length == 0) {
          this.proErr = "Project is required";
          valid = false;
        }
      }
      if (data.startBy && data.completeBy) {
        var date1 = ("0" + (data.startBy.date.day)).slice(-2);
        var month1 = ("0" + (data.startBy.date.month)).slice(-2);
        var date2 = ("0" + (data.completeBy.date.day)).slice(-2);
        var month2 = ("0" + (data.completeBy.date.month)).slice(-2);
        var startBy = data.startBy.date.year + "-" + month1 + "-" + date1
        var completeBy = data.completeBy.date.year + "-" + month2 + "-" + date2
        if (startBy > completeBy) {
          if (startBy == completeBy) {
            this.dateErr = null;
          } else {
            this.dateErr = " End Date is must greater than Start Date";
            valid = false;
          }
        }
      }
      if (!data.details) {
        this.detailsErr = "Details is required";
        valid = false;
      }
      if (data.statusId) {
        if (data.statusId.length == 0) {
          this.statusErr = "status is required.";
          valid = false;
        }
      }
      if (data.priorityId) {
        if (data.priorityId.length == 0) {
          this.priorityErr = "priority is required.";
          valid = false;
        }
      }
      return valid;
    } else if (num == 2) {
      this.nameErr = null;
      this.proErr = null;
      this.StartdateErr = null;
      this.dateErr = null;
      this.detailsErr = null;
      this.priorityErr = null;
      this.statusErr = null;
      this.isCreate1 = true;
      if (!data.name) {
        this.nameErr = "Name is required";
        valid = false;
      }
      if (data.projectId) {
        if (data.projectId.length == 0) {
          this.proErr = "project is required";
          valid = false;
        }
      }
      if (data.statusId) {
        if (data.statusId.length == 0) {
          this.statusErr = "status is required.";
          valid = false;
        }
      }
      if (data.priorityId) {
        if (data.priorityId.length == 0) {
          this.priorityErr = "priority is required.";
          valid = false;
        }
      }
      if (data.startBy && data.completeBy) {
        var date1 = ("0" + (data.startBy.date.day)).slice(-2);
        var month1 = ("0" + (data.startBy.date.month)).slice(-2);
        var date2 = ("0" + (data.completeBy.date.day)).slice(-2);
        var month2 = ("0" + (data.completeBy.date.month)).slice(-2);
        var startBy = data.startBy.date.year + "-" + month1 + "-" + date1
        var completeBy = data.completeBy.date.year + "-" + month2 + "-" + date2
        if (startBy > completeBy) {
          if (startBy == completeBy) {
            this.dateErr = null;
          } else {
            this.dateErr = " End Date is must greater than Start Date";
            valid = false;
          }
        }
      }
      if (!data.details) {
        this.detailsErr = "Details is required";
        valid = false;
      }

      return valid;
    } else {
      this.isCreate1 = true;
      this.proErr = null;
      this.StartdateErr = null;
      this.dateErr = null;
      this.detailsErr = null;
      this.priorityErr = null;
      this.statusErr = null;
      if (data.projectId) {
        if (data.projectId.length == 0) {
          this.proErr = "project is required";
          valid = false;
        }
      }
      if (data.statusId) {
        if (data.statusId.length == 0) {
          this.statusErr = "status is required.";
          valid = false;
        }
      }
      if (data.priorityId) {
        if (data.priorityId.length == 0) {
          this.priorityErr = "priority is required.";
          valid = false;
        }
      }

      return valid;
    }
  }

  submitSubTicket(){
    if(this.subTicketValidate(0)){
      this.spinner.show();
      let data = this.subTicketForm.value;
      if (this.userType == 2) {
        data.userId = null;
      } else {
        if (data.userId.length == 0) {
          data.userId = null;
        } else {
          data.userId = data.userId[0].id;
        }
      }
      if (data.statusId.length > 0) {
        data.statusId = data.statusId[0].id;
      }
      if (data.priorityId.length > 0) {
        data.priorityId = data.priorityId[0].id;
      }

      if (data.startBy != null) {
        var sdateval = data.startBy.jsdate;
        data.startBy = moment(sdateval).format("YYYY-MM-DD")
      }
      if (data.completeBy != null) {
        var dateval = data.completeBy.jsdate;
        data.completeBy = moment(dateval).format("YYYY-MM-DD")
      }
      data.projectId = this.parentProjectId;
      data.createdBy = parseInt(this.userid);
      data.type = "ISSUE";
      data.parentId = this.tid;
      this.http.post('api/task/', data).subscribe((response) => {
        this.res = response;
        if (this.res.success == true) {
          this.toastr.clear();
          this.toastr.success(this.res.message, 'Success', {
            timeOut: 6000,
            closeButton: true
          });
          this.childTicketOpen(this.res.response.id,1);
          this.toggleClose();
          this.allFilter(0,1);
        } else {
          this.toastr.clear();
          this.toastr.error(this.res.message, 'Error', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
      this.taskArr = [];
      this.taskCommentArr = [];
    }
    }
    toggleClose(){
      if (this.menuStateTaskEdit == 'in') {
        this.menuStateTaskEdit = 'out';
      }
      if (this.popoverform == true) {
        this.popoverform = false;
      } else {
        this.popoverform = true;
      }
    }

}



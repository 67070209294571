import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './login/logout.component';
import { HomeComponent } from './home/home.component';
import { DealsComponent } from './deals/deals.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SchdeuleComponent } from './schdeule/schdeule.component';
import { ResourceManagementComponent } from './resource-management/resource-management.component';
import { DomainComponent } from './domain/domain.component';
import { ContactsComponent } from './contacts/contacts.component';
import { IndexComponent } from './MainFrame/index/index.component';
import { OrganizationComponent } from './contacts/organization/organization.component';
import { CustomerComponent } from './contacts/customer/customer.component';
import { ProjectComponent } from './project/project.component';
import { UserComponent } from './admin/user/user.component';
import { CustomizationComponent } from './admin/customization/customization.component';
import { ApprovalComponent } from './approval/approval.component';
import { RoleComponent } from './admin/role/role.component';
import { ArchiveComponent } from './admin/archive/archive.component';
import { DetailsComponent } from './details/details.component';
import { ApprovalsDetailsComponent } from './approvals-details/approvals-details.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { IssuesComponent } from './issues/issues.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { CreatepasswordComponent } from './createpassword/createpassword.component';
import { UserLogsComponent } from './admin/user-logs/user-logs.component';
import { UsercreatepasswordComponent } from './usercreatepassword/usercreatepassword.component';
import { DashboardNewComponent } from './dashboard-new/dashboard-new.component';
import { TasksComponent } from './tasks/tasks.component';
import { BillingComponent } from './billing/billing.component';
import { PrintInvoiceComponent } from './print-invoice/print-invoice.component';
import { CustomerDashboardComponent } from './customer-dashboard/customer-dashboard.component';
import { JobHandlingComponent } from './job-handling/job-handling.component';
import { ProfileComponent } from './profile/profile.component';
import { HeaderComponent } from './MainFrame/header/header.component';
import { CertifierComponent } from './admin/certifier/certifier.component';
import { ExternalUserComponent } from './admin/external-user/external-user.component';
import { EstimationAdminComponent } from './admin/estimation-admin/estimation-admin.component';
import { DomainRegistryComponent } from './domain-registry/domain-registry.component'

import {
    AuthGuardService as AuthGuard
} from './_services/auth-guard.service';
const appRoutes: Routes = [
    { path: '', component: LoginComponent },
    { path: 'login', component: LoginComponent },
    { path: 'logout', component: LogoutComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'domain', component: DomainComponent },
    { path: 'resetpassword', component: ResetpasswordComponent },
    { path: 'createpassword', component: CreatepasswordComponent },
    { path: 'usercreatepassword', component: UsercreatepasswordComponent },
    { path: 'print-invoice/:invoiveId', component: PrintInvoiceComponent },
    { path: 'domain-registry', component: DomainRegistryComponent},
    { path: 'headercomponent', component: HeaderComponent, canActivate: [AuthGuard] },
    {
        path: '', component: IndexComponent,
        children: [
            { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
            { path: 'deals', component: DealsComponent, canActivate: [AuthGuard] },
            { path: 'dashboardnew', component: DashboardNewComponent, canActivate: [AuthGuard] },
            { path: 'dashboard', component: DashboardNewComponent, canActivate: [AuthGuard] },
            { path: 'billing', component: BillingComponent, canActivate: [AuthGuard] },
            { path: 'customer-dashboard', component: CustomerDashboardComponent, canActivate: [AuthGuard] },
            { path: 'certifier-dashboard', component: CustomerDashboardComponent, canActivate: [AuthGuard] },
            { path: 'council-dashboard', component: CustomerDashboardComponent, canActivate: [AuthGuard] },
            { path: 'externaluser-dashboard', component: CustomerDashboardComponent, canActivate: [AuthGuard] },
            { path: 'customer-dashboardd', component: CustomerDashboardComponent, canActivate: [AuthGuard] },
            { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
            { path: 'profile/mailsync', component: ProfileComponent, canActivate: [AuthGuard] },


            { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
            { path: 'issues', component: IssuesComponent, canActivate: [AuthGuard] },
            { path: 'resource-management', component: ResourceManagementComponent, canActivate: [AuthGuard] },
            { path: 'Events', component: SchdeuleComponent, canActivate: [AuthGuard] },
            //{ path: 'contacts', component: ContactsComponent },
            { path: 'contacts/org', component: OrganizationComponent, canActivate: [AuthGuard] },
            { path: 'contacts/cust', component: CustomerComponent, canActivate: [AuthGuard] },
            { path: 'project', component: ProjectComponent, canActivate: [AuthGuard] },
            { path: 'admin/estimation-admin', component: EstimationAdminComponent, canActivate: [AuthGuard] },
            { path: 'admin/user', component: UserComponent, canActivate: [AuthGuard] },
            { path: 'admin/custom', component: CustomizationComponent, canActivate: [AuthGuard] },
            { path: 'admin/user-logs', component: UserLogsComponent, canActivate: [AuthGuard] },
            { path: 'admin/role', component: RoleComponent, canActivate: [AuthGuard] },
            { path: 'admin/archive', component: ArchiveComponent, canActivate: [AuthGuard] },
            { path: 'approval/:type', component: ApprovalComponent, canActivate: [AuthGuard] },
            { path: 'cc/:type', component: ApprovalComponent, canActivate: [AuthGuard] },
            { path: 'subdivision/:type', component: ApprovalComponent, canActivate: [AuthGuard] },
            { path: 'details/:id', component: DetailsComponent, canActivate: [AuthGuard] },
            { path: 'details/:id/task/:tid', component: DetailsComponent, canActivate: [AuthGuard] },
            { path: 'approvals-details/:id', component: ApprovalsDetailsComponent, canActivate: [AuthGuard] },
            { path: 'project-details/:id', component: ProjectDetailsComponent, canActivate: [AuthGuard] },
            { path: 'job/:id', component: JobHandlingComponent, canActivate: [AuthGuard] },
            { path: 'project-details/:id/task/:tid', component: ProjectDetailsComponent, canActivate: [AuthGuard] },
            { path: 'billing/success/:payId/order/:orderid/signature/:signature', component: BillingComponent, canActivate: [AuthGuard] },
            { path: 'billing/:newbulling', component: BillingComponent, canActivate: [AuthGuard] },
            { path: 'admin/certifier', component: CertifierComponent, canActivate: [AuthGuard] },
            { path: 'admin/external-user', component: ExternalUserComponent, canActivate: [AuthGuard] },
            { path: 'jobPost/:id/:pid/:type/:value', component: JobHandlingComponent, canActivate: [AuthGuard] },
            { path: 'issues/:id/:iid', component: IssuesComponent, canActivate: [AuthGuard] },
            { path: 'user/:id', component: UserComponent, canActivate: [AuthGuard] },
            { path: 'customer/:id', component: CustomerComponent, canActivate: [AuthGuard] },
            { path: 'job/:id', component: JobHandlingComponent, canActivate: [AuthGuard] },
            { path: 'tasks', component: TasksComponent, canActivate: [AuthGuard] },
        ]
    }


];

export const routing = RouterModule.forRoot(appRoutes, { useHash: true });

import { Component, OnInit } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { PermissionService } from 'src/app/_services/permission.service';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';


@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  admin: any;
  isAdminDis: boolean = false;
  userid = "";
  isDeal: boolean = false;
  isApproval: boolean = false;
  isProject: boolean = false;
  iscustomer: boolean = false;
  isSuperAdmin: boolean = false;
  syncheck: boolean = false;
  result :any;
  result1 :any;


  constructor(private http: HttpClient, private permissionService: PermissionService,
  ) { }

  ngOnInit() {
    this.userid = localStorage.getItem("loggedInUserID");
    this.getUser();
     this.getPreference();
    this.admin = localStorage.getItem("loggedInUserAdmin");
    if (this.admin == "Y") {
      this.isAdminDis = true;
      this.isProject = true;
      this.isDeal = true;
      this.isApproval = true;
    }
  }
   getPreference() {
    this.http.get('/api/userPreferences/' + this.userid).subscribe((res: Response) => {
      this.result = res;
      if (this.result.length != 0) {
        if (this.result[0].isAccountAdded == "Y") {
          this.syncheck = true;
        } else {
          this.syncheck = false;
        }
      }
    })

  }
  getUser() {
    this.http.get('/api/user/' + this.userid).subscribe((res: Response) => {
      this.result1 = res;
      var permision = this.result1.role.permision;
      if (permision.length > 0) {
        var dealPermission = this.permissionService.getDealPermission(permision);
        var approvalPermission = this.permissionService.getApprovalPermission(permision);
        var projectPermission = this.permissionService.getProjectPermission(permision);
        var customerPermission = this.permissionService.getCustomerPermission(permision);
        if (customerPermission == this.permissionService.CUSTOMER) {
          this.iscustomer = true;
        }
        if (dealPermission == this.permissionService.DEAL_ADMIN || dealPermission == this.permissionService.DEAL_OBSERVER) {
          if (dealPermission == this.permissionService.DEAL_ADMIN || this.admin == "Y") {
            this.isAdminDis = true;
            this.isDeal = true;
          } else if (dealPermission == this.permissionService.DEAL_OBSERVER) {
            this.isDeal = true;
          }
        } if (approvalPermission == this.permissionService.APPROVAL_ADMIN || approvalPermission == this.permissionService.APPROVAL_OBSERVER) {
          if (approvalPermission == this.permissionService.APPROVAL_ADMIN || this.admin == "Y") {
            this.isAdminDis = true;
            this.isApproval = true;
          } else if (approvalPermission == this.permissionService.APPROVAL_OBSERVER) {
            this.isApproval = true;
          }
        } if (projectPermission == this.permissionService.PROJECT_ADMIN || projectPermission == this.permissionService.PROJECT_OBSERVER) {
          if (projectPermission == this.permissionService.PROJECT_ADMIN || this.admin == "Y") {
            this.isAdminDis = true;
            this.isProject = true;
          } else if (projectPermission == this.permissionService.PROJECT_OBSERVER) {
            this.isProject = true;
          }
        }
      }
      if(this.admin == 'Y'){
        this.isSuperAdmin = true;
      }else{
        this.isSuperAdmin = false;
      }
    });
  }
  toggleMail(){
    const url = 'https://www.google.com/gmail/'
    window.open(url);
  }

}

import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorService } from 'src/app/_services/error.service';
import * as moment from 'moment/moment.js';
import { consumeBinding } from '@angular/core/src/render3/instructions';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-createpassword',
  templateUrl: './createpassword.component.html',
  styleUrls: ['./createpassword.component.css']
})
export class CreatepasswordComponent implements OnInit {
  ProjectName:string;
  result: any;
  password: any[];
  body: {};
  recovrErr = false;
  forgotPass: boolean = true;
  forgotPass2: boolean = false;
  createRegister: boolean = false;
  butDis: boolean = false;
  resetpasswordErr: any;
  confirmpasswordErr: any;
  passwordForm: FormGroup;
  pwid: any
  constructor(private http: HttpClient, private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
    private errorService: ErrorService, private router: Router, private toastr: ToastrService,
    private route: ActivatedRoute) { }


  ngOnInit() {
    this.ProjectName =localStorage.getItem("ProjectName");
    this.createForm();
  }
  resetForm() {
    this.passwordForm.reset({
      password: '',
      confirmPassword: ''
    })
  }

  createForm() {
    this.passwordForm = this.formBuilder.group({
      password: '',
      confirmPassword: ''
    })
  }
  recoverPassword() {
    if (this.passwordValidate()) {
      var data = this.passwordForm.value;
      this.route.queryParams.subscribe(params => {
        this.pwid = params['pwid'];
      });
      this.butDis = true;
      var body = {};
      body["password"] = data.confirmPassword;
      body["token"] = this.pwid;
      this.http.post('/unsecure/createpsd/', body).subscribe((response) => {
        this.result = response;
        var status = this.result.success;
        var message = this.result.message;
        if (status == true) {
          this.toastr.success(message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          localStorage.clear();
          this.router.navigate(["/login"]);
          this.forgotPass = false;
          this.recovrErr = null;
        } else {
          this.toastr.error(message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.butDis = false;
          this.recovrErr = false;
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
        this.butDis = false;
      });
    }

  }
  loginPage() {
    localStorage.clear();
    this.router.navigate(["/login"]);
  }

  passwordValidate() {
    this.resetpasswordErr = null;
    this.confirmpasswordErr = null;
    var data = this.passwordForm.value;
    const strongRegex = /(?=.{6,})/;
    var valid = true;
    if (data.password == Validators || data.password == '') {
      this.resetpasswordErr = "Password is required.";
      valid = false;
    } else if (!strongRegex.test(data.password)) {
      this.resetpasswordErr = "Password is Must be 6 Charcters.";
      valid = false;
    } else if (data.password != data.confirmPassword) {
      this.confirmpasswordErr = "Password doesn't Match.";
      valid = false;
    }
    return valid;
  }

}




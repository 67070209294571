import { Component, ElementRef, ViewChild, Renderer2, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import * as moment from 'moment/moment.js';
import { NgForm } from '@angular/forms';
import { DragulaService } from 'ng2-dragula';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '../_services';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { ErrorService } from 'src/app/_services/error.service';
import { PermissionService } from 'src/app/_services/permission.service';
import { ToastrService } from 'ngx-toastr';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { trigger, state, animate, transition, style } from '@angular/animations';
import { Subscription } from 'rxjs';
import { DetailsService } from '../_services/index';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css'],
  viewProviders: [DragulaService],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class ProjectComponent implements OnInit {
  @ViewChild('isIncludeTag') isIncludeTag: ElementRef;
  @Input('dealSaveChild') dealSaveChild;
  @Output() ChangeEvent = new EventEmitter();
  result: any;
  body: {};
  approvalproArr = [];
  id: any;
  isCreate: boolean = true;
  closeResult: string;
  projectName: string;
  menuState: string = 'out';
  projform: FormGroup;
  projectform: FormGroup;
  usersArr = [];
  stateArr = [];
  usersArrselect = [];
  selectedItems = [];
  certifierArr = [];
  certifierArrselect = [];
  settingstype:any;
  settings: any;
  settingss: any;
  settingssingle: any;
  stateArrselect = [];
  dropdownList1 = [];
  admin: any;
  term:any;
  userid: any;
  oid: any;
  did: any;
  pinfoid: any;
  tick: any;
  typeErr: any;
  StateErr: any;
  managerErr: any;
  ProjectErr: any;
  buildErr:any;
  centrifiedErr: any;
  postalCodeErr: any;
  isAdminDis: boolean = false;
  isadmin: boolean = false;
  popoverform: boolean = false;
  isatt: boolean = false;
  isDisabled = true;
  isSuperAdmin: boolean = false;
  result1 :any;
  result2:any;
  result3 :any;
  result4 :any;
  res2 :any
  res :any;
  res1 :any;
  result5 :any;
  loggedInUserDomain :any;
  buidingArr=[];
  buidingArrselect=[];
  pagedItemspointer: any;
  currentPage = 1;
  maxSize = 5;
  pagelimit = 1000;
  reat: any;
  isfitler:boolean =false;
  projectgetId :any;
  resultTotalElemets :any;

  councilArr = [];
  councilArrSelect = [];

  projectStageArr = [];
  projectStageArrId: any;
  subs = new Subscription();
  isvaluecheck: boolean = false;

  orgMap = {};
  customerMap = {};

  inmodule : any;

  showSubmitted:boolean = false;
  editable:boolean = false;
  selectedUser: any;

  constructor(private http: HttpClient, private router: Router,
    private dragulaService: DragulaService,
    private modalService: NgbModal,
    private toastr: ToastrService, private spinner: NgxSpinnerService,
    private errorService: ErrorService,
    private confirmService: ConfirmService,
    private permissionService: PermissionService,
    private formBuilder: FormBuilder, private DetailsService: DetailsService,
    private _fb: FormBuilder) {
    this.createForm();

    this.dragulaService.createGroup('V', {
      moves: function (el: any, container: any, handle: any): any {
        if (el.classList.contains('nomove')) {
            return false;
        }
        return true;
    }
    });
    this.subs.add(this.dragulaService.drop()
      .subscribe(({ name, el, target, source, sibling }) => {
        var i = target.attributes["id"].nodeValue;
        var projectId = el.attributes["id"].nodeValue;
        if (projectId == -1) { return false; }
        var order = this.projectStageArr[i].id;
        this.projectStageArrId = this.projectStageArr[i].id;
        this.setProjectStage(projectId, this.projectStageArr[i].id);
      })
    );
  }
  ngOnInit() {
   // this.initForm();
   this.approvalproArr=[];
    this.userid = localStorage.getItem("loggedInUserID");
    this.admin = localStorage.getItem("loggedInUserAdmin");
    this.loggedInUserDomain = localStorage.getItem('loggedInDomainID');
    this.getBuildingtype();
    this.getOrgs();
    this.getCustomers();
    this.settings = {
      text: "Select",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: true,
      enableCheckAll: false
    };
      this.settingstype = {
      text: "Select",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: true,
      enableCheckAll: false
    };
    this.settingss = {
      text: "User",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: false,
      enableCheckAll: false
    };

    this.dropdownList1 = [
      { "id": 1, "itemName": "DA" },
      { "id": 2, "itemName": "CC" },
      { "id": 4, "itemName": "CDC"},
      { "id": 3, "itemName": "SubDivision" }

    ];

    this.settingssingle = {
      text: "Select",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      singleSelection: true,
      enableCheckAll: false
    };
    this.getUser();
    this.getProjectstate();
    this.getCertifier();
    this.getCouncil();
    this.getPermissionDetails();

  }

  getOrgs() {
      this.http.get('/api/orgs').subscribe((res: Response) =>
      {
        this.result = res;
        for (let i = 0; i < this.result.length; i++) {
          this.orgMap[this.result[i].id] = this.result[i].orgName;
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
     }
  getCustomers() {
    this.http.get('/api/customer').subscribe((res: Response) =>
    {
      this.result = res;
      for (let i = 0; i < this.result.length; i++) {
        this.customerMap[this.result[i].details.id] = this.result[i].details.firstName
                                              + ' ' + this.result[i].details.lastName;
      }
      console.log("this customermap");
      console.log(JSON.stringify(this.customerMap));
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
   }

   getAllProjects() {
     this.selectedUser = null;
     this.getApproval(null, 0);
   }

   getProjectsByUser($event) {
     console.log($event);
     this.selectedUser = $event.id;
     this.getApproval(this.selectedUser, 0);
   }

   getProjectByCompletion() {
     this.getApproval(this.selectedUser, 0);
   }

  getApproval(userid, num) {
    this.approvalproArr=[];
    this.projectStageArr=[];
    this.spinner.show();
    this.http.get('/api/projectStage/domain/' + this.loggedInUserDomain).subscribe((res: Response) => {
      this.result= res;
      for (let i = 0; i < this.result.length; i++) {
        this.projectStageArr.push({
          "id": this.result[i].id, "itemName": this.result[i].name,
        })
      }
      var url='';
      if (userid != null) {
        url = '/api/projectInfo/user/'+ userid + '/completed/' + this.showSubmitted + '/' + this.currentPage + '/' + this.pagelimit;
      } else {
        //url = '/api/projectInfo/' + this.currentPage + '/' + this.pagelimit;
        url = '/api/projectInfo/completed/' + this.showSubmitted + '/' + this.currentPage + '/' + this.pagelimit;
      }
      this.http.get(url).subscribe((res: Response) => {
        this.result2 = res;
        this.result = this.result2.projects;
        var datacount =this.result2.totalElements;
        this.resultTotalElemets = this.result2.totalElements;
        if (this.result.length > 0) {
          this.isvaluecheck = false;
        }
        else {
          this.isvaluecheck = true;
        }
        if (num == 0) {
          this.setPagination(datacount);
        }
        this.spinner.hide();
        for (let j = 0; j < this.projectStageArr.length; j++) {
          for (let i = 0; i < this.result.length; i++) {

            if (this.approvalproArr[j] == null) {
              this.approvalproArr[j] = [{ approvalproId: -1, approvalproName: "Drop Here", title: "test" }];
            }
            console.log(this.result[i].duration);
            if (this.projectStageArr[j].id == this.result[i].projectStage) {
              if (this.result[i].duration.TotalProjectDurationDays != 0 && this.result[i].duration.TotalProjectDurationMonths == 0 && this.result[i].duration.TotalProjectDurationYears == 0) {
                var dayvalue = this.result[i].duration.TotalProjectDurationDays + " days";
              } else if (this.result[i].duration.TotalProjectDurationDays != 0 && this.result[i].duration.TotalProjectDurationMonths != 0 && this.result[i].duration.TotalProjectDurationYears == 0) {
                var dayvalue = this.result[i].duration.TotalProjectDurationMonths + " mnt " + this.result[i].duration.TotalProjectDurationDays + " days";
              } else if (this.result[i].duration.TotalProjectDurationYears != 0) {
                var dayvalue = this.result[i].duration.TotalProjectDurationYears + " Yr " + this.result[i].duration.TotalProjectDurationMonths + " mnt " + this.result[i].duration.TotalProjectDurationDays + " days";
              } else {
                var dayvalue = this.result[i].duration.TotalProjectDurationDays + " days";
              }
              this.approvalproArr[j].push({
             "approvalproId":this.result [i].projectId, "approvalproName":this.result [i].projectTitle, "Code": this.result [i].projectCode, "id":this.result [i].projectInfoId,
              "projectStateType":this.result [i].projectType, "ausState":this.result [i].projectStateName,
              "startdate":this.result [i].projectStartDate, "enddate":this.result [i].projectEndDate,"projectTypeId":this.result [i].projectBuildingType,
              "inmodule":this.result[i].inmodule, "projectStage": this.projectStageArr[j].itemName,
              "customerId":this.result[i].customers[0].customerId, "customer": this.customerMap[this.result[i].customers[0].customerId],
              "manager":this.result[i].projectManagerName,"days": dayvalue,
              "orgId":this.result[i].customers[0].orgId, "org": this.orgMap[this.result[i].customers[0].orgId], "editable": true
            })
            }
          }
        }
        console.log("approvalproArr");
        console.log(JSON.stringify(this.approvalproArr));
        console.log(JSON.stringify(this.projectStageArr));
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  getApprovalAtt(num) {
    this.approvalproArr=[];
    this.spinner.show();
    this.isfitler =true;
    this.http.get('/api/projectStage/domain/' + this.loggedInUserDomain).subscribe((res: Response) => {
      this.result= res;
      for (let i = 0; i < this.result.length; i++) {
        this.projectStageArr.push({
          "id": this.result[i].id, "itemName": this.result[i].name,
        })
      }
      this.http.get('/api/projectInfo/user/'+ this.userid + '/completed/' + this.showSubmitted + '/' + this.currentPage + '/' + this.pagelimit).subscribe((res: Response) => {
        this.result2 = res;
        this.result = this.result2.projects;
        var datacount =this.result2.totalElements;
        this.resultTotalElemets = this.result2.totalElements;
        if (num == 0) {
          this.setPagination(datacount);
        }
        this.spinner.hide();
        for (let j = 0; j < this.projectStageArr.length; j++) {
          for (let i = 0; i < this.result.length; i++) {
            if (this.approvalproArr[j] == null) {
              this.approvalproArr[j] = [{ approvalproId: -1, approvalproName: "Drop Here", title: "test" }];
            }
            if (this.projectStageArr[j].id == this.result[i].projectStage) {
              console.log(JSON.stringify(this.result[i]));
              console.log(this.result[i].duration);
                if (this.result[i].duration.TotalProjectDurationDays != 0 && this.result[i].duration.TotalProjectDurationMonths == 0 && this.result[i].duration.TotalProjectDurationYears == 0) {
                  var dayvalue = this.result[i].duration.TotalProjectDurationDays + " days";
                } else if (this.result[i].duration.TotalProjectDurationDays != 0 && this.result[i].duration.TotalProjectDurationMonths != 0 && this.result[i].duration.TotalProjectDurationYears == 0) {
                  var dayvalue = this.result[i].duration.TotalProjectDurationMonths + " mnt " + this.result[i].duration.TotalProjectDurationDays + " days";
                } else if (this.result[i].duration.TotalProjectDurationYears != 0) {
                  var dayvalue = this.result[i].duration.TotalProjectDurationYears + " Yr " + this.result[i].duration.TotalProjectDurationMonths + " mnt " + this.result[i].duration.TotalProjectDurationDays + " days";
                } else {
                  var dayvalue = this.result[i].duration.TotalProjectDurationDays + " days";
                }
              if (this.userid == this.result[i].projectManager) {
                this.editable = true;
              }else{
                this.editable = false;
              }
              this.approvalproArr[j].push({
             "approvalproId":this.result [i].projectId, "approvalproName":this.result [i].projectTitle, "Code": this.result [i].projectCode, "id":this.result [i].projectInfoId,
              "projectStateType":this.result [i].projectType, "ausState":this.result [i].projectStateName,
              "startdate":this.result [i].projectStartDate, "enddate":this.result [i].projectEndDate,"projectTypeId":this.result [i].projectBuildingType,
              "inmodule":this.result[i].inmodule, "projectStage": this.projectStageArr[j].itemName,
              "customerId":this.result[i].customers[0].customerId, "customer": this.customerMap[this.result[i].customers[0].customerId],
              "manager":this.result[i].projectManagerName,"days": dayvalue,
              "orgId":this.result[i].customers[0].orgId, "org": this.orgMap[this.result[i].customers[0].orgId], "editable": this.editable
            })
            }
          }
        }
        console.log("approvalproArr att");
        console.log(JSON.stringify(this.approvalproArr));
        console.log(JSON.stringify(this.projectStageArr));
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  getProjectstate() {
    this.http.get('/api/australianStates').subscribe((res: Response) => {
      this.result= res;
      for (let i = 0; i < this.result.length; i++) {
        this.stateArr.push({
          "id": this.result[i].id, "itemName": this.result[i].name,
        })
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  getProjectStages() {
    this.projectStageArr = [];
    this.http.get('/api/projectStage/domain/' + this.loggedInUserDomain).subscribe((res: Response) => {
      this.result= res;
      for (let i = 0; i < this.result.length; i++) {
        this.projectStageArr.push({
          "id": this.result[i].id, "itemName": this.result[i].name,
        })
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }

  setProjectStage(projectId, projectStageId) {
    this.spinner.show();
    this.http.get('/api/project/' + projectId).subscribe((res: Response) => {
      this.result = res;
      if (this.result != null) {
        this.result.projectStageId = projectStageId;
        this.result.updatedBy = parseInt(this.userid);
        this.http.put('/api/project/', this.result).subscribe((res1: Response) => {
          this.res2 = res1;
          this.toastr.clear();
          this.toastr.success('Project Moved successfully', 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();

        }, error => {
          this.toastr.clear();
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.errorService.handleError(error);
        });
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  getCertifier() {
    this.http.get('/api/certifiers/').subscribe((res: Response) => {
      this.result = res;
      for (let i = 0; i < this.result.length; i++) {
        this.certifierArr.push({
          "id": this.result[i].id, "itemName": this.result[i].firstName + " " + this.result[i].lastName,
        })
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  getCouncil() {
    this.http.get('/api/council/').subscribe((res: Response) => {
      this.result = res;
      for (let i = 0; i < this.result.length; i++) {
        this.councilArr.push({
          "id": this.result[i].id, "itemName": this.result[i].firstName + " " + this.result[i].lastName,
        })
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  getSates() {
    this.http.get('/api/australianStates/').subscribe((res: Response) => {
      this.result = res;
      for (let i = 0; i < this.result.length; i++) {
        this.certifierArr.push({
          "id": this.result[i].id, "itemName": this.result[i].firstName + " " + this.result[i].lastName,
        })
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  async getUser() {
    var result = await this.DetailsService.getUsers();
    this.result1 = result;
    this.result1.forEach(r => {
      this.usersArr.push({
        "id": r.details.id, "itemName": r.details.firstName + " " + r.details.lastName
      })
    })
  }

  public resetForm() {
    this.projform.reset({
      input1: '',
      shortdesc: ''
    })
    this.projectform.reset({
      domainId :'',
      Code: '',
      unit: '',
      ausState: [],
      address1: '',
      address2: '',
      city: 'Sydney',
      state: 'New South Wales',
      country: 'Australia',
      postalCode: '',
      projectStateType: [],
      managerId: [],
      certifierId: [],
      councilId: [],
      createdDate: '',
      createdBy: '',
      projectTypeId:[],
    })
  }
  createForm() {
    this.projform = this.formBuilder.group({
      input1: '',
      shortdesc: ''
    })
    this.projectform = this.formBuilder.group({
      domainId:'',
      Code: '',
      unit: '',
      ausState: '',
      address1: '',
      address2: '',
      city: 'Sydney',
      state: 'New South Wales',
      country: 'Australia',
      postalCode: '',
      projectStateType: '',
      managerId: '',
      certifierId: '',
      councilId: '',
      createdDate: '',
      createdBy: '',
      projectTypeId:''
    })
  }

  toggleCreateMenu() {
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.isCreate = true;
    this.resetForm();
    this.typeErr = null;
    this.StateErr = null;
    this.managerErr = null;
    this.ProjectErr = null;
    this.buildErr=null;
    this.centrifiedErr = null;
    this.postalCodeErr = null;
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
  }

  toggleEditMenu(pinfoid) {
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
   // this.projform.disable();
    this.tick = false;
    this.isCreate = false;
        this.buidingArrselect = [];
    this.stateArrselect = [];
    this.usersArrselect = [];
    this.certifierArrselect = [];
    this.councilArrSelect = [];
    this.typeErr = null;
    this.StateErr = null;
    this.managerErr = null;
    this.ProjectErr = null;
    this.buildErr=null;
    this.projectgetId = pinfoid;
    this.centrifiedErr = null;
    this.postalCodeErr = null;
    this.http.get('/api/projectInfo/' + pinfoid).subscribe((res: Response) => {
      this.result = res;
      this.result1 = this.result.projectInfo;
      this.result2 = this.result.projectAddress;
      this.result3 = this.result.project;
      if (this.result) {
        this.inmodule = this.result.inmodule;
        this.did = this.result1.projectId;
        this.pinfoid = this.result1.id;
        this.projectName= this.result3.title;
        this.projform.patchValue({ 'id': this.result1.projectId });
        this.projform.patchValue({ 'input1': this.result3.title });
        this.projform.patchValue({ 'shortdesc': this.result3.shortdesc})
        this.projectform.patchValue({ 'Code': this.result1.code });
        this.projectform.patchValue({ 'id': this.result1.id });
        this.projectform.patchValue({ 'createdDate': this.result1.createdDate });
        this.projectform.patchValue({ 'createdBy': this.result1.createdBy });
        if (this.result1.projectStateType != null) {
          if (this.result1.projectStateType == "DA") {
            this.selectedItems = [];
            this.selectedItems.push({ "id": 1, "itemName": this.result1.projectStateType })
          } else if (this.result1.projectStateType == "CC") {
            this.selectedItems = [];
            this.selectedItems.push({ "id": 2, "itemName": this.result1.projectStateType })
          } else if (this.result1.projectStateType == "SubDivision") {
            this.selectedItems = [];
            this.selectedItems.push({ "id": 3, "itemName": this.result1.projectStateType })
          } else if (this.result1.projectStateType == "CDC") {
            this.selectedItems = [];
            this.selectedItems.push({ "id": 4, "itemName": this.result1.projectStateType })
          }
        }
        if (this.result1.ausState != null) {
          this.http.get('/api/australianStates/' + this.result1.ausState).subscribe((res: Response) => {
            this.result5 = res;
            this.stateArrselect.push({
              "id": this.result5.id, "itemName": this.result5.name,
            })
          }, error => {
            this.toastr.clear();
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.errorService.handleError(error);
          });
        }
              if (this.result1.projectTypeId != null) {
          this.http.get('/api/projectType/' + this.result1.projectTypeId).subscribe((res: Response) => {
            this.result = res;
            this.buidingArrselect.push({
              "id": this.result .id, "itemName": this.result .type,
            })
          }, error => {
            this.toastr.clear();
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.errorService.handleError(error);
          });
        }
        if (this.result.persons.manager != "") {
          this.usersArrselect.push({
            "id": this.result1.managerId, "itemName": this.result.persons.manager,
          })
        }
        if (this.result.persons.certifier != "") {
          this.certifierArrselect.push({
            "id": this.result1.certifierId, "itemName": this.result.persons.certifier,
          })
        }
        if (this.result.persons.council != "") {
          this.councilArrSelect.push({
            "id": this.result1.councilId, "itemName": this.result.persons.council,
          })
        }
        if (this.result2) {
          this.projectform.patchValue({ 'unit': this.result2.unit });
          this.projectform.patchValue({ 'address1': this.result2.address1 });
          this.projectform.patchValue({ 'address2': this.result2.address2 });
          this.projectform.patchValue({ 'city': this.result2.city });
          this.projectform.patchValue({ 'state': this.result2.state });
          this.projectform.patchValue({ 'country': this.result2.country });
          this.projectform.patchValue({ 'postalCode': this.result2.postalCode });
        //   this.projectform.patchValue({ 'createdDate': this.result2.createdDate });
        //   this.projectform.patchValue({ 'createdBy': this.result2.createdBy });
        }
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
  }



  updateProject() {
    this.body = {};
    if (this.validate()) {
    if (this.validate1()) {
      this.http.get('/api/project/' + this.did).subscribe((res: Response) => {
        this.result = res;
        if (this.result != null) {
          this.result.title = this.projform.value["input1"];
          this.result.shortdesc = this.projform.value["shortdesc"];
          this.result.updatedBy = parseInt(this.userid);
          this.http.put('/api/project/title', this.result).subscribe((res1: Response) => {
            this.res = res1;
            if (this.res.success == true) {
              // this.toastr.clear();
              // this.toastr.success(this.res.message, 'Success', {
              //   timeOut: 6000,
              //   closeButton: true
              // });
              // if(this.isadmin == true || this.isSuperAdmin == true){
              //   this.getApproval(0);
              // }else{
              //   this.getApprovalAtt(0);
              // }
              // this.approvalproArr = [];
            } else {
              // this.toastr.clear();
              // this.toastr.error(this.res.message, 'Error', {
              //   timeOut: 6000,
              //   closeButton: true
              // });
              // this.spinner.hide();
            }
          }, error => {
            this.toastr.clear();
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.errorService.handleError(error);
          });
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
    }

    console.log(JSON.stringify(this.projectform.value));
      this.body["projectId"] = this.did;
      this.body["id"] = this.pinfoid;
      this.body["title"] = this.projform.value["input1"];
      this.body["shortdesc"] = this.projform.value["shortdesc"];
      this.body["projectStateType"] = this.projectform.value["projectStateType"][0].itemName;
      this.body["projectTypeId"] = this.projectform.value["projectTypeId"][0].id;
      this.body["ausState"] = this.projectform.value["ausState"][0].id.toString();
      this.body["managerId"] = this.projectform.value["managerId"][0].id;
      this.body["code"] = this.projectform.value["Code"];
      if (this.projectform.value["certifierId"].length > 0 ) {
        this.body["certifierId"] = this.projectform.value["certifierId"][0].id;
      }
      if (this.projectform.value["councilId"].length > 0 ) {
        this.body["councilId"] = this.projectform.value["councilId"][0].id;
      }
      this.body["createdDate"] = this.projectform.value["createdDate"];
      this.body["createdBy"] = this.projectform.value["createdBy"];
      this.body["updatedBy"] = parseInt(this.userid);
      this.body["domainId"] = parseInt(this.loggedInUserDomain);
      this.body["unit"] = this.projectform.value["unit"];
      this.body["address1"] = this.projectform.value["address1"];
      this.body["address2"] = this.projectform.value["address2"];
      this.body["city"] = this.projectform.value["city"];
      this.body["state"] = this.projectform.value["state"];
      this.body["country"] = this.projectform.value["country"];
      this.body["postalCode"] = this.projectform.value["postalCode"];
      this.body["isActive"] = "Y";
      this.body["isHardDeleted"] = "N";
       this.spinner.show();
      this.http.put('/api/projectInfo', this.body).subscribe((response) => {
        this.resetForm();
        this.res = response;
        if (this.res.success == true) {
          this.toastr.clear();
          this.toastr.success(this.res.message, 'Success', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
          this.toggleCreateMenu();
          //this.ngOnInit();
          this.getPermissionDetails();
          this.usersArr = [];
          this.stateArr = [];
          this.certifierArr = [];
          this.councilArr = [];
          this.buidingArr=[];
        } else {
          this.toastr.clear();
          this.toastr.error(this.res.message, 'Error', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
      this.approvalproArr = [];
    }
  }
  navigatecheck(id,num){
    if(num == 1){
        this.confirmService.confirm('Please confirm..', 'Update the building type to proceed')
    .then((confirmed) => {
      if (confirmed) {
        this.toggleEditMenu(id);
      }
    })
    .catch(() => {
      return false;
    });
    }
  else{
        this.confirmService.confirm('Please confirm..', 'Building type is Not updated, So Please Contact project Managers')
     .catch(() => {
      return false;
    });
    }

  }

  navigate(approvalpro) {
    this.router.navigate(['/project-details', approvalpro]);
  }

  getBuildingtype() {
      this.http.get('/api/projectTypes').subscribe((res: Response) =>
      {
        this.result = res;
        for (let i = 0; i < this.result.length; i++) {
          this.buidingArr.push({
            "id": this.result[i].id, "itemName": this.result[i].type,
          })
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
     }
    validate1(){
      var valid = true;
    if (this.projectName == this.projform.value["input1"]) {
      valid = false;
    }else{
      valid = true;
    }
    return valid;
    }
  validate() {
    this.typeErr = null;
    this.StateErr = null;
    this.managerErr = null;
    this.buildErr=null;
    this.centrifiedErr = null;
    this.postalCodeErr = null;

    var data = this.projectform.value;
    var valid = true;
    if (!this.projform.value["input1"]) {
      this.ProjectErr = "Project Name is required.";
      valid = false;
    }
    if (data.projectStateType.length == 0) {
      this.typeErr = "Project Type is required";
      valid = false;
    }
    else if (data.ausState.length == 0) {
      this.StateErr = "State is required.";
      valid = false;
    }
    else if (data.managerId.length == 0) {
      this.managerErr = "Manager Name is required.";
      valid = false;
    }
      else if (data.projectTypeId.length == 0) {
      this.buildErr = "building Type is required.";
      valid = false;
    }
    // else if (data.certifierId.length == 0) {
    //   this.centrifiedErr = "Certifier Name is required.";
    //   valid = false;
    // }
    return valid;
  }
  projectDelete(){
    this.toggleCreateMenu();
    this.confirmService.confirm('Please confirm..', 'Do you want to delete ?')
    .then((confirmed) => {
      if (confirmed) {
        this.http.delete('/api/projectInfo/' + this.pinfoid).subscribe((res: Response) => {
          this.result = res;
          if (  this.result.success == true) {
            this.toastr.clear();
            this.toastr.success(this.result.message, 'Success', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
            //this.ngOnInit();
            this.getPermissionDetails();
            this.usersArr = [];
            this.stateArr = [];
            this.buidingArr = [];
            this.certifierArr = [];
            this.councilArr = [];

          } else {
            this.toastr.clear();
            this.toastr.error(this.result.message, 'Error', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();


          }
        }, error => {
          this.toastr.clear();
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.errorService.handleError(error);
        });
        this.approvalproArr = [];


      }
    })
    .catch(() => {
      return false;
    });
  }
  projectarchive() {
    this.toggleCreateMenu();
    this.confirmService.confirm('Please confirm..', 'Do you really want to archive ?')
    .then((confirmed) => {
    if (confirmed) {
    this.spinner.show();
      this.http.delete('/api/projectInfo/archive/'+ this.pinfoid +'/1').subscribe((res: Response) => {
        this.toastr.clear();
        this.result = res;
        if (this.result.success) {
          this.toastr.success(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
        this.spinner.hide();
        //this.ngOnInit();
        this.getPermissionDetails();
        this.usersArr = [];
        this.stateArr = [];
        this.buidingArr = [];
        this.certifierArr = [];
        this.councilArr = [];

        }
         else {

          this.toastr.error(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
        this.errorService.handleError(error);
      }
      )
    }
    }).catch(() => {
        this.popoverform = false;
        this.toggleEditMenu(this.projectgetId);

      // this.menuState = this.menuState === 'out' ? 'in' : 'out';
    });
    // this.approvalproArr = [];
   }
   openSlider(){
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
   }
  getPermissionDetails() {
    this.http.get('/api/user/' + this.userid).subscribe((res: Response) => {
      this.result = res;
      var permision = this.result.role.permision;
      if (permision.length > 0) {
        var projectPermission = this.permissionService.getProjectPermission(permision);
        var dealPermission = this.permissionService.getDealPermission(permision);
        var approvalPermission = this.permissionService.getApprovalPermission(permision);
        // if (projectPermission == this.permissionService.PROJECT_ADMIN || this.admin == "Y") {
          if(this.admin  == 'Y'){
          this.isadmin = true;
          this.getApproval(null, 0);
        // } else if (projectPermission == this.permissionService.PROJECT_OBSERVER ) {
          }else{
          this.isatt = true;
          this.getApprovalAtt(0);
        }
         if(this.admin == 'Y'){
        this.isSuperAdmin = true;
       }else{
        this.isSuperAdmin = false;
      }
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }

  setPagination(pagedItems) {
    var val = pagedItems / this.pagelimit;
    var rem = pagedItems % this.pagelimit;
    if (rem != 0) {
      if (val < 1) {
        this.pagedItemspointer = 1;
      } else {
        this.pagedItemspointer = Math.floor(val) + 1;
      }
    } else {
      this.pagedItemspointer = val;
    }
    if (this.pagedItemspointer < this.maxSize) {
      this.range(this.currentPage, this.pagedItemspointer, this.pagedItemspointer);
    } else {
      this.range(this.currentPage, this.maxSize, this.pagedItemspointer);
    }
  }
  prevPage() {
    var perPage = this.currentPage - 1;
    if (this.maxSize >= perPage) {
      var end = this.maxSize;
      var start = 1;
    } else {
      var end = perPage;
      var start = (end + 1) - this.maxSize;
    }
    if (this.reat.includes(perPage)) {
      this.currentPage = perPage;
    } else {
      this.currentPage = perPage;
      this.range(start, end, this.pagedItemspointer);
    }
    if (this.isfitler != true) {
      this.getApproval(null,1);
    } else {
      this.getApprovalAtt(1);
    }
  }
  setPage(n) {
    this.currentPage = n;
    if (this.isfitler != true) {
      this.getApproval(null,1);
    } else {
      this.getApprovalAtt(1);
    }    }
  nextPage() {
    var nextPage = this.currentPage + 1;
    if (this.maxSize < nextPage) {
      var text = this.maxSize + nextPage - 1;
      if (text < this.pagedItemspointer) {
        var end = text;
        var start = nextPage;
      } else {
        end = this.pagedItemspointer;
        var start = nextPage;
      }
    } else {
      var end = this.maxSize;
      var start = 1;
    }
    if (this.reat.includes(nextPage)) {
      this.currentPage = nextPage;
    } else {
      this.currentPage = nextPage;
      this.range(start, end, this.pagedItemspointer)
    }
    if (this.isfitler != true) {
      this.getApproval(null,1);
    } else {
      this.getApprovalAtt(1);
    }    }
  range(start, end, pages) {
    this.reat = [];
    if (pages == 1) {
      this.reat.push(1);
    } else {
      for (var i = start; i <= end; i++) {
        this.reat.push(i);
      }
    }
  };

}

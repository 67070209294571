import { Component, OnInit ,Input, Output,EventEmitter} from '@angular/core';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '../../_services';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { ErrorService } from 'src/app/_services/error.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, animate, transition, style } from '@angular/animations';
@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class OrganizationComponent implements OnInit {
  @Input('organizationSaveChild') organizationSaveChild;
  @Output() ChangeEvent = new EventEmitter();
  orgArr = [];
  result: any;
  resArr:any =[];
  form: FormGroup;
  isCreate: boolean = true;
  oid: any;
  body: {};
  _body: any;
  key: any;
  sort: any;
  err: { 'name': '', 'desc': '', };
  nameErr: any;
  descErr: any;
  closeResult: string;
  menuState: string = 'out';
  userid: any;
  result1 :any;
  result2:any;
  result3 :any;
  result4 :any;
  loggedInUserDomain :any;
  popoverform: boolean = false;
  dealTosliderOpen: boolean = false;
  customerSliderCloser: boolean = false;
  customerSaveChild :any;


  constructor(private http: HttpClient, private router: Router,
    private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private errorService: ErrorService,
    private confirmService: ConfirmService,
    private modalService: NgbModal, private activeModal: NgbActiveModal) {
    this.createForm();
  }

  ngOnInit() {
    this.getOrg();
    this.userid = localStorage.getItem("loggedInUserID");
    this.loggedInUserDomain = localStorage.getItem('loggedInDomainID');
    if(this.router.url == "/deals"){
      this.dealTosliderOpen = true;
      this.toggleCreateMenu();
     }else if(this.router.url == "/contacts/cust"){
      this.dealTosliderOpen = true;
      this.toggleCreateMenu();
    }else{
       this.dealTosliderOpen = false;
     }
     this.customerSaveChild = "organization";
  }
 

  public resetForm() {
    this.form.reset({
      orgName: '',
      orgDesc: '',
      createdDate: '',
      updatedDate: '',
      createdBy: '',
      updatedBy: '',
      entityTypeId: '',
    })
  }
  createForm() {
    this.form = this.formBuilder.group({
      orgName: ['', Validators.required],
      orgDesc: ['', Validators.required],
      createdDate: '',
      updatedDate: '',
      createdBy: '',
      updatedBy: '',
      entityTypeId: '',
    })
  }
  toggleCreateMenu() {
  
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
 
    this.nameErr = null;
    this.descErr = null;
    this.isCreate = true;
    this.oid = undefined;
    this.resetForm();
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
  }
  toggleEditMenu(id) {
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.nameErr = null;
    this.descErr = null;
    this.isCreate = false;
    this.oid = id;
    this.http.get('/api/org/' + this.oid).subscribe((res: Response) => {
     this.result = res;
      if (this.result) {
        this.form.patchValue({ 'id': this.result.id });
        this.form.patchValue({ 'orgName': this.result.orgName });
        this.form.patchValue({ 'orgDesc': this.result.orgDesc });
        this.form.patchValue({ 'isActive': this.result.isActive });
        this.form.patchValue({ 'createdDate': this.result.createdDate });
        this.form.patchValue({ 'entityTypeId': this.result.entityTypeId });
        this.form.patchValue({ 'createdBy': this.result.createdBy });
        this.form.patchValue({ 'updatedDate': this.result.updatedDate });
        this.form.patchValue({ 'updatedBy': this.result.updatedBy });
      }
    });


    this.menuState = this.menuState === 'out' ? 'in' : 'out';
  }






  validate() {
    this.nameErr = null;
    this.descErr = null;
    var data = this.form.value;
    var valid = true;
    if (data.orgName == undefined || data.orgName == '') {
      this.nameErr = "Organization Name is required.";
      valid = false;
    }

    return valid;
  }


  updateOrg() {
    this.body = {};
    this.body["id"] = this.oid;
    this.body["orgName"] = this.form.value["orgName"];
    this.body["orgDesc"] = this.form.value["orgDesc"];
    this.body["createdDate"] = this.form.value["createdDate"];
    this.body["entityTypeId"] = this.form.value["entityTypeId"];
    this.body["createdBy"] = this.form.value["createdBy"];
    this.body["updatedBy"] = parseInt(this.userid);
    this.body["domainId"]  =parseInt(this.loggedInUserDomain);

    this.body["isActive"] = "Y";
    if (this.validate()) {
      this.spinner.show();
      this.http.put('/api/org/', this.body).subscribe((response) => {

        this.toastr.clear();
        this.result = response;
        if (this.result.success) {
          this.resetForm();
          this.toastr.success(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.toggleCreateMenu();
          this.getOrg();
          this.orgArr = [];
        } else {

          this.toastr.error(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
        this.errorService.handleError(error);
      }
      )
    }
  }


  delete(id) {
       this.http.get('/api/customer/organization/' +id).subscribe((res: Response) => {
        this.result = res;
      this.resArr=[];
      if (this.result.length != 0) {
      for (let i = 0; i < this.result.length; i++) {
        this.resArr.push(this.result[i].firstName+" "+this.result[i].lastName);
      }
      this.confirmService.confirm('Users are associated to organization please do not delete '+ this.result.length+' user',  this.resArr)
      .then((confirmed) => {
        if (confirmed) {
          return false;
        }
      })
      .catch(() => {
        return false;
      });
      }else{
         this.confirmService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) => {
        if (confirmed) {
          this.spinner.show();
          this.http.delete('/api/org/' + id).subscribe((response) => {
            this.toastr.clear();
            this.result = response;
            if (this.result.success) {
              this.resetForm();
              this.toastr.success(this.result.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.getOrg();
              this.toggleCreateMenu()
              this.orgArr = [];
            } else {

              this.toastr.error(this.result.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
            }
          }, error => {
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
            this.errorService.handleError(error);
          })
        }
      })
      .catch(() => {
        return false;
      });
      }
    });
   
  }





 

  getOrg() {
    this.spinner.show();
    this.http.get('/api/orgs/').subscribe((res: Response) => {
      this.result= res;
      this.spinner.hide();
      for (let i = 0; i < this.result.length; i++) {
        this.orgArr.push({
          "id": this.result[i].id, "orgName": this.result[i].orgName, "orgDesc": this.result[i].orgDesc, "createdDate": this.result[i].createdDate,


        })
      }
    });
  }


  submitOrg() {
    var data = this.form.value;
    if (this.validate()) {
      this.spinner.show();
      data.createdBy = parseInt(this.userid);
      this.http.post('/api/org/', data).subscribe((response) => {
        this.toastr.clear();
        this.result = response;
        if (this.result.success) {
          this.resetForm();
          this.toastr.success(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          if(this.router.url == "/deals"){
            this.customerSliderCloser = true;
            this.toggleCreateMenu();
           }else if(this.router.url == "/contacts/cust"){
            this.customerSliderCloser = true;
            this.toggleCreateMenu();
          }else{
            this.toggleCreateMenu();
            this.getOrg();
            this.orgArr = [];
           }
     
        } else {
          this.toastr.error(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
        this.errorService.handleError(error);
      })
    }

  }


  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  closeToggleMenu(){
    this.popoverform = false;  
    this.nameErr = null;
    this.descErr = null;
    this.isCreate = true;
    this.oid = undefined;
    this.resetForm();
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
    this.customerSliderCloser = true;
  }

}


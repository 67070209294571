import { Inject, Injectable } from '@angular/core';
@Injectable()

export class PermissionService {
    public SITEADMIN = "A#01";
    public CUSTOMER = "A#02";
    public CERTIFIER = "A#03";
    public DEAL_ADMIN = "A#04";
    public DEAL_OBSERVER = "A#05";
    public APPROVAL_ADMIN = "A#06";
    public APPROVAL_OBSERVER = "A#07";
    public PROJECT_ADMIN = "A#08";
    public PROJECT_OBSERVER = "A#09";
    constructor() { }
    public getSiteadminPermission(permissions) {
      for (let i = 0; i < permissions.length; i++) {
        if (permissions[i].permissionCode == "A#01") {
          return this.SITEADMIN;
        }
      }
    }
    public getCustomerPermission(permissions) {
      for (let i = 0; i < permissions.length; i++) {
        if (permissions[i].permissionCode == "A#02") {
          return this.CUSTOMER;
        }
      }
    }
    public getCertifierPermission(permissions) {
      for (let i = 0; i < permissions.length; i++) {
        if (permissions[i].permissionCode == "A#03") {
          return this.CERTIFIER;
        }
      }
    }
    public getDealPermission(permissions) {
        for (let i = 0; i < permissions.length; i++) {
            if(permissions[i].permissionCode == "A#04") {
              return this.DEAL_ADMIN;
            } else if(permissions[i].permissionCode == "A#05"){
              return this.DEAL_OBSERVER;
            }
          }
    }
    public getApprovalPermission(permissions) {
        for (let i = 0; i < permissions.length; i++) {
            if(permissions[i].permissionCode == "A#06") {
              return this.APPROVAL_ADMIN;
            } else if(permissions[i].permissionCode == "A#07"){
              return this.APPROVAL_OBSERVER;
            }
          }
    }
    public getProjectPermission(permissions) {
        for (let i = 0; i < permissions.length; i++) {
            if(permissions[i].permissionCode == "A#08") {
              return this.PROJECT_ADMIN;
            } else if(permissions[i].permissionCode == "A#09"){
              return this.PROJECT_OBSERVER;
            }
          }
    }
};

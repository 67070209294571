import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  template: ''
})
export class LogoutComponent implements OnInit {
  username = "";
  userid = "";
  result: any;
  password: any[];
  email: any[];
  body: {};
  status = "";
  loginErr = false;
  constructor(private http: HttpClient, private router: Router, private toastr: ToastrService,
    private titleService: Title, private metaService: Meta) { }

  ngOnInit() {
    this.getProjectName();
    localStorage.clear();
    this.router.navigate(["/login"]);
  }
  getProjectName() {
    this.http.get('/unsecure/app/name').subscribe((res: Response) => {
      this.result = res;
      this.titleService.setTitle(this.result.aname);
      localStorage.setItem("ProjectName", this.result.aname);
    });
  }

}

import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { NgForm, EmailValidator } from '@angular/forms';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '../../_services';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { ErrorService } from 'src/app/_services/error.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DetailsService } from '../../_services';
import { trigger, state, animate, transition, style } from '@angular/animations';
@Component({
  selector: 'app-external-user',
  templateUrl: './external-user.component.html',
  styleUrls: ['./external-user.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class ExternalUserComponent implements OnInit {
  @Input('certifierSlider') certifierSlider;
  @Output() ChangeEvent = new EventEmitter();
  @ViewChild('isIncludeTag') isIncludeTag: ElementRef;

  externalUserForm: FormGroup;
  passwordForm: FormGroup;
  certifierForm: FormGroup;

  certifiersCount: any;

  menuStateCertifier: string = 'out';
  isCreate: boolean = true;
  popoverform: boolean = false;
  isSiteAdmin: boolean = false;
  popoverform1: boolean = false;
  externalUserArr = [];
  selectedItems = [];
  dropdownList = [];
  certifierArr = [];
  selectedItemsExternal = [];
  dropdownList2 = [];
  result: any;
  result1: any;
  externalUserCount: any;
  menuStateExternalUser: string = 'out';
  admin: any;
  genderErr: any;
  fnameErr: any;
  lnameErr: any;
  externalEmailErr: any;
  phoneErr: any;
  entityErr: any;
  filter: any;
  reverse: any;
  p: any;
  passwordErr: any;
  resetpasswordErr: any;
  confirmpasswordErr: any;
  userid: any;
  externalUserID: any;
  settingssingle: any;
  passreset: boolean = false;
  settingsUserType: any;
  key: any;
  term: any;
  genderCertErr: any;
  fnameCertErr: any;
  lnameCertErr: any;
  certifiemailErr: any;
  phoneCertErr: any;
  certifierID: any;
  dealTosliderOpen: boolean = false;
  certifierToDeal: boolean = false;
  certifier: boolean = false;
  council: boolean = true;


  constructor(private http: HttpClient, private router: Router, private DetailsService: DetailsService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService, private toastr: ToastrService,
    private errorService: ErrorService,
    private confirmService: ConfirmService,
    private modalService: NgbModal) {
    this.createForm();
  }

  ngOnInit() {
    this.getCertifier();
    // this.getExternalUser();
    this.userid = localStorage.getItem("loggedInUserID");
    this.admin = localStorage.getItem("loggedInUserAdmin");
    if (this.admin == 'Y') {
      this.isSiteAdmin = true;
    } else {
      this.isSiteAdmin = false;
    }
    this.dropdownList = [
      { "id": 1, "itemName": "Male" },
      { "id": 2, "itemName": "Female" },
      { "id": 3, "itemName": "Others" }

    ];
    this.dropdownList2 = [
      { "id": 4, "itemName": "Tradie" },
      { "id": 5, "itemName": "Supplier" }
    ];
    this.settingssingle = {
      text: "Select Gender",
      classes: "myclass custom-class",
      singleSelection: true,
      enableCheckAll: false
    };
    this.settingsUserType = {
      text: "Select User-Type",
      classes: "myclass custom-class",
      singleSelection: true,
      enableCheckAll: false
    };
    if (this.router.url == "/deals") {
      this.dealTosliderOpen = true;
      this.popoverform1 = true;
      this.toggleCertifierMenu();
    }
  }
  ngOnChanges() {
  }

  createForm() {
    this.externalUserForm = this.formBuilder.group({
      domainId: '',
      id: null,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      gender: '',
      isActive: '',
      createdBy: '',
      createdDate: '',
      updatedBy: '',
      entityTypeId: ''
    });
    this.passwordForm = this.formBuilder.group({
      password: '',
      confirmPassword: ''
    });
    this.certifierForm = this.formBuilder.group({
      domainId: '',
      id: null,
      firstName: '',
      lastName: '',
      email: '',
      phone: null,
      gender: '',
      isActive: '',
      createdBy: '',
      createdDate: '',
      updatedBy: ''
    });

  }
  resetForm() {
    this.externalUserForm.reset({
      domainId: '',
      id: null,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      isActive: '',
      createdBy: '',
      gender: [],
      createdDate: '',
      updatedBy: '',
      entityTypeId: []
    });
    this.certifierForm.reset({
      domainId: '',
      id: null,
      firstName: '',
      lastName: '',
      email: '',
      phone: null,
      isActive: '',
      createdBy: '',
      gender: [],
      createdDate: '',
      updatedBy: '',
    });
  }
  onchangtab(value) {
    var id = value.nextId;
    if (id == "ExternalUser") {
      this.getExternalUser();
    } else if (id == "Certifier") {
      this.getCertifier();
    } else if (id == "Council") {
      this.getCouncil();
    }
  }
  //  passwordresetForm() {
  //   this.resetpasswordErr = null;
  //   this.confirmpasswordErr = null;
  //   this.passwordForm.reset({
  //      password: '',
  //      confirmPassword: '',
  //    })
  //  }
  //  passwordValidate() {
  //   this.resetpasswordErr = null;
  //   this.confirmpasswordErr = null;
  //   var data = this.passwordForm.value;
  //   const strongRegex = /(?=.{6,})/;
  //   var valid = true;
  //   if (data.password == Validators || data.password == '') {
  //     this.resetpasswordErr = "Password is required.";
  //     valid = false;
  //   } else if (!strongRegex.test(data.password)) {
  //     this.resetpasswordErr = "Password is Must be 6 Charcters.";
  //     valid = false;
  //   } else if (data.password != data.confirmPassword) {
  //     this.confirmpasswordErr = "Password doesn't Match.";
  //     valid = false;
  //   }
  //   return valid;
  // }

  getExternalUser() {
    this.spinner.show();
    this.externalUserArr = [];
    this.http.get('/api/externalUsers/').subscribe((res: Response) => {
      this.result = res;
      this.externalUserCount = this.result.length;
      this.spinner.hide();
      for (let i = 0; i < this.result.length; i++) {
        this.externalUserArr.push({
          "id": this.result[i].id, "firstName": this.result[i].firstName, "lastName": this.result[i].lastName, "email": this.result[i].email, "phone": this.result[i].phone
        })
      }
    });
  }
  toggleExternalUserMenu() {
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.fnameErr = null;
    this.lnameErr = null;
    this.externalEmailErr = null;
    this.phoneErr = null;
    this.entityErr = null;
    this.isCreate = true;
    this.selectedItems = [];
    this.genderErr = null;
    this.passreset = false
    this.resetForm();
    this.menuStateExternalUser = this.menuStateExternalUser === 'out' ? 'in' : 'out';
  }
  submitExternalUser() {
    var data = this.externalUserForm.value;
    data.createdBy = this.userid;
    if (this.userValidate()) {
      this.spinner.show();
      if (data.gender.length > 0){
        data.gender = data.gender[0].itemName;
      } else {
        data.gender = null;
      }
      data.entityTypeId = data.entityTypeId[0].id;
      this.http.post('/api/externalUser/', data).subscribe((response) => {
        this.toastr.clear();
        this.result = response;
        if (this.result.success) {
          this.resetForm();
          this.toastr.success(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.toggleExternalUserMenu();
          this.getExternalUser();
        } else {
          this.toastr.error(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }

      }, error => {
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
        this.errorService.handleError(error);
      })
    }

  }
  ExternalUserEditMenu(id) {
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.fnameErr = null;
    this.lnameErr = null;
    this.genderErr = null;
    this.externalEmailErr = null;
    this.phoneErr = null;
    this.entityErr = null;
    this.isCreate = false;
    this.externalUserID = id;
    this.http.get('/api/externalUser/' + this.externalUserID).subscribe((res: Response) => {
      this.result = res;
      if (this.result) {
        this.externalUserForm.patchValue({ 'id': this.result.id });
        this.externalUserForm.patchValue({ 'firstName': this.result.firstName });
        this.externalUserForm.patchValue({ 'lastName': this.result.lastName });
        this.externalUserForm.patchValue({ 'email': this.result.email });
        this.externalUserForm.patchValue({ 'phone': this.result.phone });
        this.externalUserForm.patchValue({ 'createdBy': this.result.createdBy });
        this.externalUserForm.patchValue({ 'isActive': this.result.isActive });
        this.externalUserForm.patchValue({ 'createdDate': this.result.createdDate });
        this.externalUserForm.patchValue({ 'domainId': this.result.domainId });
        if (this.result.gender == "Male") {
          this.selectedItems = [];
          this.selectedItems.push({ "id": 1, "itemName": "Male" })
        } else if (this.result.gender == "Female") {
          this.selectedItems = [];
          this.selectedItems.push({ "id": 2, "itemName": "Female" })
        }
        else if (this.result.gender == "Others") {
          this.selectedItems = [];
          this.selectedItems.push({ "id": 3, "itemName": "Others" })
        }

        if (this.result.entityTypeId == "4") {
          this.selectedItemsExternal = [];
          this.selectedItemsExternal.push({ "id": 4, "itemName": "Tradie" })
        } else if (this.result.entityTypeId == "5") {
          this.selectedItemsExternal = [];
          this.selectedItemsExternal.push({ "id": 5, "itemName": "Supplier" })
        }
      }
    });
    this.menuStateExternalUser = this.menuStateExternalUser === 'out' ? 'in' : 'out';
  }
  updateExternalUser() {
    var data = this.externalUserForm.value;
    data.updatedBy = this.userid;
    if (this.userValidate()) {
      this.spinner.show();
      if (data.gender.length > 0){
        data.gender = data.gender[0].itemName;
      } else {
        data.gender = null;
      }
      data.entityTypeId = data.entityTypeId[0].id;
      this.http.put('/api/externalUser/', data).subscribe((response) => {
        this.toastr.clear();
        this.result = response;
        if (this.result.success) {
          this.resetForm();
          this.toastr.success(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.toggleExternalUserMenu();
          this.getExternalUser();
          this.popoverform = false;
        } else {

          this.toastr.error(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }

      }, error => {
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
        this.errorService.handleError(error);
      })
    }
  }
  externalUserDelete(id) {
    this.menuStateExternalUser = this.menuStateExternalUser === 'out' ? 'in' : 'out';
    this.confirmService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) => {
        if (confirmed) {
          this.spinner.show();
          this.http.delete('/api/externalUser/' + id).subscribe((response) => {
            this.toastr.clear();
            this.result = response;
            if (this.result.success) {
              this.resetForm();
              this.toastr.success(this.result.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.getExternalUser();
              // this.toggleExternalUserMenu();
              this.popoverform = false;
            } else {
              this.toastr.error(this.result.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
            }
          }, error => {
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
            this.errorService.handleError(error);
          })
        } else {
        }
      })
      .catch(() => {
        this.menuStateExternalUser = this.menuStateExternalUser === 'out' ? 'in' : 'out';
        return false;
      });
  }
  userValidate() {
    this.fnameErr = null;
    this.lnameErr = null;
    this.genderErr = null;
    this.externalEmailErr = null;
    this.phoneErr = null;
    this.entityErr = null;
    var data = this.externalUserForm.value;
    const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const validNumber = /^\d+$/;
    const strongRegex = /(?=.{6,})/;
    var valid = true;
    if (data.firstName == undefined || data.firstName == '') {
      this.fnameErr = "First Name is required.";
      valid = false;
    }
    else if (data.lastName == undefined || data.lastName == '') {
      this.lnameErr = "Last Name is required.";
      valid = false;
    }
    else if (data.email == EmailValidator || data.email == '') {
      this.externalEmailErr = "Email is required.";
      valid = false;
    } else if (!validEmailRegEx.test(data.email)) {
      this.externalEmailErr = "Invalid Email";
      valid = false;
    } else if (data.entityTypeId.length == 0) {
      this.entityErr = "User-Type is Required.";
      valid = false;
    }  else if (data.phone != '' && !validNumber.test(data.phone)) {
      this.phoneErr = "Invalid Phone Number";
      valid = false;
    }
    return valid;
  }
  restpasslist: boolean = false;
  restpass() {
    if (this.passreset == true) {
      this.passreset = false;
    } else {
      this.passreset = true;
    }
  }
  passwordPost(externalUserID) {
    if (this.passwordValidate()) {
      this.spinner.show();
      this.http.get('/api/externalUser/' + externalUserID).subscribe((res: Response) => {
        this.result = res;
        var data = this.result;
        var data1 = this.passwordForm.value;
        data.password = data1.confirmPassword;
        data.updatedBy = parseInt(this.userid);
        this.http.put('/api/externalUser/', data).subscribe((response) => {
          this.toastr.clear();
          this.result1 = response;
          if (this.result1.success) {
            this.externalUserID = this.result1.response.id
            this.passwordresetForm();
            this.toastr.success('Password Updated successfully', 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.restpass();
            this.spinner.hide();
          } else {
            this.toastr.error(this.result1.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
          }
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })
      })
    }
  }


  passwordresetForm() {
    this.resetpasswordErr = null;
    this.confirmpasswordErr = null;
    this.passwordForm.reset({
      password: '',
      confirmPassword: '',
    })
  }
  passwordValidate() {
    this.resetpasswordErr = null;
    this.confirmpasswordErr = null;
    var data = this.passwordForm.value;
    const strongRegex = /(?=.{6,})/;
    var valid = true;
    if (data.password == Validators || data.password == '') {
      this.resetpasswordErr = "Password is required.";
      valid = false;
    } else if (!strongRegex.test(data.password)) {
      this.resetpasswordErr = "Password is Must be 6 Charcters.";
      valid = false;
    } else if (data.password != data.confirmPassword) {
      this.confirmpasswordErr = "Password doesn't Match.";
      valid = false;
    }
    return valid;
  }

  getCertifier() {
    this.certifier = true;
    this.council = false;
    this.spinner.show();
    this.certifierArr = [];
    this.http.get('/api/certifiers/').subscribe((res: Response) => {
      this.result = res;
      this.certifiersCount = this.result.length;
      this.spinner.hide();
      for (let i = 0; i < this.result.length; i++) {
        this.certifierArr.push({
          "id": this.result[i].id, "firstName": this.result[i].firstName, "lastName": this.result[i].lastName, "email": this.result[i].email, "phone": this.result[i].phone
        })
      }
    });
  }
  getCouncil() {
    this.certifier = false;
    this.council = true;
    this.spinner.show();
    this.certifierArr = [];
    this.http.get('/api/council/').subscribe((res: Response) => {
      this.result = res;
      this.certifiersCount = this.result.length;
      this.spinner.hide();
      for (let i = 0; i < this.result.length; i++) {
        this.certifierArr.push({
          "id": this.result[i].id, "firstName": this.result[i].firstName, "lastName": this.result[i].lastName, "email": this.result[i].email, "phone": this.result[i].phone
        })
      }
    });
  }
  toggleCertifierMenu() {
    this.certifier = true;
    this.council = false;
    this.toggleCertiferCouncilMenu();
  }
  toggleCouncilMenu() {
    this.council = true;
    this.certifier = false;
    this.toggleCertiferCouncilMenu();
  }

  toggleCertiferCouncilMenu(){
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.fnameErr = null;
    this.lnameErr = null;
    this.certifiemailErr = null;
    this.phoneErr = null;
    this.isCreate = true;
    this.selectedItems = [];
    this.genderErr = null;
    this.passreset = false
    this.resetForm();
    this.menuStateCertifier = this.menuStateCertifier === 'out' ? 'in' : 'out';
    if (this.certifier) {
      this.getCertifier();
    }
    if (this.council) {
      this.getCouncil();
    }
  }
  submitCertifier() {
    var data = this.certifierForm.value;
    data.createdBy = this.userid;
    if (data.phone == "" || data.phone == null) {
      data.phone = null;
    } else {
      data.phone = data.phone;
    }
    if (this.certifierValidate()) {
      this.spinner.show();
      if (data.gender.length > 0){
        data.gender = data.gender[0].itemName;
      } else {
        data.gender = null;
      }
      var apiUrl = '/api/certifier/';
      if (this.council) {
        apiUrl = '/api/council/'
      }
      this.http.post(apiUrl, data).subscribe((response) => {
        this.toastr.clear();
        this.result = response;
        if (this.result.success) {
          this.resetForm();
          this.toastr.success(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          if (this.router.url == "/deals") {
            window.location.reload();
          }
          if (this.certifier) {
            this.toggleCertifierMenu();
            // this.getCertifier();
          }
          if (this.council) {
            this.toggleCouncilMenu();
            // this.getCouncil();
          }
        } else {
          this.toastr.error(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }

      }, error => {
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
        this.errorService.handleError(error);
      })
    }

  }
  certifierEditMenu(id) {
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.fnameErr = null;
    this.lnameErr = null;
    this.genderErr = null;
    this.certifiemailErr = null;
    this.phoneErr = null;
    this.isCreate = false;
    this.certifierID = id;
    var apiUrl = '/api/certifier/';
    if (this.council) {
      apiUrl = '/api/council/'
    }
    this.http.get(apiUrl + this.certifierID).subscribe((res: Response) => {
      this.result = res;
      if (this.result) {
        this.certifierForm.patchValue({ 'id': this.result.id });
        this.certifierForm.patchValue({ 'firstName': this.result.firstName });
        this.certifierForm.patchValue({ 'lastName': this.result.lastName });
        this.certifierForm.patchValue({ 'email': this.result.email });
        this.certifierForm.patchValue({ 'phone': this.result.phone });
        this.certifierForm.patchValue({ 'createdBy': this.result.createdBy });
        this.certifierForm.patchValue({ 'isActive': this.result.isActive });
        this.certifierForm.patchValue({ 'createdDate': this.result.createdDate });
        this.certifierForm.patchValue({ 'domainId': this.result.domainId });
        if (this.result.gender == "Male") {
          this.selectedItems = [];
          this.selectedItems.push({ "id": 1, "itemName": "Male" })
        } else if (this.result.gender == "Female") {
          this.selectedItems = [];
          this.selectedItems.push({ "id": 2, "itemName": "Female" })
        }
        else if (this.result.gender == "Others") {
          this.selectedItems = [];
          this.selectedItems.push({ "id": 3, "itemName": "Others" })
        }
      }
    });
    this.menuStateCertifier = this.menuStateCertifier === 'out' ? 'in' : 'out';
  }
  updateCertifier() {
    var data = this.certifierForm.value;
    data.updatedBy = this.userid;
    if (this.certifierValidate()) {
      this.spinner.show();
      if (data.gender.length > 0){
        data.gender = data.gender[0].itemName;
      } else {
        data.gender = null;
      }
      var apiUrl = '/api/certifier/';
      if (this.council) {
        apiUrl = '/api/council/'
      }
      this.http.put(apiUrl, data).subscribe((response) => {
        this.toastr.clear();
        this.result = response;
        if (this.result.success) {
          this.resetForm();
          this.toastr.success(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          if (this.certifier) {
            this.toggleCertifierMenu();
            //this.getCertifier();
          }
          if (this.council) {
            this.toggleCouncilMenu();
            //this.getCouncil();
          }
          this.popoverform = false;
        } else {

          this.toastr.error(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }

      }, error => {
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
        this.errorService.handleError(error);
      })
    }
  }
  certifierDelete(id) {
    this.menuStateCertifier = this.menuStateCertifier === 'out' ? 'in' : 'out';
    this.confirmService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) => {
        if (confirmed) {
          this.spinner.show();
          var apiUrl = '/api/certifier/';
          if (this.council) {
            apiUrl = '/api/council/'
          }
          this.http.delete(apiUrl + id).subscribe((response) => {
            this.toastr.clear();
            this.result = response;
            if (this.result.success) {
              this.resetForm();
              this.toastr.success(this.result.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              // this.getCertifier();
              // this.toggleCertifierMenu();
              if (this.certifier) {
                this.getCertifier();
              }
              if (this.council) {
                this.getCouncil();
              }
              this.popoverform = false;
            } else {
              this.toastr.error(this.result.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
            }
          }, error => {
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
            this.errorService.handleError(error);
          })
        } else {
        }
      })
      .catch(() => {
        this.menuStateCertifier = this.menuStateCertifier === 'out' ? 'in' : 'out';
        return false;
      });
  }
  certifierValidate() {
    this.fnameErr = null;
    this.lnameErr = null;
    this.genderErr = null;
    this.certifiemailErr = null;
    this.phoneErr = null;
    var data = this.certifierForm.value;
    const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const validNumber = /^\d+$/;
    const strongRegex = /(?=.{6,})/;
    var valid = true;
    if (data.firstName == undefined || data.firstName == '') {
      this.fnameErr = "First Name is required.";
      valid = false;
    }
    else if (data.lastName == undefined || data.lastName == '') {
      this.lnameErr = "Last Name is required.";
      valid = false;
    }
    else if (data.email == EmailValidator || data.email == '') {
      this.certifiemailErr = "Email is required.";
      valid = false;
    } else if (!validEmailRegEx.test(data.email)) {
      this.certifiemailErr = "Invalid Email";
      valid = false;
    }
    if (data.phone) {
      if (data.phone != '' && !validNumber.test(data.phone)) {
        this.phoneErr = "Invalid Phone Number.";
        valid = false;
      }
    }
    //  else if(data.phone != '' && !validNumber.test(data.phone)) {
    //     this.phoneErr = "Invalid Phone Number";
    //     valid = false;
    //   }
    return valid;
  }
  // restpasslist: boolean = false;
  // restpass(){
  //   if (this.passreset == true) {
  //     this.passreset = false;
  //   } else {
  //     this.passreset = true;
  //   }
  // }
  passwordPostCertifier(certifierID) {
    if (this.passwordValidate()) {
      this.spinner.show();
      var apiUrl = '/api/certifier/';
      if (this.council) {
        apiUrl = '/api/council/'
      }
      this.http.get(apiUrl + certifierID).subscribe((res: Response) => {
        this.result = res;
        var data = this.result;
        var data1 = this.passwordForm.value;
        data.password = data1.confirmPassword;
        data.updatedBy = parseInt(this.userid);
        this.http.put(apiUrl, data).subscribe((response) => {
          this.toastr.clear();
          this.result1 = response;
          if (this.result1.success) {
            this.certifierID = this.result1.response.id
            this.passwordresetForm();
            this.toastr.success('Password Updated successfully', 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.restpass();
            this.spinner.hide();
          } else {
            this.toastr.error(this.result1.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
          }
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })
      })
    }
  }
  toggleCertifierMenuClose() {
    window.location.reload();
  }

}

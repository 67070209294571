import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
  result :any;
  ProjectName :any;

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.getProjectName();
  }

  getProjectName(){
    this.http.get('/unsecure/app/name').subscribe((res: Response) => {
      this.result = res;
      this.ProjectName = this.result.aname;
    });
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { DetailsService } from '../_services/index';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { ErrorService } from 'src/app/_services/error.service';
import { PermissionService } from 'src/app/_services/permission.service';
import { trigger, state, animate, transition, style } from '@angular/animations';
import { ToastrService } from 'ngx-toastr';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { Router, CanActivate } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';


import * as moment from 'moment/moment.js';


@Component({
  selector: 'app-approvals-details',
  templateUrl: './approvals-details.component.html',
  styleUrls: ['./approvals-details.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})

export class ApprovalsDetailsComponent implements OnInit {
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '25rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    customClasses: [{ name: "quote", class: "quote", },
    { name: 'redText', class: 'redText' },
    { name: "titleText", class: "titleText", tag: "h1", },]
  };
  approvalStateArr = [];
  result: any;
  step:any;
  tab01: boolean = true;
  tab02: boolean = false;
  result0: any;
  details: any;
  results: any;
  isSubmit: boolean = false;
  isComplete: boolean = false;
  oid: any;
  current: number = 0;
  items: Array<any>;
  // ------------------------------------
  // customer: any;
  err: { 'name': '', 'desc': '', };
  nameErr: any;
  notes: any;
  opened = false;
  openeds = false;
  projectTask: any;
  projectlog: any;
  allActivity: any;
  taskLog: any;
  noteLog: any;
  projectFromState: any;
  projectToState: any;
  approvalproArr = [];
  task: any;
  noteLogArr = [];
  taskLogArr = [];
  usersArr = [];
  rolesArr = [];
  orgsArr = [];
  taskArr = [];
  noteArr = [];
  allActivityArr = [];
  dateCountArr = [];
  projectlogArr = [];
  uploadFileArr = [];
  checklistArr = [];
  uploadFilesArr = [];
  isCreate: boolean = true;
  fileToUpload: any;
  role: any;
  users = {};
  projectLogs: any;
  projectUsers: any;
  roles = {};
  orgs = {};
  tid: any;
  projectStId: any;
  projectId: any;
  userid: any;
  fileErr: any
  settings = {};
  settingssingle = {};
  orgExists = false;
  signpat = false;
  userExists = false;
  selectedFile: File
  checkid: any;
  approvalCheckId: any;
  projectUserRoleErr: boolean = false;
  projectUserErr = null;
  orgErr = null;
  noteHeadingErr = null;
  noteDescErr = null;
  projectUserForm: FormGroup;
  notesForm: FormGroup;
  orgForm: FormGroup;
  uploadFileForm: FormGroup;
  form: FormGroup;
  menuStateDealEdit: string = 'out';
  menuStateProjectUserEdit: string = 'out';
  menuStatesnotes: string = 'out';
  menuStateOrgEdit: string = 'out';
  addTask: string = 'out';
  menuState: string = 'out';
  loggedInUserDomain: any;
  dealstateArr = [];
  dealStates = {};
  projectStateIdOrder = -1;
  i: any;
  nid: any;
  body: {};
  _body: any;
  projectComplete:any;
  printPdfDis: boolean = true;
  pdfHeadingEnable = false;
  addCheckDis: boolean = false;
  isAdminDis: boolean = false;
  isApprovalAdminOnly: boolean = false;
  approvalpop: boolean = false;
  isApproval: boolean = true;
  isSuperAdmin: boolean = false;
  admin: any;
  custArr = [];
  userArr = [];
  detailsArr = [];
  projectUserArr = [];
  result1 :any;
  result2:any;
  result3 :any;
  result4 :any;
  res2 :any
  res :any;
  res1 :any;
  result5 :any;
  certMang :any;
  popoverformorg: boolean = false;
  popoverforcustomer: boolean = false;
  popoverformperson:boolean=false;
  popoverformapproval: boolean = false;
  custForm: FormGroup;
  menuStateCustomerEdit: string = 'out';
  customerAllArrselect = [];
  customersArr = [];
  customerAllDetails :any;
  customerAllDetailsArr =[];
  custArrDe =[];
  allCustomer=[];
  customerErr :any;
  orgAllDetails:any;
  myFiles:any = [];
  filesUploadArr:any = [];
  rolesPermissionArr :any;

  keypeopleForm: any;
  allCertifiers:any = [];
  allCouncil: any = [];
  keypeopleAllArrselect:any = [];
  keypeopleAllArrselect1:any = [];
  certifierErr = '';
  councilErr = '';
  popoverforkeypeople: any;
  menuStateKeypeopleEdit: string = 'out';
  internaluser: boolean = false;
  is_notes: boolean = false;
  notesUserForm: FormGroup;
  is_delete: boolean = false;
  noteCreatedAnddeleteUSer: boolean = false;
  menuStateNotesUserEdit: string = 'out';
  eventListSelected: any;
  noteTypeList = [];
  maxChars = 255;
  approvalType :string = "approvals";

    constructor(private DetailsService: DetailsService, private route: ActivatedRoute,
    private spinner: NgxSpinnerService, private toastr: ToastrService,
    private errorService: ErrorService,
    private confirmService: ConfirmService,
    private formBuilder: FormBuilder,
    private permissionService: PermissionService,private http: HttpClient,
    public router: Router,
  ) {
    this.userid = localStorage.getItem("loggedInUserID");
    this.settings = {
      singleSelection: true
    }
    this.eventListSelected = [];
    this.settingssingle = {
      text: "Select",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      singleSelection: true,
      enableCheckAll: false
    }
    this.step='step1';
  }

  ngOnInit() {
    this.admin = localStorage.getItem("loggedInUserAdmin");
    this.loggedInUserDomain = localStorage.getItem('loggedInDomainID');
    this.noteTypeList = [
      { "id": 2, "itemName": "Internal" },
      { "id": 3, "itemName": "External" },
    ];
    this.getApprovalStates();
    this.createForms();
    //this.getPermissionDetails();
    this.getprojectinfo();
    this.customerGetCall();

  }

  toggleCreateMenu(id, num) {

    this.fileErr = null;
    if (num == 0) {
      this.uploadFileArr = [];
      this.checklistArr = [];
      this.getdocuments(id);
    }
     this.myFiles = [];
    this.filesUploadArr=[];
    this.opened = false;
    this.isCreate = true;
    this.oid = undefined;
    if (this.menuState == 'in') {
      this.approvalpop = false
    }
    this.menuState = this.menuState === 'out' ? 'in' : 'out';

  }

  async getdocuments(Checkid) {
    this.uploadFileArr = [];
    this.checkid = Checkid;
    this.checklistArr.push({
      "CheckId": this.checkid
    });
    this.http.get('api/approvallist/documents/' + this.checkid).subscribe((res: Response) => {
      this.result = res;
      for (let i = 0; i < this.result.length; i++) {
        this.uploadFileArr.push({
          "FileId": this.result[i].id, "Filename": this.result[i].uploadName, "FileLocation": this.result[i].location, "uploadedDate": this.result[i].uploadedDate, "CheckId": this.checkid
        })
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });

  }
  getPermissionDetails() {
    this.http.get('/api/user/' + this.userid).subscribe((res: Response) => {
      this.result = res;
      var permision = this.result.role.permision;
      if (permision.length > 0) {
        var projectPermission = this.permissionService.getProjectPermission(permision);
        var dealPermission = this.permissionService.getDealPermission(permision);
        var approvalPermission = this.permissionService.getApprovalPermission(permision);
        if (projectPermission == this.permissionService.PROJECT_ADMIN ||
          dealPermission == this.permissionService.DEAL_ADMIN ||
          approvalPermission == this.permissionService.APPROVAL_ADMIN ||
          this.admin == "Y" ||
          this.userid == this.details.managerId) {
          this.isAdminDis = true;
          this.isApproval = true;
          if(approvalPermission == this.permissionService.APPROVAL_ADMIN || this.admin == "Y"){
            this.isApprovalAdminOnly = true;
          }
        }  else if (projectPermission == this.permissionService.PROJECT_OBSERVER || dealPermission == this.permissionService.DEAL_OBSERVER || approvalPermission == this.permissionService.APPROVAL_OBSERVER) {
          this.isApproval = false;
        }
      }
      if(this.admin == 'Y'){
        this.isSuperAdmin = true;
      }else{
        this.isSuperAdmin = false;
      }

    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }

  async getApprovalStates() {
   this.projectComplete=[];
   const id = +this.route.snapshot.paramMap.get('id');
   this.projectId = id;
   var value1 = await this.getDetails();
   this.getPermissionDetails();
   this.approvalType = this.details.approvalType;
   if(this.details.approvalCompleted == "T"){
    var url = 'api/projectStates/' + this.approvalType + '/'+this.details.id+'/completed'
  }else{
     var url = 'api/projectStates/' + this.approvalType ;
  }
   var value = await this.getcompleted();
   this.projectComplete = value;
   this.http.get(url).subscribe((res: Response) => {
    this.result4 = res;
     if ( this.result4) {
       this.spinner.show();
       this.getcompletedstatus( this.result4);
       var val = false;
       for (let i = 0; i <  this.result4.length; i++) {
           val = false;
           var checklistnote="";
           for (let j = 0; j < this.projectComplete.length; j++) {
             if ( this.result4[i].id == this.projectComplete[j].projectStateId) {
               var approvalcheckId = this.projectComplete[j].id;
               checklistnote = this.projectComplete[j].note;
               if (this.projectComplete[j].isCompleted == "Y") {
                 val = true;
               }
               break;
             }
           }
            this.approvalStateArr.push({
              "approvalStatesName": this.result4[i].name, "approvalStatesId": this.result4[i].id, "approvalstatus": val, "approvalcheckId": approvalcheckId, "checklistnote": checklistnote
            })
          }
       }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  getcompleted(){
    return this.http.get('api/approvallist/' + this.projectId).toPromise();
  }
  async getcompletedstatus(result1) {
     this.result2 =[];
    var value2 = await this.getcompleted();
    this.result2 = value2;
      var completecount = 0;
      for (let j = 0; j < this.result2.length; j++) {
        if (this.result2[j].isCompleted == "Y") {
          completecount++;
        }
        if (result1.length == completecount) {
          if (this.details.approvalCompleted == "T") {
            this.isComplete = true;
            this.isSubmit = false;
            this.openeds = true;
            this.signpat = true;
            this.form.patchValue({ 'approvedBy': this.details.approvedBy });
          } else {
            this.isComplete = false;
            this.isSubmit = true;
            this.openeds = false;
            this.signpat = false;
          }
        } else {
          this.isComplete = false;
          this.isSubmit = false;
          this.openeds = false;
          this.signpat = false;
        }
      }
      this.spinner.hide();
  }
  createForms() {
    this.projectUserForm = this.formBuilder.group({
      userId: null,
      roleId: null
    });
    this.uploadFileForm = this.formBuilder.group({
      file: ''
    })
    this.custForm = this.formBuilder.group({
      customerId: ''
    });
    this.keypeopleForm = this.formBuilder.group({
      certifierId: '',
      councilId: ''
    });
    this.orgForm = this.formBuilder.group({
      orgId: null
    });
    this.form = this.formBuilder.group({
      approvedBy: ['', Validators.required],


    })
    this.notesForm = this.formBuilder.group({
      note: '',
      projectId: '',
      projectStateId: '',
      createdDate: '',
      id: '',
      isCompleted: '',
      isActive: ''
    })
    this.notesUserForm = this.formBuilder.group({
      domainId: '',
      viewType: [],
      description: '',
      heading: '',
      userId: '',
      projectId: '',
      createdAt: '',
      updatedAt: '',
      createdBy: '',
      updatedBy: ''
})
  }
  fileRest() {
    this.uploadFileForm.reset({
      file: ''
    })
  }



  resetForms() {
    this.projectUserForm.reset({
      userId: [],
      roleId: []
    })
    this.orgForm.reset({
      orgId: []
    })
    this.form.reset({
      approvedBy: '',

    })
    this.custForm.reset({
      customerId: []
    });
    this.keypeopleForm.reset({
      certifierId: [],
      councilId: []
    });
    this.notesForm.reset({
      note: null,
      projectId: null,
      projectStateId: null,
      createdDate: null,
      id: null,
      isCompleted: null,
      isActive: null
    })
    this.notesUserForm.reset({
      description: '',
      heading: ''
})
  }



  async getDetails() {
    const id = +this.route.snapshot.paramMap.get('id');
    this.projectId = id;
    this.roleDetails(this.projectId);
    var result = await this.DetailsService.getDealStates();
    this.result1= result;
    this.result1.forEach(r => {
      this.dealStates[r.id] = r;
      this.dealstateArr.push({
        "id": r.id, "itemName": r.name, "orders": r.orders
      })

    });

    result = await this.DetailsService.getDetailsID(id);
    this.details = result;


    if (this.details.orgId) { this.orgExists = true; }
    var projStateId = this.details.projectStateId;
    this.projectStateIdOrder = this.dealStates[projStateId].orders;
    result = await this.DetailsService.getUsers();
    this.result1 = result;
    // this.detailsArr = [];
    this.projectUserArr = this.result1;
    if (result) {
      this.users = {};
      this.result1.forEach(r => {
        this.users[r.details.id] = r.details;
        // this.detailsArr.push(r.details.id);
      });
    } else {
      this.spinner.hide();
    }
    result = await this.DetailsService.rolesPermission("APPROVAL");
    this.rolesPermissionArr = result;
    this.detailsArr = [];
    this.projectUserArr = this.rolesPermissionArr;
    if (this.rolesPermissionArr) {
      this.rolesPermissionArr.forEach(r => {
        this.detailsArr.push(r.id);
      });
    }


    result = await this.DetailsService.getRoles();
    this.result1 = result;
    this.roles = {};
    this.result1.forEach(r => {
      this.roles[r.role.id] = r.role;
      this.rolesArr.push({
        "id": r.role.id, "itemName": r.role.name
      })
    })
    result = await this.DetailsService.getOrgs();
    this.result1 = result;
    if (this.result1) {
      this.orgsArr = [];
      this.orgs = {};
      this.result1.forEach(r => {
        this.orgs[r.id] = r;
        this.orgsArr.push({
          "id": r.id, "itemName": r.orgName
        })
      })
    } else {
      this.spinner.hide();
    }
   // this.customerDetials(this.details.customerId);
   this.orgsDetails(id);

  }

  arr_diff(a1, a2) {
    var a = [], diff = [];

    for (var i = 0; i < a1.length; i++) {
      a[a1[i]] = true;
    }

    for (var i = 0; i < a2.length; i++) {
      if (a[a2[i]]) {
        delete a[a2[i]];
      } else {
        a[a2[i]] = true;
      }
    }

    for (var k in a) {
      diff.push(k);
    }

    return diff;
  }
  arr_diff_cust(a1, a2) {
    var a = [], diff = [];

    for (var i = 0; i < a1.length; i++) {
      a[a1[i]] = true;
    }

    for (var i = 0; i < a2.length; i++) {
      if (a[a2[i]]) {
        delete a[a2[i]];
      } else {
        a[a2[i]] = true;
      }
    }

    for (var k in a) {
      diff.push(k);
    }

    return diff;
  }
  async getDetailsOnly() {
    const id = +this.route.snapshot.paramMap.get('id');
    this.projectId = id;
    var result = await this.DetailsService.getDetailsID(id);
    this.details = result;
    if (this.details.orgId) { this.orgExists = true; }
  }
  async roleDetails(id) {
    var result = await this.DetailsService.getProjectsUser(id);
    this.projectUsers = result;
    if (this.projectUsers == []) { this.userExists = true; }
    this.userArr = [];
    for (let i = 0; i < this.projectUsers.length; i++) {
      if (this.projectUsers[i].userId == parseInt(this.userid)) {
        this.internaluser = true;
      }
      this.userArr.push(this.projectUsers[i].userId);
    }
    this.getnotes();
  }
   orgsDetails(id){
     this.http.get('/api/project/details/org/' + id).subscribe((res: Response) => {
        this.result= res;
        this.orgAllDetails=this.result.Organization;
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
  }

  async customerDetials(id) {

    // var result = await this.DetailsService.getCustomerID(id);
    // if (result) {
    //   this.customer = result;

    // }
    // this.spinner.hide();
  }

  toggleAddProjectOrg() {
    if (this.popoverformorg == true) {
      this.popoverformorg = false;
    } else {
      this.popoverformorg = true;
    }
    this.orgErr = null;
    this.resetForms();
    if (this.menuStateProjectUserEdit == 'in') {
      this.menuStateProjectUserEdit = 'out';
    }
    this.menuStateOrgEdit = this.menuStateOrgEdit === 'out' ? 'in' : 'out';
  }
  // getCust() {
  //   const id = +this.route.snapshot.paramMap.get('id');
  //   this.projectId = id;
  //   this.http.get('/api/customer/project/' + this.projectId).subscribe((res: Response) => {
  //     this.result = res;
  //     this.custArr.push({
  //       "id": this.result.details.id, "custName": this.result.details.firstName + " " + this.result.details.lastName, "city": this.result.location.city
  //     })

  //   }, error => {
  //     this.toastr.clear();
  //     this.toastr.error(error, 'Report Status', {
  //       timeOut: 6000,
  //       closeButton: true
  //     });
  //     this.errorService.handleError(error);
  //   });
  // }



  submitOrg() {
    var post = {};
    if (this.orgValidate()) {
      this.spinner.show();
      var data = this.orgForm.value;
      this.details.orgId = data.orgId[0].id;
      this.details.updatedBy = this.userid;
      this.http.put('/api/project', this.details).subscribe((response) => {
        this.spinner.show();
        this.res = response;
        if (this.res.success == true) {
          this.toastr.clear();
          this.toastr.success(this.res.message, 'Success', {
            timeOut: 6000,
            closeButton: true
          });
          this.getDetailsOnly();
          this.spinner.hide();
          this.toggleAddProjectOrg();
        } else {
          this.toastr.clear();
          this.toastr.error(this.res.message, 'Error', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
        this.toggleAddProjectOrg();
      })
    }
  }



  orgValidate() {
    this.orgErr = null;
    var valid = true;
    var data = this.orgForm.value;
    if (data.orgId.length == 0) {
      this.orgErr = "Organization is required";
      valid = false;
    }
    return valid;
  }


  removeOrg() {
    this.confirmService.confirm('Please confirm..', 'Do you want to detach the Organization ?')
      .then((confirmed) => {
        if (confirmed) {
          this.spinner.show();
          this.details.orgId = null;
          this.http.put('/api/project', this.details).subscribe((response) => {
            this.res = response;
            if (this.res.success == true) {
              this.toastr.clear();
              this.toastr.success(this.res.message, 'Success', {
                timeOut: 6000,
                closeButton: true
              });
              this.getDetailsOnly();
              this.spinner.hide();
            } else {
              this.toastr.clear();
              this.toastr.error(this.res.message, 'Error', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
            }
          }, error => {
            this.toastr.clear();
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.errorService.handleError(error);
          });
        }
      })
      .catch(() => {
        return false;
      });
  }
  // end organization





  toggleAddProjectUser(number) {
    if (this.popoverformperson == true) {
      this.popoverformperson = false;
    } else {
      this.popoverformperson = true;
    }
    this.usersArr = [];
    this.projectUserErr = null;
    this.resetForms();
    if (number == 0) {
      var result2 = this.arr_diff(this.detailsArr, this.userArr);
      this.projectUserArr.forEach(r => {
        for (let i = 0; i < result2.length; i++) {
          if (result2[i] == r.id) {
            this.usersArr.push({
              "id": r.id, "itemName": r.firstName + " " + r.lastName + " - " + r.roleName,"roleId": r.role, "roleName": r.roleName
            })
          }
        }
      });
    }
    if (this.menuStateOrgEdit == 'in') {
      this.menuStateOrgEdit = 'out';
    }

    this.menuStateProjectUserEdit = this.menuStateProjectUserEdit === 'out' ? 'in' : 'out';
  }



  submitProjectUser() {
    var data1 = {};
    if (this.projectUserValidate()) {
      this.spinner.show();
      var data = this.projectUserForm.value;
      data1 = { "userId": data.userId[0].id, "roleId": data.userId[0].roleId, "projectId": this.projectId, "createdBy": this.userid }
      this.http.post('/api/projectUserRole', data1).subscribe((response) => {
        this.res = response;
        if (this.res.success == true) {
          this.toastr.clear();
          this.toastr.success(this.res.message, 'Success', {
            timeOut: 6000,
            closeButton: true
          });
          this.roleDetails(this.projectId);
          this.spinner.hide();
          this.toggleAddProjectUser(1);
          this.getDetails();
          this.getDetailsOnly();
          this.dealstateArr = [];
          this.spinner.hide();
        } else {
          this.toastr.clear();
          this.toastr.error(this.res.message, 'Error', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
        this.toggleAddProjectUser(1);
      })
    }
  }


  projectUserValidate() {
    this.projectUserErr = null;
    var valid = true;
    var data = this.projectUserForm.value;
    if (data.userId.length == 0) {
      this.projectUserErr = "User is required";
      valid = false;
    }
    // if (!data.roleId) {
    //   this.projectUserRoleErr = "Role is required";
    //   valid = false;
    // }
    return valid;
  }


  RemoveUserFromProject(id) {
    this.confirmService.confirm('Please confirm..', 'Do you want to remove the user ?')
      .then((confirmed) => {
        if (confirmed) {
          window.scrollTo(0, 0);
          this.spinner.show();
          this.http.delete('/api/projectUserRole/' + id).subscribe((response) => {
            this.res= response;
            if (this.res.success == true) {
              this.toastr.clear();
              this.toastr.success(this.res.message, 'Success', {
                timeOut: 6000,
                closeButton: true
              });
              this.roleDetails(this.projectId);
              this.getDetails();
              this.getDetailsOnly();
              this.dealstateArr = [];
              // this.spinner.hide();
            } else {
              this.toastr.clear();
              this.toastr.error(this.res.message, 'Error', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
            }
          }, error => {
            this.toastr.clear();
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.errorService.handleError(error);
          });
        }
      })
      .catch(() => {
        return false;
      });
  }


  check(projectStateId, numb) {
    if (this.approvalpop == true) {
      this.approvalpop = false;
    } else {
      this.approvalpop = true;
    }
      if (numb == 0) {
     window.scrollTo(0, 0);

      const id = +this.route.snapshot.paramMap.get('id');
      this.projectId = id;
      this.projectStId = projectStateId;
       this.http.get('/api/approvallist/' + this.projectId + '/' + this.projectStId).subscribe((res: Response) => {
        this.result0 = res;
        if (this.result0.data != null) {
          this.fatchdatenote(this.result0.data);
          this.toggleCreateMenu(this.result0.data.id, 0);
        } else if (this.result0.data == null) {
          this.onApproval(this.projectStId, 1)
        } else {
        this.onApproval(this.projectStId, 1)
        }
        this.spinner.hide();
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
    } else {
      window.scrollTo(0, 0);
      const id = +this.route.snapshot.paramMap.get('id');
      this.projectId = id;
      this.projectStId = projectStateId;
      this.http.get('/api/approvallist/' + this.projectId + '/' + this.projectStId).subscribe((res: Response) => {
        this.result = res
        if (this.result.data != null) {
          this.onApprovalComplete(this.result.data)
        } else if (this.result.data == null) {
          this.onApproval(this.projectStId, 0)
        } else {
          this.onApproval(this.projectStId, 1)
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
    }
  }
  onApproval(projectStateId, num) {

    const id = +this.route.snapshot.paramMap.get('id');
    this.projectId = id;
    this.projectStId = projectStateId;
    var post = {};
    this.http.post('/api/approvallist/' + this.projectId + '/' + this.projectStId, post).subscribe((response) => {
      this.res = response;
      if (this.res.success == true) {
        if (num == 0) {
          this.onApprovalComplete(this.res.response);
        } else {
          this.spinner.hide();
          this.getDetails();
          this.getDetailsOnly();
          this.getApprovalStates();
          this.approvalStateArr = [];
          this.dealstateArr = [];
          this.toggleCreateMenu(this.res.response.id, 0);
          this.fatchdatenote(this.res.response);
        }
      }
       else {
        this.toastr.clear();
        this.toastr.error(this.res.message, 'Error', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });


  }

  onApprovalComplete(data) {

    this.spinner.show()
    var post = data;
    if (data.isCompleted == "N") {
      post.isCompleted = "Y";
    } else {
      post.isCompleted = "N";
    }
    this.http.put('/api/approvallist/', post).subscribe((response) => {
      this.res= response;
      if (this.res.success == true) {
        if (this.res.response.isCompleted == "Y") {
          this.toastr.clear();
          this.toastr.success('Approval Complete Successfully', 'Status', {
            timeOut: 6000,
            closeButton: true

          });
        } else {
          this.toastr.clear();
          this.toastr.success('Approval InComplete Successfully', 'Status', {
            timeOut: 6000,
            closeButton: true
          });
        }
        this.getDetails();
        this.getDetailsOnly();
        this.getApprovalStates();
        this.approvalStateArr = [];
        this.dealstateArr = [];
        this.approvalpop=false;
      } else {
        this.toastr.clear();
        this.toastr.error(this.res.message, 'Error', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  getFileDetails(e) {
    this.myFiles = [];
    this.filesUploadArr=[];
    for (var i = 0; i < e.target.files.length; i++) {
      this.myFiles.push(e.target.files[i]);
      this.filesUploadArr.push({
        "name": e.target.files[i].name
      })
    }
  }
  canc(){
    if(this.opened == true){
      this.opened = false;
    }else{
       this.opened = true;
    }
  this.myFiles = [];
  this.filesUploadArr=[];
  }
  postMethod(CheckId) {
    if (this.clearFile()) {
      this.spinner.show();
      var post = {};
      const id = +this.route.snapshot.paramMap.get('id');
      this.projectId = id;
      this.checkid = CheckId;
      const frmData = new FormData();
      for (var i = 0; i < this.myFiles.length; i++) {
        frmData.append("file", this.myFiles[i]);
      }
      frmData.append("description", "");
      this.http.post('/api/upload/approval/' + this.projectId + '/' + this.userid, frmData).subscribe((response) => {
        this.toastr.clear();
        this.res = response;
        if (this.res.successArr.length > 0) {
          var lastarr = this.res.successArr.length - 1;
          for (var i = 0; i < this.res.successArr.length; i++) {
          this.http.post('/api/approvallist/documents/' + this.res.successArr[i].response.id + '/' + this.checkid, post).subscribe((response) => {
            this.res1 = response;
          }, error => {
            this.toastr.clear();
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.errorService.handleError(error);
          });
          if(lastarr == i){
            setTimeout(() => {
              if (this.res1.success == true) {
                this.toastr.clear();
                this.toastr.success(this.res1.message, 'Success', {
                  timeOut: 6000,
                  closeButton: true
                });
              } else {
                this.toastr.clear();
                this.toastr.error(this.res1.message, 'Error', {
                  timeOut: 6000,
                  closeButton: true
                });
                this.spinner.hide();
              }
              this.spinner.hide();
              this.fileRest();
              this.filesUploadArr=[];
              this.uploadFileArr = [];
              this.checklistArr = [];
            this.getdocuments(this.checkid);
              },2000);

          }
        }
        }else if(this.res.failureArr.length > 0) {
          this.spinner.hide();
          this.toastr.clear();
          this.toastr.error(this.res.message, 'Error', {
            timeOut: 6000,
            closeButton: true
          });
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
      return false;
    }
  }
  clearFile() {
    this.fileErr = null;
    var valid = true;
    let data = this.uploadFileForm.value;
    if (!data.file) {
      this.fileErr = "Upload File is Required";
      valid = false;
    }
    return valid;
  }

  downloadFile(fid) {
    const url = '/unsecure/download/' + fid;
    window.open(url, "_self");
  }

  deleteFile(fid, CheckId) {
    this.toggleCreateMenu(CheckId, 1);
    this.confirmService.confirm('Please confirm..', 'Do you want to delete the file ?')
      .then((confirmed) => {
        if (confirmed) {
          this.checkid = CheckId;
          this.http.delete('/api/approvallist/documents/' + fid).subscribe((response) => {
            this.res = response;
            if (this.res.success == true) {
              this.toastr.clear();
              this.toastr.success(this.res.message, 'Success', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
              this.uploadFileArr = [];
              this.checklistArr = [];
              this.toggleCreateMenu(this.checkid, 0);
            } else {
              this.toastr.clear();
              this.toastr.error(this.res.message, 'Error', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
            }
          }, error => {
            this.toastr.clear();
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.errorService.handleError(error);
          });
        }
      })
      .catch(() => {
        this.spinner.hide();
        this.uploadFileArr = [];
        this.checklistArr = [];
        this.toggleCreateMenu(this.checkid, 0);
      });
  }

  openConfirmationDialog() {
    if (this.validate()) {
      this.confirmService.confirm('Please confirm..', 'Do you really want to save ?')
        .then((confirmed) => {
          this.submitSign();
        })
        .catch(() => {
        });
    } else {
      return false;
    }
  }

  validate() {
    this.nameErr = null;
    var data = this.form.value;
    var valid = true;
    if (data.approvedBy == '') {
      this.nameErr = " Name is required.";
      valid = false;
    }
    return valid;
  }

  submitSign() {
    var data = this.form.value;
    this.details.approvedBy = data.approvedBy;
    var approvalSignature = this.signaturePad.toDataURL();
    this.details.updatedBy = parseInt(this.userid);
    this.details.approvalSign = approvalSignature;
    this.details.approvalCompleted = "T";
    this.http.put('/api/project', this.details).subscribe((response) => {
      this.res = response;
      if (this.res.success == true) {
        this.toastr.clear();
        this.toastr.success(this.res.message, 'Success', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
        this.getDetails();
        this.getDetailsOnly();
        this.getApprovalStates();
        this.approvalStateArr = [];
        this.dealstateArr = [];
      } else {
        this.toastr.clear();
        this.toastr.error(this.res.message, 'Error', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }

  fatchdatenote(res) {
    if (res) {
      this.notesForm.patchValue({ 'id': res.id });
      this.notesForm.patchValue({ 'note': res.note });
      this.notesForm.patchValue({ 'isActive': res.isActive });
      this.notesForm.patchValue({ 'projectId': res.projectId });
      this.notesForm.patchValue({ 'projectStateId': res.projectStateId });
      this.notesForm.patchValue({ 'createdDate': res.createdDate });
      this.notesForm.patchValue({ 'isCompleted': res.isCompleted });
    }
  }

  updateNote() {
    this.body = {};
    this.body["id"] = this.notesForm.value["id"];
    this.body["note"] = this.notesForm.value["note"];
    this.body["isActive"] = this.notesForm.value["isActive"];
    this.body["projectStateId"] = this.notesForm.value["projectStateId"];
    this.body["projectId"] = this.notesForm.value["projectId"];
    this.body["projectStateId"] = this.notesForm.value["projectStateId"];
    this.body["isCompleted"] = this.notesForm.value["isCompleted"];
    this.body["createdDate"] = this.notesForm.value["createdDate"];
    if (this.notesForm.value.note) {
      this.http.put('/api/approvallist', this.body).subscribe((response) => {
        this.res = response;
        if (this.res.success == true) {
          this.toastr.clear();
          this.toastr.success(this.res.message, 'Success', {
            timeOut: 6000,
            closeButton: true
          });
          this.resetForms();
          this.fatchdatenote(this.res.response)
          this.getApprovalStates();
          this.approvalStateArr = [];
          this.dealstateArr = [];
          this.toggleCreateMenu(1,0);
          this.approvalpop=false;

        } else {
          this.toastr.clear();
          this.toastr.error(this.res.message, 'Error', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }

      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
    } else {
      return false;
    }
  }

  notesValidate() {
    this.noteHeadingErr = null;
    this.noteDescErr = null;
    var valid = true;
    var data = this.notesForm.value;
    if (!data.note) {
      this.noteDescErr = "note is required";
      valid = false;
    }
    return valid;
  }

  onbegin: any;
  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  private signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    // 'minWidth': 5,
    'canvasWidth': 260,
    'canvasHeight': 150,
  };

  ngAfterViewInit() {
    // this.signaturePad.set('backgroundColor', 'red');
    // this.signaturePad.set('penColor', 'rgb(0,0, 0)');
    // this.signaturePad is now available
    //this.signaturePad.set('minWidth', 5); // set szimek/signature_pad options at runtime
    // this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  clearSign() {
    this.signaturePad.clear();
  }

  drawComplete() {

  }

  drawStart() {
    var count = 1;
  }
  pdfDownload(project) {
    this.pdfHeadingEnable = !this.pdfHeadingEnable;
    this.confirmService.confirm('Please confirm..', 'Do you want to download checklist?')
      .then((confirmed) => {
        if (confirmed) {
          this.spinner.show();
          this.captureScreen(project);
        }
        this.pdfHeadingEnable = !this.pdfHeadingEnable;
        this.spinner.hide();
      })
      .catch(() => {
        this.pdfHeadingEnable = !this.pdfHeadingEnable;
        return false;
      });
  }

  // pdfPrint() {
  //   window.print();
  // }

  pdfPrint() {
    this.confirmService.confirm('Please confirm..', 'Do you want print checklist ?')
    .then((confirmed) => {
      if (confirmed) {
        this.spinner.show();
    window.print();
      }
      this.spinner.hide();
    })
  }

  captureScreen(project) {
    var data = document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {
      var imgWidth = 150;
      var pageHeight = 80;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 8;
      pdf.addImage(contentDataURL, 'PNG', 30, position, imgWidth, pageHeight)
      pdf.output('save', project + '.pdf');
    });
  }

  zipFile() {
    const id = +this.route.snapshot.paramMap.get('id');
    const url = 'unsecure/approval/downloadZip/' + id;
    window.open(url, "_self");
  }




  approvalreset() {
    var data = {};
    this.confirmService.confirm('Please confirm..', 'Do you really want to revert ?')
    .then((confirmed) => {
       this.spinner.show();
    this.http.post('/api/project/revert/'+this.projectId, data).subscribe((response) => {
      this.toastr.clear();
      this.res = response;
      if (this.res.success == true) {
        this.toastr.clear();
        this.toastr.success(this.res.message, 'Success', {
          timeOut: 6000,
          closeButton: true
        });
        this.resetForms();
        this.fatchdatenote(this.res.response)
        this.getApprovalStates();
        this.approvalStateArr = [];
        this.dealstateArr = [];
      } else {
        this.toastr.clear();
        this.toastr.error(this.res.message, 'Error', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
      }
    }, error => {
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.spinner.hide();
      this.errorService.handleError(error);
    })
  })
  .catch(() => {
    return false;
  });

  }
  approvalDeleteAdmin() {
    const id = +this.route.snapshot.paramMap.get('id');
    this.confirmService.confirm('Please confirm..', 'Do you really want to Delete ?')
    .then((confirmed) => {
       this.spinner.show();
    this.http.delete('/api/project/' + id).subscribe((res: Response) => {
      this.toastr.clear();
     this.result = res;
      if ( this.result.success == true) {
        this.toastr.clear();
        this.toastr.success("Approval Delete Successfully!", 'Success', {
          timeOut: 6000,
          closeButton: true
        });
        this.router.navigate(["/approval"]);
      } else {
        this.toastr.clear();
        this.toastr.error(this.result.message, 'Error', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
      }
    }, error => {
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.spinner.hide();
      this.errorService.handleError(error);
    })
  })
  .catch(() => {
    return false;
  });

  }

  approvalarchive() {
    this.confirmService.confirm('Please confirm..', 'Do you really want to archive ?')
    .then((confirmed) => {
      if (confirmed) {
    const id = +this.route.snapshot.paramMap.get('id');
    this.spinner.show();
      this.http.delete('/api/project/archive/'+id+'/1').subscribe((res: Response) => {
        this.toastr.clear();
        this.result = res;
        if (this.result.success) {
          this.toastr.success(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true

          });

          this.router.navigate(['/approval']);
          this.spinner.hide();
        }
         else {

          this.toastr.error(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
        this.errorService.handleError(error);
      }
      )
    }
  })


  }
  toggleapppop(){
    this.approvalpop=false;
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
  }
  customerGetCall(){
    this.multiCustomerDetails();
    this.customerDetial();
  }
  customerDetial() {
    this.customersArr =[];
    this.http.get('/api/customer').subscribe((res1: Response) => {
       this.result1 = res1;
      this.custArrDe = this.result1;
      for (let i = 0; i < this.result1.length; i++) {
        this.customersArr.push(this.result1[i].details.id)
      }
  })
  }
  multiCustomerDetails(){
  this.customerAllDetails =[];
  this.customerAllDetailsArr =[];
  const id = +this.route.snapshot.paramMap.get('id');
  this.projectId = id;
  this.http.get('/api/projectCustomer/project/' + this.projectId).subscribe((res1: Response) => {
    this.customerAllDetails= res1;
    for (let i = 0; i < this.customerAllDetails.length; i++) {
      this.customerAllDetailsArr.push(this.customerAllDetails[i].cust.id)
    }

  })
  }

toggleAddCustomer(){
  if (this.popoverforcustomer == true) {
    this.popoverforcustomer = false;
  } else {
    this.popoverforcustomer = true;
  }
  this.allCustomer =[];
  this.customerAllArrselect = [];
  this.customerErr =null;
  var result2 = this.arr_diff_cust(this.customersArr,this.customerAllDetailsArr);
  this.custArrDe.forEach(r => {
    for (let i = 0; i < result2.length; i++) {
      if (result2[i] == r.details.id) {
        this.allCustomer.push({
          "id": r.details.id, "itemName": r.details.firstName + " " + r.details.lastName
        })
      }
    }
  });
  if (this.menuStateOrgEdit == 'in') {
    this.menuStateOrgEdit = 'out';
  }
  if (this.menuStateProjectUserEdit == 'in') {
    this.menuStateProjectUserEdit = 'out';
  }
  this.menuStateCustomerEdit = this.menuStateCustomerEdit === 'out' ? 'in' : 'out';
}
toggleCloseCustomer(){
  this.customerAllArrselect = [];
  this.menuStateCustomerEdit = this.menuStateCustomerEdit === 'out' ? 'in' : 'out';
  this.popoverforcustomer = false;
}

submitCustomer(){
  if (this.custValidate()) {
    this.spinner.show();
    var data = this.custForm.value;
    data.customerId = data.customerId[0].id;
    data.projectId = this.projectId;
    this.http.post('/api/projectCustomer', data).subscribe((response) => {
      this.res = response;
      if (this.res.success == true) {
        this.toastr.clear();
        this.toastr.success(this.res.message, 'Success', {
          timeOut: 6000,
          closeButton: true
        });
        this.toggleCloseCustomer();
        this.customerGetCall();
        this.spinner.hide();
      } else {
        this.toastr.clear();
        this.toastr.error(this.res.message, 'Error', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }

}
removeFromCustomer(id) {
  this.confirmService.confirm('Please confirm..', 'Do you want to remove the customer ?')
    .then((confirmed) => {
      if (confirmed) {
        this.spinner.show();
        this.http.delete('/api/projectCustomer/' + id).subscribe((response) => {
          this.res = response;
          if (this.res.success == true) {
            this.toastr.clear();
            this.toastr.success(this.res.message, 'Success', {
              timeOut: 6000,
              closeButton: true
            });
            this.customerGetCall();
            this.spinner.hide();
          } else {
            this.toastr.clear();
            this.toastr.error(this.res.message, 'Error', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
          }
        }, error => {
          this.toastr.clear();
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.errorService.handleError(error);
        });
      }
    })
    .catch(() => {
      return false;
    });
}
custValidate() {
  this.customerErr = null;
  var valid = true;
  var data = this.custForm.value;
  if (data.customerId.length == 0) {
    this.customerErr = "Customer is required";
    valid = false;
  }
  return valid;
}

toggleAddKeyPeople(){
  if (this.popoverforkeypeople == true) {
    this.popoverforkeypeople = false;
  } else {
    this.popoverforkeypeople = true;
  }
  this.allCertifiers =[];
  this.allCouncil =[];
  this.keypeopleAllArrselect = [];
  this.keypeopleAllArrselect1 = [];
  this.certifierErr =null;
  this.councilErr = null;

  this.http.get('/api/certifiers/').subscribe((res: Response) => {
    this.result = res;
    if (this.result.length != 0) {
      for (let i = 0; i < this.result.length; i++) {
        this.allCertifiers.push({
          "id": this.result[i].id, "itemName": this.result[i].firstName + " " + this.result[i].lastName,
        })
      }
    }
  }, error => {
    this.toastr.clear();
    this.toastr.error(error, 'Report Status', {
      timeOut: 6000,
      closeButton: true
    });
    this.errorService.handleError(error);
  });

  this.http.get('/api/council/').subscribe((res: Response) => {
    this.result1 = res;
    if (this.result1.length != 0) {
      for (let i = 0; i < this.result1.length; i++) {
        this.allCouncil.push({
          "id": this.result1[i].id, "itemName": this.result1[i].firstName + " " + this.result1[i].lastName,
        })
      }
    }
  }, error => {
    this.toastr.clear();
    this.toastr.error(error, 'Report Status', {
      timeOut: 6000,
      closeButton: true
    });
    this.errorService.handleError(error);
  });

  if (this.menuStateOrgEdit == 'in') {
    this.menuStateOrgEdit = 'out';
  }
  if (this.menuStateProjectUserEdit == 'in') {
    this.menuStateProjectUserEdit = 'out';
  }
  this.menuStateKeypeopleEdit = this.menuStateKeypeopleEdit === 'out' ? 'in' : 'out';
}
toggleCloseKeypeople(){
  this.keypeopleAllArrselect = [];
  this.keypeopleAllArrselect1 = [];
  this.menuStateKeypeopleEdit = this.menuStateKeypeopleEdit === 'out' ? 'in' : 'out';
  this.popoverforkeypeople = false;
}

submitKeypeople(){
  if (this.keypeopleValidate()) {
    this.spinner.show();
    var data = this.keypeopleForm.value;
    console.log(JSON.stringify(data));
    //data.customerId = data.certifierId[0].id;
    this.spinner.show();
    this.http.get('/api/project/' + this.projectId).subscribe((res: Response) => {
      this.result = res;
      this.spinner.hide();
      if (this.result != null) {
        this.result.certifierId = data.certifierId[0].id;
        this.result.councilId = data.councilId[0].id;
        this.result.updatedBy = parseInt(this.userid);
        this.http.put('/api/project/', this.result).subscribe((res1: Response) => {
          this.res2 = res1;
          if (this.res2.success == true) {
            this.toastr.clear();
            this.toastr.success(this.res2.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.toggleCloseKeypeople();
            this.getprojectinfo();
          }
        }, error => {
          this.toastr.clear();
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.errorService.handleError(error);
        })
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    })
  }

}
keypeopleValidate() {
  this.certifierErr = null;
  var valid = true;
  var data = this.keypeopleForm.value;
  if (data.certifierId.length == 0) {
    this.certifierErr = "Certifier is required";
    valid = false;
  }
  if (data.councilId.length == 0) {
    this.councilErr = "Council is required";
    valid = false;
  }
  return valid;
}


  getprojectinfo() {
    const id = +this.route.snapshot.paramMap.get('id');
    this.projectId = id;
    this.http.get('/api/project/CertMang/' + this.projectId).subscribe((res: Response) => {
      this.certMang= res;
    });
  }
  downloadEachApproval(approvalStateID){
    this.http.get('/api/approvallist/' + this.projectId + '/' + approvalStateID).subscribe((res: Response) => {
      this.results = res;
       if (this.results.data != null) {
      this.getdocuments1(this.results.data.id);
    }else{
      this.toastr.clear();
      this.toastr.error("Empty Documents", 'Report Status', {
        timeOut: 2000,
        closeButton: true
      });
    }
  });
}
 getdocuments1(Checkid) {
  this.http.get('api/approvallist/documents/' + Checkid).subscribe((res: Response) => {
    this.results = res;
    debugger;
    if(this.results.length > 0){
      for (let i = 0; i < this.results.length; i++) {
        this.downloadFile1(this.results[i].id);
       }
    }else{
      this.toastr.clear();
      this.toastr.error("Empty Documents", 'Report Status', {
        timeOut: 2000,
        closeButton: true
      });
    }

  });

}
downloadFile1(fid) {
  const url = '/unsecure/download/' + fid;
  window.open(url);
}
tabs(name) {
  switch (name) {
    case 'Approval':
      this.tab01 = true;
      this.tab02 = false;
      break;
    case 'Notes':
      this.tab01 = false;
      this.tab02 = true;
      break;
  }
}
async getnotes() {
  if (this.internaluser == true) {
    var result = await this.DetailsService.getApprovalNotesDetailsID(this.projectId, this.userid);
  } else {
    var result = await this.DetailsService.getApprovalNotesDetailsIDexternal(this.projectId, this.userid);
  }
  this.result1 = result;
  this.result1.forEach(r => {
    var desc = r.description;
    var edit = $(desc).text();
    this.noteArr.push({
      "id": r.id, "descName": r.description, "heading": r.heading, "createdAt": r.createdAt, "createdBy": r.createdBy, "viewType": r.viewType
    })
  })
console.log(this.noteArr);
}

submitNotesUser() {
  if (this.noteValidate()) {
    this.spinner.show();
    let data = this.notesUserForm.value;
    data.userId = parseInt(this.userid);
    data.createdBy = parseInt(this.userid);
    data.viewType = parseInt(data.viewType[0].id);
    data.domainId = parseInt(this.loggedInUserDomain);
    data.projectId = this.projectId;
    this.http.post('api/notes/approvals', data).subscribe((response) => {
      this.res = response;
      if (this.res.success == true) {
        this.toasterAlert(this.res.message, "success");
        this.toggleAddNotesUser();
        this.is_notes = false;
        this.isCreate = true;
        this.resetForms();
        this.getnotes();
        this.spinner.hide();
      } else {
        this.toasterAlert(this.res.message, "Error");
        this.spinner.hide();
      }
    }, error => {
      this.errorService.handleError(error);
    });
    this.noteArr = [];
    this.eventListSelected = [];
  }
}
noteEditMenu(id) {
  this.is_notes = true;
  this.noteHeadingErr = null;
  this.noteDescErr = null;
  this.isCreate = false;
  this.nid = id;
  this.http.get('/api/note/' + this.nid).subscribe((res: Response) => {
    this.result = res;
    if (this.result) {
      this.notesUserForm.patchValue({ 'id': this.result.id });
      this.notesUserForm.patchValue({ 'heading': this.result.heading });
      this.notesUserForm.patchValue({ 'description': this.result.description });
      this.notesUserForm.patchValue({ 'isActive': this.result.isActive });
      this.notesUserForm.patchValue({ 'userId': this.result.userId });
      this.notesUserForm.patchValue({ 'projectId': this.result.projectId });
      this.notesUserForm.patchValue({ 'createdAt': this.result.createdAt });
      this.notesUserForm.patchValue({ 'updatedAt': this.result.updatedAt });
      this.notesUserForm.patchValue({ 'createdBy': this.result.createdBy });
      this.notesUserForm.patchValue({ 'updatedBy': this.result.updatedBy });
      this.notesUserForm.patchValue({ 'domainId': this.result.domainId });

      if (this.result.createdBy == this.userid || this.admin == "Y") {
        this.is_delete = true;
        this.noteCreatedAnddeleteUSer = false;
      } else {
        this.noteCreatedAnddeleteUSer = true;
        this.is_delete = false;
      }
      if (this.result.viewType == "2") {
        this.eventListSelected = [];
        this.eventListSelected.push({ "id": 2, "itemName": "Internal" })
      } else if (this.result.viewType == "3") {
        this.eventListSelected = [];
        this.eventListSelected.push({ "id": 3, "itemName": "External" })
      }
    }
  }, error => {
    this.errorService.handleError(error);
  });
  this.menuStateNotesUserEdit = this.menuStateNotesUserEdit === 'out' ? 'in' : 'out';
}
updateuserNote() {
  if (this.noteValidate()) {
  this.body = {};
  this.body["id"] = this.nid;
  this.body["viewType"] = this.notesUserForm.value["viewType"][0].id;
  this.body["heading"] = this.notesUserForm.value["heading"];
  this.body["description"] = this.notesUserForm.value["description"];
  this.body["userId"] = this.notesUserForm.value["userId"];
  this.body["projectId"] = this.notesUserForm.value["projectId"];
  this.body["createdAt"] = this.notesUserForm.value["createdAt"];
  this.body["updatedAt"] = this.notesUserForm.value["updatedAt"];
  this.body["createdBy"] = this.notesUserForm.value["createdBy"];
  this.body["domainId"] = this.notesUserForm.value["domainId"];
  this.body["updatedBy"] = this.userid;
  this.body["isActive"] = "Y";
    this.spinner.show();
    this.http.put('/api/notes/approvals', this.body).subscribe((response) => {
      this.resetForms();
      this.res = response;
      if (this.res.success == true) {
        this.toasterAlert(this.res.message, "success");
        this.spinner.hide();
        this.is_notes = false;
        this.getnotes();
      } else {
        this.toasterAlert(this.res.message, "Error");
        this.spinner.hide();
      }
    }, error => {
      this.errorService.handleError(error);
    });
    this.noteArr = [];
  }

}
delateNote() {
  this.confirmService.confirm('Please confirm..', 'Do you really want to delete ?')
    .then((confirmed) => {
      if (confirmed) {
        this.spinner.show();
        this.http.delete('/api/notes/' + this.nid).subscribe((response) => {
          this.resetForms();
          this.isCreate = true;
          this.res = response;
          if (this.res.success == true) {
            this.toasterAlert(this.res.message, "success");
            this.spinner.hide();
            this.is_notes = false;
            this.getnotes();
            this.noteArr = [];
          } else {
            this.toasterAlert(this.res.message, "Error");
          }
        }, error => {
          this.spinner.hide();
          this.errorService.handleError(error);
        })
        this.getnotes();
      }
    })
    .catch(() => {
      return false;
    });
}
noteValidate() {
  this.noteHeadingErr = null;
  this.noteDescErr = null;
  this.nameErr = null;
  const strongRegex = /^[:;,\-@0-9a-zA-Zגיט'.\s]{1,9999}$/;
  var valid = true;
  var data = this.notesUserForm.value;
  console.log(data)
  var desc = data.description;
  var edit = $(desc).text();
  if (!data.heading) {
    this.noteHeadingErr = "Heading is required";
    valid = false;
  } else if (data.viewType.length == 0) {
    this.nameErr = "NoteType  is required.";
    valid = false;
  }
  if (edit == undefined || edit == '') {
    this.noteDescErr = "Notes is Required";
    valid = false;
  }
  // else if (!strongRegex.test(edit)) {
  //   this.noteDescErr = "Too long. Please restrict to 10000 characters";
  //   valid = false;
  // }
  else if (edit.length > 10000) {
    this.noteDescErr = "Too long. Please restrict to 10000 characters";
    valid = false;
  }
  return valid;
}
toggleAddNotesUser() {

  this.noteHeadingErr = null;
  this.noteDescErr = null;
  this.isCreate = true;
  this.resetForms();
  this.is_notes = false;
  if (this.menuStateOrgEdit == 'in') {
    this.menuStateOrgEdit = 'out';
  }
  if (this.menuStateProjectUserEdit == 'in') {
    this.menuStateProjectUserEdit = 'out';
  }
  this.menuStateNotesUserEdit = this.menuStateNotesUserEdit === 'out' ? 'in' : 'out';
}
toggleAddNotesUsercancel() {
  this.is_notes = false
}
tabs1() {
  this.noteCreatedAnddeleteUSer = false;
  this.eventListSelected = [];
  this.noteHeadingErr = null;
  this.noteDescErr = null;
  this.nameErr = null;
  this.filesUploadArr = [];
  this.tab01 = false;
  this.tab02 = true;
  if (this.is_notes == true) {
    if (this.isCreate == true) {
      this.is_notes = false;
    } else {
      this.resetForms();
      this.isCreate = true;
    }

  } else {
    this.resetForms();
    this.isCreate = true;
    this.is_notes = true;
  }
}
toasterAlert(msg, value) {
  this.spinner.hide();
  this.toastr.clear();
  if (value == "success") {
    this.toastr.success(msg, 'Report Status', {
      timeOut: 2000,
      closeButton: true
    });
  } else if (value == "warning") {
    this.toastr.warning(msg, 'Report Status', {
      timeOut: 2000,
      closeButton: true
    });
  } else {
    this.toastr.error(msg, 'Report Status', {
      timeOut: 2000,
      closeButton: true
    });
  }
}

getHeaderLabel() {
  if (this.approvalType == 'approvals') {
    return "Approval";
  } else if (this.approvalType == 'cc') {
    return "CC";
  } else if (this.approvalType == 'subdivision') {
    return "Subdivision";
  } else {
    return "Approval";
  }
}
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Router } from '@angular/router';
import * as moment from 'moment/moment.js';
import { DetailsService } from '../_services/index';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { trigger, state, animate, transition, style } from '@angular/animations';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/_services/error.service';
import { Lightbox } from 'ngx-lightbox';
import { PermissionService } from 'src/app/_services/permission.service';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { ITreeOptions } from 'angular-tree-component';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';
import { TreeNode, TreeModel, TREE_ACTIONS, KEYS, IActionMapping, TreeComponent } from 'angular-tree-component';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';


@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class ProjectDetailsComponent implements OnInit {
  @ViewChild('myContextMenu') public myContextMenu: ContextMenuComponent;
  @ViewChild('myFileContextMenu') public myFileContextMenu: ContextMenuComponent;
  @ViewChild('tree') treeComponent: TreeComponent;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '25rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    // uploadUrl: 'v1/images', // if needed
    customClasses: [ // optional
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  menuStateDocumentEdit: string = 'out';
  menuStateEmail: string = 'out';
  menuStateUploadFile: string = 'out';
  menuStateTaskEdit: string = 'out';
  menuStateEmailEdit: string = 'out';
  userExists = false;
  noteHeadingErr: any;
  noteDescErr: any;
  internaluser: boolean = false;
  fileErr: any;
  emailErr: any;
  emailccErr: any;
  emailbccErr: any;
  filemailErr: any;
  imageErr: any;
  userid: any;
  customer: any;
  opened = false;
  approvalproArr = [];
  customersArr = [];
  customerAllDetails: any;
  customerAllDetailsArr = [];
  customerAllArrselect: any;
  custArrDe = [];
  allCustomer: any;
  custLength: any;
  customerErr: any;
  mailSignnature: any;
  projectDays = {};
  projectaddress = {};
  isCreate: boolean = true;
  projectArr = [];
  projectDaysArr = [];
  details: any;
  fromEmailIdArr = [];
  mailContentsArr = [];
  mailSendArr = [];
  mailAttachmentArr = [];
  projectId: any;
  mail: any;
  syncmail: string;
  mailDetailsArr = [];
  emptyarr = {};
  multimail: boolean = false;
  syncheck = false;
  fd: any;
  mailArr = [];
  filesatt = [];
  containers = [];
  uploadFileArr = [];
  uploadPhotoArr = [];
  uploadFilesArr = [];
  projectUsersArr = [];
  stateID = [];
  photosArr = [];
  projectInfoID = [];
  fileToUpload: any;
  rolesArr = [];
  userArr = [];
  detailsArr = [];
  projectUserArr = [];
  result: any;
  usersArr = [];
  projectUserErr = null;
  fileToEmail: any;
  fileTodocument: any;
  fileTophoto: any;
  users = {};
  projectUsers: any;
  roles = {};
  gmailsyn: boolean = false;
  isCreate1: boolean = false;
  isdis: boolean = false;
  iscurrdis: boolean = false;
  iscompleteDis: boolean = false;
  istakenHide: boolean = false;
  fol: boolean = false;
  doc: boolean = false;
  resetTask: boolean = false;
  jobCompleteHide: boolean = false;
  jobCompleteHideDis: boolean = false;
  is_delete: boolean = false;
  noteCreatedAnddeleteUSer: boolean = false;
  uploadFileForm: FormGroup;
  Photoform: FormGroup;
  uploadDocumentForm: FormGroup;
  projectUserForm: FormGroup;
  folderform: FormGroup;
  documentform: FormGroup;
  emailForm: FormGroup;
  emailFormAttach: FormGroup;
  TaskForm: FormGroup;
  taskComment: FormGroup;
  notesForm: FormGroup;
  Ocdocform: FormGroup;
  form: FormGroup;
  menuStateProjectUserEdit: string = 'out';
  settings = {};
  settings1 = {};
  dateErr: any;
  nodes: any;
  nameErr = null;
  custErr = null;
  folderErr = null;
  folderval: boolean = true;
  documentErr = null;
  fileTaskErr = null;
  detailsErr = null;
  StartdateErr: any;
  myDatePickerOptions: any;
  myDatePickerOptions2: any;
  options: any;
  today: any;
  taskcommentId: any;
  taskCommentArr = [];
  taskCommentesAr = [];
  taskAttArr = [];
  taskArr = [];
  custArrselect = [];
  body: {};
  tid: any;
  projectStId: any;
  menuStateProjectEdit: string = 'out';
  menuStateOrgEdit: string = 'out';
  menuStateCustomerEdit: string = 'out';
  ProjectJobForm: FormGroup;
  projectStateArr = [];
  jobArrselect = [];
  jobId: any;
  admin: any;
  isProject: boolean = false;
  isProjectatt: boolean = false;
  iscustomer: boolean = false;
  isAdminDis: boolean = false;
  projectUserRoleErr: any;
  selDate: any;
  customermail: any;
  result1: any;
  result2: any;
  result3: any;
  result4: any;
  res2: any
  res: any;
  res1: any;
  result5: any;
  notesUserForm: FormGroup;
  custForm: FormGroup;
  loggedInUserDomain: any;
  menuStateNotesUserEdit: string = 'out';
  noteArr = [];
  nid: any;
  cc: any;
  bcc: any;
  chartDataJob: any;
  JOB_COMPLETED = "#f5748a";
  JOB_IN_PROGRESS = "#63a5e6";
  chartTypeJob: any;
  chartColumNamesJob: any;
  chartOptionsJob: any;
  specialNoteList: any;
  settingssingle: any;
  eventListSelected: any;
  noteTypeList: any;
  splNotesForm: FormGroup;
  menuStateSplNotesEdit: string = 'out';
  splNoteArr: any;
  splNoteArr2: any;
  tab01: boolean = false;
  tab02: boolean = false;
  tab03: boolean = false;
  tab04: boolean = false;
  tab05: boolean = false;
  tab06: boolean = false;
  is_edit: boolean = false;
  specialNotesView: boolean = false;
  popoverformproj: boolean = false;
  popoverformproj1: boolean = false;
  popoverformprojnote: boolean = false;
  popoverformmail: boolean = false;
  popoverformassign: boolean = false;
  popoverformTask: boolean = false;
  myFilesDocument: string[] = [];
  myFilesPhoto: string[] = [];
  myFilesDocumentArr = [];
  myFilesPhotoArr = [];
  myFiles: string[] = [];
  myFilesEmail: string[] = [];
  myFilesOcDoc: string[] = [];
  myFilesOcDocArr = [];
  attachmentShowhide: boolean = false;
  allActivityArr = [];
  subJobDetailsArr = [];
  parentJobId: any;
  projectStatus = [];
  projectDepended = [];
  projectPredecessor = [];
  noftificationArr = [];
  noftificationArrSelect = [];
  filesUploadArr = [];
  predecessorSettings: any;
  dependedSettings: any;
  notifiSettings: any;
  myFilesTask: string[] = [];
  myFilesTaskArr = [];
  rolesPermissionArr: any;
  descriptionHide: boolean = false;
  fileID: any;
  ocdArr = [];
  ocdResult: any;
  openoc: boolean = false;
  isSubmit: boolean = false;
  isSubmitZipFile: boolean = false;
  uploadOCArr = [];
  ocDocumentName: any;
  checkid: any;
  maxChars = 255;
  OcdocErr: any;
  documentsLengthg: any;
  documentsListShow: boolean = false;
  openeds: boolean = false;
  signpat = false;
  projectInfoDetails: any;
  pdfHeadingEnable: boolean = false;

  projectCompleted: boolean = false;

  keypeopleForm: any;
  allCertifiers:any = [];
  allCouncil: any = [];
  keypeopleAllArrselect:any = [];
  keypeopleAllArrselect1:any = [];
  certifierErr = '';
  councilErr = '';
  popoverforkeypeople: any;
  menuStateKeypeopleEdit: string = 'out';
  certMang :any;

  documentPermissionForm: FormGroup;
  treeEditPermissionTrue : boolean = false;
  filesName :any;
  treeMapId :any;

  constructor(private spinner: NgxSpinnerService, private http: HttpClient, private router: Router,
    private DetailsService: DetailsService, private route: ActivatedRoute,
    private formBuilder: FormBuilder, private contextMenuService: ContextMenuService,
    private permissionService: PermissionService, private errorService: ErrorService, private confirmService: ConfirmService, private toastr: ToastrService,
    private _lightbox: Lightbox) {
    this.settings = {
      singleSelection: true,
      enableSearchFilter: true,

    }
    this.eventListSelected = [];
    this.customerAllArrselect = [];
    this.customermail = [];
    this.allCustomer = [];
    this.settings1 = {
      singleSelection: true,
      disabled: true
    }

    this.chartTypeJob = "Timeline";
    this.chartOptionsJob = { timeline: { showRowLabels: false, groupByRowLabel: false }, avoidOverlappingGridLines: false, width: 950, height: 300 }
    this.chartColumNamesJob = [
      { type: 'string', id: 'User' },
      { type: 'string', id: 'JobName' },
      { type: 'string', id: 'style', role: 'style' },
      { type: 'date', id: 'Start' },
      { type: 'date', id: 'End' }
    ]
    this.chartDataJob = [];
    this.noteTypeList = [

      { "id": 2, "itemName": "Internal" },
      { "id": 3, "itemName": "External" },
    ];
    this.noftificationArr = [
      { "id": 1, "itemName": "3 Days before" },
      { "id": 2, "itemName": "7 Days before" },
      { "id": 3, "itemName": "15 Days before" },
    ];
  }
  ngOnInit() {
    this.userid = localStorage.getItem("loggedInUserID");
    this.loggedInUserDomain = localStorage.getItem('loggedInDomainID');
    this.admin = localStorage.getItem("loggedInUserAdmin");

    this.createForms();
    this.getDetailsOnly();
    this.getDetails();
    this.getdocuments();
    this.getProjectKeyPeopleInfo();
    //this.getPermissionDetails();

    this.today = new Date();
    this.options = {
      allowDrop: true,
      allowDragoverStyling: false,
      displayField: ''
    };
    this.myDatePickerOptions = {
      dateFormat: 'dd-mm-yyyy',
      disableUntil: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() - 1 }

    }
    this.myDatePickerOptions2 = {
      dateFormat: 'dd-mm-yyyy',
      disableUntil: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() - 1 }

    }
    this.specialNoteList = [
      { "id": 1, "itemName": "Personal" },
      { "id": 2, "itemName": "Internal" },
      { "id": 3, "itemName": "External" },
    ];
    this.settingssingle = {
      text: "Select",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      singleSelection: true,
      enableCheckAll: false
    };
    this.dependedSettings = {
      text: "Depended State",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: true,
      enableCheckAll: false
    };
    this.notifiSettings = {
      text: "Notification",
      disabled: false,
      singleSelection: true,
      enableCheckAll: false
    };
    this.predecessorSettings = {
      text: "Predecessor State",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: true,
      enableCheckAll: false
    }
  }
  myContextClick($event, data, id) {

    this.contextMenuService.show.next({
      contextMenu: this.myContextMenu,
      event: <any>$event,
      item: data,
    });
    const treeModel: TreeModel = this.treeComponent.treeModel;
    treeModel.getNodeById(id).setIsActive(true);
    $event.preventDefault();
    $event.stopPropagation();
  }
  myFileContextMenuClick($event, data, id) {

    this.contextMenuService.show.next({
      contextMenu: this.myFileContextMenu,
      event: <any>$event,
      item: data,
    });
    const treeModel: TreeModel = this.treeComponent.treeModel;
    treeModel.getNodeById(id).setIsActive(true);
    $event.preventDefault();
    $event.stopPropagation();
  }

  getProjectKeyPeopleInfo() {
    const id = +this.route.snapshot.paramMap.get('id');
    this.projectId = id;
    this.http.get('/api/project/CertMang/' + this.projectId).subscribe((res: Response) => {
      this.certMang= res;
    });
  }

  getPermissionDetails() {
    this.http.get('/api/user/' + this.userid).subscribe((res: Response) => {
      this.result = res;
      var permision = this.result.role.permision;
      if (permision.length > 0) {
        var projectPermission = this.permissionService.getProjectPermission(permision);
        if (projectPermission == this.permissionService.PROJECT_ADMIN ||
              this.admin == "Y" ||
              this.userid == this.details.managerId) {
          this.isAdminDis = true;
          this.isProject = true;
        } else if (projectPermission == this.permissionService.PROJECT_OBSERVER) {
          this.isProjectatt = true;
        }
        var customerPermission = this.permissionService.getCustomerPermission(permision);
        if (customerPermission == this.permissionService.CUSTOMER) {
          this.iscustomer = true;
        }
      }

    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }


  onDateChanged(event) {
  }
  toggleDocumentUser() {
    this.fileErr = null;
    this.isCreate = true;
    this.resetForms();
    if (this.menuStateEmail == 'in') {
      this.menuStateEmail = 'out';
    }
    if (this.menuStateTaskEdit == 'in') {
      this.menuStateTaskEdit = 'out';
    }
    if (this.menuStateEmailEdit == 'in') {
      this.menuStateEmailEdit = 'out';
    }
    if (this.menuStateProjectEdit == 'in') {
      this.menuStateProjectEdit = 'out';
    }

    this.menuStateDocumentEdit = this.menuStateDocumentEdit === 'out' ? 'in' : 'out';
  }
  toggleUploadPhoto() {
    this.isCreate = true;
    this.fileErr = null;
    if (this.menuStateDocumentEdit == 'in') {
      this.menuStateDocumentEdit = 'out';
    }
    if (this.menuStateEmail == 'in') {
      this.menuStateEmail = 'out';
    }

    if (this.menuStateTaskEdit == 'in') {
      this.menuStateTaskEdit = 'out';
    }
    if (this.menuStateEmailEdit == 'in') {
      this.menuStateEmailEdit = 'out';
    }
    if (this.menuStateProjectEdit == 'in') {
      this.menuStateProjectEdit = 'out';
    }

    this.menuStateUploadFile = this.menuStateUploadFile === 'out' ? 'in' : 'out';


  }
  toggleEmail(num) {
    this.emailErr = null;
    this.emailccErr = null;
    this.emailbccErr = null;
    this.filemailErr = null;
    this.opened = null;
    this.resetForms();
    this.deleteFilemailarr();
    this.isCreate = true;
    if (this.menuStateDocumentEdit == 'in') {
      this.menuStateDocumentEdit = 'out';
    }

    if (this.menuStateUploadFile == 'in') {
      this.menuStateUploadFile = 'out';
    }
    if (this.menuStateTaskEdit == 'in') {
      this.menuStateTaskEdit = 'out';
    }
    if (this.menuStateEmailEdit == 'in') {
      this.menuStateEmailEdit = 'out';
    }
    if (this.menuStateProjectEdit == 'in') {
      this.menuStateProjectEdit = 'out';
    }

    this.menuStateEmail = this.menuStateEmail === 'out' ? 'in' : 'out';
    if (num == 0) {
      this.getUserDetails();
    }
  }
  toggleAddTask() {
    this.isdis = null;
    this.custErr = null;
    this.nameErr = null;
    this.detailsErr = null;
    this.fileTaskErr = null;
    this.taskCommentesAr = [];
    this.taskCommentArr = [];
    this.taskAttArr = [];
    this.dateErr = null;
    this.StartdateErr = null;
    this.isCreate = true;
    this.resetForms();
    this.TaskForm.enable();


    if (this.menuStateDocumentEdit == 'in') {
      this.menuStateDocumentEdit = 'out';
    }

    if (this.menuStateUploadFile == 'in') {
      this.menuStateUploadFile = 'out';
    }
    if (this.menuStateEmailEdit == 'in') {
      this.menuStateEmailEdit = 'out';
    }
    if (this.menuStateProjectEdit == 'in') {
      this.menuStateProjectEdit = 'out';
    }

    this.menuStateTaskEdit = this.menuStateTaskEdit === 'out' ? 'in' : 'out';
    if (this.popoverformTask == true) {
      this.popoverformTask = false;
    } else {
      this.popoverformTask = true;
    }
  }
  createForms() {
    this.uploadDocumentForm = this.formBuilder.group({
      file: ''
    })
    this.custForm = this.formBuilder.group({
      customerId: ''
    });
    this.notesUserForm = this.formBuilder.group({
      domainId: '',
      description: ['', Validators.required],
      heading: '',
      userId: '',
      projectId: '',
      createdAt: '',
      updatedAt: '',
      createdBy: '',
      updatedBy: '',
      viewType: [],

    })
    this.Ocdocform = this.formBuilder.group({
      Ocdoc: '',
      description: ''
    });
    this.uploadFileForm = this.formBuilder.group({
      file: ''
    })
    this.Photoform = this.formBuilder.group({
      Photo: '',
      description: ''
    })
    this.folderform = this.formBuilder.group({
      folder: '',
      projectId: '',
      createdDate: '',
      updatedDate: '',
      createdBy: '',
      updatedBy: '',
      rootId: '',
      id: '',
      isActive: ''
    })
    this.documentform = this.formBuilder.group({
      document: '',
      projectId: '',
      createdDate: '',
      updatedDate: '',
      createdBy: '',
      updatedBy: '',
      rootId: '',
      id: '',
      isActive: '',
      fileFolderId: '',
      fileId: '',
    })
    this.emailForm = this.formBuilder.group({
      to: '',
      subject: '',
      bodyText: '',
      cc: '',
      bcc: '',
      file: '',
      attach: ''
    });
    this.notesForm = this.formBuilder.group({
      note: '',
      projectId: '',
      projectStateId: '',
      createdDate: '',
      id: '',
      isCompleted: '',
      isActive: '',
      domainId: ''
    })
    this.TaskForm = this.formBuilder.group({
      name: '',
      details: '',
      userId: [],
      projectId: '',
      createdAt: '',
      updatedAt: '',
      startBy: '',
      createdBy: '',
      updatedBy: '',
      taskId: '',
      completeBy: null,
      comment: '',
      commentType: '',
      isCompleted: '',
      isTaken: ''
    });
    this.emailFormAttach = this.formBuilder.group({
      file: ''
    });
    this.taskComment = this.formBuilder.group({
      taskId: '',
      comment: '',
      commentType: '',
      commentBy: '',
      attachmentFile: ''

    })
    this.ProjectJobForm = this.formBuilder.group({
      id: '',
      assignedToId: '',
      projectStateId: '',
      projectId: '',
      startDate: '',
      endDate: '',
      isCompleted: '',
      createdBy: '',
      updatedBy: '',
      isActive: '',
      createdDate: '',
      jobOrder: '',
      dependedJobId: [],
      predecessorJobId: [],
      notifybefore: []

    });
    this.splNotesForm = this.formBuilder.group({
      note: ['', Validators.required],

    })
    this.projectUserForm = this.formBuilder.group({
      userId: null,
      roleId: null
    });
    this.keypeopleForm = this.formBuilder.group({
      certifierId: '',
      councilId: ''
    });
    this.form = this.formBuilder.group({
      approvedBy: ['', Validators.required],
    });
    this.documentPermissionForm = this.formBuilder.group({
      mapId:'',
      isCertifierShared: 'N',
      isCustomerShared: 'N',
      isExternalUserShared: 'N',
    });
  }
  resetForms() {
    this.eventListSelected = [];
    this.emailErr = null;
    this.emailccErr = null;
    this.emailbccErr = null;
    this.uploadFileForm.reset({
      file: ''
    })
    this.uploadDocumentForm.reset({
      file: ''
    });
    this.notesUserForm.reset({
      description: '',
      heading: ''
    })
    this.Photoform.reset({
      Photo: '',
      description: ''
    });
    this.custForm.reset({
      customerId: []
    })
    this.folderform.reset({
      document: '',
      // projectId:'',
      // createdDate :'',
      // updatedDate :'',
      // createdBy :'',
      // updatedBy:'',
      // rootId:'',
      // id:'',
      // isActive:''
    });
    this.documentform.reset({
      folder: '',
      projectId: '',
      createdDate: '',
      updatedDate: '',
      createdBy: '',
      updatedBy: '',
      rootId: '',
      id: '',
      isActive: ''
    });

    this.ProjectJobForm.reset({
      id: '',
      projectStateId: '',
      assignedToId: [],
      projectId: '',
      startDate: '',
      endDate: '',
      isCompleted: '',
      createdBy: '',
      updatedBy: '',
      isActive: '',
      createdDate: '',
      jobOrder: '',
      dependedJobId: [],
      predecessorJobId: [],
      notifybefore: []


    });
    this.ProjectJobForm.patchValue({ startDate: null });
    this.ProjectJobForm.patchValue({ endDate: null });
    this.projectUserForm.reset({
      userId: null,
      roleId: null
    })

    this.emailForm.reset({
      to: '',
      subject: '',
      bodyText: '',
      cc: '',
      bcc: '',
      file: '',
      attach: ''
    })
    this.splNotesForm.reset({
      note: ['', Validators.required]
    })
    this.TaskForm.reset({
      name: null,
      details: null,
      userId: [],
      isCompleted: null,
      isTaken: null,
    })
    this.TaskForm.patchValue({ startBy: null });
    this.TaskForm.patchValue({ completeBy: null });
    this.keypeopleForm.reset({
      certifierId: [],
      councilId: []
    });
    this.documentPermissionForm.reset({
      mapId:'',
      isCertifierShared: 'N',
      isCustomerShared: 'N',
      isExternalUserShared: 'N',
    });
  }
  emailAttachReset() {
    this.emailFormAttach.reset({
      file: ''
    })
  }
  taskreset() {
    this.taskComment.reset({
      comment: '',
      attachmentFile: ''
    })
  }
  clearTaskFile() {
    this.fileTaskErr = null;
    var valid = true;
    let data = this.taskComment.value;
    if (!data.attachmentFile) {
      this.fileTaskErr = "Upload File is Required";
      valid = false;
    }
    return valid;
  }
  onchangtab(value) {
    switch (value.nextId) {
      case 'Email':
        this.mailContentsArr = [];
        this.getMailContent();
        break;
      case 'Notes':
        this.spinner.show();
        this.noteArr = [];
        this.getnotes();
        break;
      case 'Task':
        this.spinner.show();
        this.taskArr = [];
        this.taskCommentArr = [];
        this.gettask();
        break;
      case 'Photos':
        this.spinner.show();
        this.taskArr = [];
        this.taskCommentArr = [];
        this.getphoto();
        break;
      case 'OCD':
        this.spinner.show();
        this.getocdocuments();
        break;
      case 'Project':
        this.spinner.show();
        this.getalllog();
        break;
      case 'Jobs':
        this.spinner.show();
        this.getJobs();
        break;
    }
  }
  onchangtab2(value) {
    var id = value.nextId;
    switch (value.nextId) {
      case 'Email':
        this.mailContentsArr = [];
        this.getMailContent();
        break;
      case 'Notes':
        this.spinner.show();
        this.noteArr = [];
        this.getnotes();
        break;
      case 'Task':
        this.spinner.show();
        this.taskArr = [];
        this.taskCommentArr = [];
        this.gettask();
        break;
      case 'Photos':
        this.spinner.show();
        this.taskArr = [];
        this.taskCommentArr = [];
        this.getphoto();
        break;
      case 'OCD':
        this.spinner.show();
        this.getocdocuments();
        break;
      case 'Project':
        this.spinner.show();
        this.getalllog();
        break;
      case 'Jobs':
        this.spinner.show();
        this.getJobs();
        break;
    }

  }
  onchangtab3(value) {
    this.getphoto();

  }
  emailValidate(num) {
    this.emailErr = null;
    this.emailccErr = null;
    this.emailbccErr = null;
    var valid = true;
    var data = this.emailForm.value;
    const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


    if (num == 0) {
      if (!data.to) {
        this.emailErr = "Email is required.";
        valid = false;
      } else {
        var valueemail = data.to.split(",");
        for (let i = 0; i < valueemail.length; i++) {
          if (!validEmailRegEx.test(valueemail[i])) {
            this.emailErr = "Invalid Email";
            this.multimail = true;

            valid = false;
          }
        }

      }
    } else if (num == 1) {
      if (data.cc != "") {
        var valueemail = data.cc.split(",");
        for (let i = 0; i < valueemail.length; i++) {
          if (!validEmailRegEx.test(valueemail[i])) {
            this.emailccErr = "Invalid Email";
            this.multimail = true;
            valid = false;
          }
        }
      }
    } else if (num == 2) {
      if (data.bcc != "") {
        var valueemail = data.bcc.split(",");
        for (let i = 0; i < valueemail.length; i++) {
          if (!validEmailRegEx.test(valueemail[i])) {
            this.emailbccErr = "Invalid Email";
            this.multimail = true;
            valid = false;
          }
        }
      }
    }
    return valid;
  }
  clearFile(num) {
    if (num == 0) {
      this.fileErr = null;
      var valid = true;
      let data = this.uploadDocumentForm.value;
      if (!data.file) {
        this.fileErr = "Upload File is Required";
        valid = false;
      }
      return valid;
    } if (num == 2) {
      this.OcdocErr = null;
      var valid = true;
      let data = this.Ocdocform.value;
      if (!data.Ocdoc) {
        this.OcdocErr = "Upload File is Required";
        valid = false;
      }
      return valid;
    } else {
      this.filemailErr = null;
      var valid = true;
      let data = this.emailFormAttach.value;
      if (!data.file) {
        this.filemailErr = "Upload File is Required";
        valid = false;
      }
      return valid;

    }


  }


  async getDetailsOnly() {
    const id = +this.route.snapshot.paramMap.get('id');
    this.projectId = id;
    var result = await this.DetailsService.getDetailsID(id);
    this.details = result;
    console.log("details");
    console.log(JSON.stringify(this.details));
    this.getPermissionDetails();
    if (this.details.projectCompleted == 'T') {
      this.projectCompleted = true;
    } else {
      this.projectCompleted = false;
    }

    var desc = this.details.note;
    this.splNoteArr = desc;
    this.splNoteArr2 = $(desc).text();
    if (this.details.note == "") {
      this.specialNotesView = false;
    } else {
      this.specialNotesView = true;
    }
    if (this.details.customerId != null) {
      this.customerDetials(this.details.customerId);
    }
    this.getFromEmail();

  }
  async customerDetials(id) {
    var result = await this.DetailsService.getCustomerID(id);
    this.customer = result;
    this.customermail.push(this.customer.details.email);

  }

  async getDetails() {
    this.spinner.show();
    const id = +this.route.snapshot.paramMap.get('id');
    this.projectId = id;
    this.roleDetails(this.projectId);
    var result = await this.DetailsService.getUsers();
    this.result1 = result;
    this.projectUserArr = this.result1;
    if (result) {
      this.users = {};
      this.result1.forEach(r => {
        this.users[r.details.id] = r.details;
      });
    }
    result = await this.DetailsService.rolesPermission("PROJECT");
    this.rolesPermissionArr = result;
    this.detailsArr = [];
    this.projectUserArr = this.rolesPermissionArr;
    if (this.rolesPermissionArr) {
      this.rolesPermissionArr.forEach(r => {
        this.detailsArr.push(r.id);
      });
    }
    result = await this.DetailsService.getRoles();
    this.result1 = result;
    this.roles = {};
    this.result1.forEach(r => {
      this.roles[r.role.id] = r.role;
      this.rolesArr.push({
        "id": r.role.id, "itemName": r.role.name
      })
    })
    this.spinner.hide();

    const TaskId = +this.route.snapshot.paramMap.get('tid');
    if (TaskId != 0) {
      this.spinner.show();
      // this.ProjectEditMenu(TaskId, "true");
      this.taskEditMenu(TaskId, 0);
    }
  }
  async roleDetails(id) {
    var result = await this.DetailsService.getProjectsUser(id);
    this.projectUsers = result;
    if (this.projectUsers == []) { this.userExists = true; }
    this.userArr = [];
    for (let i = 0; i < this.projectUsers.length; i++) {
      if (this.projectUsers[i].userId == parseInt(this.userid)) {
        this.internaluser = true;
      }
      this.userArr.push(this.projectUsers[i].userId);
    }
    this.projectUsers.forEach(r => {
      this.http.get('/api/user/' + r.userId).subscribe((res: Response) => {
        this.result = res;
        this.projectUsersArr.push({
          "id": this.result.details.id, "itemName": this.result.details.firstName + " " + this.result.details.lastName
        })
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
    })

  }
  toggleAddProjectUser(number) {
    if (this.popoverformproj == true) {
      this.popoverformproj = false;
    } else {
      this.popoverformproj = true;
    }
    this.usersArr = [];
    this.projectUserErr = null;
    this.resetForms();
    if (number == 0) {
      var result2 = this.arr_diff(this.detailsArr, this.userArr);
      this.projectUserArr.forEach(r => {
        for (let i = 0; i < result2.length; i++) {
          if (result2[i] == r.id) {
            this.usersArr.push({
              "id": r.id, "itemName": r.firstName + " " + r.lastName + " - " + r.roleName, "roleId": r.role, "roleName": r.roleName
            })
          }
        }
      });
    }
    if (this.menuStateDocumentEdit == 'in') {
      this.menuStateDocumentEdit = 'out';
    }
    if (this.menuStateEmail == 'in') {
      this.menuStateEmail = 'out';
    }

    if (this.menuStateTaskEdit == 'in') {
      this.menuStateTaskEdit = 'out';
    }
    if (this.menuStateEmailEdit == 'in') {
      this.menuStateEmailEdit = 'out';
    }
    if (this.menuStateProjectEdit == 'in') {
      this.menuStateProjectEdit = 'out';
    }

    this.menuStateProjectUserEdit = this.menuStateProjectUserEdit === 'out' ? 'in' : 'out';
  }
  async getalllog() {
    var result = await this.DetailsService.allActivitiyForProject(this.projectId);
    this.result1 = result;
    this.allActivityArr = this.result1;
    this.spinner.hide();
  }

  jobHandlingPage(projectId) {
    this.router.navigate(["/job", projectId]);
  }
  projectJobMenu(projectId, jobId, value) {
    this.router.navigate(["/jobPost", projectId, jobId, 1, value]);
  }
  getprojectStatus(id) {
    this.projectStatus = [];
    for (let i = 0; i < this.projectArr.length; i++) {
      if (id != this.projectArr[i].jobid) {
        this.projectStatus.push({ "id": this.projectArr[i].jobid, "itemName": this.projectArr[i].jobName, })
      }
    }
  }
  arr_diff(a1, a2) {
    var a = [], diff = [];

    for (var i = 0; i < a1.length; i++) {
      a[a1[i]] = true;
    }

    for (var i = 0; i < a2.length; i++) {
      if (a[a2[i]]) {
        delete a[a2[i]];
      } else {
        a[a2[i]] = true;
      }
    }

    for (var k in a) {
      diff.push(k);
    }

    return diff;
  }
  arr_diff_cust(a1, a2) {
    var a = [], diff = [];

    for (var i = 0; i < a1.length; i++) {
      a[a1[i]] = true;
    }

    for (var i = 0; i < a2.length; i++) {
      if (a[a2[i]]) {
        delete a[a2[i]];
      } else {
        a[a2[i]] = true;
      }
    }

    for (var k in a) {
      diff.push(k);
    }

    return diff;
  }
  projectUserValidate() {
    this.projectUserErr = null;
    var valid = true;
    var data = this.projectUserForm.value;
    if (!data.userId) {
      this.projectUserErr = "User is required";
      valid = false;
    }
    return valid;
  }
  submitProjectUser() {
    var data1 = {};
    if (this.projectUserValidate()) {
      this.spinner.show();
      var data = this.projectUserForm.value;
      data1 = { "userId": data.userId[0].id, "roleId": data.userId[0].roleId, "projectId": this.projectId, "createdBy": this.userid }
      this.http.post('/api/projectUserRole', data1).subscribe((response) => {
        this.res = response;
        if (this.res.success == true) {
          this.toastr.clear();
          this.toastr.success(this.res.message, 'Success', {
            timeOut: 6000,
            closeButton: true
          });
          this.projectUsersArr = [];
          this.roleDetails(this.projectId);
          this.spinner.hide();
          this.toggleAddProjectUser(1);
          // this.getDetails();
          // this.getDetailsOnly();
          this.spinner.hide();
        } else {
          this.toastr.clear();
          this.toastr.error(this.res.message, 'Error', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
        this.toggleAddProjectUser(1);
      })
    }
  }

  RemoveUserFromProject(id) {
    this.confirmService.confirm('Please confirm..', 'Do you want to remove the user ?')
      .then((confirmed) => {
        if (confirmed) {
          this.spinner.show();
          this.http.delete('/api/projectUserRole/' + id).subscribe((response) => {
            this.res = response;
            if (this.res.success == true) {
              this.toastr.clear();
              this.toastr.success(this.res.message, 'Success', {
                timeOut: 6000,
                closeButton: true
              });
              this.projectUsersArr = [];
              this.roleDetails(this.projectId);
              this.spinner.hide();
              // this.getDetails();
              // this.getDetailsOnly();
            } else {
              this.toastr.clear();
              this.toastr.error(this.res.message, 'Error', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
            }
          }, error => {
            this.toastr.clear();
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.errorService.handleError(error);
          });
        }
      })
      .catch(() => {
        return false;
      });
  }
  getJobs() {
    this.http.get('/api/projectJob/' + this.projectId).subscribe((res: Response) => {
      this.result = res;
      for (let i = 0; i < this.result.length; i++) {
        if (this.isProjectatt == true) {
          var edit = "false";
          if (this.result[i].assigned.name != null) {
            if (this.userid == this.result[i].assigned.id) {
              edit = "true";
            }
          }
          this.projectArr.push({
            "jobid": this.result[i].job.id,
            "jobName": this.result[i].job.name,
            "statename": this.result[i].state.name,
            "startDate": this.result[i].job.startDate,
            "endDate": this.result[i].job.endDate,
            "isCompleted": this.result[i].job.isCompleted,
            "assigned": this.result[i].assigned.name,
            "edit": edit,
            "createdDate": this.result[i].job.createdDate,
            "statusId": this.result[i].job.statusId
          })
        } else {
          var edit = "true";
          this.projectArr.push({
            "jobid": this.result[i].job.id,
            "jobName": this.result[i].job.name,
            "statename": this.result[i].state.name,
            "startDate": this.result[i].job.startDate,
            "endDate": this.result[i].job.endDate,
            "isCompleted": this.result[i].job.isCompleted,
            "assigned": this.result[i].assigned.name,
            "edit": edit,
            "createdDate": this.result[i].job.createdDate,
            "statusId": this.result[i].job.statusId
          })
        }
        var subJobDetails = this.result[i].subJobs;
        if (subJobDetails.length > 0) {
          for (let i = 0; i < subJobDetails.length; i++) {
            this.subJobDetailsArr.push({
              "parentId": subJobDetails[i].subJob.parentJobId,
              "subJobName": subJobDetails[i].subJob.name,
              "statusId": subJobDetails[i].subJob.statusId,
              "subJobId": subJobDetails[i].subJob.id,
              "startDate": subJobDetails[i].subJob.startDate,
              "createdDate": subJobDetails[i].subJob.createdDate,
              "endDate": subJobDetails[i].subJob.endDate,
              "subJobAssoName": subJobDetails[i].subJobAsso.name,
              "subJobAssoId": subJobDetails[i].subJobAsso.id,
            })
          }
        }
      }
      this.spinner.hide();
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  getUserDetails() {
    this.http.get('/api/userPreferences/' + this.userid).subscribe((res: Response) => {
      this.result = res;
      if (this.result.length != 0) {
        this.mailSignnature = this.result[0].mailSignature;
        var paragraph = "<p><br></p><p><br></p>";
        var sign = $(this.mailSignnature).text();
        this.emailForm.patchValue({ 'bodyText': this.mailSignnature });
        if (this.result[0].isAccountAdded == "Y") {
          this.syncmail = this.result[0].email
          this.syncheck = true;
        } else {
          this.syncheck = false;
        }
      }

    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  getEmailSync() {
    this.toggleEmail(1);
    this.confirmService.confirm(' Add Google Account..', 'Email?')
      .then((confirmed) => {
        if (confirmed) {
          const url = '/api/mail/gsync/' + this.userid;
          window.open(url);
        }
      });

  }
  getFromEmail() {
    this.fromEmailIdArr =[];
    this.http.get('/api/mail/' + this.userid).subscribe((res: Response) => {
      this.result = res;
      if (this.result.length != 0) {
        this.fromEmailIdArr.push({
          "fromId": this.result[0].email
        })
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });

  }

  toggleonlyEmail(num) {
    if (num == 1) {
      this.emailErr = null;
      this.emailccErr = null;
      this.emailbccErr = null;
      this.filemailErr = null;
      this.opened = null;
      this.isCreate = true;
      this.resetForms();
      this.deleteFilemailarr();
    }
    if (num == 0) {
      this.menuStateEmail = this.menuStateEmail === 'out' ? 'in' : 'out';
    }
  }
  submitEmail(id) {
    if (this.emailValidate(0)) {
      if (this.emailValidate(1)) {
        if (this.emailValidate(2)) {
          let data = this.emailForm.value;
          data.projectId = this.projectId;
          if (!data.subject && !data.bodyText) {
            this.toggleonlyEmail(0);
            this.confirmService.confirm('Email Alert', 'Send this message without a subject or text in the body?')
              .then((confirmed) => {
                if (confirmed) {
                  this.spinner.show();
                  this.fd = new FormData();
                  this.fd.append('bcc', data.bcc);
                  this.fd.append('cc', data.cc);
                  this.fd.append('from', id);
                  this.fd.append('projectId', this.projectId);
                  this.fd.append('to', data.to);
                  this.fd.append('subject', data.subject);
                  this.fd.append('bodyText', data.bodyText);
                  this.mailArr.forEach(r => {
                    this.filesatt.push(r.location);
                  });
                  if (this.filesatt) {
                    this.fd.append('file', this.filesatt);
                  }
                  this.http.post('api/mail/send/' + this.userid, this.fd).subscribe((response) => {
                    this.res = response;
                    if (this.res.success == true) {
                      this.toastr.clear();
                      this.toastr.success(this.res.message, 'Success', {
                        timeOut: 6000,
                        closeButton: true
                      });
                      this.toggleonlyEmail(1);
                      if (this.filesatt) {
                        this.deleteFilemailarr();
                      }
                      this.mailContentsArr = [];
                      this.getMailContent();

                      this.spinner.hide();
                    } else {
                      this.toastr.clear();
                      this.toastr.error(this.res.message, 'Error', {
                        timeOut: 6000,
                        closeButton: true
                      });
                      this.spinner.hide();
                    }
                  }, error => {
                    this.errorService.handleError(error);
                    this.spinner.hide();
                    if (this.filesatt) {
                      this.deleteFilemailarr();
                    }
                  })
                }
              }).catch(() => {
                this.spinner.hide();
                this.toggleonlyEmail(0);
              });
          } else {
            this.spinner.show();
            this.fd = new FormData();
            this.fd.append('bcc', data.bcc);
            this.fd.append('cc', data.cc);
            this.fd.append('from', id);
            this.fd.append('projectId', this.projectId);
            this.fd.append('to', data.to);
            this.fd.append('subject', data.subject);
            this.fd.append('bodyText', data.bodyText);
            this.mailArr.forEach(r => {
              this.filesatt.push(r.location);
            });
            if (this.filesatt) {
              this.fd.append('file', this.filesatt);
            }
            this.http.post('api/mail/send/' + this.userid, this.fd).subscribe((response) => {
              this.res = response;
              if (this.res.success == true) {
                this.toastr.clear();
                this.toastr.success(this.res.message, 'Success', {
                  timeOut: 6000,
                  closeButton: true
                });
                this.toggleEmail(1);
                if (this.filesatt) {
                  this.deleteFilemailarr();
                }
                this.mailContentsArr = [];
                this.getMailContent();
                this.tab02 = false;
                this.spinner.hide();
              } else {
                this.toastr.clear();
                this.toastr.error(this.res.message, 'Error', {
                  timeOut: 6000,
                  closeButton: true
                });
                this.spinner.hide();
              }
            }, error => {
              this.errorService.handleError(error);
              this.spinner.hide();
              if (this.filesatt) {
                this.deleteFilemailarr();
              }
            })
          }
        }
      }
    }
  }
  emailRefresh() {
    this.spinner.show();
    this.http.get('/api/mail/read/' + this.userid).subscribe((res: Response) => {
      var result = res;
      this.toastr.clear();
      this.spinner.hide();
      this.toastr.success('Mail Sync', 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.getMailContent();
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
    this.mailContentsArr = [];

  }

  CustForMail() {
    if (this.emailForm.value.to) {
      if (this.customermail.length != 0) {
        var iterator = this.customermail.toString();
        var add = this.emailForm.value.to + "," + iterator
        this.emailForm.patchValue({ 'to': add });
        this.emailValidate(0);

      }
    } else {
      if (this.customermail.length != 0) {
        var iterator = this.customermail.toString();
        this.emailForm.patchValue({ 'to': iterator });
        this.emailValidate(0);

      }
    }

  }
  getMailContent() {
    this.spinner.show();
    this.http.get('/api/mail/contents/' + this.projectId).subscribe((res: Response) => {
      this.result = res;
      if (this.result) {
        this.mail = {};
        this.result.forEach(r => {
          var recipient = r.recipient;
          var tomail = [];
          var bccmail = [];
          var ccmail = [];
          var frommail = [];
          for (let i = 0; i < r.recipient.length; i++) {
            if (r.recipient[i].type == 'To') {
              tomail.push(r.recipient[i].email)
            }
            if (r.recipient[i].type == 'Bcc') {
              bccmail.push(r.recipient[i].email)
            }
            if (r.recipient[i].type == 'Cc') {
              ccmail.push(r.recipient[i].email)
            }
            if (r.recipient[i].type == 'From') {
              frommail.push(r.recipient[i].email)
            }
          }
          this.mailContentsArr.push({
            "id": r.content.id, "subject": r.content.subject, "body": r.content.minimalBody, "messageId": r.content.messageId, "recipient": r.recipient, "tomail": tomail, "bccmail": bccmail, "ccmail": ccmail, "frommail": frommail,
            "createdDate": r.content.createdDate
          })
          recipient.forEach(rs => {
            this.mail[rs.messageId] = rs;
            if (rs.type == "To") {
              var mailid = rs.email
            }
            this.mailSendArr.push({
              "id": rs.id, "tomail": mailid
            })
          })
          this.spinner.hide();
        })
      }
      this.spinner.hide();
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  emailEditMenu(id) {
    if (this.popoverformmail == true) {
      this.popoverformmail = false;
    } else {
      this.popoverformmail = true;
    }
    this.tab01 = false;
    this.tab02 = false;
    this.tab03 = false;
    this.tab06 = false;
    this.mailDetailsArr = [];
    this.mailAttachmentArr = [];
    this.isCreate = false;
    this.http.get('/api/mail/message/' + id).subscribe((res: Response) => {
      this.result = res;
      var attachment1 = this.result.attachment;
      this.mail = {};
      this.result.recipient.forEach(r => {
        if (r.type == 'From' || r.type == 'To' || r.type == 'Bcc' || r.type == 'Cc') {
          if (this.syncmail == r.email) {
            this.gmailsyn = true;
          }
        }
      });
      attachment1.forEach(r => {
        this.mailAttachmentArr.push({
          "attachment": r.attachment
        })
      })

      var tomail = [];
      var bccmail = [];
      var ccmail = [];
      var frommail = [];
      for (let i = 0; i < this.result.recipient.length; i++) {
        if (this.result.recipient[i].type == 'To') {
          tomail.push(this.result.recipient[i].email)
        }
        if (this.result.recipient[i].type == 'Bcc') {
          bccmail.push(this.result.recipient[i].email)
        }
        if (this.result.recipient[i].type == 'Cc') {
          ccmail.push(this.result.recipient[i].email)
        }
        if (this.result.recipient[i].type == 'From') {
          frommail.push(this.result.recipient[i].email)
        }
      }
      this.mailDetailsArr.push({
        "subject": this.result.content.subject, "body": this.result.content.body, "messageId": this.result.content.messageId, "createdDate": this.result.content.createdDate, "msgRedirectId": this.result.content.msgRedirectId, "recipient": this.result.recipient,
        "attachment": this.result.attachment.attachment, "tomail": tomail, "bccmail": bccmail, "ccmail": ccmail, "frommail": frommail
      });

    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });

    this.menuStateEmailEdit = this.menuStateEmailEdit === 'out' ? 'in' : 'out';

  }
  closeEmail() {
    this.popoverformmail = false;
    this.menuStateEmailEdit = this.menuStateEmailEdit === 'out' ? 'in' : 'out';
  }
  btnClick = function () {
    this.router.navigateByUrl('/profile/mailsync');
  };
  deleteFilemailarr() {
    this.mailArr.forEach(r => {
      this.deleteFilemail(r.id)
    });
    this.filesatt = [];
  }

  downloadFiles(fid) {
    const url = '/unsecure/download/' + fid;
    window.open(url, "_self");
  }

  EnableGmail(link) {
    var url = "https://mail.google.com/mail/#search/rfc822msgid%3A" + encodeURIComponent(link);
    window.open(url);
  }
  getEmailDetails(e) {
    this.myFilesEmail = [];
    for (var i = 0; i < e.target.files.length; i++) {
      this.myFilesEmail.push(e.target.files[i]);
      if (this.myFilesEmail.length == e.target.files.length) {
        this.postMethodmail();
      }
    }

  }
  postMethodmail() {
    if (this.clearFile(1)) {
      this.spinner.show();
      const frmData = new FormData();
      if (this.myFilesEmail.length != 0) {
        for (var i = 0; i < this.myFilesEmail.length; i++) {
          frmData.append("file", this.myFilesEmail[i]);
        }
        frmData.append("description", "");
        this.http.post('/api/upload/mail/' + this.projectId + '/' + this.userid, frmData).subscribe((response) => {
          this.res = response;
          if (this.res.successArr.length > 0) {
            this.toastr.clear();
            this.toastr.success(this.res.successArr[0].message, 'Success', {
              timeOut: 6000,
              closeButton: true
            });
            this.opened = false;
            this.getmailfiles();
            this.spinner.hide();
          } else if (this.res.failureArr.length > 0) {
            this.toastr.clear();
            this.toastr.error(this.res.failureArr[0].message, 'Error', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
          }
        }, error => {
          this.toastr.clear();
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.errorService.handleError(error);
        });
        this.fileToEmail = [];
        this.emailAttachReset();
      }
    }

  }
  getmailfiles() {
    this.mailArr = [];
    this.http.get('/api/files/mail/' + this.projectId).subscribe((res1: Response) => {
      this.result = res1;
      for (let i = 0; i < this.result.length; i++) {
        this.mailArr.push({
          "id": this.result[i].id, "itemName": this.result[i].uploadName, "location": this.result[i].location
        })
      }

    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }

  deleteFilemail(id) {
    if (id) {
      this.http.delete('/api/files/permanent/' + id).subscribe((response) => {
        this.res = response;
        if (this.res.success == true) {
          // this.toastr.clear();
          // this.toastr.success(this.res.message, 'Success', {
          //   timeOut: 6000,
          //   closeButton: true
          // });
          this.getmailfiles();
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
      this.mailArr = [];

    } else {
      return false;
    }
  }
  getjobsummary() {
    this.spinner.show();
    this.http.get('/api/projectJob/' + this.projectId).subscribe((response) => {
      this.res = response;
      this.chartDataJob = [];
      this.res.forEach(u => {
        var chartDataJob = []
        if (u.job) {
          var job = [];
          job.push(this.details.title);

          if (u.assigned.name != "") {
            var name = "'" + u.state.name + "' " + u.assigned.name
            job.push(name);
          } else {
            var name = "'" + u.state.name + "' None"
            job.push(name);
          }
          if (u.job.isCompleted == "Y") {
            job.push(this.JOB_COMPLETED);
          } else {
            job.push(this.JOB_IN_PROGRESS);
          }
          if (moment(new Date(u.job.startDate)).format("YYYY-MM-DD") == moment(new Date(u.job.endDate)).format("YYYY-MM-DD")) {
            job.push(new Date(u.job.startDate));
            job.push(new Date(u.job.endDate).getTime() + ((24 * 60 * 60 * 1000) - 1));
          } else {
            job.push(new Date(u.job.startDate));
            job.push(new Date(u.job.endDate));
          }
          chartDataJob.push(job);
          this.chartDataJob.push(job);
        }
      });

      this.spinner.hide();
    })


  }
  getphoto() {
    this.http.get('/api/files/project/photo/' + this.projectId).subscribe((res1: Response) => {
      this.result5 = res1;
      this.uploadPhotoArr = this.result5;
      this.spinner.hide();
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }

  uploadadd() {
    this.containers.push(this.containers.length);
  }


  async getfiles() {
    var result = await this.DetailsService.uploadProjectFile(this.projectId);
    this.result1 = result;
    this.uploadFileArr = this.result1;
    this.getalllog();
    this.allActivityArr = [];
  }
  getFileDetails(files: FileList) {
    this.fileToUpload = files.item(0);
    this.uploadFilesArr.push({
      "fileNames": this.fileToUpload.name,
    })
  }
  downloadFile(fid) {
    const url = '/unsecure/download/' + fid;
    window.open(url, "_self");
  }
  deleteFile(fid) {
    this.confirmService.confirm('Please confirm..', 'Do you want to delete the File ?')
      .then((confirmed) => {
        if (confirmed) {
          this.http.delete('/api/files/' + fid).subscribe((response) => {
            this.res = response;
            if (this.res.success == true) {
              this.toastr.clear();
              this.toastr.success(this.res.message, 'Success', {
                timeOut: 6000,
                closeButton: true
              });
              this.getphoto();
            } else {
              this.toastr.clear();
              this.toastr.error(this.res.message, 'Error', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
            }
          }, error => {
            this.toastr.clear();
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.errorService.handleError(error);
          });
          this.uploadPhotoArr = [];
        }
      })
      .catch(() => {
        return false;
      });
  }
  projectInfoCode() {
    this.http.get('/api/projectInfo/project/' + this.projectId).subscribe((res: Response) => {
      this.result = res;
      this.projectInfoID = this.result.ProjectInfoId;
      this.getprojectinfo(this.projectInfoID);
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  getprojectinfo(id) {
    this.http.get('/api/projectInfo/' + id).subscribe((res: Response) => {
      this.result = res;
      this.approvalproArr =[];
      if (this.result) {
        var duration = moment(this.result.projectInfo.projectStart).format("YYYY-MM-DD") + " to " + moment(this.result.projectInfo.projectEnd).format("YYYY-MM-DD")
        this.approvalproArr.push({
          "managerName": this.result.persons.manager, "certifierName": this.result.persons.certifier, "address": this.result.projectAddress, "duration": duration
        });
        this.projectInfoDetails = this.result.projectInfo;
        if (this.projectInfoDetails.approvedBy != null) {
          this.signpat = true;
          this.openeds = true;
          this.isSubmit = false;
          this.isSubmitZipFile = true;
        } else {
          this.openeds = false;
          this.signpat = false;
          this.isSubmitZipFile = false;
        }
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  taskValidate(num) {
    if (num == 0) {
      this.nameErr = null;
      this.custErr = null;
      this.StartdateErr = null;
      this.dateErr = null;
      this.detailsErr = null;

      var valid = true;
      var data = this.TaskForm.value;
      if (!data.name) {
        this.nameErr = "Name is required";
        valid = false;
      }
      else if (data.userId.length == 0) {
        this.custErr = "User is required";
        valid = false;
      }
      else if (!data.startBy) {
        this.StartdateErr = "Start Date is required";
        valid = false;
      }
      else if (!data.completeBy) {
        this.dateErr = " End Date is required";
        valid = false;
      }
      else if (data.startBy && data.completeBy) {
        var date1 = ("0" + (data.startBy.date.day)).slice(-2);
        var month1 = ("0" + (data.startBy.date.month)).slice(-2);
        var date2 = ("0" + (data.completeBy.date.day)).slice(-2);
        var month2 = ("0" + (data.completeBy.date.month)).slice(-2);
        var startBy = data.startBy.date.year + "-" + month1 + "-" + date1
        var completeBy = data.completeBy.date.year + "-" + month2 + "-" + date2
        if (startBy > completeBy) {
          if (startBy == completeBy) {
            this.dateErr = null;
          } else {
            this.dateErr = " End Date is must greater than Start Date";
            valid = false;
          }
        }
        else if (!data.details) {
          this.detailsErr = "Details is required";
          valid = false;
        }
      }

      return valid;
    } else if (num == 2) {
      this.nameErr = null;
      this.custErr = null;
      this.StartdateErr = null;
      this.dateErr = null;
      this.detailsErr = null;

      var valid = true;
      var data = this.TaskForm.value;
      if (!data.name) {
        this.nameErr = "Name is required";
        valid = false;
      }
      if (data.userId) {
        if (data.userId.length == 0) {
          this.custErr = "User is required";
          valid = false;
        }
      }
      if (!data.startBy) {
        this.StartdateErr = "Start Date is required";
        valid = false;
      }
      if (!data.completeBy) {
        this.dateErr = "End Date is required";
        valid = false;
      }
      if (data.startBy && data.completeBy) {
        var date1 = ("0" + (data.startBy.date.day)).slice(-2);
        var month1 = ("0" + (data.startBy.date.month)).slice(-2);
        var date2 = ("0" + (data.completeBy.date.day)).slice(-2);
        var month2 = ("0" + (data.completeBy.date.month)).slice(-2);
        var startBy = data.startBy.date.year + "-" + month1 + "-" + date1
        var completeBy = data.completeBy.date.year + "-" + month2 + "-" + date2
        if (startBy > completeBy) {
          if (startBy == completeBy) {
            this.dateErr = null;
          } else {
            this.dateErr = " End Date is must greater than Start Date";
            valid = false;
          }
        }
      }
      if (!data.details) {
        this.detailsErr = "Details is required";
        valid = false;
      }
      return valid;
    } else {
      this.isCreate1 = true;
      this.custErr = null;
      this.StartdateErr = null;
      this.dateErr = null;
      this.detailsErr = null;
      var valid = true;
      var data = this.TaskForm.value;
      if (data.userId) {
        if (data.userId.length == 0) {
          this.custErr = "User is required";
          valid = false;
        }
      }
      if (!data.startBy) {
        this.StartdateErr = "Start Date is required";
        valid = false;
      }
      if (!data.completeBy) {
        this.dateErr = " End Date is required";
        valid = false;
      }
      if (data.startBy && data.completeBy) {
        var date1 = ("0" + (data.startBy.date.day)).slice(-2);
        var month1 = ("0" + (data.startBy.date.month)).slice(-2);
        var date2 = ("0" + (data.completeBy.date.day)).slice(-2);
        var month2 = ("0" + (data.completeBy.date.month)).slice(-2);
        var startBy = data.startBy.date.year + "-" + month1 + "-" + date1
        var completeBy = data.completeBy.date.year + "-" + month2 + "-" + date2
        if (startBy > completeBy) {
          if (startBy == completeBy) {
            this.dateErr = null;
          } else {
            this.dateErr = " End Date is must greater than Start Date";
            valid = false;
          }
        }
      }
      // if(!data.details) {
      //     this.detailsErr = "Details is required";
      //     valid = false;
      //  }
      return valid;
    }
  }
  gettask() {
    this.http.get('/api/projectTasks/project/' + this.projectId).subscribe((res: Response) => {
      this.result = res;
      this.taskArr = this.result;
      this.spinner.hide();
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }

  submitTask() {
    if (this.taskValidate(0)) {
      this.spinner.show();
      let data = this.TaskForm.value;
      if (data.userId.length > 0) {
        data.userId = data.userId[0].id;
      }
      if (data.startBy != null) {
        var sdateval = data.startBy.jsdate;
        data.startBy = moment(sdateval).format("YYYY-MM-DD")
      }
      if (data.completeBy != null) {
        var dateval = data.completeBy.jsdate;
        data.completeBy = moment(dateval).format("YYYY-MM-DD")
      }
      data.createdBy = this.userid;
      data.projectId = this.projectId;
      data.domainId = parseInt(this.loggedInUserDomain);
      data.type = "PROJECT";
      this.http.post('api/task/', data).subscribe((response) => {
        this.res = response;
        if (this.res.success == true) {
          this.toastr.clear();
          this.toastr.success(this.res.message, 'Success', {
            timeOut: 6000,
            closeButton: true
          });
          this.custArrselect = [];
          this.tab04 = false;
          // this.toggleAddTask();
          this.gettask();
          this.spinner.hide();
        } else {
          this.toastr.clear();
          this.toastr.error(this.res.message, 'Error', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
      this.taskArr = [];
      // this.taskCommentArr = [];

    }
  }

  taskEditMenu(tid, num) {
    if (this.popoverformTask == true) {
      this.popoverformTask = false;
    } else {
      this.popoverformTask = true;
    }
    this.tab02 = false
    this.tab01 = false
    this.tab03 = false;
    this.tab06 = false;
    this.tab04 = false;
    if (num == 0) {
      this.taskreset();
      this.custArrselect = [];
      this.custErr = null;
      this.nameErr = null;
      this.detailsErr = null;
      this.isCreate1 = null;
      this.iscurrdis = null;
      this.isCreate = false;
      this.tid = tid;
      this.http.get('/api/task/' + tid).subscribe((res: Response) => {
        this.result = res;
        this.taskcommentId = this.result.id;
        this.taskCommentArr.push({
          "taskName": this.result.name, "taskcommId": this.result.id
        })
        this.getTaskComments(this.taskcommentId);
        if (this.result) {
          this.TaskForm.patchValue({ 'id': this.result.id });
          this.TaskForm.patchValue({ 'name': this.result.name });
          this.TaskForm.patchValue({ 'details': this.result.details });
          this.TaskForm.patchValue({ 'isActive': this.result.isActive });
          this.TaskForm.patchValue({ 'projectId': this.result.projectId });
          this.TaskForm.patchValue({ 'createdAt': this.result.createdAt });
          this.TaskForm.patchValue({ 'updatedAt': this.result.updatedAt });
          this.TaskForm.patchValue({ 'createdBy': this.result.createdBy });
          this.TaskForm.patchValue({ 'updatedBy': this.result.updatedBy });
        }


        if (this.result.userId == this.userid) {
          this.iscurrdis = true;
          if (this.result.isTaken == "Y") {
            this.TaskForm.patchValue({ 'isTaken': true });
            this.istakenHide = true;
            this.iscompleteDis = true;
            this.isdis = true;
            if (this.result.isCompleted == "Y") {
              this.TaskForm.patchValue({ 'isCompleted': true });
              this.resetTask = true;
              this.TaskForm.disable();
              this.taskComment.disable();
            } else {
              this.TaskForm.patchValue({ 'isCompleted': false });
              this.resetTask = false;
              this.TaskForm.enable();
              this.taskComment.enable();
            }
          } else {
            this.TaskForm.patchValue({ 'isTaken': false });
            this.iscompleteDis = false;
            this.istakenHide = false;
            this.isdis = false;

          }
        }
        if(this.admin == "Y"){
          this.isdis = false;
        }


        var scdate = this.result.startBy;
        if (scdate) {
          var ydate = moment(scdate).format("YYYY")
          var mdate = moment(scdate).format("M")
          var ddate = moment(scdate).format("D")


          this.TaskForm.patchValue({
            'startBy': {
              date: {
                year: ydate,
                month: mdate,
                day: ddate
              }
            }
          });
        } else {
          this.TaskForm.patchValue({ startBy: null });
        }

        var cdate = this.result.completeBy;
        if (cdate) {
          var ydate = moment(cdate).format("YYYY")
          var mdate = moment(cdate).format("M")
          var ddate = moment(cdate).format("D")


          this.TaskForm.patchValue({
            'completeBy': {
              date: {
                year: ydate,
                month: mdate,
                day: ddate
              }
            }
          });
        } else {
          this.TaskForm.patchValue({ completeBy: null });
        }
        this.http.get('/api/user/' + this.result.userId).subscribe((res1: Response) => {
          this.result1 = res1;
          this.custArrselect.push({
            "id": this.result1.details.id, "itemName": this.result1.details.firstName + " " + this.result1.details.lastName,
          })
        }, error => {
          this.toastr.clear();
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.errorService.handleError(error);
        });
        this.spinner.hide();
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
    }
    this.taskCommentArr = [];
    this.taskCommentesAr = [];
    this.taskAttArr = [];
    this.menuStateTaskEdit = this.menuStateTaskEdit === 'out' ? 'in' : 'out';

  }
  // updateTask() {
  //   if (this.taskValidate(2)) {
  //     this.spinner.show();
  //     this.body = {};
  //     this.body["id"] = this.tid;
  //     this.body["name"] = this.TaskForm.value["name"];
  //     this.body["details"] = this.TaskForm.value["details"];
  //     this.body["userId"] = this.TaskForm.value["userId"][0].id;
  //     this.body["projectId"] = this.TaskForm.value["projectId"];
  //     this.body["createdAt"] = this.TaskForm.value["createdAt"];
  //     this.body["updatedAt"] = new Date();
  //     this.body["updatedBy"] = parseInt(this.userid);
  //     this.body["createdBy"] = this.TaskForm.value["createdBy"];
  //     var date1 = ("0" + (this.TaskForm.value["startBy"].date.day)).slice(-2);
  //     var month1 = ("0" + (this.TaskForm.value["startBy"].date.month)).slice(-2);
  //     var date2 = ("0" + (this.TaskForm.value["completeBy"].date.day)).slice(-2);
  //     var month2 = ("0" + (this.TaskForm.value["completeBy"].date.month)).slice(-2);
  //     var startBy = this.TaskForm.value["startBy"].date.year + "-" + month1 + "-" + date1
  //     this.body["startBy"] = startBy;
  //     var completeBy = this.TaskForm.value["completeBy"].date.year + "-" + month2 + "-" + date2
  //     this.body["completeBy"] = completeBy;
  //     this.body["isActive"] = "Y";
  //     if (this.TaskForm.value["isCompleted"] == true) {
  //       this.body["isCompleted"] = "Y";
  //     } else {
  //       this.body["isCompleted"] = "N";
  //     }
  //     if (this.TaskForm.value["isTaken"] == true) {
  //       this.body["isTaken"] = "Y";
  //     } else {
  //       this.body["isTaken"] = "N";
  //     }

  //     this.http.put('/api/task', this.body).subscribe((response) => {
  //       this.spinner.show();
  //       this.resetForms();
  //       this.res = response;
  //       if (this.res.success == true) {
  //         this.toastr.clear();
  //         this.toastr.success(this.res.message, 'Success', {
  //           timeOut: 6000,
  //           closeButton: true
  //         });
  //         this.toggleAddTask();
  //         this.gettask();
  //         this.spinner.hide();
  //       } else {
  //         this.toastr.clear();
  //         this.toastr.error(this.res.message, 'Error', {
  //           timeOut: 6000,
  //           closeButton: true
  //         });
  //         this.spinner.hide();
  //       }
  //     }, error => {
  //       this.toastr.clear();
  //       this.toastr.error(error, 'Report Status', {
  //         timeOut: 6000,
  //         closeButton: true
  //       });
  //       this.errorService.handleError(error);
  //     });
  //     this.taskArr = [];
  //     this.taskCommentArr = [];
  //     this.taskCommentesAr = [];

  //   }

  // }
  updateTask() {
    if (this.taskValidate(2)) {
      this.spinner.show();
      this.body = {};
      this.body["id"] = this.tid;
      this.body["name"] = this.TaskForm.value["name"];
      this.body["details"] = this.TaskForm.value["details"];
      this.body["userId"] = this.TaskForm.value["userId"][0].id;
      this.body["projectId"] = this.TaskForm.value["projectId"];
      this.body["createdAt"] = this.TaskForm.value["createdAt"];
      this.body["updatedAt"] = new Date();
      this.body["updatedBy"] = parseInt(this.userid);
      this.body["createdBy"] = this.TaskForm.value["createdBy"];
      this.body["domainId"] = this.TaskForm.value["domainId"];
      this.body["type"] = "PROJECT";
      var date1 = ("0" + (this.TaskForm.value["startBy"].date.day)).slice(-2);
      var month1 = ("0" + (this.TaskForm.value["startBy"].date.month)).slice(-2);
      var date2 = ("0" + (this.TaskForm.value["completeBy"].date.day)).slice(-2);
      var month2 = ("0" + (this.TaskForm.value["completeBy"].date.month)).slice(-2);
      var startBy = this.TaskForm.value["startBy"].date.year + "-" + month1 + "-" + date1
      this.body["startBy"] = startBy;
      var completeBy = this.TaskForm.value["completeBy"].date.year + "-" + month2 + "-" + date2
      this.body["completeBy"] = completeBy;
      this.body["isActive"] = "Y";
      if (this.TaskForm.value["isCompleted"] == true) {
        this.body["isCompleted"] = "Y";
      } else {
        this.body["isCompleted"] = "N";
      }
      if (this.TaskForm.value["isTaken"] == true) {
        this.body["isTaken"] = "Y";
      } else {
        this.body["isTaken"] = "N";
      }

      this.http.put('/api/task', this.body).subscribe((response) => {
        this.spinner.show();
        this.resetForms();
        this.res = response;
        if (this.res.success == true) {
          this.toastr.clear();
          this.toastr.success(this.res.message, 'Success', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
          this.toggleAddTask();
          this.gettask();
        } else {
          this.toastr.clear();
          this.toastr.error(this.res.message, 'Error', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
      this.taskArr = [];
      // this.taskCommentArr = [];
      this.taskCommentesAr = [];

    }

  }
  submitTaskComment(tid) {
    if (this.taskComment.value.comment) {
      let data = this.taskComment.value;
      data.taskId = tid;
      data.commentBy = this.userid;
      data.createdBy = this.userid;
      this.http.post('api/taskLogs/', data).subscribe((response) => {
        this.res = response;
        if (this.res.success == true) {
          this.toastr.clear();
          this.taskreset();
          this.getTaskComments(this.taskcommentId);
        } else {
          this.toastr.clear();
          this.toastr.error(this.res.message, 'Error', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
      this.taskCommentesAr = [];
      this.taskAttArr = [];
    } else {
      return false;
    }
  }
  getTaskComments(id) {
    var taskCid = id;
    this.http.get('/api/taskComments/' + taskCid).subscribe((res1: Response) => {
      this.result = res1;
      this.result.forEach(r => {
        this.taskCommentesAr.push({
          "id": r.id, "comment": r.comment, "commentBy": r.commentBy, "createdAt": r.createdAt, "commentType": r.commentType
        })
        if (r.commentType == "file") {
          this.taskAttArr.push({
            "id": r.id, "comment": r.comment, "commentBy": r.commentBy, "createdAt": r.createdAt, "attId": r.attachmentId
          })
        }
      })
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }

  Enable() {
    this.isdis = false;
    this.TaskForm.enable();
    this.taskComment.enable();
  }
  // getTaskFile(files: FileList) {
  //   this.fileToUpload = files.item(0);
  //   this.uploadFilesArr.push({
  //     "fileNames": this.fileToUpload.name,
  //   })
  // }
  getTaskFile(e) {
    this.myFilesTask = [];
    this.myFilesTaskArr = [];
    for (var i = 0; i < e.target.files.length; i++) {
      this.myFilesTask.push(e.target.files[i]);
      this.myFilesTaskArr.push({
        "name": e.target.files[i].name
      })
    }
  }

  // taskFilePost(tid) {
  //   if (this.clearTaskFile()) {
  //     this.spinner.show();
  //     let data = this.taskComment.value;
  //     const id = +this.route.snapshot.paramMap.get('id');
  //     this.projectId = id;
  //     var formData = new FormData();
  //     formData.append("file", this.fileToUpload, this.fileToUpload.name);
  //     this.http.post('/api/upload/approval/' + this.projectId + '/' + this.userid, formData).subscribe((response) => {
  //       this.toastr.clear();
  //       this.res = response;
  //       if (this.res.success == true) {
  //         this.toastr.clear();
  //         data.taskId = tid;
  //         data.commentBy = this.userid;
  //         data.createdBy = this.userid;
  //         data.attachmentId = this.res.response.id;
  //         data.comment = this.res.response.uploadName;
  //         this.http.post('/api/taskLogs/attachment', data).subscribe((response) => {
  //           this.res1= response;
  //           if (this.res1.success == true) {
  //             this.toastr.clear();
  //             this.toastr.success(this.res1.message, 'Success', {
  //               timeOut: 6000,
  //               closeButton: true
  //             });
  //             this.getTaskComments(tid);
  //             this.spinner.hide();
  //           } else {
  //             this.toastr.clear();
  //             this.toastr.error(this.res1.message, 'Error', {
  //               timeOut: 6000,
  //               closeButton: true
  //             });
  //             this.spinner.hide();
  //           }
  //         }, error => {
  //           this.toastr.clear();
  //           this.toastr.error(error, 'Report Status', {
  //             timeOut: 6000,
  //             closeButton: true
  //           });
  //           this.errorService.handleError(error);
  //         });
  //         this.taskAttArr = [];
  //         this.taskCommentesAr = [];
  //       } else {
  //         this.toastr.clear();
  //         this.toastr.error(this.res.message, 'Error', {
  //           timeOut: 6000,
  //           closeButton: true
  //         });
  //         this.spinner.hide();
  //       }
  //     }, error => {
  //       this.toastr.clear();
  //       this.toastr.error(error, 'Report Status', {
  //         timeOut: 6000,
  //         closeButton: true
  //       });
  //       this.errorService.handleError(error);
  //     });
  //     return false;
  //   }
  // }
  taskFilePost(tid) {
    if (this.clearTaskFile()) {
      this.spinner.show();
      let data = this.taskComment.value;
      const id = +this.route.snapshot.paramMap.get('id');
      this.projectId = id;
      const frmData = new FormData();
      for (var i = 0; i < this.myFilesTask.length; i++) {
        frmData.append("file", this.myFilesTask[i]);
      }
      frmData.append("description", "");
      this.http.post('/api/upload/approval/' + this.projectId + '/' + this.userid, frmData).subscribe((response) => {
        this.res = response;
        if (this.res.successArr.length > 0) {
          // this.myFilesTaskArr = [];
          var lastarr = this.res.successArr.length - 1;
          data.taskId = tid;
          data.commentBy = this.userid;
          data.createdBy = this.userid;
          for (var i = 0; i < this.res.successArr.length; i++) {
            data.attachmentId = this.res.successArr[i].response.id;
            data.comment = this.res.successArr[i].response.uploadName;
            this.http.post('/api/taskLogs/attachment', data).subscribe((response) => {
              this.toastr.clear();
              this.res1 = response;
              if (this.res1.success == true) {
                this.taskreset();
                this.toastr.clear();

                this.toastr.success(this.res1.message, 'Success', {
                  timeOut: 6000,
                  closeButton: true
                });
              } else {
                this.toastr.clear();
                this.toastr.error(this.res.message, 'Error', {
                  timeOut: 6000,
                  closeButton: true
                });
                this.spinner.hide();
              }
              this.spinner.hide();
            }, error => {
              this.toastr.clear();
              this.toastr.error(error, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.errorService.handleError(error);
            });
            if (lastarr == i) {
              setTimeout(() => {
                this.taskAttArr = [];
                this.taskCommentesAr = [];
                this.myFilesTaskArr = [];
                this.getTaskComments(tid);
              }, 2000);
            }
          }
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
      return false;
    }
  }
  getdocuments() {
    this.http.get('/api/fileMap/project/' + this.projectId).subscribe((res1: Response) => {
      this.result = res1;
      this.nodes = this.result;
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  togglefoler(num, treeModel: any) {
    if (treeModel.activeNodes.length > 0) {
      let last: any = treeModel.activeNodes[treeModel.activeNodes.length - 1];
      let type = last.data.type;
      if (type == "folder") {
        this.folderErr = null;
        this.resetForms();
        if (num == 0) {
          this.isCreate = true;
          if (this.fol == false) {
            this.fol = true;
            if (this.doc == true) {
              this.doc = false;
            }
          } else {
            this.fol = false;
          }
        } else if (num == 1) {
          this.isCreate = false;
          if (this.fol == false) {
            this.fol = true;
            if (this.doc == true) {
              this.doc = false;
            }
          } else {
            this.fol = false;
          }
        } else {
          this.fol = false;
        }
      } else {
        this.toastr.clear();
        this.toastr.error('Please Select the Folder', 'Status', {
          timeOut: 6000,
          closeButton: true
        });
      }
    } else {
      this.folderErr = null;
      this.resetForms();
      if (num == 0) {
        this.isCreate = true;
        if (this.fol == false) {
          this.fol = true;
          if (this.doc == true) {
            this.doc = false;
          }
        } else {
          this.fol = false;
        }
      } else if (num == 1) {
        this.isCreate = false;
        if (this.fol == false) {
          this.fol = true;
          if (this.doc == true) {
            this.doc = false;
          }
        } else {
          this.fol = false;
        }
      } else {
        this.fol = false;
      }
    }



  }
  downloadNodes(treeModel: any) {
    if (treeModel.activeNodes.length > 0) {
      let last: any = treeModel.activeNodes[treeModel.activeNodes.length - 1];
      let type = last.data.type;
      let fid = last.data.id;
      if (type == "file") {
        this.downloadFile(fid)
      }
    }
  }
  foldercheck(treeModel: any) {
    if (treeModel.activeNodes.length > 0) {
      let last: any = treeModel.activeNodes[treeModel.activeNodes.length - 1];
      let type = last.data.type;
      if (type == "folder") {
        this.folderval = true;
      } else {
        this.folderval = false;
      }
    }
  }
  toggledocument(num, treeModel: any) {
    if (treeModel.activeNodes.length > 0 && num == 0) {
      let last: any = treeModel.activeNodes[treeModel.activeNodes.length - 1];
      let type = last.data.type;
      if (type == "folder") {
        this.documentErr = null;
        this.resetForms();
        if (this.doc == false) {
          this.doc = true;
          if (this.fol == true) {
            this.fol = false;
          }
        } else {
          this.doc = false;
        }
      } else {
        this.toastr.clear();
        this.toastr.error('Please Select the Folder', 'Status', {
          timeOut: 6000,
          closeButton: true
        });
      }
    } else if (num == 1) {
      this.documentErr = null;
      this.resetForms();
      this.doc = false;
    } else {
      this.toastr.clear();
      this.toastr.error('Please Select the Folder', 'Status', {
        timeOut: 6000,
        closeButton: true
      });
    }
  }
  editNodes(treeModel: any) {
    if (treeModel.activeNodes.length > 0) {
      let last: any = treeModel.activeNodes[treeModel.activeNodes.length - 1];
      let rootid = last.data.id;
      let type = last.data.type;
      if (type == "folder") {
        this.togglefoler(1, treeModel);
        this.http.get('/api/fileFolder/' + rootid).subscribe((res: Response) => {
          this.result = res;
          if (this.result) {
            this.folderform.patchValue({ 'id': this.result.id });
            this.folderform.patchValue({ 'folder': this.result.folderName });
            this.folderform.patchValue({ 'rootId': this.result.rootId });
            this.folderform.patchValue({ 'isActive': this.result.isActive });
            this.folderform.patchValue({ 'projectId': this.result.projectId });
            this.folderform.patchValue({ 'createdDate': this.result.createdDate });
            this.folderform.patchValue({ 'updatedDate': this.result.updatedDate });
            this.folderform.patchValue({ 'createdBy': this.result.createdBy });
          }
        }, error => {
          this.toastr.clear();
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.errorService.handleError(error);
        });
      } else {
        this.toastr.clear();
        this.toastr.error('Please Select the Folder', 'Status', {
          timeOut: 6000,
          closeButton: true
        });
      }
    } else {
      this.toastr.clear();
      this.toastr.error('Please Select the Folder', 'Status', {
        timeOut: 6000,
        closeButton: true
      });
    }
  }

  updateNodes() {
    if (this.folderValidate()) {
      this.spinner.show();
      this.body = {};
      this.body["id"] = this.folderform.value["id"];
      this.body["folderName"] = this.folderform.value["folder"];
      this.body["rootId"] = this.folderform.value["rootId"];
      this.body["isActive"] = this.folderform.value["isActive"];
      this.body["projectId"] = this.folderform.value["projectId"];
      this.body["createdDate"] = this.folderform.value["createdDate"];
      this.body["updatedDate"] = new Date();
      this.body["updatedBy"] = parseInt(this.userid);
      this.body["createdBy"] = this.folderform.value["createdBy"];
      this.http.put('/api/fileFolder', this.body).subscribe((response) => {
        this.res = response;
        if (this.res.success == true) {
          this.toastr.clear();
          this.toastr.success(this.res.message, 'Success', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
          this.nodes = [];
          this.emptyarr["activeNodes"] = [];
          this.togglefoler(2, this.emptyarr)
          this.getdocuments();
        } else {
          this.spinner.hide();
          this.toastr.clear();
          this.toastr.error(this.res.message, 'Error', {
            timeOut: 6000,
            closeButton: true
          });
        } error => {
          this.errorService.handleError(error);
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
    }
  }
  addNodes(treeModel: any) {
    if (treeModel.activeNodes.length > 0) {
      let last: any = treeModel.activeNodes[treeModel.activeNodes.length - 1];
      let rootid = last.data.id;
      var data1 = {};
      if (this.folderValidate()) {
        this.spinner.show();
        var data = this.folderform.value;
        data1 = { "folderName": data.folder, "rootId": rootid, "projectId": this.projectId, "createdBy": parseInt(this.userid) }
        this.http.post('/api/fileFolder', data1).subscribe((response) => {
          this.res = response;
          if (this.res.success == true) {
            this.toastr.clear();
            this.toastr.success(this.res.message, 'Success', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
            this.nodes = [];
            this.emptyarr["activeNodes"] = [];
            this.togglefoler(2, this.emptyarr)
            this.getdocuments();
          } else {
            this.spinner.hide();
            this.toastr.clear();
            this.toastr.error(this.res.message, 'Error', {
              timeOut: 6000,
              closeButton: true
            });
          }
        }, error => {
          this.toastr.clear();
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.errorService.handleError(error);
        });
      }
    } else {
      let rootid = 0;
      var data1 = {};
      if (this.folderValidate()) {
        this.spinner.show();
        var data = this.folderform.value;
        data1 = { "folderName": data.folder, "rootId": rootid, "projectId": this.projectId, "createdBy": parseInt(this.userid) }
        this.http.post('/api/fileFolder', data1).subscribe((response) => {
          this.res = response;
          if (this.res.success == true) {
            this.toastr.clear();
            this.toastr.success(this.res.message, 'Success', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
            this.nodes = [];
            this.emptyarr["activeNodes"] = [];
            this.togglefoler(2, this.emptyarr)
            this.getdocuments();
          } else {
            this.spinner.hide();
            this.toastr.clear();
            this.toastr.error(this.res.message, 'Error', {
              timeOut: 6000,
              closeButton: true
            });
          }

        }, error => {
          this.toastr.clear();
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.errorService.handleError(error);
        });
      }
    }
  }

  adddocumentNodes(fileids, rootid) {
    var data1 = {};
    this.spinner.show();
    data1 = { "fileFolderId": rootid, "fileId": fileids, "projectId": this.projectId, "createdBy": parseInt(this.userid) }
    this.http.post('/api/fileMap', data1).subscribe((response) => {
      this.res = response;
      if (this.res.success == true) {
        this.toastr.clear();
        this.toastr.success(this.res.message, 'Success', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
        this.nodes = [];
        this.emptyarr["activeNodes"] = [];
        this.toggledocument(1, this.emptyarr);
        this.getdocuments();
      } else {
        this.toastr.clear();
        this.toastr.error(this.res.message, 'Error', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  deleteNodes(treeModel: any) {
    if (treeModel.activeNodes.length > 0) {
      let last: any = treeModel.activeNodes[treeModel.activeNodes.length - 1];
      let rootid = last.data.id;
      let type = last.data.type;
      if (type == "folder") {
        this.confirmService.confirm('Please confirm..', 'Do you want to remove the Folder ?')
          .then((confirmed) => {
            if (confirmed) {
              this.spinner.show();
              this.http.delete('/api/fileFolder/' + rootid).subscribe((response) => {
                this.res = response;
                if (this.res.success == true) {
                  this.toastr.clear();
                  this.toastr.success(this.res.message, 'Success', {
                    timeOut: 6000,
                    closeButton: true
                  });
                  this.spinner.hide();
                  this.nodes = [];
                  this.getdocuments();
                } else {
                  this.spinner.hide();
                  this.toastr.clear();
                  this.toastr.error(this.res.message, 'Error', {
                    timeOut: 6000,
                    closeButton: true
                  });
                }
              }, error => {
                this.toastr.clear();
                this.toastr.error(error, 'Report Status', {
                  timeOut: 6000,
                  closeButton: true
                });
                this.errorService.handleError(error);
              });
            }
          })
          .catch(() => {
            return false;
          });
      } else {
        this.confirmService.confirm('Please confirm..', 'Do you want to remove the document ?')
          .then((confirmed) => {
            if (confirmed) {
              this.spinner.show();
              this.http.delete('/api/fileMap/' + rootid).subscribe((response) => {
                this.res = response;
                if (this.res.success == true) {
                  this.toastr.clear();
                  this.toastr.success(this.res.message, 'Success', {
                    timeOut: 6000,
                    closeButton: true
                  });
                  this.spinner.hide();
                  this.nodes = [];
                  this.getdocuments();
                } else {
                  this.spinner.hide();
                  this.toastr.clear();
                  this.toastr.error(this.res.message, 'Error', {
                    timeOut: 6000,
                    closeButton: true
                  });
                }
              }, error => {
                this.toastr.clear();
                this.toastr.error(error, 'Report Status', {
                  timeOut: 6000,
                  closeButton: true
                });
                this.errorService.handleError(error);
              });
            }
          })
          .catch(() => {
            return false;
          });
      }

    } else {
      this.toastr.clear();
      this.toastr.error('Please Select the Folder', 'Status', {
        timeOut: 6000,
        closeButton: true
      });
    }
  }
  folderValidate() {
    this.folderErr = null;
    var valid = true;
    var data = this.folderform.value;
    if (!data.folder) {
      this.folderErr = "Folder Name is required";
      valid = false;
    }

    return valid;
  }

  ProjectEditMenu(id, val) {
    this.tab01 = false;
    this.tab02 = false;
    this.tab03 = false;
    this.tab04 = false;
    this.tab05 = false;
    this.tab06 = false;
    if (this.popoverformTask == true) {
      this.popoverformTask = false;
    } else {
      this.popoverformTask = true;
    }
    if (this.isProjectatt == true) {
      this.is_edit = true;

    }
    if (val == "true") {
      this.jobId = id;
      this.projectStateArr = [];
      this.jobArrselect = [];
      this.custErr = null;
      this.StartdateErr = null;
      this.dateErr = null;
      this.isCreate1 = null;
      this.isCreate = false;
      this.getprojectStatus(this.jobId);
      this.http.get('api/projectJob/' + this.projectId + "/" + this.jobId).subscribe((res: Response) => {
        this.result = res;
        this.result1 = this.result.job;
        this.result2 = this.result.assigned;
        this.result3 = this.result.state;
        if (this.result) {
          if (this.result1.assignedToId) {
            this.jobCompleteHide = true;
            this.jobArrselect.push({
              "id": this.result2.id, "itemName": this.result2.name
            })
          } else {
            this.jobCompleteHide = false;
          }
          if (this.result1.isCompleted == "Y") {
            this.jobCompleteHideDis = true;
            this.ProjectJobForm.disable();
            this.ProjectJobForm.patchValue({ 'isCompleted': true });
          } else {
            this.jobCompleteHideDis = false;
            this.ProjectJobForm.enable();
            this.ProjectJobForm.patchValue({ 'isCompleted': false });
          }

          this.projectStateArr.push({
            "stateName": this.result1.name
          })
          this.projectDepended = [];
          if (this.result.dependedJob != null) {
            this.projectDepended.push({
              "id": this.result.dependedJob.id, "itemName": this.result.dependedJob.name
            });
          } else {
            this.projectDepended = [];
          }
          this.projectPredecessor = [];
          if (this.result.predecessorJob != null) {
            this.projectPredecessor.push({
              "id": this.result.predecessorJob.id, "itemName": this.result.predecessorJob.name
            })
          } else {
            this.projectPredecessor = [];
          }
          if (this.result1.notifybefore != null) {
            if (this.result1.notifybefore == "1") {
              this.noftificationArrSelect = [];
              this.noftificationArrSelect.push({ "id": 1, "itemName": "3 Days before" })
            } else if (this.result1.notifybefore == "2") {
              this.noftificationArrSelect = [];
              this.noftificationArrSelect.push({ "id": 2, "itemName": "7 Days Before" })
            } else if (this.result1.notifybefore == "3") {
              this.noftificationArrSelect = [];
              this.noftificationArrSelect.push({ "id": 3, "itemName": "15 Days Before" })
            }
          } else {
            this.noftificationArrSelect = [];
          }
          this.ProjectJobForm.patchValue({ 'id': this.result1.id });
          this.ProjectJobForm.patchValue({ 'projectStateId': this.result1.projectStateId });
          this.ProjectJobForm.patchValue({ 'isActive': this.result1.isActive });
          this.ProjectJobForm.patchValue({ 'projectId': this.result1.projectId });
          this.ProjectJobForm.patchValue({ 'createdDate': this.result1.createdDate });
          this.ProjectJobForm.patchValue({ 'createdBy': this.result1.createdBy });
          this.ProjectJobForm.patchValue({ 'createdBy': this.result1.createdBy });
          this.ProjectJobForm.patchValue({ 'updatedBy': this.result1.updatedBy });
          this.ProjectJobForm.patchValue({ 'jobOrder': this.result1.jobOrder });

          var scdate = this.result1.startDate;
          if (scdate) {
            var ydate = moment(scdate).format("YYYY")
            var mdate = moment(scdate).format("M")
            var ddate = moment(scdate).format("D")
            this.ProjectJobForm.patchValue({
              'startDate': {
                date: {
                  year: ydate,
                  month: mdate,
                  day: ddate
                }
              }
            });
          } else {
            this.ProjectJobForm.patchValue({ startDate: null });
          }
          var cdate = this.result1.endDate;
          if (cdate) {
            var ydate = moment(cdate).format("YYYY")
            var mdate = moment(cdate).format("M")
            var ddate = moment(cdate).format("D")
            this.ProjectJobForm.patchValue({
              'endDate': {
                date: {
                  year: ydate,
                  month: mdate,
                  day: ddate
                }
              }
            });
          } else {
            this.ProjectJobForm.patchValue({ endDate: null });
          }
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
      this.projectStateArr = [];

      if (this.menuStateDocumentEdit == 'in') {
        this.menuStateDocumentEdit = 'out';
      }
      if (this.menuStateEmail == 'in') {
        this.menuStateEmail = 'out';
      }

      if (this.menuStateEmailEdit == 'in') {
        this.menuStateEmailEdit = 'out';
      }
      if (this.menuStateProjectUserEdit == 'in') {
        this.menuStateProjectUserEdit = 'out';
      }
      this.menuStateProjectEdit = this.menuStateProjectEdit === 'out' ? 'in' : 'out';
      if (this.popoverformassign == true) {
        this.popoverformassign = false;
      } else {
        this.popoverformassign = true;
      }
    }
  }
  toggleCloseProject() {
    this.menuStateProjectEdit = this.menuStateProjectEdit === 'out' ? 'in' : 'out';
    this.popoverformassign = false;
  }
  updateJob() {
    if (this.jobValidate()) {
      var data = this.ProjectJobForm.value;
      if (this.ProjectJobForm.value["isCompleted"] == true) {
        this.toggleCloseProject();
        this.confirmService.confirm('Please confirm..', 'Do you want to Complete this Job ?')
          .then((confirmed) => {
            if (confirmed) {
              this.spinner.show();
              this.body = {};
              this.body["id"] = this.jobId;
              if (data.dependedJobId != null) {
                if (data.dependedJobId.length > 0) {
                  this.body["dependedJobId"] = data.dependedJobId[0].id;
                } else {
                  this.body["dependedJobId"] = null;
                }
              }
              if (data.predecessorJobId != null) {
                if (data.predecessorJobId.length > 0) {
                  this.body["predecessorJobId"] = data.predecessorJobId[0].id;
                } else {
                  this.body["predecessorJobId"] = null;

                }
              }
              if (data.notifybefore.length > 0) {
                this.body["notifybefore"] = data.notifybefore[0].id;
              } else {
                this.body["notifybefore"] = 2;
              }
              this.body["assignedToId"] = this.ProjectJobForm.value["assignedToId"][0].id;
              this.body["projectStateId"] = this.ProjectJobForm.value["projectStateId"];
              this.body["projectId"] = this.ProjectJobForm.value["projectId"];
              this.body["createdDate"] = this.ProjectJobForm.value["createdDate"];
              this.body["jobOrder"] = this.ProjectJobForm.value["jobOrder"];
              this.body["updatedDate"] = new Date();
              this.body["updatedBy"] = parseInt(this.userid);
              this.body["createdBy"] = this.ProjectJobForm.value["createdBy"];
              var date1 = ("0" + (this.ProjectJobForm.value["startDate"].date.day)).slice(-2);
              var month1 = ("0" + (this.ProjectJobForm.value["startDate"].date.month)).slice(-2);
              var date2 = ("0" + (this.ProjectJobForm.value["endDate"].date.day)).slice(-2);
              var month2 = ("0" + (this.ProjectJobForm.value["endDate"].date.month)).slice(-2);
              var startDate = this.ProjectJobForm.value["startDate"].date.year + "-" + month1 + "-" + date1
              this.body["startDate"] = startDate;
              var endDate = this.ProjectJobForm.value["endDate"].date.year + "-" + month2 + "-" + date2
              this.body["endDate"] = endDate;
              this.body["isActive"] = "Y";
              this.body["isCompleted"] = "Y";
              this.http.put('/api/projectJob', this.body).subscribe((response) => {
                this.spinner.show();
                this.res = response;
                if (this.res.success == true) {
                  this.resetForms();
                  this.toastr.clear();
                  this.toastr.success(this.res.message, 'Success', {
                    timeOut: 6000,
                    closeButton: true
                  });
                  this.projectArr = [];
                  this.getJobs();
                  this.spinner.hide();
                } else {
                  this.toastr.clear();
                  this.toastr.error(this.res.message, 'Error', {
                    timeOut: 6000,
                    closeButton: true
                  });
                  this.spinner.hide();
                }
              }, error => {
                this.toastr.clear();
                this.toastr.error(error, 'Report Status', {
                  timeOut: 6000,
                  closeButton: true
                });
                this.errorService.handleError(error);
              });
            }
          })
          .catch(() => {
            this.toggleCloseProject();
            return false;
          });
      } else {
        this.spinner.show();
        this.body = {};
        this.body["id"] = this.jobId;
        this.body["assignedToId"] = this.ProjectJobForm.value["assignedToId"][0].id;
        this.body["projectStateId"] = this.ProjectJobForm.value["projectStateId"];
        this.body["projectId"] = this.ProjectJobForm.value["projectId"];
        this.body["createdDate"] = this.ProjectJobForm.value["createdDate"];
        this.body["jobOrder"] = this.ProjectJobForm.value["jobOrder"];
        this.body["updatedDate"] = new Date();
        this.body["updatedBy"] = parseInt(this.userid);
        this.body["createdBy"] = this.ProjectJobForm.value["createdBy"];
        var date1 = ("0" + (this.ProjectJobForm.value["startDate"].date.day)).slice(-2);
        var month1 = ("0" + (this.ProjectJobForm.value["startDate"].date.month)).slice(-2);
        var date2 = ("0" + (this.ProjectJobForm.value["endDate"].date.day)).slice(-2);
        var month2 = ("0" + (this.ProjectJobForm.value["endDate"].date.month)).slice(-2);
        var startDate = this.ProjectJobForm.value["startDate"].date.year + "-" + month1 + "-" + date1
        this.body["startDate"] = startDate;
        var endDate = this.ProjectJobForm.value["endDate"].date.year + "-" + month2 + "-" + date2
        this.body["endDate"] = endDate;
        this.body["isActive"] = "Y";
        this.body["isCompleted"] = "N";
        this.http.put('/api/projectJob', this.body).subscribe((response) => {
          this.spinner.show();
          this.res = response;
          if (this.res.success == true) {
            this.resetForms();
            this.toastr.clear();
            this.toastr.success(this.res.message, 'Success', {
              timeOut: 6000,
              closeButton: true
            });
            this.projectArr = [];
            this.toggleCloseProject();
            this.getJobs();
            this.spinner.hide();
          } else {
            this.toastr.clear();
            this.toastr.error(this.res.message, 'Error', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
          }
        }, error => {
          this.toastr.clear();
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.errorService.handleError(error);
        });
      }
    }
  }
  jobValidate() {
    this.nameErr = null;
    this.custErr = null;
    this.StartdateErr = null;
    this.dateErr = null;
    this.detailsErr = null;
    var valid = true;
    var data = this.ProjectJobForm.value;
    if (data.assignedToId.length == 0) {
      this.custErr = "User is required";
      valid = false;
    }
    else if (!data.startDate) {
      this.StartdateErr = "Start Date is required";
      valid = false;
    }
    else if (!data.endDate) {
      this.dateErr = " End Date is required";
      valid = false;
    }
    else if (data.startDate && data.endDate) {
      var date1 = ("0" + (data.startDate.date.day)).slice(-2);
      var month1 = ("0" + (data.startDate.date.month)).slice(-2);
      var date2 = ("0" + (data.endDate.date.day)).slice(-2);
      var month2 = ("0" + (data.endDate.date.month)).slice(-2);
      var startDate = data.startDate.date.year + "-" + month1 + "-" + date1
      var endDate = data.endDate.date.year + "-" + month2 + "-" + date2
      if (startDate > endDate) {
        if (startDate == endDate) {
          this.dateErr = null;
        } else {
          this.dateErr = "Closing Date must be greater than Start Date";
          valid = false;
        }
      }

    }

    return valid;
  }
  // getdocumentFile(files: FileList) {
  //   this.fileTodocument = files.item(0);
  // }
  getdocumentFile(e) {
    this.myFilesDocument = [];
    this.myFilesDocumentArr = [];
    for (var i = 0; i < e.target.files.length; i++) {
      this.myFilesDocument.push(e.target.files[i]);
      this.myFilesDocumentArr.push({
        "name": e.target.files[i].name
      })
    }
  }
  // postdocumentMethod(treeModel: any) {
  //   if (this.cleardocumentFile(0)) {
  //     this.spinner.show();
  //     let last: any = treeModel.activeNodes[treeModel.activeNodes.length - 1];
  //     let rootid = last.data.id;
  //     var formData = new FormData();
  //     formData.append("file", this.fileTodocument, this.fileTodocument.name);

  //     this.http.post('/api/upload/project/' + this.projectId + '/' + this.userid, formData).subscribe((response) => {
  //       this.toastr.clear();
  //       this.result = response;
  //       if (this.result.success == true) {
  //         this.adddocumentNodes(this.result.response.id, rootid)
  //         this.toastr.success(' Upload File successfully', 'Status', {
  //           timeOut: 6000,
  //           closeButton: true
  //         });
  //       } else {
  //         this.toastr.clear();
  //         this.toastr.error(this.result.message, 'Error', {
  //           timeOut: 6000,
  //           closeButton: true
  //         });
  //         this.spinner.hide();
  //       }
  //       this.spinner.hide();
  //       this.fileTodocument = [];
  //     }, error => {
  //       this.toastr.clear();
  //       this.toastr.error(error, 'Report Status', {
  //         timeOut: 6000,
  //         closeButton: true
  //       });
  //       this.errorService.handleError(error);
  //     });
  //   }
  // }

  postdocumentMethod(treeModel: any) {
    if (this.cleardocumentFile(0)) {
      this.spinner.show();
      let last: any = treeModel.activeNodes[treeModel.activeNodes.length - 1];
      let rootid = last.data.id;
      const frmData = new FormData();
      for (var i = 0; i < this.myFilesDocument.length; i++) {
        frmData.append("file", this.myFilesDocument[i]);
      }
      frmData.append("description", "");
      this.http.post('/api/upload/project/' + this.projectId + '/' + this.userid, frmData).subscribe((response) => {
        this.toastr.clear();
        this.result = response;
        var lastarr = this.result.successArr.length - 1;
        if (this.result.successArr.length > 0) {
          for (var i = 0; i < this.result.successArr.length; i++) {
            this.adddocumentNodes(this.result.successArr[i].response.id, rootid)
            if (lastarr == i) {
              this.myFilesDocumentArr = [];
              this.toastr.success(' Upload File successfully', 'Status', {
                timeOut: 6000,
                closeButton: true
              });
            }
          }
        } else if (this.result.failureArr.length > 0) {
          this.toastr.clear();
          this.toastr.error(this.result.failureArr[0].message, 'Error', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
        this.spinner.hide();
        this.fileTodocument = [];
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
    }
  }

  cleardocumentFile(num) {
    if (num == 0) {
      this.documentErr = null;
      var valid = true;
      let data = this.documentform.value;
      if (!data.document) {
        this.documentErr = "Document is required";
        valid = false;
      }
      return valid;
    }
  }

  getphotoFile(e) {
    this.myFilesPhoto = [];
    this.myFilesPhotoArr = [];
    for (var i = 0; i < e.target.files.length; i++) {
      this.myFilesPhoto.push(e.target.files[i]);
      this.myFilesPhotoArr.push({
        "name": e.target.files[i].name
      })
    }
  }
  postimageMethod() {
    if (this.clearimageFile(0)) {
      this.spinner.show();
      const frmData = new FormData();
      var data = this.Photoform.value;
      for (var i = 0; i < this.myFilesPhoto.length; i++) {
        frmData.append("file", this.myFilesPhoto[i]);
      }
      frmData.append("description", data.description);
      this.http.post('/api/upload/photo/' + this.projectId + '/' + this.userid, frmData).subscribe((response) => {
        this.res = response;
        if (this.res.successArr.length > 0) {
          this.toastr.clear();
          this.toastr.success(this.res.successArr[0].message, 'Success', {
            timeOut: 6000,
            closeButton: true
          });
          this.tab03 = false;
          this.resetForms();
          this.spinner.hide();
          this.getphoto();
          this.myFilesPhotoArr = [];
          this.fileTophoto = false;
        } else if (this.res.failureArr.length > 0) {
          this.toastr.clear();
          this.toastr.error(this.res.failureArr[0].message, 'Error', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });

    }
  }
  clearimageFile(num) {
    if (num == 0) {
      this.imageErr = null;
      var valid = true;
      let data = this.Photoform.value;
      var _validFileExtensions = [".jpg", ".jpeg", ".bmp", ".gif", ".png"];
      if (!data.Photo) {
        this.imageErr = "Image is not selected";
        valid = false;
      }
      return valid;
    }
  }



  open(index: number, inx): void {
    this.photosArr = [];
    for (let i = 0; i < this.uploadPhotoArr.length; i++) {
      if (i == inx) {
        var result = this.uploadPhotoArr[i].photos;
        for (let j = 0; j < result.length; j++) {
          if (result[j].description != null && result[j].description != "") {
            this.photosArr.push({
              "id": result[j].id,
              "src": result[j].src,
              "caption": result[j].caption + ", Description : " + result[j].description,
            });
          } else {
            this.photosArr.push({
              "id": result[j].id,
              "src": result[j].src,
              "caption": result[j].caption
            });
          }
        }
        this._lightbox.open(this.photosArr, index);
      }
    }
  }

  close(): void {
    this._lightbox.close();
  }
  async getnotes() {
    if (this.internaluser == true) {
      var result = await this.DetailsService.getProjectNotesDetailsID(this.projectId, this.userid);
    } else {
      var result = await this.DetailsService.getProjectNotesDetailsIDexternal(this.projectId, this.userid);
    }
    this.result1 = result;
    this.result1.forEach(r => {
      var desc = r.description;
      var edit = $(desc).text();
      this.noteArr.push({
        "id": r.id, "descName": r.description, "heading": r.heading, "createdAt": r.createdAt, "createdBy": r.createdBy,
        "viewType": r.viewType,
      })
    })
    this.spinner.hide();
  }
  toggleAddNotesUser() {

    this.noteHeadingErr = null;
    this.noteDescErr = null;
    this.isCreate = true;
    this.resetForms();
    this.tab01 = false;


    if (this.menuStateEmail == 'in') {
      this.menuStateEmail = 'out';
    }
    if (this.menuStateProjectEdit == 'in') {
      this.menuStateProjectEdit = 'out';
    }
    if (this.menuStateEmailEdit == 'in') {
      this.menuStateEmailEdit = 'out';
    }
    this.menuStateNotesUserEdit = this.menuStateNotesUserEdit === 'out' ? 'in' : 'out';
  }
  submitNotesUser() {
    if (this.noteValidate()) {
      this.spinner.show();
      let data = this.notesUserForm.value;
      data.userId = parseInt(this.userid);
      data.createdBy = parseInt(this.userid);
      data.domainId = parseInt(this.loggedInUserDomain);
      data.viewType = parseInt(data.viewType[0].id);
      data.projectId = this.projectId;
      this.http.post('api/notes/project', data).subscribe((response) => {
        this.res = response;
        if (this.res.success == true) {
          this.toastr.clear();
          this.toastr.success(this.res.message, 'Success', {
            timeOut: 6000,
            closeButton: true
          });
          // this.toggleAddNotesUser();
          this.tab01 = false;
          this.getnotes();

          this.spinner.hide();
        } else {
          this.toastr.clear();
          this.toastr.error(this.res.message, 'Error', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });

      this.noteArr = [];
    }
  }
  noteEditMenu(id) {
    this.tab01 = true;
    this.tab02 = false;
    this.tab03 = false
    this.tab05 = false;
    this.tab04 = false;
    this.tab06 = false;
    this.noteHeadingErr = null;
    this.noteDescErr = null;
    this.isCreate = false;
    this.nid = id;
    this.http.get('/api/note/' + this.nid).subscribe((res: Response) => {
      this.result = res;
      if (this.result) {
        this.notesUserForm.patchValue({ 'id': this.result.id });
        this.notesUserForm.patchValue({ 'heading': this.result.heading });
        this.notesUserForm.patchValue({ 'description': this.result.description });
        this.notesUserForm.patchValue({ 'isActive': this.result.isActive });
        this.notesUserForm.patchValue({ 'userId': this.result.userId });
        this.notesUserForm.patchValue({ 'projectId': this.result.projectId });
        this.notesUserForm.patchValue({ 'createdAt': this.result.createdAt });
        this.notesUserForm.patchValue({ 'updatedAt': this.result.updatedAt });
        this.notesUserForm.patchValue({ 'createdBy': this.result.createdBy });
        this.notesUserForm.patchValue({ 'updatedBy': this.result.updatedBy });
        this.notesUserForm.patchValue({ 'domainId': this.result.domainId });
        if (this.result.createdBy == this.userid || this.admin == "Y") {
          this.is_delete = true;
          this.noteCreatedAnddeleteUSer = false;
        } else {
          this.noteCreatedAnddeleteUSer = true;
          this.is_delete = false;
        }
        if (this.result.viewType == "2") {
          this.eventListSelected = [];
          this.eventListSelected.push({ "id": 2, "itemName": "Internal" })
        } else if (this.result.viewType == "3") {
          this.eventListSelected = [];
          this.eventListSelected.push({ "id": 3, "itemName": "External" })
        }
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
    this.menuStateNotesUserEdit = this.menuStateNotesUserEdit === 'out' ? 'in' : 'out';
  }
  updateNote() {
    if (this.noteValidate()) {
    this.body = {};
    this.body["id"] = this.nid;
    this.body["viewType"] = this.notesUserForm.value["viewType"][0].id;
    this.body["heading"] = this.notesUserForm.value["heading"];
    this.body["description"] = this.notesUserForm.value["description"];
    this.body["userId"] = this.notesUserForm.value["userId"];
    this.body["projectId"] = this.notesUserForm.value["projectId"];
    this.body["createdAt"] = this.notesUserForm.value["createdAt"];
    this.body["updatedAt"] = this.notesUserForm.value["updatedAt"];
    this.body["createdBy"] = this.notesUserForm.value["createdBy"];
    this.body["domainId"] = this.notesUserForm.value["domainId"];
    this.body["updatedBy"] = this.userid;
    this.body["isActive"] = "Y";

      this.spinner.show();
      this.http.put('/api/notes/project', this.body).subscribe((response) => {
        this.resetForms();
        this.res = response;
        if (this.res.success == true) {
          this.toastr.clear();
          this.toastr.success(this.res.message, 'Success', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
          this.tab01 = false;

          this.getnotes();
        } else {
          this.toastr.clear();
          this.toastr.error(this.res.message, 'Error', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
      this.noteArr = [];
    }
  }
  delateNote() {
    this.confirmService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) => {
        if (confirmed) {
          this.spinner.show();
          this.http.delete('/api/notes/' + this.nid).subscribe((response) => {
            this.resetForms();
            this.isCreate = true;
            this.res = response;
            if (this.res.success == true) {
              this.toastr.clear();
              this.toastr.success(this.res.message, 'Success', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
              this.tab01 = false;
              this.getnotes();
              this.noteArr = [];
            } else {

              this.toastr.clear();
              this.toastr.error(this.res.message, 'Error', {
                timeOut: 6000,
                closeButton: true
              });


            }
          }, error => {
            this.toastr.clear();
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
            // this.tab01 = false;
            this.errorService.handleError(error);
          })
          this.getnotes();
          this.noteArr = [];
        }
      })
      .catch(() => {
        return false;
      });
  }
  noteValidate() {
    this.noteHeadingErr = null;
    this.noteDescErr = null;
    this.nameErr = null;
    const strongRegex = /^[:;,\-@0-9a-zA-Zגיט'.\s]{1,9999}$/;
    var valid = true;
    var data = this.notesUserForm.value;
    var desc = data.description;
    var edit = $(desc).text();
    if (!data.heading) {
      this.noteHeadingErr = "Heading is required";
      valid = false;
    } else if (!data.viewType || data.viewType.length == 0) {
      this.nameErr = "NoteType is required.";
      valid = false;
    }
    if (edit == undefined || edit == '') {
      this.noteDescErr = "Notes is Required";
      valid = false;
    }
    // else if (!strongRegex.test(edit)) {
    //   this.noteDescErr = "Too long. Please restrict to 10000 characters";
    //   valid = false;
    // }
    else if (edit.length > 10000) {
      this.noteDescErr = "Too long. Please restrict to 10000 characters";
      valid = false;
    }
    return valid;
  }

  ///spl notes///


  toggleAddProjectSplNotes() {
    if (this.popoverformprojnote == true) {
      this.popoverformprojnote = false;
    } else {
      this.popoverformprojnote = true;
    }
    this.noteDescErr = null;

    const id = +this.route.snapshot.paramMap.get('id');
    this.http.get('/api/project/' + id).subscribe((res: Response) => {
      this.result = res;
      this.splNotesForm.patchValue({ 'note': this.result.note });
    })

    this.resetForms();
    if (this.menuStateDocumentEdit == 'in') {
      this.menuStateDocumentEdit = 'out';
    }
    if (this.menuStateEmail == 'in') {
      this.menuStateEmail = 'out';
    }

    if (this.menuStateTaskEdit == 'in') {
      this.menuStateTaskEdit = 'out';
    }
    if (this.menuStateEmailEdit == 'in') {
      this.menuStateEmailEdit = 'out';
    }
    if (this.menuStateProjectEdit == 'in') {
      this.menuStateProjectEdit = 'out';
    }
    if (this.menuStateProjectUserEdit == 'in') {
      this.menuStateProjectUserEdit = 'out';
    }
    if (this.menuStateNotesUserEdit == 'in') {
      this.menuStateNotesUserEdit = 'out';
    }
    this.menuStateSplNotesEdit = this.menuStateSplNotesEdit === 'out' ? 'in' : 'out';
  }
  closeSplNote() {
    this.noteDescErr = null;
    this.menuStateSplNotesEdit = this.menuStateSplNotesEdit === 'out' ? 'in' : 'out';
  }
  submitSplNotes() {
    this.popoverformprojnote = false;
    this.spinner.show();
    const id = +this.route.snapshot.paramMap.get('id');
    var data = this.splNotesForm.value;
    this.http.get('/api/project/' + id).subscribe((res: Response) => {
      this.result = res;
      this.result.note = data.note;
      var formData = new FormData();
      formData.append("note", data.note);
      this.http.post('/api/notes/special/' + id, formData).subscribe((res: Response) => {
        this.resetForms();
        this.result = res;
        if (this.result.success == true) {
          this.toastr.clear();
          this.toastr.success(this.result.message, 'Success', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
          this.closeSplNote();
          this.getDetailsOnly();
        } else {
          this.toastr.clear();
          this.toastr.error(this.result.message, 'Error', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
      });
    })

  }

  tabs(tabValue) {
    switch (tabValue) {
      case 'Note':
        this.noteCreatedAnddeleteUSer = false;
        this.eventListSelected = [];
        this.noteHeadingErr = null;
        this.noteDescErr = null;
        this.nameErr = null;
        this.myFilesPhotoArr = [];
        if (this.tab01 == true) {
          if (this.isCreate == true) {
            this.tab01 = false;
          } else {
            this.resetForms();
            this.isCreate = true;
          }
        } else {
          this.resetForms();
          this.isCreate = true;
          this.tab01 = true;
        }
        this.tab02 = false
        this.tab03 = false
        this.tab04 = false;
        this.tab06 = false;
        break;
      case 'Email':
        this.myFilesPhotoArr = [];
        if (this.tab02 == false) {
          this.resetForms();
          this.isCreate = true
          this.getmailfiles();
          this.multiCustomerDetails()
          this.deleteFilemailarr();
          this.getUserDetails();
          this.tab02 = true;
        } else {
          this.tab02 = false;
        }
        this.tab06 = false;
        this.tab01 = false
        this.tab03 = false
        this.tab04 = false;
        this.emailErr = null;
        this.emailccErr = null;
        this.emailbccErr = null;

        break;
      case 'Photo':
        this.imageErr = null;
        this.myFilesPhotoArr = [];
        if (this.tab03 == false) {
          this.resetForms();
          this.isCreate = true

          this.tab03 = true;
        } else {
          this.tab03 = false;
        }
        this.tab01 = false
        this.tab02 = false
        this.tab04 = false;
        this.tab06 = false;
        this.descriptionHide = false;
        break;
      case 'Task':
        this.nameErr = null;
        this.custErr = null;
        this.StartdateErr = null;
        this.dateErr = null;
        this.detailsErr = null;
        this.filesUploadArr = [];
        if (this.tab04 == true) {
          if (this.isCreate == true) {
            this.tab04 = false;
          } else {
            this.resetForms();
            this.isCreate = true;
          }
        } else {
          this.resetForms();
          this.isCreate = true;
          this.tab04 = true;
        }
        this.tab01 = false
        this.tab02 = false
        this.tab03 = false
        this.tab06 = false;
        break;

    }
  }
  togglePhotocancel() {
    this.tab03 = false;
    this.fileTophoto = false;
    this.myFilesPhotoArr = [];
  }
  toggleAddNotesUsercancel() {
    this.tab01 = false;
  }
  toggleEmailcancel() {
    this.tab02 = false;
  }
  customerGetCall() {
    this.multiCustomerDetails();
    this.customerDetial();
  }
  customerDetial() {
    this.customersArr = [];
    this.http.get('/api/customer').subscribe((res1: Response) => {
      this.result1 = res1;
      this.custArrDe = this.result1;
      for (let i = 0; i < this.result1.length; i++) {
        this.customersArr.push(this.result1[i].details.id)
      }
    })
  }
  multiCustomerDetails() {
    this.customerAllDetails = [];
    this.customerAllDetailsArr = [];
    this.customermail = [];
    const id = +this.route.snapshot.paramMap.get('id');
    this.projectId = id;
    this.http.get('/api/projectCustomer/project/' + this.projectId).subscribe((res1: Response) => {
      this.customerAllDetails = res1;
      this.custLength = this.customerAllDetails.length;
      for (let i = 0; i < this.custLength; i++) {
        this.customerDetials(this.customerAllDetails[i].cust.id);
        this.customerAllDetailsArr.push(this.customerAllDetails[i].cust.id)
      }
    })
  }
  toggleAddCustomer() {
    this.allCustomer = [];
    this.customerAllArrselect = [];
    this.customerErr = null;
    this.resetForms();
    var result2 = this.arr_diff_cust(this.customersArr, this.customerAllDetailsArr);
    this.custArrDe.forEach(r => {
      for (let i = 0; i < result2.length; i++) {
        if (result2[i] == r.details.id) {
          this.allCustomer.push({
            "id": r.details.id, "itemName": r.details.firstName + " " + r.details.lastName
          })
        }
      }
    });
    if (this.menuStateOrgEdit == 'in') {
      this.menuStateOrgEdit = 'out';
    }
    if (this.menuStateProjectUserEdit == 'in') {
      this.menuStateProjectUserEdit = 'out';
    }
    this.menuStateCustomerEdit = this.menuStateCustomerEdit === 'out' ? 'in' : 'out';
    if (this.popoverformproj1 == true) {
      this.popoverformproj1 = false;
    } else {
      this.popoverformproj1 = true;
    }
  }
  toggleCloseCustomer() {
    this.customerAllArrselect = [];
    this.menuStateCustomerEdit = this.menuStateCustomerEdit === 'out' ? 'in' : 'out';
    this.popoverformproj1 = false;
  }
  submitCustomer() {
    var post = {};
    if (this.custValidate()) {
      this.spinner.show();
      var data = this.custForm.value;
      data.customerId = data.customerId[0].id;
      data.projectId = this.projectId;
      this.http.post('/api/projectCustomer', data).subscribe((response) => {
        this.res = response;
        if (this.res.success == true) {
          this.toastr.clear();
          this.toastr.success(this.res.message, 'Success', {
            timeOut: 6000,
            closeButton: true
          });
          this.toggleCloseCustomer();
          this.customerGetCall();
          this.spinner.hide();
        } else {
          this.toastr.clear();
          this.toastr.error(this.res.message, 'Error', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
    }

  }
  removeFromCustomer(id) {
    this.confirmService.confirm('Please confirm..', 'Do you want to remove the customer ?')
      .then((confirmed) => {
        if (confirmed) {
          this.spinner.show();
          this.http.delete('/api/projectCustomer/' + id).subscribe((response) => {
            this.res = response;
            if (this.res.success == true) {
              this.toastr.clear();
              this.toastr.success(this.res.message, 'Success', {
                timeOut: 6000,
                closeButton: true
              });
              this.customerGetCall();
              this.spinner.hide();
            } else {
              this.toastr.clear();
              this.toastr.error(this.res.message, 'Error', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
            }
          }, error => {
            this.toastr.clear();
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.errorService.handleError(error);
          });
        }
      })
      .catch(() => {
        return false;
      });
  }
  custValidate() {
    this.customerErr = null;
    var valid = true;
    var data = this.custForm.value;
    if (data.customerId.length == 0) {
      this.customerErr = "Customer is required";
      valid = false;
    }
    return valid;
  }
  toggledoc() {
    this.doc = !this.doc;
  }
  toggleAddTask1() {
    this.custErr = null;
    this.nameErr = null;
    this.detailsErr = null;
    this.fileTaskErr = null;
    this.taskCommentesAr = [];
    this.taskCommentArr = [];
    this.taskAttArr = [];
    this.dateErr = null;
    this.StartdateErr = null;
    this.isCreate = true;
    this.resetForms();
    this.TaskForm.enable();
    this.tab04 = false;
  }
  descriptionEdit(id, description) {
    this.spinner.show();
    this.fileID = id;
    this.tab03 = true;
    this.descriptionHide = true;
    setTimeout(() => {
      this.Photoform.patchValue({ 'description': description });
      this.spinner.hide();
    }, 200);
  }
  descriptionUpdate() {
    var data = this.Photoform.value;
    this.body = {};
    this.body["fileId"] = this.fileID;
    this.body["description"] = data.description;
    this.http.put('/api/upload/description', this.body).subscribe((response) => {
      this.res = response;
      var res = this.res;
      this.spinner.show();
      if (res.success == true) {
        this.tab03 = false;
        this.toastr.clear();
        this.toastr.success(res.message, 'Success', {
          timeOut: 2000,
          closeButton: true
        });
        this.getphoto();
        this.spinner.hide();
      } else {
        this.toastr.clear();
        this.toastr.error(res.message, 'Error', {
          timeOut: 2000,
          closeButton: true
        });
        this.spinner.hide();
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 2000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  getocdocuments() {
    this.http.get('/api/projectOCD/' + this.projectId).subscribe((res: Response) => {
      this.ocdResult = res;
      var result = this.ocdResult;
      var ocd = result.OCDs;
      var states = result.states;
      var OCStatus = false;
      var completecount = 0;
      this.ocdArr = [];
      var val = false;
      for (let i = 0; i < states.length; i++) {
        val = false;
        var checklistnote = "";
        for (let j = 0; j < ocd.length; j++) {
          if (states[i].id == ocd[j].projectStateId) {
            var ocdID = ocd[j].id;
            var data = ocd[j];
            checklistnote = ocd[j].note;
            if (ocd[j].isCompleted == "Y") {
              completecount++;
              val = true;
            }
            break;
          }
        }
        if (states.length == completecount) {
          this.isSubmit = true;
        } else {
          this.isSubmit = false;
        }
        this.ocdArr.push({
          "StateName": states[i].name,
          "StateId": states[i].id,
          "status": val,
          "Id": ocdID,
          "checklistnote": checklistnote,
          "data": states[i],
        })
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
    this.projectInfoCode();
    this.spinner.hide();
  }
  check(data) {
    this.http.get('/api/projectOCDs/' + this.projectId + '/' + data.id).subscribe((res: Response) => {
      this.result = res;
      var result = this.result;
      if (result.data != null) {
        this.checkComplete(result.data);
      } else if (result.data == null) {
        this.openOCCheck(data.id)
      } else {
        this.openOCCheck(data.id)
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  openOC(data) {
    this.tab01 = false;
    this.tab02 = false;
    this.tab03 = false;
    this.tab04 = false;
    this.tab05 = false;
    this.tab06 = true;
    this.OcdocErr = null;
    this.ocDocumentReset();
    this.myFilesOcDocArr = [];
    this.checkid = data.Id;
    this.ocDocumentName = data.StateName;
    this.getOcdoc(data.Id);
    const id = +this.route.snapshot.paramMap.get('id');
    this.projectId = id;
    this.projectStId = data.StateId;
    this.http.get('/api/projectOCDs/' + this.projectId + '/' + this.projectStId).subscribe((res: Response) => {
      this.result = res;
      var result = this.result;
      if (result.data != null) {
        this.fatchdatenote(result.data);
      } else if (result.data == null) {
        this.onPostOcNotes(this.projectStId, 1)
      } else {
        this.onPostOcNotes(this.projectStId, 1)
      }
      this.spinner.hide();
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  openOCCheck(data) {
    const id = +this.route.snapshot.paramMap.get('id');
    this.projectId = id;
    this.projectStId = data;
    var post = {};
    this.http.post('/api/projectOCDs/' + this.projectId + '/' + this.projectStId, post).subscribe((response) => {
      this.res = response;
      var res = this.res;
      if (res.success == true) {
        this.checkComplete(res.response);
      } else {
        this.toastr.clear();
        this.toastr.error(res.message, 'Error', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  checkComplete(data) {
    data["isCompleted"] = 'Y';
    this.http.put('/api/projectOCDs', data).subscribe((response) => {
      this.res = response;
      var res = this.res;
      this.spinner.show();
      if (res.success == true) {
        this.toastr.clear();
        this.toastr.success(res.message, 'Success', {
          timeOut: 6000,
          closeButton: true
        });
        this.ocdArr = [];
        this.getocdocuments();
      } else {
        this.toastr.clear();
        this.toastr.error(res.message, 'Error', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  onPostOcNotes(projectStateId, num) {
    const id = +this.route.snapshot.paramMap.get('id');
    this.projectId = id;
    this.projectStId = projectStateId;
    var post = {};
    this.http.post('/api/projectOCDs/' + this.projectId + '/' + this.projectStId, post).subscribe((response) => {
      this.res = response;
      var res = this.res;
      if (res.success == true) {
        if (num == 0) {
        } else {
          this.spinner.hide();
          this.fatchdatenote(res.response);
        }
      } else {
        this.toastr.clear();
        this.toastr.error(res.message, 'Error', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  fatchdatenote(res) {
    if (res) {
      this.notesForm.patchValue({ 'id': res.id });
      this.notesForm.patchValue({ 'note': res.note });
      this.notesForm.patchValue({ 'isActive': res.isActive });
      this.notesForm.patchValue({ 'projectId': res.projectId });
      this.notesForm.patchValue({ 'projectStateId': res.projectStateId });
      this.notesForm.patchValue({ 'createdDate': res.createdDate });
      this.notesForm.patchValue({ 'isCompleted': res.isCompleted });
      this.notesForm.patchValue({ 'domainId': res.domainId });
    }
  }
  notesValidate() {
    this.noteHeadingErr = null;
    this.noteDescErr = null;
    var valid = true;
    var data = this.notesForm.value;
    if (!data.note) {
      this.noteDescErr = "Note is required";
      valid = false;
    }
    return valid;
  }
  getOcdoc(id) {
    this.uploadOCArr = [];
    if (id) {
      this.http.get('api/projectOCDs/documents/' + id).subscribe((res: Response) => {
        this.result = res;
        var result = this.result;
        this.documentsLengthg = this.result.length;
        for (let i = 0; i < result.length; i++) {
          this.uploadOCArr.push({
            "FileId": result[i].id, "Filename": result[i].uploadName, "FileLocation": result[i].location, "uploadedDate": result[i].uploadedDate, "CheckId": this.checkid
          })
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
    }
  }
  ocDocumentReset() {
    this.Ocdocform.reset({
      Ocdoc: '',
      description: ''
    });
  }
  updateNoteOcd() {
    this.body = {};
    this.body["id"] = this.notesForm.value["id"];
    this.body["note"] = this.notesForm.value["note"];
    this.body["isActive"] = this.notesForm.value["isActive"];
    this.body["projectStateId"] = this.notesForm.value["projectStateId"];
    this.body["projectId"] = this.notesForm.value["projectId"];
    this.body["projectStateId"] = this.notesForm.value["projectStateId"];
    this.body["isCompleted"] = this.notesForm.value["isCompleted"];
    this.body["createdDate"] = this.notesForm.value["createdDate"];
    this.body["domainId"] = this.notesForm.value["domainId"];
    this.spinner.show();
    this.http.put('/api/projectOCDs', this.body).subscribe((response) => {
      this.res = response;
      var res = this.res;
      if (res.success == true) {
        this.tab06 = false;
        this.toastr.clear();
        this.toastr.success(res.message, 'Success', {
          timeOut: 6000,
          closeButton: true
        });
        this.resetForms();
        this.fatchdatenote(res.response)
        this.getocdocuments();
      } else {
        this.toastr.clear();
        this.toastr.error(res.message, 'Error', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
      }

    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  ocdoccancel() {
    this.tab06 = false;
  }
  getdocumentsFile(Checkid) {
    this.http.get('api/projectOCDs/documents/' + Checkid).subscribe((res: Response) => {
      this.result = res;
      if (this.result.length > 0) {
        for (let i = 0; i < this.result.length; i++) {
          this.downloadFileOcDocument(this.result[i].id);
        }
      } else {
        this.toastr.clear();
        this.toastr.error("Empty Documents", 'Report Status', {
          timeOut: 2000,
          closeButton: true
        });
      }

    });
  }
  downloadFileOcDocument(fid) {
    const url = '/unsecure/download/' + fid;
    window.open(url);
  }
  getOcdocFile(e) {
    this.myFilesOcDoc = [];
    this.myFilesOcDocArr = [];
    for (var i = 0; i < e.target.files.length; i++) {
      this.myFilesOcDoc.push(e.target.files[i]);
      this.myFilesOcDocArr.push({
        "name": e.target.files[i].name
      })
    }
  }
  postOcdocMethod() {
    if (this.clearFile(2)) {
      this.spinner.show();
      var post = {};
      const frmData = new FormData();
      for (var i = 0; i < this.myFilesOcDoc.length; i++) {
        frmData.append("file", this.myFilesOcDoc[i]);
      }
      frmData.append("description", "");
      this.http.post('/api/upload/OCD/' + this.projectId + '/' + this.userid, frmData).subscribe((response) => {
        this.toastr.clear();
        this.res = response;
        var res = this.res;
        if (res.successArr.length > 0) {
          var lastarr = res.successArr.length - 1;
          for (var i = 0; i < res.successArr.length; i++) {
            post = {
              "createdBy": this.userid,
              "fileId": res.successArr[i].response.id,
              "projectOcdId": this.checkid
            }
            this.http.post('/api/projectOCDs/documents/', post).subscribe((response) => {
              this.res1 = response;
            }, error => {
              this.toastr.clear();
              this.toastr.error(error, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.errorService.handleError(error);
            });
            if (lastarr == i) {
              setTimeout(() => {
                if (this.res1.success == true) {
                  this.ocDocumentReset();
                  this.toastr.clear();
                  this.toastr.success(this.res1.message, 'Success', {
                    timeOut: 6000,
                    closeButton: true
                  });
                } else {
                  this.toastr.clear();
                  this.toastr.error(this.res1.message, 'Error', {
                    timeOut: 6000,
                    closeButton: true
                  });
                  this.spinner.hide();
                }
                this.spinner.hide();
                this.OcdocErr = null;
                this.myFilesOcDocArr = [];
                this.getOcdoc(this.checkid);
                this.getocdocuments();
              }, 2000);
            }
          }
        } else if (res.failureArr.length > 0) {
          this.toastr.clear();
          this.toastr.error(res.failureArr[0].message, 'Error', {
            timeOut: 6000,
            closeButton: true
          });
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
      return false;
    }
  }
  documentList() {
    console.log("test")
    if (this.documentsListShow == true) {
      this.documentsListShow = false
    } else {
      this.documentsListShow = true
    }
  }
  deleteFileOc(fid) {
    this.confirmService.confirm('Please confirm..', 'Do you want to delete the File ?')
      .then((confirmed) => {
        if (confirmed) {
          this.http.delete('/api/files/' + fid).subscribe((response) => {
            this.res = response;
            var res = this.res;
            if (res.success == true) {
              this.toastr.clear();
              this.toastr.success(res.message, 'Success', {
                timeOut: 6000,
                closeButton: true
              });
              this.getOcdoc(this.checkid);
            } else {
              this.toastr.clear();
              this.toastr.error(res.message, 'Error', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
            }
          }, error => {
            this.toastr.clear();
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.errorService.handleError(error);
          });
        }
      })
      .catch(() => {
        return false;
      });
  }
  signOpen() {
    this.openeds = true;
    this.isSubmit = !this.isSubmit;
  }
  onbegin: any;
  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  private signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    // 'minWidth': 5,
    'canvasWidth': 260,
    'canvasHeight': 150,
  };
  clearSign() {
    this.signaturePad.clear();
  }

  drawComplete() {
  }

  drawStart() {
    var count
      = 1;
  }
  openConfirmationDialog() {
    if (this.validate()) {
      this.confirmService.confirm('Please confirm..', 'Do you really want to save ?')
        .then((confirmed) => {
          this.submitSign();
        })
        .catch(() => {
        });
    } else {
      return false;
    }
  }
  validate() {
    this.nameErr = null;
    var data = this.form.value;
    var valid = true;
    if (data.approvedBy == '') {
      this.nameErr = " Name is required.";
      valid = false;
    }
    return valid;
  }
  submitSign() {
    var dataProjectInfo = this.projectInfoDetails;
    var data = this.form.value;
    dataProjectInfo.approvedBy = data.approvedBy;
    dataProjectInfo.approvalSign = this.signaturePad.toDataURL();
    dataProjectInfo.updatedBy = parseInt(this.userid);
    dataProjectInfo.projectStart = moment(this.projectInfoDetails.projectStart).format("YYYY-MM-DD");
    if (this.projectInfoDetails.projectEnd != null) {
      dataProjectInfo.projectEnd = moment(this.projectInfoDetails.projectEnd).format("YYYY-MM-DD");
    } else {
      dataProjectInfo.projectEnd = null;
    }
    this.http.put('/api/projectInfo', dataProjectInfo).subscribe((response) => {
      this.res = response;
      if (this.res.success == true) {
        this.toastr.clear();
        this.toastr.success(this.res.message, 'Success', {
          timeOut: 6000,
          closeButton: true
        });
        this.getprojectinfo(dataProjectInfo.id);
        this.spinner.hide();
      } else {
        this.toastr.clear();
        this.toastr.error(this.res.message, 'Error', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  ocDocumentResetAll() {
    var data = {};
    this.confirmService.confirm('Please confirm..', 'Do you really want to revert ?')
      .then((confirmed) => {
        this.spinner.show();
        this.http.post('/api/ocd/revert/' + this.projectId, data).subscribe((response) => {
          this.toastr.clear();
          this.res = response;
          if (this.res.success == true) {
            this.toastr.clear();
            this.toastr.success(this.res.message, 'Success', {
              timeOut: 6000,
              closeButton: true
            });
            this.resetForms();
            this.getocdocuments();
            this.fatchdatenote(this.res.response)
          } else {
            this.toastr.clear();
            this.toastr.error(this.res.message, 'Error', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
          }
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })
      })
      .catch(() => {
        return false;
      });
  }
  zipFileDownlodad() {
    const id = +this.route.snapshot.paramMap.get('id');
    const url = 'unsecure/ocd/downloadZip/' + id;
    window.open(url, "_self");
  }
  pdfDownload(project) {
    this.pdfHeadingEnable = !this.pdfHeadingEnable;
    this.confirmService.confirm('Please confirm..', 'Do you want download Pdf?')
      .then((confirmed) => {
        if (confirmed) {
          this.spinner.show();
          this.captureScreen(project);
        }
        this.pdfHeadingEnable = !this.pdfHeadingEnable;
        this.spinner.hide();
      })
      .catch(() => {
        this.pdfHeadingEnable = !this.pdfHeadingEnable;
        return false;
      });
  }
  captureScreen(project) {
    var data = document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {
      var imgWidth = 150;
      var pageHeight = 150;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 8;
      pdf.addImage(contentDataURL, 'PNG', 30, position, imgWidth, pageHeight)
      pdf.output('save', project + '.pdf');
    });
  }
  pdfPrint() {
    this.pdfHeadingEnable = !this.pdfHeadingEnable;
    this.confirmService.confirm('Please confirm..', 'Do you want print Pdf?')
      .then((confirmed) => {
        if (confirmed) {
          this.spinner.show();
          window.print();
        }
        this.pdfHeadingEnable = !this.pdfHeadingEnable;
        this.spinner.hide();
      })
      .catch(() => {
        this.pdfHeadingEnable = !this.pdfHeadingEnable;
        return false;
      });
  }
  toggleAccordian(ngPanel) {
    switch (ngPanel.panelId) {
      case 'static-2':
        if(ngPanel.nextState == true){
          this.projectInfoCode();
          break;
        }
      case 'static-3':
        if(ngPanel.nextState == true){
          this.customerGetCall();
          break;
        }
    }
  }
  downloadPhotos(fid) {
    const url = '/unsecure/download/' + fid;
    window.open(url, "_self");
  }

  submitProjectComplete(type: string) {
    console.log("clicked project complete");
    this.confirmService.confirm('Please confirm..', 'Do you want to move Project to ' + type + ' state? This action cannot be revoked.')
      .then((confirmed) => {
        if (confirmed) {
          this.spinner.show();
          this.http.get('/api/project/' + this.projectId).subscribe((res: Response) => {
            this.result = res;
            this.spinner.hide();
            if (this.result != null) {
              this.result.projectCompleted = "T";
              this.result.approvalType = type;
              this.result.updatedBy = parseInt(this.userid);
              this.http.put('/api/project/', this.result).subscribe((res1: Response) => {
                this.res2 = res1;
                if (this.res2.success == true) {
                  this.toastr.clear();
                  this.toastr.success(this.res2.message, 'Report Status', {
                    timeOut: 6000,
                    closeButton: true
                  });
                  this.getDetailsOnly();
                }
              }, error => {
                this.toastr.clear();
                this.toastr.error(error, 'Report Status', {
                  timeOut: 6000,
                  closeButton: true
                });
                this.errorService.handleError(error);
              })
            }
          }, error => {
            this.toastr.clear();
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.errorService.handleError(error);
          })
        }
      })
  }

  // methods for keypeople
  toggleAddKeyPeople(){
    if (this.popoverforkeypeople == true) {
      this.popoverforkeypeople = false;
    } else {
      this.popoverforkeypeople = true;
    }
    this.allCertifiers =[];
    this.allCouncil =[];
    this.keypeopleAllArrselect = [];
    this.keypeopleAllArrselect1 = [];
    this.certifierErr =null;
    this.councilErr = null;

    this.http.get('/api/certifiers/').subscribe((res: Response) => {
      this.result = res;
      if (this.result.length != 0) {
        for (let i = 0; i < this.result.length; i++) {
          this.allCertifiers.push({
            "id": this.result[i].id, "itemName": this.result[i].firstName + " " + this.result[i].lastName,
          })
        }
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });

    this.http.get('/api/council/').subscribe((res: Response) => {
      this.result1 = res;
      if (this.result1.length != 0) {
        for (let i = 0; i < this.result1.length; i++) {
          this.allCouncil.push({
            "id": this.result1[i].id, "itemName": this.result1[i].firstName + " " + this.result1[i].lastName,
          })
        }
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });

    if (this.menuStateOrgEdit == 'in') {
      this.menuStateOrgEdit = 'out';
    }
    if (this.menuStateProjectUserEdit == 'in') {
      this.menuStateProjectUserEdit = 'out';
    }
    this.menuStateKeypeopleEdit = this.menuStateKeypeopleEdit === 'out' ? 'in' : 'out';
  }
  toggleCloseKeypeople(){
    this.keypeopleAllArrselect = [];
    this.keypeopleAllArrselect1 = [];
    this.menuStateKeypeopleEdit = this.menuStateKeypeopleEdit === 'out' ? 'in' : 'out';
    this.popoverforkeypeople = false;
  }

  submitKeypeople(){
    if (this.keypeopleValidate()) {
      this.spinner.show();
      var data = this.keypeopleForm.value;
      console.log(JSON.stringify(data));
      //data.customerId = data.certifierId[0].id;
      this.spinner.show();
      this.http.get('/api/project/' + this.projectId).subscribe((res: Response) => {
        this.result = res;
        this.spinner.hide();
        if (this.result != null) {
          this.result.certifierId = data.certifierId[0].id;
          this.result.councilId = data.councilId[0].id;
          this.result.updatedBy = parseInt(this.userid);
          this.http.put('/api/project/', this.result).subscribe((res1: Response) => {
            this.res2 = res1;
            if (this.res2.success == true) {
              this.toastr.clear();
              this.toastr.success(this.res2.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.toggleCloseKeypeople();
              this.getProjectKeyPeopleInfo();
            }
          }, error => {
            this.toastr.clear();
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.errorService.handleError(error);
          })
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      })
    }

  }
  keypeopleValidate() {
    this.certifierErr = null;
    var valid = true;
    var data = this.keypeopleForm.value;
    if (data.certifierId.length == 0) {
      this.certifierErr = "Certifier is required";
      valid = false;
    }
    if (data.councilId.length == 0) {
      this.councilErr = "Council is required";
      valid = false;
    }
    return valid;
  }

  editTreepermission(treeModel,num){
    if(num == 0 ){
      this.treeEditPermissionTrue = !this.treeEditPermissionTrue;
      if (treeModel.activeNodes.length > 0) {
        let last: any = treeModel.activeNodes[treeModel.activeNodes.length - 1];
        let mapId = last.data.mapId;
        let type = last.data.type;
        if (type == "file") {
          this.treeMapId = mapId;
          if(last.data.isCustomerShared == 'Y'){
            this.documentPermissionForm.patchValue({ 'isCustomerShared': true});
          }else{
            this.documentPermissionForm.patchValue({ 'isCustomerShared': false});
          }

          if(last.data.isCertifierShared == 'Y'){
            this.documentPermissionForm.patchValue({ 'isCertifierShared': true});
          }else{
            this.documentPermissionForm.patchValue({ 'isCertifierShared': false});
          }

          if(last.data.isExternalUserShared == 'Y'){
            this.documentPermissionForm.patchValue({ 'isExternalUserShared': true});
          }else{
            this.documentPermissionForm.patchValue({ 'isExternalUserShared': false});
          }
        }

    }
    }else{
      this.treeEditPermissionTrue = !this.treeEditPermissionTrue;
    }
  }

  permissionCheck(num){
      var data= this.documentPermissionForm.value;
      switch (num) {
        case 0:
          if(data.isCustomerShared == false){
            this.documentPermissionForm.patchValue({ 'isCustomerShared': true});
            this.treeNodesPermissionFunction();
          }else{
            this.documentPermissionForm.patchValue({ 'isCustomerShared': false});
            this.treeNodesPermissionFunction();
          }
          break;
        case 1:
          if(data.isCertifierShared == false){
            this.documentPermissionForm.patchValue({ 'isCertifierShared': true});
            this.treeNodesPermissionFunction();
          }else{
            this.documentPermissionForm.patchValue({ 'isCertifierShared': false});
            this.treeNodesPermissionFunction();
          }
          break;
        case 2:
          if(data.isExternalUserShared == false){
            this.documentPermissionForm.patchValue({ 'isExternalUserShared': true});
            this.treeNodesPermissionFunction();
          }else{
            this.documentPermissionForm.patchValue({ 'isExternalUserShared': false});
            this.treeNodesPermissionFunction();
          }
          break;

      }
    }

    treeNodesPermissionFunction(){
      this.spinner.show();
      var data= this.documentPermissionForm.value;
      data.mapId =this.treeMapId;
      if(data.isCustomerShared == true){
        data.isCustomerShared = 'Y';
        this.documentPermissionForm.patchValue({ 'isCustomerShared': true});
      }else{
        data.isCustomerShared = 'N';
        this.documentPermissionForm.patchValue({ 'isCustomerShared': false});
      }

      if(data.isCertifierShared == true){
        data.isCertifierShared ='Y';
        this.documentPermissionForm.patchValue({ 'isCertifierShared': true});
      }else{
        data.isCertifierShared = 'N';
        this.documentPermissionForm.patchValue({ 'isCertifierShared': false});
      }

      if(data.isExternalUserShared == true){
        data.isExternalUserShared = 'Y';
        this.documentPermissionForm.patchValue({ 'isExternalUserShared': true});
      }else{
        data.isExternalUserShared = 'N';
        this.documentPermissionForm.patchValue({ 'isExternalUserShared': false});
      }
      this.http.put('/api/fileMap', data).subscribe((response: Response) => {
        this.result = response;
        if (this.result.success == true) {
          this.toastr.clear();
          this.toastr.success(this.result.message, 'Success', {
            timeOut: 2000,
            closeButton: true
          });
          this.treeEditPermissionTrue = false;
          this.nodes = [];
          this.getdocuments()
          this.spinner.hide();
        } else {
          this.toastr.clear();
          this.toastr.error(this.result.message, 'Error', {
            timeOut: 2000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 2000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });


    }

}

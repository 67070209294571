import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '../../_services';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { ErrorService } from 'src/app/_services/error.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DetailsService } from '../../_services/index';
import * as moment from 'moment/moment.js';


@Component({
  selector: 'app-user-logs',
  templateUrl: './user-logs.component.html',
  styleUrls: ['./user-logs.component.css']
})
export class UserLogsComponent implements OnInit {
  key: any;
  sort: any;
  term: any;
  filter: any;
  reverse: any;
  p: any;
  usersArr = [];
  logArr = [];
  usersArrselect = [];
  settings: any;
  result: any;
  mylog: any;
  opened: boolean = false;
  open: boolean = true
  userlogform: FormGroup;
  myDatePickerOptions: any;
  today: any;
  userErr: any;
  sdateErr: any;
  edateErr: any;
  noDataFound: boolean = false;
  

  constructor(private DetailsService: DetailsService, private http: HttpClient, private router: Router,
    private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private errorService: ErrorService,
    private confirmService: ConfirmService,
    private modalService: NgbModal, private activeModal: NgbActiveModal) {
    this.createForm();
  }


  public resetForm() {
    this.userlogform.reset({
      user: '',
      startDateTime: '',
      endDateTime: '',

    })
  }
  createForm() {
    this.userlogform = this.formBuilder.group({
      user: ['', Validators.required],
      startDateTime: ['', Validators.required],
      endDateTime: ['', Validators.required],
    })
  }
  ngOnInit() {
    this.getUser();
    this.settings = {
      text: "Select User",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: false,
      enableCheckAll: false
    };
    this.today = new Date();
    this.myDatePickerOptions = {
      dateFormat: 'dd-mm-yyyy',
      // disableUntil: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() - 1 }
    }
  }
  async getUser() {
    this.spinner.show();
    var result = await this.DetailsService.getUsers();
    this.result= result;
    this.result.forEach(r => {
      this.usersArr.push({
        "id": r.details.id, "itemName": r.details.firstName + " " + r.details.lastName

      })
      this.spinner.hide();
    })
  }


  validate() {
    this.userErr = null;
    this.sdateErr = null;
    this.edateErr = null;
    var data = this.userlogform.value;
    var valid = true;
    if (data.user == undefined || data.user == '') {
      this.userErr = "User Name is required.";
      valid = false;
    }
    else if (data.startDateTime == undefined || data.startDateTime == '') {
      this.sdateErr = "start Date is required.";
      valid = false;

    }
    else if (data.endDateTime == undefined || data.endDateTime == '') {
      this.edateErr = "End Date is required.";
      valid = false;

    }

    else if (data.startDateTime && data.endDateTime && data.startDateTime != "" && data.endDateTime != "") {
      var date1 = ("0" + (data.startDateTime.date.day)).slice(-2);
      var month1 = ("0" + (data.startDateTime.date.month)).slice(-2);
      var date2 = ("0" + (data.endDateTime.date.day)).slice(-2);
      var month2 = ("0" + (data.endDateTime.date.month)).slice(-2);
      var startdt = data.startDateTime.date.year + "-" + month1 + "-" + date1;
      var enddt = data.endDateTime.date.year + "-" + month2 + "-" + date2;
     
    }
    var cdate = moment(data.startDateTime.jsdate).format("MMDD");
    var edate = moment(data.endDateTime.jsdate).format("MMDD");
    if (cdate > edate) {
      this.edateErr = "*End Date Must be Greater Than Start Date";
    }
    return valid;
  }
  onDateChanged(event, number) {
    if (number == 0) {
      this.sdateErr = null;
    } else if (number == 1) {
      this.edateErr = null;
    }
  }
  userlogs() {
    var data = this.userlogform.value;
    var userval = [];
    var usres = [];

    if (this.validate()) {
      this.spinner.show();
      var data1 = {};
      usres = data.user;
      for (let i = 0; i < usres.length; i++) {
        userval.push(usres[i].id.toString());
      }
      data1["user"] = userval;
      var dateval = data.endDateTime.jsdate;
      data1["endDate"] = moment(dateval).format("DD-MM-YYYY")
      var dateval1 = data.startDateTime.jsdate;
      data1["startDate"] = moment(dateval1).format("DD-MM-YYYY")
      this.http.post('/api/logInOut/multiple', data1).subscribe((response) => {
       this.result = response;
        this.opened =true;
        this.logArr = this.result;        
        if(this.logArr.length == 0){
          this.spinner.hide();
         this.noDataFound = true
        }else{
          this.spinner.hide();
          this.noDataFound = false
        }
      })
    }
  }

}

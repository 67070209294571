import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ErrorService } from 'src/app/_services/error.service';


@Component({
  selector: 'app-domain',
  templateUrl: './domain.component.html',
  styleUrls: ['./domain.component.css']
})
export class DomainComponent implements OnInit {
  result: any;
  domainform: FormGroup;
  domainErr: any;
  mailErr: any;
  body: {};
  status = "";
  message = "";
  loginErr = false;
 

  constructor(private http: Http,
    private router: Router,
    private formBuilder: FormBuilder,
    private errorService: ErrorService,
      ) {
        this.createForm();
       }
       ngOnInit() {
 
      }
       public resetForm() {
        this.domainform.reset({
          name: null,
          email: null,
          
        })
      }
      createForm(){
        this.domainform = this.formBuilder.group({
          name: ['', Validators.required ],
          email: ['', Validators.required ],
         
        })
        }
 
        validate() {
          this.domainErr = null;
          this.mailErr = null;
          var data = this.domainform.value;
          var valid = true;
          if (data.name == undefined || data.name == '')
          {
            this.domainErr = " Name is required.";
            valid=false;
          }
          if (data.email == undefined || data.email == '')
          {
            this.mailErr = "Email is required.";
            valid=false;
          }
          return valid;
        }
        onSubmit(form: NgForm) {
          var data = form.value;
          if (this.validate()) {
          this.loginErr = false;
          this.http.post('/api/domain/', data).subscribe((response) => {
            this.result = response
            var status = this.result.status;
            var message = this.result.message;
                   if (status == true) {
                    localStorage.clear();              
              this.router.navigate(["/login"]);
              
      
            } 
           else {
              this.loginErr = true;
      
            }
          })
        }
        }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Http,Response, Headers, RequestOptions} from "@angular/http";
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { trigger, state, animate, transition, style } from '@angular/animations';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import * as moment from 'moment/moment.js';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { ErrorService } from 'src/app/_services/error.service';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { NgForm, EmailValidator } from '@angular/forms';
// import { MatDialog } from '@angular/material';
import { environment } from 'src/environments/environment';
import { WindowRef } from 'src/app/_services/windowRef.service';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.css']
})
export class BillingComponent implements OnInit {
  settings: any;
  settings1:any;
  clientForm: FormGroup;
  displayToggle: boolean=false;
  periodValu:any;
  nameErr :any;
  descErr :any;
  phoneErr :any;
  amountword:any;
  elems:any;
  response:any;
  instances:any;
  licenseresult:any;
  historyresult:any;
  invoiceresult:any;
  result:any;
  domainresult:any;
  atemp:any;
  number1:any;
  n_length:any;
  words_string:any;
  n_array:any;
  received_n_array:any;
  words:any;
  lastresult:any;
  noDataError: boolean=false;
  razorInstance: any;
  istab1:boolean=false;
  istab2:boolean=false;
  istab3:boolean=false;
  ispopup:boolean=false;
  ismonth:boolean=true;
  userArr: any;
  settingsplan:any;
  invperiod:any;
  planarr:any;
  dataarr:any;
  dataarrselect:any;
  planarrselect:any;
  periodarr:any;
  periodarrselect:any;
  planeErr:any;
  userErr:any;
  selectedItem:any;
  step:any;
  currencysmb:any;
  currency:any;
  gross:any;
  planAmt:any;
  values:any;
  invplanAmt:any;
  grosspro:any;
  periodErr:any;
  description:any;
  Qyt:any;
  invgross:any;
  invplan:any;
  tax:any;
  invtax:any;
  subamt:any;
  isinvoice:boolean=false;
  loggedInUserDomain:any;

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private errorService: ErrorService,
    private spinner: NgxSpinnerService,
    // public dialog: MatDialog,
    private confirmService: ConfirmService,
    private winRef: WindowRef
    
  ) {
    this.userArr = [];
    this.licenseresult=[];
    this.historyresult=[];
    this.result=[];
    this.lastresult=[];
    this.invoiceresult=[];
    this.domainresult=[];
    this.planarrselect=[];
    this.planarr=[];
    this.dataarrselect=[];
    this.dataarr=[];
    this.periodarr=[];
    this.periodarrselect=[];
  this.settings = {
    text: "Select ",
    classes: "myclass custom-class",
    enableSearchFilter: true,
    disabled: false,
    enableCheckAll: false,
    singleSelection: true,
  };
      this.settings1 = {
    text: "Select ",
    classes: "myclass custom-class",
    enableSearchFilter: true,
    disabled: false,
    enableCheckAll: false,
      singleSelection: true,
  };
   }

  ngOnInit() {
    this.loggedInUserDomain = localStorage.getItem('loggedInDomainID');
    this.createForms();
    this.getUser();
    this.step='step1';
    this.tab1();
   this.getLicenseHistoryDetials();
   this.getLicenseDetials();
    setTimeout(() => {
      var pid = this.route.snapshot.paramMap.get('payId');
       if (pid) {
        this.step='step2';
         this.tab2();
        this.paymentSave(pid);
      }else{
        this.newbulling();
      }
    });
    var lang = window.navigator.language;
   if (lang === 'en-US') {
     this.currencysmb='$';
     this.currency='USD';
     this.gross=1;
     this.grosspro=2;
     }else if(lang === 'en-au'){
      this.currencysmb='a$';
      this.currency='AUD';
      this.gross=(1*1.4);
     this.grosspro=(2*1.4);
   }else if(lang === 'en-in'){
     this.currency='INR';
     this.gross=(1*70);
     this.grosspro=(2*70);
     this.currencysmb='₹';
   }else if(lang === 'en-gb'){
      this.currency='GBP'
      this.currencysmb='£';
       this.gross=(1*0.8);
     this.grosspro=(2*0.8);
   }else{
     this.currency='USD';
     this.currencysmb='$';
         this.gross=1;
     this.grosspro=2;
   }
    this.periodValu ="Month";
    this.planarr=[
      {"id":1, "itemName":"Basic"},
      {"id":2, "itemName":"Pro"},
    ]
     this.periodarr=[
      {"id":1, "itemName":"Monthly"},
      {"id":2, "itemName":"Yearly"},
    ]
  }
  createForms() {
    this.clientForm = this.formBuilder.group({
        period: '',
        contact:'',
        scheme:'',
        plan: '',
        user: '',
        email:'',
        amount: '',
        createdAt: '',
        updatedAt: '',
        createdBy: '',
        updatedBy: '',
        month:'',
        Year:'',

      })
 }
   resetForms() {
     this.planeErr = null;
     this.userErr = null;
     this.periodErr = null;
    //  this.isCreate = true;
     this.ismonth=true;
     this.clientForm.reset({
        period: '',
        plan: '',
        user: '',
        amount: '',
        scheme:'',
        contact:'',
        email:'',
        month:'',
        Year:'',
      })
   }
  tab1(){
    this.istab1=true;
    this.istab2=false;
    this.istab3=false;
    
  }
  tab2(){
    this.istab2=true;
    this.istab1=false;
    this.istab3=false;
  }
  tab3(){
    this.istab3=true;
    this.istab1=false;
    this.istab2=false;
    // this.resetForms();
    // this.getLicenseHistoryDetials()
  }
  valuecheck(num){
    if(num == 1){
       this.periodValu = "Month";
         this.AmountCal();
      }else if(num == 2){
       this.periodValu = "Quarter";
       this.AmountCal();
    }else if(num == 3){
       this.periodValu = "Half";
       this.AmountCal();
    }else if(num == 4){
       this.periodValu = "Year";
       this.AmountCal();
    }
  }
  CustForMail() {
      // this.clientForm.patchValue({ 'email': this.loggedInUsermail });

  }
  AmountCal(){
    this.planAmt = 0;
    var usercount =0;
    var value =0;
   var Amount =0;
   this.tax = 0;
   var data = this.clientForm.value;
    if(data.plan.length != 0 ){
            if(data.plan[0].id == 1){
               this.planAmt =this.gross;
          }else  if(data.plan[0].id == 2){
               this.planAmt =this.grosspro;
          }
        }
      if(data.user.length != 0){
        usercount = data.user[0].itemName;
      }
     if(this.periodValu == "Month"){
       this.clientForm.patchValue({ 'period': 1});
              value = 1;
          }else if(this.periodValu == "Quarter"){
             this.clientForm.patchValue({ 'period': 3});
             value = 3;
          }else if(this.periodValu == "Half"){
              this.clientForm.patchValue({ 'period': 6});
             value = 6;
          }else if(this.periodValu == "Year"){
              this.clientForm.patchValue({ 'period': 12});
             value = 12;
          }
           if(this.currency == 'INR'){
             var Amount1 =( this.planAmt * usercount * value);
              this.tax =(Amount1*18)/100;
             Amount =Amount1 + this.tax;
           }else{
              Amount =( this.planAmt * usercount * value);
           }
      this.clientForm.patchValue({ 'amount': Amount});
  }
  validate(num) {
    if(num == 1 ){
        this.AmountCal();
        this.planeErr = null;
    this.userErr = null;
    this.periodErr = null;
    var data = this.clientForm.value;
    var valid = true;
    if ( data.plan.length == 0) {
      this.planeErr = "plan is required.";
      valid = false;
    } else if (data.user.length == 0) {
      this.userErr = "user count is required.";
      valid = false;
    }
    // else if (!data.period && this.periodValu == "Month") {
    //   this.periodErr = "Period is  required.";
    //   valid = false;
    // }
     return valid;
    }else{
      this.planeErr = null;
    this.userErr = null;
    this.periodErr = null;
    var data = this.clientForm.value;
    var valid = true;
    if ( data.plan.length == 0) {
      this.planeErr = "plan is required.";
      valid = false;
    } else if (data.user.length == 0) {
      this.userErr = "user count is required.";
      valid = false;
    }
    else if (!data.period) {
      this.periodErr = "Period is  required.";
      valid = false;
    }
     return valid;
    }

  }
  newbulling() {
    var newbulling = this.route.snapshot.paramMap.get('newbulling');
    if (newbulling == 'new') {
      this.step='step2';
        this.tab2();
    }else if (newbulling == 'new1') {
      this.step='step2';
        this.tab2();
        this.chooseplan(1);
    }else if (newbulling == 'new2') {
      this.step='step2';
        this.tab2();
        this.chooseplan(2);
    }else{
      this.step='step1';
      this.tab1();
    }
 
}
getLicenseDetials(){
  this.http.get('/api/licenseStatus/'+ this.loggedInUserDomain).subscribe((res: Response) => {
    this.licenseresult = res;
  }
  , error => {
    this.errorService.handleError(error);
  });
}
getLicenseHistoryDetials(){
  this.http.get('/api/license/history/'+this.loggedInUserDomain).subscribe((res: Response) => {
    this.historyresult = res;
  }, error => {
    this.errorService.handleError(error);
  });
}
purchase() {
  if (this.validate(0)) {
var date =new Date();
  var data = this.clientForm.value;
   var tdate = moment(date).format("YYYY-MM-DD HH:mm:ss")
      // const message = `Are You Sure Buy This?`;
      // const dialogData = new ConfirmDialogModel("Confirm Action", message);
      // const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      //   maxWidth: "500px",
      //   data: dialogData
      // });
      // dialogRef.afterClosed().subscribe(dialogResult => {
      //   if (dialogResult) {
        // this.spinner.show();
          var body = {};
        body["amount"] = data.amount*100;
        body["currency"] = this.currency;
        body["receipt"] = "rpt_ftracer_"+tdate;
        body["payment_capture"] = true;
        this.http.post('/api/rp/order', body).subscribe((order) => {          
          this.payForOrder(order["amount_due"], order["id"]);
        },error => {
          this.errorService.handleError(error);
        });
        }else{
          return false;
        }
//  });

}
payForOrder(amount: number, orderId: string) {
  var data = this.clientForm.value;
    var options = {
      "key": "rzp_test_ZdPUZuXGg1y9cs",
      "amount": amount, // 2000 paise = INR 20
      "name": "fphase Buy",
      "description": "fphase Payment for order : " + orderId,
      "order_id": orderId,
      "handler": function (response){
           if(response){
            var url = environment.apiUrl + "/#/billing/success/"+response.razorpay_payment_id+"/order/"+response.razorpay_order_id+"/signature/"+response.razorpay_signature
            window.location.href=url;
            rzp1.close();
            }
         },
      "prefill": {
          "name":  this.loggedInUserDomain,
          "email": data.email,
          "contact": data.contact,
      },
      "notes": { "module":"fphase",
                  "method": "expiration extension" ,
                  "plan":data.plan[0].id,
                  "user":data.user[0].itemName,
                  "period":data.period,
                  "periodtype":this.periodValu,
                  "email":data.email,
                  "contact":data.contact,
                  "amount":data.amount-this.tax,
                  "planAmt":this.planAmt,
                  "tax":this.tax
                },
      "theme": {
          "color": "#333"
      }
  };
  var rzp1 = new this.winRef.nativeWindow.Razorpay(options);
 rzp1.open();
}
paymentSave(pid){
  var response ={};
  response["razorpay_payment_id"] =pid;
  var orderId = this.route.snapshot.paramMap.get('orderid');
  var signature = this.route.snapshot.paramMap.get('signature');
 response["razorpay_signature"] =signature;

   this.http.put('/api/rp/pay/'+orderId,  response).subscribe((order) => {   
     if(order){
       this.toastr.clear();
       this.toastr.success("Payment SuccessFully", 'Report Status', {
         timeOut: 3000,
         closeButton: true
       });
       setTimeout(function(){
         var url = environment.apiUrl + "/#/billing/"
       window.location.href=url;
      }, 3000);

     }
   },error => {
     this.errorService.handleError(error);
   });


}
renew(){
 this.step='step2';
 this.tab2();
 this.planarrselect=[];
 this.dataarrselect=[];
 // if(num == 1 ){
   this.http.get('/api/license/lastPayment/').subscribe((res: Response) => {
     this.lastresult=res;
    var data = this.lastresult.notes.split(",");
    for (let i = 0; i < data.length; i++) {
      var data2 = data[i].split(":");
      if (data2[0].replace(/[\s]/g, '') == '"plan"') {
        if (data2[1].replace(/[\s]/g, '') != '""') {
         if (data2[1].replace(/[\s]/g, '') == '"1"') {
                   this.planarrselect.push({"id": 1 , "itemName":"Basic"})
         }else if (data2[1].replace(/[\s]/g, '') == '"2"') {
               this.planarrselect.push({"id":2, "itemName":"Pro"})
         }
        } 
      }
      if (data2[0].replace(/[\s]/g, '') == '"user"') {
       if (data2[1].replace(/[\s]/g, '') != '""') {
         var x = data2[1].replace(/[\s]/g, '');
         var y = x.split('"');
          this.dataarrselect.push({ "id" : (y[1]/5),"itemName" : y[1]});
       } 
     }
     if (data2[0].replace(/[\s]/g, '') == '"periodtype"') {
       if (data2[1].replace(/[\s]/g, '') != '""') {
          var x = data2[1].replace(/[\s]/g, '');
         var y = x.split('"');
         this.periodValu = y[1];
       } 
     }
   
     }
     this.AmountCal();
   }, error => {
     this.errorService.handleError(error);
   });

}
getUser() {
   for (let i = 1; i <=20; i++) {
      this.dataarr.push({"id":i ,"itemName": i*5})
   }
}
chooseplan(num){
 this.planarrselect=[];
 if(num == 1 ){
       this.planarrselect.push({"id":1, "itemName":"Basic"})
  }else if(num == 2 ){
       this.planarrselect.push({"id":2, "itemName":"Pro"})
  }
}
convertNumberToWords(amount) {
  this.words = new Array();
 this.words[0] = '';
 this.words[1] = 'One';
 this.words[2] = 'Two';
 this.words[3] = 'Three';
 this.words[4] = 'Four';
 this.words[5] = 'Five';
 this.words[6] = 'Six';
 this.words[7] = 'Seven';
 this.words[8] = 'Eight';
 this.words[9] = 'Nine';
 this.words[10] = 'Ten';
 this.words[11] = 'Eleven';
 this.words[12] = 'Twelve';
 this.words[13] = 'Thirteen';
 this.words[14] = 'Fourteen';
 this.words[15] = 'Fifteen';
 this.words[16] = 'Sixteen';
 this.words[17] = 'Seventeen';
 this.words[18] = 'Eighteen';
 this.words[19] = 'Nineteen';
 this.words[20] = 'Twenty';
 this.words[30] = 'Thirty';
 this.words[40] = 'Forty';
 this.words[50] = 'Fifty';
 this.words[60] = 'Sixty';
 this.words[70] = 'Seventy';
 this.words[80] = 'Eighty';
 this.words[90] = 'Ninety';
 amount = amount.toString();
  this.atemp = amount.split(".");
  this.number1 = this.atemp[0].split(",").join("");
  this.n_length = this.number1.length;
  this.words_string = "";
 if (this.n_length <= 9) {
      this.n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      this.received_n_array = new Array();
     for (var i = 0; i < this.n_length; i++) {
         this.received_n_array[i] = this.number1.substr(i, 1);
     }
     for (var i = 9 - this.n_length, j = 0; i < 9; i++, j++) {
         this.n_array[i] = this.received_n_array[j];
     }
     for (var i = 0, j = 1; i < 9; i++, j++) {
         if (i == 0 || i == 2 || i == 4 || i == 7) {
             if (this.n_array[i] == 1) {
                 this.n_array[j] = 10 + parseInt(this.n_array[j]);
                 this.n_array[i] = 0;
             }
         }
     }
    this.values = "";
     for (var i = 0; i < 9; i++) {
         if (i == 0 || i == 2 || i == 4 || i == 7) {
            this.values = this.n_array[i] * 10;
         } else {
             this.values = this.n_array[i];
         }
         if (this.values != 0) {
             this.words_string += this.words[this.values] + " ";
         }
         if ((i == 1 && this.values != 0) || (i == 0 && this.values != 0 && this.n_array[i + 1] == 0)) {
             this.words_string += "Crores ";
         }
         if ((i == 3 && this.values != 0) || (i == 2 && this.values != 0 && this.n_array[i + 1] == 0)) {
             this.words_string += "Lakhs ";
         }
         if ((i == 5 && this.values != 0) || (i == 4 && this.values != 0 && this.n_array[i + 1] == 0)) {
             this.words_string += "Thousand ";
         }
         if (i == 6 && this.values != 0 && (this.n_array[i + 1] != 0 && this.n_array[i + 2] != 0)) {
             this.words_string += "Hundred and ";
         } else if (i == 6 && this.values != 0) {
             this.words_string += "Hundred ";
         }
     }
     this.words_string = this.words_string.split("  ").join(" ");
 }
 return this.words_string;
}
invoice(id){
      this.isinvoice=true;
      this.http.get('/api/rp/payment/'+id).subscribe((res: Response) => {
       this.result = res;
       this.invoiceresult =this.result.receipt;
       this.domainresult =this.result.domain;
       var notes =this.invoiceresult.notes;
      this.amountword=this.convertNumberToWords(this.invoiceresult.amount)+" Only.";
      if(notes){
        var data = notes.split(",");

    for (let i = 0; i < data.length; i++) {
      var data2 = data[i].split(":");
      if (data2[0].replace(/[\s]/g, '') == '"plan"' || data2[0].replace(/[\s]/g, '') == '{"plan"') {
        if (data2[1].replace(/[\s]/g, '') != '""') {
         if (data2[1].replace(/[\s]/g, '') == '"1"') {
                this.invplan=1;
         }else if (data2[1].replace(/[\s]/g, '') == '"2"') {
            this.invplan=2;
         }
        } 
      }
      if (data2[0].replace(/[\s]/g, '') == '"user"' || data2[0].replace(/[\s]/g, '') == '{"user"') {
       if (data2[1].replace(/[\s]/g, '') != '""') {
         var x = data2[1].replace(/[\s]/g, '');
         var y = x.split('"');
         this.Qyt=y[1];
       } 
     }
      if (data2[0].replace(/[\s]/g, '') == '"module"' || data2[0].replace(/[\s]/g, '') == '{"module"') {
       if (data2[1].replace(/[\s]/g, '') != '""') {
         var x = data2[1].replace(/[\s]/g, '');
         var y = x.split('"');
         this.description= y[1];
       } 
     }
      if (data2[0].replace(/[\s]/g, '') == '"planAmt"' || data2[0].replace(/[\s]/g, '') == '{"planAmt"') {
       if (data2[1].replace(/[\s]/g, '') != '""') {
         var x = data2[1].replace(/[\s]/g, '');
         var y = x.split('"');
         this.invplanAmt= y[1];
       } 
     }
       if (data2[0].replace(/[\s]/g, '') == '"tax"' || data2[0].replace(/[\s]/g, '') == '{"tax"') {
       if (data2[1].replace(/[\s]/g, '') != '""') {
          var x = data2[1].replace(/[\s]/g, '');
         var y = x.split('"');
         this.invtax = y[1];
       } 
     }
     if (data2[0].replace(/[\s]/g, '') == '"amount"' || data2[0].replace(/[\s]/g, '') == '{"amount"') {
       if (data2[1].replace(/[\s]/g, '') != '""') {
          var x = data2[1].replace(/[\s]/g, '');
         var y = x.split('"');
         this.subamt = y[1];
       } 
     }
     if (data2[0].replace(/[\s]/g, '') == '"periodtype"' || data2[0].replace(/[\s]/g, '') == '{"periodtype"') {
       if (data2[1].replace(/[\s]/g, '') != '""') {
           if (data2[1].replace(/[\s]/g, '') == '"Month"') {
               this.invperiod = 1;
         }else if (data2[1].replace(/[\s]/g, '') == '"Quarter"') {
               this.invperiod = 3
         }else if (data2[1].replace(/[\s]/g, '') == '"Half"') {
               this.invperiod = 6
         }else if (data2[1].replace(/[\s]/g, '') == '"Year"') {
               this.invperiod = 12
         }
         
       } 
     }
     }
   }   
     }, error => {
       this.errorService.handleError(error);
     });
     }
     onCancel(){
        this.isinvoice=false;
        this.invoiceresult=[];
     }
       pdfPrint() {

 window.print();
   }

pdfDownload() {
         this.captureScreen();

}
 captureScreen() {
 var data = document.getElementById('contentToConvert');
 html2canvas(data).then(canvas => {
   // Few necessary setting options
   var imgWidth = 208;
   var pageHeight = 265;
   var imgHeight = canvas.height * imgWidth / canvas.width;
   var heightLeft = imgHeight;
   const contentDataURL = canvas.toDataURL('image/png')
   let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
   
   var position = 0;
   pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, pageHeight)
   //pdf.save('MYPdf.pdf'); // Generated PDF
   pdf.output('save',  '.pdf');
   
 });
}
onselect(id){
 this.router.navigate(['/print-invoice/'+id])
}

}

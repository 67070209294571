import { Component, OnInit } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Router } from '@angular/router';
import * as moment from 'moment/moment.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { GoogleChartsModule } from 'angular-google-charts/angular-google-charts';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
@Component({
  selector: 'app-resource-management',
  templateUrl: './resource-management.component.html',
  styleUrls: ['./resource-management.component.css']
})
export class ResourceManagementComponent implements OnInit {
  alltasksArr = [];
  allprojectArr = [];
  allUserArr = [];
  alltasksuserArr = [];
  filter: any;
  reverse: any;
  key: any;
  step: any;
  chartType: any;
  chartColumNames: any;
  chartOptions: any;
  chartData: any;
  dateErr: any;
  DateErr: any;
  DateErrs: any;
  myapprovals: any;
  myapprovalUser: any;
  myDatePickerOptions: any;
  today: any;
  filterform: FormGroup;
  nameErr: any;
  tab01: boolean = true;
  tab02: boolean = false;
  tab1: any = 'tab1';
  tab2: any;
  projassigned: any;
  COL_NOT_STARTED = "#ccc";
  COL_TAKEN = "#ff9800";
  COL_COMPLETED = "#4caf50";
  PROJ_TASK_NOT_STARTED = "#9e9e9e";
  PROJ_TASK_TAKEN = "#ffc107";
  PROJ_TASK_COMPLETED = "#8bc34a";
  JOB_COMPLETED = "#f5748a";
  JOB_IN_PROGRESS = "#009688";
  result1: any;
  result: any;
  result3: any;

  isAddTasksToChart: boolean = true;
  isAddProjectTasksToChart: boolean = false;
  isAddProjectJobsToChart: boolean = false;

  constructor(private http: HttpClient, private router: Router, private spinner: NgxSpinnerService, private formBuilder: FormBuilder,
    private confirmService: ConfirmService, ) {
    this.chartType = "Timeline";
    this.chartOptions = { timeline: { showRowLabels: true }, avoidOverlappingGridLines: false , hAxis: {
            format: 'd/M/yy hh:mm aa'}}
    this.chartColumNames = [
      { type: 'string', id: 'User' },
      { type: 'string', id: 'TaskName' },
      { type: 'string', id: 'style', role: 'style' },
      { type: 'date', id: 'Start' },
      { type: 'date', id: 'End' }
    ]
    this.chartData = [];
    this.today = new Date();

    this.myDatePickerOptions = {
      dateFormat: 'dd-mm-yyyy',
      // disableUntil: {year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate()-1}
    }
    this.createForm();
  }
  public resetForm() {
    this.filterform.reset({
      startDateTime: '',
      endDateTime: '',

    })
  }
  createForm() {
    this.filterform = this.formBuilder.group({
      startDateTime: ['', Validators.required],
      endDateTime: ['', Validators.required],

    })
  }



  ngOnInit() {
    // this.getAllTasks();
    this.step = "step1";
    this.getallusertasks(0);
  }

  onSelectdeals(deals) {
    this.router.navigate(['/details', deals]);
  }


  getAllTasks() {
    this.spinner.show();
    this.http.get('/api/users/dashboard').subscribe((res: Response) => {
      this.result = res;
      for (let i = 0; i < this.result.length; i++) {
        if (this.result[i].task.taskcount == 0 && this.result[i].project.length > 0) {
          this.allprojectArr.push({
            "userId": this.result[i].user.uid, "userName": this.result[i].user.username, "ProjectArray": this.result[i].project, "row": this.result[i].project.length, "userRole": this.result[i].user.userRole.name
          })
          this.myapprovals = this.allprojectArr.length;
        }
        if (this.result[i].task.taskcount == 0 && this.result[i].project.length == 0) {
          this.allUserArr.push({
            "userId": this.result[i].user.uid, "userName": this.result[i].user.username
          })
          this.myapprovalUser = this.allUserArr.length;
        }
      }
      this.spinner.hide();
    });
  }

  getallusertasks(num) {
    this.dateErr = null;
    var today = new Date();
    var data = this.filterform.value;
    var sdate = moment(today).format("YYYY-MM-DD");
    var val = today.setDate(today.getDate() + 7);
    var edate = moment(val).format("YYYY-MM-DD");
    if (num == 0) {
      this.gettastsummary(sdate, edate, 1);
    }
    if (data.startDateTime && data.endDateTime && data.startDateTime != "" && data.endDateTime != "") {
      var date1 = ("0" + (data.startDateTime.date.day)).slice(-2);
      var month1 = ("0" + (data.startDateTime.date.month)).slice(-2);
      var date2 = ("0" + (data.endDateTime.date.day)).slice(-2);
      var month2 = ("0" + (data.endDateTime.date.month)).slice(-2);
      var startdt = data.startDateTime.date.year + "-" + month1 + "-" + date1;
      var enddt = data.endDateTime.date.year + "-" + month2 + "-" + date2;
      if (num == 1) {
        this.gettastsummary(startdt, enddt, 0);
      } else {
        this.gettastsummary(sdate, edate, 1);
      }
    }
    this.dateErr = "*Start Date & End Date Must Required";
    var cdate = moment(data.startDateTime.jsdate).format("MMDD");
    var edate = moment(data.endDateTime.jsdate).format("MMDD");
    if (cdate > edate) {
      this.DateErr = "*End Date Must be Greater Than Start Date";
      this.dateErr = "";
    }

  }
  gettastsummary(sdate, edate, num) {
    this.spinner.show();
    this.chartData = [];
    this.http.get('/api/tasks/dashboard/' + sdate + '/' + edate).subscribe((res: Response) => {
      this.result = res;
      //this.isAddTasksToChart = true;
      this.addTasksAndJobsToChart();
      if (num == 1) {
        if (sdate) {
          var ydate = moment(sdate).format("YYYY")
          var mdate = moment(sdate).format("M")
          var ddate = moment(sdate).format("D")
          this.filterform.patchValue({
            'startDateTime': {
              date: {
                year: ydate,
                month: mdate,
                day: ddate
              }
            }
          });
        }
        if (edate) {
          var ydate = moment(edate).format("YYYY")
          var mdate = moment(edate).format("M")
          var ddate = moment(edate).format("D")
          this.filterform.patchValue({
            'endDateTime': {
              date: {
                year: ydate,
                month: mdate,
                day: ddate
              }
            }
          });
        }
      }
      this.spinner.hide();
    })
  }

  addTasksAndJobsToChart() {
    this.chartData = [];
    if (this.isAddTasksToChart) {
      this.addDealTasksToChart();
    }
    if (this.isAddProjectTasksToChart) {
      this.addProjectTasksToChart();
    }
    if (this.isAddProjectJobsToChart) {
      this.addProjectJobsToChart();
    }
  }

  addDealTasksToChart() {
    this.result.forEach(u => {
      if (u.tasks) {
        u.tasks.forEach(r => {
          var task = [];
          task.push(u.user.firstName);
          task.push( "(" + r.projectName + ") "+ r.name);
          if (r.isCompleted == "Y") {
            task.push(this.COL_COMPLETED);
          } else if (r.isTaken == "Y") {
            task.push(this.COL_TAKEN);
          } else {
            task.push(this.COL_NOT_STARTED);
          }
          if (moment(new Date(r.startBy)).format("YYYY-MM-DD") == moment(new Date(r.completeBy)).format("YYYY-MM-DD")) {
            task.push(new Date(r.startBy));
            task.push(new Date(r.completeBy).getTime() + ((24 * 60 * 60 * 1000) - 1));
          } else {
            task.push(new Date(r.startBy));
            task.push(new Date(r.completeBy));
          }

          this.chartData.push(task);
        });
      }
    });
  }

  addProjectTasksToChart() {
    this.result.forEach(u => {
      if (u.projectTasks) {
        u.projectTasks.forEach(r => {
          var task = [];
          task.push(u.user.firstName);
          task.push( "(" + r.projectName + ") "+ r.name);
          if (r.isCompleted == "Y") {
            task.push(this.PROJ_TASK_COMPLETED);
          } else if (r.isTaken == "Y") {
            task.push(this.PROJ_TASK_TAKEN);
          } else {
            task.push(this.PROJ_TASK_NOT_STARTED);
          }
          if (moment(new Date(r.startBy)).format("YYYY-MM-DD") == moment(new Date(r.completeBy)).format("YYYY-MM-DD")) {
            task.push(new Date(r.startBy));
            task.push(new Date(r.completeBy).getTime() + ((24 * 60 * 60 * 1000) - 1));
          } else {
            task.push(new Date(r.startBy));
            task.push(new Date(r.completeBy));
          }

          this.chartData.push(task);
        });
      }
    });
  }

  addProjectJobsToChart() {
    this.result.forEach(u => {
      if (u.jobs) {
        u.jobs.forEach(r => {
          var job = [];
          job.push(u.user.firstName);
          job.push("(" + r.projectName + ") " + r.jobName);
          if (r.isCompleted == "Y") {
            job.push(this.JOB_COMPLETED);
          } else {
            job.push(this.JOB_IN_PROGRESS);
          }
          if (moment(new Date(r.startDate)).format("YYYY-MM-DD") == moment(new Date(r.endDate)).format("YYYY-MM-DD")) {
            job.push(new Date(r.startDate));
            job.push(new Date(r.endDate).getTime() + ((24 * 60 * 60 * 1000) - 1));
          } else {
            job.push(new Date(r.startDate));
            job.push(new Date(r.endDate));
          }
          this.chartData.push(job);
        });
      }
    });
  }

  dealTaskChecked(values:any):void {
    if (values.currentTarget.checked) {
      this.isAddTasksToChart = true;
    } else {
      this.isAddTasksToChart = false;
    }
    this.addTasksAndJobsToChart();
  }

  projectTaskChecked(values:any):void {
    if (values.currentTarget.checked) {
      this.isAddProjectTasksToChart = true;
    } else {
      this.isAddProjectTasksToChart = false;
    }
    this.addTasksAndJobsToChart();
  }

  projectJobsChecked(values:any):void {
    if (values.currentTarget.checked) {
      this.isAddProjectJobsToChart = true;
    } else {
      this.isAddProjectJobsToChart = false;
    }
    this.addTasksAndJobsToChart();
  }

  tabs1() {
    this.tab01 = true
    this.tab02 = false
    this.getallusertasks(0);
  }

  tabs2() {
    this.getAllTasks();
    this.tab02 = true
    this.tab01 = false
  }
  onSwitch(check) {
    switch (check) {
      case 1:
        return 'tab1';
      case 2:
        return 'tab2';
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { DetailsService } from '../../_services/index';
import { ModalService } from '../../_services';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { ErrorService } from 'src/app/_services/error.service';
import { PermissionService } from 'src/app/_services/permission.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DragulaService } from 'ng2-dragula';
import { EmailValidator } from '@angular/forms';
import { trigger, state, animate, transition, style } from '@angular/animations';
import { Subscription } from 'rxjs';
import { shouldCallLifecycleInitHook } from '@angular/core/src/view';
import { IfStmt } from '@angular/compiler';
import { multicast } from 'rxjs/operators';


@Component({
  selector: 'app-estimation-admin',
  templateUrl: './estimation-admin.component.html',
  styleUrls: ['./estimation-admin.component.css'],
  viewProviders: [DragulaService],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class EstimationAdminComponent implements OnInit {
  loggedInUserDomain: any;
  basicArr = [];
  designGroupsArr = [];
  optionArr = [];
  result: any;
  projectTypeArr: any;
  projectTypeArrSelect: any;
  projectType = {};
  region = {};
  GroupDesign = {};
  propertyType = {};
  propertyTypeArr = [];
  isCreate: boolean = true;
  isCreate1: boolean = true;
  imageTagOpen: boolean = false;
  optinInputShow: boolean = false;
  priceShow: boolean = false;
  nameErr: any;
  regionErr: any;
  priceErr: any;
  widthErr: any;
  bedRoomErr: any;
  bathRoomErr: any;
  garageErr: any;
  regionArr: any;
  GroupDesignArr: any;
  regionArrSelect: any;
  storeyTypeErr: any;
  imageNameErr: any;
  designGroupsnameErr: any;
  designGroupspriceErr: any;
  designGroupsstoreyTypeErr: any;
  designGroupsDescNameErr: any;
  propertiesnameErr: any;
  propertiespriceErr: any;
  propertiesDesNameErr: any;
  propertiesGropTypeErr: any;
  propertiesdefaultOptionErr: any;
  addOptionnameErr: any;
  addOptionDesNameErr: any;
  addOptionpriceErr: any;
  basicid: any;
  userid: any;
  admin: any;
  result4: any;
  result3: any;
  res: any;
  settingsRegion: any;
  settings: any;
  Muiltsettings: any;
  settingsGroup: any;
  settingspropertiy: any;
  basicPackageForm: FormGroup;
  designGroupsForm: FormGroup;
  propertiesForm: FormGroup;
  addOptionForm: FormGroup;
  uploadFileForm: FormGroup;
  menuBasicPackages: string = 'out';
  menuDesginGroups: string = 'out';
  menuGroupsProperties: string = 'out';
  menuAddOption: string = 'out';
  imageFile: string[] = [];
  imageFileArr = [];
  groupPropertiesArr = [];
  groupDesignArrSelect = [];
  propertyTypesArrSelect = [];
  groupDesignSelectt = [];
  imageUrl: any;
  optionID: any;
  editSh: boolean = false;
  filter: any;
  reverse: any;
  p: any;
  term: any;
  filterSetting: any;
  fileErr: any;
  estimationDocumentArr = [];
  customerCodesArr = [];
  groupPropertiesCodeArr = [];
  myFiles: string[] = [];
  filesUploadArr = [];
  groupPropertyListArr: any;
  accordienClose: boolean = false;
  boxInedx = 0;
  popoverform: boolean = false;
  popoverform1: boolean = false;
  basicId: any;
  designGroupIdEdit: any;
  propertiesEditId: any;
  addOptionEditId: any;
  propertyTypeCheck: any;
  constructor(private http: HttpClient, private router: Router, private DetailsService: DetailsService, private modalService: NgbModal,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private errorService: ErrorService,
    private confirmService: ConfirmService,
    private dragulaService: DragulaService,
    private permissionService: PermissionService,
    private spinner: NgxSpinnerService,
  ) {

  }

  ngOnInit() {

    this.userid = localStorage.getItem("loggedInUserID");
    this.admin = localStorage.getItem("loggedInUserAdmin");
    this.createForm();
    this.getdropdownlist();
    this.settingsRegion = {
      text: "Select Region",
      classes: "myclass custom-class",
      singleSelection: true,
      enableCheckAll: false
    };
    this.settings = {
      text: "Select Storey Type",
      classes: "myclass custom-class",
      singleSelection: true,
      enableCheckAll: false
    };
    this.filterSetting = {
      text: "Filter DesignGroup",
      classes: "myclass custom-class",
      singleSelection: true,
      enableCheckAll: false
    };
    this.Muiltsettings = {
      text: "Select Storey Type",
      classes: "myclass custom-class",
      singleSelection: false,
      enableCheckAll: true
    };
    this.settingsGroup = {
      text: "Select Desgin Group",
      classes: "myclass custom-class",
      singleSelection: true,
      enableCheckAll: false
    };
    this.settingspropertiy = {
      text: "Select PropertyTypes",
      classes: "myclass custom-class",
      singleSelection: true,
      enableCheckAll: false
    };
  }
  onchangtab(value) {
    var id = value.nextId;
    if (id == "BasicPackages") {
      this.menuBasicPackages = 'out';
      this.getBasicPackages();
    } else if (id == "DesignGroups") {
      this.menuDesginGroups = 'out';
      this.getDesignGropus();
    } else if (id == "GroupProperties") {
      this.menuGroupsProperties = 'out';
      this.getAllGropDesign();
    } else if (id == "PropertyCode") {
      this.getPropertyCode();
    }
    else if (id == "EstimationTemplate") {
      this.fileErr = null;
    }
  }
  async getdropdownlist() {
    this.projectTypeArr = [];
    this.regionArr = [];
    var result = await this.DetailsService.getprojectTypes();
    this.result4 = result;
    this.result4.forEach(r => {
      this.projectType[r.id] = r;
      this.projectTypeArr.push({
        "id": r.id, "itemName": r.type
      })
    });
    var result2 = await this.DetailsService.getaustralianStates();
    this.result3 = result2;
    this.result3.forEach(r => {
      this.region[r.id] = r;
      this.regionArr.push({
        "id": r.id, "itemName": r.name
      })
    });
    this.getBasicPackages()
  }
  getBasicPackages() {
    this.basicArr = [];
    this.spinner.show();
    this.http.get('/api/basicPackages').subscribe((res: Response) => {
      this.result = res;
      var result = this.result;
      this.spinner.hide();
      for (let i = 0; i < result.length; i++) {
        var region = parseInt(result[i].region);
        this.basicArr.push({
          "id": result[i].id, "name": result[i].name, "region": region, "storeyType": result[i].storeyType, "referenceLink": result[i].referenceLink, "thumbnailUrl": result[i].thumbnailUrl
        })
      }
    });
  }
  BasicPackagesvalidate() {
    this.nameErr = null;
    this.regionErr = null;
    this.priceErr = null;
    this.widthErr = null;
    this.bedRoomErr = null;
    this.bathRoomErr = null;
    this.garageErr = null;
    this.storeyTypeErr = null;
    this.imageNameErr = null;
    var data = this.basicPackageForm.value;
    var valid = true;
    if (data.name == undefined || data.name == '') {
      this.nameErr = "BasicPackage Name is required.";
      valid = false;
    }
    else if (data.region == undefined || data.region == '') {
      if (data.region.length == 0) {
        this.regionErr = "Region is required.";
        valid = false;
      }
    }
    else if (data.storeyType == undefined || data.storeyType == '') {
      if (data.storeyType.length == 0) {
        this.storeyTypeErr = "StoreyType is required.";
        valid = false;
      }
    }
    else if (data.width == undefined || data.width == '') {
      this.widthErr = "Width is required.";
      valid = false;
    }
    else if (data.width < 1) {
      this.widthErr = "Invalid width.";
      valid = false;
    }
    else if (data.price == undefined || data.price == '') {
      this.priceErr = "Price is required.";
      valid = false;
    } else if (data.price < 1) {
      this.priceErr = "Invalid price.";
      valid = false;
    }
    else if (data.bedRoom == undefined || data.bedRoom == '') {
      this.bedRoomErr = "BedRoom is required.";
      valid = false;
    } else if (data.bedRoom > 5) {
      this.bedRoomErr = "Count should be below 6.";
      valid = false;
    }
    else if (data.bedRoom < 1) {
      this.bedRoomErr = "Count should be above 0.";
      valid = false;
    }
    else if (data.bathRoom == undefined || data.bathRoom == '') {
      this.bathRoomErr = "BathRoom is required.";
      valid = false;
    } else if (data.bathRoom > 3) {
      this.bathRoomErr = "Count should be below 4.";
      valid = false;
    }
    else if (data.bathRoom < 1) {
      this.bathRoomErr = "Count should be Minimum 0.";
      valid = false;
    }
    else if (data.garage == undefined || data.garage == '') {
      //  this.basicPackageForm.patchValue({ 'garage': 0 });
      this.garageErr = "Garage is required.";
      valid = false;
      //  if(data.garage == 0){
      //    this.garageErr = null;
      //     valid = true;
      //  }
    } else if (data.garage > 3) {
      this.garageErr = "Count should be below 4.";
      valid = false;
    }
    else if (data.garage < 0) {
      this.garageErr = "Count should be Minimum 0.";
      valid = false;
    }
    else if (this.isCreate == true) {
      if (data.imageName == undefined || data.imageName == '') {
        this.imageNameErr = "Image is required.";
        valid = false;
      }
    }

    return valid;
  }

  submitBasicPackagess() {
    var data = this.basicPackageForm.value;
    if (this.BasicPackagesvalidate()) {
      if (data.storeyType.length != 0) {
        data.storeyType = data.storeyType[0].id;
      }
      if (data.region.length != 0) {
        data.region = data.region[0].id;
      }
      this.spinner.show();
      if (!data.id) {
        data.createdBy = this.userid;
        this.http.post('/api/basicPackage', data).subscribe((response) => {
          this.res = response;
          if (this.res.success == true) {
            this.resetForm();
            this.toastr.clear();
            this.toastr.success(this.res.message, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });
            this.basicid = this.res.response.id;
            this.imageUpload();
            this.toggleBasicPackage();
            this.getBasicPackages();
            this.basicArr = [];
          } else {
            this.spinner.hide();
            this.toastr.clear();
            this.toastr.error(this.res.message, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });
          }
          this.spinner.hide();
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 2000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })
      } else {
        data.updatedBy = this.userid;
        this.http.put('/api/basicPackage', data).subscribe((response) => {
          this.res = response;
          if (this.res.success == true) {
            this.resetForm();
            this.toastr.clear();
            this.toastr.success(this.res.message, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });
            this.toggleBasicPackage();
            this.getBasicPackages();
            this.basicArr = [];
          } else {
            this.spinner.hide();
            this.toastr.clear();
            this.toastr.error(this.res.message, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });
          }
          this.spinner.hide();
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 2000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })
      }
    }

  }
  toggleBasicPackage() {
    this.nameErr = null;
    this.regionErr = null;
    this.priceErr = null;
    this.widthErr = null;
    this.bedRoomErr = null;
    this.bathRoomErr = null;
    this.garageErr = null;
    this.storeyTypeErr = null;
    this.imageNameErr = null;
    this.projectTypeArrSelect = [];
    this.imageFileArr = [];
    this.regionArrSelect = [];
    this.isCreate = true;
    this.basicid = undefined;
    this.resetForm();
    this.menuBasicPackages = this.menuBasicPackages === 'out' ? 'in' : 'out';
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
  }
  selectectEvent(array, selectArr, id) {
    for (let j = 0; j < array.length; j++) {
      if (id == array[j].id) {
        selectArr.push({ 'id': array[j].id, 'itemName': array[j].itemName });
      }
    }
  }
  selectectArrayEvent(array, selectArr, arrayvalue) {
    arrayvalue.forEach(r => {
      for (let j = 0; j < array.length; j++) {
        if (r == array[j].id) {
          selectArr.push({ 'id': array[j].id, 'itemName': array[j].itemName });
        }
      }
    });
  }
  createForm() {
    this.basicPackageForm = this.formBuilder.group({
      id: '',
      name: ['', Validators.required],
      region: [],
      storeyType: [],
      width: '',
      createdDate: '',
      createdBy: '',
      updatedDate: '',
      updatedBy: '',
      isActive: '',
      price: '',
      bedRoom: '',
      bathRoom: '',
      garage: '',
      thumbnailId: '',
      thumbnailUrl: '',
      referenceLink: '',
      imageName: '',
      description: ''
    });
    this.designGroupsForm = this.formBuilder.group({
      id: '',
      name: ['', Validators.required],
      storeyType: [],
      createdDate: '',
      createdBy: '',
      updatedDate: '',
      updatedBy: '',
      isActive: '',
      defaultPrice: '',
      description: ''
    });
    this.propertiesForm = this.formBuilder.group({
      id: '',
      name: ['', Validators.required],
      designGroupId: [],
      propertyTypeId: [],
      createdDate: '',
      createdBy: '',
      updatedDate: '',
      updatedBy: '',
      isActive: '',
      defaultPrice: 0,
      description: '',
      isDefaultIncluded: ''
    });
    this.addOptionForm = this.formBuilder.group({
      id: '',
      name: ['', Validators.required],
      propertyId: '',
      createdDate: '',
      createdBy: '',
      updatedDate: '',
      updatedBy: '',
      isActive: '',
      defaultPrice: '',
      description: '',
    });

    this.uploadFileForm = this.formBuilder.group({
      file: '',
      description: ''
    });


  }
  public resetForm() {

    this.basicPackageForm.reset({
      id: '',
      name: '',
      region: [],
      storeyType: [],
      width: '',
      createdDate: '',
      createdBy: '',
      updatedDate: '',
      updatedBy: '',
      isActive: '',
      price: '',
      bedRoom: '',
      bathRoom: '',
      garage: '',
      thumbnailId: '',
      thumbnailUrl: '',
      referenceLink: '',
      imageName: '',
      description: ''
    });
    this.designGroupsForm.reset({
      id: '',
      name: '',
      storeyType: [],
      createdDate: '',
      createdBy: '',
      updatedDate: '',
      updatedBy: '',
      isActive: '',
      defaultPrice: 0,
      description: ''
    });
    this.propertiesForm.reset({
      id: '',
      name: '',
      designGroupId: [],
      propertyTypeId: [],
      createdDate: '',
      createdBy: '',
      updatedDate: '',
      updatedBy: '',
      isActive: '',
      defaultPrice: '',
      description: '',
      isDefaultIncluded: ''
    });
    this.addOptionForm.reset({
      id: '',
      name: '',
      propertyId: '',
      createdDate: '',
      createdBy: '',
      updatedDate: '',
      updatedBy: '',
      isActive: '',
      defaultPrice: '',
      description: '',
    });
  }
  basicPackageEdit(id, num) {
    if (num == 0) {
      this.popoverform = true;
      this.basicId = id;
      this.nameErr = null;
      this.regionErr = null;
      this.priceErr = null;
      this.widthErr = null;
      this.bedRoomErr = null;
      this.bathRoomErr = null;
      this.garageErr = null;
      this.storeyTypeErr = null;
      this.imageNameErr = null;
      this.isCreate = false;
      this.projectTypeArrSelect = [];
      this.regionArrSelect = [];
      this.basicid = id;
      this.http.get('/api/basicPackage/' + this.basicid).subscribe((res: Response) => {
        this.result = res;
        var result = this.result;
        this.selectectEvent(this.regionArr, this.regionArrSelect, parseInt(result.region));
        this.selectectEvent(this.projectTypeArr, this.projectTypeArrSelect, result.storeyType);
        if (result) {
          this.imageUrl = result.thumbnailUrl
          this.basicPackageForm.patchValue({ 'id': result.id });
          this.basicPackageForm.patchValue({ 'name': result.name });
          this.basicPackageForm.patchValue({ 'width': result.width });
          this.basicPackageForm.patchValue({ 'price': result.price });
          this.basicPackageForm.patchValue({ 'bedRoom': result.bedRoom });
          this.basicPackageForm.patchValue({ 'bathRoom': result.bathRoom });
          this.basicPackageForm.patchValue({ 'garage': result.garage });
          this.basicPackageForm.patchValue({ 'thumbnailId': result.thumbnailId });
          this.basicPackageForm.patchValue({ 'thumbnailUrl': result.thumbnailUrl });
          this.basicPackageForm.patchValue({ 'referenceLink': result.referenceLink });
          this.basicPackageForm.patchValue({ 'createdDate': result.createdDate });
          this.basicPackageForm.patchValue({ 'createdBy': result.createdBy });
          this.basicPackageForm.patchValue({ 'updatedDate': result.updatedDate });
          this.basicPackageForm.patchValue({ 'updatedBy': result.updatedBy });
          this.basicPackageForm.patchValue({ 'isActive': result.isActive });
        }
      });
      this.menuBasicPackages = this.menuBasicPackages === 'out' ? 'in' : 'out';
    } else {
      this.http.get('/api/basicPackage/' + this.basicid).subscribe((res: Response) => {
        this.result = res;
        if (this.result) {
          this.imageUrl = this.result.thumbnailUrl;
          this.basicPackageForm.patchValue({ 'thumbnailId': this.result.thumbnailId });
          this.basicPackageForm.patchValue({ 'thumbnailUrl': this.result.thumbnailUrl });
        }
      });
    }
  }
  basicPackageDelete(id) {
    this.confirmService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) => {
        if (confirmed) {
          this.spinner.show();
          this.http.delete('/api/basicPackage/' + id).subscribe((response) => {
            this.toastr.clear();
            this.result = response;
            if (this.result.success) {
              this.resetForm();
              this.toastr.success(this.result.message, 'Report Status', {
                timeOut: 2000,
                closeButton: true
              });
              this.toggleBasicPackage();
              this.getBasicPackages();
            } else {
              this.toastr.error(this.result.message, 'Report Status', {
                timeOut: 2000,
                closeButton: true
              });
            }
            this.spinner.hide();
          }, error => {
            this.toastr.error(error, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });
            this.spinner.hide();
            this.errorService.handleError(error);
          })
        }
        this.basicArr = [];
      })
      .catch(() => {
        return false;
      });
  }
  addImageOpen() {
    this.imageTagOpen = !this.imageTagOpen;
  }
  getAttachFile(e, num) {
    if (num == 0) {
      this.imageFile = [];
      this.imageFileArr = [];
      for (var i = 0; i < e.target.files.length; i++) {
        this.imageFile.push(e.target.files[i]);
        this.imageFileArr.push({
          "name": e.target.files[i].name
        })
      }
    } else if (num == 1) {
      this.imageFile = [];
      this.imageFileArr = [];
      for (var i = 0; i < e.target.files.length; i++) {
        this.imageFile.push(e.target.files[i]);
        this.imageFileArr.push({
          "name": e.target.files[i].name
        })
      }
      this.imageUpload();
    }

  }
  imageUpload() {
    const frmData = new FormData();
    let data = this.basicPackageForm.value;
    for (var i = 0; i < this.imageFile.length; i++) {
      frmData.append("file", this.imageFile[i]);
    }
    frmData.append("packageId", this.basicid);
    frmData.append("userId", this.userid);
    this.http.post('/api/basicPackage/upload/thumbnail/', frmData).subscribe((response) => {
      this.res = response;
      if (this.isCreate == false) {
        if (this.res.success == true) {
          this.basicPackageEdit(this.basicid, 1);
          this.toastr.clear();
          this.toastr.success(this.res.message, 'Success', {
            timeOut: 2000,
            closeButton: true
          });
          this.imageFile = [];
          this.spinner.hide();
        } else {
          this.toastr.clear();
          this.toastr.error(this.res.message, 'Error', {
            timeOut: 2000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }

    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 2000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }



  // Design Groups//


  toggleDesignGroups() {
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.isCreate = true;
    this.designGroupsnameErr = null;
    this.designGroupspriceErr = null;
    this.designGroupsstoreyTypeErr = null;
    this.designGroupsDescNameErr = null;
    this.resetForm();
    this.menuDesginGroups = this.menuDesginGroups === 'out' ? 'in' : 'out';
  }
  closeDesignGroups() {
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.menuDesginGroups = this.menuDesginGroups === 'out' ? 'in' : 'out';
  }
  getDesignGropus() {
    this.designGroupsArr = [];
    this.spinner.show();
    this.http.get('/api/designGroups').subscribe((res: Response) => {
      this.res = res;
      var result = this.res;
      for (let i = 0; i < result.length; i++) {
        this.designGroupsArr.push({
          "id": result[i].id,
          "name": result[i].name,
          "storeyType": result[i].storeyType
        })
      }
      this.spinner.hide();
    });
  }

  submitDesignGroup() {
    var data = this.designGroupsForm.value;
    var cusr = [];
    if (this.DesignGroupsvalidate()) {
      if (data.storeyType.length != 0) {
        for (let i = 0; i < data.storeyType.length; i++) {
          cusr.push(data.storeyType[i].id.toString());
        }
        data.storeyType = cusr;
      } else {
        data.storeyType = cusr;
      }
      this.spinner.show();
      if (!data.id) {
        data.createdBy = this.userid;
        this.http.post('/api/designGroup', data).subscribe((response) => {
          this.res = response;
          if (this.res.success == true) {
            this.resetForm();
            this.toastr.clear();
            this.toastr.success(this.res.message, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });
            this.toggleDesignGroups();
            this.getDesignGropus();
            this.getAllGropDesign();
          } else {
            this.spinner.hide();
            this.toastr.clear();
            this.toastr.error(this.res.message, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });
          }
          this.spinner.hide();
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 2000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })
      } else {
        data.updatedBy = this.userid;
        this.http.put('/api/designGroup', data).subscribe((response) => {
          this.res = response;
          if (this.res.success == true) {
            this.resetForm();
            this.toastr.clear();
            this.toastr.success(this.res.message, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });
            this.toggleDesignGroups();
            this.getDesignGropus();
            this.getAllGropDesign();
          } else {
            this.spinner.hide();
            this.toastr.clear();
            this.toastr.error(this.res.message, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });
          }
          this.spinner.hide();
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 2000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })
      }
    }
  }
  designGroupEdit(id) {
    this.designGroupIdEdit = id;
    this.popoverform = true;
    this.designGroupsnameErr = null;
    this.designGroupspriceErr = null;
    this.designGroupsstoreyTypeErr = null;
    this.designGroupsDescNameErr = null;
    this.isCreate = false;
    this.projectTypeArrSelect = [];
    this.regionArrSelect = [];
    this.http.get('/api/designGroup/' + id).subscribe((res: Response) => {
      this.result3 = res;
      var result = this.result3;
      this.selectectArrayEvent(this.projectTypeArr, this.projectTypeArrSelect, result.storeyType);
      if (result) {
        this.designGroupsForm.patchValue({ 'id': result.id });
        this.designGroupsForm.patchValue({ 'name': result.name });
        this.designGroupsForm.patchValue({ 'defaultPrice': result.defaultPrice });
        this.designGroupsForm.patchValue({ 'description': result.description });
        this.designGroupsForm.patchValue({ 'createdDate': result.createdDate });
        this.designGroupsForm.patchValue({ 'createdBy': result.createdBy });
        this.designGroupsForm.patchValue({ 'updatedDate': result.updatedDate });
        this.designGroupsForm.patchValue({ 'updatedBy': result.updatedBy });
        this.designGroupsForm.patchValue({ 'isActive': result.isActive });
      }
    });
    this.menuDesginGroups = this.menuDesginGroups === 'out' ? 'in' : 'out';
  }
  designGroupDelete(id) {
    this.confirmService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) => {
        if (confirmed) {
          this.spinner.show();
          this.http.delete('/api/designGroup/' + id).subscribe((response) => {
            this.toastr.clear();
            this.result = response;
            if (this.result.success) {
              this.resetForm();
              this.toastr.success(this.result.message, 'Report Status', {
                timeOut: 2000,
                closeButton: true
              });
              this.closeDesignGroups();
              this.getDesignGropus();
              this.getAllGropDesign();
            } else {
              this.toastr.error(this.result.message, 'Report Status', {
                timeOut: 2000,
                closeButton: true
              });
            }
            this.spinner.hide();
          }, error => {
            this.toastr.error(error, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });
            this.spinner.hide();
            this.errorService.handleError(error);
          })
        }
        this.basicArr = [];
      })
      .catch(() => {
        return false;
      });
  }
  DesignGroupsvalidate() {
    this.designGroupsnameErr = null;
    this.designGroupspriceErr = null;
    this.designGroupsstoreyTypeErr = null;
    this.designGroupsDescNameErr = null;
    var data = this.designGroupsForm.value;
    var valid = true;
    if (data.name == undefined || data.name == '') {
      this.designGroupsnameErr = "DesignGroups Name is required.";
      valid = false;
    }
    else if (data.description == undefined || data.description == '') {
      this.designGroupsDescNameErr = "Description is required.";
      valid = false;
    }
    else if (data.storeyType == undefined || data.storeyType == '') {
      if (data.storeyType.length == 0) {
        this.designGroupsstoreyTypeErr = "StoreyType is required.";
        valid = false;
      }
    }
    // else if (data.defaultPrice == undefined || data.defaultPrice == '') {
    //   if(data.defaultPrice != 0){
    //       this.designGroupspriceErr = "Price is required.";
    //       valid = false;
    //   }
    // }else if (data.defaultPrice < 0) {
    //      this.designGroupspriceErr = "Count should be Minimum 0.";
    //       valid = false;
    // } 

    return valid;
  }


  //Group Properties//

  toggleGroupProperties() {
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.priceShow = false;
    this.isCreate = true;
    this.designGroupsnameErr = null;
    this.designGroupspriceErr = null;
    this.designGroupsstoreyTypeErr = null;
    this.designGroupsDescNameErr = null;
    this.propertiesdefaultOptionErr = null;
    this.propertiesnameErr = null;
    this.propertiespriceErr = null;
    this.propertiesDesNameErr = null;
    this.propertiesGropTypeErr = null;
    this.resetForm();
    this.menuGroupsProperties = this.menuGroupsProperties === 'out' ? 'in' : 'out';
  }
  closeGroupProperties() {
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.optinInputShow = false;
    this.isCreate1 = true;
    this.menuGroupsProperties = this.menuGroupsProperties === 'out' ? 'in' : 'out';
  }
  async getAllGropDesign() {
    this.spinner.show();
    this.GroupDesignArr = [];
    this.propertyTypeArr = [];
    var result = await this.DetailsService.getALLDesignGroups();
    this.result = result;
    this.result.forEach(r => {
      this.GroupDesign[r.id] = r;
      this.GroupDesignArr.push({
        "id": r.id, "itemName": r.name
      })
    });
    var result = await this.DetailsService.getpropertyType();
    this.result = result;
    this.result.forEach(r => {
      this.propertyType[r.id] = r;
      this.propertyTypeArr.push({
        "id": r.id, "itemName": r.name
      })
    });
    this.getGroupsProperties();

  }
  getGroupsProperties() {
    this.groupPropertiesArr = [];
    this.spinner.show();
    this.http.get('/api/groupProperties').subscribe((res: Response) => {
      this.result = res;
      for (let i = 0; i < this.result.length; i++) {
        this.groupPropertiesArr.push({
          "id": this.result[i].id,
          "name": this.result[i].name,
          "designGroupId": this.result[i].designGroupId,
          "propertyType": this.result[i].propertyTypeId,
          "designGroupName": this.result[i].designGroupName,
          "propertyTypeName": this.result[i].propertyTypeName,
          "groupPropertyCode": this.result[i].groupPropertyCode,
        })
      }
      this.spinner.hide();
    });
  }

  submitProperties() {
    var data = this.propertiesForm.value;
    if (this.propertiesvalidate()) {
      if (data.designGroupId.length != 0) {
        data.designGroupId = data.designGroupId[0].id;
      }
      if (data.propertyTypeId.length != 0) {
        data.propertyTypeId = data.propertyTypeId[0].id;
      }
      if (data.isDefaultIncluded == true) {
        data.isDefaultIncluded = "Y"
      } else {
        data.isDefaultIncluded = "N"
      }
      this.spinner.show();
      if (!data.id) {
        data.createdBy = this.userid;
        this.http.post('/api/groupProperty', data).subscribe((response) => {
          this.res = response;
          if (this.res.success == true) {
            this.resetForm();
            this.toastr.clear();
            this.toastr.success(this.res.message, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });
            this.toggleGroupProperties();
            this.getGroupsProperties();
          } else {
            this.spinner.hide();
            this.toastr.clear();
            this.toastr.error(this.res.message, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });
          }
          this.spinner.hide();
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 2000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })
      } else {
        data.updatedBy = this.userid;
        this.http.put('/api/groupProperty', data).subscribe((response) => {
          this.res = response;
          if (this.res.success == true) {
            this.resetForm();
            this.toastr.clear();
            this.toastr.success(this.res.message, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });
            this.toggleGroupProperties();
            this.getGroupsProperties();
          } else {
            this.spinner.hide();
            this.toastr.clear();
            this.toastr.error(this.res.message, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });
          }
          this.spinner.hide();
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 2000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })
      }
    }
  }
  propertiesEdit(id) {
    this.popoverform = true;
    this.propertiesEditId = id;
    this.optionAdd(id, 1)
    this.propertiesnameErr = null;
    this.propertiespriceErr = null;
    this.propertiesDesNameErr = null;
    this.propertiesGropTypeErr = null;
    this.isCreate = false;
    this.groupDesignArrSelect = [];
    this.propertyTypesArrSelect = [];
    this.http.get('/api/groupProperty/' + id).subscribe((res: Response) => {
      this.res = res;
      var result = this.res;
      this.propertyTypeCheck = result.propertyTypeId;
      this.selectectEvent(this.GroupDesignArr, this.groupDesignArrSelect, result.designGroupId);
      this.selectectEvent(this.propertyTypeArr, this.propertyTypesArrSelect, result.propertyTypeId);
      if (result.propertyTypeId == 1) {
        this.priceShow = true;
      } else {
        this.priceShow = false;
      }
      if (result) {
        if (result.isDefaultIncluded == "Y") {
          this.propertiesForm.patchValue({ 'isDefaultIncluded': true });
        } else {
          this.propertiesForm.patchValue({ 'isDefaultIncluded': false });
        }
        this.propertiesForm.patchValue({ 'id': result.id });
        this.propertiesForm.patchValue({ 'name': result.name });
        this.propertiesForm.patchValue({ 'defaultPrice': result.defaultPrice });
        this.propertiesForm.patchValue({ 'description': result.description });
        this.propertiesForm.patchValue({ 'createdDate': result.createdDate });
        this.propertiesForm.patchValue({ 'createdBy': result.createdBy });
        this.propertiesForm.patchValue({ 'updatedDate': result.updatedDate });
        this.designGroupsForm.patchValue({ 'updatedBy': result.updatedBy });
        this.propertiesForm.patchValue({ 'isActive': result.isActive });
      }
    });
    this.menuGroupsProperties = this.menuGroupsProperties === 'out' ? 'in' : 'out';
  }
  propertiespDelete(id) {
    this.confirmService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) => {
        if (confirmed) {
          this.spinner.show();
          this.http.delete('/api/groupProperty/' + id).subscribe((response) => {
            this.toastr.clear();
            this.result = response;
            if (this.result.success) {
              this.resetForm();
              this.toastr.success(this.result.message, 'Report Status', {
                timeOut: 2000,
                closeButton: true
              });
              this.closeGroupProperties();
              this.getGroupsProperties();
            } else {
              this.toastr.error(this.result.message, 'Report Status', {
                timeOut: 2000,
                closeButton: true
              });
            }
            this.spinner.hide();
          }, error => {
            this.toastr.error(error, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });
            this.spinner.hide();
            this.errorService.handleError(error);
          })
        }
      })
      .catch(() => {
        return false;
      });
  }
  propertiesvalidate() {
    this.propertiesnameErr = null;
    this.propertiespriceErr = null;
    this.propertiesDesNameErr = null;
    this.propertiesGropTypeErr = null;
    this.propertiesdefaultOptionErr = null;
    var data = this.propertiesForm.value;
    var valid = true;
    if (data.propertyTypeId.length != 0) {
      if (data.propertyTypeId[0].id == 1) {
        this.priceShow = true;
      } else {
        this.propertiesForm.patchValue({ 'defaultPrice': 0 });
        this.priceShow = false;
      }
    }
    if (data.propertyTypeId == undefined || data.propertyTypeId == '') {
      if (data.propertyTypeId.length == 0) {
        this.propertiesdefaultOptionErr = "PropertyType is required.";
        valid = false;
      }
    } else if (data.name == undefined || data.name == '') {
      this.propertiesnameErr = "Property Name is required.";
      valid = false;
    }
    else if (data.description == undefined || data.description == '') {
      this.propertiesDesNameErr = "Description is required.";
      valid = false;
    }
    else if (data.designGroupId == undefined || data.designGroupId == '') {
      if (data.designGroupId.length == 0) {
        this.propertiesGropTypeErr = "DesignGroup is required.";
        valid = false;
      }
    }
    else if (data.propertyTypeId[0].id == 1) {
      if (data.defaultPrice == undefined || data.defaultPrice == '') {
        this.propertiespriceErr = "Price is required.";
        valid = false;
        if (data.defaultPrice == 0) {
          this.propertiesForm.patchValue({ 'defaultPrice': 0 });
          this.propertiespriceErr = null;
          valid = true;
        }
      } else if (data.defaultPrice < 0) {
        this.propertiespriceErr = "Count should be Minimum 0.";
        valid = false;
      }
    }

    return valid;
  }



  // OptionAdd //

  optionAdd(id, num) {
    //  this.optinInputShow = false;
    this.spinner.show();
    this.optionID = id;
    this.addOptionnameErr = null;
    this.addOptionDesNameErr = null;
    this.addOptionpriceErr = null;
    this.optionArr = [];
    //  this.optinInputShow = false;
    this.http.get('/api/propertyOption/property/' + id).subscribe((res: Response) => {
      this.result = res;
      // if(this.result.length == 0){
      //   this.optinInputShow = true;
      // }
      for (let i = 0; i < this.result.length; i++) {
        this.optionArr.push({
          "id": this.result[i].id,
          "name": this.result[i].name,
          "description": this.result[i].description,
          "defaultPrice": this.result[i].defaultPrice,
        })
      }
      this.spinner.hide();
    });

    if (num == 0) {
      if (this.popoverform1 == true) {
        this.popoverform1 = false;
      } else {
        this.popoverform1 = true;
      }
      this.menuAddOption = this.menuAddOption === 'out' ? 'in' : 'out';
    }

  }
  closeoptionAdd(num) {
    if (num == 0) {
      this.isCreate1 = true;
      this.optinInputShow = false;
      // this.menuGroupsProperties = this.menuGroupsProperties === 'out' ? 'in' : 'out';
      // this.menuAddOption = this.menuAddOption === 'out' ? 'in' : 'out';
      // this.popoverform1 = false;
      // this.popoverform = false;      
    } else {
      this.isCreate1 = true;
      this.optinInputShow = false;
      // this.menuAddOption = this.menuAddOption === 'out' ? 'in' : 'out';
    }

  }
  addOptionNew() {
    this.resetForm();
    this.isCreate1 = false;
    this.optinInputShow = !this.optinInputShow;
  }
  submitOption() {
    var data = this.addOptionForm.value;
    if (this.optionvalidate()) {
      this.spinner.show();
      if (!data.id) {
        data.createdBy = this.userid;
        data.propertyId = this.optionID;
        this.http.post('/api/propertyOption', data).subscribe((response) => {
          this.res = response;
          if (this.res.success == true) {
            // this.optinInputShow = false;
            this.optionAdd(this.optionID, 1);
            this.resetForm();
            this.toastr.clear();
            this.toastr.success(this.res.message, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });
          } else {
            this.spinner.hide();
            this.toastr.clear();
            this.toastr.error(this.res.message, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });
          }
          this.spinner.hide();
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 2000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })
      } else {
        data.updatedBy = this.userid;
        this.http.put('/api/propertyOption', data).subscribe((response) => {
          this.res = response;
          if (this.res.success == true) {
            // this.optinInputShow = false;
            this.optionAdd(this.optionID, 1);
            this.resetForm();
            this.toastr.clear();
            this.toastr.success(this.res.message, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });
          } else {
            this.spinner.hide();
            this.toastr.clear();
            this.toastr.error(this.res.message, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });
          }
          this.spinner.hide();
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 2000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })
      }
    }
  }
  addOptionEdit(id) {
    this.addOptionnameErr = null;
    this.addOptionDesNameErr = null;
    this.addOptionpriceErr = null;
    this.isCreate1 = true;
    this.optinInputShow = true;
    this.addOptionEditId = id;
    this.http.get('/api/propertyOption/' + id).subscribe((res: Response) => {
      this.result4 = res;
      if (this.result4) {
        this.addOptionForm.patchValue({ 'id': this.result4.id });
        this.addOptionForm.patchValue({ 'name': this.result4.name });
        this.addOptionForm.patchValue({ 'defaultPrice': this.result4.defaultPrice });
        this.addOptionForm.patchValue({ 'description': this.result4.description });
        this.addOptionForm.patchValue({ 'createdDate': this.result4.createdDate });
        this.addOptionForm.patchValue({ 'createdBy': this.result4.createdBy });
        this.addOptionForm.patchValue({ 'updatedDate': this.result4.updatedDate });
        this.addOptionForm.patchValue({ 'updatedBy': this.result4.updatedBy });
        this.addOptionForm.patchValue({ 'isActive': this.result4.isActive });
        this.addOptionForm.patchValue({ 'propertyId': this.result4.propertyId });
      }
    });
  }
  addOptionpDelete(id) {
    // this.closeoptionAdd(1);
    this.confirmService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) => {
        if (confirmed) {
          this.spinner.show();
          this.http.delete('/api/propertyOption/' + id).subscribe((response) => {
            this.toastr.clear();
            this.result = response;
            if (this.result.success) {
              this.resetForm();
              this.toastr.success(this.result.message, 'Report Status', {
                timeOut: 2000,
                closeButton: true
              });
              this.optionAdd(this.optionID, 1);
            } else {
              this.toastr.error(this.result.message, 'Report Status', {
                timeOut: 2000,
                closeButton: true
              });
            }
            this.spinner.hide();
          }, error => {
            this.toastr.error(error, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });
            this.spinner.hide();
            this.errorService.handleError(error);
          })
        }
      })
      .catch(() => {
        this.closeoptionAdd(1);
        return false;
      });
  }
  optionvalidate() {
    this.addOptionnameErr = null;
    this.addOptionDesNameErr = null;
    this.addOptionpriceErr = null;
    var data = this.addOptionForm.value;
    var valid = true;
    if (data.name == undefined || data.name == '') {
      this.addOptionnameErr = "Option Name is required.";
      valid = false;
    }
    else if (data.description == undefined || data.description == '') {
      this.addOptionDesNameErr = "Description is required.";
      valid = false;
    }
    else if (data.defaultPrice == undefined || data.defaultPrice == '') {
      this.addOptionpriceErr = "Price is required.";
      valid = false;
      //  if(data.defaultPrice == 0){
      //    this.addOptionForm.patchValue({ 'defaultPrice': 0 });
      //    this.addOptionpriceErr = null;
      //    valid = true;
      //  }
    }

    return valid;
  }
  getFilterGroupProperities(value) {
    if (value != null) {
      this.groupPropertiesArr = [];
      this.spinner.show();
      this.http.get('/api/groupProperty/group/' + value.id).subscribe((res: Response) => {
        this.result = res;
        for (let i = 0; i < this.result.length; i++) {
          this.groupPropertiesArr.push({
            "id": this.result[i].id,
            "name": this.result[i].name,
            "designGroupId": this.result[i].designGroupId,
            "propertyType": this.result[i].propertyTypeId,
            "designGroupName": this.result[i].designGroupName,
            "propertyTypeName": this.result[i].propertyTypeName,
            "groupPropertyCode": this.result[i].groupPropertyCode,
          })
        }
        this.spinner.hide();
      });
    } else {
      this.getGroupsProperties();
    }
  }
  copyText(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success("Copied Successfully", 'Success', {
      timeOut: 1000,
      closeButton: true
    });
  }
  getPropertyCode() {
    this.spinner.show();
    this.estimationDocumentArr = [];
    this.customerCodesArr = [];
    this.groupPropertiesCodeArr = [];
    this.http.get('/api/estimationDocument/codes').subscribe((res: Response) => {
      this.result = res;
      var defaultCodes = this.result.defaultCodes;
      this.customerCodesArr = defaultCodes;
      for (let i = 0; i < this.result.AllGroups.length; i++) {
        var groupProperties = this.result.AllGroups[i].groupPropertyList;
        this.estimationDocumentArr.push({
          "groupName": this.result.AllGroups[i].groupName,
          "groupCode": this.result.AllGroups[i].groupCode
        });
        this.groupPropertiesCodeArr.push({
          "propertyName": groupProperties
        })
      }
      this.spinner.hide();
    });
  }
  estimationCodeOpen(i) {
    if (this.editSh == true) {
      this.editSh = false;
    } else {
      this.editSh = true;
    }
    if (i != null) {
      var result = this.groupPropertiesCodeArr[i];
      this.boxInedx = i;
      this.groupPropertyListArr = result.propertyName;
    } else {

    }

  }
  getFileDetails(e) {
    this.myFiles = [];
    this.filesUploadArr = [];
    for (var i = 0; i < e.target.files.length; i++) {
      this.myFiles.push(e.target.files[i]);
      this.filesUploadArr.push({
        "name": e.target.files[i].name
      });
    }
  }
  postMethod() {
    if (this.clearFile()) {
      this.spinner.show();
      const frmData = new FormData();
      for (var i = 0; i < this.myFiles.length; i++) {
        frmData.append("file", this.myFiles[i]);
      }
      frmData.append("description", "");
      this.http.post('/api/upload/DocumentTemplate', frmData).subscribe((response) => {
        this.res = response;
        if (this.res.success == true) {
          this.toastr.clear();
          this.toastr.success(this.res.message, 'Success', {
            timeOut: 2000,
            closeButton: true
          });
          this.filesResetForm();
          this.myFiles = [];
          this.filesUploadArr = [];
          this.spinner.hide();
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 2000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
    }
  }
  clearFile() {
    this.fileErr = null;
    var valid = true;
    var _validFileExtensions = [".docx"];
    let data = this.uploadFileForm.value;
    if (!data.file) {
      this.fileErr = "Upload File is Required";
      valid = false;
    } else if (data.file) {
      var sFileName = data.file;
      if (sFileName.length != 0) {
        var blnValid = false;
        for (var j = 0; j < _validFileExtensions.length; j++) {
          var sCurExtension = _validFileExtensions[j];
          if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
            blnValid = true;
            break;
          }
        }
        if (!blnValid) {
          this.fileErr = "Word document(.docx) file only accepted..!";
          valid = false;
        }
      }
    }
    return valid;
  }
  filesResetForm() {
    this.myFiles = [];
    this.filesUploadArr = [];
    this.uploadFileForm.reset({
      file: '',
      description: ''
    });
  }
  DocumentTemplateDownLoad() {
    this.loggedInUserDomain = localStorage.getItem('loggedInDomainID');
    const url = '/unsecure/download/DocumentTemplate/' + this.loggedInUserDomain;
    window.open(url, "_self");
  }
}
import { Component, Input, Output, OnInit, OnChanges, EventEmitter } from '@angular/core';
import { DetailsService } from '../../_services/index';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-propsave',
  templateUrl: './propsave.component.html',
  styleUrls: ['./propsave.component.css']
})
export class PropsaveComponent implements OnInit {

  @Input('propSaveChild') propSaveChild;
  @Output() propTotalChangeEvent = new EventEmitter();

  currentUserId:any;
  totalunit:number;
  unitprice:number;
  optionDescription:any;
  dataSave:any;
  isLockeCheck :any;
  response :any;


  constructor(
    private DetailsService: DetailsService,
    private toastr: ToastrService,) {
    this.currentUserId = localStorage.getItem("loggedInUserID");
    this.totalunit=0;
    this.unitprice=0;
    this.optionDescription='';
    this.dataSave="save";
  }

  ngOnInit() {
  }
  ngOnChanges(){ 
    this.totalunit=this.propSaveChild[0].estimationProperty.areaPrice;
    this.unitprice=this.propSaveChild[0].estimationProperty.unitPrice;
    var optionId_saved=this.propSaveChild[0].estimationProperty.optionId;
    var optionCollection=this.propSaveChild[0].options;
    this.isLockeCheck =  this.propSaveChild[0].isLocked;
    if(optionId_saved !=null){
      for (let i = 0; i < optionCollection.length; i++) {
        if(optionCollection[i].id === optionId_saved ){
          this.optionDescription=optionCollection[i].description;
        }
      }
    }else{
      this.optionDescription='';
    }
  }

  //update array for option selection
  basicOptSelection(event:any,propChange,updateProp){
    this.optionDescription='';
    updateProp.estimationProperty.optionId=propChange.id;
    updateProp.estimationProperty.optionValue=propChange.defaultPrice;
    updateProp.estimationProperty.totalPrice=propChange.defaultPrice;
    this.optionDescription=propChange.description;
  }

  //update call for option selection store
  async update_property(propSave,propmethod){
    var propmethod=propmethod;
    var optionId_select;
    var optionValue;
    var totalPrice,unitpriceVal,totalunitVal;
    this.dataSave="Processing...";

    if(propmethod=='no_option'){
      optionId_select = null;
      optionValue=0;
      totalPrice=propSave.estimationProperty.totalPrice;
      unitpriceVal=0;
      totalunitVal=0;
    }else if(propmethod=='with_option'){
      optionId_select=propSave.estimationProperty.optionId;
      optionValue=propSave.estimationProperty.optionValue;
      totalPrice=propSave.estimationProperty.optionValue;
      unitpriceVal=0;
      totalunitVal=0;
    }else if(propmethod='with_calc'){
      optionId_select=null;
      optionValue=0;
      if(!this.unitprice){
        this.unitprice=0;
      }
      if(!this.totalunit){
        this.totalunit=0;
      }
      unitpriceVal=this.unitprice;
      totalunitVal=this.totalunit;
      totalPrice=unitpriceVal*totalunitVal;
      propSave.estimationProperty.unitPrice=this.unitprice;
      propSave.estimationProperty.areaPrice=this.totalunit;
      propSave.estimationProperty.totalPrice=totalPrice;
    }
    var data={
        "id": propSave.estimationProperty.id,
        "estimationId": propSave.estimationProperty.estimationId,
        "designGroupId": propSave.estimationProperty.designGroupId,
        "propertyId": propSave.estimationProperty.propertyId,
        "isPropertyIncluded": propSave.estimationProperty.isPropertyIncluded,
        "propertyName": propSave.estimationProperty.propertyName,
        "isInclude": propSave.estimationProperty.isInclude,
        "includeValue": propSave.estimationProperty.includeValue,
        "isOption": propSave.estimationProperty.isOption,
        "optionId": optionId_select,
        "optionValue":optionValue,
        "isPriced": propSave.estimationProperty.isPriced,
        "unitPrice": unitpriceVal,
        "areaPrice": totalunitVal,
        "totalPrice": totalPrice,
        "isActive": propSave.estimationProperty.isActive,
        "createdDate": propSave.estimationProperty.createdDate,
        "createdBy": propSave.estimationProperty.createdBy,
        "updatedBy": this.currentUserId,
        "estimationVersionId": propSave.estimationProperty.estimationVersionId
    }
    //console.log(data);
    this.response = await this.DetailsService.updateEstimationProp(data);
    if (this.response.success == true) {
      this.toastr.clear();
      this.toastr.success('Property Updated', 'Success', {
        timeOut: 6000,
        closeButton: true
      });
    } else {
       this.toastr.clear();
       this.toastr.error(this.response.message, 'Error', {
         timeOut: 6000,
         closeButton: true
       });
    }
    this.dataSave="save";
    this.propTotalChangeEvent.emit(propSave.estimationProperty.estimationVersionId);
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { NgForm, EmailValidator } from '@angular/forms';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { ErrorService } from 'src/app/_services/error.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, animate, transition, style } from '@angular/animations';
import * as moment from 'moment/moment.js';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '25rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    // uploadUrl: 'v1/images', // if needed
    customClasses: [ // optional
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  profileForm: FormGroup;
  passwordForm: FormGroup;
  mailSignatureForm: FormGroup;
  domainForm: FormGroup;
  form: FormGroup;
  loggedInUserID: any;
  userDetails: any;
  profile_pc_view: boolean = false;
  edit_show: boolean = false;
  editPhone_show: boolean = false;
  profile_upload: boolean = false;
  filesToUpload: Array<File> = [];
  userId: any;
  profileDetails: any;
  profileDetailsArr: any;
  profileDetailsArr1: any;
  result: any;
  nameErr: any;
  emailErr: any;
  phoneErr: any;
  loggedEntityTypeId: any;
  proPicId: any;
  profilePicView: any;
  ProjectName: string;
  username: any;
  step: any;
  tab01: boolean = false;
  tab02: boolean = false;
  tab03: boolean = false;
  tab04: boolean = false;
  tab05: boolean = false;
  resetpasswordErr: any;
  confirmpasswordErr: any;
  domainListArr: any;
  loginEmail: any;
  fromEmailIdArr: any;
  isdismail: boolean = false;
  syncheck: boolean = false;
  PreferenceArr = [];
  data1: any;
  domainId: any;
  resultDomain: any;
  domainnameErr: any;
  domainPhoneErr: any;
  userResult: any;




  constructor(private http: HttpClient, private router: Router,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService, private toastr: ToastrService,
    private errorService: ErrorService,
    private confirmService: ConfirmService,
    private modalService: NgbModal,private route: ActivatedRoute) { }

  ngOnInit() {
    this.spinner.show();
    this.createForm();
    var component = this.router.url;
    if(component == "/profile/mailsync"){
      this.tabs('configuration');
    }else{
      this.tabs('personal');
    }
    this.loggedInUserID = localStorage.getItem('loggedInUserID');
    this.loggedEntityTypeId = localStorage.getItem('loggedInUserType');
    this.username = localStorage.getItem("loggedInUser");
    this.proPicId = localStorage.getItem("loggedInUserproPicId");
    this.ProjectName = localStorage.getItem("ProjectName");


    if (this.loggedEntityTypeId == 2) {
      this.getCustomer();
    }
    this.getUserProfile();
    this.getPreference();
    this.getFromEmail();
    this.domainListArr = [
      { "id": 1, "itemName": "Domain 1" },
      { "id": 2, "itemName": "Domain 2" },
      { "id": 3, "itemName": "Domain 3" }
    ];
  }
  createForm() {
    this.form = this.formBuilder.group({
      id: '',
      domainId: '',
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: '',
      addressId: null,
      phone: '',
      createdBy: '',
      isActive: '',
      updatedBy: '',
      unit: '',
      entityTypeId: '',
      address1: '',
      address2: '',
      city: 'Sydney',
      state: 'New South Wales',
      country: 'Australia',
      postalCode: '',
      role: '',
      isAdmin: '',
      gender: '',
      createdDate: '',
      orgId :''
    })
    this.domainForm = this.formBuilder.group({
      id: '',
      name: '',
      address: '',
      cin: '',
      gstin: '',
      phone: '',
      email: '',
      uniqueCode: '',
      createdAt: '',
      isActive :'',
      isVerified :''
    });
    this.profileForm = this.formBuilder.group({
      file: '',
    });
    this.passwordForm = this.formBuilder.group({
      password: '',
      confirmPassword: ''
    });
    this.mailSignatureForm = this.formBuilder.group({
      mailSignature: ''
    })

  }
  resetForm() {
    this.passwordForm.reset({
      password: '',
      confirmPassword: ''
    })
  }

  getCustomer() {
    this.spinner.show();
    this.http.get('/api/customer/' + this.loggedInUserID).subscribe((res: Response) => {
      this.result = res;
      this.userDetails = this.result;
      this.spinner.hide();
    });
  }
  edit_show_click(id, num) {
    if (num == 0) {
      var data = id;
      this.edit_show = !this.edit_show;
      this.form.patchValue({ 'id': data.details.id });
      this.form.patchValue({ 'firstName': data.details.firstName });
      this.form.patchValue({ 'lastName': data.details.lastName });
      this.form.patchValue({ 'phone': data.details.phone });
      this.form.patchValue({ 'email': data.details.email });
      this.form.patchValue({ 'addressId': data.details.addressId });
      this.form.patchValue({ 'unit': data.location.unit });
      this.form.patchValue({ 'address1': data.location.address1 });
      this.form.patchValue({ 'address2': data.location.address2 });
      this.form.patchValue({ 'city': data.location.city });
      this.form.patchValue({ 'state': data.location.state });
      this.form.patchValue({ 'country': data.location.country });
      this.form.patchValue({ 'isActive': data.details.isActive });
      this.form.patchValue({ 'postalCode': data.location.postalCode });
      this.form.patchValue({ 'createdBy': data.details.createdBy });
      this.form.patchValue({ 'entityTypeId': data.details.entityTypeId });
      this.form.patchValue({ 'role': data.details.role });
      this.form.patchValue({ 'isAdmin': data.details.isAdmin });
      this.form.patchValue({ 'gender': data.details.gender });
      this.form.patchValue({ 'createdDate': data.details.createdDate });
      this.form.patchValue({ 'domainId': data.details.domainId });
      this.form.patchValue({ 'orgId': data.details.orgId });
    } else {
      this.edit_show = !this.edit_show;
    }

  }
  cancel_show_click() {
    this.nameErr = null;
    this.emailErr = null;
    this.phoneErr = null;
    this.domainnameErr = null;
    this.domainPhoneErr = null;
    this.edit_show = false;

  }
  getAttachFile(files: any) {
    this.filesToUpload = <Array<File>>files.target.files;
    this.postAttachment();
  }
  postAttachment() {
    var files: Array<File> = this.filesToUpload;
    for (let i = 0; i < files.length; i++) {
      this.spinner.show();
      var formData = new FormData();
      formData.append("file", files[i], files[i].name);
      formData.append("createdBy", this.loggedInUserID);
      formData.append("description", "");
      this.http.post('/api/upload/profile/' + this.loggedInUserID + "/" + this.loggedInUserID, formData).subscribe((res: Response) => {
        this.result = res;
        if (this.result.successArr.length > 0) {
          localStorage.setItem("loggedInUserproPicId", this.result.successArr[0].response.id);
          this.proPicId = this.result.successArr[0].response.id;
          this.getUserProfile();
          this.toastr.success('Profile Updated successfully', 'Report Status', {
            timeOut: 2000,
            closeButton: true
          });
          var component = this.router.url;
          this.router.navigateByUrl('/headercomponent', { skipLocationChange: true }).then(() => {
            this.router.navigate([component]);
          });
          this.spinner.hide();
        } else if (this.result.failureArr.length > 0) {
          this.toastr.error(this.result.successArr[0].message, 'Report Status', {
            timeOut: 2000,
            closeButton: true
          });
          this.spinner.hide();
        }
      });
    }
  }


  updateCust(data) {
    if (data.details.entityTypeId != 2) {
      if (this.validate()) {
        var data1 = this.form.value;
        data1.updatedBy = parseInt(this.loggedInUserID);

        data1.password = "";
        this.spinner.show();
        if (this.loggedEntityTypeId == 3) {
          var url = '/api/certifier/'
        } else if (this.loggedEntityTypeId == 4 || this.loggedEntityTypeId == 5) {
          var url = '/api/externalUser/'
        } else {
          var url = '/api/user/' + data1.id
        }
        this.http.put(url, data1).subscribe((res: Response) => {
          this.toastr.clear();
          this.result = res;
          if (this.result.success == true) {
            this.toastr.success(this.result.message, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });
            localStorage.setItem("loggedInUser", this.result.response.firstName + " " + this.result.response.lastName);
            this.username = this.result.response.firstName + " " + this.result.response.lastName;
            this.getUserProfile();
            this.cancel_show_click();
            var component = this.router.url;
            this.router.navigateByUrl('/headercomponent', { skipLocationChange: true }).then(() => {
              this.router.navigate([component]);
            });
          } else {
            this.toastr.error(this.result.message, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });

            this.spinner.hide();
          }
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 2000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })
      }
    } else {
      if (this.validate()) {
        var data1 = this.form.value;
        data1.updatedBy = parseInt(this.loggedInUserID);
        this.spinner.show();
        this.http.put('/api/customer/' + data1.id, data1).subscribe((res: Response) => {
          this.toastr.clear();
          this.result = res;
          if (this.result.success) {
            this.edit_show = !this.edit_show;
            this.toastr.success(this.result.message, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });
            this.getCustomer();
            var component = this.router.url;
            this.router.navigateByUrl('/headercomponent', { skipLocationChange: true }).then(() => {
              this.router.navigate([component]);
            });
          } else {
            this.toastr.error(this.result.message, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });
            this.spinner.hide();
          }
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 2000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })
      }
    }
  }
  validate() {
    this.nameErr = null;
    this.emailErr = null;
    var data = this.form.value;
    var valid = true;
    this.phoneErr = null;
    const validNumber = /^\d+$/;
    const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!data.firstName) {
      this.nameErr = "FirstName is required.";
      valid = false;
    }
    if (data.phone) {
      if (data.phone != '' && !validNumber.test(data.phone)) {
        this.phoneErr = "Invalid Phone Number.";
        valid = false;
      }
    }
    return valid;

  }
  getUserProfile() {
    if (this.proPicId == "null") {
      this.profile_pc_view = false;
    } else {
      this.profile_pc_view = true;
      this.profilePicView = '/unsecure/gallery/' + this.proPicId;
    }
    this.spinner.show();
    this.http.get('/api/user/' + this.loggedInUserID).subscribe((res: Response) => {
      this.result = res;
      this.userDetails = this.result;
      localStorage.setItem("loggedInUser", this.userDetails.details.firstName + " " + this.userDetails.details.lastName);
      this.spinner.hide();
    });
  }
  tabs = (tabValue) => {
    this.tab01 = false;
    this.tab02 = false;
    this.tab03 = false;
    this.tab04 = false;
    this.tab05 = false;
    switch (tabValue) {
      case 'personal':
        this.step = 'step1';
        this.tab01 = true;        
        break;
      case 'domain':
        this.step = 'step2';
        this.domainnameErr = null;
        this.domainPhoneErr = null;        
        this.tab05 = true;
        this.domainCall();
        break;
      case 'configuration':
        this.step = 'step3';       
        this.tab02 = true;        
        break
      case 'email':
        this.step = 'step4';
        this.tab04 = true;
        break;
      case 'password':
        this.step = 'step5';
        this.confirmpasswordErr = null;
        this.resetpasswordErr = null;        
        this.tab03 = true;
        break;        
    }
  }
   
  onchangtab(data) {

  }
  passwordValidate() {
    this.resetpasswordErr = null;
    this.confirmpasswordErr = null;
    var data = this.passwordForm.value;
    const strongRegex = /(?=.{6,})/;
    var valid = true;
    if (data.password == Validators || data.password == '') {
      this.resetpasswordErr = "Password is required.";
      valid = false;
    } else if (!strongRegex.test(data.password)) {
      this.resetpasswordErr = "Password is Must be 6 Charcters.";
      valid = false;
    } else if (data.confirmPassword == Validators || data.confirmPassword == '') {
      this.confirmpasswordErr = "ConfirmPassword is required.";
      valid = false;
    } else if (data.password != data.confirmPassword) {
      this.confirmpasswordErr = "Password doesn't Match.";
      valid = false;
    }
    return valid;
  }
  passwordPost() {
    if (this.passwordValidate()) {
      this.spinner.show();
      // this.http.get('/api/user/' + this.loggedInUserID).subscribe((res: Response) => {
      //   this.result = res;
      //   var data = this.result.details;
      //   var data1 = this.passwordForm.value;
      //   data.password = data1.confirmPassword;
      //   data.updatedBy = parseInt(this.loggedInUserID);
      //   if (this.loggedEntityTypeId == 3) {
      //     var url = '/api/certifier/'
      //   } else {
      //     var url = '/api/user/' + this.loggedInUserID
      //   }
      //   this.http.put(url, data).subscribe((response) => {
      //     this.resetForm();
      //     this.toastr.clear();
      //     this.toastr.success('Password Updated successfully', 'Report Status', {
      //       timeOut: 2000,
      //       closeButton: true
      //     });
      //     this.result = response;
      //   })
      //   this.spinner.hide();
      // })
      var data = this.passwordForm.value;
      data.password = data.confirmPassword;
      data.userId = parseInt(this.loggedInUserID);
      data.updateById = parseInt(this.loggedInUserID);
      var url = '/api/allUser/resetPassword'
      this.http.post(url, data).subscribe((response) => {
        this.result = response;
        if (this.result.success) {
          this.resetForm();
          this.toastr.clear();
          this.toastr.success('Password Updated successfully', 'Report Status', {
            timeOut: 2000,
            closeButton: true
          });
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }

      })

    }
  }
  getPreference() {
    this.http.get('/api/userPreferences/' + this.loggedInUserID).subscribe((res: Response) => {
      this.result = res;
      var result = this.result;
      this.PreferenceArr = [];
      if (result.length != 0) {
        if (result[0].isAccountAdded == "Y") {
          this.syncheck = true;
          this.isdismail = true;
        } else {
          this.isdismail = false;
          this.syncheck = false;
        }
        this.mailSignatureForm.patchValue({ 'mailSignature': result[0].mailSignature });
        this.PreferenceArr.push({
          "id": result[0].id, "isAccountAdded": result[0].isAccountAdded, "isCalenderSync": result[0].isCalenderSync, "isContactsSync": result[0].isContactsSync, "isMailSync": result[0].isMailSync
        })
      }
    })
  }
  check(number) {
    this.spinner.show();
    this.http.get('/api/userPreferences/' + this.loggedInUserID).subscribe((res: Response) => {
      this.data1 = res;
      var data = this.data1[0];
      if (number == 1) {
        if (data.isAccountAdded == "Y") {
          data.isAccountAdded = "N";
          data.isMailSync = "N";
          data.isCalenderSync = "N";
          data.isContactsSync = "N";
        }
        this.http.put('/api/userPreferences/', data).subscribe((response) => {
          this.toastr.clear();
          this.result = response;
          if (this.result.success) {
            this.resetForm();
            this.toastr.success(this.result.message, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });
            this.getPreference();
            this.PreferenceArr = []
          } else {

            this.toastr.error(this.result.message, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });
          }
          this.spinner.hide();

        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 2000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })
      }
      if (number == 2) {
        if (data.isMailSync == "Y") {
          data.isMailSync = "N";
        } else {
          data.isMailSync = "Y";
        }
        this.http.put('/api/userPreferences/', data).subscribe((response) => {
          this.toastr.clear();
          this.result = response;
          if (this.result.success) {
            this.spinner.hide();
            this.toastr.success(this.result.message, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });
          } else {
            this.toastr.error(this.result.message, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });
          }
          this.spinner.hide();
        }
          , error => {
            this.toastr.error(error, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });
            this.spinner.hide();
            this.errorService.handleError(error);
          })
      } else if (number == 3) {
        if (data.isCalenderSync == "Y") {
          data.isCalenderSync = "N";
        } else {
          data.isCalenderSync = "Y";
        }
        this.http.put('/api/userPreferences/', data).subscribe((response) => {

          this.toastr.clear();
          this.result = response;
          if (this.result.success) {
            this.spinner.hide();
            this.toastr.success(this.result.message, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });

          } else {

            this.toastr.error(this.result.message, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });
          }
          this.spinner.hide();

        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 2000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })
      }
    })
  }
  getEmailSync() {
    this.confirmService.confirm(' Add Google Account..', 'Do you want Add your Account?')
      .then((confirmed) => {
        if (confirmed) {
          this.http.get('/api/mail/gsync/' + this.loggedInUserID).subscribe((res: Response) => {
            this.result = res;
            if (this.result) {
              window.open(this.result.token, "_self");
            }
          })
        }
      })
      .catch(() => {
        return false;
      });
  }
  switchuser() {
    this.confirmService.confirm(' Remove Account ..', 'Do you want remove account ?')
      .then((confirmed) => {
        if (confirmed) {
          this.spinner.show();
          this.check(1);
          this.syncheck = false;
        }
        this.spinner.hide();
      })
      .catch(() => {
        return false;
      });
  }
  getFromEmail() {
    this.fromEmailIdArr = [];
    this.http.get('/api/mail/' + this.loggedInUserID).subscribe((res: Response) => {
      this.result = res;
      var result = this.result;
      if (result.length != 0) {
        this.fromEmailIdArr.push({
          "fromId": result[0].email,
        })
      }
    })
  }
  singNaturePost() {
    this.http.get('/api/userPreferences/' + this.loggedInUserID).subscribe((res: Response) => {
      this.data1 = res;
      var data = this.data1[0];
      var data2 = this.mailSignatureForm.value;
      data.mailSignature = data2.mailSignature;
      this.spinner.show();
      this.http.put('/api/userPreferences/', data).subscribe((response) => {
        this.toastr.clear();
        this.result = response;
        if (this.result.success) {
          this.spinner.hide();
          this.toastr.success("Signature Updated Successfully", 'Report Status', {
            timeOut: 2000,
            closeButton: true
          });
        } else {
          this.toastr.error(this.result.message, 'Report Status', {
            timeOut: 2000,
            closeButton: true
          });
        }
        this.spinner.hide();
      }, error => {
        this.toastr.error(error, 'Report Status', {
          timeOut: 2000,
          closeButton: true
        });
        this.spinner.hide();
        this.errorService.handleError(error);
      })
    });

  }
  singNatureClear() {
    this.mailSignatureForm.reset({
      mailSignature: ''
    })
  }

  domainCall() {
    this.domainId = localStorage.getItem("loggedInDomainID");
    this.http.get('/api/domain/' + this.domainId).subscribe((response) => {
      this.resultDomain = response;
      this.domainForm.patchValue({ 'id': this.resultDomain.id });
      this.domainForm.patchValue({ 'name': this.resultDomain.name });
      this.domainForm.patchValue({ 'cin': this.resultDomain.cin });
      this.domainForm.patchValue({ 'gstin': this.resultDomain.gstin });
      this.domainForm.patchValue({ 'email': this.resultDomain.email });
      this.domainForm.patchValue({ 'uniqueCode': this.resultDomain.uniqueCode });
      this.domainForm.patchValue({ 'phone': this.resultDomain.phone });
      this.domainForm.patchValue({ 'createdAt': this.resultDomain.createdAt });
      this.domainForm.patchValue({ 'address': this.resultDomain.address });
      this.domainForm.patchValue({ 'isActive': this.resultDomain.isActive });
      this.domainForm.patchValue({ 'isVerified': this.resultDomain.isVerified });
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  updateDomain() {
    if (this.validateDomain()) {
      this.spinner.show();
      var data = this.domainForm.value;
      data.updatedBy = parseInt(this.loggedInUserID);
      this.spinner.show();
      this.http.put('/api/domain/', data).subscribe((res: Response) => {
        this.result = res;
        if (this.result.success) {
          localStorage.setItem("domainName", data.name);
          this.toastr.success(this.result.message, 'Report Status', {
            timeOut: 2000,
            closeButton: true
          });
          this.domainCall();
          var component = this.router.url;
          this.router.navigateByUrl('/headercomponent', { skipLocationChange: true }).then(() => {
            this.router.navigate([component]);
          });
          this.cancel_show_click();
          this.spinner.hide();
        } else {
          this.toastr.error(this.result.message, 'Report Status', {
            timeOut: 2000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.error(error, 'Report Status', {
          timeOut: 2000,
          closeButton: true
        });
        this.spinner.hide();
        this.errorService.handleError(error);
      })
    }
  }
  validateDomain() {
    this.domainnameErr = null;
    this.domainPhoneErr = null;
    var data = this.domainForm.value;
    var valid = true;
    this.phoneErr = null;
    const validNumber = /^\d+$/;
    const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!data.name) {
      this.domainnameErr = "Domain Name is required.";
      valid = false;
    }
    if (data.phone) {
      if (data.phone != '' && !validNumber.test(data.phone)) {
        this.domainPhoneErr = "Invalid Phone Number.";
        valid = false;
      }
    }
    return valid;
  }
  deletePic(id) {
    this.confirmService.confirm('Please confirm..', 'Do you really want to delete ?')
    .then((confirmed) => {
      if (confirmed) {
        this.spinner.show();
        this.http.delete('/api/files/profilePic/' + id).subscribe((res: Response) => {
          this.userResult = res;
          if (this.userResult.success == true) {
            this.toastr.success(this.userResult.message, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });
            localStorage.setItem("loggedInUserproPicId", null);
            var component = this.router.url;
            this.router.navigateByUrl('/headercomponent', { skipLocationChange: true }).then(() => {
              this.router.navigate([component]);
            });
          }else{
            this.spinner.hide();
            this.toastr.success(this.userResult.message, 'Report Status', {
              timeOut: 2000,
              closeButton: true
            });
          }
        });
      }
    })
    .catch(() => {
      return false;
    });  
  }
  passwordClear(){
    this.resetForm();
    this.confirmpasswordErr =null;
    this.resetpasswordErr =null;
  }
}

import { Component, Input, Output,OnInit,EventEmitter,ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { NgForm, EmailValidator } from '@angular/forms';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '../../_services';
import { DetailsService } from '../../_services/index';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { ErrorService } from 'src/app/_services/error.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, animate, transition, style } from '@angular/animations';

import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class CustomerComponent implements OnInit {
  @Input('customerSaveChild') customerSaveChild;
  @Output() ChangeEvent = new EventEmitter();

  @ViewChild('isIncludeTag') isIncludeTag: ElementRef;
  custArr = [];
  result: any;
  pid: any;
  key: any;
  sort: any;
  admin: any;
  form: FormGroup;
  isCreate: boolean = true;
  isCustomerAss: boolean = true;
  isDeal: boolean = false;
  isApproval: boolean = false;
  popoverform1: boolean = false;
  popoverform2: boolean = false;
  isProject: boolean = false;
  body: {};
  err: { 'fname': '', 'lname': '', 'email': '' };
  nameErr: any;
  lnameErr: any;
  emailErr: any;
  phoneErr: any;
  postalErr: any;
  orgErr: any;
  closeResult: string;
  menuState: string = 'out';
  menuStateCust: string = 'out';
  menuAddCust: string = 'out';
  filter: any;
  reverse: any;
  p: any;
  term: any;
  userid: any;
  dropdownList=[];
  selectedItems=[];
  ProjectsArr = [];
  DealArr = [];
  ApprovalArr = [];
  genderErr: any;
  stateErr: any;
  settingssingle: any;
  pc: any;
  padd: any;
  stateResult:any;
  pj: any;
  is_edit: boolean = false;
  addbtndis: boolean = false;
  userType: any;
  isdisable: boolean= true;
  mycustomers:any;
  DealsArrCounts:any;
  ApprovalsArrCounts:any;
  ProjectsArrCounts:any;
  DealsArr = [];
  ApprovalsArr = [];
  orgArr=[];
  orgArrselect=[];
  stateArr=[];
  stateArrselect=[];
  settingssingleorg:any;
  settingState:any;
  passreset: boolean = false;
  passwordForm: FormGroup;
  passwordErr: any;
  resetpasswordErr: any;
  confirmpasswordErr: any;
  result1 :any;
  isSiteAdmin: boolean = false;
  dealTosliderOpen : boolean = false;
  customerToOrgSlider : boolean = false;
  addOrgbtn : boolean = false;
  customerToDeal : boolean = false;


  constructor(private DetailsService: DetailsService,private http: HttpClient, private router: Router,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService, private toastr: ToastrService,
    private errorService: ErrorService,
    private confirmService: ConfirmService,private route: ActivatedRoute,
    private modalService: NgbModal,
) {
      this.createForm();

  }
 ngOnInit() {
    this.userid = localStorage.getItem("loggedInUserID");
    this.userType = localStorage.getItem("loggedInUserType");
    this.admin = localStorage.getItem("loggedInUserAdmin");
    if(this.admin == 'Y'){
      this.isSiteAdmin = true;
    }else{
      this.isSiteAdmin = false;
    }
    this.getOrg();
    this.getCust()
    this.getState();
    this.dropdownList = [
      { "id": 1, "itemName": "Male" },
      { "id": 2, "itemName": "Female" },
      { "id": 3, "itemName": "Others" }
      
    ];
    this.settingssingle = {
      text: "Select Gender",
      classes: "myclass custom-class",
      singleSelection: true,
      enableCheckAll: false
    };
    this.settingState = {
      text: "Select State",
      classes: "myclass custom-class",
      singleSelection: true,
      enableCheckAll: false
    };
    this.settingssingleorg = {
      text: "Select Organization",
      classes: "myclass custom-class",
      singleSelection: true,
      enableCheckAll: false
    };
    if(this.router.url == "/deals"){
     this.dealTosliderOpen = true;
     this.toggleAddMenu();
    }else if(this.addOrgbtn == true){
      this.dealTosliderOpen = true;
      // this.getOrg();
      this.toggleAddMenuCust();
    }else{
      this.dealTosliderOpen = false;
    }
    const custRouteID = +this.route.snapshot.paramMap.get('id');
    if (custRouteID != 0) {
      this.spinner.show();
      this.toggleEditMenu(custRouteID,1);
    }
  }
  ngOnChanges(){
    this.spinner.show();
    this.addOrgbtn = true;
  }
  getCust() {
    this.spinner.show();
    this.custArr = [];
    this.http.get('/api/customer/').subscribe((res: Response) => {
      this.result = res;
      this.mycustomers = this.result.length;
      this.spinner.hide();
      for (let i = 0; i < this.result.length; i++) {
        this.custArr.push({
          "id": this.result[i].details.id, "firstName": this.result[i].details.firstName, "lastName": this.result[i].details.lastName, "email": this.result[i].details.email, "organization": this.result[i].organisation.orgName, "phone": this.result[i].details.phone, "location": this.result[i].location, "unit": this.result[i].location.unit, "address1": this.result[i].location.address1, "address2": this.result[i].location.address2, "state": this.result[i].location.state, "city": this.result[i].location.city, "country": this.result[i].location.country, "postalCode": this.result[i].location.postalCode
        })
      }
    });
  }
  getState() {
    this.stateArr = [];
    this.http.get('/api/australianStates').subscribe((res: Response) => {
      this.stateResult = res;  
      for (let i = 0; i < this.stateResult.length; i++) {
        this.stateArr.push({
          "id": this.stateResult[i].id, "itemName": this.stateResult[i].name,
        })
      }
    });
  }
  async getOrg() {
    this.orgArr =[];
    var result = await this.DetailsService.getOrgs();
    this.result = result;
    if(this.result.length != 0){
    this.addbtndis =false;
    this.result.forEach(r => {
      this.orgArr.push({
        "id": r.id, "itemName": r.orgName
      })
  })
 }else{
   this.addbtndis =true;
 }
}
  toggleCreateMenu() {
    this.popoverform2=false;
    this.nameErr = null;
    this.lnameErr = null;
    this.emailErr = null;  
    this.phoneErr = null;
    this.postalErr = null;
    this.genderErr = null;
    this.stateErr = null;
    this.orgErr = null;
    this.isCreate = true;
    this.pid = undefined;
    this.ProjectsArr=[];
    this.resetForm();
    if (this.menuStateCust == 'in') {
      this.menuStateCust = 'out';
    }
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
  }
  toggleCreateMenuClose(){
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
  }
  toggleAddMenu(){
    if (this.popoverform1 == true) {
      this.popoverform1 = false;
    } else {
      this.popoverform1 = true;
    }
  
    this.nameErr = null;
    this.lnameErr = null;
    this.emailErr = null;
    this.phoneErr = null;
    this.postalErr = null;
    this.orgErr = null;
    this.genderErr = null;
    this.stateErr = null;
    this.isCreate = true;
    this.pid = undefined;
    this.ProjectsArr=[];
    this.resetForm();
    if (this.menuState == 'in') {
      this.menuState = 'out';
    }
    this.menuAddCust = this.menuAddCust === 'out' ? 'in' : 'out';
  }
  toggleAddMenuCust(){
    // this.getOrg();
    if (this.popoverform1 == true) {
      this.popoverform1 = false;
    } else {
      this.popoverform1 = true;
    }
  
    this.nameErr = null;
    this.lnameErr = null;
    this.emailErr = null;
    this.phoneErr = null;
    this.postalErr = null;
    this.orgErr = null;
    this.genderErr = null;
    this.stateErr = null;
    this.isCreate = true;
    this.pid = undefined;
    this.ProjectsArr=[];
    this.resetForm();
    if (this.menuState == 'in') {
      this.menuState = 'out';
    }
    this.menuAddCust = this.menuAddCust === 'out' ? 'in' : 'out';
  }
  toggleAddMenuCustClose(){
    if (this.popoverform1 == true) {
      this.popoverform1 = false;
    } else {
      this.popoverform1 = true;
    }  
    this.nameErr = null;
    this.lnameErr = null;
    this.emailErr = null;
    this.phoneErr = null;
    this.postalErr = null;
    this.orgErr = null;
    this.genderErr = null;
    this.stateErr = null;
    this.isCreate = true;
    this.pid = undefined;
    this.ProjectsArr=[];
    this.resetForm();
    if (this.menuState == 'in') {
      this.menuState = 'out';
    }
    this.menuAddCust = this.menuAddCust === 'out' ? 'in' : 'out';
  }
  public resetForm() {
    this.nameErr = null;
    this.lnameErr = null;
    this.emailErr = null;
    this.postalErr = null;
    this.orgErr = null;
    this.phoneErr = null;
    this.form.reset({
      id: null,
      addressId: null,
      firstName: '',
      lastName: '',
      email: '',
      orgId:[],
      phone: '',
      unit: '',
      entityTypeId:'',
      address1: '',
      address2: '',
      city: 'Sydney',
      state: [],
      country: 'Australia',
      postalCode: '',
      isAdmin: '',
      role: '',
      createdBy: '',
      updatedBy: '',
      gender: []
    })
  }

  createForm() {
    this.form = this.formBuilder.group({
      id: null,
      addressId: null,
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: '',
      unit: '',
      orgId:'',
      address1: '',
      address2: '',
      city: 'Sydney',
      state: '',
      country: 'Australia',
      postalCode: '',
      isAdmin: '',
      entityTypeId:'',
      role: '',
      createdBy: '',
      updatedBy: '',
      gender: ''
    });
    this.passwordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]]
    })
  }
  public passwordresetForm() {
    this.resetpasswordErr = null;
    this.confirmpasswordErr = null;
    this.passwordForm.reset({
       password: '',
       confirmPassword: '',
     })
   }
  validate() {
    this.nameErr = null;
    this.lnameErr = null;
    this.emailErr = null;
    this.phoneErr = null;
    this.orgErr = null;
    this.postalErr = null;
    this.genderErr = null;
    this.stateErr = null;
    var data = this.form.value;
    const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const validNumber = /^\d+$/;
    var valid = true;
    if (data.firstName == undefined || data.firstName == '') {
      this.nameErr = "First Name is required.";
      valid = false;
    }
    if (data.lastName == undefined || data.lastName == '') {
      this.lnameErr = "Last Name is required.";
      valid = false;
    }
    if (data.email == EmailValidator || data.email == '') {
      this.emailErr = "Email is required.";
      valid = false;
    } else if (!validEmailRegEx.test(data.email)) {
      this.emailErr = "Invalid Email";
      valid = false;
    } if (data.gender.length == 0) {
      this.genderErr = "Gender is Required";
      valid = false;
    }
    if (data.state.length == 0) {
        this.stateErr = "State is Required";
        valid = false;
    }
    if (data.phone != '' && !validNumber.test(data.phone)) {
      this.phoneErr = "Invalid Phone Number";
      valid = false;
    }
    if (data.postalCode != '' && !validNumber.test(data.postalCode)) {
      this.postalErr = "Invalid Postal Code";
      valid = false;
    }
    return valid;
  
}
  submitCust() {
    var data = this.form.value;
    data.isAdmin = "N";
    data.role = "N";
    data.createdBy = parseInt(this.userid);
    if (this.validate()) {
      data.gender = data.gender[0].itemName;
      if(data.state.length > 0){
        data.state = data.state[0].itemName.toString();
      }else{
        data.state = "";
      }
      if (data.orgId.length > 0) {
        data.orgId = parseInt(data.orgId[0].id);
      }else{
        data.orgId = null;
      }
      this.spinner.show();
      this.http.post('/api/customer/', data).subscribe((res: Response) => {
        this.toastr.clear();
        this.result = res;
        if (this.result.success) {
          this.resetForm();
          this.toastr.success(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          if(this.router.url == "/deals"){
            this.customerToOrgSlider = false;
            this.customerToDeal = true;
            this.toggleAddMenu();
            this.getCust();
           }else{
            this.toggleAddMenu();
            this.getCust();
           }
      
        } else {
          this.toastr.error(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
        this.errorService.handleError(error);
      })
    }

  }

  updateCust() {
    var data = this.form.value;
    data.updatedBy = parseInt(this.userid);
    if (this.validate()) {
      data.gender = data.gender[0].itemName;
      if(data.state.length > 0){
        data.state = data.state[0].itemName.toString();
      }else{
        data.state = "";
      }

      if (data.orgId.length > 0) {
        data.orgId = parseInt(data.orgId[0].id);
      }else{
        data.orgId = null;
      }
      window.scrollTo(0, 0);
      this.spinner.show();
      this.http.put('/api/customer/' + data.id, data).subscribe((res: Response) => {
        this.toastr.clear();
        this.result = res;
        if (this.result.success) {
          this.resetForm();
          this.toastr.success(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.toggleCreateMenu();
          this.getCust();
        } else {

          this.toastr.error(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
        this.errorService.handleError(error);
      })
    }

  }
  delete(id) {
    this.confirmService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) => {
        if (confirmed) {
          window.scrollTo(0, 0);
          this.spinner.show();
          this.http.delete('/api/customer/' + id).subscribe((res: Response) => {
            this.toastr.clear();
            this.result = res;

            if (this.result.success) {
              this.toggleCreateMenu();
              this.resetForm();
              this.toastr.success(this.result.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.getCust();
            } else {
              this.toastr.error(this.result.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
            }
          }, error => {
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
            this.errorService.handleError(error);
          })
        } else {
        }
      })
      .catch(() => {
        return false;
      });
  }

  toggleEditMenu(id,num) {
    if(num == 0){
      if (this.popoverform2 == true) {
        this.popoverform2 = false;
      } else {
        this.popoverform2 = true;
      }
  
      if (this.isCustomerAss == false) 
      {
        this.is_edit = true;
        this.settingssingle = {
          text: "Select User",
          disabled: true,
        };
      } else {
        this.is_edit = false;
        this.settingssingle = {
          text: "Select User",
          disabled: false,
          
        };
      }
      this.nameErr = null;
      this.lnameErr = null;
      this.emailErr = null;
      this.isCreate = false;
      this.ProjectsArr =[];
      this.pid = id;
      this.http.get('/api/customer/' + this.pid).subscribe((res: Response) => {
        this.result = res;
        if (this.result ) {
          this.form.patchValue({ 'id': this.result .details.id });
          this.form.patchValue({ 'firstName': this.result .details.firstName });
          this.form.patchValue({ 'lastName': this.result .details.lastName });
          this.form.patchValue({ 'email': this.result .details.email });
          this.form.patchValue({ 'isAdmin': this.result .details.isAdmin });
          this.form.patchValue({ 'addressId': this.result .details.addressId });
          this.form.patchValue({ 'phone': this.result .details.phone });
          this.form.patchValue({ 'unit': this.result .location.unit });
          this.form.patchValue({ 'address1': this.result.location.address1 });
          this.form.patchValue({ 'address2': this.result.location.address2 });
          this.form.patchValue({ 'city': this.result.location.city });
          this.form.patchValue({ 'state': this.result.location.state });
          this.form.patchValue({ 'country': this.result.location.country });
          this.form.patchValue({ 'postalCode': this.result.location.postalCode });
          this.form.patchValue({ 'createdBy': this.result.details.createdBy });
          this.form.patchValue({ 'entityTypeId': this.result.details.entityTypeId });
          this.form.patchValue({ 'role': this.result.details.role });
          this.orgArrselect = [];
            if(this.result.details.orgId){
              this.orgArr.forEach(r => {
                if(r.id == this.result.details.orgId){
                  this.orgArrselect.push({
                    "id": r.id, "itemName": r.itemName
                  })
                }
            })
            }
          if (this.result.details.gender == "Male") {
            this.selectedItems = [];
            this.selectedItems.push({ "id": 1, "itemName": "Male" })
          } else if (this.result .details.gender == "Female") {
            this.selectedItems = [];
            this.selectedItems.push({ "id": 2, "itemName": "Female" })
          }
          else if (this.result .details.gender == "Others") {
            this.selectedItems = [];
            this.selectedItems.push({ "id": 3, "itemName": "Others" })
          }
          this.stateArrselect = [];
          for (let i = 0; i < this.stateResult.length; i++) {
            if(this.stateArr[i].itemName == this.result.location.state){
              this.stateArrselect.push({
                "id": this.stateArr[i].id, "itemName": this.stateArr[i].itemName,
              })
            }
          }
          
         
        }
      });
      this.http.get('/api/customer/projects/' + this.pid).subscribe((res: Response) => {
        this.result = res;
        if (this.result) {
          this.DealArr = this.result.deal;
          this.ApprovalArr = this.result.approval;
          this.ProjectsArr = this.result.project;
         }
        });
      this.menuState = this.menuState === 'out' ? 'in' : 'out';
    }else{
      if (this.popoverform2 == true) {
        this.popoverform2 = false;
      } else {
        this.popoverform2 = true;
      }
  
      if (this.isCustomerAss == false) 
      {
        this.is_edit = true;
        this.settingssingle = {
          text: "Select User",
          disabled: true,
        };
      } else {
        this.is_edit = false;
        this.settingssingle = {
          text: "Select User",
          disabled: false,
          
        };
      }
      this.nameErr = null;
      this.lnameErr = null;
      this.emailErr = null;
      this.isCreate = false;
      this.ProjectsArr =[];
      this.pid = id;
      this.http.get('/api/customer/' + this.pid).subscribe((res: Response) => {
        this.result = res;
        if (this.result ) {
          this.form.patchValue({ 'id': this.result .details.id });
          this.form.patchValue({ 'firstName': this.result .details.firstName });
          this.form.patchValue({ 'lastName': this.result .details.lastName });
          this.form.patchValue({ 'email': this.result .details.email });
          this.form.patchValue({ 'isAdmin': this.result .details.isAdmin });
          this.form.patchValue({ 'addressId': this.result .details.addressId });
          this.form.patchValue({ 'phone': this.result .details.phone });
          this.form.patchValue({ 'unit': this.result .location.unit });
          this.form.patchValue({ 'address1': this.result.location.address1 });
          this.form.patchValue({ 'address2': this.result.location.address2 });
          this.form.patchValue({ 'city': this.result.location.city });
          this.form.patchValue({ 'state': this.result.location.state });
          this.form.patchValue({ 'country': this.result.location.country });
          this.form.patchValue({ 'postalCode': this.result.location.postalCode });
          this.form.patchValue({ 'createdBy': this.result.details.createdBy });
          this.form.patchValue({ 'entityTypeId': this.result.details.entityTypeId });
          this.form.patchValue({ 'role': this.result.details.role });
          this.orgArrselect = [];
            if(this.result.details.orgId){
              this.orgArr.forEach(r => {
                if(r.id == this.result.details.orgId){
                  this.orgArrselect.push({
                    "id": r.id, "itemName": r.itemName
                  })
                }
            })
            }
          if (this.result.details.gender == "Male") {
            this.selectedItems = [];
            this.selectedItems.push({ "id": 1, "itemName": "Male" })
          } else if (this.result .details.gender == "Female") {
            this.selectedItems = [];
            this.selectedItems.push({ "id": 2, "itemName": "Female" })
          }
          else if (this.result .details.gender == "Others") {
            this.selectedItems = [];
            this.selectedItems.push({ "id": 3, "itemName": "Others" })
          }
          this.stateArrselect = [];
          for (let i = 0; i < this.stateResult.length; i++) {
            if(this.stateArr[i].itemName == this.result.location.state){
              this.stateArrselect.push({
                "id": this.stateArr[i].id, "itemName": this.stateArr[i].itemName,
              })
            }
          }
          
         
        }
      });
      this.http.get('/api/customer/projects/' + this.pid).subscribe((res: Response) => {
        this.result = res;
        if (this.result) {
          this.DealArr = this.result.deal;
          this.ApprovalArr = this.result.approval;
          this.ProjectsArr = this.result.project;
         }
        });
      this.menuState = this.menuState === 'out' ? 'in' : 'out';
    }
    
  }
  toggleprojectMenu(id) {
    this.http.get('/api/customer/projects/' + id).subscribe((res: Response) => {
      this.result = res;
      if (this.result) {
        if (this.result.deal.length < 10) {
          this.DealsArrCounts = ("0" + this.result.deal.length).slice(-2);
        } else if (this.result.deal.length == 0) {
          this.DealsArrCounts = "00";
        } else {
          this.DealsArrCounts = this.result.deal.length;
        }
        if (this.result.approval.length < 10) {
          this.ApprovalsArrCounts = ("0" + this.result.approval.length).slice(-2);
        } else if (this.result.approval.length == 0) {
          this.ApprovalsArrCounts = "00";
        } else {
          this.ApprovalsArrCounts = this.result.approval.length;
        }
        if (this.result.project.length < 10) {
          this.ProjectsArrCounts = ("0" + this.result.project.length).slice(-2);
        } else if (this.result.project.length == 0) {
          this.ProjectsArrCounts = "00";
        } else {
          this.ProjectsArrCounts = this.result.project.length;
        }
        this.DealsArr = this.result.deal;
        this.ApprovalsArr = this.result.approval;
        this.ProjectsArr = this.result.project;
      }
    });
    this.menuStateCust = this.menuStateCust === 'out' ? 'in' : 'out';
  }
   closecust() {
        this.menuStateCust = this.menuStateCust === 'out' ? 'in' : 'out';
  }
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  onSelectdeals(deals) {
    this.router.navigate(['/details', deals]);
  }
  onSelectapproval(approvalpro) {
    this.router.navigate(['/approvals-details', approvalpro]);
  }
  onSelectProject(projects) {
    this.router.navigate(['/project-details', projects]);
  }
  ontasks(deals, taskid) {
    this.router.navigate(['/details', deals]);
  }
  toggleAddClose(){
    if(this.router.url == "/deals"){
      this.customerToOrgSlider = false;
      this.customerToDeal = true;
     }else{     
     }
     this.nameErr = null;
     this.lnameErr = null;
     this.emailErr = null;
     this.phoneErr = null;
     this.postalErr = null;
     this.orgErr = null;
     this.genderErr = null;
     this.stateErr = null;
     this.isCreate = true;
     this.pid = undefined;
     this.ProjectsArr=[];
     this.resetForm();
     if (this.menuState == 'in') {
       this.menuState = 'out';
     }
    this.popoverform1 = false;
    this.menuAddCust = this.menuAddCust === 'out' ? 'in' : 'out';

  }
  toggleCreateMenu1(){
    this.popoverform2 = false;
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
  }
  restpasslist: boolean = false;
  restpass(){
    if (this.passreset == true) {
      this.passreset = false;
    } else {
      this.passreset = true;
    }
  }
  passwordPost(pid) {
    if (this.passwordValidate()) {
      this.spinner.show();
      this.http.get('/api/customer/' +pid).subscribe((res: Response) => {
        this.result = res;
        var data = this.result.details;
        var data1 = this.passwordForm.value;
        data.password = data1.confirmPassword;
        data.updatedBy = parseInt(this.userid);
        this.http.put('/api/customer/' + pid, data).subscribe((response) => {
          this.toastr.clear();
          this.result1 = response;
          if (this.result1.success) {
            this.pid =this.result1.response.id
            this.passwordresetForm();
            this.toastr.success('Password Updated successfully', 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.restpass();
            this.spinner.hide();
          } else {
            this.toastr.error(this.result1.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
          }
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })
      })
    }
  }

  passwordValidate() {
    this.resetpasswordErr = null;
    this.confirmpasswordErr = null;
    var data = this.passwordForm.value;
    const strongRegex = /(?=.{6,})/;
    var valid = true;
    if (data.password == Validators || data.password == '') {
      this.resetpasswordErr = "Password is required.";
      valid = false;
    } else if (!strongRegex.test(data.password)) {
      this.resetpasswordErr = "Password is Must be 6 Charcters.";
      valid = false;
    } else if (data.password != data.confirmPassword) {
      this.confirmpasswordErr = "Password doesn't Match.";
      valid = false;
    }
    return valid;
  }

  OpenOrgSlier(){
    this.spinner.show();
    this.toggleAddClose();
    this.customerToOrgSlider = false;  
    this.customerToDeal=false;
    setTimeout(() => {
      this.customerToOrgSlider = !this.customerToOrgSlider; 
      this.spinner.hide();
     },1000);
   
  }
  
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Router } from '@angular/router';
import * as moment from 'moment/moment.js';
import { DetailsService } from '../_services/index';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { trigger, state, animate, transition, style } from '@angular/animations';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/_services/error.service';
import { Lightbox } from 'ngx-lightbox';
import { PermissionService } from 'src/app/_services/permission.service';
import { GanttEditorComponent, GanttEditorOptions } from 'ng-gantt';
import * as $ from 'jquery';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';



@Component({
  selector: 'app-job-handling',
  templateUrl: './job-handling.component.html',
  styleUrls: ['./job-handling.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class JobHandlingComponent implements OnInit {
  @ViewChild('editor') editor: GanttEditorComponent;
  public editorOptions: GanttEditorOptions;
  data: any;
  isProjectatt: boolean = false;
  divheight: any;
  projectId: any;
  userid: any;
  jobId: any;
  projestJobArr: any;
  admin: any;
  details: any;
  chartTypeJob: any;
  chartColumNamesJob: any;
  chartOptionsJob: any;
  chartDataJob: any;
  nameErr: any;;
  custErr: any;
  StartdateErr: any;
  dateErr: any;
  buildErr: any;
  statusErr: any;
  detailsErr: any;
  nameSubErr: any;
  jobStatusErr: any;
  openStatusErr: any;
  endDateSubErr: any;
  StartsubdateErr: any;
  custSubErr: any;
  projectUsers: any;
  showGantLength: any;
  editJobId: any;
  editJobValue: any;
  jobtype: any;
  statussettings: any;
  statOpenSettings: any;
  statOpenSettings1: any;
  statusOpenSettings: any;
  statusOpenSettings1: any;
  selDate: any;
  resultOrder: any;
  daysDurationErr: any;
  myDatePickerOptions: any;
  myDatePickerOptionsSub: any;
  myDatePickerOptions2: any;
  notifiSettings: any;
  subJobNameTitle: any;
  jobDetails: any;
  subjobdaycount: any;
  msettings:any;
  result: any;
  result1:any;
  result2:any;
  result3:any;  
  subJobId: any;
  parentId: any;
  supervisorErr :any;
  JOB_COMPLETED = "#f5748a";
  JOB_IN_PROGRESS = "#63a5e6";
  SUBJOB_COMPLETED = "#c3959c";
  SUBJOB_IN_PROGRESS = "#70859a";
  projectArr = [];
  projectStateArr = [];
  subprojectStateArr = [];
  subJobDetailsArr = [];
  jobArrselect = [];
  projectUsersArr = [];
  projectStatus = [];
  projectInfoID:any;
  projectDepended = [];
  projectPredecessor = [];
  subJobArrselect = [];
  statusArr = [];
  statusArrSelect = [];
  noftificationArr = [];
  noftificationArrSelect = [];
  noftificationSubArrSelect = [];
  menuStateProjectEdit: string = 'out';
  menuStateSubJobtEdit: string = 'out';
  ProjectJobForm: FormGroup;
  subJobForm: FormGroup;
  isCreate1: boolean = false;
  iscurrdis: boolean = false;
  isCreate: boolean = true;
  isSubCreate: boolean = true;
  jobCompleteHide: boolean = false;
  jobCompleteHideDis: boolean = false;
  jobCompleteSubHide: boolean = false;
  SubjobCompleteHide: boolean = false;
  depjobCompleteHide: boolean = false;
  is_edit: boolean = false;
  subJobAllDetailsShow: boolean = false;
  iconShow: boolean = false;
  settings = {};
  settings1 = {};
  body: {};
  charthigth: any;
  dependedStateSettings: any;
  predecessorStateSettings: any;
  supervisorsettings :any;
  projectAllUsersArr=[];
  projectAllUsersArrSelect =[];
  projectSupervisorArrSelect =[];
  supervisorShow: boolean = false;
  externalinternalResult :any;
  jobName:any;
  entId :any;
  issupplier: boolean = false;
  ovrelayPopup1: boolean = false;
  ovrelayPopup2: boolean = false;
  backBtn: boolean = false;

  constructor(private spinner: NgxSpinnerService,
    private http: HttpClient, private router: Router,
    private DetailsService: DetailsService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private permissionService: PermissionService,
    private errorService: ErrorService,
    private confirmService: ConfirmService,
    private toastr: ToastrService,
  ) {
    this.chartTypeJob = "Timeline";
    this.chartOptionsJob = { timeline: { showRowLabels: false, groupByRowLabel: false }, avoidOverlappingGridLines: false, width: 890, height: 1200 }
    this.chartColumNamesJob = [
      { type: 'string', id: 'User' },
      { type: 'string', id: 'JobName' },
      { type: 'string', id: 'style', role: 'style' },
      { type: 'date', id: 'Start' },
      { type: 'date', id: 'End' },
    ]
    this.chartDataJob = [];
    this.settings = {
      text: "SelectUser",
      singleSelection: true,
      enableSearchFilter: true,
    }
    this.settings1 = {
      singleSelection: true,
      disabled: true
    }
    this.statussettings = {
      text: "Select projectState",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: true,
      enableCheckAll: false
    }
    this.supervisorsettings = {
      text: "Select Supervisor",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: true,
      enableCheckAll: false
    }
    this.statusOpenSettings = {
      text: "Status Select",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: true,
      enableCheckAll: false
    }
    this.msettings = {
      text: "Select User",
      classes: "myclass custom-class",
      singleSelection: true,
      enableCheckAll: false,
      groupBy: "type"
    };
    this.statusOpenSettings1 = {
      text: "Status Select",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: true,
      singleSelection: true,
      enableCheckAll: false
    }
    this.statOpenSettings = {
      text: "Status Select",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: true,
      enableCheckAll: false
    }
    this.statOpenSettings1 = {
      text: "Status Select",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: true,
      singleSelection: true,
      enableCheckAll: false
    }
    this.notifiSettings = {
      text: "Notification",
      disabled: false,
      singleSelection: false,
      enableCheckAll: false
    }
    this.dependedStateSettings = {
      text: "Select DependedState",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: false,
      enableCheckAll: false
    }
    this.predecessorStateSettings = {
      text: "Select PredecessorState",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: true,
      enableCheckAll: false
    }
  }

  ngOnInit() {
    this.editorOptions = {
      vFormat: 'day',
      vUsesSingleCell: 10000
    }
    this.spinner.show();
    this.userid = localStorage.getItem("loggedInUserID");
    this.admin = localStorage.getItem("loggedInUserAdmin");
    this.entId=localStorage.getItem("loggedInUserType");
    const id = +this.route.snapshot.paramMap.get('id');
    this.projectId = id;
    const pid = +this.route.snapshot.paramMap.get('pid');
    this.editJobId = pid;
    const value = this.route.snapshot.paramMap.get('value');
    this.editJobValue = value;
    const type = this.route.snapshot.paramMap.get('type');
    this.jobtype = type;
    if(this.editJobValue == true){
      this.backBtn = false 
    }else if(this.editJobValue == "true"){
      this.backBtn = false 
    }else{
      this.backBtn = true
    }  
    this.getJobs();
    if (this.editJobValue) {
      setTimeout(() => {
        if (this.jobtype == 1) {
          this.ProjectEditMenu(this.editJobId, this.editJobValue, 1);
        } else {
          this.ProjectEditMenu(this.editJobId, this.editJobValue, 2);
        }
      }, 4000);
    }
    this.getDetailsOnly();
    this.projectInfoCode();
    this.createForms();
      
    this.statusArr = [
      { "id": 1, "itemName": "Open" },
      { "id": 2, "itemName": "Inprogress" },
      { "id": 4, "itemName": "Booked" },
      { "id": 3, "itemName": "Completed" }
    ];
    this.noftificationArr = [
      { "id": 1, "itemName": "3 Days before" },
      { "id": 2, "itemName": "7 Days before" },
      { "id": 3, "itemName": "15 Days before" },
      { "id": 4, "itemName": "30 Days Before" }
    ];
  }


  onDateChanged(event) {
  }
  createForms() {
    this.ProjectJobForm = this.formBuilder.group({
      id: '',
      name: '',
      assignedToId: '',
      supervisorId : [],
      projectStateId: '',
      projectId: '',
      startDate: '',
      endDate: '',
      isCompleted: '',
      createdBy: '',
      updatedBy: '',
      isActive: '',
      createdDate: '',
      jobOrder: '',
      daysDuration:'',
      dependedJobId: '',
      predecessorJobId: '',
      notifybefore: [],
      statusId: [],
      notifyMsg: ''

    });
    this.subJobForm = this.formBuilder.group({
      id: '',
      name: '',
      assignedToId: '',
      supervisorId : [],
      projectStateId: '',
      projectId: '',
      startDate: '',
      endDate: '',
      createdBy: '',
      updatedBy: '',
      isActive: '',
      createdDate: '',
      jobOrder: '',
      dependedJobId: '',
      predecessorJobId: '',
      parentJobId: '',
      isCompleted: '',
      statusId: [],
      daysDuration: '',
      notifybefore: [],
      notifyMsg: ''

    });
  }
  resetForms() {
    this.ProjectJobForm.reset({
      id: '',
      name: '',
      projectStateId: '',
      assignedToId: [],
      supervisorId : [],
      projectId: '',
      startDate: '',
      endDate: '',
      isCompleted: '',
      createdBy: '',
      updatedBy: '',
      isActive: '',
      createdDate: '',
      jobOrder: '',
      daysDuration:'',
      parentJobId: '',
      notifybefore: [],
      statusId: [],
      notifyMsg: ''
    });
    this.ProjectJobForm.patchValue({ startDate: null });
    this.ProjectJobForm.patchValue({ endDate: null });


    this.subJobForm.reset({
      id: '',
      name: '',
      assignedToId: [],
      supervisorId : [],
      projectStateId: '',
      projectId: '',
      startDate: '',
      endDate: '',
      createdBy: '',
      updatedBy: '',
      isActive: '',
      createdDate: '',
      jobOrder: '',
      parentJobId: '',
      isCompleted: '',
      statusId: [],
      daysDuration: '',
      notifybefore: [],
      notifyMsg: ''
    });
    this.subJobForm.patchValue({ startDate: null });
    this.subJobForm.patchValue({ endDate: null });
  }

projectInfoCode() {
    this.http.get('/api/projectInfo/project/' + this.projectId).subscribe((res: Response) => {
      this.result = res;
      this.projectInfoID = this.result.ProjectInfoId;
      this.getprojectinfo(this.projectInfoID);
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  getprojectinfo(id) {
     this.myDatePickerOptions={};
    this.http.get('/api/projectInfo/' + id).subscribe((res: Response) => {
      this.result = res;
          var disableDate1 = new Date(this.result.projectInfo.projectStart);
      this.myDatePickerOptions = {
          dateFormat: 'dd-mm-yyyy',
          disableUntil: { year: disableDate1.getFullYear(), month: disableDate1.getMonth() + 1, day: disableDate1.getDate() - 1 }
        }
        this.myDatePickerOptions2 = {
          dateFormat: 'dd-mm-yyyy',
          disableUntil: { year: disableDate1.getFullYear(), month: disableDate1.getMonth() + 1, day: disableDate1.getDate() - 1 }
        }
      
       }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }

  async getDetailsOnly() {
    var result = await this.DetailsService.getDetailsID(this.projectId);
    this.details = result;
    // this.roleDetails(this.projectId);
    this.getallUserAndExternalUser(this.projectId);
  }
  async roleDetails(id) {
    this.projectUsersArr = [];
    this.http.get('/api/projectJob/jobAssignedTo/' + id).subscribe((res: Response) => {
      this.result = res;
      this.result.forEach(r => {
        this.projectUsersArr.push({
          "id": r.id, "itemName": r.name
        })
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
    })

  }

  getJobs() {
    var todaydate = new Date();
    var tdate = moment(todaydate).format("YYYY-MM-DD");
    this.showGantLength = [];
    this.chartDataJob = [];
    if(this.entId == 4 ||this.entId == 5){
      var url ='/api/externalUser/projectJobs/' + this.projectId +"/"+this.userid
    }else{
       url ='/api/projectJob/' + this.projectId
    }
    this.http.get(url).subscribe((res: Response) => {
      this.spinner.show();
      this.result = res;
      this.getjobsummary(this.result);
      for (let i = 0; i < this.result.length; i++) {
        if (this.isProjectatt == true) {
          var edit = "false";
          if (this.result[i].assigned.name != null) {
            if (this.userid == this.result[i].assigned.id) {
              edit = "true";
            }
          }
          this.projectArr.push({
            "jobid": this.result[i].job.id, "jobName": this.result[i].job.name, "stateId": this.result[i].state.id, "statename": this.result[i].state.name, "startDate": this.result[i].job.startDate, "endDate": this.result[i].job.endDate, "isCompleted": this.result[i].job.isCompleted, "assigned": this.result[i].assigned.name, "edit": edit
          })
        } else {
          var edit = "true";
          this.projectArr.push({
            "jobid": this.result[i].job.id, "jobName": this.result[i].job.name, "stateId": this.result[i].state.id, "statename": this.result[i].state.name, "startDate": this.result[i].job.startDate, "endDate": this.result[i].job.endDate, "isCompleted": this.result[i].job.isCompleted, "assigned": this.result[i].assigned.name, "edit": edit
          })
        }
        //  this.subJobDetailsShow(result[i].job.id);
      }
      setTimeout(() => {
        this.spinner.hide();
      }, 3000);
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  getjobsummary(result) {
    var u: any;
    var className = ''
    var completeval = 100;
    var name = '';
    var dependedJobId = '';
    var link = "";
    var gropid = 0;
    var startDt = '';
    var endDt = '';
    result.forEach(u => {
      if (u.job) {
        if (u.assigned.name != "") {
          name = u.assigned.name;
        } else {
          name = ''
        }
        if (u.subJobs.length != 0) {
          gropid = 1;
          startDt = '';
          endDt = '';
        } else {
          gropid = 0;
          startDt = u.job.startDate;
          endDt = u.job.endDate;
        }
        if(this.entId == 4 ||this.entId == 5){
          if (u.job.statusId == 3) {
         var jobname = '<a class="myjob">' + u.job.name + '<span style="display:none">' + u.job.id + '</span></a>';
          className = 'gtaskgreen'
          completeval = 100;
           } else if (u.job.statusId == 2) {
          className = 'gtaskpink'
          completeval = 25;
          jobname = '<a class="myjob">' + u.job.name + '<span style="display:none">' + u.job.id + '</span></a>';
        } else {
          className = 'gtaskblue'
          completeval = 0;
          jobname = '<a class="myjob">' + u.job.name + '<span style="display:none">' + u.job.id + '</span></a>';
        }
      }else{
        if (u.job.statusId == 3) {
          className = 'gtaskgreen'
          completeval = 100;
          var jobname = '<a class="myjob">' + u.job.name + '<span style="display:none">' + u.job.id + '</span></a>';
        } else if (u.job.statusId == 2) {
          className = 'gtaskpink'
          completeval = 25;
          var jobname = '<a class="myjob">' + u.job.name + '<span style="display:none">' + u.job.id + '</span></a><a class="mytest">+<span style="display:none">' + u.job.id + '</span></a>';
        } else {
          className = 'gtaskblue'
          completeval = 0;
          var jobname = '<a class="myjob">' + u.job.name + '<span style="display:none">' + u.job.id + '</span></a><a class="mytest">+<span style="display:none">' + u.job.id + '</span></a>';
        }
      }

        if (u.job.dependedJobId != null) {
          dependedJobId = u.job.dependedJobId;
        } else {
          dependedJobId = '';
        }
        this.chartDataJob.push({
          'pID': u.job.id,
          'pName': jobname,
          'pStart': startDt,
          'pEnd': endDt,
          'pClass': className,
          'pLink': link,
          'pMile': 0,
          'pRes': name,
          'pComp': completeval,
          'pGroup': gropid,
          'pParent': 0,
          'pOpen': 1,
          'pDepend': dependedJobId,
          'pCaption': '',
          'pNotes': ''
        })
      }
      if (u.subJobs.length != 0) {
        u.subJobs.forEach(r => {
          if (r.subJobAsso.name != "") {
            name = r.subJobAsso.name;
          } else {
            name = '';
          }
          if (r.subJob.statusId == 3) {
            className = 'gtaskgreen'
            completeval = 100;
          } else if (r.subJob.statusId == 2) {
            className = 'gtaskpink'
            completeval = 25;
          } else {
            className = 'gtaskyellow'
            completeval = 0;
          }
          if (r.subJob.dependedJobId != null) {
            dependedJobId = r.subJob.dependedJobId;
          } else {
            dependedJobId = '';
          }
          this.chartDataJob.push({
            'pID': r.subJob.id,
            'pName': '<a class="mysubjob">' + r.subJob.name + '<span style="display:none">' + r.subJob.id + '</span></a>',
            'pStart': r.subJob.startDate,
            'pEnd': r.subJob.endDate,
            'pClass': className,
            'pLink': link,
            'pMile': 0,
            'pRes': name,
            'pComp': completeval,
            'pGroup': 0,
            'pParent': r.subJob.parentJobId,
            'pOpen': 1,
            'pDepend': dependedJobId,
            'pCaption': '',
            'pNotes': ''
          });
        });
      }
    });

    setTimeout(() => {
      this.editorOptions = {
        vFormat: 'day',
        vShowStartDate: '0',
        vShowEndDate: '0',
      };
      this.showGantLength = this.chartDataJob.length;
      this.spinner.hide();
      let self = this;
      $(document).ready(function () {
        $(".mytest").click(function () {
          var pid = $(this).find("span").text();
          self.toggleAddSubJob(pid);
        });
        $(".myjob").click(function () {
          var pid = $(this).find("span").text();
          self.ProjectEditMenu(pid, "true", 0);
        });
        $(".mysubjob").click(function () {
          var pid = $(this).find("span").text();
          self.subJobEditMenu(pid);
        });
      });
    }, 5000);
  }

  hitList(pid) {
    alert(pid);
  }
  jobstatuschanges(dataval) {
    this.jobStatusErr = null;
    var valid = true;
    var data = this.ProjectJobForm.value;
    if (data.statusId.length == 0) {
      this.jobStatusErr = "Status is required";
      valid = false;
    } else {
      if (data.statusId[0].id == 1) {
        valid = true;
      } else if (data.statusId[0].id == 2) {
        if (dataval[0].predecessorJob != null) {
          if (dataval[0].predecessorJob.statusId != 3) {
            this.jobStatusErr = "could not start or Complete this Job,predecessorJob '" + dataval[0].predecessorJob.name + "' is not Completed!";
            if (dataval[0].status == "1") {
              this.statusArrSelect = [];
              this.statusArrSelect.push({ "id": 1, "itemName": "Open" })
            } else if (dataval[0].status == "2") {
              this.statusArrSelect = [];
              this.statusArrSelect.push({ "id": 2, "itemName": "Inprogress" })
            } else if (dataval[0].status == "3") {
              this.statusArrSelect = [];
              this.statusArrSelect.push({ "id": 3, "itemName": "Completed" })
            }
            valid = false;
          }
        }
      } else if (data.statusId[0].id == 3) {
        if (dataval[0].subJobTotal != dataval[0].subJobCompleted) {
          this.jobStatusErr = "could not complete this Job, since some SubJobs are not Completed!";
          if (dataval[0].status == "1") {
            this.statusArrSelect = [];
            this.statusArrSelect.push({ "id": 1, "itemName": "Open" })
          } else if (dataval[0].status == "2") {
            this.statusArrSelect = [];
            this.statusArrSelect.push({ "id": 2, "itemName": "Inprogress" })
          } else if (dataval[0].status == "3") {
            this.statusArrSelect = [];
            this.statusArrSelect.push({ "id": 3, "itemName": "Completed" })
          }
          valid = false;
        }

        if (dataval[0].dependedJob.length != 0) {
          for (let i = 0; i < dataval[0].dependedJob.length; i++) {
            if (dataval[0].dependedJob[i].statusId != 3) {
              this.jobStatusErr = "could not Complete this Job, dependent Job '" + dataval[0].dependedJob[i].name + "' is not Completed!";
              if (dataval[0].status == "1") {
                this.statusArrSelect = [];
                this.statusArrSelect.push({ "id": 1, "itemName": "Open" })
              } else if (dataval[0].status == "2") {
                this.statusArrSelect = [];
                this.statusArrSelect.push({ "id": 2, "itemName": "Inprogress" })
              } else if (dataval[0].status == "3") {
                this.statusArrSelect = [];
                this.statusArrSelect.push({ "id": 3, "itemName": "Completed" })
              }
              valid = false;
              break;
            }
          }
        }

      }
    }
    return valid;
  }
  getprojectStatus(id) {
    this.projectStatus = [];
    for (let i = 0; i < this.projectArr.length; i++) {
      if (id != this.projectArr[i].jobid) {
        this.projectStatus.push({ "id": this.projectArr[i].jobid, "itemName": this.projectArr[i].jobName, })
      }
    }
  }
  ProjectEditMenu(id, val, number) {
    if (this.ovrelayPopup1 == true) {
      this.ovrelayPopup1 = false;
    } else {
      this.ovrelayPopup1 = true;
    }
    this.myDatePickerOptions2={};
    this.projestJobArr = [];
    this.projectDepended = [];
    this.projectPredecessor = [];
    if (number == 0) {
      if (this.isProjectatt == true) {
        this.is_edit = true;
      }
      if(this.entId == 4 || this.entId == 5){
        this.issupplier=true;
    }else{
       this.issupplier=false;
    }
      if (val == "true") {
        this.parentId = id;
        this.jobId = id;
        this.projectStateArr = [];
        this.jobArrselect = [];
        this.projectDepended = [];
        this.projectPredecessor = [];
        this.projectAllUsersArrSelect = [];
        this.custErr = null;
        this.StartdateErr = null;
        this.dateErr = null;
        this.isCreate1 = null;
        this.statusErr = null;
        this.jobStatusErr = null;
        this.supervisorErr =null;
        this.isCreate = false;
        this.http.get('api/projectJob/' + this.projectId + "/" + this.jobId).subscribe((res: Response) => {
          this.result = res;
         this.result1 = this.result.job;
         this.result2 = this.result.assigned;
          this.result3 = this.result.state;
          this.jobDetails = this.result.job;
          this.jobName =  this.result.job.name;
          var result4 = this.result.supervisor;
          this.subjobdaycount = this.result.totalSubJobDuretion
          this.projestJobArr.push({
            "dependedJob": this.result.dependedJob, "predecessorJob": this.result.predecessorJob, "subJobTotal": this.result.subJobTotal, "subJobCompleted": this.result.subJobCompleted, "status": this.result1.statusId
          })
          this.getprojectStatus(this.result1.id);
          if (this.result1.notifybefore.length > 0) {
            this.noftificationArrSelect = [];
            for (let i = 0; i < this.result1.notifybefore.length; i++) {
              if (this.result1.notifybefore[i] == "1") {
                this.noftificationArrSelect.push({ "id": 1, "itemName": "3 Days before" })
              } else if (this.result1.notifybefore[i] == "2") {
                this.noftificationArrSelect.push({ "id": 2, "itemName": "7 Days Before" })
              } else if (this.result1.notifybefore[i] == "3") {
                this.noftificationArrSelect.push({ "id": 3, "itemName": "15 Days Before" })
              } else if (this.result1.notifybefore[i] == "4") {
                this.noftificationArrSelect.push({ "id": 4, "itemName": "30 Days Before" })
              }
            }
          } else {
            this.noftificationArrSelect = [];
          }
          if (this.result1.statusId != null) {
            if (this.result1.statusId == "1") {
              this.jobCompleteHideDis = false;
              this.jobCompleteHide = false;
              this.ProjectJobForm.enable();
              this.statusArrSelect = [];
              this.statusArrSelect.push({ "id": 1, "itemName": "Open" })
            } else if (this.result1.statusId == "2") {
              this.jobCompleteHideDis = false;
              this.jobCompleteHide = false;
              this.ProjectJobForm.enable();
              this.statusArrSelect = [];
              this.statusArrSelect.push({ "id": 2, "itemName": "Inprogress" })
            } else if (this.result1.statusId == "3") {
              this.jobCompleteHideDis = true;
              this.ProjectJobForm.disable();
              this.statusArrSelect = [];
              this.statusArrSelect.push({ "id": 3, "itemName": "Completed" })
            }else if (this.result1.statusId == "4") {   
              this.statusArrSelect = [];
              this.statusArrSelect.push({ "id": 4, "itemName": "Booked" })
            }
          } else {
            this.statusArrSelect = [];
          }
          if (this.result1.dependedJobId != null) {
            this.projectArr.forEach(r => {
              for (let j = 0; j < this.result1.dependedJobId.length; j++) {
                if (r.jobid == this.result1.dependedJobId[j]) {
                  this.projectDepended.push({
                    "id": r.jobid, "itemName": r.jobName
                  })
                }
              }
            });
          } else {
            this.ProjectJobForm.patchValue({ 'dependedJobId': this.result.dependedJobId });
          }
          if (this.result.predecessorJob != null) {
            this.projectPredecessor.push({
              "id": this.result.predecessorJob.id, "itemName": this.result.predecessorJob.name
            })
          } else {
            this.projectPredecessor = [];
          }
          if (this.result) {
            if(this.entId == 4 ||this.entId == 5){
              this.ProjectJobForm.disable();
             }else{
                //  this.ProjectJobForm.enable();
             }
            if (this.result1.assignedToId) {
              this.jobCompleteHide = true;
              this.projectAllUsersArrSelect.push({
                "id": this.result2.id, "itemName": this.result2.name
              })
            } else {
              this.jobCompleteHide = false;
            }
            this.projectSupervisorArrSelect=[];
            if(this.result1.supervisorId != null){
              this.supervisorShow = true;
              this.projectSupervisorArrSelect.push({
                "id": result4.id, "itemName": result4.name
              })
            }else{
              this.projectSupervisorArrSelect=[];
              this.supervisorShow = false;
            }
            // if (this.result1.assignedToId) {
            //   this.jobCompleteHide = true;
            //   this.jobArrselect.push({
            //     "id": this.result2.id, "itemName": this.result2.name
            //   })
            // } else {
            //   this.jobCompleteHide = false;
            // }
            // if (result1.isCompleted == "Y") {
            //   this.jobCompleteHideDis = true;
            //   this.ProjectJobForm.disable();
            //   this.ProjectJobForm.patchValue({ 'isCompleted': true });
            // } else {
            //   this.jobCompleteHideDis = false;
            //   this.ProjectJobForm.enable();
            this.ProjectJobForm.patchValue({ 'isCompleted': false });
            // }
            this.projectStateArr.push({
              "stateName": this.result3.name
            })
            this.ProjectJobForm.patchValue({ 'id': this.result1.id });
            this.ProjectJobForm.patchValue({ 'name': this.result1.name });
            this.ProjectJobForm.patchValue({ 'projectStateId': this.result1.projectStateId });
            this.ProjectJobForm.patchValue({ 'isActive': this.result1.isActive });
            this.ProjectJobForm.patchValue({ 'projectId': this.result1.projectId });
            this.ProjectJobForm.patchValue({ 'createdDate': this.result1.createdDate });
            this.ProjectJobForm.patchValue({ 'createdBy': this.result1.createdBy });
            this.ProjectJobForm.patchValue({ 'createdBy': this.result1.createdBy });
            this.ProjectJobForm.patchValue({ 'updatedBy': this.result1.updatedBy });
            this.ProjectJobForm.patchValue({ 'jobOrder': this.result1.jobOrder });
            this.ProjectJobForm.patchValue({ 'daysDuration': this.result1.daysDuration });
            if (this.result1.notifyMsg != null) {
              this.ProjectJobForm.patchValue({ 'notifyMsg': this.result1.notifyMsg });
            } else {
              this.ProjectJobForm.patchValue({ 'notifyMsg': "" });
            }
            var scdate = this.result1.startDate;
            if (scdate) {
              var ydate = moment(scdate).format("YYYY")
              var mdate = moment(scdate).format("M")
              var ddate = moment(scdate).format("D")
              this.ProjectJobForm.patchValue({
                'startDate': {
                  date: {
                    year: ydate,
                    month: mdate,
                    day: ddate
                  }
                }
              });
            } else {
              this.ProjectJobForm.patchValue({ startDate: null });
            }
               var disableDate1 = new Date(this.result1.startDate);
      this.myDatePickerOptions2 = {
          dateFormat: 'dd-mm-yyyy',
          disableUntil: { year: disableDate1.getFullYear(), month: disableDate1.getMonth() + 1, day: disableDate1.getDate() - 1 }
        }
            var cdate = this.result1.endDate;
            if (cdate) {
              var ydate = moment(cdate).format("YYYY")
              var mdate = moment(cdate).format("M")
              var ddate = moment(cdate).format("D")
              this.ProjectJobForm.patchValue({
                'endDate': {
                  date: {
                    year: ydate,
                    month: mdate,
                    day: ddate
                  }
                }
              });
            } else {
              this.ProjectJobForm.patchValue({ endDate: null });
            }
          }
        }, error => {
          this.toastr.clear();
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.errorService.handleError(error);
        });
        this.projectStateArr = [];
        if (this.menuStateSubJobtEdit == 'in') {
          this.menuStateSubJobtEdit = 'out';
        }
        this.menuStateProjectEdit = this.menuStateProjectEdit === 'out' ? 'in' : 'out';
      }
    } else if (number == 1) {
      if (this.isProjectatt == true) {
        this.is_edit = true;
      }
      if(this.entId == 4 ||this.entId == 5){
        this.issupplier=true;
    }else{
       this.issupplier=false;
    }
      if (val == "true") {
        this.parentId = id;
        this.jobId = id;
        this.projectStateArr = [];
        this.jobArrselect = [];
        this.custErr = null;
        this.StartdateErr = null;
        this.dateErr = null;
        this.isCreate1 = null;
        this.statusErr = null;
        this.jobStatusErr = null;
        this.projectAllUsersArrSelect = [];
        this.isCreate = false;
        this.projectDepended = [];
        this.projectPredecessor = [];
        this.http.get('api/projectJob/' + this.projectId + "/" + this.jobId).subscribe((res: Response) => {
         this.result = res;
          this.result1 = this.result.job;
          this.result2 = this.result.assigned;
          this.result3 = this.result.state;
          this.jobDetails = this.result.job;
          var result4 = this.result.supervisor;
          this.jobName =  this.result.job.name;
          this.projestJobArr.push({
            "dependedJob": this.result.dependedJob, "predecessorJob": this.result.predecessorJob, "subJobTotal": this.result.subJobTotal, "subJobCompleted": this.result.subJobCompleted, "status": this.result1.statusId
          })
          if (this.result1.notifybefore.length > 0) {
            this.noftificationArrSelect = [];
            for (let i = 0; i < this.result1.notifybefore.length; i++) {
              if (this.result1.notifybefore[i] == "1") {
                this.noftificationArrSelect.push({ "id": 1, "itemName": "3 Days before" })
              } else if (this.result1.notifybefore[i] == "2") {
                this.noftificationArrSelect.push({ "id": 2, "itemName": "7 Days Before" })
              } else if (this.result1.notifybefore[i] == "3") {
                this.noftificationArrSelect.push({ "id": 3, "itemName": "15 Days Before" })
              } else if (this.result1.notifybefore[i] == "4") {
                this.noftificationArrSelect.push({ "id": 4, "itemName": "30 Days Before" })
              }
            }
          } else {
            this.noftificationArrSelect = [];
          }
          if (this.result1.statusId != null) {
            if (this.result1.statusId == "1") {
              this.jobCompleteHideDis = false;
              this.ProjectJobForm.enable();
              this.statusArrSelect = [];
              this.statusArrSelect.push({ "id": 1, "itemName": "Open" })
            } else if (this.result1.statusId == "2") {
              this.jobCompleteHideDis = false;
              this.ProjectJobForm.enable();
              this.statusArrSelect = [];
              this.statusArrSelect.push({ "id": 2, "itemName": "Inprogress" })
            } else if (this.result1.statusId == "3") {
              this.jobCompleteHideDis = true;
              this.ProjectJobForm.disable();
              this.statusArrSelect = [];
              this.statusArrSelect.push({ "id": 3, "itemName": "Completed" })
            }else if (this.result1.statusId == "4") {   
              this.statusArrSelect = [];
              this.statusArrSelect.push({ "id": 4, "itemName": "Booked" })
            }
          } else {
            this.statusArrSelect = [];
          }
          this.getprojectStatus(this.result1.id);
          if (this.result.dependedJob != null) {
            if (this.result.dependedJob.statusId == "3") {
              this.depjobCompleteHide = true;
            } else {
              this.depjobCompleteHide = false;
            }
            // this.projectDepended.push({
            //   "id": result.dependedJob.id, "itemName": result.dependedJob.name 
            // })        
          } else {
            this.depjobCompleteHide = true;
            this.projectDepended = [];
          }
          if (this.result.subJobTotal != this.result.subJobCompleted) {
            this.SubjobCompleteHide = false;
          } else {
            this.SubjobCompleteHide = true;
          }
          if (this.result1.dependedJobId != null) {
            this.projectArr.forEach(r => {
              for (let j = 0; j < this.result1.dependedJobId.length; j++) {
                if (r.jobid == this.result1.dependedJobId[j]) {
                  this.projectDepended.push({
                    "id": r.jobid, "itemName": r.jobName
                  })
                }
              }
            });
          } else {
            this.ProjectJobForm.patchValue({ 'dependedJobId': this.result.dependedJobId });
          }
          if (this.result.predecessorJob != null) {
            this.projectPredecessor.push({
              "id": this.result.predecessorJob.id, "itemName": this.result.predecessorJob.name
            })
          } else {
            this.projectPredecessor = [];
          }
          if (this.result) {
            if(this.entId == 4 ||this.entId == 5){
              this.ProjectJobForm.disable();
             }else{
                //  this.ProjectJobForm.enable();
             }
            if (this.result1.assignedToId) {
              this.jobCompleteHide = true;
              this.projectAllUsersArrSelect.push({
                "id": this.result2.id, "itemName": this.result2.name
              })
            } else {
              this.jobCompleteHide = false;
            }
            this.projectSupervisorArrSelect=[];
            if(this.result1.supervisorId != null){
              this.supervisorShow = true;
              this.projectSupervisorArrSelect.push({
                "id": result4.id, "itemName": result4.name
              })
            }else{
              this.projectSupervisorArrSelect=[];
              this.supervisorShow = false;
            }
            // if (this.result1.assignedToId) {
            //   this.jobCompleteHide = true;
            //   this.jobArrselect.push({
            //     "id": this.result2.id, "itemName": this.result2.name
            //   })
            // } else {
            //   this.jobCompleteHide = false;
            // }
            // if (result1.isCompleted == "Y") {
            //   this.jobCompleteHideDis = true;
            //   this.ProjectJobForm.disable();
            //   this.ProjectJobForm.patchValue({ 'isCompleted': true });
            // } else {
            //   this.jobCompleteHideDis = false;
            //   this.ProjectJobForm.enable();
            this.ProjectJobForm.patchValue({ 'isCompleted': false });
            //  }
            this.projectStateArr.push({
              "stateName": this.result1.name
            })
            this.ProjectJobForm.patchValue({ 'id': this.result1.id });
            this.ProjectJobForm.patchValue({ 'name': this.result1.name });
            this.ProjectJobForm.patchValue({ 'projectStateId': this.result1.projectStateId });
            this.ProjectJobForm.patchValue({ 'isActive': this.result1.isActive });
            this.ProjectJobForm.patchValue({ 'projectId': this.result1.projectId });
            this.ProjectJobForm.patchValue({ 'createdDate': this.result1.createdDate });
            this.ProjectJobForm.patchValue({ 'createdBy': this.result1.createdBy });
            this.ProjectJobForm.patchValue({ 'createdBy': this.result1.createdBy });
            this.ProjectJobForm.patchValue({ 'updatedBy': this.result1.updatedBy });
            this.ProjectJobForm.patchValue({ 'jobOrder': this.result1.jobOrder });
            this.ProjectJobForm.patchValue({ 'daysDuration': this.result1.daysDuration });
            if (this.result1.notifyMsg != null) {
              this.ProjectJobForm.patchValue({ 'notifyMsg': this.result1.notifyMsg });
            } else {
              this.ProjectJobForm.patchValue({ 'notifyMsg': "" });
            }
            var scdate = this.result1.startDate;
            if (scdate) {
              var ydate = moment(scdate).format("YYYY")
              var mdate = moment(scdate).format("M")
              var ddate = moment(scdate).format("D")
              this.ProjectJobForm.patchValue({
                'startDate': {
                  date: {
                    year: ydate,
                    month: mdate,
                    day: ddate
                  }
                }
              });
            } else {
              this.ProjectJobForm.patchValue({ startDate: null });
            }
              var disableDate1 = new Date(this.result1.startDate);
      this.myDatePickerOptions2 = {
          dateFormat: 'dd-mm-yyyy',
          disableUntil: { year: disableDate1.getFullYear(), month: disableDate1.getMonth() + 1, day: disableDate1.getDate() - 1 }
        }
            var cdate = this.result1.endDate;
            if (cdate) {
              var ydate = moment(cdate).format("YYYY")
              var mdate = moment(cdate).format("M")
              var ddate = moment(cdate).format("D")
              this.ProjectJobForm.patchValue({
                'endDate': {
                  date: {
                    year: ydate,
                    month: mdate,
                    day: ddate
                  }
                }
              });
            } else {
              this.ProjectJobForm.patchValue({ endDate: null });
            }
          }
        }, error => {
          this.toastr.clear();
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.errorService.handleError(error);
        });
        this.projectStateArr = [];
        if (this.menuStateSubJobtEdit == 'in') {
          this.menuStateSubJobtEdit = 'out';
        }
        this.menuStateProjectEdit = this.menuStateProjectEdit === 'out' ? 'in' : 'out';
      }

    } else if (number == 2) {
      this.subJobEditMenu(id)
    }

  }
  toggleCloseProject() {
    if (this.ovrelayPopup1 == true) {
      this.ovrelayPopup1 = false;
    } else {
      this.ovrelayPopup1 = true;
    }
    this.menuStateProjectEdit = this.menuStateProjectEdit === 'out' ? 'in' : 'out';
  }
  updateJob() {
    if (this.jobValidate() && this.jobstatuschanges(this.projestJobArr)) {
      var data = this.ProjectJobForm.value;
      if (this.ProjectJobForm.value["isCompleted"] == true) {
        this.toggleCloseProject();
        this.confirmService.confirm('Please confirm..', 'Do you want to Complete this Job ?')
          .then((confirmed) => {
            if (confirmed) {
              this.spinner.show();
              this.body = {};
              this.body["id"] = this.jobId;
              var dependedJobArr = [];
              if (data.dependedJobId != null) {
                if (data.dependedJobId.length > 0) {
                  for (let i = 0; i < data.dependedJobId.length; i++) {
                    dependedJobArr.push(data.dependedJobId[i].id);
                  }
                  this.body["dependedJobId"] = dependedJobArr;
                } else {
                  this.body["dependedJobId"] = [];
                }
              }
              if (data.predecessorJobId != null) {
                if (data.predecessorJobId.length != 0) {
                  this.body["predecessorJobId"] = data.predecessorJobId[0].id;
                } else {
                  this.body["predecessorJobId"] = null;
                }
              } if (data.statusId.length > 0) {
                this.body["statusId"] = data.statusId[0].id;
              }
              var notifiyArr = [];
              if (data.notifybefore != null) {
                if (data.notifybefore.length > 0) {
                  for (let i = 0; i < data.notifybefore.length; i++) {
                    notifiyArr.push(data.notifybefore[i].id);
                  }
                  this.body["notifybefore"] = notifiyArr;
                } else {
                  this.body["notifybefore"] = [1];
                }
              } else {
                this.body["notifybefore"] = [1];
              }
              this.body["assignedToId"] = this.ProjectJobForm.value["assignedToId"][0].id;
              if(this.supervisorShow ==  true){
                this.body["supervisorId"] = this.ProjectJobForm.value["supervisorId"][0].id;
              }else{
                this.body["supervisorId"] =null;
              }
              this.body["projectStateId"] = this.ProjectJobForm.value["projectStateId"];
              this.body["name"] = this.ProjectJobForm.value["name"];
              this.body["projectId"] = this.ProjectJobForm.value["projectId"];
              this.body["createdDate"] = this.ProjectJobForm.value["createdDate"];
              this.body["jobOrder"] = this.ProjectJobForm.value["jobOrder"];
              this.body["daysDuration"] = this.jobDetails.daysDuration;
              // this.body["notifybefore"] = this.jobDetails.notifybefore;
              this.body["notifyMsg"] = this.ProjectJobForm.value["notifyMsg"];
              this.body["updatedDate"] = new Date();
              this.body["updatedBy"] = parseInt(this.userid);
              this.body["createdBy"] = this.ProjectJobForm.value["createdBy"];
             var date1 = ("0" + (this.ProjectJobForm.value["startDate"].date.day)).slice(-2);
              var month1 = ("0" + (this.ProjectJobForm.value["startDate"].date.month)).slice(-2);
              var date2 = ("0" + (this.ProjectJobForm.value["endDate"].date.day)).slice(-2);
              var month2 = ("0" + (this.ProjectJobForm.value["endDate"].date.month)).slice(-2);
              var startDate = this.ProjectJobForm.value["startDate"].date.year + "-" + month1 + "-" + date1
              this.body["startDate"] = startDate;
              var endDate = this.ProjectJobForm.value["endDate"].date.year + "-" + month2 + "-" + date2
              this.body["endDate"] = endDate;
              this.body["isActive"] = "Y";
              this.body["isCompleted"] = "Y";
              this.http.put('/api/projectJob', this.body).subscribe((response) => {
                this.result = response;
                if (this.result.success == true) {
                  this.resetForms();
                  this.toastr.clear();
                  this.toastr.success(this.result.message, 'Success', {
                    timeOut: 6000,
                    closeButton: true
                  });
                  this.projectArr = [];
                  this.getJobs();
                  this.spinner.hide();
                } else {
                  this.toastr.clear();
                  this.toastr.error(this.result.message, 'Error', {
                    timeOut: 6000,
                    closeButton: true
                  });
                  this.spinner.hide();
                }
              }, error => {
                this.toastr.clear();
                this.toastr.error(error, 'Report Status', {
                  timeOut: 6000,
                  closeButton: true
                });
                this.errorService.handleError(error);
              });
            }
          })
          .catch(() => {
            this.toggleCloseProject();
            return false;
          });
      } else {
        this.spinner.show();
        this.body = {};
        this.body["id"] = this.jobId;
        var dependedJobArr = [];
        if (data.dependedJobId != null) {
          if (data.dependedJobId.length > 0) {
            for (let i = 0; i < data.dependedJobId.length; i++) {
              dependedJobArr.push(data.dependedJobId[i].id);
            }
            this.body["dependedJobId"] = dependedJobArr;
          } else {
            this.body["dependedJobId"] = [];
          }
        }
        if (data.predecessorJobId != null) {
          if (data.predecessorJobId.length != 0) {
            this.body["predecessorJobId"] = data.predecessorJobId[0].id;
          }
        }
        if (data.statusId.length > 0) {
          this.body["statusId"] = data.statusId[0].id;
        }
        var notifiyArr = [];
        if (data.notifybefore != null) {
          if (data.notifybefore.length > 0) {
            for (let i = 0; i < data.notifybefore.length; i++) {
              notifiyArr.push(data.notifybefore[i].id);
            }
            this.body["notifybefore"] = notifiyArr;
          } else {
            this.body["notifybefore"] = [1];
          }
        } else {
          this.body["notifybefore"] = [1];
        }
        this.body["projectStateId"] = this.ProjectJobForm.value["projectStateId"];
        this.body["assignedToId"] = this.ProjectJobForm.value["assignedToId"][0].id;
        if(this.supervisorShow ==  true){
          this.body["supervisorId"] = this.ProjectJobForm.value["supervisorId"][0].id;
        }else{
          this.body["supervisorId"] =null;
        }
        this.body["projectId"] = this.ProjectJobForm.value["projectId"];
        this.body["name"] = this.ProjectJobForm.value["name"];
        this.body["createdDate"] = this.ProjectJobForm.value["createdDate"];
        this.body["jobOrder"] = this.ProjectJobForm.value["jobOrder"];
        this.body["daysDuration"] = this.jobDetails.daysDuration;
         // this.body["notifybefore"] = this.jobDetails.notifybefore;
        this.body["notifyMsg"] = this.ProjectJobForm.value["notifyMsg"];
        this.body["updatedDate"] = new Date();
        this.body["updatedBy"] = parseInt(this.userid);
        this.body["createdBy"] = this.ProjectJobForm.value["createdBy"];
        var date1 = ("0" + (this.ProjectJobForm.value["startDate"].date.day)).slice(-2);
        var month1 = ("0" + (this.ProjectJobForm.value["startDate"].date.month)).slice(-2);
        var date2 = ("0" + (this.ProjectJobForm.value["endDate"].date.day)).slice(-2);
        var month2 = ("0" + (this.ProjectJobForm.value["endDate"].date.month)).slice(-2);
        var startDate = this.ProjectJobForm.value["startDate"].date.year + "-" + month1 + "-" + date1
        this.body["startDate"] = startDate;
        var endDate = this.ProjectJobForm.value["endDate"].date.year + "-" + month2 + "-" + date2
        this.body["endDate"] = endDate;
        this.body["isActive"] = "Y";
        this.body["isCompleted"] = "N";
        this.http.put('/api/projectJob', this.body).subscribe((response) => {
          this.result = response;
          if (this.result.success == true) {
            this.resetForms();
            this.toastr.clear();
            this.toastr.success(this.result.message, 'Success', {
              timeOut: 6000,
              closeButton: true
            });
            this.projectArr = [];
            this.toggleCloseProject();
            this.getJobs();
            this.spinner.hide();
          } else {
            this.toastr.clear();
            this.toastr.error(this.result.message, 'Error', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
          }
        }, error => {
          this.toastr.clear();
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.errorService.handleError(error);
        });
      }
    }
  }


  jobValidate() {
    this.nameErr = null;
    this.custErr = null;
    this.StartdateErr = null;
    this.dateErr = null;
    this.detailsErr = null;
    this.statusErr = null;
    this.jobStatusErr = null;
    this.supervisorErr =null;
    var valid = true;
    var data = this.ProjectJobForm.value;
    if (data.statusId.length == 0) {
      this.jobStatusErr = "Status is required";
      valid = false;
    }
    if (data.assignedToId.length == 0) {
      this.custErr = "User is required";
      valid = false;
    }
    if(this.supervisorShow ==  true){
      if (data.supervisorId.length == 0) {
     this.supervisorErr = "Supervisor is required";
     valid = false;
   }
  }  if (data.dependedJobId) {
      for (let i = 0; i < data.dependedJobId.length; i++) {
        if (data.predecessorJobId.length > 0) {
          if (data.dependedJobId[i].id == data.predecessorJobId[0].id) {
            this.statusErr = "Dependent and Predecessor Should be different.";
            valid = false;
          }
        }
      }
    }  if (data.predecessorJobId) {
      for (let i = 0; i < data.dependedJobId.length; i++) {
        if (data.dependedJobId) {
          if (data.predecessorJobId.length > 0) {
          if (data.dependedJobId[i].id == data.predecessorJobId[0].id) {
            this.statusErr = "Dependent and Predecessor Should be different.";
            valid = false;
          }
        }
      }
      }
    }

    if (!data.startDate) {
      this.StartdateErr = "Start Date is required";
      valid = false;
    }
     if(data.startDate){
      this.myDatePickerOptions2={};
      var date1 = ("0" + (data.startDate.date.day)).slice(-2);
      var month1 = ("0" + (data.startDate.date.month)).slice(-2);
       this.myDatePickerOptions2 = {
          dateFormat: 'dd-mm-yyyy',
          disableUntil: { year: data.startDate.date.year, month: month1, day: date1 }
        }
    }
    if (!data.endDate || data.endDate == null) {
      this.dateErr = " End Date is required";
      valid = false;
    } 
    
    if (data.startDate && data.endDate) {
      var date1 = ("0" + (data.startDate.date.day)).slice(-2);
      var month1 = ("0" + (data.startDate.date.month)).slice(-2);
      var date2 = ("0" + (data.endDate.date.day)).slice(-2);
      var month2 = ("0" + (data.endDate.date.month)).slice(-2);
      var startDate = data.startDate.date.year + "-" + month1 + "-" + date1
      var endDate = data.endDate.date.year + "-" + month2 + "-" + date2
      if (startDate > endDate) {
        if (startDate == endDate) {
          this.dateErr = null;
        } else {
          this.dateErr = "Closing Date must be greater than Start Date";
          valid = false;
        }
      }

    }

    return valid;
  }


  toggleAddSubJob(parentId) {
    if (this.ovrelayPopup2 == true) {
      this.ovrelayPopup2 = false;
    } else {
      this.ovrelayPopup2 = true;
    }
    this.resetForms();
    this.jobCompleteHide = true;
    this.jobCompleteSubHide = false;
    this.nameSubErr = null;
    this.StartsubdateErr = null;
    this.endDateSubErr = null;
    this.custSubErr = null;
    this.isSubCreate = false;
    this.subprojectStateArr = [];
    this.subJobArrselect = [];
    this.parentId = parentId;
    this.subJobForm.enable();

    this.http.get('api/projectJob/' + this.projectId + "/" + parentId).subscribe((res: Response) => {
      this.result = res;
      var result1=this.result.job;
      var result3 = this.result.state;
      var disableDate1 = new Date(result1.startDate);
      this.myDatePickerOptionsSub = {
          dateFormat: 'dd-mm-yyyy',
          disableUntil: { year: disableDate1.getFullYear(), month: disableDate1.getMonth() + 1, day: disableDate1.getDate() - 1 }
        }
      this.subprojectStateArr.push({
        "stateName": result3.name
      })
    });
    if (this.menuStateProjectEdit == 'in') {
      this.menuStateProjectEdit = 'out';
    }
    this.http.get('/api/projectInfoStages/maxSubJobOrder/' + parentId).subscribe((response) => {
      this.resultOrder = response;
      this.menuStateSubJobtEdit = this.menuStateSubJobtEdit === 'out' ? 'in' : 'out';
    });
  }

  closeToggleAddSubJob() {
    if (this.ovrelayPopup2 == true) {
      this.ovrelayPopup2 = false;
    } else {
      this.ovrelayPopup2 = true;
    }
    this.ovrelayPopup1 = false;
    this.menuStateSubJobtEdit = this.menuStateSubJobtEdit === 'out' ? 'in' : 'out';

  }
  submitSubJob() {
    if (this.subJobValidate()) {
      this.spinner.show();
      var data = this.subJobForm.value;
      if (data.assignedToId != null) {
        if (data.assignedToId.length != 0) {
          data.assignedToId = data.assignedToId[0].id;
        }
      }
      if (this.supervisorShow == true) {
        if (data.supervisorId.length != 0) {
          data.supervisorId = data.supervisorId[0].id;
        }
      }else{
        data.supervisorId = null;
      }
      if (data.statusId.length > 0) {
        data.statusId = data.statusId[0].id;
      }
      data.createdBy = this.userid;
      data.projectId = this.projectId;
      data.parentJobId = this.parentId;
      var notifiyArr = [];
      if (data.notifybefore != null) {
        if (data.notifybefore.length > 0) {
          for (let i = 0; i < data.notifybefore.length; i++) {
            notifiyArr.push(data.notifybefore[i].id);
          }
          data.notifybefore = notifiyArr;
        } else {
          data.notifybefore = [1];
        }
      } else {
        data.notifybefore = [1];
      }
      if (data.startDate != null) {
        var dateval = data.startDate.jsdate;
        data.startDate = moment(dateval).format("YYYY-MM-DD")
      }
      data.jobOrder = (this.resultOrder.maxOrder) + 1;
      this.http.post('api/projectInfoStages/subJob', data).subscribe((response) => {
        this.result = response;
        if (this.result.success == true) {
          this.toastr.clear();
          this.toastr.success(this.result.message, 'Success', {
            timeOut: 6000,
            closeButton: true
          });
          this.closeToggleAddSubJob();
          this.resetForms();
          this.projectArr = [];
          this.getJobs();
          // this.subJobDetailsShow(this.parentId);   
        } else {
          this.toastr.clear();
          this.toastr.error(this.result.message, 'Error', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
    }
  }

  subJobEditMenu(subJobId) {
    if (this.ovrelayPopup2 == true) {
      this.ovrelayPopup2 = false;
    } else {
      this.ovrelayPopup2 = true;
    }
    this.myDatePickerOptionsSub={};
    this.subJobId = subJobId;
    this.subJobArrselect = [];
    this.projectStateArr = [];
    this.subJobNameTitle = [];
    this.nameSubErr = null;
    this.StartsubdateErr = null;
    this.endDateSubErr = null;
    this.custSubErr = null;
    this.isSubCreate = true;
    this.http.get('api/projectJob/' + this.projectId + "/" + subJobId).subscribe((res: Response) => {
      this.result = res;
      this.result1 = this.result.job;
      this.result2 = this.result.assigned;
      this.result3 = this.result.parentJob;
      var result4 = this.result.supervisor;
      var disableDate1 = new Date(this.result3.startDate);
      this.myDatePickerOptionsSub = {
          dateFormat: 'dd-mm-yyyy',
          disableUntil: { year: disableDate1.getFullYear(), month: disableDate1.getMonth() + 1, day: disableDate1.getDate() - 1 }
        }   
      this.subJobNameTitle = this.result1.name;
      if (this.result1.notifybefore.length > 0) {
        this.noftificationSubArrSelect = [];
        for (let i = 0; i < this.result1.notifybefore.length; i++) {
          if (this.result1.notifybefore[i] == "1") {
            this.noftificationSubArrSelect.push({ "id": 1, "itemName": "3 Days before" })
          } else if (this.result1.notifybefore[i] == "2") {
            this.noftificationSubArrSelect.push({ "id": 2, "itemName": "7 Days Before" })
          } else if (this.result1.notifybefore[i] == "3") {
            this.noftificationSubArrSelect.push({ "id": 3, "itemName": "15 Days Before" })
          } else if (this.result1.notifybefore[i] == "4") {
            this.noftificationSubArrSelect.push({ "id": 4, "itemName": "30 Days Before" })
          }
        }
      } else {
        this.noftificationSubArrSelect = [];
      }
      if (this.result1.statusId != null) {
        if (this.result1.statusId == "1") {
          this.jobCompleteHide = true;
          this.jobCompleteSubHide = false;
          this.subJobForm.enable();
          this.statusArrSelect = [];
          this.statusArrSelect.push({ "id": 1, "itemName": "Open" })
        } else if (this.result1.statusId == "2") {
          this.jobCompleteHide = true;
          this.jobCompleteSubHide = false;
          this.subJobForm.enable();
          this.statusArrSelect = [];
          this.statusArrSelect.push({ "id": 2, "itemName": "Inprogress" })
        } else if (this.result1.statusId == "3") {
          this.jobCompleteHide = false;
          this.jobCompleteSubHide = true;
          this.subJobForm.disable();
          this.statusArrSelect = [];
          this.statusArrSelect.push({ "id": 3, "itemName": "Completed" })
        }else if (this.result1.statusId == "4") {   
          this.statusArrSelect = [];
          this.statusArrSelect.push({ "id": 4, "itemName": "Booked" })
        }
      } else {
        this.statusArrSelect = [];
      }
      if (this.result) {
        // if (this.result1.assignedToId) {
        //   // this.jobCompleteHide = true;
        //   this.subJobArrselect.push({
        //     "id": this.result2.id, "itemName": this.result2.name
        //   })
        // } else {
        //   // this.jobCompleteHide = false;
        // }
        // if (result1.isCompleted == "Y") {
        //   this.jobCompleteSubHide = true;
        //   this.subJobForm.disable();
        //   this.subJobForm.patchValue({ 'isCompleted': true });
        // } else {
          if (this.result1.assignedToId) {
            // this.jobCompleteHide = true;
            this.subJobArrselect.push({
              "id": this.result2.id, "itemName": this.result2.name
            })
          } else {
            // this.jobCompleteHide = false;
          }
          if(this.result1.supervisorId != null){
            this.supervisorShow = true;
            this.projectSupervisorArrSelect.push({
              "id": result4.id, "itemName": result4.name
            })
          }else{
            this.projectSupervisorArrSelect=[];
            this.supervisorShow = false;
          }  
        this.subJobForm.patchValue({ 'isCompleted': false });
        // }         
        this.subJobForm.patchValue({ 'id': this.result1.id });
        this.subJobForm.patchValue({ 'name': this.result1.name });
        this.subJobForm.patchValue({ 'parentJobId': this.result.parentJob.id });
        this.subJobForm.patchValue({ 'projectStateId': this.result1.projectStateId });
        this.subJobForm.patchValue({ 'isActive': this.result1.isActive });
        this.subJobForm.patchValue({ 'projectId': this.result1.projectId });
        this.subJobForm.patchValue({ 'createdDate': this.result1.createdDate });
        this.subJobForm.patchValue({ 'createdBy': this.result1.createdBy });
        this.subJobForm.patchValue({ 'updatedBy': this.result1.updatedBy });
        this.subJobForm.patchValue({ 'jobOrder': this.result1.jobOrder });
        this.subJobForm.patchValue({ 'predecessorJobId': this.result1.predecessorJob });
        this.subJobForm.patchValue({ 'dependedJobId': this.result1.dependedJob });
        this.subJobForm.patchValue({ 'daysDuration': this.result1.daysDuration });
        if (this.result1.notifyMsg != null) {
          this.subJobForm.patchValue({ 'notifyMsg': this.result1.notifyMsg });
        } else {
          this.subJobForm.patchValue({ 'notifyMsg': "" });
        }

        var scdate = this.result1.startDate;
        if (scdate) {
          var ydate = moment(scdate).format("YYYY")
          var mdate = moment(scdate).format("M")
          var ddate = moment(scdate).format("D")
          this.subJobForm.patchValue({
            'startDate': {
              date: {
                year: ydate,
                month: mdate,
                day: ddate
              }
            }
          });
        } else {
          this.subJobForm.patchValue({ startDate: null });
        }
        var cdate = this.result1.endDate;
        if (cdate) {
          var ydate = moment(cdate).format("YYYY")
          var mdate = moment(cdate).format("M")
          var ddate = moment(cdate).format("D")
          this.subJobForm.patchValue({
            'endDate': {
              date: {
                year: ydate,
                month: mdate,
                day: ddate
              }
            }
          });
        } else {
          this.subJobForm.patchValue({ endDate: null });
        }

      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
    if (this.menuStateProjectEdit == 'in') {
      this.menuStateProjectEdit = 'out';
    }
    this.menuStateSubJobtEdit = this.menuStateSubJobtEdit === 'out' ? 'in' : 'out';

  }
  updateSubJob() {
    if (this.subJobValidate()) {
      var data = this.subJobForm.value;
      if (this.subJobForm.value["isCompleted"] == true) {
        this.closeToggleAddSubJob();
        this.confirmService.confirm('Please confirm..', 'Do you want to Complete this Job ?')
          .then((confirmed) => {
            if (confirmed) {
              this.spinner.show();
              this.body = {};
              this.body["id"] = this.subJobId;
              if(this.supervisorShow ==  true){
                this.body["supervisorId"] = this.ProjectJobForm.value["supervisorId"][0].id;
              }else{
                this.body["supervisorId"] =null;
              }
              this.body["name"] = this.subJobForm.value["name"];
              this.body["assignedToId"] = this.subJobForm.value["assignedToId"][0].id;
              this.body["statusId"] = this.subJobForm.value["statusId"][0].id;
              this.body["daysDuration"] = this.subJobForm.value["daysDuration"];
              this.body["projectStateId"] = this.subJobForm.value["projectStateId"];
              this.body["parentJobId"] = this.subJobForm.value["parentJobId"];
              this.body["projectId"] = this.subJobForm.value["projectId"];
              this.body["createdDate"] = this.subJobForm.value["createdDate"];
              this.body["jobOrder"] = this.subJobForm.value["jobOrder"];
              this.body["updatedDate"] = new Date();
              this.body["updatedBy"] = parseInt(this.userid);
              this.body["createdBy"] = this.subJobForm.value["createdBy"];
              this.body["notifyMsg"] = this.subJobForm.value["notifyMsg"];
              var date1 = ("0" + (this.subJobForm.value["startDate"].date.day)).slice(-2);
              var month1 = ("0" + (this.subJobForm.value["startDate"].date.month)).slice(-2);
              var date2 = ("0" + (this.subJobForm.value["endDate"].date.day)).slice(-2);
              var month2 = ("0" + (this.subJobForm.value["endDate"].date.month)).slice(-2);
              var startDate = this.subJobForm.value["startDate"].date.year + "-" + month1 + "-" + date1
              this.body["startDate"] = startDate;
              var strtDate1 = new Date(startDate);
              var expdate = strtDate1.setDate(strtDate1.getDate() + parseInt(this.body["daysDuration"]));
              var pickUpDate = new Date(expdate);
              this.body["endDate"] = moment(pickUpDate).format("YYYY-MM-DD")
              // var endDate = this.subJobForm.value["endDate"].date.year + "-" + month2 + "-" + date2
              // this.body["endDate"] = endDate;
              var notifiyArr = [];
              if (data.notifybefore != null) {
                if (data.notifybefore.length > 0) {
                  for (let i = 0; i < data.notifybefore.length; i++) {
                    notifiyArr.push(data.notifybefore[i].id);
                  }
                  this.body["notifybefore"] = notifiyArr;
                } else {
                  this.body["notifybefore"] = [1];
                }
              } else {
                this.body["notifybefore"] = [1];
              }
              this.body["isActive"] = "Y";
              this.body["isCompleted"] = "Y";
              this.http.put('/api/projectJob', this.body).subscribe((response) => {
                this.result = response;
                if (this.result.success == true) {
                  this.resetForms();
                  this.toastr.clear();
                  this.toastr.success(this.result.message, 'Success', {
                    timeOut: 6000,
                    closeButton: true
                  });
                  this.projectArr = [];
                  this.getJobs();
                  this.subJobDetailsShow(this.result.response.parentJobId);
                } else {
                  this.toastr.clear();
                  this.toastr.error(this.result.message, 'Error', {
                    timeOut: 6000,
                    closeButton: true
                  });
                  this.spinner.hide();
                }
              }, error => {
                this.toastr.clear();
                this.toastr.error(error, 'Report Status', {
                  timeOut: 6000,
                  closeButton: true
                });
                this.errorService.handleError(error);
              });

            }
          })
          .catch(() => {
            this.closeToggleAddSubJob();
            return false;
          });
      } else {
        this.spinner.show();
        this.body = {};
        this.body["id"] = this.subJobId;
        if(this.supervisorShow ==  true){
          this.body["supervisorId"] = this.ProjectJobForm.value["supervisorId"][0].id;
        }else{
          this.body["supervisorId"] =null;
        }
        this.body["name"] = this.subJobForm.value["name"];
        this.body["assignedToId"] = this.subJobForm.value["assignedToId"][0].id;
        this.body["projectStateId"] = this.subJobForm.value["projectStateId"];
        this.body["parentJobId"] = this.subJobForm.value["parentJobId"];
        this.body["projectId"] = this.subJobForm.value["projectId"];
        this.body["createdDate"] = this.subJobForm.value["createdDate"];
        this.body["jobOrder"] = this.subJobForm.value["jobOrder"];
        this.body["updatedDate"] = new Date();
        this.body["updatedBy"] = parseInt(this.userid);
        this.body["createdBy"] = this.subJobForm.value["createdBy"];
        this.body["statusId"] = this.subJobForm.value["statusId"][0].id;
        this.body["notifyMsg"] = this.subJobForm.value["notifyMsg"];
        this.body["daysDuration"] = this.subJobForm.value["daysDuration"];
        var date1 = ("0" + (this.subJobForm.value["startDate"].date.day)).slice(-2);
        var month1 = ("0" + (this.subJobForm.value["startDate"].date.month)).slice(-2);
        // var date2 = ("0" + (this.subJobForm.value["endDate"].date.day)).slice(-2);
        // var month2 = ("0" + (this.subJobForm.value["endDate"].date.month)).slice(-2);
        var startDate = this.subJobForm.value["startDate"].date.year + "-" + month1 + "-" + date1
        this.body["startDate"] = startDate;
        var strtDate1 = new Date(startDate);
        var expdate = strtDate1.setDate(strtDate1.getDate() + parseInt(this.body["daysDuration"]));
        var pickUpDate = new Date(expdate);
        this.body["endDate"] = moment(pickUpDate).format("YYYY-MM-DD")
        // var endDate = this.subJobForm.value["endDate"].date.year + "-" + month2 + "-" + date2
        // this.body["endDate"] = endDate;
        this.body["isActive"] = "Y";
        this.body["isCompleted"] = "N";
        var notifiyArr = [];
        if (data.notifybefore != null) {
          if (data.notifybefore.length > 0) {
            for (let i = 0; i < data.notifybefore.length; i++) {
              notifiyArr.push(data.notifybefore[i].id);
            }
            this.body["notifybefore"] = notifiyArr;
          } else {
            this.body["notifybefore"] = [1];
          }
        } else {
          this.body["notifybefore"] = [1];
        }
        this.http.put('/api/projectJob', this.body).subscribe((response) => {
          this.result = response;
          if (this.result.success == true) {
            this.resetForms();
            this.toastr.clear();
            this.toastr.success(this.result.message, 'Success', {
              timeOut: 6000,
              closeButton: true
            });
            this.closeToggleAddSubJob();
            this.projectArr = [];
            this.getJobs();
            // this.subJobDetailsShow(this.parentId);
          } else {
            this.toastr.clear();
            this.toastr.error(this.result.message, 'Error', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
          }
        }, error => {
          this.toastr.clear();
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.errorService.handleError(error);
        });
      }
    }


  }

  subJobValidate() {   
    this.nameSubErr = null;
    this.StartsubdateErr = null;
    this.endDateSubErr = null;
    this.custSubErr = null;
    this.openStatusErr = null;
    this.daysDurationErr = null;
    this.supervisorErr =null;
    var valid = true;
    var data = this.subJobForm.value;
    if (!data.name) {
      this.nameSubErr = "Name required";
      valid = false;
    } else if (data.assignedToId.length == 0) {
      this.custSubErr = "User is required";
      valid = false;
    } else if (data.daysDuration == undefined || data.daysDuration == '') {
      this.daysDurationErr = "Days Duration is required.";
      valid = false;
    } else if (data.daysDuration == 0) {
      this.daysDurationErr = "Invalid Days.";
      valid = false;
    }
    if (data.statusId.length == 0) {
      this.openStatusErr = "Status is required";
      valid = false;
    }else if (!data.startDate || data.startDate == null) {
      this.StartsubdateErr = "Start Date is required";
      valid = false;
    } if(this.supervisorShow ==  true){
      if (data.supervisorId.length == 0) {
     this.supervisorErr = "Supervisor is required";
     valid = false;
   }
  } 
    return valid;    
  }

  subJobDetailsShow(id) {
    if (this.subJobAllDetailsShow == true) {
      this.subJobAllDetailsShow = false;
    } else {
      this.subJobAllDetailsShow = true;
    }
    this.subJobDetailsArr = [];
    this.parentId = id;
    this.http.get('api/projectJob/' + this.projectId + "/" + id).subscribe((res: Response) => {
      this.result = res;
      this.result1 = this.result.subJobs;
      for (let i = 0; i < this.result1.length; i++) {
        this.subJobDetailsArr.push({
          "parentId": this.result.job.id,
          "subJobName": this.result1[i].subJob.name,
          "subJobIsCompleted": this.result1[i].subJob.isCompleted,
          "subJobId": this.result1[i].subJob.id,
          "startDate": this.result1[i].subJob.startDate,
          "endDate": this.result1[i].subJob.endDate,
          "subJobAssoName": this.result1[i].subJobAsso.name,
          "subJobAssoId": this.result1[i].subJobAsso.id,
        })
      }
    });
  }




  deleteSubJob(id) {
    this.closeToggleAddSubJob();
    this.confirmService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) => {
        if (confirmed) {
          this.spinner.show();
          this.http.delete('/api/projectJob/' + id).subscribe((response) => {
            this.toastr.clear();
            this.result = response;
            if (this.result.success) {
              this.resetForms();
              this.toastr.success(this.result.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.projectArr = [];
              this.getJobs();
              // this.subJobDetailsShow(this.parentId);  
            } else {
              this.toastr.error(this.result.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
            }
          }, error => {
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
            this.errorService.handleError(error);
          })
        } else {
        }
      })
      .catch(() => {
        this.closeToggleAddSubJob();
        return false;
      });

  }
  updateNotification() {
    this.body = {};
    var data = this.ProjectJobForm.value;
    this.body["id"] = this.jobId;
    var notifiyArr = [];
    if (data.notifybefore != null) {
      if (data.notifybefore.length > 0) {
        for (let i = 0; i < data.notifybefore.length; i++) {
          notifiyArr.push(data.notifybefore[i].id);
        }
        this.body["notifybefore"] = notifiyArr;
      } else {
        this.body["notifybefore"] = [1];
      }
    } else {
      this.body["notifybefore"] = [1];
    }
    this.body["notifyMsg"] = data.notifyMsg;
    this.http.put('/api/projectJob/notifyUpdate', this.body).subscribe((response) => {
      this.result = response;
      if (this.result.success == true) {
        this.resetForms();
        this.toastr.clear();
        this.toastr.success(this.result.message, 'Success', {
          timeOut: 6000,
          closeButton: true
        });
        this.projectArr = [];
        this.toggleCloseProject();
        this.getJobs();
        this.spinner.hide();
      } else {
        this.toastr.clear();
        this.toastr.error(this.result.message, 'Error', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
    });
  }
  getallUserAndExternalUser(id) {
    this.projectAllUsersArr =[];
    this.projectUsersArr = [];
    this.http.get('/api/projectJob/jobAssignedToExt/' + id).subscribe((res: Response) => {
      this.externalinternalResult = res;
      var result = this.externalinternalResult;
      for (let i = 0; i < result.internaluser.length; i++) {
        this.projectUsersArr.push({
          "id": result.internaluser[i].id, "itemName": result.internaluser[i].name
        })
        this.projectAllUsersArr.push({
          "id": result.internaluser[i].id, "itemName": result.internaluser[i].name, "type": "User"
        })
      }
      for (let i = 0; i < result.externalUser.length; i++) {
        this.projectAllUsersArr.push({
          "id": result.externalUser[i].id, "itemName": result.externalUser[i].name, "type": "External User"
        })
      }
    });
  }
  jobUserExternalSelect(item,num){
    if(num ==0){
      if(item.type == "External User"){
        this.supervisorShow = true;
      }else{
        this.supervisorShow = false;
      }
    }else{
      this.supervisorShow = false;
      this.supervisorErr=null;
    }   
  }
  ngGantClick(data){
  }

}

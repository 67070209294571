import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { NgForm, EmailValidator } from '@angular/forms';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '../../_services';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { ErrorService } from 'src/app/_services/error.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DetailsService } from '../../_services';
import { trigger, state, animate, transition, style } from '@angular/animations';
@Component({
  selector: 'app-certifier',
  templateUrl: './certifier.component.html',
  styleUrls: ['./certifier.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class CertifierComponent implements OnInit {
  certifierForm: FormGroup;
  passwordForm: FormGroup;
  isCreate: boolean = true;
  popoverform: boolean = false;
  isSiteAdmin: boolean = false;
  certifierArr = [];
  selectedItems =[];
  dropdownList =[];
  result:any;
  result1 :any;
  certifiersCount :any;
  menuStateCertifier: string = 'out';
  admin: any;
  genderErr: any;
  fnameErr: any;
  lnameErr: any;
  certifiemailErr: any;
  phoneErr: any;
  filter: any;
  reverse: any;
  p: any;
  passwordErr: any;
  resetpasswordErr: any;
  confirmpasswordErr: any;
  userid :any;
  certifierID:any;
  settingssingle :any;
  passreset: boolean = false;
  key:any;
  term:any;

  constructor(private http: HttpClient, private router: Router, private DetailsService: DetailsService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService, private toastr: ToastrService,
    private errorService: ErrorService,
    private confirmService: ConfirmService,
    private modalService: NgbModal) {
    this.createForm();
  }

  ngOnInit() {
    this.getCertifier();
    this.userid = localStorage.getItem("loggedInUserID");
    this.admin = localStorage.getItem("loggedInUserAdmin");
    if(this.admin == 'Y'){
      this.isSiteAdmin = true;
    }else{
      this.isSiteAdmin = false;
    }
    this.dropdownList = [
      { "id": 1, "itemName": "Male" },
      { "id": 2, "itemName": "Female" },
      { "id": 3, "itemName": "Others" }
      
    ];

    this.settingssingle = {
      text: "Select",
      classes: "myclass custom-class",
      singleSelection: true,
      enableCheckAll: false
    };
  }
   

  createForm(){
    this.certifierForm = this.formBuilder.group({
      domainId :'',
      id: null,
      firstName: '',
      lastName: '',
      email: '',
      phone: null,
      gender: '',
      isActive: '',
      createdBy: '',
      createdDate: '',
      updatedBy: ''
    });
    this.passwordForm = this.formBuilder.group({
      password: '',
      confirmPassword: ''
    })
  }
   resetForm() {
    this.certifierForm.reset({
      domainId :'', 
      id: null,
      firstName: '',
      lastName:'',
      email: '',
      phone: null,
      isActive: '',
      createdBy: '',
      gender: [],
      createdDate: '',
      updatedBy: '',
    });   
  }
   passwordresetForm() {
    this.resetpasswordErr = null;
    this.confirmpasswordErr = null;
    this.passwordForm.reset({
       password: '',
       confirmPassword: '',
     })
   }
   passwordValidate() {
    this.resetpasswordErr = null;
    this.confirmpasswordErr = null;
    var data = this.passwordForm.value;
    const strongRegex = /(?=.{6,})/;
    var valid = true;
    if (data.password == Validators || data.password == '') {
      this.resetpasswordErr = "Password is required.";
      valid = false;
    } else if (!strongRegex.test(data.password)) {
      this.resetpasswordErr = "Password is Must be 6 Charcters.";
      valid = false;
    } else if (data.password != data.confirmPassword) {
      this.confirmpasswordErr = "Password doesn't Match.";
      valid = false;
    }
    return valid;
  }

  getCertifier() {
    this.spinner.show();
    this.certifierArr = [];
    this.http.get('/api/certifiers/').subscribe((res: Response) => {
      this.result = res;
      this.certifiersCount = this.result.length;
      this.spinner.hide();
      for (let i = 0; i < this.result.length; i++) {
        this.certifierArr.push({
          "id": this.result[i].id, "firstName": this.result[i].firstName, "lastName": this.result[i].lastName, "email": this.result[i].email, "phone": this.result[i].phone
        })
      }
    });
  }
  toggleCertifierMenu() {
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.fnameErr = null;
    this.lnameErr = null;
    this.certifiemailErr = null;
    this.phoneErr = null;
    this.isCreate = true;
    this.selectedItems=[];
    this.genderErr = null;
    this.passreset=false
    this.resetForm();
    this.menuStateCertifier = this.menuStateCertifier === 'out' ? 'in' : 'out';
  }
  submitCertifier() {
    var data = this.certifierForm.value;
    data.createdBy = this.userid;
    if(data.phone == "" || data.phone == null){
      data.phone = null;
    }else{
      data.phone = data.phone;
    }
    console.log(data);
    if (this.certifierValidate()) {
      this.spinner.show();
      data.gender = data.gender[0].itemName;
      this.http.post('/api/certifier/', data).subscribe((response) => {
        this.toastr.clear();
        this.result = response;
        if (this.result.success) {
          this.resetForm();
          this.toastr.success(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.toggleCertifierMenu();
          this.getCertifier();
        } else {
          this.toastr.error(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }

      }, error => {
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
        this.errorService.handleError(error);
      })
    }

  }
  certifierEditMenu(id) {
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.fnameErr = null;
    this.lnameErr = null;
    this.genderErr = null;
    this.certifiemailErr = null;
    this.phoneErr = null;
    this.isCreate = false;
    this.certifierID = id;
    this.http.get('/api/certifier/' + this.certifierID).subscribe((res: Response) => {
      this.result = res;
      if (this.result) {
        this.certifierForm.patchValue({ 'id': this.result.id });
        this.certifierForm.patchValue({ 'firstName': this.result.firstName });
        this.certifierForm.patchValue({ 'lastName': this.result.lastName });
        this.certifierForm.patchValue({ 'email': this.result.email });
        this.certifierForm.patchValue({ 'phone': this.result.phone });
        this.certifierForm.patchValue({ 'createdBy': this.result.createdBy });
        this.certifierForm.patchValue({ 'isActive': this.result.isActive });
        this.certifierForm.patchValue({ 'createdDate': this.result.createdDate });
        this.certifierForm.patchValue({ 'domainId': this.result.domainId });
        if (this.result.gender == "Male") {
          this.selectedItems = [];
          this.selectedItems.push({ "id": 1, "itemName": "Male" })
        } else if (this.result.gender == "Female") {
          this.selectedItems = [];
          this.selectedItems.push({ "id": 2, "itemName": "Female" })
        }
        else if (this.result.details.gender == "Others") {
          this.selectedItems = [];
          this.selectedItems.push({ "id": 3, "itemName": "Others" })
        }
      }
    });
    this.menuStateCertifier = this.menuStateCertifier === 'out' ? 'in' : 'out';
  }
  updateCertifier() {
    var data = this.certifierForm.value;
    data.updatedBy = this.userid;
    if (this.certifierValidate()) {
      this.spinner.show();
      data.gender = data.gender[0].itemName;
      this.http.put('/api/certifier/', data).subscribe((response) => {
        this.toastr.clear();
        this.result = response;
        if (this.result.success) {
          this.resetForm();
          this.toastr.success(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.toggleCertifierMenu();
          this.getCertifier();
          this.popoverform=false;
        } else {

          this.toastr.error(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }

      }, error => {
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
        this.errorService.handleError(error);
      })
    }
  }
  certifierDelete(id) {
    this.menuStateCertifier = this.menuStateCertifier === 'out' ? 'in' : 'out';
    this.confirmService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) => {
        if (confirmed) {
          this.spinner.show();
          this.http.delete('/api/certifier/' + id).subscribe((response) => {
            this.toastr.clear();
            this.result = response;
            if (this.result.success) {
              this.resetForm();
              this.toastr.success(this.result.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.getCertifier();
              // this.toggleCertifierMenu();
              this.popoverform=false;
            } else {
              this.toastr.error(this.result.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
            }
          }, error => {
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
            this.errorService.handleError(error);
          })
        } else {
        }
      })
      .catch(() => {
        this.menuStateCertifier = this.menuStateCertifier === 'out' ? 'in' : 'out';
        return false;
      });
  }
  certifierValidate() {
    this.fnameErr = null;
    this.lnameErr = null;
    this.genderErr = null;
    this.certifiemailErr = null;
    this.phoneErr = null;
    var data = this.certifierForm.value;
    const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const validNumber = /^\d+$/;
    const strongRegex = /(?=.{6,})/;
    var valid = true;
    if (data.firstName == undefined || data.firstName == '') {
      this.fnameErr = "First Name is required.";
      valid = false;
    }
    else if (data.lastName == undefined || data.lastName == '') {
      this.lnameErr = "Last Name is required.";
      valid = false;
    }
    else if (data.email == EmailValidator || data.email == '') {
      this.certifiemailErr = "Email is required.";
      valid = false;
    } else if (!validEmailRegEx.test(data.email)) {
      this.certifiemailErr = "Invalid Email";
      valid = false;
    }else if (data.gender.length == 0) {
      this.genderErr = "Gender is Required.";
      valid = false;
    }
    if(data.phone){
      if (data.phone != '' && !validNumber.test(data.phone)) {
       this.phoneErr = "Invalid Phone Number.";
       valid = false;
     }
   }
  //  else if(data.phone != '' && !validNumber.test(data.phone)) {
  //     this.phoneErr = "Invalid Phone Number";
  //     valid = false;
  //   }
    return valid;
  }
  restpasslist: boolean = false;
  restpass(){
    if (this.passreset == true) {
      this.passreset = false;
    } else {
      this.passreset = true;
    }
  }
  passwordPost(certifierID) {
    if (this.passwordValidate()) {
      this.spinner.show();
      this.http.get('/api/certifier/' +certifierID).subscribe((res: Response) => {
        this.result = res;
        var data = this.result;
        var data1 = this.passwordForm.value;
        data.password = data1.confirmPassword;
        data.updatedBy = parseInt(this.userid);
        this.http.put('/api/certifier/' , data).subscribe((response) => {
          this.toastr.clear();
          this.result1 = response;
          if (this.result1.success) {
            this.certifierID =this.result1.response.id
            this.passwordresetForm();
            this.toastr.success('Password Updated successfully', 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.restpass();
            this.spinner.hide();
          } else {
            this.toastr.error(this.result1.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
          }
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })
      })
    }
  }
}

import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';

import 'rxjs/add/observable/of';
@Injectable()

export class EventSesrvice {
    peopleEventArr =[];
    userid:any;    
    private eventUrl = '/api/calendar/people';
  
    
    constructor(private http: HttpClient) { }
    public getEvents() {
        this.userid = localStorage.getItem("loggedInUserID");
        return this.http.get('/api/calendar/people/'+this.userid);
    }
    public getPeopleListEvents() {
        this.userid = localStorage.getItem("loggedInUserID");
        return this.http.get('/api/calendar/people/list/'+this.userid);
    }
};

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, animate, transition, style } from '@angular/animations';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PermissionService } from 'src/app/_services/permission.service';
import { ErrorService } from 'src/app/_services/error.service';
import { each } from 'highcharts';
import * as moment from 'moment/moment.js';
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]

})
export class HeaderComponent implements OnInit {
  ProjectName: string;
  passwordForm: FormGroup;
  domainForm: FormGroup;
  serachForm: FormGroup;
  username: any;
  userid: any;
  logType: any;
  userlogin: any;
  resetlogin: boolean = false;
  notifyList: boolean = false;
  NavInActive: boolean = false;
  NavOutActive: boolean = true;
  passwordMenuState: string = 'out';
  profileViewState: string = 'out';
  notificationMenuState: string = 'out';
  searchMenuState: string = 'out';
  resetpasswordErr: any;
  confirmpasswordErr: any;
  isCreate: boolean = true;
  result: any;
  admin: any;
  isAdminDis: boolean = false;
  isDeal: boolean = false;
  isApproval: boolean = false;
  isProject: boolean = false;
  iscustomer: boolean = false;
  iscertifier: boolean = false;
  isSuperAdmin: boolean = false;
  syncheck: boolean = false;
  form: FormGroup;
  userDetails: any;
  profile_pc_view: boolean = false;
  popoverform: boolean = false;
  popoverform1: boolean = false;
  popoverform2: boolean = false;
  edit_show: boolean = false;
  editPhone_show: boolean = false;
  profile_upload: boolean = false;
  iscertifer: boolean = false;
  filesToUpload: Array<File> = [];
  userId: any;
  profileDetails: any;
  profileDetailsArr: any;
  profileDetailsArr1: any;
  nameErr: any;
  emailErr: any;
  phoneErr: any;
  loggedEntityTypeId: any;
  notifyArr = [];
  notifytotal: any;
  menuMob: boolean = false;
  opennnav: boolean = true;
  proPicId: any;
  profilePicView: any;
  UserType: any;
  isOnly: boolean = false;
  userResult: any;
  isSuperAllAdmin: boolean = false;
  editSh: boolean = false;
  loginEmail: any;
  domainListArr: any;
  domainName: any;
  domainHide: boolean = false;
  domainId: any;
  resultDomain: any;
  domainnameErr: any;
  domainPhoneErr: any;
  domainLength: any;
  domainLengthCheck: boolean = false;
  domainListLoad: boolean = false;
  domainAdmin: boolean = false;
  domainAdminCheck: any;
  notifyPopup: boolean = false;
  searchPopup: boolean = false;
  step: any;

  jobcreatedArr = [];
  taskArr = [];
  ticketArr = [];
  pageNum = 1;
  array = [];
  sum = 10;

  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  direction = '';

  directionticket = '';
  ticketSum = 10;
  ticketpageNum = 1;

  taskticket = '';
  taskSum = 10;
  taskpageNum = 1;
  directionTask = '';
  modalOpen = false;
  tab01: boolean = false;
  tab02: boolean = false;
  tab03: boolean = false;
  tab04: boolean = false;
  searchTab01: boolean = false;
  searchTab02: boolean = false;
  searchTab03: boolean = false;
  searchTab04: boolean = false;
  searchTab05: boolean = false;
  searchTab06: boolean = false;
  userSpinnerLoad: boolean = false;
  dealSpinnerLoad: boolean = false;
  approvalSpinnerLoad: boolean = false;
  projectSpinnerLoad: boolean = false;
  custSpinnerLoad: boolean = false;
  searchTicketSpinnerLoad: boolean = false;

  jobSpinnerLoad: boolean = false;
  ticketSpinnerLoad: boolean = false;
  taskSpinnerLoad: boolean = false;
  searchtrue: boolean = false;
  jobNodata: boolean = false;
  taskNodata: boolean = false;
  ticketNodata: boolean = false;
  notifyJobCount: any;
  notifiyTicketCount: any;
  notifiyTaskCount: any;
  data: any;
  dealsArr = []
  approvalsArr = [];
  projectsArr = [];
  ticketsArr = [];
  usersArr = [];
  custArr = [];
  dealsLength: any;
  approvalsLength: any;
  projectsLength: any;
  ticketsLength: any;
  usersLength: any;
  custLength: any;
  pageNumUser = 1;
  pageNumDeal = 1;
  pageNumApproval = 1;
  pageNumProject = 1;
  pageNumCust = 1;
  pageNumsearchTicket = 1;
  emptysearchData: boolean = false;
  searchDataResponse: boolean = false;

  userDisabled: boolean = false;
  dealDisbaled: boolean = false;
  approvalDisabled: boolean = false;
  projectDisabled: boolean = false;
  custDisabled: boolean = false;
  ticketDisabled: boolean = false;
  searchTabNum = 0;
  step1: any;
  activebtn: boolean = false;
  activebtndeal: boolean = false;
  activebtnapproval: boolean = false;
  activebtnproject: boolean = false;
  activebtncust: boolean = false;
  activebtnticket: boolean = false;
  valueInt1: number;
  valueInt2: number;
  valueInt3: number;
  valueInt4: number;
  valueInt5: number;
  valueInt6: number;
  entityTypeUser: boolean = false;
  jobResult: any;
  ticketResult: any;
  taskResult: any;
  notifyresult: any;
  searchUserResult: any;
  searchDealResult: any;
  searchApprovalResult: any;
  searchProjectResult: any;
  searchCustResult: any;
  searchTicketResult: any;
  domainSiteAdmin :any
  isext: boolean = false;
  isotherUser: boolean = false;
  domainSpinner : boolean = false;
  loggedInUserDomainId :any;
  domainNoticationMessage :any;


  constructor(private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService, private http: HttpClient, private router: Router,
    private toastr: ToastrService, private permissionService: PermissionService, private errorService: ErrorService, 
    private titleService: Title, private metaService: Meta) { this.createForm(); }

  ngOnInit() {
    this.ProjectName = localStorage.getItem("ProjectName");
    this.titleService.setTitle(this.ProjectName);
    this.createFormProfile();
    this.username = localStorage.getItem("loggedInUser");
    this.userid = localStorage.getItem("loggedInUserID");
    this.proPicId = localStorage.getItem("loggedInUserproPicId");
    this.UserType = localStorage.getItem("loggedInUserType");
    this.domainLength = localStorage.getItem("domainLength");
    this.loggedInUserDomainId = localStorage.getItem('loggedInDomainID');
    this.getNoticitionMessageDomain(this.loggedInUserDomainId);

    this.localStorage();
    if (this.UserType == 2) {
      this.isotherUser = true;
      this.iscustomer = true;
    } else if (this.UserType == 3) {
      this.iscertifier = true;
      this.isotherUser = true;
    } else if (this.UserType == 4 || this.UserType == 5) {
      this.getUser();
      this.isext = true;
      this.isotherUser = true;
    } else {
      this.getUser();
      this.getPreference();
      this.getNotify();
    }
    this.getUserProfile();
    this.admin = localStorage.getItem("loggedInUserAdmin");
    if (this.domainLength == "true") {
      this.domainLengthCheck = false;
    } else {
      this.domainLengthCheck = true;
    }
    // this.getJobList(1, this.sum)
    // this.getTicketList(1, this.ticketSum);
    // this.getTaskList(1, this.taskSum);
  }
  localStorage() {
    this.domainName = localStorage.getItem("domainName");
  }

  getUser() {
    this.http.get('/api/user/' + this.userid).subscribe((res: Response) => {
      this.result = res;
      var permision = this.result.role.permision;
      if (permision.length > 0) {
        var dealPermission = this.permissionService.getDealPermission(permision);
        var approvalPermission = this.permissionService.getApprovalPermission(permision);
        var projectPermission = this.permissionService.getProjectPermission(permision);
        if (dealPermission == this.permissionService.DEAL_ADMIN || dealPermission == this.permissionService.DEAL_OBSERVER) {
          this.isDeal = true;
        }
        if (approvalPermission == this.permissionService.APPROVAL_ADMIN || approvalPermission == this.permissionService.APPROVAL_OBSERVER) {
          this.isApproval = true;
        }
        if (projectPermission == this.permissionService.PROJECT_ADMIN || projectPermission == this.permissionService.PROJECT_OBSERVER) {
          this.isProject = true;
        }
        this.dashbordpermission();
      }
    });
  }

  dashbordpermission() {
    if (this.admin == "Y") {
      this.isSuperAdmin = true;
      this.isSuperAllAdmin = true;
      this.isAdminDis = false;
    } else if (this.isDeal == true && this.isProject == true && this.isApproval == true) {
      this.isSuperAdmin = true;
      this.isAdminDis = false;
    } else {
      if (this.isDeal == true && this.isApproval == true) {
        this.isSuperAdmin = false;
        this.isAdminDis = true;
      } else if (this.isApproval == true && this.isProject == true) {
        this.isSuperAdmin = false;
        this.isAdminDis = true;
      } else if (this.isDeal == true && this.isProject == true) {
        this.isSuperAdmin = false;
        this.isAdminDis = true;
      } else {
        if (this.isDeal == true || this.isApproval == true || this.isProject == true) {
          this.isSuperAdmin = false;
          this.isAdminDis = false;
          this.isOnly = true;
        }

      }
    }
  }
  mobileClose1() {
    this.opennnav = false
  }
  logout() {
    var data = {
      "userId": this.userid,
      "logType": "OUT"
    };
    this.http.post('/api/logInOut', data).subscribe((response) => {
      this.toastr.clear();
      this.result = response;
      if (this.result) {
        this.router.navigate(["/logout"]);
      }
    }, error => {
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.spinner.hide();
    })

  }

  logFun() {
    this.editSh = false;
    this.domainHide = false;
    if (this.resetlogin == true) {
      this.resetlogin = false;
    } else {
      this.resetlogin = true;
    }
  }

  mobile() {
    // this.menuClose=true;
    if (this.NavOutActive == true) {
      this.NavInActive = true;
      this.NavOutActive = false;
      if (this.popoverform == true) {
        this.popoverform = false;
      } else {
        this.popoverform = true;
      }
    } else {
      this.NavInActive = false;
      this.NavOutActive = true;
      if (this.popoverform == false) {
        this.popoverform = true;
      } else {
        this.popoverform = false;
      }
    }

  }

  mobileClose() {
    this.subnavshad = false;
    this.subnavsh = false;
    if (this.NavInActive == true) {
      this.NavInActive = false;
      this.NavOutActive = true;
      if (this.popoverform == false) {
        this.popoverform = true;
      } else {
        this.popoverform = false;
      }
    } else {
      this.NavOutActive = false;
    }

  }


  subnavsh: boolean = false;
  subnavshad: boolean = false;

  subnav() {
    if (this.subnavsh == true) {
      this.subnavsh = false;
    } else {
      this.subnavsh = true;
      this.subnavshad = false;
    }
  }

  subnavadmin() {
    if (this.subnavshad == true) {
      this.subnavshad = false;
    } else {
      this.subnavshad = true;
      this.subnavsh = false;
    }
  }

  passwordCreateMenu() {
    if (this.popoverform2 == true) {
      this.popoverform2 = false;
    } else {
      this.popoverform2 = true;
    }
    this.resetpasswordErr = null;
    this.confirmpasswordErr = null;
    this.resetForm();
    this.isCreate = true;
    this.passwordMenuState = this.passwordMenuState === 'out' ? 'in' : 'out';
    this.resetlogin = false;
  }
  resetForm() {
    this.passwordForm.reset({
      password: '',
      confirmPassword: ''
    })
  }

  createForm() {
    this.passwordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]]
    })
    this.domainForm = this.formBuilder.group({
      id: '',
      name: '',
      address: '',
      cin: '',
      gstin: '',
      phone: '',
      email: '',
      uniqueCode: '',
      createdAt: ''
    });
    this.serachForm = this.formBuilder.group({
      search: '',
      includeArchives: false
    })
  }
  passwordValidate() {
    this.resetpasswordErr = null;
    this.confirmpasswordErr = null;
    var data = this.passwordForm.value;
    const strongRegex = /(?=.{6,})/;
    var valid = true;
    if (data.password == Validators || data.password == '') {
      this.resetpasswordErr = "Password is required.";
      valid = false;
    } else if (!strongRegex.test(data.password)) {
      this.resetpasswordErr = "Password is Must be 6 Charcters.";
      valid = false;
    } else if (data.password != data.confirmPassword) {
      this.confirmpasswordErr = "Password doesn't Match.";
      valid = false;
    }
    return valid;
  }

  passwordPost() {
    if (this.passwordValidate()) {
      this.spinner.show();
      this.http.get('/api/user/' + this.userid).subscribe((res: Response) => {
        this.result = res;
        var data = this.result.details;
        var data1 = this.passwordForm.value;
        data.password = data1.confirmPassword;
        data.updatedBy = parseInt(this.userid);
        if (this.UserType == 3) {
          var url = '/api/certifier/'
        } else {
          var url = '/api/user/' + this.userid
        }
        this.http.put(url, data).subscribe((response) => {
          this.resetForm();
          this.toastr.clear();
          this.toastr.success('Password Updated successfully', 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.result = response;
        })
        this.passwordCreateMenu();
        this.resetlogin = false;
        this.spinner.hide();
      })
    }
  }
  notify() {
    $('body').css('overflow', 'hidden')   
    this.getJobList(1, this.sum,0)
    this.getTicketList(1, this.ticketSum,0);
    this.getTaskList(1, this.taskSum,0);
    this.step = 'step1';
    this.tabs1();
    this.notifyPopup = true;
    this.notificationMenuState = this.notificationMenuState === 'out' ? 'in' : 'out';
  }
  getNotify() {
    this.http.get('/api/tasks/count/' + this.userid).subscribe((res: Response) => {
      this.result = res;
      this.notifyArr.push({
        "id": this.result.id, "userJobCount": this.result.userJobCount, "userTicketCount": this.result.userTicketCount, "userTasksCount": this.result.userTasksCount,
      })
      this.notifyJobCount = this.result.userJobCount;
      this.notifiyTicketCount = this.result.userTicketCount;
      this.notifiyTaskCount = this.result.userTasksCount;
      this.notifytotal = this.result.userJobCount + this.result.userTicketCount + this.result.userTasksCount;
    });
  }


  reload() {
    this.notifyArr = [];
    this.notifytotal = [];
    this.getNotify();

  }
  getPreference() {
    this.http.get('/api/userPreferences/' + this.userid).subscribe((res: Response) => {
      this.result = res;
      if (this.result.length != 0) {
        if (this.result[0].isAccountAdded == "Y") {
          this.syncheck = true;
        } else {
          this.syncheck = false;
        }
      }
    })

  }

  toggleMail() {
    const url = 'https://www.google.com/gmail/'
    window.open(url);
  }
  profilePage() {
    this.domainAdminCheck = localStorage.getItem("domainAdmin");
    if (this.domainAdminCheck == "true") {
      this.domainAdmin = true;
    } else {
      this.domainAdmin = false;
    }
    if (this.popoverform1 == true) {
      this.popoverform1 = false;
    } else {
      this.popoverform1 = true;
    }
    this.getUserProfile();
    this.edit_show = false;
    this.resetlogin = false;
    this.profileViewState = this.profileViewState === 'out' ? 'in' : 'out';
  }
  goToProfilePage() {
    this.resetlogin = false;
    this.router.navigate(["/profile"]);
  }



  getUserProfile() {
    if (this.proPicId == "null") {
      this.profile_pc_view = false;
    } else {
      this.profile_pc_view = true;
      this.profilePicView = '/unsecure/gallery/' + this.proPicId;
    }
    this.spinner.show();
    this.http.get('/api/user/' + this.userid).subscribe((res: Response) => {
      this.result = res;
      this.userDetails = this.result;
      this.spinner.hide();
    });
  }
  getCustomer() {
    this.spinner.show();
    this.http.get('/api/customer/' + this.userid).subscribe((res: Response) => {
      this.result = res;
      this.userDetails = this.result;
      this.spinner.hide();
    });
  }

  edit_show_click(id) {
    var data = id;
    this.edit_show = !this.edit_show;
    this.form.patchValue({ 'id': data.details.id });
    this.form.patchValue({ 'firstName': data.details.firstName });
    this.form.patchValue({ 'lastName': data.details.lastName });
    this.form.patchValue({ 'phone': data.details.phone });
    this.form.patchValue({ 'email': data.details.email });
    this.form.patchValue({ 'addressId': data.details.addressId });
    this.form.patchValue({ 'unit': data.location.unit });
    this.form.patchValue({ 'address1': data.location.address1 });
    this.form.patchValue({ 'address2': data.location.address2 });
    this.form.patchValue({ 'city': data.location.city });
    this.form.patchValue({ 'state': data.location.state });
    this.form.patchValue({ 'country': data.location.country });
    this.form.patchValue({ 'isActive': data.details.isActive });
    this.form.patchValue({ 'postalCode': data.location.postalCode });
    this.form.patchValue({ 'createdBy': data.details.createdBy });
    this.form.patchValue({ 'entityTypeId': data.details.entityTypeId });
    this.form.patchValue({ 'role': data.details.role });
    this.form.patchValue({ 'isAdmin': data.details.isAdmin });
    this.form.patchValue({ 'gender': data.details.gender });
    this.form.patchValue({ 'createdDate': data.details.createdDate });
    this.form.patchValue({ 'domainId': data.details.domainId });
  }
  cancel_show_click() {
    this.nameErr = null;
    this.emailErr = null;
    this.edit_show = false;
  }
  getAttachFile(files: any) {
    this.filesToUpload = <Array<File>>files.target.files;
    this.postAttachment();
  }
  postAttachment() {
    var files: Array<File> = this.filesToUpload;
    for (let i = 0; i < files.length; i++) {
      this.spinner.show();
      var formData = new FormData();
      formData.append("file", files[i], files[i].name);
      formData.append("createdBy", this.userid);
      this.http.post('/api/upload/profile/' + this.userid + "/" + this.userid, formData).subscribe((res: Response) => {
        this.result = res;
        if (this.result.successArr.length > 0) {
          localStorage.setItem("loggedInUserproPicId", this.result.successArr[0].response.id);
          this.proPicId = this.result.successArr[0].response.id;
          this.getUserProfile();
          this.toastr.success("Profile Picture Updated", 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          // window.location.reload();
          var component = this.router.url;
          this.router.navigateByUrl('/headercomponent', { skipLocationChange: true }).then(() => {
            this.router.navigate([component]);
          });
          this.spinner.hide();
        } else {
          this.toastr.error(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
      });
    }
  }


  updateCust(data) {
    if (data.details.entityTypeId != 2) {
      if (this.validate()) {
        var data1 = this.form.value;
        data1.updatedBy = parseInt(this.userid);

        data1.password = "";
        this.spinner.show();
        if (this.UserType == 3) {
          var url = '/api/certifier/'
        } else if (this.UserType == 4 || this.UserType == 5) {
          var url = '/api/externalUser/'
        } else {
          var url = '/api/user/' + data1.id
        }
        this.http.put(url, data1).subscribe((res: Response) => {
          this.toastr.clear();
          this.result = res;
          if (this.result.success == true) {
            this.toastr.success(this.result.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            localStorage.setItem("loggedInUser", this.result.response.firstName + " " + this.result.response.lastName);
            this.username = this.result.response.firstName + " " + this.result.response.lastName;
            this.getUserProfile();
            this.cancel_show_click();
            if (this.UserType == 4 || this.UserType == 5) {
              var component = this.router.url;
              this.router.navigateByUrl('/headercomponent', { skipLocationChange: true }).then(() => {
                this.router.navigate([component]);
              });
            }
          } else {
            this.toastr.error(this.result.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
           
            this.spinner.hide();
          }
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })
      }
    } else {
      if (this.validate()) {
        var data1 = this.form.value;
        data1.updatedBy = parseInt(this.userid);
        this.spinner.show();
        this.http.put('/api/customer/' + data1.id, data1).subscribe((res: Response) => {
          this.toastr.clear();
          this.result = res;
          if (this.result.success) {
            this.edit_show = !this.edit_show;
            this.toastr.success(this.result.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.getCustomer();
            var component = this.router.url;
              this.router.navigateByUrl('/headercomponent', { skipLocationChange: true }).then(() => {
                this.router.navigate([component]);
              });
          } else {
            this.toastr.error(this.result.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
          }
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })
      }

    }
  }
  validate() {
    this.nameErr = null;
    this.emailErr = null;
    var data = this.form.value;
    var valid = true;
    this.phoneErr = null;
    const validNumber = /^\d+$/;
    const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!data.firstName) {
      this.nameErr = "FirstName is required.";
      valid = false;
    }
    if (data.phone) {
      if (data.phone != '' && !validNumber.test(data.phone)) {
        this.phoneErr = "Invalid Phone Number.";
        valid = false;
      }
    }
    return valid;

  }
  createFormProfile() {
    this.form = this.formBuilder.group({
      id: '',
      domainId :'',
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: '',
      addressId: null,
      phone: '',
      createdBy: '',
      isActive :'',
      updatedBy: '',
      unit: '',
      entityTypeId: '',
      address1: '',
      address2: '',
      city: 'Sydney',
      state: 'New South Wales',
      country: 'Australia',
      postalCode: '',
      role: '',
      isAdmin: '',
      gender: '',
      createdDate: ''
    })

  }
  onSelectjob() {
    this.router.navigate(['/dashboardnew']);
  }
  onSelectticket() {
    this.router.navigate(['/issues']);
  }
  onSelecttask() {
    this.router.navigate(['/dashboardnew']);
  }
  deletePic(id) {
    this.http.delete('/api/files/profilePic/' + id).subscribe((res: Response) => {
      this.userResult = res;
      if (this.userResult.success == true) {
        this.toastr.success(this.userResult.message, 'Report Status', {
          timeOut: 2000,
          closeButton: true
        });
        localStorage.setItem("loggedInUserproPicId", null);
        var component = this.router.url;
        this.router.navigateByUrl('/headercomponent', { skipLocationChange: true }).then(() => {
          this.router.navigate([component]);
        });
      }
    });
  }
  domainListOpen() {
    // this.spinner.show();
    this.resetlogin = false;
    this.loginEmail = localStorage.getItem("loggedEmail");
    this.editSh = true;
    this.http.get('/api/userDomain/' + this.loginEmail).subscribe((response) => {
      this.result = response;
      this.domainSiteAdmin = this.result.siteAdmin;
      this.domainListArr = this.result.domains;
      this.domainSpinner=true;
      // this.spinner.hide();
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }

  domainClose() {
    this.nameErr = null;
    this.emailErr = null;
    this.domainnameErr = null;
    this.domainPhoneErr = null;
    if (this.editSh == true) {
      this.editSh = false;
    } else {
      this.editSh = true;
    }
    this.resetlogin = false;
  }
  domainSelect(data) {
    this.editSh = false;
    this.spinner.show();
    this.loginEmail = localStorage.getItem("loggedEmail");
    localStorage.setItem("domainName", data.domainName);
    this.http.post('/unsecure/login/domain' + '/' + this.loginEmail + '/' + data.domainId, data).subscribe((response) => {
      this.result = response;
      var status = this.result.status;
      var message = this.result.message;
      var data = this.result.data;
      if (data) {
        this.localStorage();
        var date = new Date();
        var url = this.router.url;
        var tdate = moment(date).format("YYYY-MM-DD")
        localStorage.setItem("loggedIn", "true");
        localStorage.setItem("jwt", this.result.jwt);
        localStorage.setItem("loggedInUserID", data.id);
        localStorage.setItem("loggedInDomainID", data.domainId);
        localStorage.setItem("loggedInUser", data.firstName + " " + data.lastName);
        localStorage.setItem("loggedInUserAdmin", data.isAdmin);
        localStorage.setItem("loggedIndate", tdate);
        localStorage.setItem("loggedInUserType", data.entityTypeId);
        localStorage.setItem("loggedInUserproPicId", data.proPicId);
        localStorage.setItem("domainAdmin", this.result.domainAdmin);
        if (data.entityTypeId == 1) {
          // if(url =="/dashboardnew"){
          //  this.router.navigate(["/dashboard"]);
          // }else{
          //   this.router.navigate(["/dashboardnew"]);
          // }
          this.router.navigateByUrl('/headercomponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/dashboardnew']);
          });
        } else if (data.entityTypeId == 2 || data.entityTypeId == 3) {
          //   if(url =="/customer-dashboard"){
          //  this.router.navigate(["/customer-dashboardd"]);
          // }else{
          //   this.router.navigate(["/customer-dashboard"]);
          // }   
          this.router.navigateByUrl('/headercomponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/customer-dashboard']);
          });
        }

      }
      else {
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }

  domainEdit() {
    this.domainHide = true;
    this.domainId = localStorage.getItem("loggedInDomainID");
    this.http.get('/api/domain/' + this.domainId).subscribe((response) => {
      this.resultDomain = response;
      this.domainForm.patchValue({ 'id': this.resultDomain.id });
      this.domainForm.patchValue({ 'name': this.resultDomain.name });
      this.domainForm.patchValue({ 'cin': this.resultDomain.cin });
      this.domainForm.patchValue({ 'gstin': this.resultDomain.gstin });
      this.domainForm.patchValue({ 'email': this.resultDomain.email });
      this.domainForm.patchValue({ 'uniqueCode': this.resultDomain.uniqueCode });
      this.domainForm.patchValue({ 'phone': this.resultDomain.phone });
      this.domainForm.patchValue({ 'createdAt': this.resultDomain.createdAt });
      this.domainForm.patchValue({ 'address': this.resultDomain.address });
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  cancel_domain_click() {
    this.domainHide = false;
  }
  updateDomain() {
    if (this.validateDomain()) {
      this.spinner.show();
      var data = this.domainForm.value;
      data.updatedBy = parseInt(this.userid);
      this.spinner.show();
      this.http.put('/api/domain/', data).subscribe((res: Response) => {
        this.result = res;
        if (this.result.success) {
          localStorage.setItem("domainName", data.name);
          this.toastr.success(this.result.message, 'Report Status', {
            timeOut: 2000,
            closeButton: true
          });
          this.domainEdit();
          this.localStorage();
          this.spinner.hide();
        } else {
          this.toastr.error(this.result.message, 'Report Status', {
            timeOut: 2000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.error(error, 'Report Status', {
          timeOut: 2000,
          closeButton: true
        });
        this.spinner.hide();
        this.errorService.handleError(error);
      })
    }
  }

  validateDomain() {
    this.domainnameErr = null;
    this.domainPhoneErr = null;
    var data = this.domainForm.value;
    var valid = true;
    this.phoneErr = null;
    const validNumber = /^\d+$/;
    const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!data.name) {
      this.domainnameErr = "Domain Name is required.";
      valid = false;
    }
    if (data.phone) {
      if (data.phone != '' && !validNumber.test(data.phone)) {
        this.domainPhoneErr = "Invalid Phone Number.";
        valid = false;
      }
    }
    return valid;
  }
  tabs1() {
    this.tab01 = false;
    this.tab02 = false;
    this.tab03 = false;
  }
  tabs2() {
    this.tab01 = true;
    this.tab02 = true;
    this.tab03 = false;
  }
  tabs3() {
    this.tab01 = true;
    this.tab02 = false;
    this.tab03 = true;
  }
  notificationCloseMenu() {
    $('body').css('overflow', 'auto')
    this.notifyPopup = false;
    this.notificationMenuState = this.notificationMenuState === 'out' ? 'in' : 'out';
  }
  onScrollJob() {
    this.pageNum++;
    this.getJobList(this.pageNum, this.sum,1)
  }
  getJobList(page, datacount,num) {
    if(num == 0){
      this.jobcreatedArr = [];
    }
    this.http.get('/api/projectJob/user/' + this.userid + '/' + page + '/' + datacount).subscribe((res: Response) => {
      this.jobResult = res;
      var result = this.jobResult.masterList;
      for (let i = 0; i < result.length; i++) {
        this.jobcreatedArr.push({
          "id": result[i].id,
          "jobName": result[i].jobName,
          "projectName": result[i].projectName,
          "assigned": result[i].assigned,
          "startDate": result[i].startDate,
          "endDate": result[i].endDate,
          "ProjectId": result[i].projectId,
          "edit": "true",
          "jobtype": result[i].type,
          "statusId": result[i].status
        })
      }
      if (result.length < 10) {
        this.jobSpinnerLoad = true;
      }
      if (result.length == 0 && this.pageNum == 1) {
        this.jobNodata = true;
      } else {
        this.valueInt5 = 5;
        this.jobNodata = false;
      }
    });
  }
  onScrollTicket(ev) {
    this.ticketpageNum++;
    this.getTicketList(this.ticketpageNum, this.ticketSum,1)
  }


  getTicketList(page, datacount,num) {
    if(num == 0){
      this.ticketArr =[];
    }
    this.http.get('/api/userTicketAssigned/paging/' + this.userid + '/' + page + '/' + datacount).subscribe((res: Response) => {
      this.ticketResult = res;
      var result = this.ticketResult;
      for (let i = 0; i < result.length; i++) {
        this.ticketArr.push({
          "id": result[i].ticket.id,
          "ticketName": result[i].ticket.name,
          "userName": result[i].User.firstName + " " + result[i].User.lastName,
          "ProjectId": result[i].ticket.projectId,
          "projectName": result[i].Project.title,
          "statusId": result[i].ticket.statusId,
          "startDate": result[i].ticket.startBy,
          "endDate": result[i].ticket.completeBy,
        })
      }
      if (result.length < 10) {
        this.ticketSpinnerLoad = true;
      }
      if (result.length == 0 && this.ticketpageNum == 1) {
        this.ticketNodata = true;
      } else {
        this.valueInt5 = 5;
        this.ticketNodata = false;
      }
    });
  }
  onScrollTask(ev) {
    this.taskpageNum++;
    this.getTaskList(this.taskpageNum, this.taskSum,1)
  }
  getTaskList(page, datacount,num) {
    if(num == 0){
      this.taskArr =[];
    }
    this.http.get('/api/userTaskAssigned/paging/' + this.userid + '/' + page + '/' + datacount).subscribe((res: Response) => {
      this.ticketResult = res;
      var result = this.ticketResult;
      for (let i = 0; i < result.length; i++) {
        this.taskArr.push({
          "id": result[i].Task.id,
          "taskName": result[i].Task.name,
          "userName": result[i].User.firstName + " " + result[i].User.lastName,
          "ProjectId": result[i].Task.projectId,
          "projectName": result[i].Project.title,
          "type": result[i].Task.type,
          "startDate": result[i].Task.startBy,
          "endDate": result[i].Task.completeBy,
          "isCompleted": result[i].Task.isCompleted,
          "isTaken": result[i].Task.isTaken
        })
      }
      if (result.length < 10) {
        this.taskSpinnerLoad = true;
      }
      if (result.length == 0 && this.taskpageNum == 1) {
        this.taskNodata = true;
      } else {
        this.valueInt5 = 5;
        this.taskNodata = false;
      }
      return this.taskArr;
    });
  }

  reloadNotification() {
    // this.spinner.show();
    this.notifyArr = [];
    this.notifytotal = [];
    this.jobcreatedArr = [];
    this.ticketArr = [];
    this.taskArr = [];
    this.taskpageNum = 1;
    this.pageNum = 1;
    this.ticketpageNum = 1;
    this.getJobList(1, this.sum,0);
    this.getTicketList(1, this.ticketSum,0);
    this.getTaskList(1, this.taskSum,0);
    this.http.get('/api/tasks/count/' + this.userid).subscribe((res: Response) => {
      this.notifyresult = res;
      // this.notifyArr.push({
      //   "id": result.id, "userJobCount": result.userJobCount, "userTicketCount": result.userTicketCount, "userTasksCount": result.userTasksCount,
      // })
      this.notifyJobCount = this.notifyresult.userJobCount;
      this.notifiyTicketCount = this.notifyresult.userTicketCount;
      this.notifiyTaskCount = this.notifyresult.userTasksCount;
      this.notifytotal = this.notifyresult.userJobCount + this.notifyresult.userTicketCount + this.notifyresult.userTasksCount;
      // this.spinner.hide();
    });
  }
  searchOpen() {
    this.searchPopup = true;
    $('body').css('overflow', 'hidden')
    this.searchMenuState = this.searchMenuState === 'out' ? 'in' : 'out';
  }
  searchCloseMenu() {
    this.searchPopup = false;
    this.searchtrue = false;
    this.searchTab01 = false;
    this.searchTab02 = false;
    this.searchTab03 = false;
    this.searchTab04 = false;
    this.searchTab05 = false;
    this.searchTab06 = false;
    this.searchResetForm();
    $('body').css('overflow', 'auto')
    this.searchMenuState = this.searchMenuState === 'out' ? 'in' : 'out';
  }
  searchResetForm() {
    this.serachForm.reset({
      search: '',
      includeArchives: false
    })
  }
  searchFilter() {
    var data = this.serachForm.value;
    if (data.search == "") {
      this.emptysearchData = true;
      this.searchtrue = false;
      this.searchTab01 = false;
      this.searchTab02 = false;
      this.searchTab03 = false;
      this.searchTab04 = false;
      this.searchTab05 = false;
      this.searchTab06 = false;
    } else {
      this.emptysearchData = false;
      data.userId = this.userid;
      data.searchInto = 4;
      data.searchString = data.search;
      data.pageNo = 1;
      data.limit = 10;
      this.searchtrue = true;
      this.step = 'step11';
      this.data = data;
      this.usersArr = [];
      this.dealsArr = [];
      this.approvalsArr = [];
      this.projectsArr = [];
      this.custArr = [];
      this.ticketsArr = [];
      this.pageNumUser = 1;
      this.pageNumDeal = 1;
      this.pageNumApproval = 1;
      this.pageNumProject = 1;
      this.pageNumCust = 1;
      this.pageNumsearchTicket = 1;
      this.searchTabNum = 0;
      this.getSearchUserList();
      this.getSearchDealList();
      this.getSearchApprovalList();
      this.getSearchProjectList();
      this.getSearchCustList();
      this.getSearchTicketList();
    }
  }
  searchTabs(num) {
    if (num == 1) {
      this.activebtn = true;
      this.step = 'step11';
      this.activebtndeal = false;
      this.activebtnapproval = false;
      this.activebtnproject = false;
      this.activebtncust = false;
      this.activebtnticket = false;

      this.searchTab01 = true;
      this.searchTab02 = false;
      this.searchTab03 = false;
      this.searchTab04 = false;
      this.searchTab05 = false;
      this.searchTab06 = false;

    } else if (num == 2) {
      this.step = 'step22';
      this.activebtn = false;
      this.activebtndeal = true;
      this.activebtnapproval = false;
      this.activebtnproject = false;
      this.activebtncust = false;
      this.activebtnticket = false;

      this.searchTab01 = false;
      this.searchTab02 = true;
      this.searchTab03 = false;
      this.searchTab04 = false;
      this.searchTab05 = false;
      this.searchTab06 = false;

    } else if (num == 3) {
      this.step = 'step33';
      this.activebtn = false;
      this.activebtndeal = false;
      this.activebtnapproval = true;
      this.activebtnproject = false;
      this.activebtncust = false;
      this.activebtnticket = false;

      this.searchTab01 = false;
      this.searchTab02 = false;
      this.searchTab03 = true;
      this.searchTab04 = false;
      this.searchTab05 = false;
      this.searchTab06 = false;

    } else if (num == 4) {
      this.step = 'step44';
      this.activebtn = false;
      this.activebtndeal = false;
      this.activebtnapproval = false;
      this.activebtnproject = true;
      this.activebtncust = false;
      this.activebtnticket = false;

      this.searchTab01 = false;
      this.searchTab02 = false;
      this.searchTab03 = false;
      this.searchTab04 = true;
      this.searchTab05 = false;
      this.searchTab06 = false;
    } else if (num == 5) {
      this.step = 'step55';
      this.activebtn = false;
      this.activebtndeal = false;
      this.activebtnapproval = false;
      this.activebtnproject = false;
      this.activebtncust = true;
      this.activebtnticket = false;

      this.searchTab01 = false;
      this.searchTab02 = false;
      this.searchTab03 = false;
      this.searchTab04 = false;
      this.searchTab05 = true;
      this.searchTab06 = false;
    } else if (num == 6) {
      this.step = 'step66';
      this.activebtn = false;
      this.activebtndeal = false;
      this.activebtnapproval = false;
      this.activebtnproject = false;
      this.activebtncust = false;
      this.activebtnticket = true;

      this.searchTab01 = false;
      this.searchTab02 = false;
      this.searchTab03 = false;
      this.searchTab04 = false;
      this.searchTab05 = false;
      this.searchTab06 = true;
    }
  }
  onScrollUser() {
    this.pageNumUser++;
    this.data.pageNo = this.pageNumUser;
    this.getSearchUserList();
  }
  onScrollDeal() {
    this.pageNumDeal++;
    this.data.pageNo = this.pageNumDeal;
    this.getSearchDealList();

  }
  onScrollApproval() {
    this.pageNumApproval++;
    this.data.pageNo = this.pageNumApproval;
    this.getSearchApprovalList();
  }
  onScrollProject() {
    this.pageNumProject++;
    this.data.pageNo = this.pageNumProject;
    this.getSearchProjectList();

  }
  onScrollCust() {
    this.pageNumCust++;
    this.data.pageNo = this.pageNumCust;
    this.getSearchCustList();
  }
  onScrollSearchTicket() {
    this.pageNumsearchTicket++;
    this.data.pageNo = this.pageNumsearchTicket;
    this.getSearchTicketList();
  }
  getSearchUserList() {
    this.data.searchInto = 4;
    this.http.post('/api/globalSearch', this.data).subscribe((response) => {
      this.searchUserResult = response;
      for (let i = 0; i < this.searchUserResult.usrs.length; i++) {
        if (this.searchUserResult.usrs[i].phone == "") {
          this.usersArr.push({
            "id": this.searchUserResult.usrs[i].id,
            "firstName": this.searchUserResult.usrs[i].firstName,
            "lastName": this.searchUserResult.usrs[i].lastName,
            "email": this.searchUserResult.usrs[i].email,
            "phone": null,
          })
        } else {
          this.usersArr.push({
            "id": this.searchUserResult.usrs[i].id,
            "firstName": this.searchUserResult.usrs[i].firstName,
            "lastName": this.searchUserResult.usrs[i].lastName,
            "email": this.searchUserResult.usrs[i].email,
            "phone": this.searchUserResult.usrs[i].phone,
          })
        }
      }
      this.usersLength = this.searchUserResult.usrs.length;

      if (this.searchUserResult.usrs.length == 0 && this.pageNumUser == 1) {
        this.userDisabled = true;
      } else {
        this.valueInt1 = 1;
        this.userDisabled = false;
      }
      if (this.searchUserResult.usrs.length < 10) {
        this.userSpinnerLoad = true;
      }
    });
  }
  getSearchDealList() {
    this.data.searchInto = 1;
    this.http.post('/api/globalSearch', this.data).subscribe((response) => {
      this.searchDealResult = response;
      var dealResult = this.searchDealResult.deals;
      for (let i = 0; i < dealResult.length; i++) {
        this.dealsArr.push({
          "id": dealResult[i].deal.id,
          "title": dealResult[i].deal.title,
          "projectStateId": dealResult[i].deal.projectStateId,
          "value": dealResult[i].deal.value,
          "createdDate": dealResult[i].deal.createdDate,
          "stateName": dealResult[i].currentStateName,
        })
      }
      if (dealResult.length < 10) {
        this.dealSpinnerLoad = true;
      }
      if (dealResult.length == 0 && this.pageNumDeal == 1) {
        this.dealDisbaled = true;
      } else {
        this.valueInt2 = 2;
        this.dealDisbaled = false;
      }
      this.dealsLength = dealResult.length;
    });
  }
  getSearchApprovalList() {
    this.data.searchInto = 2;
    this.http.post('/api/globalSearch', this.data).subscribe((response) => {
      this.searchApprovalResult = response;
      var approvalResult = this.searchApprovalResult.approvals;
      for (let i = 0; i < approvalResult.length; i++) {
        this.approvalsArr.push({
          "id": approvalResult[i].approval.id,
          "title": approvalResult[i].approval.title,
          "projectStateId": approvalResult[i].approval.projectStateId,
          "value": approvalResult[i].approval.value,
          "createdDate": approvalResult[i].approval.createdDate,
          "completePerc": approvalResult[i].completionPercentage
        })
      }
      if (approvalResult.length < 10) {
        this.approvalSpinnerLoad = true;
      }
      if (approvalResult.length == 0 && this.pageNumApproval == 1) {
        this.approvalDisabled = true;
      } else {
        this.valueInt3 = 3;
        this.approvalDisabled = false;
      }
      this.approvalsLength = approvalResult.length;
    });
  }
  getSearchProjectList() {
    this.data.searchInto = 3;
    this.http.post('/api/globalSearch', this.data).subscribe((response) => {
      this.searchProjectResult = response;
      var projectresult = this.searchProjectResult.projects;
      for (let i = 0; i < projectresult.length; i++) {
        this.projectsArr.push({
          "id": projectresult[i].project.id,
          "title": projectresult[i].project.title,
          "projectStateId": projectresult[i].project.projectStateId,
          "value": projectresult[i].project.value,
          "createdDate": projectresult[i].project.createdDate,
          "manager": projectresult[i].manager
        })
      }
      if (projectresult.length < 10) {
        this.projectSpinnerLoad = true;
      }
      if (projectresult.length == 0 && this.pageNumProject == 1) {
        this.projectDisabled = true;
      } else {
        this.valueInt4 = 4;
        this.projectDisabled = false;
      }
      this.projectsLength = projectresult.length;
    });
  }
  getSearchCustList() {
    this.data.searchInto = 5;
    this.http.post('/api/globalSearch', this.data).subscribe((response) => {
      this.searchCustResult = response;
      for (let i = 0; i < this.searchCustResult.custs.length; i++) {
        if (this.searchCustResult.custs[i].phone == "") {
          this.custArr.push({
            "id": this.searchCustResult.custs[i].id,
            "firstName": this.searchCustResult.custs[i].firstName,
            "lastName": this.searchCustResult.custs[i].lastName,
            "email": this.searchCustResult.custs[i].email,
            "phone": null
          })
        } else {
          this.custArr.push({
            "id": this.searchCustResult.custs[i].id,
            "firstName": this.searchCustResult.custs[i].firstName,
            "lastName": this.searchCustResult.custs[i].lastName,
            "email": this.searchCustResult.custs[i].email,
            "phone": this.searchCustResult.custs[i].phone
          })
        }

      }
      if (this.searchCustResult.custs.length < 10) {
        this.custSpinnerLoad = true;
      }
      if (this.searchCustResult.custs.length == 0 && this.pageNumCust == 1) {
        this.custDisabled = true;
      } else {
        this.valueInt5 = 5;
        this.custDisabled = false;
      }
      this.custLength = this.searchCustResult.custs.length;
    });
  }

  getSearchTicketList() {
    this.searchTabNum++;
    this.data.searchInto = 6;
    this.http.post('/api/globalSearch', this.data).subscribe((response) => {
      this.searchTicketResult = response;
      var ticketResult = this.searchTicketResult.tickets;
      for (let i = 0; i < ticketResult.length; i++) {
        this.ticketsArr.push({
          "id": ticketResult[i].ticket.id,
          "name": ticketResult[i].ticket.name,
          "projectId": ticketResult[i].ticket.projectId,
          "projectName": ticketResult[i].Project.title,
          "statusId": ticketResult[i].ticket.statusId,
          "startBy": ticketResult[i].ticket.startBy,
          "completeBy": ticketResult[i].ticket.completeBy,
        })
      }
      if (ticketResult.length < 10) {
        this.searchTicketSpinnerLoad = true;
      }
      if (ticketResult.length == 0 && this.pageNumsearchTicket == 1) {
        this.ticketDisabled = true;
      } else {
        this.valueInt6 = 6;
        this.ticketDisabled = false;
      }
      this.ticketsLength = ticketResult.length;
      this.searchDataResponse = true;
      setTimeout(() => {
        if (this.searchTabNum == 1) {
          this.step1 = 'step222';
          if (this.valueInt1 == 1) {
            this.step1 = 'step222';
            this.searchTabs(this.valueInt1);
          } else if (this.valueInt2 == 2) {
            this.step1 = 'step222';
            this.searchTabs(this.valueInt2);
          } else if (this.valueInt3 == 3) {
            this.step1 = 'step222';
            this.searchTabs(this.valueInt3);
          } else if (this.valueInt4 == 4) {
            this.step1 = 'step222';
            this.searchTabs(this.valueInt4);
          } else if (this.valueInt5 == 5) {
            this.step1 = 'step222';
            this.searchTabs(this.valueInt5);
          } else if (this.valueInt6 == 6) {
            this.step1 = 'step222';
            this.searchTabs(this.valueInt6);
          } else {
            this.searchTab01 = true;
          }
          this.valueInt1 = 0;
          this.valueInt2 = 0;
          this.valueInt3 = 0;
          this.valueInt4 = 0;
          this.valueInt5 = 0;
          this.valueInt6 = 0;
        }
      }, 1000);
    });
  }
  archiveSearch() {
    var data = this.serachForm.value;
    this.data = {};
    if (data.includeArchives == true) {
      this.serachForm.patchValue({ 'includeArchives': false });
      this.searchFilter();
    } else {
      this.serachForm.patchValue({ 'includeArchives': true });
      this.searchFilter();
    }
  }
  domainRegisteryPage(){
    this.resetlogin = false;
    this.router.navigate(["/domain-registry"]);
  }
   getNoticitionMessageDomain(id){
    this.http.get('/api/licenseStatus/' + id).subscribe((res: Response) => {
      this.domainNoticationMessage = res;
    });
  }
}

import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { NgForm, EmailValidator } from '@angular/forms';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '../../_services';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { ErrorService } from 'src/app/_services/error.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DetailsService } from '../../_services';
import { trigger, state, animate, transition, style } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class UserComponent implements OnInit {
  @Input('userSlider') userSlider;
  @Output() ChangeEvent = new EventEmitter();
  @ViewChild('isIncludeTag') isIncludeTag: ElementRef;
  custArr = [];
  result: any;
  pid: any;
  key: any;
  sort: any;
  myFilter: any;
  term:any;
  form: FormGroup;
  passwordForm: FormGroup;
  isCreate: boolean = true;
  body: {};
  err: { 'fname': '', 'lname': '', 'email': '' };
  nameErr: any;
  lnameErr: any;
  emailErr: any;
  phoneErr: any;
  passwordErr: any;
  resetpasswordErr: any;
  confirmpasswordErr: any;
  postalErr: any;
  closeResult: string;
  menuState: string = 'out';
  passwordMenuState: string = 'out';
  filter: any;
  reverse: any;
  p: any;
  roles = {};
  rolesArrselect = [];
  roleErr: any;
  genderErr: any;
  settings: any;
  roleArr = [];
  uid: any;
  userid: any;
  dropdownList = [];
  settingssingle: any;
  selectedItems = [];
  result1 :any;
  result2:any;
  result3 :any;
  result4 :any;
  myusers :any;
  popoverform: boolean = false;
  popoverform1: boolean = false;
  passreset: boolean = false;
  movetorole: boolean = false;
  admin :any;
  isSiteAdmin: boolean = false;
  dealTosliderOpen: boolean = false;
  rolesliderOpenCheck: boolean = false;
  ngOnChangeRoleCheck :boolean = false;
  

  toggleCreateMenu() {
    this.getRole();
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.nameErr = null;
    this.lnameErr = null;
    this.emailErr = null;
    this.phoneErr = null;
    this.postalErr = null;
    this.passwordErr = null;
    this.roleErr = null;
    this.genderErr = null;
    this.resetpasswordErr = null;
    this.confirmpasswordErr = null;
    this.isCreate = true;
    this.pid = undefined;
    this.resetForm();
    this.passreset=false

    this.menuState = this.menuState === 'out' ? 'in' : 'out';
  }



  constructor(private http: HttpClient, private router: Router, private DetailsService: DetailsService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService, private toastr: ToastrService,
    private errorService: ErrorService,
    private confirmService: ConfirmService,private route: ActivatedRoute,
    private modalService: NgbModal) {
    this.createForm();

  }

  ngOnInit() {
    // this.ngOnChangeRoleCheck = false;
    this.userid = localStorage.getItem("loggedInUserID");
    this.admin = localStorage.getItem("loggedInUserAdmin");
    if(this.admin == 'Y'){
      this.isSiteAdmin = true;
    }else{
      this.isSiteAdmin = false;
    }
    this.getCust();
    this.dropdownList = [
      { "id": 1, "itemName": "Male" },
      { "id": 2, "itemName": "Female" },
      { "id": 3, "itemName": "Others" }
      
    ];

    this.settingssingle = {
      text: "Select",
      classes: "myclass custom-class",
      singleSelection: true,
      enableCheckAll: false
    };
    this.settings = {
      text: "Select",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      singleSelection: true,
      enableCheckAll: false,
    };
    const userRouteID = +this.route.snapshot.paramMap.get('id');
    if (userRouteID != 0) {
      this.spinner.show();
      this.toggleEditMenu(userRouteID,1);
    }
    if (this.router.url == "/deals") {
      this.dealTosliderOpen = true;
      this.popoverform1 = true;
      this.toggleCreateMenu();
    }
  }
  getCust() {
    this.spinner.show();
    this.custArr = [];
    this.http.get('/api/user/').subscribe((res: Response) => {
      this.result = res;
      this.myusers = this.result.length;
      this.spinner.hide();
      for (let i = 0; i < this.result.length; i++) {
        this.custArr.push({
          "id": this.result[i].details.id, "firstName": this.result[i].details.firstName, "lastName": this.result[i].details.lastName, "email": this.result[i].details.email, "phone": this.result[i].details.phone, "location": this.result[i].location, "roleName": this.result[i].role.name
        })
      }
    });
  }

  getRole() {
    this.roleArr = [];
    this.http.get('/api/roles/').subscribe((res: Response) => {
      this.result1 = res;
       if(this.result1.length != 0){
        this.movetorole =false;
        for (let i = 0; i < this.result1.length; i++) {
            if (this.result1[i].role.isDefault == 'N') {
              this.roleArr.push({ "id": this.result1[i].role.id, "itemName": this.result1[i].role.name })
            }
        }
       }
         else{
          this.movetorole = true;
        }
    });
 
  }
  
  public resetForm() {
    this.nameErr = null;
    this.lnameErr = null;
    this.emailErr = null;
    this.postalErr = null;
    this.phoneErr = null;
    this.passwordErr = null;
    this.form.reset({
      id: null,
      addressId: null,
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      phone: '',
      unit: '',
      address1: '',
      address2: '',
      city: 'Sydney',
      state: 'New South Wales',
      country: 'Australia',
      postalCode: '',
      isMailSync: '',
      isAdmin: '',
      createdBy: '',
      updatedBy: '',
      role: [],
      gender: []
    })
    this.passwordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]]
    })
  }

  createForm() {
    this.form = this.formBuilder.group({
      id: null,
      addressId: null,
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      phone: '',
      entityTypeId:'',
      unit: '',
      address1: '',
      address2: '',
      city: 'Sydney',
      state: 'New South Wales',
      country: 'Australia',
      postalCode: '',
      isMailSync: '',
      isAdmin: '',
      gender: '',
      role: '',
      createdBy: '',
      updatedBy: '',
    })
    this.passwordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]]
    })
  }
  public passwordresetForm() {
    this.resetpasswordErr = null;
    this.confirmpasswordErr = null;
    this.passwordForm.reset({
       password: '',
       confirmPassword: '',
     })
   }

  passwordValidate() {
    this.resetpasswordErr = null;
    this.confirmpasswordErr = null;
    var data = this.passwordForm.value;
    const strongRegex = /(?=.{6,})/;
    var valid = true;
    if (data.password == Validators || data.password == '') {
      this.resetpasswordErr = "Password is required.";
      valid = false;
    } else if (!strongRegex.test(data.password)) {
      this.resetpasswordErr = "Password is Must be 6 Charcters.";
      valid = false;
    } else if (data.password != data.confirmPassword) {
      this.confirmpasswordErr = "Password doesn't Match.";
      valid = false;
    }
    return valid;
  }
  passwordPost(pid) {
    if (this.passwordValidate()) {
      this.spinner.show();
      this.http.get('/api/user/' +pid).subscribe((res: Response) => {
        this.result = res;
        var data = this.result.details;
        var data1 = this.passwordForm.value;
        data.password = data1.confirmPassword;
        data.updatedBy = parseInt(this.userid);
        this.http.put('/api/user/' + pid, data).subscribe((response) => {
          this.toastr.clear();
          this.result1 = response;
          if (this.result1.success) {
            this.pid =this.result1.response.id
            this.passwordresetForm();
            this.toastr.success('Password Updated successfully', 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.restpass();
            this.spinner.hide();
          } else {
            this.toastr.error(this.result1.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
          }
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })
      })
    }
  }
  validate(num) {
    if(num == 0){
      this.nameErr = null;
      this.lnameErr = null;
      this.emailErr = null;
      this.phoneErr = null;
      this.postalErr = null;
      this.passwordErr = null;
      this.roleErr = null;
      this.genderErr = null;
      var data = this.form.value;
      const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const validNumber = /^\d+$/;
      const strongRegex = /(?=.{6,})/;
      var valid = true;
      if (data.firstName == undefined || data.firstName == '') {
        this.nameErr = "First Name is required.";
        valid = false;
      }
      else if (data.lastName == undefined || data.lastName == '') {
        this.lnameErr = "Last Name is required.";
        valid = false;
      }   
      else if (data.email == EmailValidator || data.email == '') {
        this.emailErr = "Email is required.";
        valid = false;
      } else if (!validEmailRegEx.test(data.email)) {
        this.emailErr = "Invalid Email.";
        valid = false;
      }
      else if (data.gender.length == 0) {
        this.genderErr = "Gender is Required.";
        valid = false;
      }  
      else if (data.phone != '' && !validNumber.test(data.phone)) {
        this.phoneErr = "Invalid Phone Number.";
        valid = false;
      }
      else if (data.role.length == 0) {
        this.roleErr = "Role is Required.";
        valid = false;
      }
      else if (data.password == Validators || data.password == '') {
        this.passwordErr = "Password is required.";
        valid = false;
      } else if (!strongRegex.test(data.password)) {
        this.passwordErr = "Password is Must be 6 Charcters.";
        valid = false;
      }
      return valid;
    }else if(num == 1){
      this.nameErr = null;
      this.lnameErr = null;
      this.emailErr = null;
      this.phoneErr = null;
      this.postalErr = null;
      this.passwordErr = null;
      this.roleErr = null;
      this.genderErr = null;
      var data = this.form.value;
      const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const validNumber = /^\d+$/;
      const strongRegex = /(?=.{6,})/;
      var valid = true;
      if (data.firstName == undefined || data.firstName == '') {
        this.nameErr = "First Name is required.";
        valid = false;
      }
      else if (data.lastName == undefined || data.lastName == '') {
        this.lnameErr = "Last Name is required.";
        valid = false;
      }   
      else if (data.email == EmailValidator || data.email == '') {
        this.emailErr = "Email is required.";
        valid = false;
      } else if (!validEmailRegEx.test(data.email)) {
        this.emailErr = "Invalid Email.";
        valid = false;
      }
      else if (data.gender.length == 0) {
        this.genderErr = "Gender is Required.";
        valid = false;
      }  
      else if (data.phone != '' && !validNumber.test(data.phone)) {
        this.phoneErr = "Invalid Phone Number.";
        valid = false;
      }
      else if (data.role.length == 0) {
        this.roleErr = "Role is Required.";
        valid = false;
      }
      return valid;
    }
    
  }
  submitCust() {
    var data = this.form.value;
    data.isMailSync = "N";
    data.isAdmin = "N";
    data.createdBy = parseInt(this.userid);
    if (this.validate(0)) {
      data.role = data.role[0].id;
      data.gender = data.gender[0].itemName;
      this.spinner.show();
      this.http.post('/api/user/', data).subscribe((response) => {
        this.toastr.clear();
        this.result = response;
        if (this.result.success) {
          this.resetForm();
          this.toastr.success(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          if (this.router.url == "/deals") {
            window.location.reload();
          }
          this.toggleCreateMenu();
          this.getCust();
        } else {
          this.toastr.error(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
        this.errorService.handleError(error);
      })
    }
  }

  updateCust() {
    var data = this.form.value;
    if (this.validate(1)) {
      data.role = data.role[0].id;
      data.password ="";
      data.gender = data.gender[0].itemName;
      data.updatedBy = parseInt(this.userid);
      this.spinner.show();
      this.http.put('/api/user/' + data.id, data).subscribe((response) => {
        this.toastr.clear();
        this.result = response;
        if (this.result.success) {
          this.resetForm();
          this.toastr.success(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.toggleCreateMenu();
          this.getCust();
        } else {

          this.toastr.error(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }

      }, error => {
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
        this.errorService.handleError(error);
      })
    }
  }
  delete(id) {
    
    this.confirmService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) => {
        if (confirmed) {
          this.spinner.show();
          this.http.delete('/api/user/' + id).subscribe((response) => {
            this.toastr.clear();
            this.result = response;
            if (this.result.success) {
              this.toggleCreateMenu();
              this.resetForm();
              this.toastr.success(this.result.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.getCust();
            } else {

              this.toastr.error(this.result.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
            }

          }, error => {
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
            this.errorService.handleError(error);
          })
        }
      })
      .catch(() => {
        return false;
      });
  }



  toggleEditMenu(id,num) {
    this.getRole();
    if(num == 0){
      if (this.popoverform == true) {
        this.popoverform = false;
      } else {
        this.popoverform = true;
      }
      this.nameErr = null;
      this.lnameErr = null;
      this.emailErr = null;
      this.isCreate = false;
      this.pid = id;
      this.rolesArrselect = [];
      this.http.get('/api/user/' + this.pid).subscribe((res: Response) => {
        this.result = res;
        if (this.result) {
          this.rolesArrselect.push({ "id": this.result.role.id, "itemName": this.result.role.name })
          this.form.patchValue({ 'id': this.result.details.id });
          this.form.patchValue({ 'firstName': this.result.details.firstName });
          this.form.patchValue({ 'lastName': this.result.details.lastName });
          this.form.patchValue({ 'email': this.result.details.email });
          this.form.patchValue({ 'isMailSync': this.result.details.isMailSync });
          this.form.patchValue({ 'addressId': this.result.details.addressId });
          this.form.patchValue({ 'phone': this.result.details.phone });
          this.form.patchValue({ 'unit': this.result.location.unit });
          this.form.patchValue({ 'address1': this.result.location.address1 });
          this.form.patchValue({ 'address2': this.result.location.address2 });
          this.form.patchValue({ 'city': this.result.location.city });
          this.form.patchValue({ 'state': this.result.location.state });
          this.form.patchValue({ 'country': this.result.location.country });
          this.form.patchValue({ 'postalCode': this.result.location.postalCode });
          this.form.patchValue({ 'isAdmin': this.result.details.isAdmin });
          this.form.patchValue({ 'createdBy': this.result.details.createdBy });
          // this.form.patchValue({ 'password': this.result.details.password });
          this.form.patchValue({ 'entityTypeId': this.result.details.entityTypeId });
          if (this.result.details.gender == "Male") {
            this.selectedItems = [];
            this.selectedItems.push({ "id": 1, "itemName": "Male" })
          } else if (this.result.details.gender == "Female") {
            this.selectedItems = [];
            this.selectedItems.push({ "id": 2, "itemName": "Female" })
          }
          else if (this.result.details.gender == "Others") {
            this.selectedItems = [];
            this.selectedItems.push({ "id": 3, "itemName": "Others" })
          }
        }
      });
      this.menuState = this.menuState === 'out' ? 'in' : 'out';
    }else{
      if (this.popoverform == true) {
        this.popoverform = false;
      } else {
        this.popoverform = true;
      }
      this.nameErr = null;
      this.lnameErr = null;
      this.emailErr = null;
      this.isCreate = false;
      this.pid = id;
      this.rolesArrselect = [];
      this.http.get('/api/user/' + this.pid).subscribe((res: Response) => {
        this.result = res;
        if (this.result) {
          this.rolesArrselect.push({ "id": this.result.role.id, "itemName": this.result.role.name })
          this.form.patchValue({ 'id': this.result.details.id });
          this.form.patchValue({ 'firstName': this.result.details.firstName });
          this.form.patchValue({ 'lastName': this.result.details.lastName });
          this.form.patchValue({ 'email': this.result.details.email });
          this.form.patchValue({ 'isMailSync': this.result.details.isMailSync });
          this.form.patchValue({ 'addressId': this.result.details.addressId });
          this.form.patchValue({ 'phone': this.result.details.phone });
          this.form.patchValue({ 'unit': this.result.location.unit });
          this.form.patchValue({ 'address1': this.result.location.address1 });
          this.form.patchValue({ 'address2': this.result.location.address2 });
          this.form.patchValue({ 'city': this.result.location.city });
          this.form.patchValue({ 'state': this.result.location.state });
          this.form.patchValue({ 'country': this.result.location.country });
          this.form.patchValue({ 'postalCode': this.result.location.postalCode });
          this.form.patchValue({ 'isAdmin': this.result.details.isAdmin });
          this.form.patchValue({ 'createdBy': this.result.details.createdBy });
          // this.form.patchValue({ 'password': this.result.details.password });
          this.form.patchValue({ 'entityTypeId': this.result.details.entityTypeId });
          if (this.result.details.gender == "Male") {
            this.selectedItems = [];
            this.selectedItems.push({ "id": 1, "itemName": "Male" })
          } else if (this.result.details.gender == "Female") {
            this.selectedItems = [];
            this.selectedItems.push({ "id": 2, "itemName": "Female" })
          }
          else if (this.result.details.gender == "Others") {
            this.selectedItems = [];
            this.selectedItems.push({ "id": 3, "itemName": "Others" })
          }
        }
      });
      this.menuState = this.menuState === 'out' ? 'in' : 'out';
    }
    
  }


  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  restpasslist: boolean = false;
  restpass(){
    if (this.passreset == true) {
      this.passreset = false;
    } else {
      this.passreset = true;
    }
  }
  toggleCreateMenuClose(){
   window.location.reload();
  }
  roleSlierOpen(){
    this.spinner.show();
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
    this.popoverform = false
    this.popoverform1 = false
    this.rolesliderOpenCheck = false;  
    setTimeout(() => {
      this.rolesliderOpenCheck = !this.rolesliderOpenCheck; 
      this.spinner.hide();
     },1000);
  }
  ngOnChanges(){
    this.ngOnChangeRoleCheck = true;
    // this.popoverform = true;
    // this.popoverform1 = false
  }
  
}

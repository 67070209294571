import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, OnInit } from '@angular/core';
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
import { DatePipe } from '@angular/common';//importing the module
import { MyDatePickerModule } from 'mydatepicker';
import { Router } from '@angular/router';
import { DetailsService } from '../../_services';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '../../_services';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { ErrorService } from 'src/app/_services/error.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, animate, transition, style } from '@angular/animations';
@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class RoleComponent implements OnInit {
  @Input('roleSlider') roleSlider;
  @Output() ChangeEvent = new EventEmitter();
  @ViewChild('isIncludeTag') isIncludeTag: ElementRef;
  result: any;
  roles = {};
  roleArr = [];
  rolesArr = [];
  permissionsArr = [];
  permissionsArrselect = [];
  settings: any;
  settings1: any;
  nameErr: any;
  descErr: any;
  permissionErr: any;
  form: FormGroup;
  isCreate: boolean = true;
  isDefaultcheck: boolean = false;
  closeResult: string;
  menuState: string = 'out';
  roleid: any;
  body: {};
  delval:any;
  key: any;
  sort: any;
  userid: any;
  result1 :any;
  result2:any;
  result3 :any;
  result4 :any;
  loggedInUserDomain :any;
  popoverform: boolean = false;
  userTosliderOpen: boolean = false;
  popoverform1: boolean = false;
  roleSliderCloserCheck: boolean = false;
  
  constructor(private http: HttpClient, private confirmService: ConfirmService,
    private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private errorService: ErrorService, DetailsService: DetailsService,private router: Router ) { this.createForm(); }

  ngOnInit() {
    this.getRole();
    this.getpermission();
    this.userid = localStorage.getItem("loggedInUserID");
    this.loggedInUserDomain = localStorage.getItem('loggedInDomainID');
    this.settings = {
      text: "Select",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      singleSelection: false,
      enableCheckAll: false
    };
    this.settings1 = {
      text: "Select",
      disabled: true,
      enableSearchFilter: true,
      classes: "myclass custom-class",
      singleSelection: true,
      enableCheckAll: false
    };
    if (this.router.url == "/admin/user") {
      this.userTosliderOpen = true;
      this.popoverform1 = true;
      this.resetForm();
      this.toggleCreateMenu();
    }else  if (this.router.url == "/deals") {
      this.userTosliderOpen = true;
      this.popoverform1 = true;
      this.resetForm();
      this.toggleCreateMenu();
    }
  }
  toggleCreateMenu() {   
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.isDefaultcheck = false
    this.nameErr = null;
    this.descErr = null;
    this.permissionErr = null;
    this.isCreate = true;
    this.roleid = undefined;
    this.resetForm();
    this.permissionsArrselect = [];
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
  }

  createForm() {
    this.form = this.formBuilder.group({
      domainId :'',
      role: ['', Validators.required],
      description: ['', Validators.required],
      permissionLimitId: '',
      roleId: '',
      createdDate: '',
      isDefault: '',
      isActive: '',
      updatedDate: '',
      createdBy: '',
      updatedBy: '',
    })
  }

  public resetForm() {
    this.form.reset({
      role: '',
      description: '',
      permissionLimitId: [],
      createdDate: '',
      updatedDate: '',
      createdBy: '',
      updatedBy: '',
      isDefault: '',
      isActive: '',
      roleId: null
    })
  }
  arrayToString(string) {
    return string.join(",");
  };
  getpermission() {
    this.http.get('/api/permLimit').subscribe((res: Response) => {
      this.result = res
      this.spinner.hide();
      for (let i = 0; i < this.result.length; i++) {
        if (this.result[i].role != "SiteAdmin" && this.result[i].role != "Customer" && this.result[i].role != "Certifier") {
          this.permissionsArr.push({
            "id": this.result[i].id, "itemName": this.result[i].role, "description": this.result[i].description, "permCode" : this.result[i].permissionCode
          })
        }
      }
    });
  }

  getRole() {
    this.spinner.show();
    this.http.get('/api/roles/').subscribe((res: Response) => {
      this.result = res;
      this.spinner.hide();
      for (let i = 0; i < this.result.length; i++) {
        this.roleArr.push({
          "id": this.result[i].role.id, "itemName": this.result[i].role.name, "description": this.result[i].role.description, "createdDate": this.result[i].role.createdDate, "permission": this.result[i].role.permission, "permissionArr": this.result[i].permArr, "isDefault": this.result[i].role.isDefault
        })
      }

    });
  }
   deletepermission(id, string, rid) {
    this.confirmService.confirm('Please confirm..', 'Do you really want to delete ?')
    .then((confirmed) => {
      if (confirmed) {
        this.spinner.show();
        var string1 = string.replace(/\s/g, "");
        var array = string1.split(",");
        this.delval = [];
        for (let i = 0; i < array.length; i++) {
          if (id != array[i]) {
            this.delval.push(array[i]);
          }
        }
        this.toggleEditMenu(rid, 1);
      } else {
      }
    })
    .catch(() => {
      return false;
    });
  }
  submitRole() {
    var data = this.form.value;
    if (this.validate()) {
      this.spinner.show();
      var cusr2 = [];
      for (let i = 0; i < data.permissionLimitId.length; i++) {
        cusr2.push(data.permissionLimitId[i].permCode);
      }
      data.createdBy = this.userid;
      data.domainId =this.loggedInUserDomain;
      data = { "createdBy": data.createdBy,"domainId" :data.domainId, "name": data.role, "description": data.description, "permission": this.arrayToString(cusr2) };
      this.http.post('/api/role', data).subscribe((response) => {
        this.toastr.clear();
        this.result = response;
        if (this.result.success) {
          this.resetForm();
          this.toastr.success(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          if (this.router.url == "/admin/user") {
            this.toggleCreateMenuClose();
          }else  if (this.router.url == "/deals") {
            this.toggleCreateMenuClose();
          }else{
            this.toggleCreateMenu();
            this.roleArr = [];
            this.getRole();
          }
     
        } else {
          this.toastr.error(this.result.message, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
        this.errorService.handleError(error);
      })
    }
  }
  delete(id) {
    this.confirmService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) => {
        if (confirmed) {
          this.spinner.show();
          this.http.delete('/api/role/' + id).subscribe((response) => {
            this.toastr.clear();
            this.result = response;
            if (this.result.success) {
              this.resetForm();
              this.toastr.success(this.result.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.getRole();
              this.toggleCreateMenu();
              this.popoverform=false;
              this.roleArr = [];
            } else {

              this.toastr.error(this.result.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.spinner.hide();
            }
          }, error => {
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
            this.errorService.handleError(error);
          })
        } else {
        }
      })
      .catch(() => {
        return false;
      });
  }

  toggleEditMenu(id, num) {
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    if (num == 0) {
      this.permissionsArrselect = [];
      this.nameErr = null;
      this.descErr = null;
      this.permissionErr = null;
      this.isCreate = false;
      this.isDefaultcheck = false;
      this.roleid = id;
      this.http.get('/api/role/' + this.roleid).subscribe((res: Response) => {
        this.result = res;
        if (this.result) {
          this.form.patchValue({ 'roleId': this.result.role.id });
          this.form.patchValue({ 'role': this.result.role.name });
          this.form.patchValue({ 'description': this.result.role.description });
          this.form.patchValue({ 'isActive': this.result.role.isActive });
          this.form.patchValue({ 'createdDate': this.result.role.createdDate });
          this.form.patchValue({ 'createdBy': this.result.role.createdBy });
          this.form.patchValue({ 'updatedDate': this.result.role.updatedDate });
          this.form.patchValue({ 'updatedBy': this.result.role.updatedBy });
          this.form.patchValue({ 'domainId': this.result.role.domainId });
          if (this.result.role.isDefault == 'Y') {
            this.isDefaultcheck = true;
            this.form.patchValue({ 'isDefault': 'Y' });
          } else {
            this.isDefaultcheck = false;
            this.form.patchValue({ 'isDefault': 'N' });
          }
          for (let i = 0; i < this.result.permArr.length; i++) {
            if(this.roleArr)
            this.permissionsArrselect.push({
              "id": this.result.permArr[i].id, "itemName": this.result.permArr[i].name,"description": this.result.permArr[i].description, "permCode" : this.result.permArr[i].permCode
            })
          }

        }
      })
      this.menuState = this.menuState === 'out' ? 'in' : 'out';
    } else {
      this.permissionsArrselect = [];
      this.nameErr = null;
      this.descErr = null;
      this.permissionErr = null;
      this.isCreate = false;
      this.roleid = id;
      this.http.get('/api/role/' + this.roleid).subscribe((res: Response) => {
        this.result = res;
        if (this.result) {
          this.form.patchValue({ 'roleId': this.result.role.id });
          this.form.patchValue({ 'role': this.result.role.name });
          this.form.patchValue({ 'description': this.result.role.description });
          this.form.patchValue({ 'isActive': this.result.role.isActive });
          this.form.patchValue({ 'createdDate': this.result.role.createdDate });
          this.form.patchValue({ 'createdBy': this.result.role.createdBy });
          this.form.patchValue({ 'updatedDate': this.result.role.updatedDate });
          this.form.patchValue({ 'updatedBy': this.result.role.updatedBy });
          this.form.patchValue({ 'domainId': this.result.role.domainId });
          if (this.result.role.isDefault == 'Y') {
            this.isDefaultcheck = true;
            this.form.patchValue({ 'isDefault': 'Y' });
          } else {
            this.isDefaultcheck = false;
            this.form.patchValue({ 'isDefault': 'N' });
          }
         this.updateRole(1)
        }
      })
    }
  }

  updateRole(num) {
    var cusr = [];
    if (num == 0) {
      var data = this.form.value;
      for (let i = 0; i < data.permissionLimitId.length; i++) {
        cusr.push(data.permissionLimitId[i].permCode);
      }
      this.body = {};
      this.body["id"] = this.roleid;
      this.body["name"] = this.form.value["role"];
      this.body["description"] = this.form.value["description"];
      this.body["permission"] = this.arrayToString(cusr);
      this.body["createdDate"] = this.form.value["createdDate"];
      this.body["updatedDate"] = this.form.value["updatedDate"];
      this.body["domainId"] = this.form.value["domainId"];
      this.body["createdBy"] = this.form.value["createdBy"];
      this.body["updatedBy"] = this.userid;
      this.body["isActive"] = "Y";
      this.body["isDefault"] = this.form.value["isDefault"];
      this.body["permissionCode"] = this.form.value["permissionCode"];
      if (this.validate()) {
        this.spinner.show();
        this.http.put('/api/role/', this.body).subscribe((response) => {
          this.toastr.clear();
          this.result = response;
          if (this.result.success) {
            this.resetForm();
            this.toastr.success(this.result.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.toggleCreateMenu();
            this.popoverform=false;
            this.getRole();
            this.roleArr = [];
          } else {
  
            this.toastr.error(this.result.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
          }
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })
      }
    } else {
      cusr = this.delval;
      this.body = {};
      this.body["id"] = this.form.value["roleId"];
      this.body["name"] = this.form.value["role"];
      this.body["description"] = this.form.value["description"];
      this.body["permission"] = this.arrayToString(cusr);
      this.body["createdDate"] = this.form.value["createdDate"];
      this.body["updatedDate"] = this.form.value["updatedDate"];
      this.body["domainId"] = this.form.value["domainId"];
      this.body["createdBy"] = this.form.value["createdBy"];
      this.body["updatedBy"] = this.userid;
      this.body["isActive"] = "Y";
      this.body["isDefault"] = this.form.value["isDefault"];
      this.body["permissionCode"] = this.form.value["permissionCode"];
        this.spinner.show();
        this.http.put('/api/role/', this.body).subscribe((response) => {
          this.toastr.clear();
          this.result = response;
          if (this.result.success) {
            this.resetForm();
            this.toastr.success("Permission deleted successfully", 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.popoverform=false;
            this.getRole();
            this.roleArr = [];
          } else {
  
            this.toastr.error(this.result.message, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
          }
        }, error => {
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
          this.errorService.handleError(error);
        })
    }
  
  }


  validate() {
    this.nameErr = null;
    this.descErr = null;
    this.permissionErr = null;
    var data = this.form.value;
    var valid = true;
    if (data.role == undefined || data.role == '') {
      this.nameErr = "Role Name is required.";
      valid = false;
    }
    else if (data.permissionLimitId.length == 0) {
      this.permissionErr = "Permission is required";
      valid = false;
    }
    return valid;
  }
  toggleCreateMenuClose(){
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
    this.popoverform1= false
    this.popoverform= false
    this.roleSliderCloserCheck = true;
  }

}

import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { DetailsService } from '../_services/index';
import * as moment from 'moment/moment.js';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { ErrorService } from 'src/app/_services/error.service';
import { PermissionService } from 'src/app/_services/permission.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { trigger, state, animate, transition, style } from '@angular/animations';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { HttpHeaders } from '@angular/common/http';
import { NgForm, EmailValidator } from '@angular/forms';
import { ITreeOptions } from 'angular-tree-component';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';
import { TreeNode, TreeModel, TREE_ACTIONS, KEYS, IActionMapping, TreeComponent } from 'angular-tree-component';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DealDetailsModel } from 'src/app/model/deal-details-model';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class DetailsComponent extends DealDetailsModel implements OnInit {
  maxChars = 255;
  @ViewChild('isIncludeTag') isIncludeTag: ElementRef;
  @ViewChild('tabRef') tabRef;
  @ViewChild('myContextMenu') public myContextMenu: ContextMenuComponent;
  @ViewChild('myFileContextMenu') public myFileContextMenu: ContextMenuComponent;
  @ViewChild('tree') treeComponent: TreeComponent;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '25rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    customClasses: [{ name: "quote", class: "quote", },
    { name: 'redText', class: 'redText' },
    { name: "titleText", class: "titleText", tag: "h1", },]
  };


  constructor(private DetailsService: DetailsService, private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService, private toastr: ToastrService,
    private errorService: ErrorService,
    private confirmService: ConfirmService,
    private contextMenuService: ContextMenuService,
    private formBuilder: FormBuilder,
    private permissionService: PermissionService,
    private http: HttpClient) {
    super();
    this.eventListSelected = [];
    this.customerAllArrselect = [];
    this.customermail = [];
    this.allCustomer = [];
    this.userid = localStorage.getItem("loggedInUserID");
    this.settings = {
      singleSelection: true,
      enableSearchFilter: true,
    }
    this.settings1 = {
      singleSelection: true,
      disabled: true
    }
    this.settingssingle = {
      text: "Select",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      singleSelection: true,
      enableCheckAll: false
    }
    this.dataSave = "Generate Estimation";
    this.dataSavePreview = "Preview Estimation"
    this.btnName = "Save";
    this.basicHomeArr = [];
    this.bed_value = 1;
    this.bath_value = 1;
    this.garage_value = 1;
    this.defaultHomeId = '';
    this.widthRange = 5;
    this.storeyId = null;
    this.basicHomeTotal = 0;
    this.desginGrData = [];
    this.designActive = 0;
    this.partTwoActive = 0;
    this.estimationProperty = [];
    this.estimationOptDisplay = '';
    this.propSaveChild = [];
    this.estimationNext = true;
    this.basicHomeSaveArr = [];
    this.estimationPropTitle = 'Basic Home Package';
    this.finalEstHomePreArr = [];
    this.finalEstGroupPreArr = [];
    this.certMang = {};

  }


  ngOnInit() {
    this.admin = localStorage.getItem("loggedInUserAdmin");
    this.loggedInUserDomain = localStorage.getItem('loggedInDomainID');
    this.createForms();
    this.getDetails();
    this.getdocuments();
    this.step = "step1";
    this.today = new Date();
    this.noteTypeList = [
      { "id": 2, "itemName": "Internal" },
      { "id": 3, "itemName": "External" },
    ];
    this.options = {
      allowDrop: true,
      allowDragoverStyling: false,
      displayField: ''
    };
    this.myDatePickerOptions = {
      dateFormat: 'dd-mm-yyyy',
      disableUntil: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() - 1 }

    }
    this.myDatePickerOptions2 = {
      dateFormat: 'dd-mm-yyyy',
      disableUntil: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() - 1 }
    }
  }
  myContextClick($event, data, id) {

    this.contextMenuService.show.next({
      contextMenu: this.myContextMenu,
      event: <any>$event,
      item: data,
    });
    const treeModel: TreeModel = this.treeComponent.treeModel;
    treeModel.getNodeById(id).setIsActive(true);
    $event.preventDefault();
    $event.stopPropagation();
  }
  myFileContextMenuClick($event, data, id) {

    this.contextMenuService.show.next({
      contextMenu: this.myFileContextMenu,
      event: <any>$event,
      item: data,
    });
    const treeModel: TreeModel = this.treeComponent.treeModel;
    treeModel.getNodeById(id).setIsActive(true);
    $event.preventDefault();
    $event.stopPropagation();
  }


  createForms() {
    this.projectUserForm = this.createProjectUserForm(this.formBuilder);
    this.orgForm = this.createProjectorgForm(this.formBuilder);
    this.custForm = this.createCustForm(this.formBuilder);
    this.emailForm = this.createEmailForm(this.formBuilder);
    this.emailFormAttach = this.createEmailFormAttach(this.formBuilder);
    this.uploadFileForm = this.createUploadFileForm(this.formBuilder);
    this.notesUserForm = this.createnNotesUserForm(this.formBuilder);
    this.TaskForm = this.createTaskForm(this.formBuilder);
    this.taskComment = this.createTaskComment(this.formBuilder);
    this.estimationNameForm = this.createEstimationNameForm(this.formBuilder);
    this.documentPermissionForm = this.formBuilder.group({
      mapId:'',
      isCertifierShared: 'N',
      isCustomerShared: 'N',
      isExternalUserShared: 'N',
    });
    this.folderform = this.formBuilder.group({
      folder: '',
      projectId: '',
      createdDate: '',
      updatedDate: '',
      createdBy: '',
      updatedBy: '',
      rootId: '',
      id: '',
      isActive: ''
    })
    this.documentform = this.formBuilder.group({
      document: '',
      projectId: '',
      createdDate: '',
      updatedDate: '',
      createdBy: '',
      updatedBy: '',
      rootId: '',
      id: '',
      isActive: '',
      fileFolderId: '',
      fileId: '',
    })
    this.keypeopleForm = this.formBuilder.group({
      councilId: ''
    });
  }
  showSelectedDate() {
    let completeBy: any = this.form.controls['completeBy'].value;
  }
  onDateChanged(event) {
  }

  getPermissionDetails() {
    this.http.get('/api/user/' + this.userid).subscribe((res: Response) => {
      this.result = res;
      var permision = this.result.role.permision;
      if (permision.length > 0) {
        var projectPermission = this.permissionService.getProjectPermission(permision);
        var dealPermission = this.permissionService.getDealPermission(permision);
        var approvalPermission = this.permissionService.getApprovalPermission(permision);
        if (projectPermission == this.permissionService.PROJECT_ADMIN ||
          dealPermission == this.permissionService.DEAL_ADMIN ||
          approvalPermission == this.permissionService.APPROVAL_ADMIN ||
          this.admin == "Y" ||
          this.userid == this.details.managerId) {
          this.isAdminDis = true;
          this.isDeal = true;
          this.gettask();
        } else if (projectPermission == this.permissionService.PROJECT_OBSERVER ||
          dealPermission == this.permissionService.DEAL_OBSERVER ||
          approvalPermission == this.permissionService.APPROVAL_OBSERVER) {
          this.isDeal = false;
          this.isdis = true;
          this.resetTask = false;
          this.gettask();
          this.getDealAttendtesNotes();
        }
      }
    }, error => {
      this.errorService.handleError(error);
    });
  }
  getDealAttendtesNotes() {
    this.http.get('/api/notes/usernotes/' + this.projectId + "/" + this.userid + "/deal").subscribe((res: Response) => {
      this.result = res;
      this.result.forEach(r => {
        var desc = r.description;
        var edit = $(desc).text();
        this.noteArr.push({
          "id": r.id, "descName": r.description, "heading": r.heading, "createdAt": r.createdAt, "createdBy": r.createdBy,
          "viewType": r.viewType
        })
      })
    }, error => {
      this.errorService.handleError(error);
    });
  }

  async getDetails() {
    this.spinner.show();
    const id = +this.route.snapshot.paramMap.get('id');
    this.projectId = id;

    var result = await this.DetailsService.getDealStates();
    this.result1 = result;
    this.result1.forEach(r => {
      if (r.isFinal == "N") {
        this.dealStates[r.id] = r;
        this.dealstateArr.push({
          "id": r.id, "itemName": r.name, "orders": r.orders
        })
      }
    });
    result = await this.DetailsService.getDetailsID(id);
    this.details = result;
    if (this.details.orgId) { this.orgExists = true; }
    var projStateId = this.details.projectStateId;
    this.projectStateIdOrder = this.dealStates[projStateId].orders;
    result = await this.DetailsService.getUsers();
    this.result5 = result;
    this.projectUserArr = this.result5;
    if (result) {
      this.users = {};
      this.result5.forEach(r => {
        this.users[r.details.id] = r.details;
      });
    } else {
      this.spinner.hide();
    }
    result = await this.DetailsService.rolesPermission("DEAL");
    this.rolesPermissionArr = result;
    this.detailsArr = [];
    this.projectUserArr = this.rolesPermissionArr;
    if (this.rolesPermissionArr) {
      this.rolesPermissionArr.forEach(r => {
        this.detailsArr.push(r.id);
      });
    }
    result = await this.DetailsService.getRoles();
    this.result1 = result;
    this.roles = {};
    this.result1.forEach(r => {
      this.roles[r.role.id] = r.role;
      this.rolesArr.push({
        "id": r.role.id, "itemName": r.role.name
      })
    })


    result = await this.DetailsService.dateCount(id);
    this.result1 = result;
    this.dateCountArr = this.result1;
    this.roleDetails(id);
    this.getPermissionDetails();
    this.getFromEmail();
    const TaskId = +this.route.snapshot.paramMap.get('tid');
    if (TaskId != 0) {
      this.taskMenu(TaskId);
    }
    this.spinner.hide();

  }
  arr_diff(a1, a2) {
    var a = [], diff = [];
    for (var i = 0; i < a1.length; i++) {
      a[a1[i]] = true;
    }
    for (var i = 0; i < a2.length; i++) {
      if (a[a2[i]]) {
        delete a[a2[i]];
      } else {
        a[a2[i]] = true;
      }
    }
    for (var k in a) {
      diff.push(k);
    }

    return diff;
  }
  arr_diff_cust(a1, a2) {
    var a = [], diff = [];
    for (var i = 0; i < a1.length; i++) {
      a[a1[i]] = true;
    }
    for (var i = 0; i < a2.length; i++) {
      if (a[a2[i]]) {
        delete a[a2[i]];
      } else {
        a[a2[i]] = true;
      }
    }
    for (var k in a) {
      diff.push(k);
    }

    return diff;
  }
  orgsDetails(id) {
    this.http.get('/api/project/details/org/' + id).subscribe((res: Response) => {
      this.result = res;
      this.orgAllDetails = this.result.Organization;
    }, error => {
      this.errorService.handleError(error);
    });
  }
  onchangtab(value) {
    var id = value.nextId;
    this.tab01 = false;
    this.tab02 = false;
    this.tab03 = false;
    this.tab04 = false;
    this.tab05 = false;
    this.estimationTabSelect = false;
    if (id == "changelog") {
      this.projectlogArr = [];
      this.getprolog();
    } else if (id == "Notes") {
      this.noteArr = [];
      this.getnotes();

    } else if (id == "Upload") {
      this.uploadFileArr = [];
      this.getfiles();
    }
    else if (id == "Email") {
      this.mailContentsArr = [];
      this.getMailContent();
    } else if (id == "Task") {
      this.taskArr = [];
      this.taskCommentArr = [];
      this.gettask();
      this.tab01 = false;
      this.tab02 = false;
      this.tab03 = false;
      this.tab04 = false;
      this.tab05 = false;
      this.estimationTabSelect = false;
    } else if (id == "Estimation" && this.estimationTabSelect == false) {
      this.estimationSelectActive = false;
      this.getEstimationVersionCall(0);
    } else if (id == "Project") {
      this.getalllog();
    }

  }
  onchangtabatt(value) {
    var id = value.nextId;
    if (id == "Notes") {
      this.noteArr = [];
      this.getnotes();
    } else if (id == "Upload") {
      this.uploadFileArr = [];
      this.getfiles();
    }
    else if (id == "Email") {
      this.mailContentsArr = [];
      this.getMailContent();
    } else if (id == "Task") {
      this.taskArr = [];
      this.taskCommentArr = [];
      // this.getDealAttendtesTask();
      this.gettask();
    }

  }
  async getalllog() {
    var result = await this.DetailsService.allActivitiy(this.projectId);
    this.result1 = result;
    this.allActivityArr = this.result1;

  }
  async getprolog() {
    var result = await this.DetailsService.getProjectLog(this.projectId);
    this.result1 = result;
    this.projectlogArr = this.result1;

  }
  async getnotes() {
    if (this.internaluser == true) {
      var result = await this.DetailsService.getNotesDetailsID(this.projectId, this.userid);
    } else {
      var result = await this.DetailsService.getNotesDetailsIDexternsl(this.projectId, this.userid);
    }
    this.result1 = result;
    this.result1.forEach(r => {
      var desc = r.description;
      var edit = $(desc).text();
      this.noteArr.push({
        "id": r.id, "descName": r.description, "heading": r.heading, "createdAt": r.createdAt, "createdBy": r.createdBy, "viewType": r.viewType
      })
    })
  }
  async getfiles() {
    var result = await this.DetailsService.uploadFile(this.projectId);
    this.result1 = result;
    this.uploadFileArr = this.result1;
    this.allActivityArr = [];
  }

  async gettask() {
    var result = await this.DetailsService.getTaskId(this.projectId);
    this.result1 = result;
    this.taskArr = this.result1;
  }
  async getDetailsOnly() {
    const id = +this.route.snapshot.paramMap.get('id');
    this.projectId = id;
    var result = await this.DetailsService.getDetailsID(id);
    this.details = result;
    if (this.details.orgId) { this.orgExists = true; }
    this.allActivityArr = [];
  }

  async roleDetails(id) {
    var result = await this.DetailsService.getProjectsUser(id);
    this.projectUsers = result;
    this.userArr = [];
    for (let i = 0; i < this.projectUsers.length; i++) {
      if (this.projectUsers[i].userId == parseInt(this.userid)) {
        this.internaluser = true;
      }
      this.userArr.push(this.projectUsers[i].userId);
    }

    this.projectUsers.forEach(r => {
      this.http.get('/api/user/' + r.userId).subscribe((res: Response) => {
        this.result = res;
        this.projectUsersArr.push({
          "id": this.result.details.id, "itemName": this.result.details.firstName + " " + this.result.details.lastName
        })
      }, error => {
        this.errorService.handleError(error);
      });
    })
  }

  async customerDetials(id) {
    var result = await this.DetailsService.getCustomerID(id);
    this.customer = result;
    this.customermail.push(this.customer.details.email);
  }
  CustForMail() {
    if (this.emailForm.value.to) {
      if (this.customermail.length != 0) {
        var iterator = this.customermail.toString();
        var add = this.emailForm.value.to + "," + iterator
        this.emailForm.patchValue({ 'to': add });
        this.emailValidate(0);

      }
    } else {
      if (this.customermail.length != 0) {
        var iterator = this.customermail.toString();
        this.emailForm.patchValue({ 'to': iterator });
        this.emailValidate(0);

      }
    }

  }
  getMailContent() {
    this.spinner.show();
    this.http.get('/api/mail/contents/' + this.projectId).subscribe((res: Response) => {
      this.result = res;
      if (this.result) {
        this.mail = {};
        this.result.forEach(r => {
          var recipient = r.recipient;
          var tomail = [];
          var bccmail = [];
          var ccmail = [];
          var frommail = [];
          for (let i = 0; i < r.recipient.length; i++) {
            if (r.recipient[i].type == 'To') {
              tomail.push(r.recipient[i].email)
            }
            if (r.recipient[i].type == 'Bcc') {
              bccmail.push(r.recipient[i].email)
            }
            if (r.recipient[i].type == 'Cc') {
              ccmail.push(r.recipient[i].email)
            }
            if (r.recipient[i].type == 'From') {
              frommail.push(r.recipient[i].email)
            }
          }
          this.mailContentsArr.push({
            "id": r.content.id, "subject": r.content.subject, "body": r.content.minimalBody, "messageId": r.content.messageId, "recipient": r.recipient, "tomail": tomail, "bccmail": bccmail, "ccmail": ccmail, "frommail": frommail,
            "createdDate": r.content.createdDate
          })
          recipient.forEach(rs => {
            this.mail[rs.messageId] = rs;
            if (rs.type == "To") {
              var mailid = rs.email
            }
            this.mailSendArr.push({
              "id": rs.id, "tomail": mailid
            })
          })
          this.spinner.hide();
        })
      }
      this.spinner.hide();
    }, error => {
      this.toasterAlert(error, "error");
      this.errorService.handleError(error);
    });
  }

  RemoveUserFromProject(id) {
    this.confirmService.confirm('Please confirm..', 'Do you want to remove the user ?')
      .then((confirmed) => {
        if (confirmed) {
          window.scrollTo(0, 0);
          this.spinner.show();
          this.http.delete('/api/projectUserRole/' + id).subscribe((response) => {
            this.res = response;
            if (this.res.success == true) {
              this.toasterAlert(this.res.message, "success");
              this.projectUsersArr = [];
              this.roleDetails(this.projectId);
              this.getDetailsOnly();
              this.spinner.hide();
            } else {
              this.toasterAlert(this.res.message, "'error'");
              this.spinner.hide();
            }
          }, error => {
            this.errorService.handleError(error);
          });
        }
      })
      .catch(() => {
        return false;
      });
  }

  toggleAddProjectUser(number) {
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.usersArr = [];
    this.projectUserErr = null;
    this.resetForms();
    if (number == 0) {
      var result2 = this.arr_diff(this.detailsArr, this.userArr);
      this.projectUserArr.forEach(r => {
        for (let i = 0; i < result2.length; i++) {
          if (result2[i] == r.id) {
            this.usersArr.push({
              "id": r.id, "itemName": r.firstName + " " + r.lastName + " - " + r.roleName, "roleId": r.role, "roleName": r.roleName
            })
          }
        }
      });
    }
    if (this.menuStateOrgEdit == 'in') {
      this.menuStateOrgEdit = 'out';
    }
    if (this.menuStateUploadFile == 'in') {
      this.menuStateUploadFile = 'out';
    }
    if (this.menuStateNotesUserEdit == 'in') {
      this.menuStateNotesUserEdit = 'out';
    }
    if (this.menuStateEmail == 'in') {
      this.menuStateEmail = 'out';
    }
    if (this.menuStateTaskEdit == 'in') {
      this.menuStateTaskEdit = 'out';
    }
    if (this.menuStateEmailEdit == 'in') {
      this.menuStateEmailEdit = 'out';
    }
    this.menuStateProjectUserEdit = this.menuStateProjectUserEdit === 'out' ? 'in' : 'out';
  }
  submitProjectUser() {
    var data1 = {};
    if (this.projectUserValidate()) {
      this.spinner.show();
      var data = this.projectUserForm.value;
      data1 = { "userId": data.userId[0].id, "roleId": data.userId[0].roleId, "projectId": this.projectId, "createdBy": this.userid }
      this.http.post('/api/projectUserRole', data1).subscribe((response) => {
        this.res = response;
        if (this.res.success == true) {
          this.toasterAlert(this.res.message, "success");
          this.projectUsersArr = [];
          this.roleDetails(this.projectId);
          this.spinner.hide();
          this.toggleAddProjectUser(1);
          this.getDetailsOnly();
          this.spinner.hide();
        } else {
          this.toasterAlert(this.res.message, "error");
          this.spinner.hide();
        }
      }, error => {
        this.errorService.handleError(error);
        this.toggleAddProjectUser(1);
      });
    }

  }

  toggleAddNotesUser() {

    this.noteHeadingErr = null;
    this.noteDescErr = null;
    this.isCreate = true;
    this.resetForms();
    this.tab01 = false;
    if (this.menuStateOrgEdit == 'in') {
      this.menuStateOrgEdit = 'out';
    }
    if (this.menuStateUploadFile == 'in') {
      this.menuStateUploadFile = 'out';
    }
    if (this.menuStateProjectUserEdit == 'in') {
      this.menuStateProjectUserEdit = 'out';
    }
    if (this.menuStateEmail == 'in') {
      this.menuStateEmail = 'out';
    }
    if (this.menuStateTaskEdit == 'in') {
      this.menuStateTaskEdit = 'out';
    }
    if (this.menuStateEmailEdit == 'in') {
      this.menuStateEmailEdit = 'out';
    }
    this.menuStateNotesUserEdit = this.menuStateNotesUserEdit === 'out' ? 'in' : 'out';
  }
  toggleAddNotesUsercancel() {
    this.tab01 = false
  }
  toggleuploadcancel() {
    this.filesUploadArr = [];
    this.tab02 = false;
    this.fileToUpload = false;
  }
  toggleEmailcancel() {
    this.tab03 = false
  }
  toggleAddTask1() {
    this.custErr = null;
    this.nameErr = null;
    this.detailsErr = null;
    this.fileTaskErr = null;
    this.taskCommentesAr = [];
    this.taskCommentArr = [];
    this.taskAttArr = [];
    this.dateErr = null;
    this.StartdateErr = null;
    this.isCreate = true;
    this.resetForms();
    this.TaskForm.enable();
    this.tab04 = false;
  }
  submitNotesUser() {
    if (this.noteValidate()) {
      this.spinner.show();
      let data = this.notesUserForm.value;
      data.userId = parseInt(this.userid);
      data.createdBy = parseInt(this.userid);
      data.viewType = parseInt(data.viewType[0].id);
      data.domainId = parseInt(this.loggedInUserDomain);
      data.projectId = this.projectId;
      this.http.post('api/notes/deal', data).subscribe((response) => {
        this.res = response;
        if (this.res.success == true) {
          this.toasterAlert(this.res.message, "success");
          this.toggleAddNotesUser();
          this.tab01 = false;
          this.isCreate = true;
          this.resetForms();
          this.getnotes();
          this.spinner.hide();
        } else {
          this.toasterAlert(this.res.message, "Error");
          this.spinner.hide();
        }
      }, error => {
        this.errorService.handleError(error);
      });
      this.noteArr = [];
    }
  }
  noteEditMenu(id) {
    this.tab01 = true;
    this.tab02 = false;
    this.tab03 = false;
    this.tab04 = false;
    this.noteHeadingErr = null;
    this.noteDescErr = null;
    this.isCreate = false;
    this.nid = id;
    this.http.get('/api/note/' + this.nid).subscribe((res: Response) => {
      this.result = res;
      if (this.result) {
        this.notesUserForm.patchValue({ 'id': this.result.id });
        this.notesUserForm.patchValue({ 'heading': this.result.heading });
        this.notesUserForm.patchValue({ 'description': this.result.description });
        this.notesUserForm.patchValue({ 'isActive': this.result.isActive });
        this.notesUserForm.patchValue({ 'userId': this.result.userId });
        this.notesUserForm.patchValue({ 'projectId': this.result.projectId });
        this.notesUserForm.patchValue({ 'createdAt': this.result.createdAt });
        this.notesUserForm.patchValue({ 'updatedAt': this.result.updatedAt });
        this.notesUserForm.patchValue({ 'createdBy': this.result.createdBy });
        this.notesUserForm.patchValue({ 'updatedBy': this.result.updatedBy });
        this.notesUserForm.patchValue({ 'domainId': this.result.domainId });

        if (this.result.createdBy == this.userid || this.admin == "Y") {
          this.is_delete = true;
          this.noteCreatedAnddeleteUSer = false;
        } else {
          this.noteCreatedAnddeleteUSer = true;
          this.is_delete = false;
        }
        if (this.result.viewType == "2") {
          this.eventListSelected = [];
          this.eventListSelected.push({ "id": 2, "itemName": "Internal" })
        } else if (this.result.viewType == "3") {
          this.eventListSelected = [];
          this.eventListSelected.push({ "id": 3, "itemName": "External" })
        }
      }
    }, error => {
      this.errorService.handleError(error);
    });
    this.menuStateNotesUserEdit = this.menuStateNotesUserEdit === 'out' ? 'in' : 'out';
  }
  updateNote() {
    if (this.noteValidate()) {
    this.body = {};
    this.body["id"] = this.nid;
    this.body["viewType"] = this.notesUserForm.value["viewType"][0].id;
    this.body["heading"] = this.notesUserForm.value["heading"];
    this.body["description"] = this.notesUserForm.value["description"];
    this.body["userId"] = this.notesUserForm.value["userId"];
    this.body["projectId"] = this.notesUserForm.value["projectId"];
    this.body["createdAt"] = this.notesUserForm.value["createdAt"];
    this.body["updatedAt"] = this.notesUserForm.value["updatedAt"];
    this.body["createdBy"] = this.notesUserForm.value["createdBy"];
    this.body["domainId"] = this.notesUserForm.value["domainId"];
    this.body["updatedBy"] = this.userid;
    this.body["isActive"] = "Y";
      this.spinner.show();
      this.http.put('/api/notes/deal', this.body).subscribe((response) => {
        this.resetForms();
        this.res = response;
        if (this.res.success == true) {
          this.toasterAlert(this.res.message, "success");
          this.spinner.hide();
          this.tab01 = false;
          this.getnotes();
        } else {
          this.toasterAlert(this.res.message, "Error");
          this.spinner.hide();
        }
      }, error => {
        this.errorService.handleError(error);
      });
      this.noteArr = [];
    }

  }
  delateNote() {
    this.confirmService.confirm('Please confirm..', 'Do you really want to delete ?')
      .then((confirmed) => {
        if (confirmed) {
          this.spinner.show();
          this.http.delete('/api/notes/' + this.nid).subscribe((response) => {
            this.resetForms();
            this.isCreate = true;
            this.res = response;
            if (this.res.success == true) {
              this.toasterAlert(this.res.message, "success");
              this.spinner.hide();
              this.tab01 = false;
              this.getnotes();
              this.noteArr = [];
            } else {
              this.toasterAlert(this.res.message, "Error");
            }
          }, error => {
            this.spinner.hide();
            this.errorService.handleError(error);
          })
          this.getnotes();
        }
      })
      .catch(() => {
        return false;
      });
  }

  projectUserValidate() {
    this.projectUserErr = null;
    var valid = true;
    var data = this.projectUserForm.value;
    if (data.userId.length == 0) {
      this.projectUserErr = "User is required";
      valid = false;
    }
    return valid;
  }

  async toggleAddProjectOrg() {
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
      var result = await this.DetailsService.getOrgs();
      this.result1 = result;
      this.orgs = {};
      this.result1.forEach(r => {
        this.orgs[r.id] = r;
        this.orgsArr.push({
          "id": r.id, "itemName": r.orgName
        })
      })
    }
    this.resetForms();
    this.orgErr = null;
    if (this.menuStateNotesUserEdit == 'in') {
      this.menuStateNotesUserEdit = 'out';
    }
    if (this.menuStateUploadFile == 'in') {
      this.menuStateUploadFile = 'out';
    }
    if (this.menuStateProjectUserEdit == 'in') {
      this.menuStateProjectUserEdit = 'out';
    }
    if (this.menuStateEmail == 'in') {
      this.menuStateEmail = 'out';
    }
    if (this.menuStateTaskEdit == 'in') {
      this.menuStateTaskEdit = 'out';
    }
    if (this.menuStateEmailEdit == 'in') {
      this.menuStateEmailEdit = 'out';
    }
    this.menuStateOrgEdit = this.menuStateOrgEdit === 'out' ? 'in' : 'out';
  }
  submitOrg() {
    var post = {};
    if (this.orgValidate()) {
      var data = this.orgForm.value;
      this.details.orgId = data.orgId[0].id;
      this.details.updatedBy = this.userid;
      this.http.put('/api/project', this.details).subscribe((response) => {
        this.res = response;
        if (this.res.success == true) {
          this.toasterAlert(this.res.message, "success");
          this.toggleAddProjectOrg();
          this.getDetailsOnly();
          this.spinner.hide();
        } else {
          this.toasterAlert(this.res.message, "Error");
          this.spinner.hide();
        }
      }, error => {
        this.toasterAlert(error, "Error");
        this.errorService.handleError(error);
      });
    }
  }
  removeOrg() {
    this.confirmService.confirm('Please confirm..', 'Do you want to detach the Organization ?')
      .then((confirmed) => {
        if (confirmed) {
          this.spinner.show();
          this.details.orgId = null;
          this.http.put('/api/project', this.details).subscribe((response) => {
            this.res = response;
            if (this.res.success == true) {
              this.toasterAlert(this.res.message, "success");
              this.getDetailsOnly();
              this.spinner.hide();
            } else {
              this.toasterAlert(this.res.message, "Error");
              this.spinner.hide();
            }
          }, error => {
            this.errorService.handleError(error);
          });
        }
      })
      .catch(() => {
        return false;
      });
  }
  removeNotes(id) {
    this.confirmService.confirm('Please confirm..', 'Do you want to delete the Notes ?')
      .then((confirmed) => {
        if (confirmed) {
          this.http.delete('/api/notes/' + id).subscribe((response) => {
            this.spinner.show();
            this.res = response;
            if (this.res.success == true) {
              this.toasterAlert(this.res.message, "success");
              this.spinner.hide();
            } else {
              this.toasterAlert(this.res.message, "Error");
              this.spinner.hide();
            }
          }, error => {
            this.errorService.handleError(error);
          });
          this.getnotes();
        }
        this.noteArr = [];
        this.spinner.hide();
      })
      .catch(() => {
        return false;
      });
  }

  toggleUploadFile() {
    this.isCreate = true;
    this.fileErr = null;
    this.resetForms();
    if (this.menuStateNotesUserEdit == 'in') {
      this.menuStateNotesUserEdit = 'out';
    }
    if (this.menuStateOrgEdit == 'in') {
      this.menuStateOrgEdit = 'out';
    }
    if (this.menuStateEmail == 'in') {
      this.menuStateEmail = 'out';
    }
    if (this.menuStateProjectUserEdit == 'in') {
      this.menuStateProjectUserEdit = 'out';
    }
    if (this.menuStateTaskEdit == 'in') {
      this.menuStateTaskEdit = 'out';
    }
    if (this.menuStateEmailEdit == 'in') {
      this.menuStateEmailEdit = 'out';
    }
    this.menuStateUploadFile = this.menuStateUploadFile === 'out' ? 'in' : 'out';
  }


  getFileDetails(e) {
    this.myFiles = [];
    this.filesUploadArr = [];
    for (var i = 0; i < e.target.files.length; i++) {
      this.myFiles.push(e.target.files[i]);
      this.filesUploadArr.push({
        "name": e.target.files[i].name
      })
    }
  }
  postMethod() {
    if (this.clearFile(0)) {
      this.spinner.show();
      const frmData = new FormData();
      for (var i = 0; i < this.myFiles.length; i++) {
        frmData.append("file", this.myFiles[i]);
      }
      frmData.append("description", "");
      this.http.post('/api/upload/deal/' + this.projectId + '/' + this.userid, frmData).subscribe((response) => {
        this.res = response;
        if (this.res.successArr.length > 0) {
          this.filesUploadArr = [];
          this.spinner.hide();
          this.toasterAlert(this.res.successArr[0].message, "success");
          this.tab02 = false
          this.getfiles();
          this.resetForms();
          this.myFiles = [];
        } else if (this.res.failureArr.length > 0) {
          this.filesUploadArr = [];
          this.spinner.hide();
          this.toasterAlert(this.res.failureArr[0].message, "Error");
        }
      }, error => {
        this.errorService.handleError(error);
      });
      this.uploadFilesArr = [];
    }
  }



  downloadFile(fid) {
    this.editSh1 = false;
    const url = '/unsecure/download/' + fid;
    window.open(url, "_self");
  }


  deleteFile(fid) {
    this.confirmService.confirm('Please confirm..', 'Do you want to delete the File ?')
      .then((confirmed) => {
        if (confirmed) {
          this.http.delete('/api/files/' + fid).subscribe((response) => {
            this.res = response;
            if (this.res.success == true) {
              this.toasterAlert(this.res.message, "success");
              this.getfiles();
            } else {
              this.toasterAlert(this.res.message, "Error");
              this.spinner.hide();
            }
          }, error => {
            this.errorService.handleError(error);
          });
          this.uploadFileArr = [];
        }
      })
      .catch(() => {
        return false;
      });
  }
  toggleAddProjectTask(tid) {
    this.http.get('/api/task/' + tid).subscribe((res: Response) => {
      this.result = res;
      this.task = {};
      this.task.name = this.result.name;
      this.task.details = this.result.details;
      var sdate = this.result.startBy;
      if (sdate) {
        sdate = new Date(sdate);
        sdate = moment(sdate).format("YYYY-MM-DD")
      } else {
        sdate = '';
      }
      this.task.startBy = sdate;
      var cdate = this.result.completeBy;
      if (cdate) {
        cdate = new Date(cdate);
        cdate = moment(cdate).format("YYYY-MM-DD")
      } else {
        cdate = '';
      }
      this.task.completeBy = cdate;
    }, error => {
      this.errorService.handleError(error);
    });
    this.menuStateTaskEdit = this.menuStateTaskEdit === 'out' ? 'in' : 'out';

  }
  closeFunction() {
    this.menuStateTaskEdit = this.menuStateTaskEdit === 'out' ? 'in' : 'out';
  }

  toggleonlyEmail(num) {
    if (num == 1) {
      this.emailErr = null;
      this.emailccErr = null;
      this.emailbccErr = null;
      this.filemailErr = null;
      this.opened = null;
      this.isCreate = true;
      this.resetForms();
      this.deleteFilemailarr();
    }
    if (num == 0) {
      this.menuStateEmail = this.menuStateEmail === 'out' ? 'in' : 'out';
    }
  }
  toggleEmail(num) {
    this.emailErr = null;
    this.emailccErr = null;
    this.emailbccErr = null;
    this.filemailErr = null;
    this.opened = null;
    this.isCreate = true;
    this.resetForms();
    this.deleteFilemailarr();
    if (this.menuStateNotesUserEdit == 'in') {
      this.menuStateNotesUserEdit = 'out';
    }
    if (this.menuStateOrgEdit == 'in') {
      this.menuStateOrgEdit = 'out';
    }
    if (this.menuStateProjectUserEdit == 'in') {
      this.menuStateProjectUserEdit = 'out';
    }
    if (this.menuStateUploadFile == 'in') {
      this.menuStateUploadFile = 'out';
    }
    if (this.menuStateTaskEdit == 'in') {
      this.menuStateTaskEdit = 'out';
    }
    if (this.menuStateEmailEdit == 'in') {
      this.menuStateEmailEdit = 'out';
    }
    this.menuStateEmail = this.menuStateEmail === 'out' ? 'in' : 'out';
    if (num == 0) {
      this.getUserDetails();
    }
  }


  getUserDetails() {
    this.http.get('/api/userPreferences/' + this.userid).subscribe((res: Response) => {
      this.result = res;
      if (this.result.length != 0) {
        this.mailSignnature = this.result[0].mailSignature;
        var paragraph = "<p><br></p><p><br></p>";
        var sign = $(this.mailSignnature).text();
        this.emailForm.patchValue({ 'bodyText': this.mailSignnature });
        if (this.result[0].isAccountAdded == "Y") {
          this.syncmail = this.result[0].email
          this.syncheck = true;
        } else {
          this.syncheck = false;
        }
      }

    }, error => {
      this.errorService.handleError(error);
    });
  }


  getEmailSync() {
    this.toggleEmail(1);
    this.confirmService.confirm(' Add Google Account..', 'Email?')
      .then((confirmed) => {
        if (confirmed) {
          const url = '/api/mail/gsync/' + this.userid;
          window.open(url);

        }
      });

  }
  getFromEmail() {
    this.http.get('/api/mail/' + this.userid).subscribe((res: Response) => {
      this.result = res;
      if (this.result.length != 0) {
        this.fromEmailIdArr.push({
          "fromId": this.result[0].email
        })
      }
    }, error => {
      this.errorService.handleError(error);
    });

  }

  submitEmail(id) {
    if (this.emailValidate(0)) {
      if (this.emailValidate(1)) {
        if (this.emailValidate(2)) {
          let data = this.emailForm.value;
          data.projectId = this.projectId;
          if (!data.subject && !data.bodyText) {
            this.toggleonlyEmail(0);
            this.confirmService.confirm('Email Alert', 'Send this message without a subject or text in the body?')
              .then((confirmed) => {
                if (confirmed) {
                  this.spinner.show();
                  this.fd = new FormData();
                  this.fd.append('bcc', data.bcc);
                  this.fd.append('cc', data.cc);
                  this.fd.append('from', id);
                  this.fd.append('projectId', this.projectId);
                  this.fd.append('to', data.to);
                  this.fd.append('subject', data.subject);
                  this.fd.append('bodyText', data.bodyText);
                  this.mailArr.forEach(r => {
                    this.filesatt.push(r.location);
                  });
                  if (this.filesatt) {
                    this.fd.append('file', this.filesatt);
                  }
                  this.http.post('api/mail/send/' + this.userid, this.fd).subscribe((response) => {
                    this.res = response;
                    if (this.res.success == true) {
                      this.toasterAlert(this.res.message, "success");
                      this.toggleonlyEmail(1);
                      if (this.filesatt) {
                        this.deleteFilemailarr();
                      }
                      this.mailContentsArr = [];
                      this.getMailContent();

                      this.spinner.hide();
                    } else {
                      this.toasterAlert(this.res.message, "Error");
                      this.spinner.hide();
                    }
                  }, error => {
                    this.errorService.handleError(error);
                    this.spinner.hide();
                    if (this.filesatt) {
                      this.deleteFilemailarr();
                    }
                  })
                }
              }).catch(() => {
                this.spinner.hide();
                this.toggleonlyEmail(0);
              });
          } else {
            this.spinner.show();
            this.fd = new FormData();
            this.fd.append('bcc', data.bcc);
            this.fd.append('cc', data.cc);
            this.fd.append('from', id);
            this.fd.append('projectId', this.projectId);
            this.fd.append('to', data.to);
            this.fd.append('subject', data.subject);
            this.fd.append('bodyText', data.bodyText);
            this.mailArr.forEach(r => {
              this.filesatt.push(r.location);
            });
            if (this.filesatt) {
              this.fd.append('file', this.filesatt);
            }
            this.http.post('api/mail/send/' + this.userid, this.fd).subscribe((response) => {
              this.res = response;
              if (this.res.success == true) {
                this.toasterAlert(this.res.message, "success");
                this.toggleEmail(1);
                if (this.filesatt) {
                  this.deleteFilemailarr();
                }
                this.mailContentsArr = [];
                this.getMailContent();
                this.tab03 = false;
                this.spinner.hide();
              } else {
                this.toasterAlert(this.res.message, "Error");
                this.spinner.hide();
              }
            }, error => {
              this.errorService.handleError(error);
              this.spinner.hide();
              if (this.filesatt) {
                this.deleteFilemailarr();
              }
            })
          }
        }
      }
    }
  }
  deleteFilemailarr() {
    this.mailArr.forEach(r => {
      this.deleteFilemail(r.id)
    });
    this.filesatt = [];
  }
  emailEditMenu(id) {
    if (this.popoverformmail == true) {
      this.popoverformmail = false;
    } else {
      this.popoverformmail = true;
    }
    this.tab01 = false;
    this.tab02 = false;
    this.tab03 = false;
    this.tab04 = false;
    this.mailDetailsArr = [];
    this.mailAttachmentArr = [];
    this.isCreate = false;
    this.http.get('/api/mail/message/' + id).subscribe((res: Response) => {
      this.result = res;
      var attachment1 = this.result.attachment;
      this.mail = {};
      this.result.recipient.forEach(r => {
        if (r.type == 'From' || r.type == 'To' || r.type == 'Bcc' || r.type == 'Cc') {
          if (this.syncmail == r.email) {
            this.gmailsyn = true;
          }
        }
      });
      attachment1.forEach(r => {
        this.mailAttachmentArr.push({
          "attachment": r.attachment
        })
      })
      var tomail = [];
      var bccmail = [];
      var ccmail = [];
      var frommail = [];
      for (let i = 0; i < this.result.recipient.length; i++) {
        if (this.result.recipient[i].type == 'To') {
          tomail.push(this.result.recipient[i].email)
        }
        if (this.result.recipient[i].type == 'Bcc') {
          bccmail.push(this.result.recipient[i].email)
        }
        if (this.result.recipient[i].type == 'Cc') {
          ccmail.push(this.result.recipient[i].email)
        }
        if (this.result.recipient[i].type == 'From') {
          frommail.push(this.result.recipient[i].email)
        }
      }
      this.mailDetailsArr.push({
        "subject": this.result.content.subject, "body": this.result.content.body, "messageId": this.result.content.messageId, "createdDate": this.result.content.createdDate, "msgRedirectId": this.result.content.msgRedirectId, "recipient": this.result.recipient,
        "attachment": this.result.attachment.attachment, "tomail": tomail, "bccmail": bccmail, "ccmail": ccmail, "frommail": frommail
      })

    }, error => {
      this.errorService.handleError(error);
    });
    this.menuStateEmailEdit = this.menuStateEmailEdit === 'out' ? 'in' : 'out';
  }
  closeEmail() {
    this.popoverformmail = false;
    this.menuStateEmailEdit = this.menuStateEmailEdit === 'out' ? 'in' : 'out';
  }




  async getUser() {
    var result = await this.DetailsService.getUsers();
    this.result1 = result;
    this.result1.forEach(r => {
      this.custArr.push({
        "id": r.details.id, "itemName": r.details.firstName + " " + r.details.lastName
      })
    })
  }

  toggleAddTask() {
    this.popoverformtask = false
    this.isdis = null;
    this.custErr = null;
    this.nameErr = null;
    this.detailsErr = null;
    this.fileTaskErr = null;
    this.taskCommentesAr = [];
    this.taskCommentArr = [];
    this.taskAttArr = [];
    this.dateErr = null;
    this.StartdateErr = null;
    this.isCreate = true;
    this.resetForms();
    this.TaskForm.enable();

    if (this.menuStateNotesUserEdit == 'in') {
      this.menuStateNotesUserEdit = 'out';
    }
    if (this.menuStateOrgEdit == 'in') {
      this.menuStateOrgEdit = 'out';
    }
    if (this.menuStateEmail == 'in') {
      this.menuStateEmail = 'out';
    }
    if (this.menuStateProjectUserEdit == 'in') {
      this.menuStateProjectUserEdit = 'out';
    }
    if (this.menuStateUploadFile == 'in') {
      this.menuStateUploadFile = 'out';
    }
    if (this.menuStateEmailEdit == 'in') {
      this.menuStateEmailEdit = 'out';
    }
    this.menuStateTaskEdit = this.menuStateTaskEdit === 'out' ? 'in' : 'out';
  }

  submitTask() {
    if (this.taskValidate(0)) {
      this.spinner.show();
      let data = this.TaskForm.value;
      if (data.userId.length > 0) {
        data.userId = data.userId[0].id;
      }
      if (data.startBy != null) {
        var sdateval = data.startBy.jsdate;
        data.startBy = moment(sdateval).format("YYYY-MM-DD")
      }
      if (data.completeBy != null) {
        var dateval = data.completeBy.jsdate;
        data.completeBy = moment(dateval).format("YYYY-MM-DD")
      }
      data.createdBy = this.userid;
      data.projectId = this.projectId;
      data.domainId = parseInt(this.loggedInUserDomain);
      data.type = "TASk";
      this.http.post('api/task/', data).subscribe((response) => {
        this.res = response;
        if (this.res.success == true) {
          this.toasterAlert(this.res.message, "success");
          this.custArrselect = [];
          this.tab04 = false;
          this.gettask();
          this.spinner.hide();
        } else {
          this.toasterAlert(this.res.message, "Error");
          this.spinner.hide();
        }
      }, error => {
        this.errorService.handleError(error);
      });
      this.taskArr = [];
    }
  }

  async taskMenu(tid) {
    if (this.popoverformtask == true) {
      this.popoverformtask = false;
    } else {
      this.popoverformtask = true;
    }
    this.custArrselect = [];
    this.custErr = null;
    this.nameErr = null;
    this.detailsErr = null;
    this.isCreate1 = null;
    this.iscurrdis = null;
    this.isCreate = false;
    this.tid = tid;
    this.http.get('/api/task/' + tid).subscribe((res: Response) => {
      this.result = res;
      this.taskcommentId = this.result.id;
      this.taskCommentArr.push({
        "taskName": this.result.name, "taskcommId": this.result.id
      })
      this.getTaskComments(this.taskcommentId);
      if (this.result) {
        this.TaskForm.patchValue({ 'id': this.result.id });
        this.TaskForm.patchValue({ 'name': this.result.name });
        this.TaskForm.patchValue({ 'details': this.result.details });
        this.TaskForm.patchValue({ 'isActive': this.result.isActive });
        this.TaskForm.patchValue({ 'projectId': this.result.projectId });
        this.TaskForm.patchValue({ 'createdAt': this.result.createdAt });
        this.TaskForm.patchValue({ 'updatedAt': this.result.updatedAt });
        this.TaskForm.patchValue({ 'createdBy': this.result.createdBy });
        this.TaskForm.patchValue({ 'updatedBy': this.result.updatedBy });
        this.TaskForm.patchValue({ 'domainId': this.result.domainId });

      }

      if (this.result.userId == this.userid) {
        this.iscurrdis = true;
        if (this.result.isTaken == "Y") {
          this.TaskForm.patchValue({ 'isTaken': true });
          this.istakenHide = true;
          this.iscompleteDis = true;
          this.isdis = true;
          if (this.result.isCompleted == "Y") {
            this.TaskForm.patchValue({ 'isCompleted': true });
            this.resetTask = true;
            this.TaskForm.disable();
            this.taskComment.disable();

          } else {
            this.TaskForm.patchValue({ 'isCompleted': false });
            this.resetTask = false;
            this.TaskForm.enable();
            this.taskComment.enable();

          }
        } else {
          this.TaskForm.patchValue({ 'isTaken': false });
          this.istakenHide = false;
          this.iscompleteDis = false;
          if (this.isDeal == true) {
            this.isdis = false;
          } else {
            this.isdis = true;
          }
        }
      } else {
        if (this.isDeal == true) {
          this.isdis = false;
        } else {
          this.TaskForm.disable();
          this.isdis = true;
        }
      }

      var scdate = this.result.startBy;
      if (scdate) {
        var ydate = moment(scdate).format("YYYY")
        var mdate = moment(scdate).format("M")
        var ddate = moment(scdate).format("D")


        this.TaskForm.patchValue({
          'startBy': {
            date: {
              year: ydate,
              month: mdate,
              day: ddate
            }
          }
        });
      } else {
        this.TaskForm.patchValue({ startBy: null });
      }
      this.onDateChanged(this.result.startBy);

      var cdate = this.result.completeBy;
      if (cdate) {
        var ydate = moment(cdate).format("YYYY")
        var mdate = moment(cdate).format("M")
        var ddate = moment(cdate).format("D")


        this.TaskForm.patchValue({
          'completeBy': {
            date: {
              year: ydate,
              month: mdate,
              day: ddate
            }
          }
        });
      } else {
        this.TaskForm.patchValue({ completeBy: null });
      }
      this.onDateChanged(this.result.completeBy);
      this.http.get('/api/user/' + this.result.userId).subscribe((res1: Response) => {
        this.result = res1;
        this.custArrselect.push({
          "id": this.result.details.id, "itemName": this.result.details.firstName + " " + this.result.details.lastName,
        })
      });
    }, error => {
      this.errorService.handleError(error);
    });
    this.taskCommentArr = [];
    this.taskCommentesAr = [];
    this.taskAttArr = [];
    if (this.menuStateNotesUserEdit == 'in') {
      this.menuStateNotesUserEdit = 'out';
    }
    if (this.menuStateOrgEdit == 'in') {
      this.menuStateOrgEdit = 'out';
    }
    if (this.menuStateEmail == 'in') {
      this.menuStateEmail = 'out';
    }
    if (this.menuStateProjectUserEdit == 'in') {
      this.menuStateProjectUserEdit = 'out';
    }
    if (this.menuStateUploadFile == 'in') {
      this.menuStateUploadFile = 'out';
    }
    if (this.menuStateEmailEdit == 'in') {
      this.menuStateEmailEdit = 'out';
    }
    this.menuStateTaskEdit = this.menuStateTaskEdit === 'out' ? 'in' : 'out';

  }

  async taskEditMenu(tid, num) {

    if (this.popoverformtask == true) {
      this.popoverformtask = false;
    } else {
      this.popoverformtask = true;
    }
    this.tab04 = false
    this.tab03 = false
    this.tab02 = false
    this.tab01 = false
    if (num == 0) {
      this.taskreset();
      this.custArrselect = [];
      this.custErr = null;
      this.nameErr = null;
      this.detailsErr = null;
      this.isCreate1 = null;
      this.iscurrdis = null;
      this.isCreate = false;
      this.tid = tid;
      this.http.get('/api/task/' + tid).subscribe((res: Response) => {
        this.result = res;
        this.taskcommentId = this.result.id;
        this.taskCommentArr.push({
          "taskName": this.result.name, "taskcommId": this.result.id
        })
        this.getTaskComments(this.taskcommentId);
        if (this.result) {
          this.TaskForm.patchValue({ 'id': this.result.id });
          this.TaskForm.patchValue({ 'name': this.result.name });
          this.TaskForm.patchValue({ 'details': this.result.details });
          this.TaskForm.patchValue({ 'isActive': this.result.isActive });
          this.TaskForm.patchValue({ 'projectId': this.result.projectId });
          this.TaskForm.patchValue({ 'createdAt': this.result.createdAt });
          this.TaskForm.patchValue({ 'updatedAt': this.result.updatedAt });
          this.TaskForm.patchValue({ 'createdBy': this.result.createdBy });
          this.TaskForm.patchValue({ 'updatedBy': this.result.updatedBy });
          this.TaskForm.patchValue({ 'domainId': this.result.domainId });
        }


        if (this.result.userId == this.userid) {
          this.iscurrdis = true;
          if (this.result.isTaken == "Y") {
            this.TaskForm.patchValue({ 'isTaken': true });
            this.istakenHide = true;
            this.iscompleteDis = true;
            this.isdis = true;
            if (this.result.isCompleted == "Y") {
              this.TaskForm.patchValue({ 'isCompleted': true });
              this.resetTask = true;
              this.TaskForm.disable();
              this.taskComment.disable();
            } else {
              this.TaskForm.patchValue({ 'isCompleted': false });
              this.resetTask = false;
              this.TaskForm.enable();
              this.taskComment.enable();
            }
          } else {
            this.TaskForm.patchValue({ 'isTaken': false });
            this.iscompleteDis = false;
            this.istakenHide = false;
            if (this.isDeal == true) {
              this.isdis = false;
            } else {
              this.isdis = true;
            }

          }
        } else {
          if (this.isDeal == true) {
            this.isdis = false;
          } else {
            this.TaskForm.disable();
            this.isdis = true;
          }
        }


        var scdate = this.result.startBy;
        if (scdate) {
          var ydate = moment(scdate).format("YYYY")
          var mdate = moment(scdate).format("M")
          var ddate = moment(scdate).format("D")


          this.TaskForm.patchValue({
            'startBy': {
              date: {
                year: ydate,
                month: mdate,
                day: ddate
              }
            }
          });
        } else {
          this.TaskForm.patchValue({ startBy: null });
        }

        var cdate = this.result.completeBy;
        if (cdate) {
          var ydate = moment(cdate).format("YYYY")
          var mdate = moment(cdate).format("M")
          var ddate = moment(cdate).format("D")


          this.TaskForm.patchValue({
            'completeBy': {
              date: {
                year: ydate,
                month: mdate,
                day: ddate
              }
            }
          });
        } else {
          this.TaskForm.patchValue({ completeBy: null });
        }
        this.http.get('/api/user/' + this.result.userId).subscribe((res1: Response) => {
          this.result = res1;
          this.custArrselect.push({
            "id": this.result.details.id, "itemName": this.result.details.firstName + " " + this.result.details.lastName,
          })
        });
      }, error => {
        this.errorService.handleError(error);
      });
    }
    this.taskCommentArr = [];
    this.taskCommentesAr = [];
    this.taskAttArr = [];
    this.menuStateTaskEdit = this.menuStateTaskEdit === 'out' ? 'in' : 'out';

  }


  Enable() {
    this.isdis = false;
    this.TaskForm.enable();
    this.taskComment.enable();
  }
  updateTask() {
    if (this.taskValidate(2)) {
      this.spinner.show();
      this.body = {};
      this.body["id"] = this.tid;
      this.body["name"] = this.TaskForm.value["name"];
      this.body["details"] = this.TaskForm.value["details"];
      this.body["userId"] = this.TaskForm.value["userId"][0].id;
      this.body["projectId"] = this.TaskForm.value["projectId"];
      this.body["createdAt"] = this.TaskForm.value["createdAt"];
      this.body["updatedAt"] = new Date();
      this.body["updatedBy"] = parseInt(this.userid);
      this.body["createdBy"] = this.TaskForm.value["createdBy"];
      this.body["domainId"] = this.TaskForm.value["domainId"];
      this.body["type"] = "TASk";
      var date1 = ("0" + (this.TaskForm.value["startBy"].date.day)).slice(-2);
      var month1 = ("0" + (this.TaskForm.value["startBy"].date.month)).slice(-2);
      var date2 = ("0" + (this.TaskForm.value["completeBy"].date.day)).slice(-2);
      var month2 = ("0" + (this.TaskForm.value["completeBy"].date.month)).slice(-2);
      var startBy = this.TaskForm.value["startBy"].date.year + "-" + month1 + "-" + date1
      this.body["startBy"] = startBy;
      var completeBy = this.TaskForm.value["completeBy"].date.year + "-" + month2 + "-" + date2
      this.body["completeBy"] = completeBy;
      this.body["isActive"] = "Y";
      if (this.TaskForm.value["isCompleted"] == true) {
        this.body["isCompleted"] = "Y";
      } else {
        this.body["isCompleted"] = "N";
      }
      if (this.TaskForm.value["isTaken"] == true) {
        this.body["isTaken"] = "Y";
      } else {
        this.body["isTaken"] = "N";
      }

      this.http.put('/api/task', this.body).subscribe((response) => {
        this.spinner.show();
        this.resetForms();
        this.res = response;
        if (this.res.success == true) {
          this.toasterAlert(this.res.message, "success");
          this.spinner.hide();
          this.toggleAddTask();
          this.gettask();
        } else {
          this.toasterAlert(this.res.message, "Error");
          this.spinner.hide();
        }
      }, error => {
        this.errorService.handleError(error);
      });
      this.taskArr = [];
      this.taskCommentesAr = [];
    }
  }
  submitTaskComment(tid) {
    if (this.taskComment.value.comment) {
      let data = this.taskComment.value;
      data.taskId = tid;
      data.commentBy = this.userid;
      data.createdBy = this.userid;
      this.http.post('api/taskLogs/', data).subscribe((response) => {
        this.res = response;
        if (this.res.success == true) {
          this.taskreset();
          this.taskArr = [];
          this.gettask();
          this.getTaskComments(this.taskcommentId);
        } else {
          this.toasterAlert(this.res.message, "Error");
          this.spinner.hide();
        }
      }, error => {
        this.errorService.handleError(error);
      });
      this.taskCommentesAr = [];
      this.taskAttArr = [];
    } else {
      return false;
    }
  }
  getTaskComments(id) {
    var taskCid = id;
    this.http.get('/api/taskComments/' + taskCid).subscribe((res1: Response) => {
      this.result = res1;
      this.result.forEach(r => {
        this.taskCommentesAr.push({
          "id": r.id, "comment": r.comment, "commentBy": r.commentBy, "createdAt": r.createdAt, "commentType": r.commentType
        })
        if (r.commentType == "file") {
          this.taskAttArr.push({
            "id": r.id, "comment": r.comment, "commentBy": r.commentBy, "createdAt": r.createdAt, "attId": r.attachmentId
          })
        }
      })
    }, error => {
      this.errorService.handleError(error);
    });
  }

  getTaskFile(e) {
    this.myFilesTask = [];
    this.myFilesTaskArr = [];
    for (var i = 0; i < e.target.files.length; i++) {
      this.myFilesTask.push(e.target.files[i]);
      this.myFilesTaskArr.push({
        "name": e.target.files[i].name
      })
    }
  }

  taskFilePost(tid) {
    if (this.clearTaskFile()) {
      this.spinner.show();
      let data = this.taskComment.value;
      const id = +this.route.snapshot.paramMap.get('id');
      this.projectId = id;
      const frmData = new FormData();
      for (var i = 0; i < this.myFilesTask.length; i++) {
        frmData.append("file", this.myFilesTask[i]);
      }
      frmData.append("description", "");
      this.http.post('/api/upload/approval/' + this.projectId + '/' + this.userid, frmData).subscribe((response) => {
        this.res = response;
        if (this.res.successArr.length > 0) {
          // this.myFilesTaskArr = [];
          var lastarr = this.res.successArr.length - 1;
          data.taskId = tid;
          data.commentBy = this.userid;
          data.createdBy = this.userid;
          for (var i = 0; i < this.res.successArr.length; i++) {
            data.attachmentId = this.res.successArr[i].response.id;
            data.comment = this.res.successArr[i].response.uploadName;
            this.http.post('/api/taskLogs/attachment', data).subscribe((response) => {
              this.res1 = response;
              if (this.res1.success == true) {
                this.taskreset();
                this.toasterAlert(this.res.message, "success");
              } else {
                this.toasterAlert(this.res.message, "Error");
                this.spinner.hide();
              }
              this.spinner.hide();
            }, error => {
              this.errorService.handleError(error);
            });
            if (lastarr == i) {
              setTimeout(() => {
                this.taskAttArr = [];
                this.taskCommentesAr = [];
                this.myFilesTaskArr = [];
                this.getTaskComments(tid);
              }, 2000);
            }
          }
        }
      }, error => {
        this.errorService.handleError(error);
      });
      return false;
    }
  }

  downloadTaskFile(fid) {
    const url = '/api/download/' + fid;
    window.open(url);
  }

  emailRefresh() {
    this.spinner.show();
    this.http.get('/api/mail/read/' + this.userid).subscribe((res: Response) => {
      var result = res;
      this.spinner.hide();
      this.toasterAlert('Mail Sync', "success");
      this.getMailContent();
    }, error => {
      this.errorService.handleError(error);
    });
    this.mailContentsArr = [];

  }



  btnClick = function () {
    this.router.navigateByUrl('/profile/mailsync');
  };


  downloadFiles(fid) {
    const url = '/unsecure/download/' + fid;
    window.open(url, "_self");
  }

  EnableGmail(link) {
    var url = "https://mail.google.com/mail/#search/rfc822msgid%3A" + encodeURIComponent(link);
    window.open(url);
  }
  getEmailDetails(e) {
    this.myFilesEmail = [];
    for (var i = 0; i < e.target.files.length; i++) {
      this.myFilesEmail.push(e.target.files[i]);
      if (this.myFilesEmail.length == e.target.files.length) {
        this.postMethodmail();
      }
    }

  }
  postMethodmail() {
    if (this.clearFile(1)) {
      this.spinner.show();
      const frmData = new FormData();
      if (this.myFilesEmail.length != 0) {
        for (var i = 0; i < this.myFilesEmail.length; i++) {
          frmData.append("file", this.myFilesEmail[i]);
        }
        frmData.append("description", "");
        this.http.post('/api/upload/mail/' + this.projectId + '/' + this.userid, frmData).subscribe((response) => {
          this.res = response;
          if (this.res.successArr.length > 0) {
            this.toasterAlert(this.res.successArr[0].message, "success");
            this.opened = false;
            this.getmailfiles();
            this.spinner.hide();
          } else if (this.res.failureArr.length > 0) {
            this.toasterAlert(this.res.failureArr[0].message, "Error");
            this.spinner.hide();
          }
        }, error => {
          this.errorService.handleError(error);
        });
        this.fileToEmail = [];
        this.emailAttachReset();
      }
    }

  }
  getmailfiles() {
    this.mailArr = [];
    this.http.get('/api/files/mail/' + this.projectId).subscribe((res1: Response) => {
      this.result = res1;
      for (let i = 0; i < this.result.length; i++) {
        this.mailArr.push({
          "id": this.result[i].id, "itemName": this.result[i].uploadName, "location": this.result[i].location
        })
      }

    }, error => {
      this.errorService.handleError(error);
    });
  }

  deleteFilemail(id) {
    if (id) {
      this.http.delete('/api/files/permanent/' + id).subscribe((response) => {
        this.res = response;
        if (this.res.success == true) {
          // this.toasterAlert(this.res.message, "success");
          this.getmailfiles();
        }
      }, error => {
        this.errorService.handleError(error);
      });
      this.mailArr = [];
    } else {
      return false;
    }
  }



  uploadadd() {
    this.containers.push(this.containers.length);
  }

  tabs1() {
    this.noteCreatedAnddeleteUSer = false;
    this.eventListSelected = [];
    this.noteHeadingErr = null;
    this.noteDescErr = null;
    this.nameErr = null;
    this.filesUploadArr = [];
    if (this.tab01 == true) {
      if (this.isCreate == true) {
        this.tab01 = false;
      } else {
        this.resetForms();
        this.isCreate = true;
      }

    } else {
      this.resetForms();
      this.isCreate = true;
      this.tab01 = true;
    }
    this.tab02 = false
    this.tab03 = false
    this.tab04 = false
    this.tab05 = false;
  }

  tabs2() {
    this.filesUploadArr = [];
    this.fileErr = null;
    if (this.tab02 == true) {
      if (this.isCreate == true) {
        this.tab02 = false;
      } else {
        this.resetForms();
        this.isCreate = true;
      }

    } else {
      this.tab02 = true;
    }
    // this.tab02=true
    this.tab01 = false
    this.tab03 = false
    this.tab04 = false
    this.tab05 = false;
  }

  tabs3() {
    this.filesUploadArr = [];
    if (this.tab03 == true) {
      if (this.isCreate == true) {
        this.tab03 = false;
      } else {
        this.resetForms();
        this.isCreate = true;
      }
    } else {
      this.tab03 = true;
      this.getUserDetails();
      this.deleteFilemailarr();
      this.customerGetCall();
    }
    this.tab01 = false
    this.tab02 = false
    this.tab04 = false
    this.tab05 = false;
    this.emailErr = null;
    this.emailccErr = null;
    this.emailbccErr = null;
  }

  tabs4() {
    this.nameErr = null;
    this.custErr = null;
    this.StartdateErr = null;
    this.dateErr = null;
    this.detailsErr = null;
    this.filesUploadArr = [];
    if (this.tab04 == true) {
      if (this.isCreate == true) {
        this.tab04 = false;
      } else {
        this.resetForms();
        this.isCreate = true;
      }

    } else {
      this.resetForms();
      this.isCreate = true;
      this.tab04 = true;
    }
    // this.tab04=true
    this.tab01 = false
    this.tab02 = false
    this.tab03 = false
    this.tab05 = false;
  }
  tabs5() {
    if (this.tab05 == false) {
      this.resetForms();
      this.isCreate = true
      this.multiCustomerDetails()
      this.deleteFilemailarr();
      // this.getFromEmail();
      this.getUserDetails();
      this.tab05 = true;
    } else {
      this.tab05 = false;
    }
    this.tab03 = false
    this.tab01 = false
    this.tab02 = false
    this.tab04 = false
    this.editSh1 = false;
    this.emailErr = null;
    this.emailccErr = null;
    this.emailbccErr = null;
  }

  onSwitch(check) {

    switch (check) {
      case 1:
        return 'tab1';
      case 2:
        return 'tab2';
      case 3:
        return 'tab3';
      case 4:
        return 'tab4';
    }
  }
  customerGetCall() {
    this.multiCustomerDetails();
    this.customerDetial();
  }
  customerDetial() {
    this.customersArr = [];
    this.http.get('/api/customer').subscribe((res1: Response) => {
      this.result1 = res1;
      this.custArrDe = this.result1;
      for (let i = 0; i < this.result1.length; i++) {
        this.customersArr.push(this.result1[i].details.id)
      }
    })
  }
  multiCustomerDetails() {
    this.customerAllDetails = [];
    this.customerAllDetailsArr = [];
    this.customermail = [];
    const id = +this.route.snapshot.paramMap.get('id');
    this.projectId = id;
    this.http.get('/api/projectCustomer/project/' + this.projectId).subscribe((res1: Response) => {
      this.customerAllDetails = res1;
      this.custLength = this.customerAllDetails.length;
      for (let i = 0; i < this.custLength; i++) {
        this.customerDetials(this.customerAllDetails[i].cust.id);
        this.customerAllDetailsArr.push(this.customerAllDetails[i].cust.id)
      }

    })
  }
  toggleAddCustomer() {
    this.allCustomer = [];
    this.customerAllArrselect = [];
    this.customerErr = null;
    var result2 = this.arr_diff_cust(this.customersArr, this.customerAllDetailsArr);
    this.resetForms();
    if (this.popoverform2 == true) {
      this.popoverform2 = false;
    } else {
      this.popoverform2 = true;
    }
    this.custArrDe.forEach(r => {
      for (let i = 0; i < result2.length; i++) {
        if (result2[i] == r.details.id) {
          this.allCustomer.push({
            "id": r.details.id, "itemName": r.details.firstName + " " + r.details.lastName
          })
        }
      }
    });
    if (this.menuStateOrgEdit == 'in') {
      this.menuStateOrgEdit = 'out';
    }
    if (this.menuStateProjectUserEdit == 'in') {
      this.menuStateProjectUserEdit = 'out';
    }
    this.menuStateCustomerEdit = this.menuStateCustomerEdit === 'out' ? 'in' : 'out';
  }
  toggleCloseCustomer() {
    this.customerAllArrselect = [];
    this.menuStateCustomerEdit = this.menuStateCustomerEdit === 'out' ? 'in' : 'out';
    this.popoverform2 = false;

  }
  submitCustomer() {
    var post = {};
    if (this.custValidate()) {
      this.spinner.show();
      var data = this.custForm.value;
      data.customerId = data.customerId[0].id;
      data.projectId = this.projectId;
      this.http.post('/api/projectCustomer', data).subscribe((response) => {
        this.res = response;
        if (this.res.success == true) {
          this.toasterAlert(this.res.message, "success");
          this.toggleCloseCustomer();
          this.customerGetCall();
          this.spinner.hide();
        } else {
          this.toasterAlert(this.res.message, "Error");
          this.spinner.hide();
        }
      }, error => {
        this.errorService.handleError(error);
      });
    }

  }
  removeFromCustomer(id) {
    this.confirmService.confirm('Please confirm..', 'Do you want to remove the customer ?')
      .then((confirmed) => {
        if (confirmed) {
          this.spinner.show();
          this.http.delete('/api/projectCustomer/' + id).subscribe((response) => {
            this.res = response;
            if (this.res.success == true) {
              this.toasterAlert(this.res.message, "success");
              this.customerGetCall();
              this.spinner.hide();
            } else {
              this.toasterAlert(this.res.message, "Error");
              this.spinner.hide();
            }
          }, error => {
            this.errorService.handleError(error);
          });
        }
      })
      .catch(() => {
        return false;
      });
  }

  async getEstimationVersionCall(num) {
    if (num == 0) {
      this.versionNameArr = [];
      this.result = await this.DetailsService.getEstimationVersion(this.projectId);
      var result = this.result;
      for (let i = 0; i < result.length; i++) {
        this.versionNameArr.push({
          "id": result[i].id,
          "itemName": result[i].versionName,
          "createdDate": result[i].createdDate,
          "basicPackageId": result[i].basicPackageId,
          "isLocked": result[i].isLocked,
          "lastVersionDocId": result[i].lastVersionDocId

        });
      }
      if (result.length > 0) {
        this.currentVersionId = this.versionNameArr[0].id;
        this.currentbasePackageId = this.versionNameArr[0].basicPackageId;
        this.currentbaseVersionName = this.versionNameArr[0].itemName;
        this.isLockedCheck = this.versionNameArr[0].isLocked;
        this.listClick(this.versionNameArr[0]);
        this.getEstimation();
        this.noDataEstimation = false;
      }
      if (result.length == 0) {
        this.noDataEstimation = true;
      }
    } else if (num == 1) {
      this.versionNameArr = [];
      this.result1 = await this.DetailsService.getEstimationVersion(this.projectId);
      var result = this.result1;
      for (let i = 0; i < result.length; i++) {
        this.versionNameArr.push({
          "id": result[i].id,
          "itemName": result[i].versionName,
          "createdDate": result[i].createdDate,
          "basicPackageId": result[i].basicPackageId,
          "isLocked": result[i].isLocked,
          "lastVersionDocId": result[i].lastVersionDocId
        });
      }
      if (this.estimationSelectActive == false) {
        this.listClick(this.versionNameArr[0]);
      }
      if (result.length == 0) {
        this.noDataEstimation = true;
      } else {
        // this.listClick(this.versionNameArr[0]);
        this.noDataEstimation = false;
      }
    }
  }
  updateBedRequirement(requirment, value) {
    if (value == "up") {
      if (this.bed_value > 0) {
        this.bed_value++;
      } else {
        this.bed_value = 1;
      }
    } else if (value == "down") {
      if (this.bed_value > 0) {
        this.bed_value--;
      } else {
        this.bed_value = 1;
      }
    }
    if (this.bed_value == 0 || this.bed_value > 4) {
      this.bed_value = 1;
    }
  }
  updateBathRequirement(requirment, value) {
    if (value == "up") {
      if (this.bath_value > 0) {
        this.bath_value++;
      } else {
        this.bath_value = 1;
      }
    } else if (value == "down") {
      if (this.bath_value > 0) {
        this.bath_value--;
      } else {
        this.bath_value = 1;
      }
    }
    if (this.bath_value == 0 || this.bath_value > 4) {
      this.bath_value = 1;
    }
  }
  updateGarageRequirement(requirment, value) {
    if (value == "up") {
      if (this.garage_value > 0) {
        this.garage_value++;
      } else {
        this.garage_value = 1;
      }
    } else if (value == "down") {
      if (this.garage_value > 0) {
        this.garage_value--;
      } else {
        this.garage_value = 1;
      }
    }
    if (this.garage_value == 0 || this.garage_value > 4) {
      this.garage_value = 1;
    }
  }
  onClick(check) {
    if (check == 1) {
      this.tab = 'singleStorey';
    } else if (check == 2) {
      this.tab = 'doubleStorey';
    } else if (check == 3) {
      this.tab = 'splitLevel';
    } else if (check == 4) {
      this.tab = 'duplex';
    }

  }
  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }
  copyText(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = " <" + val + ">";
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toasterAlert("Copied Successfully", "success");
  }

  async getEstimation() {
    const id = +this.route.snapshot.paramMap.get('id');
    this.projectId = id;
    this.spinner.show();
    this.getBasicHome();
    this.getDesignGroup();
    this.getFinalEstimationPreview();
    this.spinner.hide();

  }

  // Get all Basic Home Package
  async getBasicHome() {
    this.result1 = await this.DetailsService.getBasicHomePack();
    this.basicHomeArr = [];
    this.basicHomeArr = this.result1;
    for (let i = 0; i < this.basicHomeArr.length; i++) {
      if (this.currentbasePackageId == this.basicHomeArr[i].id) {
        this.defaultHomeId = this.basicHomeArr[i].id;
        this.defaultHomeName = this.basicHomeArr[i].name;
        this.defaultHomePrice = this.basicHomeArr[i].price;
        this.defaultHomeImg = this.basicHomeArr[i].thumbnailUrl;
        this.defaultHomeURL = this.basicHomeArr[i].referenceLink;
      }
    }
    this.getEstimationTotal(this.currentVersionId);
  }

  //Get total Estimation

  async getEstimationTotal(estimateId) {
    this.basicHomeTotal = 0;
    var totalpriceCount = 0;
    var designGrId = 0;
    this.result = await this.DetailsService.getTotalEstimationAmount(estimateId);
    this.basicHomeTotal = this.result.totalValue;
    if (this.estimationProperty.length > 0) {
      for (let i = 0; i < this.estimationProperty.length; i++) {
        if (this.estimationProperty[i].estimationProperty.totalPrice > 0 && this.estimationProperty[i].estimationProperty.isPropertyIncluded == 'Y') {
          totalpriceCount = totalpriceCount + 1;
        }
        designGrId = this.estimationProperty[i].estimationProperty.designGroupId;
      }
    }
    if (this.desginGrData.length > 0) {
      for (let j = 0; j < this.desginGrData.length; j++) {
        if (designGrId == this.desginGrData[j].id) {
          this.desginGrData[j].valueAddedPropertiesCount = totalpriceCount;
        }
      }
    }
  }

  //Get all Design Group Property
  async getDesignGroup() {
    this.desginGrData = [];
    this.res = await this.DetailsService.getDesignGroup(this.currentVersionId);
    this.desginGrData = this.res;
  }

  //basic Home package details display based on selection
  async basicHomeSelection(event: any, basicHome) {
    this.defaultHomeId = basicHome.id;
    this.defaultHomeName = basicHome.name;
    this.defaultHomePrice = basicHome.price;
    this.defaultHomeImg = basicHome.thumbnailUrl;
    this.defaultHomeURL = basicHome.referenceLink;
    var data = {
      "versionId": this.currentVersionId,
      "basicPackageId": basicHome.id,
      "updatedById": parseInt(this.userid)
    }
    var result = await this.DetailsService.setSelectedHomePackVersion(data);
    this.getEstimationTotal(this.currentVersionId);
    this.getEstimationVersionCall(1);

  }

  //Active Storey Highlight
  storySelect(id) {
    if (this.storeyId != id) {
      this.storeyId = id;
    } else {
      this.storeyId = null;
    }

  }

  //List display Home package based on Filter Selection
  async filterByHome() {
    var widthValue = this.widthRange;
    var bedRoomValue = this.bed_value;
    var bathRoomValue = this.bath_value;
    var garageValue = this.garage_value;
    var storeyValue = this.storeyId;
    var filterValue = {
      "width": null,
      "bedRoom": bedRoomValue,
      "bathRoom": bathRoomValue,
      "garage": garageValue,
      "storeyType": storeyValue
    };
    this.result = await this.DetailsService.getFilterHomePack(filterValue);
    if (this.result.length > 0) {
      this.basicHomeArr = [];
      this.basicHomeArr = this.result;

      for (let i = 0; i < this.basicHomeArr.length; i++) {
        if (this.currentbasePackageId == this.basicHomeArr[i].id) {
          this.defaultHomeId = this.basicHomeArr[i].id;
          this.defaultHomeName = this.basicHomeArr[i].name;
          this.defaultHomePrice = this.basicHomeArr[i].price;
          this.defaultHomeImg = this.basicHomeArr[i].thumbnailUrl;
          this.defaultHomeURL = this.basicHomeArr[i].referenceLink;
        }
      }
      this.getEstimationTotal(this.currentVersionId);

    } else {
      this.toasterAlert("No Package Found for this filter selection", "Error");

    }
  }

  //Reset Home package Filter
  async filterByHomeReset() {
    this.basicHomeArr = [];
    this.bed_value = 1;
    this.bath_value = 1;
    this.garage_value = 1;
    this.defaultHomeId = '';
    this.widthRange = 5;
    this.storeyId = null;
    this.getBasicHome();
  }

  //activate partTwo
  estimationPartTwo(activeId, designId, designName) {
    this.designVersionId = designId;
    this.designActive = activeId;
    if (activeId == 0 && designId == 0) {
      this.partTwoActive = 0;
      this.estimationNext = true;
      this.estimationPropTitle = 'Basic Home Package';
    } else {
      this.partTwoActive = 1;
      var designGrDataLength = this.desginGrData.length;
      this.estimationNext = true;
      this.getEstimationProperty(designId, this.currentVersionId);
      this.estimationPropTitle = designName;
    }
  }

  //Get relevant design estimation property
  async getEstimationProperty(designId, estimationId) {
    this.estimationProperty = [];
    this.propSaveChild = [];
    this.result = await this.DetailsService.getEstimationPropCheck(designId, estimationId);
    this.estimationProperty = this.result;
    this.defaultEstimationPropId = this.estimationProperty[0].estimationProperty.id;
    this.estimationPropSelection(this.estimationProperty[0]);
  }

  //Get relavant estimation property details for third column
  estimationPropSelection(selectProp) {
    this.propSaveChild = [];
    selectProp.isLocked = this.isLockedCheck;
    this.propSaveChild.push(selectProp);
    this.estimationOptDisplay = "";
    var isInclude = selectProp.estimationProperty.isInclude;
    var isOption = selectProp.estimationProperty.isOption;
    var isPriced = selectProp.estimationProperty.isPriced;
    var isPricedOption = selectProp.isPricedOption;
    var isPropInc = selectProp.estimationProperty.isPropertyIncluded;
    this.defaultEstimationPropId = selectProp.estimationProperty.id;

    if (isInclude == 'N' && isOption == 'N' && isPriced == 'N') {
      this.estimationOptDisplay = "<div class='propDiv'><h3 class='propTitle'>" + selectProp.estimationProperty.propertyName + "</h3></div>";
      this.estimationOptDisplay += selectProp.estimationProperty.propertyDescription;
    }
  }

  //update Include with no option
  include_with_no_option(event: any, propChange, updateProp) {
    if (propChange == "N") {
      updateProp.estimationProperty.isPropertyIncluded = 'N';
    } else if (propChange == "Y") {
      updateProp.estimationProperty.isPropertyIncluded = 'Y';
    }
    this.estimationPropSelection(updateProp);
  }

  //next estimation button onClick
  nextEstimationClick() {
    var designGrDataLength = this.desginGrData.length;
    var designNext;
    var propNextId;
    var propNextName;
    if (this.designActive <= designGrDataLength - 1) {
      designNext = this.designActive;
      propNextId = this.desginGrData[designNext].id;
      propNextName = this.desginGrData[designNext].name;
      this.estimationPartTwo(designNext, propNextId, propNextName);
      this.estimationNext = true;
    }
    if (this.designActive == designGrDataLength) {
      this.estimationNext = false;
      this.partTwoActive = 2;
      this.getFinalEstimationPreview();
    }
    this.designActive = this.designActive + 1;
  }

  //back estimation button onClick
  backEstimationClick() {
    var propBackId;
    var propBackName;

    if (this.designActive > 1) {
      var designGrDataLength = this.desginGrData.length;
      this.designActive = this.designActive - 1;
      var designBack = this.designActive;
      propBackId = this.desginGrData[designBack - 1].id;
      propBackName = this.desginGrData[designBack - 1].name;
      this.estimationNext = true;
      this.estimationPartTwo(designBack, propBackId, propBackName);
    } else {
      this.designActive = 0;
      this.partTwoActive = 0;
      this.estimationPropTitle = "Basic Home Package";
    }
    this.estimationNext = true;
  }

  //Get Final Estimation Amount estimationPreview

  async getFinalEstimationPreview() {
    this.finalEstHomePreArr = [];
    this.finalEstGroupPreArr = [];
    this.result = await this.DetailsService.getEstimationFinalCheck(this.currentVersionId);
    this.finalEstHomePreArr = this.result.basicPackage;
    this.finalEstGroupPreArr = this.result.groupProperties;
  }

  //generate  Estimation Document

  async generateEstmationDocument(check) {
    if (check == true) {
      this.confirmService.confirm('Please confirm..', 'Do you want to download the estimation preview?')
        .then((confirmed) => {
          if (confirmed) {
            this.dataSavePreview = "Estimation Processing...";
            this.body = {};
            this.body["versionId"] = this.currentVersionId;
            this.body["userId"] = parseInt(this.userid);
            this.body["isPreview"] = check;
            this.http.post('/api/generateEstimation', this.body).subscribe((response) => {
              this.res = response;
              if (this.res.success == true) {
                this.generatefileName = this.res.fileName;
                this.downloadEstimationFile(this.res.fileName);
                this.getEstimationVersionCall(1);
                this.dataSavePreview = "Preview Estimation";
                this.toasterAlert('Preview Estimation Successfully..!', "success");
              } else {
                this.toasterAlert(this.res.message, "Error");
              }
            });
          }
        })
        .catch(() => {
          return false;
        });
    } else {
      this.confirmService.confirm('Please confirm..', 'Do you want to generate estimation? Note: Once generated you cannot make any changes in this version')
        .then((confirmed) => {
          if (confirmed) {
            this.dataSave = "Estimation Processing...";
            this.body = {};
            this.body["versionId"] = this.currentVersionId;
            this.body["userId"] = parseInt(this.userid);
            this.body["isPreview"] = check;
            this.http.post('/api/generateEstimation', this.body).subscribe((response) => {
              this.res = response;
              if (this.res.success == true) {
                this.generatefileName = this.res.fileName;
                this.downloadEstimationFile(this.res.fileName);
                this.getEstimationVersionCall(1);
                this.dataSave = "Generate Estimation";
                this.isLockedCheck = "Y";
                this.toasterAlert('Generate Estimation Successfully..!', "success");
              } else {
                this.toasterAlert(this.res.message, "Error");
              }
            });
          }
        })
        .catch(() => {
          return false;
        });
    }


  }

  //download  Estimation Document

  downloadEstimationFile(fname) {
    const url = '/unsecure/estimation/download/' + fname;
    window.open(url, "_self");
  }



  toggleAccordian(ngPanel) {
    switch (ngPanel.panelId) {
      case 'static-2':
        if (ngPanel.nextState == true) {
          this.orgsDetails(this.projectId);
          break;
        }
      case 'static-3':
        if (ngPanel.nextState == true) {
          this.customerGetCall();
          break;
        }
      case 'static-5':
        if (ngPanel.nextState == true) {
          this.getEstimationVersionCall(1);
          break;
        }
        case 'static-keypeople':
          if (ngPanel.nextState == true) {
            this.getprojectinfo();
            break;
          }
    }
  }
  getEstimationfile(id) {
    this.http.get('/api/file/' + id).subscribe((res: Response) => {
      this.estimationFileDetails = res;

    });
  }
  estimationPopuOpen(num) {
    this.versionNameArrSelect = [];
    this.estimatenameErr = null;
    this.esitimationResetForm();
    this.editVersioncheck = false;
    this.editSh1 = false;
    this.btnName = "Save";
    if (this.editSh == true) {
      this.editSh = false;
    } else {
      this.editSh = true;
    }
  }
  downloadOpen(data) {
    this.estimatenameErr = null;
    this.editSh = false;
    this.versionDataDetails = data;
    if (this.editSh1 == true) {
      this.editSh1 = false;
    } else {
      this.editSh1 = true;
    }
  }
  async estimationVersionSave() {
    if (this.estimatevalidate()) {
      this.spinner.show();
      this.btnName = "Wait! Processing...";
      var data = this.estimationNameForm.value;
      if (data.versionId) {
        if (data.versionId.length > 0) {
          data.versionId = data.versionId[0].id;
        } else if (data.versionId.length != 0 && data.versionId) {

        } else {
          data.versionId = null;
        }
      }
      data.userId = parseInt(this.userid);
      data.projectId = parseInt(this.projectId);
      this.estimationID = await this.DetailsService.createEstimationId(data);
      if (this.estimationID.success) {
        this.editSh = false;
        this.btnName = "Save";
        this.toastr.success(this.estimationID.message, 'success', {
          timeOut: 2000,
          closeButton: true
        });
        this.getEstimationVersionCall(0);
        this.spinner.hide();
      } else {
        this.btnName = "Save";
        this.spinner.hide();
        this.toasterAlert(this.estimationID.message, "warning");
      }

    }
  }
  getEstimationDetails(version) {
    this.currentVersionId = version.id;
    this.currentbasePackageId = version.basicPackageId;
    this.currentbaseVersionName = version.itemName;
    this.isLockedCheck = version.isLocked;
    this.estimationTabSelect = true;
    this.tab05 = false;
    this.tab03 = false
    this.tab01 = false
    this.tab02 = false
    this.tab04 = false
    this.editSh1 = false;
    this.tabRef.select('Estimation');
    if (this.designVersionId != null) {
      this.getEstimationProperty(this.designVersionId, this.currentVersionId);
    }
    this.getEstimation();
  }
  editVersionName(data) {
    this.estimatenameErr = null;
    this.estimationNameForm.patchValue({ "versionName": data.itemName });
    this.estimationNameForm.patchValue({ "id": data.id });
    this.editSh1 = false;
    this.editVersioncheck = true;
    if (this.editSh == true) {
      this.editSh = false;
    } else {
      this.editSh = true;
    }
  }
  estimationVersionNameSave() {
    if (this.estimatevalidate()) {
      this.spinner.show();
      var data = this.estimationNameForm.value
      this.btnName = "Wait! Processing...";
      data.projectId = parseInt(this.projectId);
      data.updatedBy = parseInt(this.userid);
      this.http.put('/api/estimationVersion/name', data).subscribe((response) => {
        this.res = response;
        if (this.res.success == true) {
          this.toasterAlert(this.res.message, "success");
          this.editSh = false;
          this.esitimationResetForm();
          this.getEstimationVersionCall(0);
          this.spinner.hide();
          this.btnName = "Save";
        } else {
          this.btnName = "Save";
          this.toasterAlert(this.res.message, "Error");
          this.spinner.hide();
        }
      }, error => {
        this.errorService.handleError(error);
      });
    }
  }

  listClick(newValue) {
    if (newValue.length > 0) {
      this.selectedItem = newValue[0];
    } else {
      this.selectedItem = newValue;
    }
  }



  toasterAlert(msg, value) {
    this.spinner.hide();
    this.toastr.clear();
    if (value == "success") {
      this.toastr.success(msg, 'Report Status', {
        timeOut: 2000,
        closeButton: true
      });
    } else if (value == "warning") {
      this.toastr.warning(msg, 'Report Status', {
        timeOut: 2000,
        closeButton: true
      });
    } else {
      this.toastr.error(msg, 'Report Status', {
        timeOut: 2000,
        closeButton: true
      });
    }
  }


  toggleAddKeyPeople(){
    if (this.popoverforkeypeople == true) {
      this.popoverforkeypeople = false;
    } else {
      this.popoverforkeypeople = true;
    }
    this.allCouncil =[];
    this.keypeopleAllArrselect = [];
    this.councilErr = null;

    // this.http.get('/api/certifiers/').subscribe((res: Response) => {
    //   this.result = res;
    //   if (this.result.length != 0) {
    //     for (let i = 0; i < this.result.length; i++) {
    //       this.allCertifiers.push({
    //         "id": this.result[i].id, "itemName": this.result[i].firstName + " " + this.result[i].lastName,
    //       })
    //     }
    //   }
    // }, error => {
    //   this.toastr.clear();
    //   this.toastr.error(error, 'Report Status', {
    //     timeOut: 6000,
    //     closeButton: true
    //   });
    //   this.errorService.handleError(error);
    // });

    this.http.get('/api/council/').subscribe((res: Response) => {
      this.result1 = res;
      if (this.result1.length != 0) {
        for (let i = 0; i < this.result1.length; i++) {
          this.allCouncil.push({
            "id": this.result1[i].id, "itemName": this.result1[i].firstName + " " + this.result1[i].lastName,
          })
        }
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });

    if (this.menuStateOrgEdit == 'in') {
      this.menuStateOrgEdit = 'out';
    }
    if (this.menuStateProjectUserEdit == 'in') {
      this.menuStateProjectUserEdit = 'out';
    }
    if (this.menuStateCustomerEdit == 'in') {
      this.menuStateCustomerEdit = 'out';
    }
    this.menuStateKeypeopleEdit1 = this.menuStateKeypeopleEdit1 === 'out' ? 'in' : 'out';
  }
  toggleCloseKeypeople(){
    this.keypeopleAllArrselect = [];
    this.menuStateKeypeopleEdit1 = this.menuStateKeypeopleEdit1 === 'out' ? 'in' : 'out';
    this.popoverforkeypeople = false;
  }

  submitKeypeople(){
    if (this.keypeopleValidate()) {
      this.spinner.show();
      var data = this.keypeopleForm.value;
      console.log(JSON.stringify(data));
      //data.customerId = data.certifierId[0].id;
      this.spinner.show();
      this.http.get('/api/project/' + this.projectId).subscribe((res: Response) => {
        this.result = res;
        this.spinner.hide();
        if (this.result != null) {
          //this.result.certifierId = data.certifierId[0].id;
          this.result.councilId = data.councilId[0].id;
          this.result.updatedBy = parseInt(this.userid);
          this.http.put('/api/project/', this.result).subscribe((res1: Response) => {
            this.res2 = res1;
            if (this.res2.success == true) {
              this.toastr.clear();
              this.toastr.success(this.res2.message, 'Report Status', {
                timeOut: 6000,
                closeButton: true
              });
              this.toggleCloseKeypeople();
              this.getprojectinfo();
            }
          }, error => {
            this.toastr.clear();
            this.toastr.error(error, 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.errorService.handleError(error);
          })
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      })
    }

  }
  keypeopleValidate() {
    this.councilErr = null;
    var valid = true;
    var data = this.keypeopleForm.value;

    // if (data.councilId.length == 0) {
    //   this.councilErr = "Council is required";
    //   valid = false;
    // }
    return valid;
  }

  getprojectinfo() {
    // const id = +this.route.snapshot.paramMap.get('id');
    // this.projectId = id;
    this.http.get('/api/project/CertMang/' + this.projectId).subscribe((res: Response) => {
      this.certMang= res;
    });
  }
  getdocuments() {
    this.http.get('/api/fileMap/project/' + this.projectId).subscribe((res1: Response) => {
      this.result = res1;
      this.nodes = this.result;
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  togglefoler(num, treeModel: any) {
    if (treeModel.activeNodes.length > 0) {
      let last: any = treeModel.activeNodes[treeModel.activeNodes.length - 1];
      let type = last.data.type;
      if (type == "folder") {
        this.folderErr = null;
        this.resetForms();
        if (num == 0) {
          this.isCreate = true;
          if (this.fol == false) {
            this.fol = true;
            if (this.doc == true) {
              this.doc = false;
            }
          } else {
            this.fol = false;
          }
        } else if (num == 1) {
          this.isCreate = false;
          if (this.fol == false) {
            this.fol = true;
            if (this.doc == true) {
              this.doc = false;
            }
          } else {
            this.fol = false;
          }
        } else {
          this.fol = false;
        }
      } else {
        this.toastr.clear();
        this.toastr.error('Please Select the Folder', 'Status', {
          timeOut: 6000,
          closeButton: true
        });
      }
    } else {
      this.folderErr = null;
      this.resetForms();
      if (num == 0) {
        this.isCreate = true;
        if (this.fol == false) {
          this.fol = true;
          if (this.doc == true) {
            this.doc = false;
          }
        } else {
          this.fol = false;
        }
      } else if (num == 1) {
        this.isCreate = false;
        if (this.fol == false) {
          this.fol = true;
          if (this.doc == true) {
            this.doc = false;
          }
        } else {
          this.fol = false;
        }
      } else {
        this.fol = false;
      }
    }



  }
  downloadNodes(treeModel: any) {
    if (treeModel.activeNodes.length > 0) {
      let last: any = treeModel.activeNodes[treeModel.activeNodes.length - 1];
      let type = last.data.type;
      let fid = last.data.id;
      if (type == "file") {
        this.downloadFile(fid)
      }
    }
  }
  foldercheck(treeModel: any) {
    if (treeModel.activeNodes.length > 0) {
      let last: any = treeModel.activeNodes[treeModel.activeNodes.length - 1];
      let type = last.data.type;
      if (type == "folder") {
        this.folderval = true;
      } else {
        this.folderval = false;
      }
    }
  }
  toggledocument(num, treeModel: any) {
    if (treeModel.activeNodes.length > 0 && num == 0) {
      let last: any = treeModel.activeNodes[treeModel.activeNodes.length - 1];
      let type = last.data.type;
      if (type == "folder") {
        this.documentErr = null;
        this.resetForms();
        if (this.doc == false) {
          this.doc = true;
          if (this.fol == true) {
            this.fol = false;
          }
        } else {
          this.doc = false;
        }
      } else {
        this.toastr.clear();
        this.toastr.error('Please Select the Folder', 'Status', {
          timeOut: 6000,
          closeButton: true
        });
      }
    } else if (num == 1) {
      this.documentErr = null;
      this.resetForms();
      this.doc = false;
    } else {
      this.toastr.clear();
      this.toastr.error('Please Select the Folder', 'Status', {
        timeOut: 6000,
        closeButton: true
      });
    }
  }
  editNodes(treeModel: any) {
    if (treeModel.activeNodes.length > 0) {
      let last: any = treeModel.activeNodes[treeModel.activeNodes.length - 1];
      let rootid = last.data.id;
      let type = last.data.type;
      if (type == "folder") {
        this.togglefoler(1, treeModel);
        this.http.get('/api/fileFolder/' + rootid).subscribe((res: Response) => {
          this.result = res;
          if (this.result) {
            this.folderform.patchValue({ 'id': this.result.id });
            this.folderform.patchValue({ 'folder': this.result.folderName });
            this.folderform.patchValue({ 'rootId': this.result.rootId });
            this.folderform.patchValue({ 'isActive': this.result.isActive });
            this.folderform.patchValue({ 'projectId': this.result.projectId });
            this.folderform.patchValue({ 'createdDate': this.result.createdDate });
            this.folderform.patchValue({ 'updatedDate': this.result.updatedDate });
            this.folderform.patchValue({ 'createdBy': this.result.createdBy });
          }
        }, error => {
          this.toastr.clear();
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.errorService.handleError(error);
        });
      } else {
        this.toastr.clear();
        this.toastr.error('Please Select the Folder', 'Status', {
          timeOut: 6000,
          closeButton: true
        });
      }
    } else {
      this.toastr.clear();
      this.toastr.error('Please Select the Folder', 'Status', {
        timeOut: 6000,
        closeButton: true
      });
    }
  }

  updateNodes() {
    if (this.folderValidate()) {
      this.spinner.show();
      this.body = {};
      this.body["id"] = this.folderform.value["id"];
      this.body["folderName"] = this.folderform.value["folder"];
      this.body["rootId"] = this.folderform.value["rootId"];
      this.body["isActive"] = this.folderform.value["isActive"];
      this.body["projectId"] = this.folderform.value["projectId"];
      this.body["createdDate"] = this.folderform.value["createdDate"];
      this.body["updatedDate"] = new Date();
      this.body["updatedBy"] = parseInt(this.userid);
      this.body["createdBy"] = this.folderform.value["createdBy"];
      this.http.put('/api/fileFolder', this.body).subscribe((response) => {
        this.res = response;
        if (this.res.success == true) {
          this.toastr.clear();
          this.toastr.success(this.res.message, 'Success', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
          this.nodes = [];
          this.emptyarr["activeNodes"] = [];
          this.togglefoler(2, this.emptyarr)
          this.getdocuments();
        } else {
          this.spinner.hide();
          this.toastr.clear();
          this.toastr.error(this.res.message, 'Error', {
            timeOut: 6000,
            closeButton: true
          });
        } error => {
          this.errorService.handleError(error);
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
    }
  }
  addNodes(treeModel: any) {
    if (treeModel.activeNodes.length > 0) {
      let last: any = treeModel.activeNodes[treeModel.activeNodes.length - 1];
      let rootid = last.data.id;
      var data1 = {};
      if (this.folderValidate()) {
        this.spinner.show();
        var data = this.folderform.value;
        data1 = { "folderName": data.folder, "rootId": rootid, "projectId": this.projectId, "createdBy": parseInt(this.userid) }
        this.http.post('/api/fileFolder', data1).subscribe((response) => {
          this.res = response;
          if (this.res.success == true) {
            this.toastr.clear();
            this.toastr.success(this.res.message, 'Success', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
            this.nodes = [];
            this.emptyarr["activeNodes"] = [];
            this.togglefoler(2, this.emptyarr)
            this.getdocuments();
          } else {
            this.spinner.hide();
            this.toastr.clear();
            this.toastr.error(this.res.message, 'Error', {
              timeOut: 6000,
              closeButton: true
            });
          }
        }, error => {
          this.toastr.clear();
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.errorService.handleError(error);
        });
      }
    } else {
      let rootid = 0;
      var data1 = {};
      if (this.folderValidate()) {
        this.spinner.show();
        var data = this.folderform.value;
        data1 = { "folderName": data.folder, "rootId": rootid, "projectId": this.projectId, "createdBy": parseInt(this.userid) }
        this.http.post('/api/fileFolder', data1).subscribe((response) => {
          this.res = response;
          if (this.res.success == true) {
            this.toastr.clear();
            this.toastr.success(this.res.message, 'Success', {
              timeOut: 6000,
              closeButton: true
            });
            this.spinner.hide();
            this.nodes = [];
            this.emptyarr["activeNodes"] = [];
            this.togglefoler(2, this.emptyarr)
            this.getdocuments();
          } else {
            this.spinner.hide();
            this.toastr.clear();
            this.toastr.error(this.res.message, 'Error', {
              timeOut: 6000,
              closeButton: true
            });
          }

        }, error => {
          this.toastr.clear();
          this.toastr.error(error, 'Report Status', {
            timeOut: 6000,
            closeButton: true
          });
          this.errorService.handleError(error);
        });
      }
    }
  }

  adddocumentNodes(fileids, rootid) {
    var data1 = {};
    this.spinner.show();
    data1 = { "fileFolderId": rootid, "fileId": fileids, "projectId": this.projectId, "createdBy": parseInt(this.userid) }
    this.http.post('/api/fileMap', data1).subscribe((response) => {
      this.res = response;
      if (this.res.success == true) {
        this.toastr.clear();
        this.toastr.success(this.res.message, 'Success', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
        this.nodes = [];
        this.emptyarr["activeNodes"] = [];
        this.toggledocument(1, this.emptyarr);
        this.getdocuments();
      } else {
        this.toastr.clear();
        this.toastr.error(this.res.message, 'Error', {
          timeOut: 6000,
          closeButton: true
        });
        this.spinner.hide();
      }
    }, error => {
      this.toastr.clear();
      this.toastr.error(error, 'Report Status', {
        timeOut: 6000,
        closeButton: true
      });
      this.errorService.handleError(error);
    });
  }
  deleteNodes(treeModel: any) {
    if (treeModel.activeNodes.length > 0) {
      let last: any = treeModel.activeNodes[treeModel.activeNodes.length - 1];
      let rootid = last.data.id;
      let type = last.data.type;
      if (type == "folder") {
        this.confirmService.confirm('Please confirm..', 'Do you want to remove the Folder ?')
          .then((confirmed) => {
            if (confirmed) {
              this.spinner.show();
              this.http.delete('/api/fileFolder/' + rootid).subscribe((response) => {
                this.res = response;
                if (this.res.success == true) {
                  this.toastr.clear();
                  this.toastr.success(this.res.message, 'Success', {
                    timeOut: 6000,
                    closeButton: true
                  });
                  this.spinner.hide();
                  this.nodes = [];
                  this.getdocuments();
                } else {
                  this.spinner.hide();
                  this.toastr.clear();
                  this.toastr.error(this.res.message, 'Error', {
                    timeOut: 6000,
                    closeButton: true
                  });
                }
              }, error => {
                this.toastr.clear();
                this.toastr.error(error, 'Report Status', {
                  timeOut: 6000,
                  closeButton: true
                });
                this.errorService.handleError(error);
              });
            }
          })
          .catch(() => {
            return false;
          });
      } else {
        this.confirmService.confirm('Please confirm..', 'Do you want to remove the document ?')
          .then((confirmed) => {
            if (confirmed) {
              this.spinner.show();
              this.http.delete('/api/fileMap/' + rootid).subscribe((response) => {
                this.res = response;
                if (this.res.success == true) {
                  this.toastr.clear();
                  this.toastr.success(this.res.message, 'Success', {
                    timeOut: 6000,
                    closeButton: true
                  });
                  this.spinner.hide();
                  this.nodes = [];
                  this.getdocuments();
                } else {
                  this.spinner.hide();
                  this.toastr.clear();
                  this.toastr.error(this.res.message, 'Error', {
                    timeOut: 6000,
                    closeButton: true
                  });
                }
              }, error => {
                this.toastr.clear();
                this.toastr.error(error, 'Report Status', {
                  timeOut: 6000,
                  closeButton: true
                });
                this.errorService.handleError(error);
              });
            }
          })
          .catch(() => {
            return false;
          });
      }

    } else {
      this.toastr.clear();
      this.toastr.error('Please Select the Folder', 'Status', {
        timeOut: 6000,
        closeButton: true
      });
    }
  }
  folderValidate() {
    this.folderErr = null;
    var valid = true;
    var data = this.folderform.value;
    if (!data.folder) {
      this.folderErr = "Folder Name is required";
      valid = false;
    }

    return valid;
  }
  editTreepermission(treeModel,num){
    if(num == 0 ){
      this.treeEditPermissionTrue = !this.treeEditPermissionTrue;
      if (treeModel.activeNodes.length > 0) {
        let last: any = treeModel.activeNodes[treeModel.activeNodes.length - 1];
        let mapId = last.data.mapId;
        let type = last.data.type;
        if (type == "file") {
          this.treeMapId = mapId;
          if(last.data.isCustomerShared == 'Y'){
            this.documentPermissionForm.patchValue({ 'isCustomerShared': true});
          }else{
            this.documentPermissionForm.patchValue({ 'isCustomerShared': false});
          }

          if(last.data.isCertifierShared == 'Y'){
            this.documentPermissionForm.patchValue({ 'isCertifierShared': true});
          }else{
            this.documentPermissionForm.patchValue({ 'isCertifierShared': false});
          }

          if(last.data.isExternalUserShared == 'Y'){
            this.documentPermissionForm.patchValue({ 'isExternalUserShared': true});
          }else{
            this.documentPermissionForm.patchValue({ 'isExternalUserShared': false});
          }
        }

    }
    }else{
      this.treeEditPermissionTrue = !this.treeEditPermissionTrue;
    }
  }

  permissionCheck(num){
      var data= this.documentPermissionForm.value;
      switch (num) {
        case 0:
          if(data.isCustomerShared == false){
            this.documentPermissionForm.patchValue({ 'isCustomerShared': true});
            this.treeNodesPermissionFunction();
          }else{
            this.documentPermissionForm.patchValue({ 'isCustomerShared': false});
            this.treeNodesPermissionFunction();
          }
          break;
        case 1:
          if(data.isCertifierShared == false){
            this.documentPermissionForm.patchValue({ 'isCertifierShared': true});
            this.treeNodesPermissionFunction();
          }else{
            this.documentPermissionForm.patchValue({ 'isCertifierShared': false});
            this.treeNodesPermissionFunction();
          }
          break;
        case 2:
          if(data.isExternalUserShared == false){
            this.documentPermissionForm.patchValue({ 'isExternalUserShared': true});
            this.treeNodesPermissionFunction();
          }else{
            this.documentPermissionForm.patchValue({ 'isExternalUserShared': false});
            this.treeNodesPermissionFunction();
          }
          break;

      }
    }

    treeNodesPermissionFunction(){
      this.spinner.show();
      var data= this.documentPermissionForm.value;
      data.mapId =this.treeMapId;
      if(data.isCustomerShared == true){
        data.isCustomerShared = 'Y';
        this.documentPermissionForm.patchValue({ 'isCustomerShared': true});
      }else{
        data.isCustomerShared = 'N';
        this.documentPermissionForm.patchValue({ 'isCustomerShared': false});
      }

      if(data.isCertifierShared == true){
        data.isCertifierShared ='Y';
        this.documentPermissionForm.patchValue({ 'isCertifierShared': true});
      }else{
        data.isCertifierShared = 'N';
        this.documentPermissionForm.patchValue({ 'isCertifierShared': false});
      }

      if(data.isExternalUserShared == true){
        data.isExternalUserShared = 'Y';
        this.documentPermissionForm.patchValue({ 'isExternalUserShared': true});
      }else{
        data.isExternalUserShared = 'N';
        this.documentPermissionForm.patchValue({ 'isExternalUserShared': false});
      }
      this.http.put('/api/fileMap', data).subscribe((response: Response) => {
        this.result = response;
        if (this.result.success == true) {
          this.toastr.clear();
          this.toastr.success(this.result.message, 'Success', {
            timeOut: 2000,
            closeButton: true
          });
          //this.treeEditPermissionTrue = false;
          this.nodes = [];
          this.getdocuments()
          this.spinner.hide();
        } else {
          this.toastr.clear();
          this.toastr.error(this.result.message, 'Error', {
            timeOut: 2000,
            closeButton: true
          });
          this.spinner.hide();
        }
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 2000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
    }

  // getdocumentFile(files: FileList) {
  //   this.fileTodocument = files.item(0);
  // }
  getdocumentFile(e) {
    this.myFilesDocument = [];
    this.myFilesDocumentArr = [];
    for (var i = 0; i < e.target.files.length; i++) {
      this.myFilesDocument.push(e.target.files[i]);
      this.myFilesDocumentArr.push({
        "name": e.target.files[i].name
      })
    }
  }
  // postdocumentMethod(treeModel: any) {
  //   if (this.cleardocumentFile(0)) {
  //     this.spinner.show();
  //     let last: any = treeModel.activeNodes[treeModel.activeNodes.length - 1];
  //     let rootid = last.data.id;
  //     var formData = new FormData();
  //     formData.append("file", this.fileTodocument, this.fileTodocument.name);

  //     this.http.post('/api/upload/project/' + this.projectId + '/' + this.userid, formData).subscribe((response) => {
  //       this.toastr.clear();
  //       this.result = response;
  //       if (this.result.success == true) {
  //         this.adddocumentNodes(this.result.response.id, rootid)
  //         this.toastr.success(' Upload File successfully', 'Status', {
  //           timeOut: 6000,
  //           closeButton: true
  //         });
  //       } else {
  //         this.toastr.clear();
  //         this.toastr.error(this.result.message, 'Error', {
  //           timeOut: 6000,
  //           closeButton: true
  //         });
  //         this.spinner.hide();
  //       }
  //       this.spinner.hide();
  //       this.fileTodocument = [];
  //     }, error => {
  //       this.toastr.clear();
  //       this.toastr.error(error, 'Report Status', {
  //         timeOut: 6000,
  //         closeButton: true
  //       });
  //       this.errorService.handleError(error);
  //     });
  //   }
  // }

  postdocumentMethod(treeModel: any) {
    if (this.cleardocumentFile(0)) {
      this.spinner.show();
      let last: any = treeModel.activeNodes[treeModel.activeNodes.length - 1];
      let rootid = last.data.id;
      const frmData = new FormData();
      for (var i = 0; i < this.myFilesDocument.length; i++) {
        frmData.append("file", this.myFilesDocument[i]);
      }
      frmData.append("description", "");
      this.http.post('/api/upload/project/' + this.projectId + '/' + this.userid, frmData).subscribe((response) => {
        this.toastr.clear();
        this.result = response;
        var lastarr = this.result.successArr.length - 1;
        if (this.result.successArr.length > 0) {
          for (var i = 0; i < this.result.successArr.length; i++) {
            this.adddocumentNodes(this.result.successArr[i].response.id, rootid)
            if (lastarr == i) {
              this.myFilesDocumentArr = [];
              this.toastr.success(' Upload File successfully', 'Status', {
                timeOut: 6000,
                closeButton: true
              });
            }
          }
        } else if (this.result.failureArr.length > 0) {
          this.toastr.clear();
          this.toastr.error(this.result.failureArr[0].message, 'Error', {
            timeOut: 6000,
            closeButton: true
          });
          this.spinner.hide();
        }
        this.spinner.hide();
        this.fileTodocument = [];
      }, error => {
        this.toastr.clear();
        this.toastr.error(error, 'Report Status', {
          timeOut: 6000,
          closeButton: true
        });
        this.errorService.handleError(error);
      });
    }
  }

  cleardocumentFile(num) {
    if (num == 0) {
      this.documentErr = null;
      var valid = true;
      let data = this.documentform.value;
      if (!data.document) {
        this.documentErr = "Document is required";
        valid = false;
      }
      return valid;
    }
  }

}

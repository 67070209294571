import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { NgForm, EmailValidator } from '@angular/forms';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { ErrorService } from 'src/app/_services/error.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, animate, transition, style } from '@angular/animations';
import * as moment from 'moment/moment.js';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-domain-registry',
  templateUrl: './domain-registry.component.html',
  styleUrls: ['./domain-registry.component.css'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class DomainRegistryComponent implements OnInit {
  step: any;
  result: any;
  settings: any;
  body: {};
  loggedInUserID: any;
  tab01: boolean = false;
  tab02: boolean = false;
  popoverform: boolean = false;
  profile_pc_view: boolean = false;
  editSh: boolean = false;
  resetlogin: boolean = false;
  domainLengthCheck: boolean = false;
  popoverform1: boolean = false;
  popoverform2: boolean = false;
  resetpasswordErr: any;
  confirmpasswordErr: any;
  domainVerifiedresult: any;
  domainNonVerifiedresult: any;
  menuStateDomainReset: string = 'out';
  menuStateDomainCreation: string = 'out';
  menuStateCreateDomain: string = 'out';
  passwordForm: FormGroup;
  passwordDoaminForm: FormGroup;
  domainCreationForm: FormGroup;
  domainDetails: any;
  loggedInUserName: any;
  proPicId: any;
  profilePicView: any;
  domainLength: any;
  registrCusomerErr: any;
  registrEmailErr: any;
  registrPhoneErr: any;
  registrCompanyErr: any;
  myDatePickerOptions: any;
  today: any;
  dateErr: any;
  addDomainValue: any;
  sortArr = [];
  sortArrSelect = [];
  filter: any;
  reverse: any;
  p: any;
  term: any;
  term1: any;
  selDate: any;
  sortArrCreated = [];
  sortArrCreatedSelect = [];
  ProjectName: any;

  constructor(private http: HttpClient, private router: Router,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService, private toastr: ToastrService,
    private errorService: ErrorService,
    private confirmService: ConfirmService,
    private modalService: NgbModal, private route: ActivatedRoute,
    private titleService: Title, private metaService: Meta) { }

  ngOnInit() {
    this.loggedInUserID = localStorage.getItem("loggedInUserID");
    this.loggedInUserName = localStorage.getItem("loggedInUser");
    this.proPicId = localStorage.getItem("loggedInUserproPicId");
    this.domainLength = localStorage.getItem("domainLength");
    this.getVerifiedDomain();
    this.createForm();
    this.getProjectName();
    if (this.domainLength == "true") {
      this.domainLengthCheck = false;
    } else {
      this.domainLengthCheck = true;
    }
    this.today = new Date();
    this.myDatePickerOptions = {
      dateFormat: 'dd-mm-yyyy',
      disableUntil: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() - 1 }
    }
    this.settings = {
      text: "Sort Filter",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      disabled: false,
      singleSelection: true,
      enableCheckAll: false
    };
    this.sortArr = [];
    this.sortArr = [
      { "id": 1, "itemName": "Expire Date DESC" },
      { "id": 2, "itemName": "Expire Date ASC" },
      { "id": 3, "itemName": "Created Date DESC" },
      { "id": 4, "itemName": "Created Date ASC" }
    ];
    this.sortArrCreated = [
      { "id": 3, "itemName": "Created Date DESC" },
      { "id": 4, "itemName": "Created Date ASC" }
    ];
  }
  onchangtab(value) {
    var id = value.nextId;
    if (id == "Verified") {
      this.getVerifiedDomain();
      this.term = null;
    } else if (id == "NonVerified") {
      this.sortArrCreatedSelect = [];
      this.getPendigDomain();
      this.term1 = null;
    }
  }
  createForm() {
    this.passwordForm = this.formBuilder.group({
      password: '',
      confirmPassword: '',
      expireAt: ''
    });
    this.domainCreationForm = this.formBuilder.group({
      customerName: '',
      companyName: '',
      registeredEmailId: '',
      registeredPhone: '',
      shortDescription: ''
    });
    this.passwordDoaminForm = this.formBuilder.group({
      password: '',
      confirmPassword: '',
      expireAt: ''
    });
  }
  passwordresetForm() {
    this.resetpasswordErr = null;
    this.confirmpasswordErr = null;
    // this.passwordForm({
    //   password: '',
    //   confirmPassword: '',
    // })
  }

  getVerifiedDomain() {
    this.spinner.show();
    this.body = {};
    this.body['search'] = '';
    this.body['order'] = 1;
    this.sortArrSelect = [];
    this.sortArrSelect.push({ "id": 1, "itemName": "Expire Date DESC" });
    this.http.post('/api/domainRegistries/verified', this.body).subscribe((res: Response) => {
      this.domainVerifiedresult = res;
      this.spinner.hide();
    });
  }
  sortFilterApproved(item) {
    this.spinner.show();
    this.body = {};
    this.body['search'] = '';
    this.body['order'] = item.id;
    this.http.post('/api/domainRegistries/verified', this.body).subscribe((res: Response) => {
      this.domainVerifiedresult = res;
      this.spinner.hide();
    });
  }
  getPendigDomain() {
    this.spinner.show();
    this.body = {};
    this.body['search'] = '';
    this.body['order'] = 1;
    this.http.post('/api/domainRegistries/nonVerified', this.body).subscribe((res: Response) => {
      this.domainNonVerifiedresult = res;
      this.spinner.hide();
    });
  }
  sortFilterPendingApproved(item) {
    this.spinner.show();
    this.body = {};
    this.body['search'] = '';
    this.body['order'] = item.id;
    this.http.post('/api/domainRegistries/nonVerified', this.body).subscribe((res: Response) => {
      this.domainNonVerifiedresult = res;
      this.spinner.hide();
    });
  }
  addDomain(value) {
    this.addDomainValue = value;
    if (this.popoverform2 == true) {
      this.popoverform2 = false;
    } else {
      this.popoverform2 = true;
    }
    this.menuStateCreateDomain = this.menuStateCreateDomain === 'out' ? 'in' : 'out';
    // this.body = {};
    // this.body["email"] = value.registeredEmailId;
    // this.body["domainName"] = value.companyName;
    // this.body["createdBy"] = parseInt(this.loggedInUserID);
    // this.spinner.show();
    // this.http.post('/unsecure/domain', this.body).subscribe((response) => {
    //   this.result = response;
    //   var status = this.result.success;
    //   var message = this.result.message;
    //   if (status == true) {
    //     this.toasterAlert(message, "Success");
    //   } else {
    //     this.toasterAlert(message, "Error");
    //   }
    // }, error => {
    //   this.spinner.hide();
    //   this.errorService.handleError(error);
    // });
  }
  resetDomain(data) {
    this.createForm();
    this.resetpasswordErr = null;
    this.confirmpasswordErr = null;
    this.dateErr = null;
    this.domainDetails = data;
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.menuStateDomainReset = this.menuStateDomainReset === 'out' ? 'in' : 'out';
    var cdate = data.expireAt;
    if (cdate) {
      var ydate = moment(cdate).format("YYYY")
      var mdate = moment(cdate).format("M")
      var ddate = moment(cdate).format("D")
      this.passwordForm.patchValue({
        'expireAt': {
          date: {
            year: ydate,
            month: mdate,
            day: ddate
          }
        }
      });
    } else {
      this.passwordForm.patchValue({ expireAt: null });
    }
  }
  toggleCloseReset() {
    if (this.popoverform == true) {
      this.popoverform = false;
    } else {
      this.popoverform = true;
    }
    this.menuStateDomainReset = this.menuStateDomainReset === 'out' ? 'in' : 'out';
  }
  resetDomainSave(value) {
    if (this.passwordValidate(0)) {
      var data = this.passwordForm.value;
      data.password = data.confirmPassword;
      data.domainId = parseInt(value.domainId);
      this.spinner.show();
      this.http.post('/api/domainAdmin/resetPassword', data).subscribe((response) => {
        this.result = response;
        var status = this.result.success;
        var message = this.result.message;
        if (status == true) {
          this.getVerifiedDomain();
          this.toggleCloseReset();
          this.toasterAlert(message, "Success");
        } else {
          this.toasterAlert(message, "Error");
        }
      }, error => {
        this.spinner.hide();
        this.errorService.handleError(error);
      });
    }
  }
  passwordValidate(num) {
    if (num == 0) {
      this.resetpasswordErr = null;
      this.confirmpasswordErr = null;
      this.dateErr = null;
      var data = this.passwordForm.value;
      const strongRegex = /(?=.{6,})/;
      var valid = true;
      if (data.password == Validators || data.password == '') {
        this.resetpasswordErr = "Password is required.";
        valid = false;
      } else if (!strongRegex.test(data.password)) {
        this.resetpasswordErr = "Password is Must be 6 Charcters.";
        valid = false;
      } else if (data.confirmPassword == Validators || data.confirmPassword == '') {
        this.confirmpasswordErr = "Confirm Password is required.";
        valid = false;
      } else if (data.password != data.confirmPassword) {
        this.confirmpasswordErr = "Password doesn't Match.";
        valid = false;
      }
      return valid;
    } else if (num == 1) {
      this.resetpasswordErr = null;
      this.confirmpasswordErr = null;
      var data = this.passwordDoaminForm.value;
      const strongRegex = /(?=.{6,})/;
      var valid = true;
      if (data.password == Validators || data.password == '') {
        this.resetpasswordErr = "Password is required.";
        valid = false;
      } else if (!strongRegex.test(data.password)) {
        this.resetpasswordErr = "Password is Must be 6 Charcters.";
        valid = false;
      } else if (data.confirmPassword == Validators || data.confirmPassword == '') {
        this.confirmpasswordErr = "Confirm Password is required.";
        valid = false;
      } else if (data.password != data.confirmPassword) {
        this.confirmpasswordErr = "Password doesn't Match.";
        valid = false;
      } else if (!data.expireAt) {
        this.dateErr = "Exp date is required";
        valid = false;
      }
      return valid;
    } else {
      this.resetpasswordErr = null;
      this.confirmpasswordErr = null;
      this.dateErr = null;
      var data = this.passwordForm.value;
      var valid = true;
      if (!data.expireAt) {
        this.dateErr = "Exp date is required";
        valid = false;
      }
      return valid;
    }

  }
  getUserProfile() {
    if (this.proPicId == "null") {
      this.profile_pc_view = false;
    } else {
      this.profile_pc_view = true;
      this.profilePicView = '/unsecure/gallery/' + this.proPicId;
    }
  }
  logFun() {
    this.editSh = false;
    if (this.resetlogin == true) {
      this.resetlogin = false;
    } else {
      this.resetlogin = true;
    }
  }

  toasterAlert(msg, value) {
    this.spinner.hide();
    this.toastr.clear();
    if (value == "Success") {
      this.toastr.success(msg, 'Report Status', {
        timeOut: 2000,
        closeButton: true
      });
    } else if (value == "warning") {
      this.toastr.warning(msg, 'Report Status', {
        timeOut: 2000,
        closeButton: true
      });
    } else {
      this.toastr.error(msg, 'Report Status', {
        timeOut: 2000,
        closeButton: true
      });
    }
  }
  domainCreationToggle() {
    if (this.popoverform1 == true) {
      this.popoverform1 = false;
    } else {
      this.popoverform1 = true;
    }
    this.registrCusomerErr = null;
    this.registrEmailErr = null;
    this.registrPhoneErr = null;
    this.registrCompanyErr = null;
    this.createForm();
    this.menuStateDomainCreation = this.menuStateDomainCreation === 'out' ? 'in' : 'out';
  }
  domainRegisterValidate() {
    var data = this.domainCreationForm.value;
    this.registrCusomerErr = null;
    this.registrEmailErr = null;
    this.registrPhoneErr = null;
    this.registrCompanyErr = null;
    const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const validNumber = /^\d+$/;
    const strongRegex = /(?=.{6,})/;
    var valid = true;
    if (!data.customerName) {
      this.registrCusomerErr = "Customer Name is required.";
      valid = false;
    } else if (!data.companyName) {
      this.registrCompanyErr = "Company Name is required.";
      valid = false;
    }
    else if (!data.registeredEmailId) {
      this.registrEmailErr = "Email is required.";
      valid = false;
    } else if (!validEmailRegEx.test(data.registeredEmailId)) {
      this.registrEmailErr = "Invalid Email.";
      valid = false;
    } else if (!data.registeredPhone) {
      this.registrPhoneErr = "Phone Number  is required.";
      valid = false;
    } else if (!validNumber.test(data.registeredPhone)) {
      this.registrPhoneErr = "Invalid Phone Number.";
      valid = false;
    }
    return valid;
  }
  domainSave() {
    var data = this.domainCreationForm.value;
    if (this.domainRegisterValidate()) {
      this.spinner.show();
      this.http.post('/unsecure/domainRegistry', data).subscribe((response) => {
        this.result = response;
        var status = this.result.success;
        var message = this.result.message;
        if (status == true) {
          this.toasterAlert(message, "Success");
          this.domainCreationToggle();
          this.getPendigDomain();
        } else {
          this.toasterAlert(message, "Error");
        }
      }, error => {
        this.errorService.handleError(error);
      });
    }
  }
  onDateChanged(event) {
    this.dateErr = null;
  }
  toggleCloseCreate() {
    if (this.popoverform2 == true) {
      this.popoverform2 = false;
    } else {
      this.popoverform2 = true;
    }
    this.menuStateCreateDomain = this.menuStateCreateDomain === 'out' ? 'in' : 'out';
  }
  createDomainSave(value) {
    if (this.passwordValidate(1)) {
      var data = this.passwordDoaminForm.value;
      value.password = data.confirmPassword;
      if (data.expireAt != null) {
        var dateval = data.expireAt.jsdate;
        value.expireAt = moment(dateval).format("YYYY-MM-DD")
      }
      this.spinner.show();
      this.http.put('/api/domainRegistry/createDomain', value).subscribe((response) => {
        this.result = response;
        var status = this.result.success;
        var message = this.result.message;
        if (status == true) {
          this.getPendigDomain();
          this.toggleCloseCreate();
          this.toasterAlert(message, "Success");
        } else {
          this.toasterAlert(message, "Error");
        }
      }, error => {
        this.spinner.hide();
        this.errorService.handleError(error);
      });
    }
  }
  domainDateSave(value) {
    if (this.passwordValidate(2)) {
      var data = this.passwordForm.value;
      if (data.expireAt != null) {
        var dateval = data.expireAt.jsdate;
        value.expireAt = moment(dateval).format("YYYY-MM-DD")
      }
      this.spinner.show();
      this.http.put('/api/domainRegistry/changeExpire', value).subscribe((response) => {
        this.result = response;
        var status = this.result.success;
        var message = this.result.message;
        if (status == true) {
          this.getVerifiedDomain();
          this.toggleCloseReset();
          this.toasterAlert(message, "Success");
        } else {
          this.toasterAlert(message, "Error");
        }
      }, error => {
        this.spinner.hide();
        this.errorService.handleError(error);
      });
    }
  }
  passwordReset() {
    this.resetpasswordErr = null;
    this.confirmpasswordErr = null;
    this.passwordForm.patchValue({ password: null });
    this.passwordForm.patchValue({ confirmPassword: null });
  }
  getProjectName() {
    this.http.get('/unsecure/app/name').subscribe((res: Response) => {
      this.result = res;
      this.ProjectName = this.result.aname;
      this.titleService.setTitle(this.result.aname);
      localStorage.setItem("ProjectName", this.result.aname);
    });
  }
}

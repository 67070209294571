import { Component, OnInit } from '@angular/core';
import { Options } from 'fullcalendar';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { EventSesrvice } from 'src/app/_services/event.service';
import { PermissionService } from 'src/app/_services/permission.service';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { DetailsService } from '../_services/index';




@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  // public barChartOptions = {
  //   scaleShowVerticalLines: false,
  //   responsive: true
  // };
  // public barChartLabels = [' ', 'Initial', 'Financial', 'Requirement', 'Pellentesque', 'Decision ', 'Deal Completed'];
  // public barChartType = 'bar';
  // public barChartLegend = true;

  // public barChartData = [

  //   { data: [0, 1, 2, 3, 4, 5, 6, 7], label: 'Series A' }

  // ];




  /**PIE Chart**/

  // public doughnutChartLabels: string[] = ['COMPLETED', 'PENDING'];
  // public demodoughnutChartData: number[] = [1, 2];
  // public doughnutChartType: string = 'doughnut';

  // events
  public chartClicked(e: any): void {
  }

  public chartHovered(e: any): void {
  }
  jobcreatedArr = [];
  projectJob: any;
  customerdeal: any;
  customerapproval: any;
  customerproject: any;
  custdealArr = [];
  custapprovalArr = [];
  custprojectArr = [];
  dealstateArr: any;
  dealArr = [];
  calendarOptions: Options;
  approvalproArr = [];
  projectArr = [];
  taskassignArr = [];
  taskcreatedArr = [];
  taskcreatedProjectArr = [];
  taskassignProjectArr = [];
  alltasksArr = [];
  allprojectArr = [];
  allUserArr = [];
  userid: any;
  username: any;
  filter: any;
  reverse: any;
  key: any;
  isAdminDis: boolean = false;
  isDeal: boolean = true;
  isApproval: boolean = true;
  isProject: boolean = true;
  myFilter: any;
  p: any;
  admin: any;
  taskassigned: any;
  taskcreated: any;
  taskassignedProject: any;
  taskcreatedProject: any;
  mydeals: any;
  myapprovals: any;
  myProject: any;
  eid: any;
  colorM: any;
  ccc: any;
  result: any
  result1: any;
  iscustomer: boolean = true;
  istotal: boolean = false;
  result2: any;
  users = {};
  result3: any;
  barChartOptions: any;
  barChartLabels: any;
  barChartLegend: any;
  barChartType: any;
  barChartData: any;
  isDealShow: boolean = false;
  doughnutChartLabels :any;
  demodoughnutChartData :any;
  doughnutChartType :any;
  dealChart:any;
  constructor(private http: HttpClient, private DetailsService: DetailsService, private router: Router, protected eventService: EventSesrvice,
    private toastr: ToastrService, private confirmService: ConfirmService, private spinner: NgxSpinnerService, private permissionService: PermissionService

  ) {
    // this.barChartOptions = {
    //   scaleShowVerticalLines: false,
    //   responsive: true
    // };
    // this.dealChart=[];
    // this.barChartLabels = [];
    // this.barChartType = 'bar';
    // this.barChartLegend = true;
    // this.barChartData = [];

    this.doughnutChartLabels= ['COMPLETED', 'PENDING'];
    // this.demodoughnutChartData= [1, 2];
    this.doughnutChartType = 'doughnut';

  }


  ngOnInit() {
    this.spinner.show();
    this.username = localStorage.getItem("loggedInUser");
    this.userid = localStorage.getItem("loggedInUserID");
    this.admin = localStorage.getItem("loggedInUserAdmin");
    this.getPermissionDetails();
    this.getUser();
    this.getDealDiagram();
    this.getApprovalDiagram();
    // this.getdealbyuser();
    // this.getApprovalbyuser();
    // this.getProjectUser();
    // this.getTaskassigned();
    // this.gettaskcreated();
    // this.getTaskassignedProject();
    // this.gettaskcreatedProject();
    // this.calendarFunc();
    // this.getAllTasks();

  }
  calendarFunc() {
    this.spinner.show();
    this.eventService.getPeopleListEvents().subscribe(data => {
      data = data;
      this.calendarOptions = {
        defaultView: 'listMonth',
        editable: false,
        eventLimit: false,
        header: {
          left: 'prev,next today',
          center: 'title',
          right: '',

        },
        events: data
      };
      this.spinner.hide();
    });
  }
  getPermissionDetails() {
    this.http.get('/api/user/' + this.userid).subscribe((res: Response) => {
      this.result = res;
      var permision = this.result.role.permision;
      if (permision.length > 0) {
        var dealPermission = this.permissionService.getDealPermission(permision);
        var approvalPermission = this.permissionService.getApprovalPermission(permision);
        var projectPermission = this.permissionService.getProjectPermission(permision);
        var customerPermission = this.permissionService.getCustomerPermission(permision);
        if (customerPermission == this.permissionService.CUSTOMER) {
          this.getovercalls(1);
          this.istotal = true;
          this.iscustomer = true;
        } else {
          this.getovercalls(0);
        }
        if (dealPermission == this.permissionService.DEAL_ADMIN || this.admin == "Y") {
          this.istotal = true;
          this.iscustomer = false;
          this.isAdminDis = true;
          this.isDeal = true;
        } else if (dealPermission == this.permissionService.DEAL_OBSERVER) {
          this.istotal = true;
          this.iscustomer = false;
          this.isDeal = true;
          this.isProject = false;
          this.isApproval = false;
        }
        if (approvalPermission == this.permissionService.APPROVAL_ADMIN || this.admin == "Y") {
          this.istotal = true;
          this.iscustomer = false;
          this.isAdminDis = true;
          this.isApproval = true;
        } else if (approvalPermission == this.permissionService.APPROVAL_OBSERVER) {
          this.istotal = true;
          this.iscustomer = false;
          this.isApproval = true;
          this.isDeal = false;
          this.isProject = false;
        }
        if (projectPermission == this.permissionService.PROJECT_ADMIN || this.admin == "Y") {
          this.istotal = true;
          this.iscustomer = false;
          this.isAdminDis = true;
          this.isProject = true;
        } else if (projectPermission == this.permissionService.PROJECT_OBSERVER) {
          this.istotal = true;
          this.iscustomer = false;
          this.isProject = true;
          this.isApproval = false;
          this.isDeal = false;
        }

      }
    });
  }
  getovercalls(num) {
    if (num == 1) {
      this.getcustomerdetials();
    } else {

      this.getdealbyuser();
      this.getApprovalbyuser();
      this.getProjectUser();
      this.getTaskassigned();
      this.gettaskcreated();
      this.getjobcreated();
      this.calendarFunc();
    }
  }
  getcustomerdetials() {
    var url = '/api/customer/projects/' + this.userid;
    this.http.get(url).subscribe((res: Response) => {
      this.result = res;
      if (this.result)
        this.custdealArr = this.result.deal;
      this.custapprovalArr = this.result.approval;
      this.custprojectArr = this.result.project;
      if (this.result.deal.length < 10) {
        this.customerdeal = ("0" + this.result.deal.length).slice(-2);
      } else if (this.result.deal.length == 0) {
        this.customerdeal = "00";
      } else {
        this.customerdeal = this.result.deal.length;
      }
      if (this.result.approval.length < 10) {
        this.customerapproval = ("0" + this.result.approval.length).slice(-2);
      } else if (this.result.approval.length == 0) {
        this.customerapproval = "00";
      } else {
        this.customerapproval = this.result.approval.length;
      }
      if (this.result.project.length < 10) {
        this.customerproject = ("0" + this.result.project.length).slice(-2);
      } else if (this.result.project.length == 0) {
        this.customerproject = "00";
      } else {
        this.customerproject = this.result.project.length;
      }
    });
    this.spinner.hide();
  }

  getjobcreated() {
    this.http.get('/api/projectJob/user/' + this.userid).subscribe((res: Response) => {
      this.result = res;
      if (this.result.length < 10) {
        this.projectJob = ("0" + this.result.length).slice(-2);
      } else if (this.result.length == 0) {
        this.projectJob = "00";
      } else {
        this.projectJob = this.result.length;
      }

      for (let i = 0; i < this.result.length; i++) {
        this.jobcreatedArr.push({
          "id": this.result[i].id, "jobName": this.result[i].jobName, "projectName": this.result[i].projectName, "assigned": this.result[i].assigned,
          "startDate": this.result[i].startDate, "endDate": this.result[i].endDate, "ProjectId": this.result[i].projectId,
        })
      }
    });

  }
  getdealbyuser() {
    this.dealArr = [];
    var url = '/api/projects/' + this.userid;
    this.http.get(url).subscribe((res: Response) => {
      this.result = res;
      this.mydeals = this.result.length;
      this.result.forEach(r => {
        this.http.get("/api/projectLogs/dealDuration/" + r.Project.id).subscribe((res: Response) => {
          this.result1 = res;
          if (this.result1.TotalDealDurationDays! <= 1 && this.result1.TotalDealDurationMonths == 0 && this.result1.TotalDealDurationYears == 0) {
            var dayvalue = this.result1.TotalDealDurationDays + " day";
          } else if (this.result1.TotalDealDurationDays != 0 && this.result1.TotalDealDurationMonths == 0 && this.result1.TotalDealDurationYears == 0) {
            var dayvalue = this.result1.TotalDealDurationDays + " days";
          } else if (this.result1.TotalDealDurationDays != 0 && this.result1.TotalDealDurationMonths != 0 && this.result1.TotalDealDurationYears == 0) {
            var dayvalue = this.result1.TotalDealDurationMonths + " mnt " + this.result1.TotalDealDurationDays + " days";
          } else if (this.result1.TotalDealDurationYears != 0) {
            var dayvalue = this.result1.TotalDealDurationYears + " Yr " + this.result1.TotalDealDurationMonths + " mnt " + this.result1.TotalDealDurationDays + " days";
          } else {
            var dayvalue = this.result1.TotalDealDurationDays + " days";
          }

          this.dealArr.push({
            "id": r.Project.id, "itemName": r.Project.title, "days": dayvalue, "createdDate": r.Project.createdDate, "dealCompleted": r.Project.dealCompleted

          })
        })
      })

    })

  }

  getApprovalbyuser() {
    this.approvalproArr = [];
    var url = '/api/projects/approvals/' + this.userid;
    this.http.get(url).subscribe((res: Response) => {
      this.result = res;  
      this.myapprovals = this.result.length;
      this.result.forEach(r => {
        this.http.get("/api/projectLogs/approvalDuration/" + r.Project.id).subscribe((res: Response) => {
          this.result1 = res;
          if (this.result1.TotalDealDurationDays <= 1 && this.result1.TotalDealDurationMonths == 0 && this.result1.TotalDealDurationYears == 0) {
            var dayvalue = this.result1.TotalDealDurationDays + " day";
          } else if (this.result1.TotalDealDurationDays != 0 && this.result1.TotalDealDurationMonths == 0 && this.result1.TotalDealDurationYears == 0) {
            var dayvalue = this.result1.TotalDealDurationDays + " days";
          } else if (this.result1.TotalDealDurationDays != 0 && this.result1.TotalDealDurationMonths != 0 && this.result1.TotalDealDurationYears == 0) {
            var dayvalue = this.result1.TotalDealDurationMonths + " mnt " + this.result1.TotalDealDurationDays + " days";
          } else if (this.result1.TotalDealDurationYears != 0) {
            var dayvalue = this.result1.TotalDealDurationYears + " Yr" + this.result1.TotalDealDurationMonths + " mnt " + this.result1.TotalDealDurationDays + " days";
          } else {
            var dayvalue = this.result1.TotalDealDurationDays + " days";
          }
          this.approvalproArr.push({
            "approvalproId": r.Project.id, "approvalproName": r.Project.title, "days": dayvalue, "createdBy": r.Project.createdBy, "value": r.Project.value, "createdDate": r.Project.createdDate, "approvalCompleted": r.Project.approvalCompleted

          })

        })

      })
    })
  }
  getProjectUser() {
    this.projectArr = [];
    var url = '/api/projects/projects/' + this.userid;
    this.http.get(url).subscribe((res: Response) => {
      this.result = res;
      this.myProject = this.result.length;
      this.result.forEach(r => {
        this.http.get("/api/projectLogs/projectDuration/" + r.Project.id).subscribe((res: Response) => {
          this.result1 = res;
          if (this.result1.TotalDealDurationDays <= 1 && this.result1.TotalDealDurationMonths == 0 && this.result1.TotalDealDurationYears == 0) {
            var dayvalue = this.result1.TotalDealDurationDays + " day";
          } else if (this.result1.TotalDealDurationDays != 0 && this.result1.TotalDealDurationMonths == 0 && this.result1.TotalDealDurationYears == 0) {
            var dayvalue = this.result1.TotalDealDurationDays + " days";
          } else if (this.result1.TotalDealDurationDays != 0 && this.result1.TotalDealDurationMonths != 0 && this.result1.TotalDealDurationYears == 0) {
            var dayvalue = this.result1.TotalDealDurationMonths + " mnt " + this.result1.TotalDealDurationDays + " days";
          } else if (this.result1.TotalDealDurationYears != 0) {
            var dayvalue = this.result1.TotalDealDurationYears + " Yr" + this.result1.TotalDealDurationMonths + " mnt " + this.result1.TotalDealDurationDays + " days";
          } else {
            var dayvalue = this.result1.TotalDealDurationDays + " days";
          }
          this.projectArr.push({
            "projectID": r.Project.id, "projectName": r.Project.title, "days": dayvalue, "createdBy": r.Project.createdBy, "value": r.Project.value, "createdDate": r.Project.createdDate, "projectCompleted": r.Project.projectCompleted

          })

        })

      })
    })
  }
  getTaskassigned() {
    this.http.get('/api/userTaskAssigned/' + this.userid).subscribe((res: Response) => {
      this.result = res;
      this.taskassigned = this.result.length;
      for (let i = 0; i < this.result.length; i++) {
        this.taskassignArr.push({
          "taskid": this.result[i].Task.id, "TaskName": this.result[i].Task.name, "completeBy": this.result[i].Task.completeBy, "isCompleted": this.result[i].Task.isCompleted, "createdDate": this.result[i].Task.createdAt, "ProjectId": this.result[i].Project.id, "ProjectTitle": this.result[i].Project.title, "UserId": this.result[i].User.id, "UserName": this.result[i].User.firstName + " " + this.result[i].User.lastName,
        })
      }

    });

  }


  gettaskcreated() {
    this.http.get('/api/userTaskCreated/' + this.userid).subscribe((res: Response) => {
      this.result = res;
      this.taskcreated = this.result.length;
      for (let i = 0; i < this.result.length; i++) {
        this.taskcreatedArr.push({
          "taskid": this.result[i].Task.id, "TaskName": this.result[i].Task.name, "completeBy": this.result[i].Task.completeBy, "isCompleted": this.result[i].Task.isCompleted, "createdDate": this.result[i].Task.createdAt, "ProjectId": this.result[i].Project.id, "ProjectTitle": this.result[i].Project.title, "UserId": this.result[i].User.id, "UserName": this.result[i].User.firstName + " " + this.result[i].User.lastName,
        })
      }
    });

  }

  onSelectdeals(deals) {
    this.router.navigate(['/details', deals]);
  }
  onSelectapproval(approvalpro) {
    this.router.navigate(['/approvals-details', approvalpro]);
  }
  onSelectProject(projects) {
    this.router.navigate(['/project-details', projects]);
  }
  ontasks(deals, taskid) {
    this.router.navigate(['/details', deals]);
  }

  eventClick(model: any) {
    this.eid = model.event.id
    this.confirmService.confirm('Please confirm..', 'Make Done this Event!')
      .then((confirmed) => {
        if (confirmed) {
          var data = {}
          this.http.put('/api/calendar/hide/' + this.eid, data).subscribe((response) => {
            this.toastr.clear();
            this.toastr.success('Events Done!', 'Report Status', {
              timeOut: 6000,
              closeButton: true
            });
            this.result = response;
            if (this.result) {
              window.location.reload();
            }
          })
        } else {
          return false;
        }


      })
      .catch(() => {
        return false;
      });
  }

  async getUser() {
    this.result = await this.DetailsService.getUsers();
    this.result1 = this.result;
    if (this.result) {
      this.users = {};
      this.result1.forEach(r => {
        this.users[r.details.id] = r.details;
      });      
    }
   
  }
    getDealDiagram(){
      this.dealChart=[];
      this.barChartLabels = [];
      this.barChartData = [];
      this.http.get('/api/user/dealsBarChart/'+this.userid).subscribe((res: Response) => {
        this.dealChart = res;         
        var getKeysArray = Object.keys(this.dealChart);
        var getValueArray = Object.values(this.dealChart);      
        this.barChartData.push({"data": getValueArray, "label": 'Deals'}) ; 
        for (let i = 0; i < getKeysArray.length; i++) {
              this.barChartLabels.push(getKeysArray[i]);      
          }  
          this.diagramShow();         

      });
    }
    getApprovalDiagram(){
      this.demodoughnutChartData=[];
      this.http.get('/api/user/pieChartDetails/'+this.userid).subscribe((res: Response) => {
        this.result = res;
          this.demodoughnutChartData.push(this.result.completedCount,this.result.pendingCount);      
      })
    }
    diagramShow(){
      this.isDealShow =true;  
      this.barChartOptions = {
          scaleShowVerticalLines: false,
          responsive: true
        };       
        this.barChartType = 'bar';
        this.barChartLegend = true;
      
    }
}
